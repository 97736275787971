import React, {Component, Fragment} from 'react';

import AddHomeCourseForm from '../Forms/AddHomeCourseForm';

export default class AddHomeCourseModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
                        <h2 className="modal__title">Add Home Course and Default Tees</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
                        <AddHomeCourseForm assocId={this.props.assocId} clubId={this.props.clubId} closeModal={(data)=>this.props.closeModal(data)} />
                    </div>

                </div>

            </Fragment>
		);
	}
}
