import React, { useState, useEffect, useCallback } from 'react';
import { FederationAccount } from "../../../../services/api/federationAccount";
import moment from "moment";
import GhinTable from "../../../shared/GhinTable";
import { get_campaigns_columns } from './campaigns_columns';
import { selectedColumns } from './selected_columns';
import { configurationColumns } from './configuration_columns';
import { detailsColumns } from './details_columns';
import { Filter } from "./Filter";
import { load_campaigns_sorting, load_details_sorting } from './utils';


export function Statistics() {
  let infoMessage = "Press the \"Details\" button to view more information about the selected email.";

  // Campaigns
  let [ campaignsLoading, setCampaignsLoading ] = useState(true);
  let [ campaigns, setCampaigns ] = useState([]);
  let [ selectedCampaign, setSelectedCampaign ] = useState(null);
  let [ campaignsExportUrl, setCampaignsExportUrl] = useState(null);

  // Campaigns pagination
  let [ campaignPages, setCampaignPages ] = useState(0);
  let [ campaignPerPage, setCampaignPerPage ] = useState(5);
  let [ campaignPage, setCampaignPage ] = useState(0);
  let [ campaignTotal, setCampaignTotal ] = useState(0);
  let [ campaignSorted, setCampaignSorted ] = useState([{id: "date", desc: true}]);

  // CampaignDetails
  let [ detailsLoading, setDetailsLoading ] = useState(true);
  let [ campaignDetails, setCampaignDetails ] = useState([]);
  let [ detailsExportUrl, setDetailsExportUrl ] = useState(".");
  let [ detailsFilters, setDetailsFilters ] = useState({
    status: "Delivered",
    email: null,
    golfer_id: null
  });
  const [resetFilters, setResetFilters] = useState(false);

  let [ detailsPages, setDetailsPages ] = useState(0);
  let [ detailsPerPage, setDetailsPerPage ] = useState(5);
  let [ detailsPage, setDetailsPage ] = useState(0);
  let [ detailsTotal, setDetailsTotal ] = useState(0);
  let [ detailsSorted, setDetailsSorted ] = useState([{id: "ghin_number", desc: false}]);

  let [ detailsPageChanged, setDetailsPageChanged] = useState(false);
  let [ campaignsPageChanged, setCampaignsPageChanged ] = useState(false);

  useEffect(() => {
    loadDetails();
  }, [selectedCampaign, detailsPageChanged, detailsFilters]);

  let loadDetails = () => {
    if (!selectedCampaign) return;

    setDetailsLoading(true);
    setCampaignDetails([]);

    let sortedPrepared = load_details_sorting(detailsSorted[0]);
    let fedCampaignId = selectedCampaign.id;
    let paramsDetails = {
      page: detailsPage + 1,
      per_page: detailsPerPage,
      sorting_criteria: sortedPrepared.sorting_criteria,
      order: sortedPrepared.order
    }

    if (detailsFilters.status) paramsDetails.status = detailsFilters.status.toLowerCase().replace(" ", "_");
    if (detailsFilters.email) paramsDetails.email = detailsFilters.email;
    if (detailsFilters.golfer_id) paramsDetails.golfer_id = detailsFilters.golfer_id;

    FederationAccount.getCampaignDetails(fedCampaignId, paramsDetails)
      .then((res) => {
        // Create the campaignDetails list
        setCampaignDetails(res.federation_emails);
        setDetailsLoading(false);
        // Pagination
        setDetailsPages(res["total-pages"]);
        setDetailsPerPage(res["per-page"]);
        setDetailsTotal(res["total-count"]);
      })
      .catch((err) => {
        console.log(err);
        setDetailsLoading(false);
      });
  };

  let loadDetailsExportUrl = (campaignId) => {
    FederationAccount.getExportCampaignDetailsUrl(campaignId)
      .then((res) => {
        setDetailsExportUrl(res.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let onDetailsClick = (colProps) => {
    // loadDetailsExportUrl(colProps.original.id);
    setDetailsFilters({
      status: "delivered",
      email: null,
      ghin_number: null
    });
    setResetFilters(true);
    setSelectedCampaign(colProps.original);
  }

  let campaignsColumns = get_campaigns_columns(onDetailsClick);

  let loadCampaigns = useCallback(() => {
    setCampaignsLoading(true);
    setCampaigns([]);

    let sortedPrepared = load_campaigns_sorting(campaignSorted[0]);

    let params = {
      page: campaignPage + 1,
      per_page: campaignPerPage,
      sorting_criteria: sortedPrepared.sorting_criteria,
      order: sortedPrepared.order
    };

    FederationAccount.getCampaigns(params)
      .then((res) => {
        let campaignsList = res["federation_campaigns"].map((campaign) => {
          return {
            ...campaign,
            date: moment(campaign.created_at).format("MM/DD/YYYY HH:mm:ss"),
            open_unique_percent: campaign.open_unique_percent + '%',
          }
        });

        setCampaigns(campaignsList);
        setCampaignsLoading(false);
        // Pagination
        setCampaignPages(res["total-pages"]);
        setCampaignPerPage(res["per-page"]);
        setCampaignTotal(res["total-count"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [campaignPage, campaignPerPage, campaignSorted]);

  // Load the campaign export url only at startup.
  useEffect(() => {
    FederationAccount.getExportCampaignsUrl()
      .then((res) => {
        setCampaignsExportUrl(res.url);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Load the campaigns at startup and when the pagination changes
  useEffect(() => {
    // Note: ignore the warning regarding the dependency array
    // We want to trigger the effect only when this variable changes.
    // Reason: if we trigger the effect when the variables used in the effect change, this leads to too many triggers === unnecessary requests
    // because we also change those variables inside the effect itself.
    loadCampaigns();
  }, [campaignsPageChanged]);

  function updateFilters(filters) {
    setDetailsFilters({
      golfer_id: filters.golfer_id,
      email: filters.email,
      status: filters.sent
    });
    setDetailsPage(0);
    // setDetailsPageChanged(!detailsPageChanged);
  }

	return (
		<div className="row">
			<div className="col">
				<div className="panel">
					<div className="panel__head">Sent Newsletters & Statistics</div>
					<div className="panel__body">
						<div className="row">
							<div className="col jc-fs">
                <p style={{marginBottom: "1rem"}}>
                  { infoMessage }
                </p>
							</div>
              <div className="col auto push-right">
								<a href={ campaignsExportUrl } target="_blank" rel="noreferrer" className="btn fill green small">Export to Excel</a>
							</div>
						</div>
            {/* Campaigns table */}
            <div className="row">
              <div className="col">
                <GhinTable
                  idKey={ 'campaigns_table' }
                  columns={ campaignsColumns }
                  hideSelect={ true }
                  pages={ campaignPages }
                  page={ campaignPage }
                  pageSize={ campaignPerPage }
                  onPageSizeChange={ (pageSize, pageIndex) => {
                    setCampaignPerPage(pageSize);
                    setCampaignPages(pageIndex);
                    setCampaignPage(0);
                    setCampaignsPageChanged(!campaignsPageChanged);
                  }}
                  loading={ campaignsLoading }
                  total={ campaignTotal }
                  manual
                  sortable={ true }
                  data={ campaigns }
                  onPageChange={ (pageIndex) => {
                    setCampaignPage(pageIndex);
                    setCampaignsPageChanged(!campaignsPageChanged);
                  }}
                  sorted={ campaignSorted }
                  onSortedChange={(newSorted, column, shiftKey) => {
                    setCampaignSorted(newSorted);
                    setCampaignPage(0);
                    setCampaignsPageChanged(!campaignsPageChanged);
                  }}
                  onFetchData={(state, instance) => console.log("")}
                />
              </div>
            </div>
            { selectedCampaign &&
              <>
                <div className="row">
									<div className="col">
										<div className="panel">
											<div className="panel__head">
												<span className="gray italic">{ selectedCampaign.name }</span>
											</div>
											<div className="panel__body">
                        {/* Export to excel */}
                        {/*
                        <div className="row" style={{'margin': 0}}>
                          <div className="col auto push-right">
                            <a href={ detailsExportUrl } target="_blank" rel="noreferrer" className="btn fill green small">Export to Excel</a>
                          </div>
                        </div>
                        */}
                        {/* Export to excel End */}
                        {/* Overall Statistics */}
                        <div className="row">
                          <div className="col">
                            <p><strong>Overall Statistics</strong></p>
                          </div>
                        </div>
												<div className="row">
													<div className="col">
														<GhinTable
															idKey={'overall_statistics_table'}
															columns={selectedColumns}
															hideSelect={true}
															data={[selectedCampaign]}
															sortable={false}
															hidePagination={true}
														/>
													</div>
												</div>
                        {/* Overall Statistics End */}
                        {/* Configuration */}
                        <div className="row">
                          <div className="col">
                            <p><strong>Configuration</strong></p>
                          </div>
                        </div>
                        <div className="row">
													<div className="col">
														<GhinTable
															idKey={'configuration_table'}
															columns={configurationColumns}
															hideSelect={true}
															data={[selectedCampaign]}
															sortable={false}
															hidePagination={true}
														/>
													</div>
												</div>
                        {/* Configuration End */}
                        {/* Golfer Search */}
                        <div className="form__separator padding-16-y"></div>
                        <div className="row jc-c">
                          <div className="col">
                            <p><strong>Golfer Search</strong></p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <Filter updateFiltered={(filters) => updateFilters(filters)} resetFilters={resetFilters} setResetFilters={setResetFilters}/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <GhinTable
                              idKey={ 'defailts' }
                              columns={ detailsColumns }
                              hideSelect={ true }
                              pages={ detailsPages }
                              page={ detailsPage }
                              pageSize={ detailsPerPage }
                              onPageSizeChange={ (pageSize, pageIndex) => {
                                setDetailsPerPage(pageSize);
                                setDetailsPages(pageIndex);
                                setDetailsPage(0);
                                setDetailsPageChanged(!detailsPageChanged);
                              }}
                              loading={ detailsLoading }
                              total={ detailsTotal }
                              manual
                              sortable={ true }
                              data={ campaignDetails }
                              onPageChange={ (pageIndex) => {
                                setDetailsPage(pageIndex);
                                setDetailsPageChanged(!detailsPageChanged);
                              }}
                              sorted={ detailsSorted }
                              onSortedChange={(newSorted, column, shiftKey) => {
                                setDetailsSorted(newSorted);
                                setDetailsPage(0);
                                setDetailsPageChanged(!detailsPageChanged);
                              }}
                            />
                          </div> 
                        </div>
                        {/* Golfer Search End */}
                        {/* Panel End */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
					</div>
				</div>
			</div>
		</div>
	);

}
