import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect, withRouter} from 'react-router-dom';

import PrimaryClub from './PrimaryClub';
import MembershipTypesClub from './MembershipTypesClub';
import ManageUsersClub from './ManageUsersClub';
import HomeCoursesKioskSetupClub from './HomeCoursesKioskSetupClub';
import {connect} from "react-redux";

class Account extends Component {
	render() {
		return (
            <Fragment>

                <div className="vertical-tabs__container row">
                    <ul className="vertical-tabs col">
                        <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/primary`}>Primary</NavLink></li>
						{process.env.REACT_APP_TYPE !== "hv" && <Fragment>
							<li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/home-courses-and-kiosk-setup`}>Home Courses &amp; <br/> Kiosk Setup</NavLink></li>
                        	<li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/membership-types`}>Membership Types</NavLink></li>
						</Fragment>}
						{(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/manage-users`}>Manage Users</NavLink></li>}
                    </ul>
                    <div className="vertical-tabs__panel col">
                        <Switch>
                            <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/primary`}/>
                            <Route path={`${this.props.match.url}/primary`} component={(props) =>
								<PrimaryClub canEditClub={this.props.canEditClub}
											 canEditAssociation={this.props.canEditAssociation}
											 associationId={this.props.associationId}
											 association={this.props.association}
                       updateJoinClubSettings={() => this.props.updateJoinClubSettings()}
											 club={this.props.club}/>}/>
							{process.env.REACT_APP_TYPE !== "hv" && <Route path={`${this.props.match.path}/home-courses-and-kiosk-setup`} component={() =>
									<HomeCoursesKioskSetupClub canEditClub={this.props.canEditClub}
															   club={this.props.club}
															   clubName={this.props.clubName}/>}/>}
							{process.env.REACT_APP_TYPE !== "hv" && <Route path={`${this.props.match.url}/membership-types`} component={() =>
									<MembershipTypesClub canEditClub={this.props.canEditClub}
														 canEditAssociation={this.props.canEditAssociation}
														 association={this.props.association}
														 club={this.props.club}/>}/>}
							{(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && <Route path={`${this.props.match.path}/manage-users`} component={() =>
								<ManageUsersClub canEditClub={this.props.canEditClub} club={this.props.club} canEditAssociation={this.props.canEditAssociation}/>}/>}
                        </Switch>
                    </div>
                </div>

            </Fragment>
		);
	}
}
function mapStateToProps(state) {
	return {
		user_type: state.user.access.user_type
	};
}


export default connect(mapStateToProps, null)(withRouter(Account));
