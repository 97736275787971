import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {isEmailAddress, required, alphaNumeric, requiredSelect, isUrl, maxLength, isNumber} from "../../inputs/validations";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import diff from 'object-diff';
import ClubService from "../../services/api/club";
import GolferService from "../../services/api/golfer";
import {addAlert} from '../shared/actions'
import debounce from "lodash.debounce";
import {AsyncSelectInput} from "../../inputs/AsyncSelectInput";
import golfClubCategories from "../shared/golfClubCategories";
import technologyProviders from "../shared/technologyProvider";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {DatePickerInput} from "../../inputs/DatePicker";
import moment from "moment/moment";

class EditClubAccountForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			is_dac: false,
			authorized: false,
			golfers: [],
			is_aga: false,
		}
		this.getGolfers = this.getGolfers.bind(this);
	}

	componentDidMount() {
		this.props.initialize({
			...this.props.club,
			...{
				technology_provider: this.props.club.technology_provider ? {label: this.props.club.technology_provider, value: this.props.club.technology_provider} : null,
				handicap_chairperson: {label: this.props.club.handicap_chairperson,value:this.props.club.handicap_chairperson},
				usga_version: {label: this.props.club.usga_version, value: this.props.club.usga_version},
				club_type: {label: this.props.club.club_type, value: this.props.club.club_type},
				club_category: {label: this.props.club.club_category, value: this.props.club.club_category},
				membership_date: this.props.club.inactive_flag ? (this.props.club.inactive_date ? new Date(moment(this.props.club.inactive_date)) : null) : null,

			}});
		this.setState({
			is_dac: this.props.club.is_dac,
			authorized: this.props.club.authorized,
			is_aga: this.props.club.is_aga
		});
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	golferLabel = golfer => `${golfer.first_name} ${golfer.last_name} (GHIN Number: ${golfer.id})`;

	getGolfers(name, callback) {
		GolferService.getAllGolfers(this.props.club.id, {full_name: name, sorting_criteria: 'last_name',order: 'asc'})
			.then(res => {
				if(!res.errors) {
					let golfers = res.golfers
						.filter(golfer => golfer.status != "Archived")
						.map(golfer => {
						return {...golfer, ...{value: golfer.first_name + ' ' +golfer.last_name, label: this.golferLabel(golfer)}}
					});
					this.setState({golfers});
					callback(golfers)
				} else {
					callback([]);
				}
			})
			.catch(err => {console.error(err)})
	}

	onSubmit(values, dispatch, props) {

		let data = JSON.parse(JSON.stringify(values));

		if (data.handicap_chairperson) {
			data.handicap_chairperson = data.handicap_chairperson.value;
		}
		if (!(props.isAgaAssoc && (props.superAdmin || props.isAssocAdmin ))){
			delete data.authorized;
		}
		if (data.technology_provider) {
			data.technology_provider = data.technology_provider.value;
		}
		if (data.club_type) {
			data.club_type = data.club_type.value;
		}
		if (data.usga_version) {
			data.usga_version = data.usga_version.value;
		}
		if (data.club_category) {
			data.club_category = data.club_category.value;
		}

		if (data.membership_date) {
			data.inactive_date = moment(data.membership_date).format('YYYY-MM-DD');
		}

		delete data.membership_date;

		data = diff(props.club, data);

		return ClubService.editPrimary(props.associationId, props.club.id, data)
			.then(res => {
				props.addAlert({
					type:'success',
					message:'Club has been successfully edited'
				});
				props.closeModal(res);
			})
			.catch(err=>{console.error(err)});

	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>

					<div className="row">
						<div className="col is-full">
							<label htmlFor="club_name">Club Name <span>*</span></label>
							<Field component={InputField} validate={[required, maxLength([60, 'The Club Name cannot exceed 60 characters.'])]} type="text" name="club_name" id="club_name" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="short_name">Short Name</label>
							<Field component={InputField} format={value => value ? value.slice(0,8) : ""} validate={[alphaNumeric]} type="text" name="short_name" id="short_name" />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="website">Website</label>
							<Field component={InputField} type="text" validate={[isUrl]} name="website" id="website" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="phone">Phone <span>*</span></label>
							<Field component={InputField} validate={[required]} type="text" name="phone" id="phone" />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="email">Email <span>*</span></label>
							<Field component={InputField} validate={[required, isEmailAddress]} type="text" name="email" id="email" />
						</div>
					</div>

					<div className="row">
						{(this.props.club.is_dac ? this.props.superAdmin : true) && <div className="col is-1-of-2">
							<label htmlFor="club_type">Club Type <span>*</span></label>
							<Field name={'club_type'}
								   id={'club_type'}
								   component={SelectInput}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={[{value:"Type 1",label:"Type 1"}, {value:"Type 2",label:"Type 2"},{value:"Type 3",label:"Type 3"}]}/>
						</div>}
						<div className="col is-1-of-2">
							<label>Club Category <span>*</span></label>
							<Field name={'club_category'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={golfClubCategories}/>
						</div>
					</div>

					<div className="row">
						{(this.props.club.jr_enabled && (this.props.superAdmin || this.props.isAssocAdmin)) && <div className="col is-1-of-2">
							<label htmlFor="online_zip_code">Join & Renew Search Zip Code</label>
							<Field name={'online_zip_code'} id={'online_zip_code'} component={InputField} type="text" validate={[isNumber]}/>
						</div>}
					</div>

					<div className="row">
						{this.props.superAdmin && <div className="col is-1-of-3">

							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {is_dac: !prevState.is_dac};
										});
									}
								}
								checked={this.state.is_dac}
								id="is_dac"
								value={true}
								name={'is_dac'}
								component={'input'}
								type="checkbox"
							/>
							<label htmlFor="is_dac">Is DAC?</label>
						</div>}
						{(this.props.isAgaAssoc && (this.props.superAdmin || this.props.isAssocAdmin )) && <div className="col is-1-of-3">
							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {authorized: !prevState.authorized};
										});
									}
								}
								checked={this.state.authorized}
								id="authorized"
								value={true}
								name={'authorized'}
								component={'input'}
								type="checkbox"
							/>
							<label htmlFor="authorized">Is Club Authorized?</label>
						</div>}
						{this.props.superAdmin && <div className="col is-1-of-3">

							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {is_aga: !prevState.is_aga};
										});
									}
								}
								checked={this.state.is_aga}
								id="is_aga"
								value={true}
								name={'is_aga'}
								component={'input'}
								type="checkbox"
							/>
							<label htmlFor="is_aga">USGA/AGA Club?</label>
						</div>}
					</div>

					<div className="row">
						<div className="col is-full">
							<label htmlFor="handicap_chairperson">Handicap Chairperson</label>
							<Field 	onChange={(event, newValue, prevValue) => {
										if (newValue) {}
									}}
									defaultOptions={this.state.golfers}
									loadOptions={debounce(this.getGolfers, 1000)}
									component={AsyncSelectInput}
									noOptionsMessage={'Type golfer name'}
									searchable={true}
									type="text"
									autoComplete="off"
									validate={[required]}
									id="handicap_chairperson"
									maxMenuHeight={170}
									name="handicap_chairperson"
									placeholder="Enter Golfer Name" />
						</div>
					</div>

					<div className="row">
						<div className="col is-full">
							<label htmlFor="usga_version">USGA TM Version</label>
							<Field name={'usga_version'}
								   id={'usga_version'}
								   component={SelectInput}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={[{value:"USGA TM Club",label:"USGA TM Club"}, {value:"USGA TM Club Premium",label: 'USGA TM Club Premium'}]}/>
						</div>
					</div>

					{(this.props.club.is_dac ? this.props.superAdmin : true) && <div className="row">
						<div className="col is-full">
							<label htmlFor="technology_provider">Front End Provider <span>*</span></label>
							<Field name={'technology_provider'}
								   id={'technology_provider'}
								   component={SelectInput}
								   validate={[required, requiredSelect]}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={technologyProviders}/>
						</div>
					</div>}

					{this.props.club.inactive_flag && <div className="row">
						<div className="col is-full">
							<label htmlFor="date">Future {this.props.club.inactive_flag === 'Activate' ? 'Activation' : 'Inactivation'} Date</label>
							<Field className={'date'}
								   showMonthDropdown
								   showYearDropdown
								   minDate={new Date()}
								   name={'membership_date'}
								   value={null}
								   id={'membership_date'}
								   component={DatePickerInput}
								   onFocus={e => this.props.change('membership_date', new Date())}
								   placeholder="Select ..." />

						</div>
					</div>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={() => this.props.closeModal()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  className="btn fill blue" disabled={pristine || submitting}>Save</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

EditClubAccountForm = reduxForm({
	form: 'editAccountForm',
	destroyOnUnmount: true
})(EditClubAccountForm);

function mapStateToProps(state) {
	const selector = formValueSelector('editAccountForm');

	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		superAdmin: state.user.access.super_user,
		isAssocAdmin: state.user.access.user_type === "association-admin",
		isAgaAssoc: state.association.profile.is_aga,
		membershipDate: selector(state, 'membership_date'),
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditClubAccountForm));
