import React, {Component} from 'react';
import Modal from "react-modal";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class ConfirmationModalTypeB extends Component {

  constructor(props) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm() {
    this.props.onConfirmAction();
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  render() {

    return (<Modal
        isOpen={this.props.openModal}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
        shouldReturnFocusAfterClose={false}
      >

        <div className={`modal__container narrow confirmation type-b`}>
          <div className={`modal__body`}>
            <div className="modal-head__icon">
              <i className="material-icons-outlined">{"help_outline"}</i>
            </div>

            <h2 className="modal__title">{[`Delete the following offer code?`]}</h2>
            <button className="modal__close" onClick={() => this.props.closeModal()}><i
              className="material-icons-outlined">clear</i></button>

            <div>
              <p className="medium align-center">{this.props.offerCode}</p>
              <p
                className="large">{`This code has been redeemed by one or more golfers. Deleting this code will also delete statistical information for the code.`}</p>
              <p
                className="large">{`If you would like to export statistical information prior to deleting the code, you may do so by clicking the link below prior to continuing with the deletion.`}</p>
              <span className="export_offer" onClick={() => {this.props.exportOffer('Delete Modal')
              }}>
                Export Code Statistics
              </span>
            </div>

            <div className="row">
              {this.props.cancelLabel && <div className="col">
                <button type="button" disabled={this.props.loading} onClick={() => {
                  this.props.onCancelAction()
                }} className="btn fill gray">{this.props.cancelLabel}</button>
              </div>}
              <div className="col">
                <button
                  type="submit" ref={'submit'} disabled={this.props.loading || this.props.error}
                  onClick={this.onConfirm}
                  className={`${this.props.mergeContext && this.props.loading ? "btn loading fill" : "btn fill"}
															${this.props.colorClass ? "bg_" + this.props.colorClass : "blue"}`}
                >
                  {(!this.props.mergeContext || !this.props.loading) && this.props.confirmLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>)
  }
}

export default withKeyPress(ConfirmationModalTypeB);
