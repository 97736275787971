import React, {Component} from 'react';
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {GolfAssociationGroup} from "../../services/api/federations";
import {InputField} from "../../inputs/InputField";
import {required} from "../../inputs/validations";
import {addAlert} from '../shared/actions'
import {bindActionCreators} from "redux";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class AddAssocGroupForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			error: false,
			errors: []
		};
		this.onSubmit = this.onSubmit.bind(this);
	}
	
	onChange() {
		this.setState({
			error: false,
			errors: []
		});
	}

	onSubmit(values) {
		return GolfAssociationGroup.create({name: values.name, federation_id: 1})
			.then(response => {
				this.props.addAlert({type:'success',message:'Group has been successfully saved'});
				let group = response.golf_association_group;
				group.label = group.name;
				group.value = group.id;
				this.props.selectGroup(group);
				this.props.closeModal();
			})
			.catch(err => {
				if(err.data) {
					this.setState({
						error: true,
						errors: err.data.errors
					});
				}
			});
	}

	componentDidMount() {
		setTimeout(function() {
			document.getElementsByTagName('form')[0].querySelector('input').focus()
		}, 100)
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
            <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
                <div className="row">
                    <div className="col is-1-of-2">
                        <label htmlFor="name">Association Group Name <span>*</span></label>
                        <Field className={'tests'} 
													validate={[required]}
													component={InputField}
													type="text"
													name="name"
													id="name"
													onChange={(e) => {this.onChange()}} 
													/>
                    </div>
                </div>
								{this.state.error && Object.keys(this.state.errors).map(key => {
									return (<div className="row" key={key}>
										<div className="col ">
											<p className="validation__message is-error">{this.state.errors[key]}</p>
										</div>
									</div>)
								})}
                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={() => this.props.closeModal()}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
                        <button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Submit</button>
                    </div>
                </div>
            </form>
		);
	}
}


AddAssocGroupForm = reduxForm({
	form: 'addAssocGroupForm',
	destroyOnUnmount: true
})(AddAssocGroupForm);

function mapDispatchToProps(dispatch) {
	return bindActionCreators({addAlert}, dispatch);
}


function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddAssocGroupForm));
