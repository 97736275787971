import request from '../shared/request';

export class FederationAccount {
	static update(data) {
		return request({
			url: `/federations/1.json`,
			method: 'PATCH',
			data: data
		});
	}

	static deleteLogo() {
		return request({
			url: `/federations/1.json?delete_logo=true`,
			method: 'PATCH'
		});
	}

	static uploadLogo(data) {
		let bodyFormData = new FormData();
		Object.keys(data).forEach((key) => {
			bodyFormData.append(key, data[key]);
		});

		return request({
			url: `/federations/1.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static get() {
		return request({
			url: '/federations/1.json',
			method: 'GET',
		})
	}

	static getSurvey() {
		return request({
			url: '/surveys/1.json',
			method: 'GET',
		})
	}

	static updateSurvey(data) {
		return request({
			url: '/surveys/1.json',
			method: 'PATCH',
			data: data
		})
	}

	static getAssociations() {
		return request({
			url: '/golf_associations.json',
			method: 'GET',
		})
	}

	static createSurveyAssociation(data) {
		return request({
			url: `surveys/1/survey_associations.json`,
			method: 'POST',
			data: data
		});
	}

	static deleteSurveyAssociation(id) {
		return request({
			url: `surveys/1/survey_associations/${id}.json`,
			method: 'DELETE'
		});
	}

	static getFacilities(golf_association_id) {
		return request({
			url: `CrsAssociations/${golf_association_id}.json`,
			method: 'GET'
		});
	}

	static updateSurveyAssociation(data, id) {
		return request({
			url: `surveys/1/survey_associations/${id}.json`,
			method: 'PATCH',
			data: data
		});
	}

	static getGolferProductsForFederation(federationId) {
		return request({
			url: `/federations/1/federation_settings.json`,
			method: "GET"
		})
	}

	static editDefaultGolferProducts(federationId, settingsId, data) {
		return request({
			url: `/federations/1/federation_settings/${settingsId}.json`,
			method: "PATCH",
			data: {federation_settings: data}
		})
	}

	static removeDefaultImage(federationId, settingsId, data) {

		let bodyFormData = new FormData();
		Object.keys(data).forEach((key) => {
			bodyFormData.append(key, data[key]);
		});

		return request({
			url: `/federations/1/federation_settings/${settingsId}.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static getFederationKioskImages(federationId){
		return request({
			url: `/federations/1/kiosk_images.json`,
			method: 'GET'
		})
	}

	static editDefaultImageMobileApp(federationId, settingsId, data) {

		let bodyFormData = new FormData();
		Object.keys(data).forEach((key) => {
			bodyFormData.append(key, data[key]);
		});

		return request({
			url: `/federations/1/federation_settings/${settingsId}.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static addDefaultKioskImage(federationId) {
		return request({
			url: `/federations/1/kiosk_images.json`,
			method: 'POST'
		})
	}

	static uploadDefaultKioskImage(federationId, id, data) {

		let bodyFormData = new FormData();
		bodyFormData.append('kiosk_image[image]', data);
		return request({
			url: `/federations/1/kiosk_images/${id}.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static deleteDefaultKioskImage(federationId, id){
		return request({
			url: `/federations/1/kiosk_images/${id}.json`,
			method: 'DELETE'
		})
	}

	static removeDefaultKioskImage(federationId, id) {
		return request({
			url: `/federations/1/kiosk_images/${id}.json?delete_image=true`,
			method: 'PATCH'
		})
	}

	static editDefaultKioskImage(federationId, id, data) {
		return request({
			url: `/federations/1/kiosk_images/${id}.json`,
			method: 'PATCH',
			data: data

		})
	}

	static getPushNotification(page, per_page, federation_id, params) {
		return request({
			url: `/federations/1/push_notification_setting.json?page=${page}&per_page=${per_page}`,
			method: 'GET',
			params
		})
	}

	static exportNotificationHistory(federation_id, data) {
		return request({
			url: `/federations/1/push_notification_setting/export.json?only_link=true`,
			method: "POST", data
		})
	}

	static createPushNotificationSetting(federation_id, data) {
		return request({
			url: `/federations/1/push_notification_setting.json`,
			method: "POST",
			data: FederationAccount.dataToFormData(data)
		})
	}

	static deletePushNotification(federation_id, id){
		return request({
			url: `/federations/1/push_notification_setting/${id}.json`,
			method: 'DELETE'
		})
	}

	static editPushNotification(federation_id, id, data) {
		return request({
			url: `/federations/1/push_notification_setting/${id}.json`,
			method: 'PATCH',
			data: FederationAccount.dataToFormData(data)
		})
	}

	static dataToFormData(data) {
		let bodyFormData = new FormData();

		Object.keys(data).forEach((key) => {
			if (typeof(data[key]) === 'object' && key !== 'image'){
				if (Array.isArray(data[key])) {
					data[key].forEach((value) => {
						bodyFormData.append(`${key}[]`, value);
					});
				}
				else {
					for (var hashKey in data[key]) {
						bodyFormData.append(`${key}[${hashKey}]`, data[key][hashKey]);
					}
				}
			}
			else bodyFormData.append(key, data[key]);
		});

		return bodyFormData;
	}
  static getNewsletterTemplates(params) {
    return request({
      url: "federation_campaigns/templates",
      method: 'GET',
			params: params
    })
  }

  static sendFederationNewsletter(data) {
    return request({
      url: "federation_campaigns",
      method: 'POST',
      data: data
    })
  }

  static sendTestFederationNewsletter(data) {
    return request({
      url: "federation_campaigns/test.json",
      method: 'POST',
      data: data
    });
  }

  static getCampaigns(params) {
    return request({
      url: "federation_campaigns.json",
      method: 'GET',
      params: params
    });
  }

  static getExportCampaignsUrl() {
    return request({
      url: "federation_campaigns/export_to_excel.json",
      method: 'GET'
    });
  }

  static getCampaignDetails(federation_campaign_id, params) {
    return request({
      url: `federation_campaign/${federation_campaign_id}.json`,
      method: 'GET',
      params: params
    });
  }

  static getExportCampaignDetailsUrl(federation_campaign_id) {
    return request({
      url: `federation_campaigns/${federation_campaign_id}/export_to_excel.json`,
      method: 'GET',
    });
  }
}
