const hubspotScopes = 'crm.schemas.custom.write%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.schemas.custom.read%20crm.objects.custom.read%20crm.objects.custom.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write'

const baseHubspostInstallUrl = "https://app.hubspot.com/oauth/authorize"

const redirectPath = '/manage/association/ghin-connect/hubspot'

const getRedirectUrl = () => {
  let redirectUrl = window.location.origin + redirectPath

  if (!redirectUrl.includes('localhost') && redirectUrl.includes('http://')) {
    redirectUrl = redirectUrl.replace('http://', 'https://')
  }

  return redirectUrl
}

const readOnlyProps = {
  golfers: ['first_name', 'last_name', 'street_1', 'phone_number', 'email', 'zip', 'city'],
  clubs: ['club_name', 'street_1', 'street_2', 'phone', 'zip', 'city', 'country', 'website'],
  golfer_club_affiliations: []
}

const syncByValuesNames = {
  id: 'GHIN Number',
  email: 'Email'
}

const defaultlDataHubSpotTable = [
  {
    id: 'golfers',
    enable: false,
    syncBy: 'id',
    disabled: false,
    statuses:{
      active: false,
      inactive: false,
      archived: false
    },
    ghin: "Golfers",
    hubspot: "Contacts",
  },
  {
    id: 'clubs',
    enable: false,
    disabled: false,
    statuses:{
      active: false,
      inactive: false,
      archived: false
    },
    ghin: "Clubs",
    hubspot: "Companies",
  },
  {
    id: 'golfer_club_affiliations',
    enable: false,
    disabled: true,
    statuses:{
      active: false,
      inactive: false,
      archived: false
    },
    ghin: "Golfer Club Affiliations",
    hubspot: "Memberships",
  },
]

const columnsObjectMapping = [
  {
    Header: 'GHIN Field',
    accessor: 'ghin_field',
    thClassName: 'is-3-of-9',
    sortable: false,
  },
  {
    Header: 'Map?',
    accessor: 'is_mapped',
    thClassName: 'is-2-of-9 table-align-center',
    sortable: false,
    Cell: props => <span className='col_edit-delete' style={{ height: '40px' }}> {props.original.is_mapped ? 'Yes' : 'No'}</span>,
  },
  {
    Header: 'Map to (HubSpot)',
    accessor: 'hubspot_field',
    thClassName: 'is-4-of-9',
    sortable: false,
  },
];

export { defaultlDataHubSpotTable, columnsObjectMapping, hubspotScopes, baseHubspostInstallUrl, getRedirectUrl, readOnlyProps, syncByValuesNames }
