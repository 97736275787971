import React, {Component, Fragment} from 'react';
import GhinFilterSelect from "./GhinFilterSelect";
import DateRageDropDown from "./DateRageDropDown";
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";

export default class TableFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			disabled: true,
      label: '',
			filters: {
				username: '',
				change_codes: null,
				secondary_filter: null,
				start_date: null,
				end_date: null
			}
		};
		
		this.updateLabel = this.updateLabel.bind(this);
		this.updateDates = this.updateDates.bind(this);
	}

	setInitialState() {
		this.setState({
			disabled: true,
      label: '',
			filters: {
				username: '',
				change_codes: null,
				secondary_filter: null,
				start_date: null,
				end_date: null
			}
		}, () =>
			this.updateTable()
		)
	}

  componentDidMount() {
    if (!this.props.preventInitialLoad) {
      this.updateFilter()
    }
  }

	updateFilter = (name, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		}, () => {
			this.updateTable();
		})
	};

  updateDates = (start_date, end_date) => {
    this.setState({
      filters: {
        ...this.state.filters,
        start_date: start_date,
        end_date: end_date
      }
    }, () => {
      this.updateTable();
    })
  };

  updateLabel(value) {
    this.setState({
      label: value
    });
  }

	updateTable = debounce(() => {
		let filters = JSON.parse(JSON.stringify(this.state.filters));
		Object.keys(filters).forEach(f => {
			if (filters[f] !== null) {
				if (Array.isArray(filters[f])) {
					let fv = filters[f];
					fv = fv.map(v => v.value).join(",");
					filters[f] = fv;
				}
				if (typeof filters[f] === 'object') {
					filters[f] = filters[f].value;
				}
			}
		});
		this.props.updateFiltered(filters);
	}, 500);

	reset() {
		this.setInitialState();
	}

	render() {
		return (
			<Fragment>
				<div className="filters">

					<div className="row">

						<div className="filters__inputs col">

							<div className="row">

								<div className={'col'}>

									<div className="row">
								
										<div className={"col is-2-of-8"}>
											<GhinFilterSelect
												options={this.props.change_codes_array}
												value={this.state.filters.change_codes}
												onChange={values => {
													const output = values.flatMap((item) => Object.values(item));
													if (output.includes('Scores') || output.includes('Golfer Updates') || 
                            output.includes('Handicap Revisions') || output.includes('Handicap Reviews'))
														this.setState({	disabled: false })
													else 
														this.setState({	disabled: true })
													
													this.updateFilter('change_codes', values)
												}}
												label={'Change Code'}
											/>
										</div>

										{ this.props.secondary_filter_array && <div className={"col is-2-of-8"}>
											<GhinFilterSelect
												options={this.props.secondary_filter_array}
												disabled={this.state.disabled}
												value={this.state.disabled ? [] : this.state.filters.secondary_filter}
												onChange={values => {
													this.updateFilter('secondary_filter', values, 'change_codes', values)
												}}
												label={'Option'}
											/>
									  </div>}

                      <div className="col is-1-of-4">
                        <label>Date Range</label>
												<DateRageDropDown
                        value={this.state.label || this.props.date_label}
													updateDates={this.updateDates}
													updateLabel={this.updateLabel}/>
                      </div>

										<div className="col is-1-of-6">
											<label htmlFor="username">Username</label>
											<input type="text" autoComplete="off" value={this.state.filters.username} name="username" id="username" onChange={(e) => {
												this.updateFilter('username', e.target.value)
											}}/>
										</div>
									</div>
								</div>
							</div>

						</div>

						<div className="filters__controls col is-1-of-7 jc-fe">
							<button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
						</div>

					</div>

				</div>

			</Fragment>
		);
	}
}
