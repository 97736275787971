import React, {Fragment} from "react";

export default function RefundConfirmationModal(props) {

  return (
    <Fragment>
      {props.loading ?
        <span className="data-table__loader" style={{marginLeft: "calc(50% - 60px)", marginBottom: '25px'}}>
        </span>
        :
        <>
          <div className="modal__container narrow">
            <div className="modal__head">
              <div className="modal__icon"><i className="material-icons-outlined">warning</i></div>
              <h2 className="modal__title">Are You Sure?</h2>
              <button className="modal__close" onClick={() => {
                props.closeModal()
              }}><i className="material-icons-outlined">clear</i>
              </button>
            </div>

            <div className="modal__body">
              <p>Please review the following refund details for {props.golferName}. This action cannot be undone.</p>
              <div className='row' style={{marginTop: "25px"}}>
                <div className='col is-full'>
                  <table className='data__table' style={{maxWidth: "500px", margin: "auto"}}>
                    <tbody className='no-background'>
                    <tr>
                      <td className='table-center-cell'><strong>Refund Name</strong></td>
                      <td className='table-center-cell'><strong>Refund Amount</strong></td>
                    </tr>
                    {props.refund_rows.map((row, key) => {
                      return (
                        <tr key={key}>
                          <td
                            className={`table-left-cell ${row.label === 'Total refund:' ? 'strong' : ''}`}>{row.label ? row.label : <>&nbsp;</>}</td>
                          <td
                            className={`table-right-cell ${row.label === 'Total refund:' ? 'strong' : ''}`}>{row.value}</td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col is-1-of-2">
                  <button type={'button'} className="btn fill gray" onClick={() => props.closeModal()}>Cancel</button>
                </div>
                <div className="col is-1-of-2">
                  <button type={'submit'} className="btn fill blue" onClick={() => props.onConfirm()}>Submit Refund
                  </button>
                </div>
              </div>

            </div>
          </div>
        </>
      }

    </Fragment>
  );
}
