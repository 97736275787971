import React, { useEffect, useState } from "react";
import { addAlert } from "../shared/actions";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { InputField } from "../../inputs/InputField";
import { isCustomNumeric, maxLength, customMsgRequired } from "../../inputs/validations";
import TransactionFeesServices from "../../services/api/transactionFees";

function EditTransactionFeeFunction(props) {
  const { handleSubmit, pristine, submitting, valid } = props;
  const [error, set_error] = useState(props.error);

  useEffect(() => {
    const initialize = props.initialize
    const selected_fee = props.selectedFee

    initialize({
      ...selected_fee
    })
  }, [props.selectedFee])



  const onSubmit = (values, dispatch, props) => {
    if (props.operationType === "Create") {
      values.is_default ||= false
      return TransactionFeesServices.createTransaction(props.associationId, values).then(() => {
        props.addAlert({ type: 'success', message: "Transaction Fee successfully created." });
        props.reload_transaction_fees();
        props.closeModal();
      }).catch((err) => {
        console.log(err)
        if (err.data.errors) {
          const error = err.data.errors
          set_error(error[Object.keys(error)[0]])
        }
        if (err.data.error) {
          try {
            console.log(err.data.error)
            set_error(err.data.error)
          }
          catch {
            set_error("An error has occurred")
          }
        }
      })
    }

    if (props.operationType === "Edit") {
      props.setUpdateTransactionValues(values);
      props.updateTransaction(values);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col">
          <label htmlFor="description">Transaction Fee Name <span>*</span></label>
          <Field component={InputField} validate={[customMsgRequired('Please provide a transaction fee name'), maxLength([50])]} type="text" name="description" id="description" />
        </div>
      </div>
      <div className="row">
        <div className="col is-1-of-2">
          <label htmlFor="amount">Transaction Fee <span>*</span></label>
          <Field component={InputField} placeholder={'0.00'} validate={[customMsgRequired('Please provide a numerical transaction fee amount'), isCustomNumeric(8)]} type="text" name="amount" id="amount" />
        </div>
        <div style={{ marginTop: '25px', marginLeft: '15px' }} className="col is-1-of-2">
          <Field
            component={'input'}
            type="checkbox"
            name="is_default"
            id="is_default"
          />
          <label style={{ color: 'black' }} htmlFor="is_default">Make Default Transaction Fee **</label>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <span>** The default transaction fee will be automatically assigned to each transaction for every club that enables Join & Renew. Only one transaction fee can be set to the default.</span>
        </div>
      </div>
      <div className="row" style={{marginBottom: 0}}>
        <div className="col">
          <span className={"red"} style={{fontStyle: "italic"}}>NOTE: This fee is effective immediately in the association's local timezone.</span>
        </div>
      </div>
      {error && <span className={'validation__message is-error'}>{error}</span>}
      <div className="row">
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill gray" onClick={() => props.closeModal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type={'submit'} disabled={pristine || submitting || !valid} className="btn fill blue">Save</button>
        </div>
      </div>
    </form>
  )
}

const EditTransactionFeeForm = reduxForm({
  form: 'EditTransactionFeeForm',
  destroyOnUnmount: true
})(EditTransactionFeeFunction)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditTransactionFeeForm));
