import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {reduxForm, Field, SubmissionError} from "redux-form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cookies from 'universal-cookie';
import AuthService from "../../services/api/auth";
import {InputField} from "../../inputs/InputField";
import {required,isEmailAddress} from "../../inputs/validations";
import {getUserAccess, logoutUser} from "./actions";
import {toggleAlert} from "../shared/actions";
import UsersService from "../../services/api/users";
import {buildAccess} from "../../permissionManager";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import SharedService from "../../services/api/shared";
import TermsModal from "../Modals/TermsModal";
import Modal from "react-modal";

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {termsModal: false, terms: [], user: null, termsToSave: null, error: null};
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.props.toggleAlert(true);
		Modal.setAppElement('body');
	}

	onSubmit(values) {
		return AuthService.login({ user: values })
			.then(response => {

				const cookies = new Cookies();
				cookies.set('token', response.token, {path: '/', expires: values.remember_me ? null : new Date((new Date()).getTime() + 1000 * 3600 * 24)});
				this.getTerms().then(res => {
					if(response && response.token) {
						if(response.terms_accepted) {
							this.termsAccepted(response.user);
							const cookies = new Cookies();
							cookies.remove('gotAlert', { path: '/' });
						} else {
							SharedService.getFederationTerms()
								.then(res => {
									this.setState({
										termsModal: true,
										user: response.user
									});
								})
								.catch(err => {
									console.log(err);
								})

						}

					} else {
						throw new SubmissionError({email: "No user permissions defined"});
					}
				})

			})
			.catch(err => {
				throw new SubmissionError({email: "Invalid email or password", password: "Invalid email or password"})
			})
	}

	getTerms() {
		return SharedService.getFederationTerms()
			.then(res => {
				this.setState({
					terms: Object.keys(res.terms).map(label => {
						return (res.terms[label]);
					}),
					termsToSave: res.terms,
				});
			})
			.catch(err => {
				console.log(err);
			})
	}

	acceptTerms() {
		SharedService.acceptTerms()
			.then(res => {
				this.termsAccepted(this.state.user);
			})
			.catch(err => {
				console.log(err);
			})
	}

	closeModal() {
		this.setState({
			termsModal: false
		}, ()=>{
			const cookies = new Cookies();
			cookies.remove('token', { path: '/' });
		})
	}

	 termsAccepted(user) {
		this.props.dispatch({
			type: "@@ghin/ADD_LOGIN_USER_DATA",
			payload: user
		});
		return UsersService.accesses()
			.then(response => {
				const cookies = new Cookies();
				response.email = user.email;
				UsersService.emails()
					.then(res => {
						let parsedAccess = buildAccess(response, res);

						if(parsedAccess) {
							this.props.dispatch({
								type: '@@ghin/ADD_USER_PARSED_ACCESS',
								payload: parsedAccess
							});
							this.props.dispatch({
								type: '@@ghin/ADD_USER_ACCESS',
								payload: response
							});
							this.props.dispatch({
								type: '@@ghin/ADD_TERMS',
								payload: this.state.termsToSave
							});
							this.props.dispatch({
								type: "@@ghin/USER_AUTHENTICATED",
								payload: !!cookies.get("token")
							});
						} else {
							this.props.logoutUser();
							this.setState({error: "Your account is inactive"});
						}
					})
					.catch(err => {
						this.setState({error: "Your account is inactive"});
					})

			})
			.catch(err => {
				this.setState({error: "Your account is inactive"});
			})
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
				<section className="auth">
					<div className="auth__container">
						<div className="auth__head">{process.env.REACT_APP_TYPE !== "hv" ? 'USGA Admin Portal Log In': 'Testing Portal Log In'}</div>
						<div className="auth__body">
							<div className="usga__logo push-right"></div>
							<div className="f-row">
								<div className="f-col">
									<label htmlFor="email">Email Address <span>*</span></label>
									<Field validate={[required,isEmailAddress]} className={'input'} component={InputField} type="text" name="email" id="email" />
								</div>
							</div>
							<div className="f-row">
								<div className="f-col">
									<label htmlFor="password">Password <span>*</span></label>
									<Field validate={[required]} className={'input'} component={InputField} type="password" name="password" id="password" />
								</div>
							</div>
							<div className="f-row">
								<div className="f-col auto">
									<Field className={'input small'} component={'input'} type="checkbox" name="remember_me" id="remember_me"/>
									<label htmlFor="remember_me">Remember Me</label>
								</div>
								<div className="f-col auto text-align-right">
									<NavLink className="form-link" to={"/auth/recovery"} >Forgot your password?</NavLink>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn full fill blue">Log In</button>
								</div>
							</div>
							{this.state.error && <span className={'validation__message is-error'} style={{textAlign:'center'}}>{this.state.error }</span>}
						</div>
					</div>
				</section>
				<Modal
					isOpen={this.state.termsModal}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<TermsModal  onConfirm={() => this.acceptTerms()}
								 closeModal={() => this.closeModal()}
								 onDecline={() => this.closeModal()}
								 terms={this.state.terms}/>
				</Modal>
			</form>
		);
	}
}

Login = reduxForm({
	form: 'authForm',
	destroyOnUnmount: true
})(Login);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({getUserAccess, logoutUser,toggleAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(Login));
