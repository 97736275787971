export function alertsReducer(state = [], action) {
	switch(action.type) {

		case '@@ghin/ADD_ALERT':
			return state.concat(action.payload);

		case '@@ghin/REMOVE_ALERT':
			return state.filter((item, index) => index !== action.payload);

		default:
			return state;
	}
}
