import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import AssociationService from "../../../../services/api/association";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAlert } from "../../../shared/actions";
import { updateAssociation } from "../Account/Actions";
import EditTournamentsAndEventsModal from "../../../Modals/EditTournamentsAndEventsModal";
import ConfirmationModal from "../../../Modals/ConfirmationModal";

class TournamentsAndEvents extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editTournamentsAndEventsModalIsOpen: false,
			confirmRemoveTnEModalIsOpen: false,
			settings: "",
			error: null,
		};

		this.openEditTournamentsAndEventsModal = this.openEditTournamentsAndEventsModal.bind(
			this
		);
	}

	openEditTournamentsAndEventsModal() {
		this.setState({
			editTournamentsAndEventsModalIsOpen: true,
		});
	}
	openConfirmRemoveTnEModal() {
		this.setState({
			confirmRemoveTnEModalIsOpen: true,
		});
	}

	closeModal(data) {
		this.setState({
			editTournamentsAndEventsModalIsOpen: false,
			settings: data ? data : this.state.settings,
		});
	}

	removeTournamentsAndEvents() {
		let data = {
			tournaments_and_events_url: null,
			tournaments_and_events_category: null,
		};
		return AssociationService.editGolferProducts(
			this.props.associationId,
			this.state.settings.id,
			data
		)
			.then((res) => {
				this.setState({
					settings: res.association_settings,
					confirmRemoveTnEModalIsOpen: false,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	componentDidMount() {
		AssociationService.getGolferProducts(this.props.associationId)
			.then((res) => {
				this.setState({
					settings: res.association_settings,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}

	render() {
    
		return (
			<Fragment>
				{this.state.settings && (
					<Fragment>
						<div className="row">
							<div className="col">
								<div className="panel">
									<div className="panel__head">
										Tournaments & Events
									</div>
									<div className="panel__body">
										{this.props.canEditAssociation && (
											<div className="row">
												<div
													className="col auto push-right"
													style={{
														justifyContent:
															"center",
													}}
												>
													<button
														onClick={
															this
																.openEditTournamentsAndEventsModal
														}
														className="btn lnk"
													>
														Edit
													</button>
												</div>
												{this.state.settings
													.tournaments_and_events_url &&
													this.state.settings
														.tournaments_and_events_category && (
														<div className="col auto">
															<button
																className="btn fill gray"
																onClick={() => {
																	this.openConfirmRemoveTnEModal();
																}}
															>
																<i className="material-icons-outlined no-margin">
																	clear
																</i>
															</button>
														</div>
													)}
											</div>
										)}
										<div className="row">
											<div className="col jc-c">
												<div className="data-set__container">
													<div className="data-set">
														<span className="data-label">
															Tournaments & Events
															URL:
														</span>
														<span>
															<a
																href={
																	this.state
																		.settings
																		.tournaments_and_events_url
																}
																target="_blank"
																rel="noreferrer"
															>
																{
																	this.state
																		.settings
																		.tournaments_and_events_url
																}
															</a>
														</span>
													</div>
													<div className="data-set">
														<span className="data-label">
															Label:
														</span>
														<span>
															{
																this.state
																	.settings
																	.tournaments_and_events_category
															}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<Modal
							isOpen={
								this.state.editTournamentsAndEventsModalIsOpen
							}
							onRequestClose={() => {
								this.closeModal();
							}}
							contentLabel="Modal"
							portalClassName="modal"
							overlayClassName="modal__overlay"
							className="modal__content"
							bodyOpenClassName="modal--is-open"
							htmlOpenClassName="prevent-scroll"
							shouldCloseOnOverlayClick={true}
							shouldFocusAfterRender={false}
						>
							<EditTournamentsAndEventsModal
								assocSettings={this.state.settings}
								assocId={this.props.associationId}
								closeModal={(data) => {
									this.closeModal(data);
								}}
							/>
						</Modal>
						{/* Are you sure Tournaments and Events */}
						<ConfirmationModal
							openModal={this.state.confirmRemoveTnEModalIsOpen}
							onConfirmAction={() =>
								this.removeTournamentsAndEvents()
							}
							onCancelAction={() =>
								this.setState({
									confirmRemoveTnEModalIsOpen: false,
								})
							}
							closeModal={() =>
								this.setState({
									confirmRemoveTnEModalIsOpen: false,
								})
							}
							question={"Are you sure?"}
							cancelLabel={"No"}
							confirmLabel={"Yes"}
						/>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ updateAssociation, addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(mapDispatchToProps)(TournamentsAndEvents);
