import React, {Component, Fragment} from 'react';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {isUrlFormat} from "../../inputs/validations";
import Select from 'react-select';

class EditImageInformationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: this.props.image,
            alt_text: this.props.alt_text,
            ad_image_url: this.props.has_ad_image_url ? this.props.ad_image_url : null,
            mobile_image1_ad_tag: this.props.mobile_image1_ad_tag,
            mobile_image2_ad_tag: this.props.mobile_image2_ad_tag,
            deep_link: this.props.is_deep_link,
            mobile_deep_links: this.props.mobile_deep_links,
            selected_link: null,
            urlError: null,
        }
    }

    componentDidMount() {
        if (!(this.props.mobile_deep_links == null)) {
            if (this.state.deep_link) {
                const url = this.state.ad_image_url;
                this.setState({selected_link: this.state.mobile_deep_links.find(function(obj) {
                    return obj.value == url;
                }), ad_image_url: ''});
            } else {
                this.setState({selected_link: this.state.mobile_deep_links[0]});
            }
        }
	}


    render() {

        if (!this.props.image) {
            return null;
        }

        return (
            <Fragment>

                <div className="row">
                    <div className="col is-full">
                        <div className="form__image-preview">
                            <img src={this.state.image} alt="" name=""/>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label>Image Url</label>
                        <span className="image-preview__url">{this.state.image}</span>
                    </div>
                </div>

                {!this.props.remove_alt_text && <div className="row">
                    <div className="col is-full">
                        <label htmlFor="image-alt-text">Alt Text</label>
                        <input type="text"
                            value={this.state.alt_text}
                            onChange={(event) => this.setState({
                                alt_text: event.target.value
                            })}
                            autoComplete="off"
                            name="image-alt-text"
                            id="image-alt-text" />
                    </div>
                </div>}
                {!(this.props.mobile_deep_links == null) && <div>
                    <label htmlFor="deep_link">Deep Link ?</label>
                    <div className="radio__container" id="deep_link">
                        <input
                            onChange={ () => { this.setState({ deep_link: !this.state.deep_link })}}
                            checked={this.state.deep_link}
                            id="deep_link_yes"
                            component={'input'}
                            className="simple__radio"
                            type="radio"
                        />
                        <label htmlFor="deep_link_yes">Yes</label>
                        <input
                            onChange={ () => { this.setState({ deep_link: !this.state.deep_link })}}
                            checked={!this.state.deep_link}
                            id="deep_link_no"
                            component={'input'}
                            className="simple__radio"
                            type="radio"
                        />
                        <label htmlFor="deep_link_no">No</label>
                    </div>
                    <br></br>
                </div>}
                
                {this.props.has_ad_image_url && !this.state.deep_link && <div className="row">
                    <div className="col is-full">
                        <label htmlFor="image-redirect-url">Redirect URL</label>
                        <input type="url"
                            value={this.state.ad_image_url}
                            onChange={(event) => this.setState({
                                ad_image_url: event.target.value,
                                urlError: isUrlFormat(event.target.value)
                            })}
                            autoComplete="off"
                            className={this.state.urlError ? "is-error" : ""}
                            name="image-redirect-url"
                            id="image-redirect-url" />
                        {this.state.urlError &&  <span className={'validation__message is-error'}>{this.state.urlError}</span>}
                    </div>
                </div>}
                {this.state.deep_link && <div className="row">
                    <div className="col is-full">
                        <label>Deep link destination:</label>
                        <Select
                            value={this.state.selected_link}
                            onChange={(items) => this.setState({selected_link: items})}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={ this.state.mobile_deep_links }
                            isMulti={false}
                        />
                    </div>
                </div>}
                {this.props.display_image_name&&<div className="row">
                    <div className="col is-full">
                        <label htmlFor="image-tag">Image Name</label>
                        <input type="text"
                            value={this.props.display_image_name === "mobile_image1_ad_tag" ? this.state.mobile_image1_ad_tag : this.state.mobile_image2_ad_tag }
                            onChange={(event) =>
                                (this.props.display_image_name === "mobile_image1_ad_tag") ?
                                    this.setState({mobile_image1_ad_tag: event.target.value}) :
                                    this.setState({mobile_image2_ad_tag: event.target.value})
                            }
                            autoComplete="off"
                            name="image-tag"
                            id="image-tag" />
                    </div>
                </div>}

                {this.props.errors && <div className="row"> <div className="col is-1-of-2"> <span className={'validation__message is-error'}>{this.props.errors}</span> </div> </div>}
                <div className="row">
                    <div className="col is-1-of-2">
                        <button className="btn fill gray" type={'button'} onClick={() => this.props.closeModal()}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
                        <button className="btn fill blue" disabled={this.state.urlError} type={'submit'} ref={'submit'}
                            onClick={() => {
                                this.props.closeModal({is_deep_link: this.state.deep_link, 
                                                       alt_text: this.state.alt_text,
                                                       ad_image_url: this.state.deep_link ? this.state.selected_link['value'] : 
                                                                                            (this.state.ad_image_url !== "" ? this.state.ad_image_url : null),
                                                       mobile_image1_ad_tag: this.state.mobile_image1_ad_tag,
                                                       mobile_image2_ad_tag: this.state.mobile_image2_ad_tag });
                            }}>Save</button>
                    </div>
                </div>

            </Fragment>
        );
    }
}

export default withKeyPress(EditImageInformationForm);
