import React, {Component, Fragment} from 'react';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import "react-datepicker/dist/react-datepicker.css";
import {
	Field,
	getFormSyncWarnings,
	reduxForm
} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputField} from "../../inputs/InputField";
import moment from "moment";
import {required, biggerThanValue, isFloatNumber} from "../../inputs/validations";
import {checkDifference} from "../../inputs/warnings";
import GolferService from "../../services/api/golfer";
import {DatePickerInput} from "../../inputs/DatePicker";


class ModifyHandicapIndexForm extends Component {

    constructor(props) {
		super(props);

		this.state = {
			type: 'handicapIndex',
			adjustmentType: 'upwards',
			mType: 'modification',
			confirmAction: false
		};

		this.onSubmit = this.onSubmit.bind(this);

	}

	componentDidMount() {
    	this.props.initialize({adjustment: '1.0', low_hi_value: this.props.golfer.low_hi_value})
	}

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));
		delete data.low_hi_value;

		if (this.state.type === "handicapIndex") {

			delete data.adjustment;
			data.type = this.state.mType;
			data.expiration_date =  moment(data.expiration_date).format("YYYY-MM-DD");

			return GolferService.handicapModification(this.props.golfer.id, data)
				.then(res => {
					this.props.addAlert({type: 'success', message: 'The Handicap Index modification has been successfully applied!'});
					this.props.closeModal(res)
				})
				.catch(err => {
					if(err.data.errors && err.data.errors.handicap_index) {
						this.props.addAlert({type: 'error', message: err.data.errors.handicap_index});
						this.props.closeModal()
					}
					console.log(err)
				})

		} else {

			delete data.expiration_date;
			data.adjustment = parseFloat(data.adjustment);
			data.type = this.state.adjustmentType;
			return GolferService.adjustScore(this.props.golfer.id, data)
				.then(res => {
					this.props.addAlert({type: 'success', message: 'The adjustment of score differentials has been successfully requested!'});
					this.props.closeModal(res)
				})
				.catch(err => {
					console.log(err);
				})

		}
	}

	render() {
		const {handleSubmit, valid, submitting} = this.props;

		return (
			<Fragment>

					<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
						{submitting ?
							<table style={{width: '100%'}}>
								<tbody >
								<tr>
									<td style={{textAlign: 'center'}}>
										<span className="data-table__loader"></span>
									</td>
								</tr>
								</tbody>
							</table>
							:
							<Fragment>
								{this.state.confirmAction ?
									<Fragment>
										<h2>Are you sure you want to modify the Handicap Index for {this.props.golfer.player_name}?</h2>
										<div className="row">
											<div className="col is-1-of-2">
												<button type={'button'} onClick={() => this.props.closeModal()} className="btn fill gray">Cancel</button>
											</div>
											<div className="col is-1-of-2">
												<button type={'submit'} ref={'submit'} className="btn fill blue">Confirm</button>
											</div>
										</div>
									</Fragment>
									:
									<Fragment>
										<div className="row">
											<div className="col is-1-of-2">
												<div className="custom-radio__container">
													<Field
														onClick={
															() => {
																this.setState({
																	type: 'handicapIndex',
																});

															}
														}
														checked={this.state.type === "handicapIndex"}
														id="handicapIndex"
														name={'handicapIndex'}
														component={'input'}
														type="radio"
													/>
													<label htmlFor="handicapIndex">Set Handicap Index</label>
												</div>
											</div>

											<div className="col is-1-of-2">
												<div className="custom-radio__container">
													<Field
														onClick={
															() => {
																this.setState({
																	type: 'adjustmentType',
																});
															}
														}
														checked={this.state.type === "adjustmentType"}
														id="adjustmentType"
														name={'adjustmentType'}
														component={'input'}
														type="radio"
													/>
													<label htmlFor="adjustmentType">Adjust Score Differentials</label>
												</div>
											</div>

										</div>

										{this.state.type === "handicapIndex" ?
											<Fragment>
												<div className="row">
													<div className="col is-1-of-2">
														<label htmlFor="value">Modify H.I. <span>*</span></label>
														<Field component={InputField} validate={[required, isFloatNumber]} warn={[checkDifference()]} type="text" name="value" id="value" />
													</div>
													<div className="col is-1-of-2">
														<label htmlFor="expiration_date">Expiration Date <span>*</span></label>
														<Field minDate={new Date(moment().add(1,"days"))}
															   maxDate={new Date(moment().add(1, "years"))}
															   className={'date'}
															   validate={[required]}
															   showMonthDropdown
															   showYearDropdown
															   name={'expiration_date'}
															   id={'expiration_date'}
															   component={DatePickerInput}
															   placeholder="Select ... " />
													</div>
												</div>
												<div className={"row"}>
													<div className="col">
														<Field
															onClick={
																() => {
																	this.setState({
																		mType: this.state.mType === 'modification' ? 'modification-up' : 'modification',
																	});
																}
															}
															checked={this.state.mType === 'modification-up'}
															id="modification"
															name={'modification'}
															component={'input'}
															type="checkbox"
														/>
														<label htmlFor="modification">Modify H.I. against upward movement only.</label>
													</div>
												</div>
												<div className="row">
													<div className="col">{(valid && this.props.warnings) && <span className={'validation__message is-error'}>{this.props.warnings.value}</span>}</div>
												</div>
											</Fragment>
											:
											<div className="row">
												<div className="col is-1-of-2">
													<label htmlFor="adjustment">Adjustment <span>*</span></label>
													<Field component={InputField} validate={[required, biggerThanValue(1)]} type="text" name="adjustment" id="adjustment" />
												</div>
												<div className="col is-1-of-2 jc-fe">
													<div className="radio__container">
														<Field
															onClick={
																() => {
																	this.setState({
																		adjustmentType: 'upwards',
																	});

																}
															}
															checked={this.state.adjustmentType === 'upwards'}
															id="upwards"
															name={'upwards'}
															component={'input'}
															type="radio"
															className={"simple__radio"}
														/>
														<label htmlFor="upwards">upwards</label>

														<Field
															onClick={
																() => {
																	this.setState({
																		adjustmentType: 'downwards',
																	});
																}
															}
															checked={this.state.adjustmentType === "downwards"}
															id="downwards"
															name={'downwards'}
															component={'input'}
															type="radio"
															className={"simple__radio"}
														/>
														<label htmlFor="downwards">downwards</label>
													</div>
												</div>
											</div>
										}

										<div className="row">
											<div className="col is-1-of-2">
												<button type={'button'} onClick={() => this.props.closeModal()} className="btn fill gray">Cancel</button>
											</div>
											<div className="col is-1-of-2">
												<button type={'button'}  disabled={!valid} onClick={() => {this.setState({confirmAction: true}); this.props.confirmModal()}} className="btn fill blue">Save</button>
											</div>
										</div>
									</Fragment>}
							</Fragment>
						}
					</form>

            </Fragment>
		);
	}
}
ModifyHandicapIndexForm  = reduxForm({
	form: 'modifyHandicapIndexForm',
	destroyOnUnmount: true
})(ModifyHandicapIndexForm );

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		warnings: getFormSyncWarnings('modifyHandicapIndexForm')(state),
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(ModifyHandicapIndexForm));
