import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {bindActionCreators} from "redux";
import Modal from 'react-modal';
import {logoutUser} from "../Auth/actions";
import {connect} from "react-redux";
import Cookies from "universal-cookie";
import 'font-awesome/css/font-awesome.min.css';

import LogOutModal from '../Modals/LogOutModal';

class Navigation extends Component {

  constructor(props) {

    super(props);

    this.state = {
      logOutModalIsOpen: false
    };

    this.openLogOutModal = this.openLogOutModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  openLogOutModal() {
    this.setState({logOutModalIsOpen: true});
  }

  closeModal() {
    this.setState({logOutModalIsOpen: false});
  }

	render() {
		const cookies = new Cookies();
		let user_token = cookies.get("token");
		return (
            <Fragment>
                <nav>
                    <div className="container">
                        <ul className="navigation__container">
							{this.props.user_type !== "score-only" && <li><NavLink className="nav__link" to={this.props.homepage} activeClassName="is-active">Manage</NavLink></li>}
							{process.env.REACT_APP_TYPE !== "hv" && <Fragment>
								{(this.props.user_type === "super-admin" || this.props.user_type === "association-admin" || this.props.user_type === "association-score-maintenance" || this.props.user_type === "club-admin" || this.props.user_type === "club-score-maintenance" || this.props.user_type === "score-only" ) && <li><NavLink className="nav__link" to={'/rapid-score-entry'} activeClassName="is-active">Rapid Score Entry</NavLink></li>}
								{(this.props.user_type === "super-admin" || this.props.user_type === "association-admin") &&  <li><NavLink className="nav__link" to={'/merges'} activeClassName="is-active">Merges</NavLink></li>}
								{this.props.user_type !== "score-only" && <li><NavLink className="nav__link" to={'/reports'} activeClassName="is-active">Reports</NavLink></li>}
								{(this.props.user_type === "super-admin" || this.props.user_type === "association-admin" || this.props.user_type === "association-read-only" || this.props.user_type === "association-score-maintenance") && <li><NavLink className="nav__link" to={'/advanced-reports'} activeClassName="is-active">Advanced Reports</NavLink></li>}
								{this.props.user_type !== "score-only" && <li><a className="nav__link" target="_blank" rel="noreferrer" href={'https://usgasupport.zendesk.com/hc/en-us'} >Tools & Resources</a></li>}
							</Fragment>}
							{this.props.upload_user &&  <li><a className="nav__link" target="_blank" rel="noreferrer" href={process.env.REACT_APP_TYPE !== "pilot" ? `http://uploads-staging.hcp2020.com/upload_files?token=${user_token}` : `http://uploads.hcp2020.com/upload_files?token=${user_token}`} >Uploads</a></li>}
							{this.props.user_type === "super-admin" && <li><a className="nav__link" target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_API_URL + `/sso?token=${user_token}&redirect=/user_management`} >User Management</a></li>}
							{/* {(this.props.user_type === "super-admin" || this.props.user_type === "association-admin") && <li><a className="nav__link" target={"_blank"} rel="noreferrer" href={"https://usgasupport.canny.io/"}>Ideas <span><i className="fa fa-lightbulb-o set__bulb__icon"/><span className="empty__space"/></span> </a></li>} */}
              <li className="push__right"><a href="#donothing" className="nav__link" onClick={(e) => {e.preventDefault(); this.openLogOutModal()}}>Log Out</a></li>
                        </ul>
                    </div>
                </nav>

              <Modal
                isOpen={this.state.logOutModalIsOpen}
                onRequestClose={()=>{this.closeModal()}}
                contentLabel="Modal"
                portalClassName="modal"
					      overlayClassName="modal__overlay"
					      className="modal__content"
					      bodyOpenClassName="modal--is-open"
					      htmlOpenClassName="prevent-scroll"
					      shouldCloseOnOverlayClick={true}
					      shouldFocusAfterRender={false}
              >
                <LogOutModal closeModal={()=>{this.closeModal()}} />
              </Modal>

            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		homepage: state.user.access.homepage,
		user_type : state.user.access.user_type,
		upload_user: state.user.access.upload_user,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({logoutUser}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
