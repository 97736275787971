import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {Field, formValueSelector, reduxForm, SubmissionError, getFormSyncErrors, getFormMeta} from "redux-form";
import {connect} from "react-redux";
import moment from 'moment';
import {SelectInput} from "../../../inputs/Select";
import {InputField} from "../../../inputs/InputField";
import {
	isNumber,
	required
} from "../../../inputs/validations";
import SearchPlayer from "./SearchPlayer";
import ScoreService from "../../../services/api/score";
import {DatePickerInput} from "../../../inputs/DatePicker";
import CourseRatingSystemService from "../../../services/api/courseRatingSystem";
import {AsyncSelectInput} from "../../../inputs/AsyncSelectInput";
import debounce from "debounce-promise";
import ClubService from "../../../services/api/club";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import flatten from "lodash.flatten";
import {orderTees} from "../../../services/shared/teesFilter";

class OnePlayerEntry extends Component {

	constructor(props) {
		super(props);

		this.state = {
			startDate: new Date(),
			dateSetup: [
				{value: 'specific', label: 'Specific Date'},
				{value: 'ascending', label: 'Ascending Date'},
				{value: 'descending', label: 'Descending Date'},
			],
			scoreType: [
				{ value: 'H', label: 'Home'},
				{ value: 'A', label: 'Away'},
				{ value: 'T', label: "Competition"}
			],
			holesOptions: [
				{ value: "18", label: '18 (9 + 9)' },
				{ value: "18t", label: '18 Total' },
				{ value: "9", label: '9 Total' },
			],
			courses: [],
			teeSetsOptions: [],
			selectedCourse: {},
			confirmationModalOpen: false,
			savedEntry: null,
			errors: []
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.getCoursesOptions = this.getCoursesOptions.bind(this);
		this.confirmScore = this.confirmScore.bind(this);
		this.frontFieldInput = React.createRef();
		this.backFieldInput = React.createRef();
	}

	onSubmit(values) {

		let data = {
			golfer_id: values.golfer_id,
			course_id: values.course_name.value,
			tee_set_id: values.tee_set_id.value,
			tee_set_side: parseInt(values.number_of_holes.value) === 9 ? values.tee_set_id.tee_set_side : 'All18',
			played_at: moment(values.played_at).format("YYYY-MM-DD"),
			score_type: values.score_type.value,
			number_of_holes: parseInt(values.number_of_holes.value),
			gender: values.gender
		};

		if (values.number_of_holes.value === "18") {
			data.front9_adjusted= values.front9;
			data.back9_adjusted= values.back9;
			return ScoreService.post18h9and9(data)
				.then(response => {
					if (!response.errors) {
						let data = response.score;
						data.golfer_id = values.golfer_id;
						data.score_type_label = values.score_type.label;
						data.course_name = values.course_name.unparsedLabel;
						data.tee_name = values.tee_set_id.label;
						data.number_of_holes_played = values.number_of_holes.label;
						data.front = values.front9;
						data.back = values.back9;
						data.total = values.total;
						data.firstName = values.firstName;
						data.lastName = values.lastName;
						if (response.score.status === 'UnderReview') {
							this.setState({
								savedEntry: data,
								confirmationModalOpen: true,
								errors: []
							});
						} else {
							this.setState({
								errors: []
							});
							this.props.addEntry(data);
							this.props.untouch('front9');
							this.props.untouch('back9');
							this.props.untouch('total');
							this.props.change('front9', null);
							this.props.change('back9', null);
							this.props.change('total', null);

							if (values.date_setup.value === 'ascending') {
								if (!moment(values.played_at).add(1, 'd').isAfter(moment())) {
									let d = moment(values.played_at).add(1, 'd');
									this.props.change('played_at', new Date(d));
								}

							}

							if (values.date_setup.value === 'descending') {
								let d = moment(values.played_at).subtract(1, 'd')
								this.props.change('played_at', new Date(d));
							}
							if (values.number_of_holes.value === '18') {
								document.getElementsByClassName('player-score')[0].getElementsByClassName('front9')[0].focus()
							} else {
								document.getElementsByClassName('player-score')[0].getElementsByClassName('total-score')[0].focus()
							}
						}

					} else {
						throw new SubmissionError(response.errors)
					}

				})
				.catch(err => {
					let errors =  [];
					if (err.data.errors) {
						Object.keys(err.data.errors).forEach(error => {
							if(err.data.errors[error].length > 1) {
								err.data.errors[error].forEach(errorChild => {
									errors.push( error.replace(/_/g, ' ') + ' ' + errorChild);
								})
							} else {
								if (error === 'front9_adjusted' || error === 'back9_adjusted') {
									const errorMrg = `${error.split('_')[0]} score ${err.data.errors[error][0]}`
									errors.push(errorMrg) ;
								} else {
									errors.push(err.data.errors[error][0]) ;
								}
							}
						});

						this.setState({errors});
					}
				})

		} else {

			data.adjusted_gross_score= parseInt(values.total || 0);

			return ScoreService.postAdjusted(data)
				.then(response => {
					if (!response.errors) {
						let data = response.score;
						data.golfer_id = values.golfer_id;
						data.score_type_label = values.score_type.label;
						data.course_name = values.course_name.unparsedLabel;
						data.tee_name = values.tee_set_id.label;
						data.number_of_holes_played = values.number_of_holes.label;
						data.front = values.front9;
						data.back = values.back9;
						data.total = values.total;
						data.firstName = values.firstName;
						data.lastName = values.lastName;
						if (response.score.status === 'UnderReview') {
							this.setState({
								savedEntry: data,
								confirmationModalOpen: true,
								errors: []
							});
						} else {
							this.setState({
								errors: []
							});
							this.props.addEntry(data);
							this.props.untouch('front9');
							this.props.untouch('back9');
							this.props.untouch('total');
							this.props.change('front9', null);
							this.props.change('back9', null);
							this.props.change('total', null);

							if (values.date_setup.value === 'ascending') {
								if (!moment(values.played_at).add(1, 'd').isAfter(moment())) {
									let d = moment(values.played_at).add(1, 'd');
									this.props.change('played_at', new Date(d));
								}

							}

							if (values.date_setup.value === 'descending') {
								let d = moment(values.played_at).subtract(1, 'd')
								this.props.change('played_at', new Date(d));
							}
							if (values.number_of_holes.value === '18') {
								document.getElementsByClassName('player-score')[0].getElementsByClassName('front9')[0].focus()
							} else {
								document.getElementsByClassName('player-score')[0].getElementsByClassName('total-score')[0].focus()
							}
						}

					} else {
						throw new SubmissionError(response.errors)
					}

				})
				.catch(err => {
					let errors =  [];
					if (err.data.errors) {
						Object.keys(err.data.errors).forEach(error => {
							if(err.data.errors[error].length > 1) {
								err.data.errors[error].forEach(errorChild => {
									errors.push( error.replace(/_/g, ' ') + ' ' + errorChild);
								})
							} else {
								errors.push(err.data.errors[error][0]) ;
							}
						});

						this.setState({errors});
					}
				})
		}

	}

	confirmScore() {
		ScoreService.confirmScore(this.state.savedEntry.id, this.state.savedEntry.golfer_id)
			.then(response => {
				this.props.addEntry(this.state.savedEntry);
				this.setState({confirmationModalOpen: false, savedEntry: null});
				this.props.untouch('front9');
				this.props.untouch('back9');
				this.props.untouch('total');
				this.props.change('front9', null);
				this.props.change('back9', null);
				this.props.change('total', null);
				if (this.props.date_setup.value === 'ascending') {
					if (!moment(this.props.played_at).add(1, 'd').isAfter(moment())) {
						let d = moment(this.props.played_at).add(1, 'd');
						this.props.change('played_at', new Date(d));
					}

				}

				if (this.props.date_setup.value === 'descending') {
					let d = moment(this.props.played_at).subtract(1, 'd')
					this.props.change('played_at', new Date(d));
				}

				if (this.props.number_of_holes.value === '18') {
					document.getElementsByClassName('player-score')[0].getElementsByClassName('front9')[0].focus()
				} else {
					document.getElementsByClassName('player-score')[0].getElementsByClassName('total-score')[0].focus()
				}


			})
			.catch(err => {
				this.setState({confirmationModalOpen: false, savedEntry: null});
			})
	}

	getCoursesOptions(searchTerm, callback) {
		if (searchTerm && searchTerm.length > 2) {
			return CourseRatingSystemService.searchCourses(searchTerm)
                .then(response => {
                    let options = response.courses.map(c => {
                        return {value: c.CourseID, label: `${c.FullName} ${c.City && c.State ? `(${c.City}, ${c.State})` : ''}`, unparsedLabel: c.FullName}
                    });
                    this.setState({
						courses: options
					});
                    callback(options)
                })
                .catch(err => {

                })
		} return new Promise(resolve => {
			resolve([])
		})

	}

	getHomeCourses() {
		if (this.props.club_role && this.props.default_club_id) {
			ClubService.getClubHomeCourses(null, this.props.default_club_id)
				.then(async response => {
					let courses = await Promise.all(response.facilities.map(async hc => {
						let courses = await Promise.all(hc.home_courses.map(home_course => {
							let courseName = hc.name + ' - ' + home_course.name + ` (${home_course.city}, ${home_course.state})`;
							return {
								req: CourseRatingSystemService.getCourseDetails(home_course.course_id),
								teeIdMale: home_course['default_tee_male'] && home_course['default_tee_male'].id,
								teeIdFemale: home_course['default_tee_female'] && home_course['default_tee_female'].id,
								courseName: courseName,
								value: home_course.course_id,
								label: courseName,
								courseId: home_course.id
							};
						}).map(async r => {
							let response = await r.req;
							let mensTees = response.TeeSets.filter(ts => ts.Gender === "Male").map(ts => {
								let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
								let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
								let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
								return {
									label: ts.TeeSetRatingName,
									holesNumber: ts.HolesNumber,
									holes: ts.Holes, value: ts.TeeSetRatingId,
									ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
									ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
									ratingBack: back && `${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`,
									ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
								}
							});
							orderTees(mensTees);
							let femalesTees = response.TeeSets.filter(ts => ts.Gender === "Female").map(ts => {
								let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
								let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
								let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
								return {
									label: ts.TeeSetRatingName,
									holesNumber: ts.HolesNumber,
									holes: ts.Holes,
									value: ts.TeeSetRatingId,
									ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
									ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
									ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
									ratingBack: back && `${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`}
							});
							orderTees(femalesTees);

							if (this.props.number_of_holes.value === "9") {
								mensTees = mensTees.reduce((res, current) => {
									let frontPar = current.holes.reduce((acc, h, index) => {
										if (index < 9) return acc + h.Par;
										return acc + 0;
									}, 0);
									let backPar = current.holes.reduce((acc, h, index) => {
										if (index >= 9) return acc + h.Par;
										return acc + 0;
									}, 0);
									let front = current.ratingFront ? {
										label: current.label + ` F9 (${current.ratingFront} / ${frontPar})`,
										unparsedLabel: current.label + ' F9',
										value: current.value,
										tee_set_side: 'F9'
									} : null;
									let back = current.ratingBack ? {
										label: current.label + ` B9 (${current.ratingBack} / ${backPar})`,
										unparsedLabel: current.label + ' B9',
										value: current.value,
										tee_set_side: 'B9'
									} : null;
									if(front && back) {
										return res.concat([front, back])
									}
									if (front) {
										return res.concat([front]);
									}
									if (back) {
										return res.concat([back]);
									}
									return null;
								}, []);
								femalesTees = femalesTees.reduce((res, current) => {
									let frontPar = current.holes.reduce((acc, h, index) => {
										if (index < 9) return acc + h.Par;
										return acc + 0;
									}, 0);
									let backPar = current.holes.reduce((acc, h, index) => {
										if (index >= 9) return acc + h.Par;
										return acc + 0;
									}, 0);
									let front = current.ratingFront ? {
										label: current.label + ` F9 (${current.ratingFront} / ${frontPar})`,
										unparsedLabel: current.label + ' F9',
										value: current.value,
										tee_set_side: 'F9'
									} : null;
									let back = current.ratingBack ? {
										label: current.label + ` B9 (${current.ratingBack} / ${backPar})`,
										unparsedLabel: current.label + ' B9',
										value: current.value,
										tee_set_side: 'B9'
									} : null;
									if(front && back) {
										return res.concat([front, back])
									}
									if (front) {
										return res.concat([front]);
									}
									if (back) {
										return res.concat([back]);
									}
									return null;
								}, []);
							} else {
								mensTees = mensTees.filter(ts => ts.holesNumber !== 9).map(ts => {
									let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
									return {label: `${ts.label} (${ts.ratingTotal} / ${totalPar})`, value: ts.value}
								});

								femalesTees = femalesTees.filter(ts => ts.holesNumber !== 9).map(ts => {
									let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
									return {label: `${ts.label} (${ts.ratingTotal} / ${totalPar})`, value: ts.value}
								});
							}
							delete r.req;
							r.maleTees = mensTees;
							r.femaleTees = femalesTees;
							return r;
						}));
						return flatten(courses);
					}));
					let flattenCourses = flatten(courses);
					let defaultCourse = flattenCourses[0];
					this.setState({
						courses: defaultCourse,
					}, () => {
						if (defaultCourse) {
						this.props.change('course_name', defaultCourse);
						if (this.props.golfer_gender) {
							if (this.props.golfer_gender === 'M') {
								this.setState({
									teeSetsOptions: defaultCourse.maleTees
								}, () => {
									let defaultMenTee = defaultCourse.maleTees.filter(tee => parseInt(tee.value) === parseInt(defaultCourse.teeIdMale));
									this.props.change('tee_set_id', defaultMenTee[0]);
								});

							} else {
								this.setState({
									teeSetsOptions: defaultCourse.femaleTees
								}, () => {
									let defaultFemaleTee = defaultCourse.femaleTees.filter(tee => parseInt(tee.value) === parseInt(defaultCourse.teeIdFemale));
									this.props.change('tee_set_id', defaultFemaleTee[0]);
								})
							}
						}
						}
					});
				})
				.catch(err => {
					console.error(err);
				})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.golfer_id !== this.props.golfer_id) {
			this.getHomeCourses()
		}
	}


	render() {
		let {handleSubmit} = this.props;
		let self = this;
		let validateFrontBack = !(!this.props.tee_set_id || !this.props.initialized || (this.props.number_of_holes && ((this.props.number_of_holes.value === "18t") || (this.props.number_of_holes.value === "9"))));
		return (
			<Fragment>
				<SearchPlayer />
				<form className={'player-score'} onSubmit={handleSubmit(this.onSubmit)}>
					<Field component={InputField} type="hidden" autoComplete="off" id="golfer_id" name="golfer_id" placeholder="Course Name" />
					<Field component={InputField} type="hidden" autoComplete="off" id="gender" name="gender" placeholder="Course Name" />
					<Field component={InputField} type="hidden" autoComplete="off" id="firstName" name="firstName" placeholder="Course Name" />
					<Field component={InputField} type="hidden" autoComplete="off" id="lastName" name="lastName" placeholder="Course Name" />
					<Field component={InputField} type="hidden" name={'date_setup'} id="date_setup" placeholder="Course Name"/>

					<div className="row">
						<div className="col">
							<label htmlFor="played_at">Date played</label>
							<Field hideErrorMessages maxDate={new Date()} showMonthDropdown showYearDropdown className={'date'} id={"played_at"} onChange={() => {this.setState({errors: []})}}  name={"played_at"} component={DatePickerInput} disabled={!this.props.initialized} placeholder="Select ... " />
						</div>
						<div className="col is-1-of-7">
							<label>Posting Method</label>
							<Field onChange={(event, newValue, prevValue) => {
								if (prevValue) {
									if (prevValue.value === "18" && newValue.value === "9") {
										this.props.change('front9', null);
										this.props.change('back9', null);
									}
									if (prevValue.value === "18" && newValue.value === "18t") {
										this.props.change('front9', null);
										this.props.change('back9', null);
									}
									if ((prevValue.value === '18t' || prevValue.value === '9') && newValue.value === '18') {
										this.props.untouch('front9');
										this.props.untouch('back9');
									}
								}
								if (newValue) {
									if (newValue.value === "9") {
										if (this.props.score_type) {
											if (this.props.score_type.value && !(this.props.score_type.value === 'H' || this.props.score_type.value === 'A')) {
												this.props.change('score_type', null);
											}
										}
										this.setState({
											scoreType: [
												{value: 'H', label: 'Home'},
												{value: 'A', label: 'Away'},
												{value: 'T', label: "Competition"},
											]
										})
									} else {
										if (newValue.value === "18t") {
											this.setState({
												scoreType: [
													{value: 'H', label: 'Home'},
													{value: 'A', label: 'Away'},
													{value: 'T', label: "Competition"},
												]
											});
										} else {
											if (this.props.score_type) {
												if (this.props.score_type.value && !(this.props.score_type.value === 'H' || this.props.score_type.value === 'A' || this.props.score_type.value === 'T')) {
													this.props.change('score_type', null);
												}
											}
											this.setState({
												scoreType: [
													{value: 'H', label: 'Home'},
													{value: 'A', label: 'Away'},
													{value: 'T', label: "Competition"},
												]
											});
										}

									}
									if (this.props.course_name) {
										CourseRatingSystemService.getCourseDetails(this.props.course_name.value)
											.then(response => {
												let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.props.golfer_gender).map(ts => {
													let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
													let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
													let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
													return {
														label: ts.TeeSetRatingName,
														holesNumber: ts.HolesNumber,
														holes: ts.Holes, value: ts.TeeSetRatingId,
														ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
														ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
														ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
														ratingBack: back && `${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`}
												});
												orderTees(filteredTS);
												if (this.props.number_of_holes.value === "9") {
													filteredTS = filteredTS.reduce((res, current) => {
														let frontPar = current.holes.reduce((acc, h, index) => {
															if (index < 9) return acc + h.Par;
															return acc + 0;
														}, 0);
														let backPar = current.holes.reduce((acc, h, index) => {
															if (index >= 9) return acc + h.Par;
															return acc + 0;
														}, 0);
														let front = current.ratingFront ? {
															label: current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`,
															unparsedLabel: current.label + ' F9',
															value: current.value,
															tee_set_side: 'F9'
														} : null;
														let back = current.ratingBack ? {
															label: current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`,
															unparsedLabel: current.label + ' B9',
															value: current.value,
															tee_set_side: 'B9'
														} : null;
														if(front && back) {
															return res.concat([front, back])
														}
														if (front) {
															return res.concat([front]);
														}
														if (back) {
															return res.concat([back]);
														}
														return null;
													}, []);
													if (this.props.tee_set_id) {
														let selectedTee = filteredTS.filter(f => f.value === this.props.tee_set_id.value);
														this.props.change('tee_set_id', selectedTee[0])
													}
												} else {
													filteredTS = filteredTS.filter(ts => ts.holesNumber !== 9).map(ts => {
														let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
														let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
														return {label: label, value: ts.value}
													});
													if (this.props.tee_set_id) {
														let selectedTee = filteredTS.filter(f => f.value === this.props.tee_set_id.value);
														this.props.change('tee_set_id', selectedTee[0])
													}
												}
												this.setState({
													teeSetsOptions: filteredTS,
													errors: []
												});
											})
									}
								}
							}} name={"number_of_holes"} component={SelectInput} disabled={!this.props.initialized} className="react-select-container" classNamePrefix="react-select" options={this.state.holesOptions} isSearchable={false} />
						</div>
						<div className="col is-1-of-9">
							<label>Score Type</label>
							<Field name={"score_type"} component={SelectInput} disabled={!this.props.initialized || !this.props.number_of_holes} className="react-select-container" classNamePrefix="react-select" options={this.state.scoreType} isSearchable={false} />
						</div>

						<div className="col is-2-of-10">
							<label htmlFor="course_name">Course</label>
							<Field onChange={(event, newValue, prevValue) => {
										if (newValue) {
											CourseRatingSystemService.getCourseDetails(newValue.value)
												.then(response => {
													//aici
													let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.props.golfer_gender).map(ts => {
														let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
														let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
														let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
														return {
															label: ts.TeeSetRatingName,
															holes: ts.Holes,
															holesNumber: ts.HolesNumber,
															value: ts.TeeSetRatingId,
															ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
															ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
															ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
															ratingBack: back && `${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`}
													});
													orderTees(filteredTS);
													if (this.props.number_of_holes.value === "9") {
														filteredTS = filteredTS.reduce((res, current) => {
															let frontPar = current.holes.reduce((acc, h, index) => {
																if (index < 9) return acc + h.Par;
																return acc + 0;
															}, 0);
															let backPar = current.holes.reduce((acc, h, index) => {
																if (index >= 9) return acc + h.Par;
																return acc + 0;
															}, 0);
															let front = current.ratingFront ? {
																label: current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`,
																unparsedLabel: current.label + ' F9',
																value: current.value,
																tee_set_side: 'F9'
															} : null;
															let back = current.ratingBack ? {
																label: current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`,
																unparsedLabel: current.label + ' B9',
																value: current.value,
																tee_set_side: 'B9'
															} : null;
															if(front && back) {
																return res.concat([front, back])
															}
															if (front) {
																return res.concat([front]);
															}
															if (back) {
																return res.concat([back]);
															}
															return null;
														}, []);
													} else {
														filteredTS = filteredTS.filter(ts => ts.ratingTotal !== undefined).filter(ts => ts.holesNumber !== 9).map(ts => {
															let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
															let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
															return {label: label, value: ts.value}
														});
													}
													this.props.change('tee_set_id', null);
													this.setState({
														teeSetsOptions: filteredTS,
														errors: []
													})
												})
										} else {
											this.props.change('tee_set_id', null);
											this.setState({
												teeSetsOptions: [],
												errors: []
											})
										}
									}}
								   defaultOptions={this.state.courses}
								   loadOptions={debounce(self.getCoursesOptions, 1000)}
								   disabled={!this.props.initialized || !this.props.score_type}
								   component={AsyncSelectInput}
								   searchable={true}
								   type="text"
								   autoComplete="off"
								   validate={[required]}
								   hideErrorMessages={true}
								   id="course_name"
								   name="course_name"
								   placeholder="Enter Course Name"
									 grayPlaceholder={true}
									  />
						</div>
						<div className="col">
							<label>Tee (C.R. / Slope / Par)</label>
							<Field name={'tee_set_id'}
								   component={SelectInput}
								   disabled={!this.props.initialized || !this.props.course_name}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   placeholder="Men's Tees"
								   options={this.state.teeSetsOptions}
								   isSearchable={false}
								   validate={[required]}
								   hideErrorMessages={true}
							/>
						</div>

						<div className="col is-1-of-16">
							<label htmlFor="front9">Front 9</label>
							<Field className={'front9'} hideErrorMessages validate={validateFrontBack ? [isNumber, required] : undefined} onChange={(event, newValue, prevValue) => {
								if (newValue.length === 1) {
									if (parseInt(newValue) === 0) {
										event.preventDefault();
										return;
									}

									let backValue = parseInt(this.props.back) || 0;
									this.props.change('total', backValue + parseInt(newValue));
								}
								if (newValue.length > 2) {
									event.preventDefault();
									return;
								}
								if(newValue.length === 2) {
									let backValue = parseInt(this.props.back) || 0;
									this.props.change('total', backValue + parseInt(newValue));
									document.getElementsByClassName('back9')[0].focus();
								}
							}} component={InputField} disabled={!this.props.tee_set_id || !this.props.initialized || (this.props.number_of_holes && ((this.props.number_of_holes.value === "18t") || (this.props.number_of_holes.value === "9")))} type="text" autoComplete="off" id="front9" name="front9" placeholder="Front 9" />
						</div>
						<div className="col is-1-of-16">
							<label htmlFor="back9">Back 9</label>
							<Field hideErrorMessages validate={validateFrontBack ? [isNumber, required] : undefined} onChange={(event, newValue, prevValue) => {
								if (newValue.length === 1) {
									if (parseInt(newValue) === 0) {
										event.preventDefault();
										return;
									}
									let frontValue = parseInt(this.props.front) || 0;
									this.props.change('total', frontValue + parseInt(newValue));
								}
								if (newValue.length > 2) {
									event.preventDefault();
								}
								if(newValue.length === 2) {
									let frontValue = parseInt(this.props.front) || 0;
									this.props.change('total', frontValue + parseInt(newValue));
								}

							}} className={'back9'} component={InputField} disabled={!this.props.tee_set_id || !this.props.initialized || (this.props.number_of_holes && ((this.props.number_of_holes.value === "18t") || (this.props.number_of_holes.value === "9")))} type="text" autoComplete="off" id="back9" name="back9" placeholder="Back 9" />
						</div>
						<div className="col is-1-of-16">
							<label htmlFor="total">Total</label>
							<Field hideErrorMessages onChange={(event, newValue, prevValue) => {
										if (this.props.number_of_holes === "9") {
											if (newValue.length > 2) event.preventDefault();
										} else {
											if (newValue.length > 3) event.preventDefault();
										}
									}}
								   validate={[isNumber]}
								   component={InputField}
								   disabled={!this.props.tee_set_id || !this.props.initialized || (this.props.number_of_holes && ((this.props.number_of_holes.value === "18")))}
								   type="text"
								   autoComplete="off"
								   id="total"
								   name="total"
								   className={'total-score'}
								   placeholder="Total" />
						</div>
					</div>
					<div className="row">
						<div className="col ">
							{this.state.errors.length > 0 && this.state.errors.map(error=> {
								return (<p className={'validation__message is-error'}>{error.charAt(0).toUpperCase() + error.substring(1)}</p>);
							})
							}
							{this.props.errors && Object.keys(this.props.errors).map(err => {
								switch(err){
									case 'total':
										if (this.props.metas && this.props.metas.total && this.props.metas.total.touched) {
											if ((this.props.number_of_holes.value === "18t" || this.props.number_of_holes.value === "9" ) && this.props.tee_set_id) {
												return ( <p className="validation__message is-error">{this.props.errors[err]}</p>);
											} else {
												return null;
											}
										} else {
											return null;
										}
									case 'front9':
										if (this.props.metas && this.props.metas.front9 && this.props.metas.front9.touched) {
											if (!this.props.front) {
												return ( <p className="validation__message is-error">Please enter your score for the Front 9.</p>);
											} else {
												return ( <p className="validation__message is-error">{'The front ' + this.props.errors.front9}</p>);
											}
										} else {
											return null;
										}
									case 'back9':
										if (this.props.metas && this.props.metas.back9 && this.props.metas.back9.touched) {
											if (!this.props.back) {
												return ( <p className="validation__message is-error">Please enter your score for the Back 9.</p>);
											} else {
												return (
													<p className="validation__message is-error">{'The back ' + this.props.errors.back9}</p>);
											}
										} else {
											return null;
										}
									case 'course_name':
										if (this.props.metas && this.props.metas.course_name && this.props.metas.course_name.touched) {
											return (<p className="validation__message is-error">Please select a course.</p>);
										} else {
											return null;
										}
									case 'tee_set_id':
										if (this.props.metas && this.props.metas.tee_set_id && this.props.metas.tee_set_id.touched) {
											return (<p className="validation__message is-error">Please select Tees.</p>);
										} else {
											return null;
										}
									default:
										if (this.props[err]) {
											return ( <p className="validation__message is-error">{this.props.errors[err]}</p>);
										} else {
											return null;
										}
									}
								})
							}
						</div>
						<div className="col is-1-of-8 push-right">
							<button disabled={!this.props.initialized || !this.props.golfer_id || (!this.props.tee_set_id)} className="btn fill blue">Post Score</button>
						</div>
					</div>
				</form>

				<ConfirmationModalTypeB
					openModal={this.state.confirmationModalOpen}
					onConfirmAction={() => this.confirmScore()}
					modalIcon={'help_outline'}
					onCancelAction={() => {
						this.setState({
							confirmationModalOpen: false,
							savedEntry: null
						})
					}}
					closeModal={() => {
						this.setState({
							confirmationModalOpen: false,
							savedEntry: null
						})
					}}
					question={"Duplicate Score Found"}
					bodyQuestion={"This score may already exist in the golfers score history."}
					infoText={['Press "Continue" to post the score anyway', 'Press "Cancel" to review the score prior to posting']}
					cancelLabel={"Cancel"}
					confirmLabel={"Continue"}
				/>
			</Fragment>
		);
	}

}

OnePlayerEntry = reduxForm({
	form: 'playerEntryForm',
	destroyOnUnmount: true
})(OnePlayerEntry);

function mapStateToProps(state) {

	const selector = formValueSelector('playerEntryForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		course_name: selector(state, 'course_name'),
		golfer_gender: selector(state, 'gender'),
		number_of_holes: selector(state, 'number_of_holes'),
		score_type: selector(state, 'score_type'),
		tee_set_id: selector(state, 'tee_set_id'),
		date_setup: selector(state, 'date_setup'),
		total: selector(state, 'total'),
		front: selector(state, 'front9'),
		back: selector(state, 'back9'),
		played_at: selector(state, 'played_at'),
		golfer_id: selector(state, 'golfer_id'),
		errors: getFormSyncErrors('playerEntryForm')(state),
		metas: getFormMeta('playerEntryForm')(state),
		is_club_admin: state.user.access.club_role === 'admin',
		default_club_id: state.user.access.default_club_id,
		club_role: state.user.access.club_role,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}



export default connect(mapStateToProps, mapDispatchToProps)(OnePlayerEntry);
