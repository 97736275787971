import React, {Component} from 'react';

export default class Shared extends Component {

	render() {
		return (
			<iframe title="Shared reports" width="100%" height="1000px" src={this.props.iframeUrl} />
		);
	}

}
