import React, { Component, Fragment } from 'react';
import GeneratedDigitalProfileLinkForm from '../Forms/GeneratedDigitalProfileLinkForm'

export default class UserCreatePasswordLinkModal extends Component {

  render() {
    return (
      <Fragment>

        <div className="modal__container narrow">

          <div className="modal__head">

            <h2 className="modal__title">Generate Create Password Link</h2>
            <button className="modal__close" onClick={() => { this.props.closeModal() }}><i className="material-icons-outlined">clear</i></button>

          </div>

          <div className="modal__body">

            <div className="row">
              <div className="col">
                Copy this link and share it with the user so they can set their password.<br /><strong>This link is valid for 24 hours if another link is not generated in the meantime.</strong>
              </div>
            </div>

            <GeneratedDigitalProfileLinkForm generatedUrl={this.props.generatedUrl} close={() => this.props.closeModal()} />

          </div>

        </div>

      </Fragment>
    );
  }
}
