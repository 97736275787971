import GhinTable from "../../../shared/GhinTable";

export function UpdateTransactionFee(props) {
  
  
  const columns = [
    {
      Header: 'Page Name',
      accessor: 'name'
    },
    {
      Header: 'Association Page URL',
      Cell: data => {
        console.log(data);
        return (
          <div>
            <p>{data.original.url}</p>
          </div>
        )
      }
    }
  ];
  
  const affectedJoinPagesBody = () => {
    if (props.affectedJoinPages.length > 0)
      return (<div style={{paddingTop: '10px'}}>
        <GhinTable
          columns={columns}
          hideSelect
          hidePagination={true}
          data={props.affectedJoinPages}
          sortable={false}
        />
      </div>)
  }
  
  const affectedJoinPagesText = () => {
    if (props.affectedJoinPages.length > 0) {
      let text = `${props.affectedJoinPages.length} join pages`;
      if (props.affectedJoinPages.length === 1)
         text = `${props.affectedJoinPages.length} join page`
      return (<p>This transaction fee has been successfully updated for {text}. To export the records for review, use the button below.</p>)
    }
  }

  const notAffectedJoinPagesText = () => {
    if (props.affectedJoinPages.length === 0)
      return (<div>
        <p>This transaction fee is not used by any join page.</p>
      </div>)
  }

  const close = () => {
    props.closeModal();
    props.reloadTransactionFees();
  }
  
  let content = <div>
    {affectedJoinPagesText()}
    {affectedJoinPagesBody()}
    {notAffectedJoinPagesText()}
  </div>;
  
  if (props.loadingAffectedJoinPages)
    content = <span className="data-table__loader" style={{ marginLeft: "calc(50% - 60px)", marginBottom: '25px'}}></span>;  
  
  return (
    <div>
      {content}
      <div className="row">
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill gray" onClick={props.handleDownload} disabled={props.affectedJoinPages.length === 0}>Export Records For Review</button>
        </div>
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill blue" onClick={close}>ok</button>
        </div>
      </div>
    </div>
  )
}
