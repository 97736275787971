import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { addAlert } from "../shared/actions";
import { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import HtmlEditor from "../shared/HtmlEditor";
import CreateProfileImage from "../../img/create_profile.png"
import SentryLogo from "../../img/sentry_01.png"
import JoinAndRenewServices from "../../services/api/joinAndRenew";

function EditJoinPageCustomizationForm(props) {
  const [submitting, set_submitting] = useState(false)
  const [loading, set_loading] = useState(true)
  const [data, set_data] = useState({
    name: "",
    header_image: "",
    header_text: "",
    description: "",
    zip_code_search: false,
    club_name_search: false,
    digital_profile: false,
    hide_logo: false
  })

  const image = typeof data.header_image === typeof {} ? URL.createObjectURL(data.header_image) : data.header_image
  const imageRef = useRef()
  const handleClick = () => {
    imageRef.current.click();
  };

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    set_data({
      ...data,
      header_image: fileObj
    })
  };

  const submit = useCallback(() => {
    const join_page = props.join_page
    const add_alert = props.addAlert
    const close_modal = props.close_modal

    set_submitting(true)
    var form_data = new FormData();

    for (var key in data) {
      if ((key === "header_image")) {
        if (typeof data.header_image === typeof {} && data.header_image !== null) {
          form_data.append(key, data[key], data[key].name);
        }
      }
      else {
        form_data.append(key, data[key]);
      }
    }
    JoinAndRenewServices.updateJoinPage(
      join_page.golf_association_id,
      join_page.id,
      form_data
    ).then((res) => {
      add_alert({
        type: 'success',
        message: 'Join Page successfully updated'
      })
      close_modal()
    }).catch((e) => {
      set_submitting(false)
      var error = ""
      try {
        key = Object.keys(e.data.errors)[0]
        error = e.data.errors[key][0]
      } catch {
        error = "An error has occurred"
      }
      props.addAlert({
        type: 'error',
        message: error
      })
      console.log(e)
    })
  }, [data, props.join_page, props.addAlert, props.close_modal])

  useEffect(() => {
    set_loading(true)
    const association_id = props.join_page.golf_association_id
    const page_id = props.join_page.id
    JoinAndRenewServices.getJoinPage(association_id, page_id).then(res => {
      set_data({ ...res.join_page })
      set_loading(false)
    })
  }, [props.join_page.golf_association_id, props.join_page.id])

  if (loading)
    return (
      <td colSpan={9} style={{ padding: "10px" }}>
        <div className="row centered-items">
          <div className="col auto">
            <div className={'loader'}>
              <span className="data-table__loader"></span>
            </div>
          </div>
        </div>
      </td>
    )

  return (
    <div>
      <div className="row">
        <div className="col">
          <strong>
            <span className="underline">Header Image</span>
          </strong>
          <p>
            The header image will appear centered at the top of this join page. You are able to add a custom image for each join page you create. Otherwise, the logo found in the association account section will be used as the default.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>
            Required Format = PNG, JPG
            <br />
            Maximum Height is 150 px.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>
            Any images uploaded with an image height greater than 100 px will be resized proportionately.
          </p>
        </div>
      </div>


      <>
        {
          image ?
            <>
              <div className="row centered-items padding-10-y">
                <div className="col auto form__image-preview">
                  <img
                    alt=""
                    src={image}
                  />
                </div>
                <div className="col auto" style={{paddingTop: "12px"}}>
                  <input
                    type="file"
                    accept={"image/png, image/jpg, image/jpeg"}
                    className={"hidden"}
                    ref={imageRef}
                    onChange={handleFileChange}
                  />
                  <button
                    onClick={handleClick}
                    className={"btn lnk"}
                    style={{width: "fit-content", marginBottom: "8px"}}
                  >
                    Edit
                  </button>
                  <input
                    id="hide_logo"
                    type="checkbox"
                    checked={data.hide_logo}
                    onChange={() => {
                      set_data({
                        ...data,
                        hide_logo: !data.hide_logo
                      })
                    }}     
                  />
                  <label htmlFor="hide_logo">Hide Logo from Association Join Page</label>
                  <span className="bold header-image-note">Note: The Logo will still display on Club Join Pages.</span>
                </div>
              </div>
            </>
            :
            <>
              <div className="row centered-items">
                <div className="col auto">
                  <input
                    type="file"
                    accept={"image/png, image/jpg, image/jpeg"}
                    className={"hidden"}
                    ref={imageRef}
                    onChange={handleFileChange}
                  />
                  <button
                    onClick={handleClick}
                    className={"btn lnk"}
                  >Add Header Image
                  </button>
                </div>
              </div>
            </>
        }
      </>

      <hr />

      <div className="row hr-margin-24-top">
        <div className="col">
          <strong>
            <span className="underline">Header Text</span>
          </strong>
          <p>
            The Join Page Header will be displayed under the header image.
          </p>
        </div>
      </div>
      <div className="row hr-margin-24-bottom">
        <div className="col is-full" >
          <HtmlEditor
            html={data.header_text}
            onChange={(e) => set_data({
              ...data,
              header_text: e.target.value
            })}
          />
        </div>
      </div>

      <hr />

      <div className="row hr-margin-24-top">
        <div className="col">
          <strong>
            <span className="underline">Page Description</span>
          </strong>
          <p>
            The Page Description will be displayed under the page header.
          </p>
        </div>
      </div>
      <div className="row hr-margin-24-bottom">
        <div className="col is-full">
          <HtmlEditor
            html={data.description}
            onChange={(e) => set_data({
              ...data,
              description: e.target.value
            })}
          />
        </div>
      </div>

      <hr />

      <div className="row hr-margin-24-top">
        <div className="col">
          <strong>
            <span className="underline">Club Search</span>
          </strong>
          <p>
            How would you like potential members to be able to search for a club using this join page?
          </p>
        </div>
      </div>
      <div className="row hr-margin-24-bottom">
        <div className="col">
          <div className="radio__container middle hr-margin-24-bottom">
            <input
              onChange={() => set_data({
                ...data,
                club_name_search: true,
                zip_code_search: true
              })}
              checked={data.club_name_search && data.zip_code_search}
              id="name_or_zip"
              component={'input'}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="name_or_zip">Club Name or Zip Code</label>
            <input
              onChange={() => set_data({
                ...data,
                club_name_search: true,
                zip_code_search: false
              })}
              checked={data.club_name_search && !data.zip_code_search}
              id="name"
              component={'input'}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="name">Club Name Only</label>
            <input
              onChange={() => set_data({
                ...data,
                club_name_search: false,
                zip_code_search: true
              })}
              checked={!data.club_name_search && data.zip_code_search}
              id="zip"
              component={'input'}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="zip">Zip Code Only</label>
          </div>
        </div>
      </div>

      <div className="border_box hr-margin-26-bottom hr-margin-24-top">
        <div className="row hr-margin-24-top">
          <div className="col">
            <strong>
              Include "Create your Profile"
            </strong>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              Add a quick way for a golfer to create their profile once they have completed registration.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              This information will be exposed to the golfer once they have successfully submitted their registration form.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              This information will also be added to the email they recieve once registration is complete.
            </p>
          </div>
        </div>
        <div className="row centered-items">
          <div className="col">
            <div className="center-join-pages-logos">
              <input
                id="digital_profile"
                label="test"
                type="checkbox"
                checked={data.digital_profile}
                onChange={(e) => set_data({
                  ...data,
                  digital_profile: !data.digital_profile
                })}
              />
              <label htmlFor={"digital_profile"}>Include "Create your Profile"</label>
            </div>
          </div>
        </div>
        <div className="row centered-items">
          <div className="col is-3-of-5">
            <div className="border_box hr-margin-24-bottom">
              <div className="hr-margin-24-top">
                <img
                  src={CreateProfileImage}
                  alt=""
                  className={"fit_image_webkit"}
                />
              </div>
              <div className="center-join-pages-logos hr-margin-24-bottom">
                <div>
                  <span
                    role="link"
                    tabIndex={0}
                    className="usga_logo border-focus"
                    aria-label={"USGA, Golf Handicap Information Network"}
                  >
                    golf handicap <br /> information network
                  </span>
                </div>
                <div>
                  <img
                    src={SentryLogo}
                    alt=""
                    className="join-pages-sentry-logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill gray" onClick={() => props.close_modal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type={'submit'} disabled={submitting} onClick={submit} className="btn fill blue">Save</button>
        </div>
      </div>
    </div >
  )
}
function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditJoinPageCustomizationForm))
