import request from '../shared/request';

export default class UsersService {
	static get(clubId, associationId, federationId) {
		return request({
			url: '/users.json',
			method: 'GET',
			params: {
				club_id: clubId,
				association_id: associationId,
				federation_id: federationId
			}
		})
	}

	static search(email = null, associationId = 0, clubId) {
		return request({
			url: `/users/search.json?association_id=${associationId}${clubId && '&club_id=' + clubId }&email=${email}`,
			method: 'GET'
		})
	}

	static admin_user_search(params) {
		return request({
			url: `/users/search.json`,
			method: 'GET',
			params
		})
	}

	static accesses() {
		return request({
			url: '/users/accesses.json',
			method: 'GET',
		})
	}

	static insertAccess(data) {
		return request({
			url: '/users/accesses.json',
			method: 'POST',
			data
		})
	}

  static deleteAccess(id){
    return request({
      url: `/users/accesses/${id}`,
      method: 'DELETE',
    })
  }

	static insert(data) {
		return request({
			url: '/users.json',
			method: 'POST',
			data
		})
	}

	static update(data, id) {
		return request({
			url: `/users/accesses/${id}.json`,
			method: 'PATCH',
			data
		})
	}
	static reinvite (user_access_id, callback) {
		return request({
			url: `/users/accesses/${user_access_id}/reinvite.json?callback_url=${callback}`,
			method: 'POST'
		})
	}

  static generateCreatePasswordLink(user_id) {
    return request({
      url: `users/accesses/${user_id}/generate_set_password_url.json`,
      method: 'GET'
    })
  }

	static emails() {
		return request({
			url: '/users/emails.json',
			method: 'GET',
		})
	}

	static currentUser() {
		return request({
			url: '/users/current_user.json',
			method: 'GET',
		})
	}

}
