import request from '../shared/request';

export default class ScoreService {
	static postAdjusted(data) {
		return request({
			url: `/scores/adjusted.json`,
			method: 'POST',
			data
		})
	}

	static post18h9and9(data) {
		return request({
			url: `/scores/18h9and9.json`,
			method: 'POST',
			data
		})
	}

	static editScore(data, id) {
		return request({
			url: `/scores/${id}/update.json`,
			method: 'PATCH',
			data
		})
	}

	static postHoleByHole(data) {
		return request({
			url: 'scores/hbh.json',
			method: 'POST',
			data: data
		})
	}

	static confirmScore(id, ghinNumber) {
		return request({
			url: `/scores/${id}/confirm.json?golfer_id=${ghinNumber}`,
			method: 'POST'
		})
	}

	static editHoleByHole(id, data) {
		return request({
			url: `scores/hbh/${id}.json`,
			method: 'PATCH',
			data: data
		})
	}

	static deleteScore(uniqueId, score) {
		return request({
			url: `/scores/${uniqueId}.json`,
			method: "DELETE",
			data: {scores: score}
		})
	}

	static exportToCSV(params) {
		return request({
			url: '/scores/export_to_excel.json',
			method: 'GET',
			params
		})
	}

	static getAll(params) {
		return request({
			url: `/scores/get_all.json`,
			params
		})
	}

	static getChildScores(id, params) {
		return request({
			url: `/scores/${id}/get_child_scores.json`,
			params
		})
	}

	static getOverrideSatus(id) {
		return request({
			url: `/scores/${id}/esr_override_status`,
			method: 'GET'
		})
	}

	static overrideScore(id){
		return request({
				url: `/scores/${id}/perform_esr_override`,
				method: 'POST'
			})
	}

	static getLogs(id) {
		return request({
			url: `/scores/${id}/logs.json`,
			method: 'GET',
		})
	}

  static getHandicapRevisionScores(handicap_revision_id, params) {
		return request({
      url: `scores/handicap_review/${handicap_revision_id}.json`,
			method: 'GET',
      params
		})
	}
}
