import { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { compose } from "redux";
import Setup from "./setup";
import JoinPages from "./JoinPages/index"
import AddOnCharges from "./AddOnCharges";
import TransactionFees from "./TransactionFees";
import ClubManagement from "./ClubManagement";
import Emails from "./Emails";
import Transactions from "./Transactions";
import TermsAndPrivacy from "./TermsAndPolicy";
import {addAlert} from "../../../shared/actions";
import {bindActionCreators} from "redux";
import Offers from "./Offers/Offers";

function JoinAndRenew(props) {
  return (
    <Fragment>
      <div className="vertical-tabs__container row">
        <ul className="vertical-tabs col">
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/setup`}>Commerce Setup</NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/transaction-fees`}>Transaction Fees</NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/terms-and-privacy`} style={{height: '60px'}}>
            <div>
              <p>Terms & Conditions</p>
              <p>Privacy Policy</p>
            </div>
          </NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/club-membership-fees`}>Club Management</NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/join-pages`}>Manage Join Pages</NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/add-ons`} style={{height: '60px'}}>
            <div>
              <p>Add-Ons</p>
              <strong className="medium">(optional)</strong>
            </div>
          </NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/offers`} style={{height: '60px'}}>
            <div>
              <p>Offers</p>
              <strong className="medium">(optional)</strong>
            </div>
          </NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/emails`}>Emails</NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/transactions`}>Transactions</NavLink></li>
        </ul>
        <div className="vertical-tabs__panel col">
          <Switch>
            <Redirect exact from={`${props.match.url}`} to={`${props.match.url}/setup`} />
            <Route path={`${props.match.path}/setup`} render={() =>
              <Setup
                {...props}
              />} />
            <Route path={`${props.match.path}/transaction-fees`} render={() =>
              <TransactionFees
                {...props}
              />} />
		    <Route path={`${props.match.path}/terms-and-privacy`} render={() =>
			  <TermsAndPrivacy
			    {...props}
			  />} />
            <Route path={`${props.match.path}/join-pages`} render={() =>
              <JoinPages
                {...props}
              />} />
            <Route path={`${props.match.path}/add-ons`} render={() =>
              <AddOnCharges
                {...props}
              />} />
            <Route path={`${props.match.path}/offers`} render={() =>
              <Offers
                {...props}
              />} />
            <Route path={`${props.match.path}/club-membership-fees`} render={() =>
              <ClubManagement
                {...props}
              />} />
            <Route path={`${props.match.path}/emails`} render={() =>
              <Emails
                {...props}
              />} />
            <Route path={`${props.match.path}/transactions`} render={() =>
              <Transactions
                {...props}
              />} />
          </Switch>
        </div>
      </div>
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    userType: state.user.access.user_type,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(JoinAndRenew);
