import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect, withRouter} from 'react-router-dom';

import Header from '../shared/Header';
import Navigation from '../shared/Navigation';
import BreadCrumbsNav from '../shared/BreadCrumbsNav';
import Footer from '../shared/Footer';

import Templates from './Templates';
import ScheduledReports from './ScheduledReports';
import MyReports from './MyReports';
import EmailDeliveries from './EmailDeliveries';
import {connect} from "react-redux";

class Reports extends Component {

    render() {
		if(!this.props.reporting_token) return null;
		let url = process.env.REACT_APP_API_URL.slice(0,-6) + `reports/${this.props.reporting_token}/`;
        return (
            <Fragment>

                <Header />
				<Navigation />
				<BreadCrumbsNav />

                <main>
                    <div className="container">

                        <div className="page__head">
                            <h2 className="page__title">Reports</h2>
                        </div>

                        <ul className="page__tabs">
							<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/my-reports`}>My Reports</NavLink></li>
							<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/scheduled-reports`}>Scheduled Reports</NavLink></li>
							<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/templates`}>Templates</NavLink></li>
							<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/email-deliveries`}>Email Deliveries</NavLink></li>
						</ul>

                        <section className="page__tab-panel">
                            <Switch>
                                <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/my-reports`}/>
                                <Route path={`${this.props.match.url}/templates`} component={() => <Templates iframeUrl={url + 'templates'}/>}/>
                                <Route path={`${this.props.match.url}/scheduled-reports`} component={() => <ScheduledReports  iframeUrl={url + 'scheduled'}/>}/>
                                <Route path={`${this.props.match.url}/my-reports`} component={() => <MyReports iframeUrl={url + 'results'}/>}/>
                                <Route path={`${this.props.match.url}/email-deliveries`} component={() => <EmailDeliveries iframeUrl={url + 'emails'}/>}/>
                            </Switch>
                        </section>

                    </div>
                </main>

                <Footer />

            </Fragment>
        );
    }

}


function mapStateToProps(state) {
	return {
		reporting_token: state.user.access.reporting_token
	}
}

export default withRouter(connect(mapStateToProps, null)(Reports));
