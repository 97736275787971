import React, { Component, Fragment } from "react";
import { NavLink, Switch, Route, Redirect, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import HubSpot from "./HubSpot";

class GhinConnect extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {
		return (
			<Fragment>
				<div className="vertical-tabs__container row">
					<ul className="vertical-tabs col">
						<li>
							<NavLink
								className="vertical-tab"
								activeClassName="is-active"
								to={`${this.props.match.url}/hubspot`}
							>
								HubSpot
							</NavLink>
						</li>
					</ul>
					<div className="vertical-tabs__panel col">
						<Switch>
							<Redirect
								exact
								from={`${this.props.match.url}`}
								to={`${this.props.match.url}/hubspot`}
							/>
							<Route
								path={`${this.props.match.path}/hubspot`}
								component={(props) => (
									<HubSpot {...props}
										canEditAssociation={this.props.canEditAssociation}
										associationId={this.props.association.id}
									/>
								)}
							/>
							<Route
								path={`${this.props.match.path}/connector-2`}
								component={() => (
									<div> Connector 2</div>
								)}
							/>
							<Route
								path={`${this.props.match.path}/connector-3`}
								component={() => (
									<div> Connector 3</div>
								)}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		userType: state.user.access.user_type,
	};
}

export default compose(withRouter, connect(mapStateToProps))(GhinConnect);