import React, { Fragment } from 'react'
import moment from "moment";
import ReactTooltip from 'react-tooltip';


export let detailsColumns = [
  {
    Header: 'GHIN Number',
    accessor: 'golfer_id',
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    colClassName: 'col_golfer-name'
  },
  {
    Header: 'Date/Time Sent',
    accessor: 'created_at',
    colClassName: 'col_golfer-name',
    Cell: props => moment(new Date(props.original.created_at), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss')
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: props => {
      const key = Math.random();
      return <div className={"capitalize"}>{mapStatusForUsers(props.value)}{props.original.error_message && <Fragment><a href="#donothing" onClick={(e) => { e.preventDefault() }} data-tip data-for={`status${key}`}>
        <i className="material-icons v-mid m-left dark-blue">info</i></a>
        <ReactTooltip id={`status${key}`} className="auto-size-opaque_tooltip" type="light" place='top' effect={'float'} style={{ width: "auto" }}>
          {props.original.error_message}
        </ReactTooltip>
      </Fragment>
      }</div>
    }
  }
];

// status: a string representing the status as it comes from backend
// Returns: a string representing a more user friendly status
function mapStatusForUsers(status) {
  switch(status) {
    case "click":
      // For now the click notification should not be sent by SendGrid
      return "open";
    default:
      return status;
  }
}
