import React, {Component, Fragment} from 'react';

import SelectAddGolferMethod from './SelectAddGolferMethod';
import SearchGolfer from './SearchGolfer';
import AddNewGolferForm from './AddNewGolferForm';
import AddExistingGolferForm from './AddExistingGolferForm';
import SuccessModal from "../../Modals/SuccessModal";
import GolferService from "../../../services/api/golfer";

export default class AddGolferForm extends Component {

    constructor(props) {
      
        super(props);
        this.state = {
            selectMethod: true,
            searchGolfer: false,
            addGolfer: false,
			existingGolfer: null,
			golfer: null,
			digitalInvitationSent: false,
      digitalInvitationSentError: null
        }

    }

    showSearch() {
        this.setState({
            selectMethod: false,
            searchGolfer: true
        });
    }

    showAdd() {
        this.setState({
            selectMethod: false,
            addGolfer: true
        });
    }

    showExistingForm(golfer){
    	this.setState({
			selectMethod: false,
			searchGolfer: false,
			addGolfer: false,
			existingGolfer: golfer
		})
	}

    resetToSelect() {
        this.setState({
            selectMethod: true,
            searchGolfer: false,
            addGolfer: false,
			golfer: null,
			existingGolfer: null,
        });
    }

    showSuccess(golfer) {
    	this.setState({
			selectMethod:false,
			searchGolfer:false,
			addGolfer: false,
			existingGolfer: null,
      digitalInvitationSent: false,
			golfer,
		})
	}

	sendDigitalProfileInvitation(){
    const association_id = this.props.association === 0 ? this.state.golfer.association_id : this.props.association;
		GolferService.sendInvitation(this.state.golfer.id, association_id)
			.then(res => {
				this.setState({
					digitalInvitationSent: true,
          digitalInvitationSentError: null
				});
			})
			.catch(err => {
				if(err.data.errors && err.data.errors.digital_profile && err.data.errors.digital_profile.length > 0) {
					this.setState({
						digitalInvitationSentError: err.data.errors.digital_profile[0].body_line1
					})
				}
        console.error(err);
			})
	}

	render() {
		return(
			<Fragment>

				{this.state.selectMethod && <SelectAddGolferMethod
					showSearch={()=>{this.showSearch()}}
					closeModal={() => {this.props.closeModal()}}
					showAdd={()=>{this.showAdd()}}
				/>}

				{this.state.searchGolfer && <SearchGolfer
					onGolferSelect={(data)=>{this.showExistingForm(data)}}
					resetToSelect={()=>{this.resetToSelect()}}
					closeModal={() => {this.props.closeModal()}}
				/>}

				{this.state.addGolfer && <AddNewGolferForm
					association={this.props.association}
					canEditAssociation={this.props.canEditAssociation}
					club={this.props.club}
					resetToSelect={()=>{this.resetToSelect()}}
					closeModal={() => {this.props.closeModal()}}
					showSuccess={(data)=>{this.showSuccess(data)}}
				/>}

				{this.state.existingGolfer && <AddExistingGolferForm
					golfer={this.state.existingGolfer}
					association={this.props.association}
					canEditAssociation={this.props.canEditAssociation}
					club={this.props.club}
					resetToSelect={()=>{this.resetToSelect()}}
					closeModal={() => {this.props.closeModal()}}
					showSuccess={(data)=>{this.showSuccess(data)}}
				/>}

				{this.state.golfer && <SuccessModal
					openModal={this.state.golfer}
					onMainAction={() => {
						this.props.closeModal({type: 'golfer' , golfer: this.state.golfer});
					}}
					onSecundaryAction={() => {
						this.resetToSelect();
					}}
					closeModal={() => {
						this.props.closeModal({type: 'golfer' , golfer: null});
					}}
					golfer={this.state.golfer}
					mainLabel={"Continue to Setup"}
					secundaryLabel={"Add Another Golfer"}
				>
					{!this.state.golfer.has_digital_profile && this.state.golfer.email && <>
						{this.state.digitalInvitationSent ?
							<span className={'red'}>Digital Profile Invitation has been sent.</span>
							: 
              (this.state.digitalInvitationSentError ? 
                <span className={'red'}>{this.state.digitalInvitationSentError}</span> 
                  :
								<button type="button" onClick={() => {this.sendDigitalProfileInvitation()}} className="btn fill green ">Send Invitation To Create a Digital Profile</button>)
						}
					</>}
				</SuccessModal>}

			</Fragment>
		)
    }
}
