import React, {Component} from 'react';
import {Field} from "redux-form";
import {InputField} from "../../../inputs/InputField";
import {required} from "../../../inputs/validations";
import debounce from "debounce-promise";
import GuardianRelationshipForm from "./GuardianRelationshipForm";
import SelectedGuardianInfo from "./SelectedGuardianInfo";
import GuardianService from "../../../services/api/guardian";
import {processErrorMessage} from "../../../services/shared/errorHelper";

function emailIsValid(email) {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

class NonGolferGuardianForm extends Component {

	constructor(props) {
		super(props);
		this.searchForExistingEmail = debounce(this.searchForExistingEmail.bind(this), 1000);
		this.state = {
			showGuardianFields: false,
			foundGuardian: null,
			error: false
		}
	}

	searchForExistingEmail(value) {
		this.props.clearInput();
		if (!value) {
			this.setState({
				foundGuardian: undefined,
				showGuardianFields: false,
				error: 'This field is required'
			});
			return;
		}
		if (!emailIsValid(value)) {
			this.setState({
				foundGuardian: undefined,
				showGuardianFields: false,
				error: 'No results found, please change your search criteria.'
			});
			return;
		}

		let params = [];

		params['per_page'] = 1;
		params['page'] = 1;
		params['unique_guardians'] = true;
		params['global_search'] = false;
		params['search'] = value;
		params['only_non_golfing_guardians'] = true;

		GuardianService
			.getGuardians(params)
			.then((res) => {
				if (res.guardians && res.guardians.length > 0) {
					const guardians = res.guardians.filter((i) => !i.golfer_id);
					this.props.loadGuardianData({email: value});
					this.setState({
						foundGuardian: guardians[0],
						hasError: false,
						showGuardianFields: false,
						error: null

					});
				} else {
					this.props.clearInput();
					this.props.loadGuardianData({email: value});
					this.setState({
						foundGuardian: undefined,
						showGuardianFields: true,
						error: null
					})
				}
			}).catch(error => {
				if (error && error.data && error.data.errors) {
					let errorMessage = processErrorMessage(error.data.errors);
					this.setState({
						error: errorMessage
					});
				}
		});


	}


	render() {

		let guardianDetailsForm = null;


		if (this.state.showGuardianFields) {
			guardianDetailsForm = <>
				<div className="row">
					<div className="col is-1-of-5">
						<label htmlFor="guardian.prefix">Prefix</label>
						<Field component={InputField}
							   type="text"
							   name="guardian.prefix"
							   id="guardian.prefix"/>
					</div>
					<div className="col is-2-of-5">
						<label htmlFor="guardian.first_name">Guardian First Name <span>*</span></label>
						<Field component={InputField}
							   validate={[required]}
							   type="text"
							   name="guardian.first_name"
							   id="guardian.first_name"/>
					</div>
					<div className="col is-2-of-5">
						<label htmlFor="guardian.middle_name">Guardian Middle Name</label>
						<Field component={InputField}
							   type="text"
							   name="guardian.middle_name"
							   id="guardian.middle_name"/>
					</div>
				</div>
				<div className={'row'}>
					<div className="col is-2-of-3">
						<label htmlFor="guardian.first_name">Guardian Last Name <span>*</span></label>
						<Field component={InputField}
							   validate={[required]}
							   type="text"
							   name="guardian.last_name"
							   id="guardian.last_name"/>
					</div>
					<div className="col is-1-of-3">
						<label htmlFor="guardian.suffix">Suffix</label>
						<Field component={InputField}
							   type="text"
							   name="guardian.suffix"
							   id="guardian.suffix"/>
					</div>
				</div>
				<div className={'row'}>
					<div className="col is-1-of-2">
						<label htmlFor="guardian.phone_number">Guardian Phone Number </label>
						<Field component={InputField}
							   type="text"
							   name="guardian.phone_number"
							   id="guardian.phone_number"/>
					</div>
				</div>
			</>
		}

		if (this.state.foundGuardian) {
			guardianDetailsForm = <>
				<SelectedGuardianInfo guardian={this.state.foundGuardian}/>
			</>
		}


		return (
			<>
				<div className={'row'}>
					<div className="col is-1-of-2">
						<span style={{display: 'none'}}><Field component={InputField}
              validate={!this.props.is_archived && [required]}
															   type="text"
															   name="guardian.email"
															   id="guardian.email"
						/></span>
            <label htmlFor="guardian.email_search">Guardian Email {!this.props.is_archived && <span>*</span>}</label>
						<input
							type="text"
							name="email_search"
							className={(this.state.error ? ' is-error' : '')}

							onChange={(event) => this.searchForExistingEmail(event.target.value)}
							id="guardian.email_search"/>
						{
							this.state.error ? (
								<span className={'validation__message is-error'}>{this.state.error}</span>) : null
						}


					</div>
				</div>
				{
					guardianDetailsForm
				}

				{
					guardianDetailsForm ? <GuardianRelationshipForm formGuardian={this.props.formGuardian}/> : null
				}
			</>
		)
	}
}

export default NonGolferGuardianForm
