import React, {Component, Fragment} from 'react';

import AuditHistoryForm from '../Forms/AuditHistoryForm';

export default class AuditHistoryModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container wide">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">bookmark</i></div>
                        <h2 className="modal__title">Round Audit History</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <AuditHistoryForm />

                    </div>

                </div>

            </Fragment>
		);
	}
}
