import React, { Component, Fragment } from "react";
import moment from "moment";
import GhinTable from "../../../shared/GhinTable";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";
import SuccessModal from "../../../Modals/SuccessModal";
import EditNotificationModal from "../../../Modals/EditNotificationModal";
import ReviewNotificationModal from "../../../Modals/ReviewNotificationModal";
import AssociationService from "../../../../services/api/association";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";

export default class Table extends Component {
	constructor() {
		super();

		this.state = {
			per_page: 25,
			page: 0,
			push_notifications: [],
			loading: false,
			isApprovedModalOpened: false,
			isAllSelected: false,
			success: false,
			fail: false,
			editNotificationModal: false,
			reviewNotificationModal: false,
			selectedNotification: null,
			refreshTable: false,
			sorted: [{ id: "created_at", desc: true }],
		};
	}

	approveNotifications() {
		let params;
		
		if (this.state.isAllSelected) {
			params = { select_all: true }
		} else {
			let ids = this.getSelectedNotifications().map(
				(push_notification) => push_notification.id
			);
			params = {push_notification_setting_ids: ids}
		}
		AssociationService.approvePendingPushNotification(params)
			.then((res) => {
				if (res.unsuccessfully_approved)
					this.setState({
						isApprovedModalOpened: false,
						fail: true,
						unsuccessfully_approved: res.unsuccessfully_approved,
						successfully_approved: res.successfully_approved,
					});
				else {
					this.setState({
						success: true,
						isApprovedModalOpened: false,
						unsuccessfully_approved: res.unsuccessfully_approved,
						successfully_approved: res.successfully_approved,
					});
				}
				console.log("Push Notifications approved.");
			})
			.catch((err) => {
				console.error(err);
			});
	}

	loadPushNotifications() {
		let params = []
		if (this.state.sorted.length > 0) {
			params["sorting_criteria"] = this.state.sorted[0].id;
			if (params["sorting_criteria"] === "schedule.scheduled_at") {
				params["sorting_criteria"] = "scheduled_at";
			}
			params["order"] = this.state.sorted[0].desc ? "desc" : "asc";
		}

		this.setState({loading: true})
		AssociationService.getPendingPushNotification(
			this.state.page + 1,
			this.state.per_page,
			params
		)
			.then((res) => {
				this.setState({
					push_notifications: res.push_notification_settings.map(pns => {return {...pns, selected: this.state.isAllSelected}}),
					total: parseInt(res["total-count"], 10),
					per_page: parseInt(res["per-page"], 10),
					pages: parseInt(res["total-pages"], 10),
					loading: false
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}
	
	setTableRefresh(value) {
		this.setState({refreshTable: value});
	}

	componentDidMount() {
		this.loadPushNotifications();
	}
	
	componentDidUpdate() {
		if (this.state.refreshTable === true){
			this.loadPushNotifications();
			this.setState({refreshTable: false})
		}
	}

	showApprovedModal() {
		this.setState({
			isApprovedModalOpened: true,
		});
	}
	
	getSelectedNotifications() {
		let push_notifications = [...this.state.push_notifications];
		push_notifications = push_notifications.filter(
			push_notification => push_notification.selected === true
		);
		return push_notifications;
	}

	// Set time in UTC format with same hour
	localDateTime(datetime) {
		datetime = new Date(datetime);
		let hours = datetime.getHours();
		datetime.setUTCHours(hours);
		return datetime;
	}

	render() {
		const checked_notifications = this.getSelectedNotifications();
		const no_checked_notifications = this.state.isAllSelected ? this.state.total : checked_notifications.length;
		const disabled = no_checked_notifications === 0;
		
		const assocId = this.state.selectedNotification
			? this.state.selectedNotification.golf_association_id
			: null;

		const columns = [
			{
				accessor: 'id',
				show: false
			},
			{
				Header: "Association #",
				accessor: "golf_association_id",
				colClassName: "col_id_assoc",
			},
			{
				Header: "Association Name",
				accessor: "association_name",
				filterMethod: (filter, row) =>
					row[filter.id]
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) !== -1,
				Cell: (props) => (
					<Fragment>
						<NavLink
							to={`/manage/association/${props.row._original.golf_association_id}/list`}
						>
							{props.value}
						</NavLink>
					</Fragment>
				),
				colClassName: "col_assoc-name",
			},
			{
				Header: "Created On",
				accessor: "created_at",
				Cell: (props) => (
					<Fragment>
						{moment(this.localDateTime(props.value)).format("MM/DD/YYYY HH:mm:ss")}{" "}
					</Fragment>
				),
				colClassName: "col_local-id",
				sortable: false,
			},
			{
				Header: "Scheduled",
				accessor: "schedule.scheduled_at",
				Cell: (props) => (
					<Fragment>
						{props.value !== null
							? moment(this.localDateTime(props.value)).format("MM/DD/YYYY HH:mm:ss")
							: "-"}
					</Fragment>
				),
				colClassName: "col_local-id",
			},
			{
				Header: "Notification Text",
				accessor: "message.body",
				colClassName: "col_note",
				sortable: false,
			},
			{
				Header: "",
				sortable: false,
				accessor: "buttons",
				colClassName: "col_slope",
				Cell: (props) => (
					<span className="col_edit-delete">
						<button
							className={"btn lnk"}
							onClick={() => {
								this.setState({
									reviewNotificationModal: true,
									selectedNotification: props.row._original,
								});
							}}
						>
							Review
						</button>
						<button
							className={"btn lnk"}
							onClick={() => {
								this.setState({
									editNotificationModal: true,
									selectedNotification: props.row._original,
								});
							}}
						>
							Edit
						</button>
					</span>
				),
				thClassName: "is-8-of-10",
			},
		];
		
		return (
			<Fragment>
				<div className="pre__table">
					<div className="row">
						<div className="col auto push-left">
							<ul className="action__list push-left">
								<li>
									<button
										className="btn fill green"
										onClick={(event) => {
											event.stopPropagation();
											this.showApprovedModal();
										}}
										disabled={disabled}
									>
										Approve
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<GhinTable
					loading={this.state.loading}
					columns={columns}
					data={this.state.push_notifications}
					sorted={this.state.sorted}
					onSortedChange={(newSorted, column, shiftKey) => {
						this.setState(
							{
								sorted: newSorted,
								page: 0,
							},
							() => {
								this.loadPushNotifications();
							}
						);
					}}
					pages={this.state.pages}
					total={this.state.total}
					pageSize={this.state.per_page}
					manual
					onPageChange={(pageIndex) =>
						this.setState({ page: pageIndex }, () =>
							this.loadPushNotifications()
						)
					}
					onPageSizeChange={(pageSize, pageIndex) =>
						this.setState(
							{
								per_page: pageSize,
								page: pageIndex,
							},
							() => this.loadPushNotifications()
						)
					}
					ref={(r) => (this.table = r)}
					onRowSelect={(row) => {
						const push_notifications = [
							...this.state.push_notifications,
						].map((i) => {
							if (i.id === row.id) {
								return {
									...i,
									selected: !row.selected,
								};
							} else {
								return i;
							}
						});
						this.setState({
							push_notifications
						});
					}}
					onSelectPage={() => {
						let push_notifications = [
							...this.state.push_notifications,
						];
		
						push_notifications = push_notifications.map(
							(push_notification) => {
								return {
									...push_notification,
									selected: !push_notification.selected,
								};
							}
						);

						this.setState({
							push_notifications,
							isAllSelected: false
						});
					}}
					defaultSorted={[
						{
							id: "schedule.scheduled_at",
							desc: false,
						},
					]}
					sortable={true}
					hideSelectAllPages={true}
				/>

				<ConfirmationModalTypeB
					wideClass={"narrow"}
					isOpen={this.state.isApprovedModalOpened}
					openModal={this.state.isApprovedModalOpened}
					onConfirmAction={() => {
						this.approveNotifications();
					}}
					onCancelAction={() => {
						this.setState({ isApprovedModalOpened: false });
					}}
					closeModal={() => {
						this.setState({ isApprovedModalOpened: false });
					}}
					question={
						"Are you sure you want to approve " +
						no_checked_notifications +
						" Push Notifications?"
					}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>

				{this.state.success && (
					<SuccessModal
						openModal={this.state.success}
						onMainAction={() => {
							this.setState({ success: false });
							this.setTableRefresh(true);
						}}
						closeModal={() => {
							this.setState({ success: false });
							this.setTableRefresh(true);
						}}
						count={this.state.successfully_approved}
						mainLabel={"OK"}
					/>
				)}

				{this.state.fail && (
					<SuccessModal
						openModal={this.state.fail}
						warning={this.state.fail}
						warningTitle={"Please Review"}
						onMainAction={() => {
							this.setState({ fail: false });
							this.setTableRefresh(true);
						}}
						closeModal={() => {
							this.setState({ fail: false });
							this.setTableRefresh(true);
						}}
						unsuccessfully_approved={
							this.state.unsuccessfully_approved
						}
						successfully_approved={this.state.successfully_approved}
						mainLabel={"OK"}
					/>
				)}

				<Modal
					isOpen={this.state.editNotificationModal}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditNotificationModal
						associationId={assocId}
						user={this.props.user}
						push_notification_setting={
							this.state.selectedNotification
						}
						onCancelAction={() => {
							this.setState({ editNotificationModal: false });
						}}
						closeModal={() => {
							this.setState({ editNotificationModal: false });
						}}
						setTableRefresh={(value) =>
							this.setTableRefresh(value)
						}
					/>
				</Modal>

				<Modal
					isOpen={this.state.reviewNotificationModal}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<ReviewNotificationModal
						selectedNotification={this.state.selectedNotification}
						user={this.props.user}
						associationId={assocId}
						onCancelAction={() => {
							this.setState({ reviewNotificationModal: false });
							
						}}
						closeModal={() => {
							this.setState({ reviewNotificationModal: false });
						}}
						setTableRefresh={(value) =>
							this.setTableRefresh(value)
						}
					/>
				</Modal>
			</Fragment>
		);
	}
}
