import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

class ItemComponent extends Component {
	state = {
		showAdjustmentsModal: false,
		expandedStats: false,
		hasToolTip: false
	};

	componentDidMount() {
		if (this.props.hideToolTip !== true) {
			if (this.courseLabelRef && this.courseCellRef) {
				if (this.courseLabelRef.offsetWidth < this.courseLabelRef.scrollWidth || this.props.forceTooltip) {
					this.setState({ hasToolTip: true });
				} else {
					this.setState({ hasToolTip: false });
				}
			}
		}
	}

	render() {
		const { hasToolTip } = this.state;
		const data = this.props.data;
		const value = data && ((typeof data === "string" || typeof data === "number") ? data : data.props.value);
		return (
			<div
				ref={ref => (this.courseCellRef = ref)}
				data-for={this.props.id}
				data-tip={hasToolTip ? value : ""}
			>
				{hasToolTip && <ReactTooltip id={this.props.id} place="top" type="dark"  effect="float" />}
				<div className={'text-holder'}
						 ref={ref => (this.courseLabelRef = ref)}
						 style={{textOverflow: hasToolTip ? 'ellipsis' : 'initial'}}
						 onClick={this.props.onClick}
				>
					{data}
				</div>
			</div>
		);
	}
}


export default ItemComponent;
