import request from '../shared/request';

export default class AuthService {
	static login(data) {
		return request({
			url: '/users/admin_login.json',
			method: 'POST',
			data
		})
	}

	static logout(data) {
		return request({
			url: '/users/logout.json',
			method: 'DELETE',
			data
		})
	}

	static sendResetEmail(data) {
		return request({
			url: '/users/forgot_password.json',
			method: 'POST',
			data
		})
	}

	static resetPassword(data) {
		return request({
			url: '/users/set_password.json',
			method: 'POST',
			data
		})
	}
}
