import { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { SelectInput } from "../../inputs/Select";
import { required } from "../../inputs/validations";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import GolferService from "../../services/api/golfer";
import { connect } from "react-redux";
import { addAlert } from "../shared/actions";
import { bindActionCreators } from "redux";

const reasons = [
  { value: 'Too many emails', label: 'Too many emails' },
  { value: 'Never signed up for emails', label: 'Never signed up for emails' },
  { value: 'Content is not relevant to golfer', label: 'Content is not relevant to golfer' },
  { value: 'Golfer has moved', label: 'Golfer has moved' },
  { value: 'Bad Email', label: 'Bad Email' },
  { value: 'Other', label: 'Other' }
]
class EditContactSettingsReasonForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.initialize({
      ...{
        reason: {
          label: this.props.selected_settings.reason === "" ? "Please select a reason why this golfer does not want to be contacted." : this.props.selected_settings.reason,
          value: this.props.selected_settings.reason
        }
      }
    })
  }

  onSubmit(values, dispatch, props) {
    const contact_settings = props.selected_settings
    var contact_organization_id
    var contact_organization_type
    if (contact_settings.organization_type === 'Club') {
      contact_organization_type = 'Club'
      contact_organization_id = contact_settings.club_id
    }
    else {
      contact_organization_type = 'GolfAssociation'
      contact_organization_id = contact_settings.association_id
    }
    const parameters = {
      "contact_organization_type": contact_organization_type,
      "contact_organization_id": contact_organization_id,
      "reason": values.reason.value
    }
    return GolferService.postContactSettings(contact_settings.golfer_id, parameters).then(res => {
      this.props.addAlert({ type: 'success', message: 'Do Not Contact setting has been successfully edited' })
      this.props.close_modal();
      this.props.reload_contact_settings();
    })
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <Fragment>
        <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
          <div className="row">
            <div className="col">
              <p><strong>Note</strong>: If the golfer is deceased, please update in the ‘Primary’ section of the golfer’s account.</p>
            </div>
          </div>
          <div className="row" style={{ 'marginTop': '12px' }}>
            <div className="col">
              <label htmlFor="reason">Why doesn’t this golfer want to be contacted by the {this.props.selected_settings.organization_type === 'Club' ? this.props.selected_settings.club_name : this.props.selected_settings.golf_association_name}?</label>
              <Field
                name="reason"
                id="reason"
                validate={[required]}
                component={SelectInput}
                className="react-select-container"
                classNamePrefix="react-select"
                options={reasons}
              />
            </div>
          </div>
          <div className="row">
            <div className="col is-1-of-2">
              <button type="button" className="btn fill gray" onClick={() => this.props.close_modal()}>Cancel</button>
            </div>
            <div className="col is-1-of-2">
              <button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Submit</button>
            </div>
          </div>
        </form>
      </Fragment >
    );
  }
}

EditContactSettingsReasonForm = reduxForm({
  form: 'EditContactSettingsReasonForm',
  destroyOnUnmount: true
})(EditContactSettingsReasonForm)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditContactSettingsReasonForm))