import React, {Component} from "react";

class HeaderAndFooter extends Component {
  constructor(props) {
      super(props);
      this.state = {

      }
  }
  render(){
    return(
      <div className="row">
        <div className="col">
          <div className="panel">
            <div className="panel__head">Header & Footer Bar</div>
              <div className="panel__body">
                {!this.props.readOnly &&
                <div className="row">
                  <div className="col auto push-right">
                    <button onClick={this.props.assocOpenHeaderAndFooterModal} className="btn lnk">Edit</button>
                  </div>
                </div>}
                <div className="row">
                  <div className="col jc-fs">
                    The header and footer bars are the solid colored bars that appear at the top (below the header) and bottom of the template.
                  </div>
                </div>
                <div className="row">
                  <div className="col jc-fs">
                    <div className="data-set__container left">
                      <div className="data-set">
                        <span className="data-label">Show Header Bar?</span>
                        <span className="data-value">{this.props.emailSettings.show_header_bar ? "Yes" : "No"}</span>
                      </div>
                      <div className="data-set">
                        <span className="data-label">Show Footer Bar?</span>
                        <span className="data-value">{this.props.emailSettings.show_footer_bar ? "Yes" : "No"}</span>
                      </div>
                      {(this.props.emailSettings.show_header_bar || this.props.emailSettings.show_footer_bar) &&
                      <div className="data-set">
                        <span className="data-label">Color Code</span>
                        <div className="data-value">
                          <span>#{this.props.emailSettings.use_app_primary_color_for_header_and_footer_bar ? this.props.emailSettings.preferred_app_color : this.props.emailSettings.header_and_footer_bar_color}</span>
                          <span className="circle" style={{ backgroundColor: `#${this.props.emailSettings.use_app_primary_color_for_header_and_footer_bar ? this.props.emailSettings.preferred_app_color : this.props.emailSettings.header_and_footer_bar_color}`}}></span>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )};
}
export default HeaderAndFooter;
