import BasicSettings from "./BasicSettings";
import PushNotification from "./PushNotification";
import PromotionalImages from "./PromotionalImages";
import TournamentsAndEvents from "./TournamentsAndEvents";
import { Fragment } from "react";
import { Redirect, Route, Switch, withRouter, NavLink } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

function GolferProducts(props) {
	return (
		<Fragment>
			<div className="vertical-tabs__container row">
				<ul className="vertical-tabs col">
					<li>
						<NavLink
							className="vertical-tab"
							activeClassName="is-active"
							to={`${props.match.url}/basic-settings`}
						>
							Basic Settings
						</NavLink>
					</li>
					{process.env.REACT_APP_TYPE !== "hv" && (
						<Fragment>
							<li>
								<NavLink
									className="vertical-tab"
									activeClassName="is-active"
									to={`${props.match.url}/promotional-images`}
								>
									Promotional Images
								</NavLink>
							</li>
							<li>
								<NavLink
									className="vertical-tab"
									activeClassName="is-active"
									to={`${props.match.url}/tournaments-and-events`}
								>
									Tournaments & Events
								</NavLink>
							</li>
							<li>
								<NavLink
									className="vertical-tab"
									activeClassName="is-active"
									to={`${props.match.url}/push-notifications`}
								>
									Push Notifications
								</NavLink>
							</li>
						</Fragment>
					)}
				</ul>
				<div className="vertical-tabs__panel col">
					<Switch>
						<Redirect
							exact
							from={`${props.match.url}`}
							to={`${props.match.url}/basic-settings`}
						/>
						<Route
							path={`${props.match.path}/basic-settings`}
							component={() => (
								<BasicSettings
									canEditAssociation={
										props.canEditAssociation
									}
									associationId={props.associationId}
								/>
							)}
						/>
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								path={`${props.match.path}/promotional-images`}
								component={() => (
									<PromotionalImages
                    canEditAssociation={
                      props.canEditAssociation
                    }
										associationId={props.associationId}
									/>
								)}
							/>
						)}
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								path={`${props.match.path}/tournaments-and-events`}
								component={() => (
									<TournamentsAndEvents
										canEditAssociation={
											props.canEditAssociation
										}
										associationId={props.associationId}
									/>
								)}
							/>
						)}
						{process.env.REACT_APP_TYPE !== "hv" && (
							<Route
								path={`${props.match.path}/push-notifications`}
								component={() => (
									<PushNotification
										canEditAssociation={
											props.canEditAssociation
										}
										associationId={props.associationId}
										user={props.user}
										pushNotificationUser={props.pushNotificationUser}
										userType={props.userType}
									/>
								)}
							/>
						)}
					</Switch>
				</div>
			</div>
		</Fragment>
	);
}

function mapStateToProps(state) {
	return {
		userType: state.user.access.user_type,
		user: state.user
	};
}

export default compose(withRouter, connect(mapStateToProps))(GolferProducts);
