import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputField} from "../../inputs/InputField";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {addAlert} from "../shared/actions";

class GeneratedDigitalProfileLinkForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generatedUrl: props.generatedUrl
    }
    this.props.change('generated_url', props.generatedUrl);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.state.generatedUrl);
    this.props.addAlert({
      type:'success',
      message: `Join Link copied to clipboard`
    });
    this.props.close();
  }

  render() {
    return(
      <Fragment>
        <form autoComplete="off" ref={'form'}>
          <div className="row">
            <div className="col is-6-of-7">
              <Field
                component={InputField}
                type="text"
                name={'generated_url'}
                id={'generated_url'}
                disabled={true}
              />
            </div>
            <div className="col is-1-of-7">
              <button className="btn fill blue" onClick={this.copyToClipboard}>Copy</button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}


GeneratedDigitalProfileLinkForm = reduxForm({
	form: 'createDigitalProfileLinkForm',
	destroyOnUnmount: true
})(GeneratedDigitalProfileLinkForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(GeneratedDigitalProfileLinkForm));
