import React, {Component, Fragment} from 'react';
import ClubService from "../../../services/api/club";
import GolferService from "../../../services/api/golfer";
import {bindActionCreators} from "redux";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {SelectInput} from "../../../inputs/Select";
import {required, requiredSelect, isEmailAddress} from "../../../inputs/validations";
import AssociationService from "../../../services/api/association";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";
import {DatePickerInput} from "../../../inputs/DatePicker";
import moment from "moment";
import {processErrorMessage} from "../../../services/shared/errorHelper";
import GhinGuardianFormComponent2 from "../../shared/GhinGuardianFormComponent2";
import {InputField} from "../../../inputs/InputField";


class AddExistingGolferForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			associations: [],
			clubs: [],
			membershipTypes: [],
			error: null,
			membershipTypesLoaded: false,
      selectedGuardian: false,
			areLockedMemberships: false
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		this.setState({
			startDate: date
		});
	}

  selectGuardian() {
    this.setState({
      selectedGuardian: !this.state.selectedGuardian
    })
  }


	componentDidMount() {
		if(this.props.access.user_type === "club-admin") {
			this.getClubAssociation();
		} else {
			this.getAssociations();
		}
		if (this.props.club) {
			this.getMembershipTypes(this.props.club.id ? this.props.club.id : this.props.club)
		}
	}

	getAssociations() {
		ClubService.getGolfAssociations()
			.then(response => {
				let associations = response.associations.map(assoc => {
					assoc.value = assoc.id;
					assoc.label = assoc.name;
					return assoc
				});

				associations.sort((a, b) => (a.label > b.label) ? 1 : -1);

				this.setState({
					associations
				}, ()=>{
					if (this.props.association) {
						let initialAssoc = associations.filter(a => parseInt(a.id) === parseInt(this.props.association))[0];
						this.props.change('association', initialAssoc);
						this.onAssociationChange(initialAssoc);
					}
				});

			});
		this.props.onRef(this);
	}

	getClubAssociation() {
		let associations = [{
			label: this.props.access.default_association_name,
			value: this.props.access.default_association_id,
			id: this.props.access.default_association_id
		}];
		this.setState({
			associations
		},() => {
			this.props.change('association', associations[0]);
			this.onAssociationChange(associations[0]);
		});
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	getMembershipTypes(clubId){
		this.props.change('membership_code', null);
		this.setState({
			membershipTypesLoaded: false
		});
		ClubService.getMembershipTypes(clubId, {include_used_membership: 'false'})
			.then(res => {
				let lockedMemberships = false
				this.setState({
					membershipTypesLoaded: true,
					membershipTypes: res.membership_types ? res.membership_types.map(membershipType => {
						if (membershipType.locked)
							lockedMemberships = true;
						return {
							label:
								<Fragment>
									<div style={{display: "inline-flex", alignItems: "end"}}>
										<span style={{display: "flex", alignItems: "center"}}>
											<span className="membership-description-dropdown" style={{maxWidth: "250px"}}>{membershipType.code + ' - ' + membershipType.description}</span>
											{membershipType.locked && <i className="material-icons-outlined lock-icon-container"/>}
										</span>
									</div>
								</Fragment>,
							type: membershipType.type,
							value: membershipType.id,
							locked: membershipType.locked,
							isDisabled: !this.props.canEditAssociation && membershipType.locked
						};
					}).filter(membershipType => membershipType.active !== "false").sort((a, b) => a['label'].toString().localeCompare(b['label'].toString())) : []
				});
				this.setState({ areLockedMemberships: lockedMemberships });
			})
			.catch(err => {
				this.setState({
					membershipTypesLoaded: true
				});
				console.error(err)
			})
	}

	onAssociationChange(data){

		if (this.props.access.user_type === "club-admin") {
			let club = {label: this.props.access.default_club_name, value: parseInt(this.props.access.default_club_id), id: parseInt(this.props.access.default_club_id)};
			this.setState({
				clubs: [club]
			}, ()=>{
				this.props.change('club', club);
			})
		} else {
			AssociationService.getAllClubs(data.id, {status: "Active"}).then((res) => {
				let clubs = res.clubs.map(club => {
					club.value = club.id;
					club.label =  club.club_name;
					return club
				});

				this.props.change('club', null);

				clubs.sort((a, b) => a['label'].toString().localeCompare(b['label'].toString()));

				this.setState({
					clubs
				}, ()=>{
					if (this.props.club) {
						this.props.change('club', clubs.filter( c => parseInt(c.id) === parseInt(this.props.club.id))[0]);
					}
				})
			});
		}
	}

	onSubmit(postData) {
		let values = {
			...postData
		};
		let data = {
			golfer_club: {
				golfer_id: this.props.golfer.id,
				club_id: values.club.value
			},
			membership_code: values.membership_code ? {id: values.membership_code.value} : {},
			golfer: {
        date_of_birth: values.date_of_birth ? moment(values.date_of_birth).format('YYYY-MM-DD') : this.props.golfer.date_of_birth,
        email: values.email || ''
      }
		};



		if (values.guardian) {
			data['guardian'] = {
				...values.guardian,
			}

			if (values.guardian.relationship) {
				data['guardian'].relationship = values.guardian.relationship.value;
			}

		}
		return GolferService.addExistingGolfer(data)
			.then(res => {
				if(res.golfer_club){
					this.props.showSuccess({...this.props.golfer, ...{club_id: values.club.value, association_id: values.association.value}});
				}
			})
			.catch(err => {

				if(err.data) {
					let errorMessage = processErrorMessage(err.data.errors);
					this.setState({
						error: errorMessage
					})
				}
				console.error(err)
			});
	}

	render() {
		const {handleSubmit, pristine, valid, submitting} = this.props;
		const membershipForJunior = this.props.membership_code && this.props.membership_code.type === "Junior";
		const golferAge = this.props.golfer.date_of_birth ? moment().diff(moment(this.props.golfer.date_of_birth), 'years') : this.props.date_of_birth ? moment().diff(moment(this.props.date_of_birth), 'years') : 30;
		const golferIsMinor =  golferAge < 13;
		const golferIsJunior =  golferAge < 19 && golferAge > 13;
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
					<div className="row with-padding ">
							Name: &#xA0;
								<strong>{this.props.golfer.first_name} {this.props.golfer.middle_name} {this.props.golfer.last_name}
									{this.props.golfer.is_trial && (
									<span
										style={{
											fontWeight: 'bold',
											color: 'red',
										}}	
									>
										{" (TRIAL)"}
									</span>
									)}
								</strong>
					</div>
					<div className="row with-padding ">
							Club Name{this.props.golfer.clubs.length > 1 ? 's' : ''}:&#xA0;
							<strong>{this.props.golfer.clubs.sort((a, b) => (a.club_name > b.club_name) ? 1 : -1).map(club => {
								return <Fragment> {club.club_name}<br/></Fragment>;
							})}</strong>
					</div>
					<div className="row with-padding ">
							Handicap Index: &#xA0; <strong>{this.props.golfer.handicap_index}</strong>
					</div>
					<div className="row with-padding ">
							GHIN #: &#xA0; <strong>{this.props.golfer.id}</strong>
					</div>

            {this.props.golfer.email && (
              <div className="row with-padding ">
                Email Address: &#xA0;{" "}
                <strong>
                  {this.props.golfer.email}
                </strong>
              </div>
            )}

					<div className="row">
						<div className="col">
							<label htmlFor="association">Association <span>*</span></label>
							<Field name={'association'}
								   validate={[required]}
								   component={SelectInput}
								   disabled={this.props.access.user_type === "club-admin" || this.props.access.user_type === "association-admin"}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   onChange={(data) => this.onAssociationChange(data)}
								   options={this.state.associations}/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<label htmlFor="club">Club Name <span>*</span></label>
							<Field name={'club'}
								   validate={[required]}
								   component={SelectInput}
								   disabled={this.props.access.user_type === "club-admin" || this.state.clubs.length === 0}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={this.state.clubs}
								   onChange={(data) => this.getMembershipTypes(data.id)}
							/>
						</div>
					</div>

					<div className="row" style={{marginBottom: !this.props.canEditAssociation && this.state.areLockedMemberships ? 0 : ""}}>
						<div className="col is-1-of-2">
							<label htmlFor="membership_code">Membership Code <span>*</span></label>
							<Field name={'membership_code'}
								   validate={[requiredSelect]}
								   component={SelectInput}
								   disabled={(!this.props.club && !this.props.clubSelector) || this.state.membershipTypes.length === 0 }
								   className="react-select-container memberships-select-container"
								   classNamePrefix="react-select"
								   options={this.state.membershipTypes}/>
							{this.props.clubSelector && this.state.membershipTypes.length === 0 && this.state.membershipTypesLoaded && <p className="validation__message is-error">Membership Types need to be added to the club prior to adding golfers.</p> }
						</div>
						<div className="col is-1-of-2" style={{marginTop: "23px"}}>
							{!this.props.canEditAssociation && <div className="row" style={{marginBottom: 0}}>
								{this.state.areLockedMemberships && <div className="locked-info-box-existing-golfer">
									<div><i className="material-icons-outlined lock-icon-container"/><span style={{fontWeight: '500'}}> Membership Code is Locked </span></div>
									<div style={{marginTop: '10px'}}> Locked Membership codes cannot be manually removed or assigned.</div>
									<div style={{marginTop: '10px'}}> Please contact your association for assistance.</div>
								</div>}
							</div>}
							{this.props.canEditAssociation && this.state.areLockedMemberships && <div className="row">
								<div className="locked-membership-info-box" style={{position: "relative", top: "0px", right: '-10px', width: 'calc(100% - 20px)', padding: '10px'}}>
									<div><i className="material-icons-outlined lock-icon-container lock-icon-container-relative"/><span style={{fontWeight: '500'}}> Membership Code is Locked </span></div>
								</div>
							</div>}
						</div>
					</div>

					{!this.props.golfer.date_of_birth &&
            <div className="row">
              <div className="col is-1-of-2">
                <label htmlFor="date_of_birth">Birthday {membershipForJunior && <span>*</span>}</label>
                <Field maxDate={new Date()}
                    className={'date'}
                    showMonthDropdown
                    showYearDropdown
                    name={'date_of_birth'}
                    id={'date_of_birth'}
                    validate={membershipForJunior ? [required] : []}
                    component={DatePickerInput}
                    placeholder="Select ... " />
              </div>
            </div>
					}

          <div className="row">
            {!golferIsMinor && !this.props.golfer.email && (
                <div className="col is-1-of-2">
                  <label htmlFor="email">Email {!this.state.selectedGuardian && !this.props.golfer.has_active_guardians ? <span>*</span> : null}</label>
                  <Field
                    component={InputField}
                    disabled={golferIsMinor}
                    validate={this.state.selectedGuardian || this.props.golfer.has_active_guardians ? [isEmailAddress] : [required, isEmailAddress]}
                    type="text"
                    name={golferIsMinor ? "asd" : "email"}
                    id={golferIsMinor ? "asd" : "email"}
                  />
                </div>
            )}
          </div>							

					{
						!this.props.golfer.has_active_guardians && (golferIsMinor || golferIsJunior) ? <GhinGuardianFormComponent2 formGuardian={this.props.guardian} selectGuardian={() => this.selectGuardian()} change={this.props.change}  minor={golferIsMinor} junior={golferIsJunior} untouch={this.props.untouch} /> : null
					}

					{this.state.error && <span className={'validation__message is-error'}>{this.state.error}</span>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} onClick={(e) => {
								e.preventDefault();
								this.props.resetToSelect()
							}} className="btn fill gray">Back
							</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'} disabled={pristine || !valid || submitting} className="btn fill blue">Add Golfer
							</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

AddExistingGolferForm = reduxForm({
	form: 'addExistingGolferForm',
	destroyOnUnmount: true
})(AddExistingGolferForm);

function mapStateToProps(state) {
	const selector = formValueSelector('addExistingGolferForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		clubSelector: selector(state, 'club'),
		membership_code: selector(state, 'membership_code'),
		access: state.user.access,
		date_of_birth: selector(state, 'date_of_birth'),
		guardian: selector(state, 'guardian'),
    email: selector(state, 'email')
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddExistingGolferForm));
