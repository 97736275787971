import moment from 'moment';

export function addAlert(data) {
	data.timestamp = moment().unix() + 10;
	return {
		type: "@@ghin/ADD_ALERT",
		payload: data
	}
}

export function removeAlert(key) {
	return {
		type: "@@ghin/REMOVE_ALERT",
		payload: key
	}
}

export function toggleAlert(bolean) {
	return {
		type: "@@ghin/SHOW_PRODUCT_ALERTS",
		payload: bolean
	}
}

export function saveAlert(data) {
	return {
		type: "@@ghin/PRODUCT_ALERTS_DATA",
		payload: data
	}
}

export function toggleAssociationAlert(value) {
  return {
    type: '@@ghin/SHOW_ASSOCIATION_ALERT',
    payload: value
  }
}

export function saveAssociationAlert(data) {
  return {
    type: '@@ghin/ASSOCIATION_ALERT_DATA',
    payload: data
  }
}

export function clearAssociationAlert() {
  return {
    type: '@@ghin/CLEAR_ASSOCIATION_ALERT_DATA'
  }
}
