import React, {Component, Fragment} from 'react';
import TableFilter from "./TableFilter";
import {NavLink} from 'react-router-dom';
import GhinTable from "../../shared/GhinTable";
import debounce from 'debounce-promise';
import GolferService from "../../../services/api/golfer";
import TableService from "../../../services/tableService";
import { connect } from 'react-redux';
import { retryWhen } from 'rxjs';

const generateGolferName = (row) => {
	return row.last_name + (row.suffix ? ' ' + row.suffix + ', ' : ', ') + (row.prefix ?  row.prefix + ' ' : '') + row.first_name + ' ' + (row.middle_name ? row.middle_name : '');
};
class GolferSearch extends Component {


    constructor(props) {
        super(props);
		const query = decodeURI(this.props.location.search.substring(3))
        this.state = {
            tableFilterIsHidden: false,
            associations: [],
            filtered: [{id: "is_test", value: false}, {id: "include_trial_golfers", value: false}],
            sorted: [{id: "full_name", desc:false}],
            searchText: query|| '',
            isAllSelected: false,
            golfers: [],
            page: 0,
            per_page: 25,
            isExportDataOpened: false,
            selectedGolfers: [],
            tableInternalData: [] //used to get filtered array from the table
        };
      if (this.props.user.access.user_type === 'association-admin') {
        var association_filter = { id: 'association_id', value: this.props.user.access.default_association_id, label: this.props.user.access.default_association_name }
        this.state.association_filter = association_filter
        this.state.filtered.push(association_filter)
      }
    }

    componentDidMount() {
        this.loadGolferList();
    }

    getRequestParams() {

        let params = {
            page: (this.state.page) + 1,
            per_page: this.state.per_page,
            global_search: true
        };

        if (this.state.sorted.length > 0) {
            params['sorting_criteria'] = this.state.sorted[0].id;
            params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
        }

        params = {
            ...params,
            ...TableService.filtersArrayToParams(this.state.filtered)
        }

        if ('include_trial_golfers' in params) {
            if (params['include_trial_golfers'])
                params['is_trial'] = true;
            else params['is_trial'] = false;
            delete params.include_trial_golfers;
        } else {
            params['include_trial_golfers'] = true;
        }

        if (!params['search']) {
            params['search'] = this.state.searchText;
        }

        return params;
    }

    updateFilter(filtered) {
        this.setState({
            filtered,
            page: 0
        }, () => {
            this.loadGolferList();
        })

    }

    loadGolferList = debounce(() => {
			this.setState({
				loading: true,
				golfers: []
			});
			const params = this.getRequestParams();

			GolferService.getGolfers(params).then((data) => {
				this.setState({
					loading: false,
					isAllSelected: false,
					global_search: true,
					golfers: data.golfers.map((i) => {
						return {
							...i,
							...{full_name: generateGolferName(i)}
							//date: moment(i.date).format("MM/DD/YYYY")
						}
					}),
					total: parseInt(data['total-count'], 10),
					per_page: parseInt(data['per-page'], 10),
					pages: parseInt(data['total-pages'], 10)
				})
			});
		}, 1000);

    render() {
        let su = this.props.user.access.super_user
        const columns = [
            {
                accessor: 'id',
                show: false
            },
            {
                accessor: 'last_name',
                show: false
            },
            {
                Header: 'Name',
                accessor: 'full_name',
                filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1,
                Cell: props =>
                    <Fragment>
                        { su || !props.row._original.is_trial ? (
                            <NavLink to={`/manage/association/${props.original.association_id}/club/${props.original.club_id}/golfer/${props.original.ghin}`}>{props.row.full_name}</NavLink>
                        ) : (
                            props.row.full_name
                        )}
						{
                            props.row._original.is_trial && (
                                <span style={{ color: 'red' }}>(Trial)</span>
                            )
                        }
					</Fragment>
                ,
                // colClassName: 'col_golfer-name-short'
            },
            {
                Header: 'Status',
                accessor: 'status',
                colClassName: 'col_status'
            },
            {
                Header: 'H.I.',
                accessor: 'handicap_index',
				        colClassName: 'col_handicap-index'
            },
            {
                Header: 'GHIN #',
                accessor: 'ghin',
                colClassName: 'col_gender'
            },
            {
                Header: 'Gender',
                accessor: 'gender',
                colClassName: 'col_gender'
            },
            {
                Header: 'Email Address',
                accessor: 'email',
				sortable: false,
				colClassName: 'col_club-name',
				Cell: props =>
					props.row._original.entitlement ?
						<Fragment>
							<a href={`mailto:${props.value}`}>{props.value}</a>
						</Fragment>
						:
						<span>{props.value}</span>

            },
			{
				Header: <Fragment>Golfer <br/> State/Prov</Fragment>,
				accessor: 'state',
				colClassName: 'col_state'
			},
            {
                Header: 'Club Name',
                accessor: 'club_name',
                colClassName: 'col_club-name',
				Cell: props =>
					props.row._original.entitlement ?
						<Fragment>
							<NavLink to={`/manage/association/${props.original.association_id}/club/${props.original.club_id}`}>{props.value}</NavLink>
						</Fragment>
						:
						<span>{props.value}</span>
				,
            },
            {
                Header: 'Country',
                accessor: 'country',
				colClassName: 'col_phone-number'
            },


        ];

        return (
            <section className="default">

            <TableFilter updateFiltered={this.updateFilter.bind(this)} searchText={this.state.searchText} association={this.state.association_filter} />

                <h2 className="section__title large">Golfer Results ({this.state.total ? this.state.total : 0})</h2>

                <GhinTable
                    loading={this.state.loading}
                    columns={columns}
                    pages={this.state.pages}
										page={this.state.page}
										total={this.state.total}
                    manual
                    pageSize={this.state.per_page}
                    data={this.state.golfers}
                    ref={(r) => this.table = r}
                    onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.loadGolferList())}
                    onPageSizeChange={(pageSize, pageIndex) => this.setState({
                        per_page: pageSize,
                        page: pageIndex
                    }, () => this.loadGolferList())}
                    sorted={this.state.sorted}
                    onSortedChange={(newSorted, column, shiftKey) => {
                        this.setState({
                            sorted: newSorted,
							page: 0
                        }, () => {
                            this.loadGolferList()
                        })
                    }}
                    hideSelect={true}
                />

            </section>
        )
    }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, null)(GolferSearch)
