import React, {Component, Fragment} from 'react';
import EditFederationAccountForm from '../Forms/EditFederationAccountForm';

export default class EditFederationAccountModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Edit Account</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

						<EditFederationAccountForm profile={this.props.profile} close={(updatedProfile)=>this.props.closeModal(updatedProfile)}/>

                    </div>

                </div>

            </Fragment>
		);
	}
}
