import React, {Component} from 'react';
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {InputField} from "../../../inputs/InputField";
import {SelectInput} from "../../../inputs/Select";
import {required, isEmailAddress} from "../../../inputs/validations";
import UsersService from "../../../services/api/users";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";

class NewUser extends Component {

	constructor(props) {
		super(props);
		this.state = {
			roles: [
				{value: 'admin', label: 'Administrator'},
				{value: 'read_only', label: 'Admin-Read-Only'},
				{value: 'score_only', label: 'Admin-Rapid-Score-Entry-Only'},
				{value: 'score_maintenance', label: 'Admin-Score Posting & Maintenance'}
			],
			active: true
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values) {
		if (this.props.existingUser) {
			let id = values['id'];
			if (values['id']) delete values['id'];

			return UsersService.insertAccess({
				user_access: {
						...values,
						active: values.active  ? 'true': 'false',
						role: values.role.value,
						club_id: parseInt(this.props.clubId),
						association_id: this.props.clubId ?  null : parseInt(this.props.associationId)
					}
				}, id)
				.then(response => {
					this.props.closeModal();
				})
				.catch(err => {
					console.error(err.data.error);
				})
		} else {
			delete values['active'];

			UsersService.insert({
				user: {
					...values,
					role: values.role.value,
					association_id: this.props.clubId ?  null : parseInt(this.props.associationId),
					club_id: this.props.clubId,
					callback_url: window.location.origin + '/auth/set/'
					}
				})
				.then(response => {
					if (this.state.active) {
						UsersService.update({
							user_access: {
									active: this.state.active ? 'true': 'false',
								}
							}, response.user_access.id)
							.then(response => {
								this.props.closeModal();
							})
							.catch(err => {
								console.error(err.data.error);
							})

					} else {
						this.props.closeModal();
					}

				})
				.catch(err => {
					console.error(err);
				})
		}

	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<form onSubmit={handleSubmit(this.onSubmit)}>
				<div className="row">
					<div className="col is-1-of-2">
						<label htmlFor="prefix">Prefix</label>
						<Field component={InputField} type="text" name="prefix" id="prefix" />
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<label htmlFor="first_name">First Name <span>*</span></label>
						<Field validate={[required]} component={InputField} type="text" name="first_name" id="first_name" />
					</div>
					<div className="col is-1-of-2">
						<label htmlFor="middle_name">Middle Name</label>
						<Field component={InputField} type="text" name="middle_name" id="middle_name" />
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<label htmlFor="last_name">Last Name <span>*</span></label>
						<Field validate={[required]} component={InputField} type="text" name="last_name" id="last_name" />
					</div>
					<div className="col is-1-of-2">
						<label htmlFor="suffix">Suffix</label>
						<Field component={InputField} type="text" name="suffix" id="suffix" />
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<label htmlFor="phone">Phone <span>*</span></label>
						<Field validate={[required]} component={InputField} type="text" name="phone" id="phone" />
					</div>
					<div className="col is-1-of-2">
						<label htmlFor="email">Email <span>*</span></label>
						<Field disabled={true} component={InputField} validate={[required, isEmailAddress]} type="text" name="email" id="email" />
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<label htmlFor="role">Role <span>*</span></label>
						<Field name={'role'}
							   component={SelectInput}
							   className="react-select-container"
							   classNamePrefix="react-select"
							   validate={[required]}
							   options={this.state.roles}
						/>
					</div>
				</div>
				<div className={"row"}>
					<div className="col is-1-of-2 ">
						<Field
							onClick={
								() => {
									this.setState((prevState) => {
										return {active: !prevState.active};
									});
								}
							}
							checked={this.state.active}
							id="active"
							value={true}
							name={'active'}
							component={'input'}
							type="checkbox"
						/>
						<label htmlFor="active">Active</label>
					</div>

				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<button type={'button'} onClick={() => {this.props.closeModal()}} className="btn fill gray">Cancel</button>
					</div>
					<div className="col is-1-of-2">
						<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Save</button>
					</div>
				</div>

			</form>
		);
	}
}

NewUser = reduxForm({
	form: 'newUserForm',
	destroyOnUnmount: true
})(NewUser);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
	}
}

export default connect(mapStateToProps, null)(withKeyPress(NewUser));
