import React, {Component} from 'react';
import ReactTableContainer from "react-table-container";

export default class AssociationTable extends Component {

	constructor(props) {
		super(props);

		this.state = {
			allSelected: false,
			data: [],
			sortKeyHistory: "name",
			sortDirection: "asc",
			lastSelectedIndex: "name"
		};

		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		let data = this.props.associations;
		data.sort((a, b) => a['name'].toString().localeCompare(b['name'].toString()));
		this.setState({
			data
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.associations !== this.props.associations) {
			let data = this.props.associations;
			data.sort((a, b) => a['name'].toString().localeCompare(b['name'].toString()));
			this.setState({
				data
			});
		}
	}

	handleInputChange(association, e) {
		if (this.props.isMain) {
			if (e.shiftKey) {
				let lastSelectedIndex = this.state.lastSelectedIndex;
				let currentIndex = this.state.data.indexOf(association);
				if (currentIndex < lastSelectedIndex) {
					lastSelectedIndex = currentIndex
					currentIndex = this.state.lastSelectedIndex
				}
				let selectedData = JSON.parse(JSON.stringify(this.state.data)).slice(lastSelectedIndex, currentIndex + 1);
				this.setState({
					lastSelectedIndex: null
				});
				selectedData.map(a => this.props.toggleAvailableAssociation(a.id, true));
			} else {
				this.setState({
					lastSelectedIndex: this.state.data.indexOf(association)
				});
				this.props.toggleAvailableAssociation(association.id, e.target.checked);
			}
		} else {
			if (e.shiftKey) {
				let lastSelectedIndex = this.state.lastSelectedIndex;
				let currentIndex = this.state.data.indexOf(association);
				if (currentIndex < lastSelectedIndex) {
					lastSelectedIndex = currentIndex
					currentIndex = this.state.lastSelectedIndex
				}
				let selectedData = JSON.parse(JSON.stringify(this.state.data)).slice(lastSelectedIndex, currentIndex + 1);
				this.setState({
					lastSelectedIndex: null
				});
				selectedData.map(a => this.props.toggleSelectedAssociation(a.id, true));
			} else {
				this.setState({
					lastSelectedIndex: this.state.data.indexOf(association)
				});
				this.props.toggleSelectedAssociation(association.id, e.target.checked);
			}
		}
	}

	handleCheckAll(e) {
		if (this.props.isMain) {
			this.props.toggleAllAvailable(e.target.checked);
		} else {
			this.props.toggleAllSelected(e.target.checked);
		}
	}

	onSort(event, sortKey) {
		const data = this.state.data;
		let sortKeyHistory = this.state.sortKeyHistory;
		let sortDirection = this.state.sortDirection;

		if (sortKeyHistory === sortKey) {
			if (sortDirection === "asc") {
				sortDirection = "desc";
				data.sort((a, b) => a[sortKey].toString().localeCompare(b[sortKey].toString())).reverse();
			} else {
				sortDirection = "asc";
				data.sort((a, b) => a[sortKey].toString().localeCompare(b[sortKey].toString()))
			}
		} else {
			data.sort((a, b) => a[sortKey].toString().localeCompare(b[sortKey].toString()));
		}
		this.setState({
			data,
			sortKeyHistory: sortKey,
			sortDirection
		});
	}

	render() {
		return (

			<ReactTableContainer width="100%" height="493px">
				<table className="data__table">
					<colgroup>
						<col className="col_checkbox"/>
						<col className="col_id clubs"/>
						<col />
						<col className="col_status"/>
					</colgroup>
					<thead>
					<tr>
						<th><input onChange={(e) => {this.handleCheckAll(e)}} checked={this.props.checkAll} type="checkbox" name={`${this.props.toggleSelectedAssociation ? "left-" : "right-"}all`} id={`${this.props.toggleSelectedAssociation ? "left-" : "right-"}all`} /><label htmlFor={`${this.props.toggleSelectedAssociation ? "left-" : "right-"}all`}></label></th>
						<th className={'rt-resizable-header -sort-asc -cursor-pointer'} onClick={(e) => this.onSort(e, 'id')}># {this.state.sortKeyHistory === 'id' && <i className="material-icons-outlined">{this.state.sortDirection === 'asc' ?  "arrow_drop_up" : "arrow_drop_down" }</i>}</th>
						<th className={'rt-resizable-header -sort-asc -cursor-pointer'} onClick={(e) => this.onSort(e, 'name')}>{this.props.mainLabelName || 'Association'} {this.state.sortKeyHistory === 'name' && <i className="material-icons-outlined">{this.state.sortDirection === 'asc' ?  "arrow_drop_up" : "arrow_drop_down" }</i>}</th>
						<th className={'rt-resizable-header -sort-asc -cursor-pointer'} onClick={(e) => this.onSort(e, 'status')}>Status {this.state.sortKeyHistory === 'status' && <i className="material-icons-outlined">{this.state.sortDirection === 'asc' ?  "arrow_drop_up" : "arrow_drop_down" }</i>}</th>
					</tr>
					</thead>
					<tbody>
					{!this.state.data.length && <tr>
						<td colSpan="100%">
							No records to display
						</td>
					</tr>}
					{this.state.data.map(association => {
						return (
							<tr key={association.id} data-checked={association.checked}>
								<td><input type="checkbox" checked={association.checked} onChange={(e) => this.handleInputChange(association, e)} name={`${this.props.toggleSelectedAssociation ? "left-" : "right-"}${association.id}`} id={`${this.props.toggleSelectedAssociation ? "left-" : "right-"}${association.id}`} /><label htmlFor={`${this.props.toggleSelectedAssociation ? "left-" : "right-"}${association.id}`}></label></td>
								<td>{association.id}</td>
								<td>{association.name}</td>
								<td>{association.status}</td>
							</tr>
						)
					})}
					</tbody>
				</table>
			</ReactTableContainer>
		)
	}

}
