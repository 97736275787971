import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { addAlert } from "../shared/actions";
import { PickySelect } from "../../inputs/PickySelect"
import { useEffect, useState } from "react";
import JoinAndRenewServices from "../../services/api/joinAndRenew";
import Spinner from "../Manage/Associations/FederationNewsletter/Spinner";

function EditSelectedAddonsFormFunction(props) {
  const { handleSubmit, pristine, submitting, invalid } = props;
  const [addons, set_addons] = useState([])
  const [loading, set_loading] = useState(false)
  const [selectedAddons, set_selectedAddons] = useState(props.selected_addons.map(e => { return { label: e.name, value: e.id } }))

  const onSubmit = (values, dispatch, props) => {
    return JoinAndRenewServices.JoinPageAssignAddons(
      props.join_page.golf_association_id,
      props.join_page.id,
      values.selected_addons.map(e => e.value)
    ).then(res => {
      props.addAlert({ type: 'success', message: 'Join page successfully updated' })
      props.reload_selected_addons()
      props.close_modal()
    })
  }

  useEffect(() => {
    const association_id = props.join_page.golf_association_id
    const initialize = props.initialize
    set_loading(true)
    JoinAndRenewServices.getAddonCharges(association_id, { is_enabled: true }).then(res => {
      set_addons(res.addon_charges)
      set_loading(false)
      initialize({
        selected_addons: props.selected_addons.map(e => { return { label: e.name, value: e.id } })
      })
    })
  }, [props.join_page.golf_association_id, props.initialize, props.selected_addons])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="selected_addons">Choose one or more add-on to be included on this join page during checkout.</label>
      <div className="row">
        <div className="col is-1-of-2">
          <Field
            component={PickySelect}
            name="selected_addons"
            id="selected_addons"
            multiple={true}
            options={addons.map(e => { return { label: e.name, value: e.id } })}
            selected={selectedAddons}
            disabled={loading || addons.length === 0}
            onChange={(e) => {
              set_selectedAddons(e)
            }} 
          />
        </div>
        {loading && <div className="col auto push-left">
          <Spinner className="lds-ring" />
        </div>}
        {!loading && addons.length === 0 && <span className="red" style={{ paddingTop: '6px', paddingLeft: '10px' }}>
          No Add-Ons have been created at this time. To create one, click the “Add-Ons” button in the left navigation.
        </span>}
      </div>
      <div className="row">
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill gray" onClick={() => props.close_modal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type={'submit'} disabled={pristine || submitting || invalid} className="btn fill blue">Save</button>
        </div>
      </div>
    </form>
  )
}

const EditSelectedAddonsForm = reduxForm({
  form: 'EditSelectedAddonsForm',
  destroyOnUnmount: true
})(EditSelectedAddonsFormFunction)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditSelectedAddonsForm))
