import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Modal from 'react-modal';
import {addAlert} from './actions'
import { withRouter } from 'react-router-dom'
import AddGolferModal from "../Modals/AddGolferModal";
import AddNewClubModal from "../Modals/AddNewClubModal";
import AddNewAssociationModal from "../Modals/AddNewAssociationModal";
import {getPermission} from "../../permissionManager";

class PageHead extends Component {

	constructor(props) {

        super(props);

        this.state = {
			AddGolferModalIsOpen: false,
			AddClubModalIsOpen: false,
			AddAssocModalIsOpen: false,
        };

		this.openGolferAddModal = this.openGolferAddModal.bind(this);
		this.openClubAddModal = this.openClubAddModal.bind(this);
		this.openAssocAddModal = this.openAssocAddModal.bind(this);


	}

	openGolferAddModal() {
		this.setState({AddGolferModalIsOpen: true});
	}

	openClubAddModal() {
		this.setState({AddClubModalIsOpen: true});
	}

	openAssocAddModal() {
		this.setState({AddAssocModalIsOpen: true});
	}
	closeModal(data) {
		if(data && data.type === "golfer") {
      const regex = /\/golfer\/[0-9]+\/account\/primary/;
      const onAccountPage = window.location.pathname.match(regex);
			if(data.golfer) {
          this.props.history.push(`/manage/association/${data.golfer.association_id}/club/${data.golfer.club_id}/golfer/${data.golfer.id}/account`);
			} else {
				if(this.props.updateRosterList) {
					this.props.updateRosterList();
				}
			}
      if (onAccountPage && this.props.showGolfer)
        this.props.showGolfer();
		}
		if(data && data.type === "club"){
			if(this.props.reloadClub)
				this.props.reloadClub(data)
			this.props.history.push(`/manage/association/${data.club.club.golf_association_id}/club/${data.club.club.id}/account`);

		}
    if(data && data.type === "association"){
      if(this.props.setRefreshList)
        this.props.setRefreshList(true);
      this.props.history.push(`/manage/association/${data.association.association.id}/account`);
    }

		this.setState({
			AddGolferModalIsOpen: false,
			AddClubModalIsOpen: false,
			AddAssocModalIsOpen: false
		});
	}

	render() {
		var is_trial = false;
		if ((this.props.association && this.props.association.is_trial) || this.props.trial_golfer)
			is_trial = true;
		return (
            <Fragment>
				<div className="page__head">
					<h2 className="page__title">
						{this.props.title}
						{is_trial && (
							<span
								style={{
									fontWeight: 'bold',
									color: 'red',
								}}	
							>
								{" (TRIAL)"}
							</span>
						)}
					</h2>
					{((process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && !this.props.publicView) && <ul className="page__actions">
						{getPermission(this.props.user_type, 'edit-club') && !is_trial && <li><button onClick={this.openGolferAddModal} className="btn fill green">Add Golfer</button></li>}
						{getPermission(this.props.user_type, 'edit-association') && !is_trial && <li><button onClick={this.openClubAddModal} className="btn fill green">Add Club</button></li>}
						{getPermission(this.props.user_type, 'edit-associations') && !is_trial && <li><button onClick={this.openAssocAddModal} className="btn fill green">Add Association</button></li>}
					</ul>}
				</div>
				<Modal
					isOpen={this.state.AddGolferModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddGolferModal association={this.props.association ?  this.props.association.id : this.props.user.access.default_association_id}
									club={this.props.club ? this.props.club : this.props.user.access.default_club_id}
									canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
									closeModal={(data)=>{this.closeModal(data)}} />
				</Modal>

				<Modal
					isOpen={this.state.AddClubModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddNewClubModal association={this.props.association ? this.props.association.id : this.props.user.access.default_association_id} closeModal={(data)=>{this.closeModal(data)}} />
				</Modal>

				<Modal
					isOpen={this.state.AddAssocModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddNewAssociationModal setRefreshList={(data)=>{this.props.setRefreshList(data)}} closeModal={(data)=>{this.closeModal(data)}} />
				</Modal>

            </Fragment>
		);
	}
}
function mapStateToProps(state) {
	return {
		user_type: state.user.access.user_type,
		user: state.user
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({addAlert}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageHead));
