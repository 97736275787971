import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { InputField } from "../../inputs/InputField";
import { isCustomNumeric, maxLength, required, customMsgRequired } from "../../inputs/validations";
import JoinAndRenewServices from "../../services/api/joinAndRenew";
import { addAlert } from "../shared/actions";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";

function EditAddonChargesFunction(props) {
  const [is_optional, set_is_optional] = useState(true)
  const [is_donation, set_is_donation] = useState(false)
  const [is_multiple_options, set_is_multiple_options] = useState(false)

  const { handleSubmit, pristine, submitting, invalid } = props;

  useEffect(() => {
    const selected_addon = props.selected_addon
    const initialize = props.initialize
    if (selected_addon) {
      set_is_optional(selected_addon.is_optional)
      set_is_donation(selected_addon.is_donation)
      set_is_multiple_options(props.selected_addon.is_multiple_options)
    }
    initialize({
      ...selected_addon
    })
  }, [props.initialize, props.selected_addon])

  const onSubmit = (values, dispatch, props) => {
    if (props.operationType === "Edit") {
      return JoinAndRenewServices.updateAddonCharge(props.association_id, values.id, values).then(() => {
        props.reload_addons()
        props.addAlert({ type: 'success', message: 'Add-On successfully edited' })
        props.close_modal()
      })
    }
    if (props.operationType === "Create") {
      values.is_optional ||= is_optional
      return JoinAndRenewServices.createAddonCharge(props.association_id, values).then((data) => {
        if (props.select_addon) {
          props.select_addon(data.addon_charge)
        }
        props.reload_addons()
        props.addAlert({ type: 'success', message: 'Add-On successfully created' })
        props.close_modal()
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col">
          <label htmlFor="name">Add-On Name <span>*</span></label>
          <Field component={InputField} validate={[customMsgRequired('Please provide an Add-On Name'), maxLength([50, 'The Name cannot exceed 50 characters'])]} type="text" name="name" id="name" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="description">Add-On Description <span>*</span></label>
          <Field component={InputField} validate={[customMsgRequired('Please provide an Add-On Description'), maxLength([200, 'The Description cannot exceed 200 characters'])]} type="text" name="description" id="description" />
        </div>
      </div>
      <div className="row">
        <div className="col is-full">
          <span className="label">Is this Add-On optional? <span>*</span></span>
          <div className="radio__container">
            <Field
              id="is-optional-yes"
              value={true}
              onClick={
                () => {
                  set_is_optional(true);
                }
              }
              name={'is_optional'}
              component={'input'}
              checked={is_optional}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="is-optional-yes">Yes</label>

            <Field
              id="is-optional-no"
              value={false}
              onClick={
                () => {
                  set_is_optional(false);
                }
              }
              name={'is_optional'}
              component={'input'}
              checked={!is_optional}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="is-optional-no">No</label>
          </div>
        </div>
      </div>
      {is_optional && <div className="row">
        <div className="col is-full">
          <span className="label">Is this a donation? <span>*</span></span>
          <div className="radio__container">
            <Field
              id="is-donation-yes"
              value={true}
              onClick={
                () => {
                  set_is_donation(true)
                }
              }
              name={'is_donation'}
              component={'input'}
              checked={is_donation}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="is-donation-yes">Yes</label>

            <Field
              id="is-donation-no"
              value={false}
              onClick={
                () => {
                  set_is_donation(false)
                }
              }
              name={'is_donation'}
              component={'input'}
              checked={!is_donation}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="is-donation-no">No</label>
          </div>
        </div>
      </div>}
      {is_donation && is_optional && <div className="row">
        <div className="col is-full">
          <span className="label">Provide Multiple Options? <span>*</span></span>
          <div className="radio__container">
            <Field
              id="is-multiple-options-yes"
              value={true}
              onClick={
                () => {
                  set_is_multiple_options(true)
                }
              }
              name={'is_multiple_options'}
              component={'input'}
              checked={is_multiple_options}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="is-multiple-options-yes">Yes</label>

            <Field
              id="is-multiple-options-no"
              value={false}
              onClick={
                () => {
                  set_is_multiple_options(false)
                }
              }
              name={'is_multiple_options'}
              component={'input'}
              checked={!is_multiple_options}
              className="simple__radio"
              type="radio"
            />
            <label htmlFor="is-multiple-options-no">No</label>
          </div>
        </div>
      </div>}
      {is_donation && is_optional && is_multiple_options ?
        <Fragment>
          <div className="row">
            <div className="col">
              The following options will be provided to golfers during the join flow.
            </div>
          </div>
          <div className="row">
            <div className="col is-1-of-3">
              <div className="border_box centered">
                $5
              </div>
            </div>
            <div className="col is-1-of-3">
              <div className="border_box centered">
                $10
              </div>
            </div>
            <div className="col is-1-of-3">
              <div className="border_box centered">
                $20
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col is-1-of-3">
              <div className="border_box centered">
                $100
              </div>
            </div>
            <div className="col is-1-of-3">
              <div className="border_box centered">
                $200
              </div>
            </div>
            <div className="col is-1-of-3">
              <div className="border_box centered">
                Custom Amount
              </div>
            </div>
          </div>
        </Fragment>
        :
        <div className="row">
          <div className="col is-1-of-4">
            <label htmlFor="amount">Amount <span>*</span></label>
            <Field component={InputField} validate={[customMsgRequired('Please provide a numerical Add-On amount'), isCustomNumeric(8)]} placeholder={'0.00'} type="text" name="amount" id="amount" />
          </div>
        </div>}
      <div className="row">
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill gray" onClick={() => props.close_modal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type={'submit'} disabled={pristine || submitting || invalid} className="btn fill blue">Save</button>
        </div>
      </div>
    </form>
  )
}

const EditAddonChargeForm = reduxForm({
  form: 'EditAddonChargeForm',
  destroyOnUnmount: true
})(EditAddonChargesFunction)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditAddonChargeForm))
