import React, {Component, Fragment} from 'react';
import moment from 'moment';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {required} from "../../inputs/validations";
import GolferService from "../../services/api/golfer";
import {addAlert} from '../shared/actions'
import {DatePickerInput} from "../../inputs/DatePicker";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";



class EditGolfersStatusForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			now: true,
			future: false,
			type: this.props.isAllSelected ? 'filtered' : 'selected'
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.props.change('now', true);
		this.props.onRef(this);
		this.setState({
			type: this.props.isAllSelected ? 'filtered' : 'selected'
		})
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {

		if (values.future && !values.date) {
			throw new SubmissionError({date: "This field is required"})
		}
		let params = {};
		if(this.state.type === "filtered") {
			params = {...params, ...props.filters};
			delete params['page'];
			delete params['per_page'];
		}
		let data = {}

		if (!this.props.isAllSelected) {
			data.golfer_ids  = props.golfers
		} else {
			params['select_all'] = true;
			data.unchecked_items = props.golfers
		}

		if (props.type === "activate") {
			return GolferService.activateGolfers(props.clubId, data, values.future ?  moment(values.date).format('YYYY-MM-DD') : null, params).then(res=>{
				props.onSuccess(res, values.now);
			})
		} else {
			return GolferService.inactivateGolfers(props.clubId, data, values.future ?   moment(values.date).format('YYYY-MM-DD') : null, params).then(res=>{
				props.onSuccess(res, values.now);
			})
		}
	}


	render() {
		const {handleSubmit, pristine, submitting} = this.props;

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>

					<span className="label" >When would you like to {this.props.type} the selected golfers?</span>

					<div className="row">
						<div className="col is-1-of-2">
							<Field
								onClick={
									() => {
										this.setState({
											now: true,
											future: false
										});
										this.props.change('future', false)
									}
								}
								checked={this.state.now}
								id="now"
								value={true}
								name={'now'}
								component={'input'}
								type="checkbox"
							/>
							<label htmlFor="now">Now</label>

						</div>
					</div>

					<div className={'row'}>
						<div className="col is-1-of-2">
							<Field
								onClick={
									() => {
										this.setState({
											now: false,
											future: true
										});
										this.props.change('now', false)
									}
								}
								checked={this.state.future}
								id="future"
								value={true}
								name={'future'}
								component={'input'}
								type="checkbox"
							/>
							<label htmlFor="future">Future Date</label>
						</div>
					</div>

					{this.state.future &&<div className="row">
						<div className="col is-1-of-3">
							<label htmlFor="date">Date <span>*</span></label>
							<Field minDate={new Date(moment().add(1, 'days'))}
								   className={'date'}
								   showMonthDropdown
								   validate={[required]}
								   showYearDropdown
								   name={'date'}
								   value={null}
								   id={'date'}
								   component={DatePickerInput}
									 onFocus={e => this.props.change('date', new Date(moment().add(1,'days')))}
								   placeholder="Select ..." />

						</div>
					</div>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={()=>this.props.close()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={pristine || submitting} className="btn fill blue">Submit</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

EditGolfersStatusForm = reduxForm({
	form: 'editGolfersStatusForm',
	destroyOnUnmount: true
})(EditGolfersStatusForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditGolfersStatusForm));
