import React, {Component, Fragment} from 'react';
import {Field, formValueSelector, reduxForm, change as changeWithForm, getFormSyncErrors, destroy} from "redux-form";
import {InputField} from "../../inputs/InputField";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {required, scoreBetweenValues} from "../../inputs/validations";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class ManualCourseForm extends Component {

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));
		if(this.props.nrHoles === "9") {
			this.props.changeWithForm('totalForm', 'total', parseInt(data['front9']));
			data['total-cr'] = data['front-cr'];
			data['total-slope'] = data['front-slope'];
		}
		this.props.setManualValues(data)
	}

	componentDidMount() {
		if(this.props.course) {
			if (this.props.nrHoles === "9") {
				this.props.initialize( {
					course_name: this.props.course,
					front9: this.props.front9,
					'front-cr': this.props.frontCr,
					'front-slope': this.props.frontSlope,
				});

			} else {
				this.props.initialize({
					course_name: this.props.course,
					front9: this.props.front,
					back9: this.props.back,
					total: this.props.total,
					'front-cr': this.props.frontCr,
					'back-cr': this.props.backCr,
					'total-cr': this.props.totalCr,
					'front-slope': this.props.frontSlope,
					'back-slope': this.props.backSlope,
					'total-slope': this.props.totalSlope
				})
			}
		}
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	changeTotal(event, newValue, prevValue, fieldName, totalFieldName, formName) {
		if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
			let valueToChange = parseFloat(this.props[fieldName]) || 0;
			this.props.change(totalFieldName, valueToChange);
		}

		if(newValue.length === 0) {
			this.props.change(totalFieldName, '');
			return;
		}
		if (newValue.length === 1) {
			if (parseFloat(newValue) === 0 || !parseFloat(newValue)) {
				event.preventDefault();
				return;
			}

			let valueToChange = parseFloat(this.props[fieldName]) || 0;
			this.props.change(totalFieldName, valueToChange + parseFloat(newValue));
		}

		if (fieldName !== 'backSlope' && fieldName !== 'frontSlope') {
			if(newValue && ((newValue.length === 3 && !/^[0-9]{1,2}([.])?$/.test(newValue)) || (newValue.length === 2 && !/^\d+$/.test(newValue)) || (newValue.length > 3 && !/^[0-9]{1,2}([.][0-9]{1})?$/.test(newValue)))){
				event.preventDefault();
			} else {
				if( (newValue >=2) && (/^[0-9]{1,2}([.])?$/.test(newValue) || /^[0-9]{1,2}([.][0-9]{1})?$/.test(newValue))) {
					let valueToChange = parseFloat(this.props[fieldName]) || 0;
					if(parseInt(valueToChange + parseFloat(newValue)) === parseFloat(valueToChange + parseFloat(newValue))) {
						valueToChange = parseInt(valueToChange + parseFloat(newValue))
					} else {
						valueToChange = parseFloat(valueToChange + parseFloat(newValue)).toFixed(1);
					}
					this.props.change(totalFieldName, valueToChange );
				}
			}

		} else {
			if(newValue && (newValue.length > 3 || !/^[0-9]{1,3}?$/.test(newValue))){
				event.preventDefault();
			}
			if(newValue.length >= 1 && newValue.length <=3) {
				let valueToChange = parseFloat(this.props[fieldName]) || 0;
				this.props.change(totalFieldName, Math.floor((valueToChange + parseFloat(newValue))/2));
			}
		}

	}


	render() {
		const {handleSubmit, submitting} = this.props;
		const totalEntry = (this.props.nrHoles === "18" &&
			(((this.props.total && this.props.total.length)|| (this.props.totalCr && this.props.totalCr.length) || (this.props.totalSlope && this.props.totalSlope.length))
		));
		const started9Holes = this.props.front || this.props.back || this.props.frontCr || this.props.backCr || this.props.backSlope || this.props.frontSlope;
		const pristineFields = (!this.props.front && !this.props.back && !this.props.total && !this.props.frontCr && !this.props.backCr && !this.props.totalCr && !this.props.backSlope && !this.props.frontSlope && !this.props.totalSlope);
		return (
            <Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit.bind(this))} ref={'form-manual-entry'}>
					<div className="row">
						<div className="col">
							<label  htmlFor={'name'}>Course Name Played<span>*</span></label>
							<Field validate={[required]}
								   component={InputField}
								   disabled={this.props.course ? true : false}
								   type="text"
								   name={'course_name'}
								   id={'course_name'}/>
						</div>
					</div>

					<div className="row">
						<div className="col "></div>
						<div className="col  is-1-of-4">Score</div>
						<div className="col  is-1-of-4">CR</div>
						<div className="col  is-1-of-4">Slope</div>
					</div>

					<div className="row relative">
						<div className="col jc-c ai-fe ">Front 9</div>
						<div className="col  is-1-of-4">
							<Field disabled={!started9Holes && totalEntry && !pristineFields}
								   validate={!(!started9Holes && totalEntry && !pristineFields) && [required, scoreBetweenValues([27, 90, 'Score'])]}
								   hideErrorMessages={true}
								   component={InputField}
								   type="text"
								   name={'front9'}
								   onChange={(event, newValue, prevValue) => {
								   	if(this.props.nrHoles === "18") {
										if(newValue.length === 0) {
											this.props.changeWithForm('totalForm', 'total', '');
											if (this.props.nrHoles === '18') this.props.change('total', '');
											return;
										}
										this.props.changeWithForm('totalForm', 'front9', newValue);
										if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
											let backValue = parseInt(this.props.back) || 0;
											if (this.props.nrHoles === '18') this.props.change('total', backValue);
											this.props.changeWithForm('totalForm', 'total', backValue);
										}
										if (newValue.length === 1) {
											if (parseInt(newValue) === 0) {
												event.preventDefault();
												return;
											}

											let backValue = parseInt(this.props.back) || 0;
											if (this.props.nrHoles === '18') this.props.change('total', backValue + parseInt(newValue));
											this.props.changeWithForm('totalForm', 'total', backValue + parseInt(newValue));
										}
										if (newValue.length > 2) {
											event.preventDefault();
											return;
										}
										if(newValue.length === 2) {
											let backValue = parseInt(this.props.back) || 0;
											if (this.props.nrHoles === '18') this.props.change('total', backValue + parseInt(newValue));
											this.props.changeWithForm('totalForm', 'total', backValue + parseInt(newValue));
											//document.getElementsByClassName('back9')[0].focus();
										}
									} else {
										if(newValue && !/^[0-9]{1,2}?$/.test(newValue)) {
											event.preventDefault();
										}
									}

								}}/>
						</div>
						<div className="col  is-1-of-4">
							<Field onChange={(e, newValue, prevValue) => {
										if (this.props.nrHoles === '18') {
											this.changeTotal(e, newValue, prevValue, 'backCr', 'total-cr',null)
										} else {
											if(newValue && !/^[0-9]{1,2}\.?[0-9]*$/.test(newValue)) {
												e.preventDefault();
											}
										}
									}}
								   disabled={!started9Holes && totalEntry && !pristineFields}
								   validate={!(!started9Holes && totalEntry && !pristineFields) && [required, scoreBetweenValues([24,45, 'Course rating',true])]}
								   hideErrorMessages={true}
								   component={InputField}
								   type="text"
								   name={'front-cr'}
								   id={'front-cr'}/>
						</div>
						<div className="col  is-1-of-4">
							<Field onChange={(e, newValue, prevValue) => {
										if (this.props.nrHoles === '18') {
											this.changeTotal(e, newValue, prevValue, 'backSlope', 'total-slope',null)
										} else {
											if(newValue && !/^[0-9]{1,3}?$/.test(newValue)) {
												e.preventDefault();
											}
										}
									}}
								   validate={!(!started9Holes && totalEntry && !pristineFields) && [required, scoreBetweenValues([55,155, 'Slope'])]}
								   hideErrorMessages={true}
								   disabled={!started9Holes && totalEntry && !pristineFields}
								   component={InputField}
								   type="text"
								   name={'front-slope'}
								   id={'front-slope'}/>
						</div>
						{((!totalEntry && !pristineFields) || pristineFields)  && <label className={"absolute-centered"}><span>*</span></label>}
					</div>

					<div className="row relative">
						<div className="col jc-c ai-fe ">Back 9</div>
						<div className="col  is-1-of-4">
							<Field disabled={(!started9Holes && totalEntry && !pristineFields) || this.props.nrHoles === "9"}
								   className={'back9'}
								   validate={!((!started9Holes && totalEntry && !pristineFields) || this.props.nrHoles === "9") && [required, scoreBetweenValues([27, 90, 'Score'])]}
								   hideErrorMessages={true}
								   component={InputField}
								   type="text"
								   name={'back9'}
								   id={'asd'}
								   onChange={(event, newValue, prevValue) => {
										this.props.changeWithForm('totalForm', 'back9', newValue);
									    if(newValue.length === 0) {
										    this.props.changeWithForm('totalForm', 'total', '');
										    if (this.props.nrHoles === '18') this.props.change('total', '');
										    return;
									    }
										if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
											let frontValue = parseInt(this.props.front) || 0;
											if (this.props.nrHoles === '18') this.props.change('total', frontValue);
											this.props.changeWithForm('totalForm', 'total', frontValue);
										}
										if (newValue.length === 1) {
											if (parseInt(newValue) === 0) {
												event.preventDefault();
												return;
											}
											let frontValue = parseInt(this.props.front) || 0;
											if (this.props.nrHoles === '18') this.props.change('total', frontValue + parseInt(newValue));
											this.props.changeWithForm('totalForm', 'total', frontValue + parseInt(newValue));
										}
										if (newValue.length > 2) {
											event.preventDefault();
										}
										if(newValue.length === 2) {
											let frontValue = parseInt(this.props.front) || 0;
											if (this.props.nrHoles === '18') this.props.change('total', frontValue + parseInt(newValue));
											this.props.changeWithForm('totalForm', 'total', frontValue + parseInt(newValue));
										}
									}}/>
						</div>
						<div className="col  is-1-of-4">
							<Field onChange={(e, newValue, prevValue) => {
										if (this.props.nrHoles === '18') this.changeTotal(e, newValue, prevValue, 'frontCr', 'total-cr',null)
									}}
								   validate={!((!started9Holes && totalEntry && !pristineFields) || this.props.nrHoles === "9") && [required, scoreBetweenValues([24,45, 'Course rating',true])]}
								   hideErrorMessages={true}
								   disabled={(!started9Holes && totalEntry && !pristineFields) || this.props.nrHoles === "9"}
								   component={InputField}
								   type="text"
								   name={'back-cr'}
								   id={'back-cr'}/>
						</div>
						<div className="col  is-1-of-4">
							<Field onChange={(e, newValue, prevValue) => {
										if (this.props.nrHoles === '18') this.changeTotal(e, newValue, prevValue, 'frontSlope', 'total-slope',null)
									}}
								   validate={!((!started9Holes && totalEntry && !pristineFields) || this.props.nrHoles === "9") && [required, scoreBetweenValues([55,155,'Slope'])]}
								   hideErrorMessages={true}
								   disabled={(!started9Holes && totalEntry && !pristineFields) || this.props.nrHoles === "9"}
								   component={InputField}
								   type="text"
								   name={'back-slope'}
								   id={'back-slope'}/>
						</div>
						{((!totalEntry && !pristineFields && this.props.nrHoles === "18") || (this.props.nrHoles === "18" && pristineFields)) && <label className={"absolute-centered"}><span>*</span></label>}
					</div>

					<div className="row relative">
						<div className="col jc-c ai-fe  ">Total 18</div>
						<div className="col  is-1-of-4">
							<Field onChange={(e, newValue, prevValue) => {
									if(newValue && !/^[0-9]{1,3}?$/.test(newValue)) {
											e.preventDefault()
										} else {
											this.props.changeWithForm('totalForm', 'total', newValue);
										}
									}}
								   disabled={started9Holes || (!totalEntry && !pristineFields) || this.props.nrHoles === "9"}
								   component={InputField}
								   validate={((!pristineFields && this.props.nrHoles !== "9")) && [required, scoreBetweenValues([54,180,'Score'])]}
								   hideErrorMessages={true}
								   type="text"
								   name={'total'}
								   id={'total'}/>
						</div>
						<div className="col  is-1-of-4">
							<Field disabled={started9Holes || (!totalEntry && !pristineFields) || this.props.nrHoles === "9"}
								   validate={((!pristineFields) && this.props.nrHoles !== "9") && [required, scoreBetweenValues([40.0,90.0,'Course rating',true])]}
								   hideErrorMessages={true}
								   onChange={(e, newValue, prevValue) => {
									   if(newValue && !/^[0-9]{1,3}\.?[0-9]*$/.test(newValue)) {
										   e.preventDefault();
									   }
								   }}
								   component={InputField}
								   type="text"
								   name={'total-cr'}
								   id={'total-cr'}/>
						</div>
						<div className="col  is-1-of-4">
							<Field disabled={started9Holes || ( !totalEntry && !pristineFields) || this.props.nrHoles === "9"}
								   validate={((!pristineFields) && this.props.nrHoles !== "9") && [required, scoreBetweenValues([55,155,'Slope'])]}
								   hideErrorMessages={true}
								   onChange={(e, newValue, prevValue) => {
									   if(newValue && !/^[0-9]{1,3}?$/.test(newValue)) {
										   e.preventDefault()
									   }
								   }}
								   component={InputField}
								   type="text"
								   name={'total-slope'}
								   id={'total-slope'}/>
						</div>
						{(!started9Holes && totalEntry && !pristineFields) ? <label className={"absolute-centered"}><span>*</span></label> : null}
					</div>
					{this.props.errors && <div className="row">
						<div className="col ">
							{Object.keys(this.props.errors).map(err => {
								if(err !== "course_name" && this.props.errors[err] !== "This field is required")
									return ( <p className="validation__message is-error">{err.indexOf('total') > -1 ? 'Total' : '' }{err.indexOf('front') > -1 ? 'Front' : '' }{err.indexOf('back') > -1 ? 'Back' : '' }: {this.props.errors[err]}</p>);
								return null;
							})}
						</div>
					</div>}


					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={()=>{this.props.close()}}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={submitting} className="btn fill blue">Save</button>
						</div>
					</div>
				</form>

            </Fragment>
		);
	}
}

ManualCourseForm = reduxForm({
	form: 'manualCourseForm',
	 destroyOnUnmount: false
})(ManualCourseForm);

function mapStateToProps(state) {
	const selector = formValueSelector('manualCourseForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		total: selector(state, 'total'),
		front: selector(state, 'front9'),
		back: selector(state, 'back9'),
		frontCr: selector(state, 'front-cr'),
		backCr: selector(state, 'back-cr'),
		totalCr: selector(state, 'total-cr'),
		frontSlope: selector(state, 'front-slope'),
		backSlope: selector(state, 'back-slope'),
		totalSlope: selector(state, 'total-slope'),
		errors: getFormSyncErrors('manualCourseForm')(state)
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert, changeWithForm, destroy}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(ManualCourseForm));
