export function userReducer(state = [], action) {
	switch(action.type) {
		case '@@ghin/ADD_LOGIN_USER_DATA':
			return {
				...state,
				first_name: action.payload.first_name,
				last_name: action.payload.last_name,
        user_id: action.payload.id,
			};
		case '@@ghin/ADD_USER_ACCESS':
			return {
				...state,
				rawAccess: action.payload
			}
		case '@@ghin/ADD_USER_PARSED_ACCESS':
			return {
				...state,
				access: action.payload
			}
		default:
			return state;
	}
}
