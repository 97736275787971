import React, {Component, Fragment} from 'react';

export default class SelectAddMinorMethod extends Component {

	render() {
		return (
            <Fragment>
                <div className="row">
                    <div className="col">
                        <button onClick={() => {this.props.showSearch()}} className="btn outline gray">Search for Existing Minor</button>
                    </div>
                    <div className="col">
                        <button onClick={() => {this.props.showAdd()}} className="btn outline gray">Add New Minor</button>
                    </div>
                </div>
            </Fragment>
		);
	}
}
