import React, {Component, Fragment} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/es/integration/react';

import Auth from './screens/Auth';
import Home from './screens/Home';
import Manage from './screens/Manage';
import Reports from './screens/Reports';
import AdvancedReports from './screens/AdvancedReports';
import RapidScoreEntry from './screens/RapidScoreEntry';
import Merges from './screens/Merges';
import Search from './screens/Search';
import Privacy from './screens/shared/Privacy';
import Terms from './screens/shared/Terms';
import './styles/main.css';
import PrivateRoute from "./PrivateRoute";
import Cookies from "universal-cookie";
import AffiliationChange from "./screens/Affiliation/Change";
import NotFound from "./screens/NotFound/NotFound";

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			persisted: false
		}
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
	}


	dispatchAuthenticationStatus() {
		const cookies = new Cookies();
		this.props.store.dispatch({
			type: "@@ghin/USER_AUTHENTICATED",
			payload: !!cookies.get("token")
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.state.persisted !== prevState.persisted) {
			this.dispatchAuthenticationStatus()
		}
	}

	render() {
		return (
			<Provider store={this.props.store}>
				<PersistGate onBeforeLift={() => this.setState({persisted: true})} persistor={this.props.persistor}>
					<ConnectedRouter history={this.props.routerHistory}>
						<Fragment>
							<Switch>
								<Redirect exact from="/" to="/auth"/>
								<Route path="/auth" component={Auth}/>
								<PrivateRoute path={'/affiliation/select'} component={AffiliationChange}/>
								<PrivateRoute path="/home" component={Home}/>
								<PrivateRoute path="/manage" component={Manage}/>
								{process.env.REACT_APP_TYPE !== "hv" ? <Fragment>
									<Switch>
										<PrivateRoute path="/reports" component={Reports}/>
										<PrivateRoute path="/advanced-reports" component={AdvancedReports}/>
										<PrivateRoute path="/rapid-score-entry" component={RapidScoreEntry}/>
										<PrivateRoute path="/merges" component={Merges}/>
										<PrivateRoute path="/search" component={Search}/>
										<Route path="/privacy-policy" component={Privacy}/>
										<Route path="/terms-and-conditions" component={Terms}/>
										<Route path="*" component={NotFound} />
									</Switch>
								</Fragment> :  <Route path="*" component={NotFound} />}
							</Switch>
						</Fragment>
					</ConnectedRouter>
				</PersistGate>
			</Provider>
		);
	}
}

export default App;
