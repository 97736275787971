import React, {Component, Fragment} from 'react';

import {Switch, Route, Redirect} from 'react-router-dom';

import Login from './Login';
import Recovery from './Recovery';
import ResetPassword from './ResetPassword'
import {connect} from "react-redux";
import Cookies from 'universal-cookie';
import MainAlert from "../shared/MainAlert";
import NotFound from "../NotFound/NotFound";

class Auth extends Component {
	constructor() {
		super();
		this.state = {
			interval: null
		}
		this.interval =  this.interval.bind(this);
	}

	componentDidMount() {
		let interval = setInterval(this.interval, 1000);
		this.setState({interval});
		if (this.props.isAuthenticated) {
			if(this.props.access && (this.props.user.first_name && this.props.user.last_name)) {
				if (this.props.access.canChangeAffiliation) {
					if (this.props.access.homepage) {
						return this.props.history.push(this.props.access.homepage);
					}
					return this.props.history.push('/affiliation/select')
				} else {
					return this.props.history.push(this.props.access.homepage);
				}
			} else {
				const cookies = new Cookies();
				cookies.remove('token', { path: '/' });
			}

		}
	}

	interval() {
		if(window.$zopim) {
			let launcher = document.getElementById("launcher");
			if (launcher) {
				launcher.style.display = "none";
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.isAuthenticated !== prevProps.isAuthenticated) {
			if (this.props.access.canChangeAffiliation) {
				if (this.props.access.homepage) {
					return this.props.history.push(this.props.access.homepage);
				}
				return this.props.history.push('/affiliation/select')
			} else {
				return this.props.history.push(this.props.access.homepage);
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.state.interval);
	}

	render() {
		return (
            <Fragment>
				<MainAlert />
                <Switch>
                    <Redirect exact from="/auth/" to="/auth/login"/>
                    <Route path="/auth/login" component={Login}/>
                    <Route path="/auth/recovery" component={Recovery}/>
					<Route path="/auth/reset/:reset_password_token" component={ResetPassword}/>
					<Route path="/auth/set/:reset_password_token" component={() => <ResetPassword setPassword={true} />}/>
					<Route component={NotFound} />
				</Switch>
            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		access: state.user.access,
		user: state.user
	}
}

export default connect(mapStateToProps, null)(Auth);
