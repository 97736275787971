import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {required} from "../../inputs/validations";
import {InputField} from "../../inputs/InputField";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import AssociationService from "../../services/api/association";

class AddClubGroup extends Component {


	constructor(props) {
		super(props);
		this.state = {
			error: false,
			errors: []
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		setTimeout(function() {
			document.getElementsByTagName('form')[0].querySelector('input').focus()
		}, 100)
	}
	
	onChange() {
		this.setState({
			error: false,
			errors: []
		});
	}

	onSubmit(values) {
		AssociationService.createClubGroup(this.props.associationId, values.name)
			.then(response => {
				this.props.addAlert({type:'success',message:'Group has been successfully saved'});
				let group = response.club_group;
				group.label = group.name;
				group.value = group.id;
				this.props.selectGroup(group);
				this.props.closeModal();
			})
			.catch(err => {
				if(err.data) {
					this.setState({
						error: true,
						errors: err.data.errors
					});
				}
			});
	}


	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<Fragment>

				<div className="modal__container narrow">

					<div className="modal__head">

						<div className="modal__icon" onClick={() => this.props.closeModal()}><i className="material-icons-outlined">add_circle_outline</i></div>
						<h2 className="modal__title">Add Club Group</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">

						<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
							<div className="row">
								<div className="col is-1-of-2">
									<label htmlFor="name">Club Group Name <span>*</span></label>
									<Field className={'tests'}
										validate={[required]}
										component={InputField}
										type="text"
										name="name"
										id="name"
										onChange={(e) => {this.onChange()}}
										 />
								</div>
							</div>
							{this.state.error && Object.keys(this.state.errors).map(key => {
								return (<div className="row" key={key}>
									<div className="col ">
										<p className="validation__message is-error">{this.state.errors[key]}</p>
									</div>
								</div>)
							})}
							<div className="row">
						<div className="col is-1-of-2">
							<button type="button" className="btn fill gray" onClick={() => this.props.closeModal()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Submit</button>
								</div>
							</div>
						</form>

					</div>

				</div>

			</Fragment>
		);
	}
}

AddClubGroup = reduxForm({
	form: 'addAssocClubGroupForm',
	destroyOnUnmount: true
})(AddClubGroup);

function mapDispatchToProps(dispatch) {
	return bindActionCreators({addAlert}, dispatch);
}


function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClubGroup);
