import React, {Component, Fragment} from 'react';
import AssociationService from "../../services/api/association";
import {addAlert} from "../shared/actions";
import {Field, reduxForm} from 'redux-form';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class EditPrefferedColorPaletteForm extends Component {

	constructor(props){
		super(props);
		this.state={
			selectedColor: this.props.assocSettings.preferred_color_for_app,
			colors: ['10644B', '5E7331', '2592A8', '363893', '023971', '0C63B9', '625143', '948354', '000000', '6F6F6F', 'C45200', '970E16', '00365F']
		}
	}

  componentDidMount(){
    this.props.onRef(this);
  }

  componentWillUnmount(){
    this.props.onRef(undefined);
  }

  onSubmit(values, dispatch, props){
    AssociationService.editGolferProducts(props.assocId, props.assocSettings.id, {preferred_color_for_app: values['color-palette']})
      .then(res => {
        props.addAlert({
          type:'success',
          message:'Mobile App Primary Color settings has been successfully selected'
        });
        props.close(res.association_settings);
      })
      .catch(err=>{console.error(err)});
  }

	renderColors(color){
		return (<div key={color} className="badge-radio col is-1-of-4 jc-c ai-c">
			<Field component={'input'} 
           type="radio"
				   name="color-palette"
				   onChange={()=>{this.selectColor(color)}}
				   id={color}
				   value={color}
				   checked={this.state.selectedColor === color || (color==="00365F" && !this.state.selectedColor)} />
			<label htmlFor={color}>
				<div className={`badge color-${color}`}>{color}</div>
			</label>
		</div>)
	}

  isPristine(){
    return this.props.assocSettings.preferred_color_for_app === this.state.selectedColor;
  }

	selectColor(color){
		this.setState({
			selectedColor: color
		});
	}

	render() {

    const {handleSubmit, submitting} = this.props;

		return (
            <Fragment>

              <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>

                <div className="row">
                    <div className="col is-full">
                        <p className="large margin-bot">
                            Please choose a primary color from the preferred color palette below:
                        </p>
                        <div className="badge-radio__container row fr">
							{this.state.colors.map(color => {
								return this.renderColors(color);
							})}

                        </div>
                    </div>
                </div>

                <div className="row">
						      <div className="col is-1-of-2">
							      <button type={'button'} className="btn fill gray" onClick={() => this.props.close()}>Cancel</button>
						      </div>
						      <div className="col is-1-of-2">
							      <button type={'submit'} ref={'submit'} disabled={this.isPristine() || submitting} className="btn fill blue">Save</button>
						      </div>
					      </div>

              </form>

            </Fragment>
		);
	}
}

EditPrefferedColorPaletteForm = reduxForm({
  form: 'editPrefferedColorForm',
  destroyOnUnmount: true
})(EditPrefferedColorPaletteForm);

function mapStateToProps() {
  return {
    enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditPrefferedColorPaletteForm));
