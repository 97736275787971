import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {
	Field,
	formValueSelector,
	change,
	reduxForm,
	SubmissionError,
	getFormValues
} from "redux-form";
import {connect} from "react-redux";
import moment from 'moment';
import {SelectInput} from "../../../inputs/Select";
import ScoreService from "../../../services/api/score";
import CourseRatingSystemService from "../../../services/api/courseRatingSystem";
import {AsyncSelectInput} from "../../../inputs/AsyncSelectInput";
import debounce from "debounce-promise";
import ClubService from "../../../services/api/club";
import flatten from "lodash.flatten";
import {orderTees} from "../../../services/shared/teesFilter";

class DefaultSelector extends Component {

	constructor(props) {
		super(props);

		this.state = {
			startDate: new Date(),
			dateSetup: [
				{value: 'specific', label: 'Specific Date'},
				{value: 'ascending', label: 'Ascending Date'},
				{value: 'descending', label: 'Descending Date'},
			],
			scoreType: [
				{ value: 'H', label: 'Home'},
				{ value: 'A', label: 'Away'},
				{ value: 'T', label: "Competition"}
			],
			holesOptions: [
				{ value: "18", label: '18 (9 + 9)' },
				{ value: "18t", label: '18 Total' },
				{ value: "9", label: '9 Total' },
			],
			playerEntryMethod: this.props.userType === "club-admin" || this.props.club_role === "score_only" || this.props.club_role === "score_maintenance" ? [
				{ value: 'golfer_id', label: 'GHIN #' },
				{value: 'local_number', label: 'Local # (Members)'},
				{value: 'name', label: 'Name (Members)'}
			]:[
				{ value: 'golfer_id', label: 'GHIN #' }
			],
			courses: [],
			teeSetsOptions: [],
			mensTees: [],
			femalesTees: [],
			selectedCourse: {}
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.getCoursesOptions = this.getCoursesOptions.bind(this);
		this.getMensFemalesTees = this.getMensFemalesTees.bind(this);
	}

	onSubmit(values) {

		let data = {
			golfer_id: values.golfer_id,
			course_id: values.course_name.value,
			tee_set_id: values.tee_set_id.value,
			tee_set_side: parseInt(values.number_of_holes.value) === 9 ? values.tee_set_id.tee_set_side : 'All18',
			played_at: moment(values.played_at).format("YYYY-MM-DD"),
			score_type: values.score_type.value,
			number_of_holes: parseInt(values.number_of_holes.value),
			gender: values.gender
		};
		if (values.number_of_holes.value === "18") {
			data.front9_adjusted= values.front9;
			data.back9_adjusted= values.back9;
			return ScoreService.post18h9and9(data)
				.then(response => {
					if (!response.errors) {
						let data = response.score;
						data.golfer_id = values.golfer_id;
						data.score_type_label = values.score_type.label;
						data.tee_name = values.tee_set_id.label;
						data.front = values.front9;
						data.back = values.back9;
						data.total = values.total;
						this.props.addEntry(data);
						this.props.change('front9', 0);
						this.props.change('back9', 0);
						this.props.change('total', 0);
						this.props.untouch('front9');
						this.props.untouch('back9');
						this.props.untouch('total');
						if (values.date_setup.value === 'ascending') {
							let d = moment(values.played_at).add(1, 'd');
							this.props.change('played_at', new Date(d));
						}

						if (values.date_setup.value === 'descending') {
							let d = moment(values.played_at).subtract(1, 'd')
							this.props.change('played_at', new Date(d));
						}
					} else {
						throw new SubmissionError(response.errors)
					}

				})
				.catch(err => {
					//throw new SubmissionError(err.data.errors)
				})
		} else {
			data.adjusted_gross_score= parseInt(values.total || 0);
			return ScoreService.postAdjusted(data)
				.then(response => {
					if (!response.errors) {
						let data = response.score;
						data.golfer_id = values.golfer_id;
						data.score_type_label = values.score_type.label;
						data.tee_name = values.tee_set_id.label;
						data.front = values.front9;
						data.back = values.back9;
						data.total = values.total;
						this.props.addEntry(data);
						this.props.change('front9', 0);
						this.props.change('back9', 0);
						this.props.change('total', 0);
						this.props.untouch('front9');
						this.props.untouch('back9');
						this.props.untouch('total');
						if (values.date_setup.value === 'ascending') {
							let d = moment(values.played_at).add(1, 'd');
							this.props.change('played_at', new Date(d));
						}

						if (values.date_setup.value === 'descending') {
							let d = moment(values.played_at).subtract(1, 'd')
							this.props.change('played_at', new Date(d));
						}
					} else {
						throw new SubmissionError(response.errors)
					}

				})
				.catch(err => {
					//throw new SubmissionError(err.data.errors)
				})
		}
	}

	getCoursesOptions(searchTerm, callback) {
		if (searchTerm && searchTerm.length > 2) {
			return CourseRatingSystemService.searchCourses(searchTerm)
				.then(response => {
					let options = response.courses.map(c => {
						return {value: c.CourseID, label: `${c.FullName} ${c.City && c.State ? `(${c.City}, ${c.State})` : ''}`, unparsedLabel: c.FullName}
					});
					this.setState({
						courses: options
					});
					this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_courses', options))
					callback(options)
				})
				.catch(err => {

				})
		} return new Promise(resolve => {
			resolve([])
		})

	}

	getMensFemalesTees(response) {
		let mensTees = response.TeeSets.filter(ts => ts.Gender === "Male").map(ts => {
			let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
			let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
			let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
			return {
				label: ts.TeeSetRatingName,
				holes: ts.Holes,
				holesNumber: ts.HolesNumber,
				value: ts.TeeSetRatingId,
				ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
				ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
				ratingBack: back && `${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`,
				ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1)
			}
		});
		orderTees(mensTees);

		let femalesTees = response.TeeSets.filter(ts => ts.Gender === "Female").map(ts => {
			let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
			let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
			let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
			return {
				label: ts.TeeSetRatingName,
				holesNumber: ts.HolesNumber,
				holes: ts.Holes,
				value: ts.TeeSetRatingId,
				ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
				ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
				ratingBack: back && `${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`,
				ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1)
			}
		});
		orderTees(femalesTees);

		if (this.props.number_of_holes.value === "9") {
			mensTees = mensTees.reduce((res, current) => {
				let frontPar = current.holes.reduce((acc, h, index) => {
					if (index < 9) return acc + h.Par;
					return acc + 0;
				}, 0);
				let backPar = current.holes.reduce((acc, h, index) => {
					if (index >= 9) return acc + h.Par;
					return acc + 0;
				}, 0);
				let front = current.ratingFront ?{
					label: current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`,
					unparsedLabel: current.label + ' F9',
					value: current.value,
					tee_set_side: 'F9'
				} : null;
				let back = current.ratingBack ? {
					label: current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`,
					unparsedLabel: current.label + ' B9',
					value: current.value,
					tee_set_side: 'B9'
				}: null;
				if(front && back) {
					return res.concat([front, back])
				}
				if (front) {
					return res.concat([front]);
				}
				if (back) {
					return res.concat([back]);
				}
				return null;
			}, []);
			femalesTees = femalesTees.reduce((res, current) => {
				let frontPar = current.holes.reduce((acc, h, index) => {
					if (index < 9) return acc + h.Par;
					return acc + 0;
				}, 0);
				let backPar = current.holes.reduce((acc, h, index) => {
					if (index >= 9) return acc + h.Par;
					return acc + 0;
				}, 0);
				let front = current.ratingFront ? {
					label: current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`,
					unparsedLabel: current.label + ' F9',
					value: current.value,
					tee_set_side: 'F9'
				} : null;
				let back = current.ratingBack ? {
					label: current.label + " B9 \n" +  `(${current.ratingBack} / ${backPar})`,
					unparsedLabel: current.label + ' B9',
					value: current.value,
					tee_set_side: 'B9'
				} : null;
				if(front && back) {
					return res.concat([front, back])
				}
				if (front) {
					return res.concat([front]);
				}
				if (back) {
					return res.concat([back]);
				}
				return null;
			}, []);
		} else {
			mensTees = mensTees.filter(ts => ts.ratingTotal !== undefined).filter(ts => ts.holesNumber !== 9).map(ts => {
				let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
				let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
				return {label: label, value: ts.value};
			});

			femalesTees = femalesTees.filter(ts => ts.ratingTotal !== undefined).filter(ts => ts.holesNumber !== 9).map(ts => {
				let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
				let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
				return {label: label, value: ts.value};
			});
		}
		return { mensTees, femalesTees };
	}

	componentDidMount() {
		if (this.props.super_user) {
			this.setState({
				playerEntryMethod: [
					{ value: 'golfer_id', label: 'GHIN #' }
				]
			});
			this.props.dispatch(change('multiplePlayerEntryForm', 'date_setup', {value: 'specific', label: 'Specific Date'}));
			this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_number_of_holes', { value: "18", label: '18 (9 + 9)' }));
			this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_score_type', { value: 'H', label: 'Home'}));
		} else {
			if (this.props.club_role && this.props.default_club_id) {
				ClubService.getClubHomeCourses(null, this.props.default_club_id)
					.then(async response => {
						let courses = await Promise.all(response.facilities.map(async hc => {
							let courses = await Promise.all(hc.home_courses.map(home_course => {
								let courseName = hc.name + ' - ' + home_course.name + ` (${home_course.city}, ${home_course.state})`;
								return {
									req: CourseRatingSystemService.getCourseDetails(home_course.course_id),
									teeIdMale: home_course['default_tee_male'] ? home_course['default_tee_male'].id : null,
									teeIdFemale: home_course['default_tee_female'] ? home_course['default_tee_female'].id : null,
									courseName: courseName,
									value: home_course.course_id,
									label: courseName,
									courseId: home_course.id
								};
							}).map(async r => {
								let response = await r.req;
								const { mensTees, femalesTees } = this.getMensFemalesTees(response);
								delete r.req;
								r.maleTees = mensTees;
								r.femaleTees = femalesTees;
								return r;
							}));
							return flatten(courses);
						}));
						let flattenCourses = flatten(courses);
						let defaultCourse = flattenCourses[0];
						this.setState({
							courses: defaultCourse,
							mensTees: defaultCourse ? defaultCourse.maleTees : [],
							femalesTees: defaultCourse ?  defaultCourse.femaleTees : []
						}, () => {
							if (defaultCourse) {
								this.props.change('course_name', defaultCourse);
								this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_male_tees', this.state.mensTees));
								this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_female_tees', this.state.femalesTees));
								this.props.dispatch(change('multiplePlayerEntryForm', 'course_name', defaultCourse));
								this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_course_name', defaultCourse));
								let defaultMenTee = this.state.mensTees.filter(tee => parseInt(tee.value) === parseInt(defaultCourse.teeIdMale));
								let defaultFemaleTee = this.state.femalesTees.filter(tee => parseInt(tee.value) === parseInt(defaultCourse.teeIdFemale));
								this.props.dispatch(change('multiplePlayerEntryForm', 'default_male_tee', defaultMenTee[0]));
								this.props.dispatch(change('multiplePlayerEntryForm', 'default_female_tee', defaultFemaleTee[0]));
								this.props.change('men_tee_set_id', defaultMenTee);
								this.props.change('women_tee_set_id', defaultFemaleTee);
							}
						});
					})
					.catch(err => {
						console.error(err);
					})
			}
		}
	}


	render() {
		let {handleSubmit} = this.props;
		let self = this;
		//let validateFrontBack = !(!this.props.tee_set_id || !this.props.initialized || (this.props.number_of_holes && ((this.props.number_of_holes.value === "18t") || (this.props.number_of_holes.value === "9"))));
		return (
			<Fragment>
				<form onSubmit={handleSubmit(this.onSubmit)}>
					<div className="filters columns">
						<div className="row">
							<div className="col is-1-of-7">
								<label>Date Setup</label>
								<Field name={'date_setup'} component={SelectInput} className="react-select-container" classNamePrefix="react-select" placeholder="Select ... " options={this.state.dateSetup} isSearchable={false}
									onChange={(event, newValue, oldValue) => {
										this.props.dispatch(change('multiplePlayerEntryForm', 'played_at', (new Date())));
										this.props.dispatch(change('multiplePlayerEntryForm', 'date_setup', newValue))
									}}
								/>
							</div>
							<div className="col is-1-of-6">
								<label>Player Entry Method</label>
								<Field component={SelectInput}
									   onChange={(entry) => {
									   		this.props.dispatch(change('multiplePlayerEntryForm', 'player_entry', entry.value));
									   		this.props.dispatch(change('multiplePlayerEntryForm', 'PlayerName', null));
									   		this.props.dispatch(change('multiplePlayerEntryForm', 'golfer_id', null));
									   		this.props.dispatch(change('multiplePlayerEntryForm', 'gender', null));
									   		this.props.dispatch(change('multiplePlayerEntryForm', 'firstName', null));
									   		this.props.dispatch(change('multiplePlayerEntryForm', 'lastName', null));
									   		this.props.dispatch(change('multiplePlayerEntryForm', 'Display', null));
									   		this.props.dispatch(change('multiplePlayerEntryForm', 'Error', null));
										    this.props.dispatch(change('multiplePlayerEntryForm', 'searchable_player_entry', null));
									   }}
									   name={'selectedEntryMethod'}
									   className="react-select-container"
									   classNamePrefix="react-select"
									   options={this.state.playerEntryMethod}
									   isSearchable={false} />
							</div>
							<div className="col is-1-of-10">
								<label>Posting Method</label>
								<Field onChange={(event, newValue, prevValue) => {
									if (prevValue) {
										if (prevValue.value === "18" && newValue.value === "9") {
											this.props.change('front9', null);
											this.props.change('back9', null);
										}
										if (prevValue.value === "18" && newValue.value === "18t") {
											this.props.change('front9', null);
											this.props.change('back9', null);
										}
										if ((prevValue.value === '18t' || prevValue.value === '9') && newValue.value === '18') {
											this.props.untouch('front9');
											this.props.untouch('back9');
										}
									}
									if (newValue) {
										this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_number_of_holes', newValue))
										this.props.dispatch(change('multiplePlayerEntryForm', 'number_of_holes', newValue))
										this.props.change('front9', 0);
										this.props.change('back9', 0);
										this.props.change('total', 0);
										if (newValue.value === "9") {
											if (this.props.score_type) {
												if (this.props.score_type.value && !(this.props.score_type.value === 'H' || this.props.score_type.value === 'A')) {
													this.props.change('score_type', null);
												}
											}
											this.setState({
												scoreType: [
													{value: 'H', label: 'Home'},
													{value: 'A', label: 'Away'},
													{value: 'T', label: "Competition"},
												]
											})
										} else {
											if (newValue.value === "18t") {
												this.setState({
													scoreType: [
														{value: 'H', label: 'Home'},
														{value: 'A', label: 'Away'},
														{value: 'T', label: "Competition"},
													]
												});
											} else {
												if (this.props.score_type) {
													if (this.props.score_type.value && !(this.props.score_type.value === 'H' || this.props.score_type.value === 'A' || this.props.score_type.value === 'T')) {
														this.props.change('score_type', null);
													}
												}
												this.setState({
													scoreType: [
														{value: 'H', label: 'Home'},
														{value: 'A', label: 'Away'},
														{value: 'T', label: "Competition"},
													]
												});
											}

										}
										if (this.props.course_name) {
											CourseRatingSystemService.getCourseDetails(this.props.course_name.value)
												.then(response => {
													let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.props.golfer_gender).map(ts => {
														const total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
														return {label: ts.TeeSetRatingName, holesNumber: ts.HolesNumber, holes: ts.Holes, value: ts.TeeSetRatingId, ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1)}
													});
													orderTees(filteredTS);
													if (this.props.number_of_holes.value === "9") {
														filteredTS = filteredTS.reduce((res, current) => {
															let frontPar = current.holes.reduce((acc, h, index) => {
																if (index < 9) return acc + h.Par;
																return acc + 0;
															}, 0);
															let backPar = current.holes.reduce((acc, h, index) => {
																if (index >= 9) return acc + h.Par;
																return acc + 0;
															}, 0);
															let front = current.ratingFront ? {
																label: current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`,
																unparsedLabel: current.label + ' F9',
																value: current.value,
																tee_set_side: 'F9'
															} : null;
															let back = current.ratingBack ? {
																label: current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`,
																unparsedLabel: current.label + ' B9',
																value: current.value,
																tee_set_side: 'B9'
															} : null;
															if(front && back) {
																return res.concat([front, back])
															}
															if (front) {
																return res.concat([front]);
															}
															if (back) {
																return res.concat([back]);
															}
															return null;
														}, []);
													} else {
														filteredTS = filteredTS.filter(ts => ts.holesNumber !== 9).map(ts => {
															let totalPar = ts.holes.reduce((acc, h, index) => acc + h.Par, 0);
															let label = ts.label + "\n" + `(${ts.ratingTotal} / ${totalPar})`;
															return {label: label, value: ts.value}
														})
													}
													const { mensTees, femalesTees } = this.getMensFemalesTees(response);

													this.setState({
														teeSetsOptions: filteredTS,
														mensTees,
														femalesTees
													});
													this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_male_tees', mensTees));
													this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_female_tees', femalesTees));

													this.props.change('men_tee_set_id', null);
													this.props.change('women_tee_set_id', null);

													this.props.change('tee_set_id', null);
												})
										}
									}
								}} name={"number_of_holes"} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={this.state.holesOptions} isSearchable={false} />
							</div>
							<div className="col is-1-of-9">
								<label>Score Type</label>
								<Field onChange={(event, newValue, prevValue) => {
									this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_score_type', newValue))
									this.props.dispatch(change('multiplePlayerEntryForm', 'score_type', newValue))
								}} name={"score_type"} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={this.state.scoreType} isSearchable={false} />
							</div>
							<div className="col is-2-of-10">
								<label htmlFor="course_name">Course</label>
								<Field onChange={(event, newValue, prevValue) => {
									if (newValue) {
										this.props.dispatch(change('multiplePlayerEntryForm', 'course_name', newValue));
										this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_course_name', newValue));
										CourseRatingSystemService.getCourseDetails(newValue.value)
											.then(response => {
												this.props.change('seasonStart', response.Season.SeasonStartDate);
												this.props.change('seasonEnd', response.Season.SeasonEndDate);
												const { mensTees, femalesTees } = this.getMensFemalesTees(response);
												this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_male_tees', mensTees));
												this.props.dispatch(change('multiplePlayerEntryForm', 'hidden_female_tees', femalesTees));

												this.props.change('men_tee_set_id', null);
												this.props.change('women_tee_set_id', null);
												this.setState({
													mensTees,
													femalesTees
												})
											})
									}
								}} defaultOptions={this.state.courses} loadOptions={debounce(self.getCoursesOptions, 1000)} component={AsyncSelectInput} searchable={true} type="text" autoComplete="off" id="course_name" name="course_name" placeholder="Enter Course Name" grayPlaceholder={true} />
							</div>
							<div className="col">
								<label>Men's Default Tee</label>
								<Field onChange={(event, newValue, prevValue) => {
									this.props.dispatch(change('multiplePlayerEntryForm', 'default_male_tee', newValue));
									if (this.props.formValues.golfer_id && this.props.formValues.gender === "M")
										this.props.dispatch(change('multiplePlayerEntryForm', 'tee_set_id', newValue));
								}} name={'men_tee_set_id'} component={SelectInput} disabled={!this.props.course_name} className="react-select-container" classNamePrefix="react-select" placeholder="Men's Tees" options={this.state.mensTees} isSearchable={false} />
							</div>
							<div className="col">
								<label>Women's Default Tee</label>
								<Field onChange={(event, newValue, prevValue) => {
									this.props.dispatch(change('multiplePlayerEntryForm', 'default_female_tee', newValue));
									if (this.props.formValues.golfer_id && this.props.formValues.gender === "F")
										this.props.dispatch(change('multiplePlayerEntryForm', 'tee_set_id', newValue));
								}} name={'women_tee_set_id'} component={SelectInput} disabled={!this.props.course_name} className="react-select-container" classNamePrefix="react-select" placeholder="Women's Tees" options={this.state.femalesTees} isSearchable={false} />
							</div>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}

}

DefaultSelector = reduxForm({
	form: 'manyPlayersEntryForm',
	destroyOnUnmount: true,
	initialValues: {selectedEntryMethod: { value: 'golfer_id', label: 'GHIN #' }, date_setup: {value: 'specific', label: 'Specific Date'}, number_of_holes: { value: "18", label: '18 (9 + 9)' }, score_type: { value: 'H', label: 'Home'}}
})(DefaultSelector);

function mapStateToProps(state) {
	const selector = formValueSelector('manyPlayersEntryForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		course_name: selector(state, 'course_name'),
		golfer_gender: selector(state, 'gender'),
		number_of_holes: selector(state, 'number_of_holes'),
		score_type: selector(state, 'score_type'),
		tee_set_id: selector(state, 'tee_set_id'),
		total: selector(state, 'total'),
		front: selector(state, 'front9'),
		back: selector(state, 'back9'),
		played_at: selector(state, 'played_at'),
		super_admin: state.user.access.super_user,
		club_role: state.user.access.club_role,
		default_club_id: state.user.access.default_club_id,
		userType: state.user.access.user_type,
		formValues: getFormValues('multiplePlayerEntryForm')(state)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}



export default connect(mapStateToProps, mapDispatchToProps)(DefaultSelector);
