import React, { Fragment, Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { InputField } from '../../inputs/InputField';
import { withKeyPress } from '../shared/highOrderComponent/withKeyPress';
import AssociationAlertsService from '../../services/api/associationAlerts';
import { connect } from 'react-redux';
import { addAlert } from '../shared/actions';

class EditAssociationAlertForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: true,
      confirmAction: false,
      activeChanged: false
    }

    this.addMessage =  'Are you sure you want to display this alert within the Admin Portal?';
    this.removeMessage = 'Are you sure you want to remove this alert from displaying within the Admin Portal?';
  }

  showConfirm = () => {
    if (this.state.activeChanged === true) {
      this.setState({
        confirmAction: true
      });
      this.props.confirmModal();
    }
  }

  onSubmit = (values, dispatch, props) => {
    AssociationAlertsService.updateAlert(props.assocId, values)
      .then(res => {
        props.close(res.association_alert);
      })
      .catch(err => console.log(err))
  }

  componentDidMount() {
		if(this.props.data) {
			this.setState({active: this.props.data.active});
			this.props.initialize(this.props.data);
		} else {
			this.setState({active: false});
			this.props.initialize({
				banner_alert_message: "",
				message_title: "",
				message_body: "",
				active: false,
				source: this.props.type,
				federation_id: 1
			});
		}
	}
  
  render() {

    const { handleSubmit, pristine, submitting } = this.props;
    
    const message = 'The content in the “Banner Alert Message” field will be displayed at the top of each page within the Admin Portal for club admin within your association.  When the banner is clicked, a modal will open and display the content of the “Message Title” and “Message Body” fields.  Uncheck “Display Alert” to stop the message from displaying.';
    const youSureMessage = this.state.active ? this.addMessage : this.removeMessage;

    return (
    <Fragment>
      <form autoComplete='off' onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
        { this.state.confirmAction ? 
          <Fragment>
            <h2>{youSureMessage}</h2>
              <div className="row">
                <div className="col is-1-of-2">
                  <button type={'button'} onClick={() => this.props.close(null)} className="btn fill gray">Cancel</button>
                </div>
                <div className="col is-1-of-2">
                  <button type={'submit'} ref={'submit'} className="btn fill blue">Confirm</button>
                </div>
              </div>
          </Fragment>
        :
          <Fragment>
            <div className="row">
              <div className="col auto push-right">
                <Field
                  onClick={
                    () => {
                      this.setState({active: !this.state.active, activeChanged: true});
                    }
                  }
                  checked={this.state.active}
                  id={'active'}
                  value={true}
                  name={'active'}
                  component={'input'}
                  type="checkbox"
                />
                <label htmlFor={'active'}>Display Alert</label>
              </div>
            </div>
                <div className={"row margin-bottom-32"}>
                  <div className={"col"}>
                    <strong className={"medium"}>{message}</strong>
                  </div>
                </div>
                {this.props.type !== "mobile" && <div className="row">
                  <div className="col">
                    <label htmlFor={'banner_alert_message'}>Banner Alert Message</label>
                    <Field component={InputField}
                        type="text"
                        name={'banner_alert_message'}
                        id={'banner_alert_message'} />

                  </div>
                </div>}
                <div className="row">
                  <div className="col">
                    <label htmlFor={'message_title'}>Message Title</label>
                    <Field component={InputField}
                        type="text"
                        name={'message_title'}
                        id={'message_title'} />

                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label htmlFor={'message_body'}>Message Body</label>
                    <Field component={"textarea"}
                        className={"large-alert"}
                        name={'message_body'}
                        id={'message_body'} />

                  </div>
                </div>
                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={() => this.props.close(null)}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
                      <button
                        disabled={pristine || submitting}
                        type={'submit'}
                        ref={'submit'}
                        className="btn fill blue"
                        onClick={() => this.showConfirm()}
                      >
                        Save
                      </button>
                    </div>
                </div>
          </Fragment> 
        }
      </form>
    </Fragment>
    )
  }

}

EditAssociationAlertForm = reduxForm({
  form: 'editAssociationAlertForm',
  destroyOnUnmount: true
})(EditAssociationAlertForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditAssociationAlertForm));