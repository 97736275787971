import React, {Component, Fragment} from 'react';

import 'react-picky/dist/picky.css';
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import frontendProviders from "../../../shared/frontendProvider";
import TableService from "../../../../services/tableService";




const yesNoOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];


export default class TableFilter extends Component {

	constructor() {

		super();

		this.state = {
		    filters: {}
		};

	}


	updateFilter = (name, value) => {
	    this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        }, () => {
	        this.updateTable();
        })
    };

	updateGroupFilter = (name, value) => {
		if (value.value) {
			this.setState({
				filters: {
					status: [],
					is_aga: null,
					frontend_provider: null,
          is_test: null,
          is_trial: null,
          join_renew_enabled: null,
          association_group_id: value
				},
				isGroupFiltered: true
			}, () => {
				this.updateTable();
			})
		} else {
			this.reset()
		}

	};

	updateTable() {
        let filteredArray = TableService.filtersToArray(this.state.filters);
        this.props.updateFiltered(filteredArray);
    }

    componentDidMount() {
		this.setState({
			filters:{
				status: [{label:'Active',value:'Active'}],
				is_aga: {label: "Select All", value: undefined},
				frontend_provider: process.env.REACT_APP_TYPE !== "hv" ? {label: "GHIN", value: "GHIN"} : null,
        is_test: { value: false, label: "No" },
        is_trial: { value: false, label: "No" },
        join_renew_enabled: { label: "Select All", value: undefined }
			},
			isGroupFiltered: false
		},()=>{
			this.updateTable();
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.gotData !== prevProps.gotData && this.props.gotData === true) {
			this.updateTable();
		}
	}

	reset() {
        this.setState({
			filters: {
				status: [{label:'Active',value:'Active'}],
				is_aga: {label: "Select All", value: undefined},
				frontend_provider: process.env.REACT_APP_TYPE !== "hv" ? {label: "GHIN", value: "GHIN"} : null,
				is_test: {value: false, label: "No"},
                is_trial: { value: false, label: "No" },
            join_renew_enabled: { label: "Select...", value: undefined },
            association_group_id: { label: "Select...", value: undefined }
				},
			isGroupFiltered: false
			}, () => {
            this.updateTable();
        });
    }

	render() {

		if(this.props.hideFilters) return null;

		return (
			<Fragment>

                <div className="filters">

                <div className="row">

                    <div className="filters__inputs col">

                        <div className="row">

                <div className="col col is-1-of-5">
                                <label htmlFor="association-name">Association Name</label>
                                <input type="text"
									   autoComplete="off"
									   value={this.state.filters.name}
									   name="association-name"
									   id="association-name"
									   disabled={this.state.isGroupFiltered}
									   onChange={(e) => {
                                    this.updateFilter('name', e.target.value)
                                }}/>
                            </div>

                <div className={"col col is-1-of-5"}>
                                <GhinFilterSelect
                                    options={[
                                        'Active',
                                        'Inactive',
                                        'Archived'
                                    ]}
									disabled={this.state.isGroupFiltered}
                                    value={this.state.filters.status}
                                    onChange={values => this.updateFilter('status', values)}
                                    label={'Status'}
                                />
                            </div>


                <div className="col col is-1-of-5">
                                <GhinFilterSelect
                                    options={yesNoOptions}
                                    singleChoice={true}
									disabled={this.state.isGroupFiltered}
                                    value={this.state.filters.is_aga}
                                    onChange={values => this.updateFilter('is_aga', values)}
                                    label={'Is AGA ?'}
                                />
                            </div>


                <div className="col col is-1-of-5">
                                <GhinFilterSelect
                                    options={yesNoOptions}
                                    singleChoice={true}
									disabled={this.state.isGroupFiltered}
                                    value={this.state.filters.is_test}
                                    onChange={values => this.updateFilter('is_test', values)}
                                    label={'Is Test Association ?'}
                                />
                            </div>


                <div className="col col is-1-of-5">
                                <GhinFilterSelect
                                    options={frontendProviders}
									singleChoice={true}
									disabled={this.state.isGroupFiltered}
                                    value={this.state.filters.frontend_provider}
                                    onChange={values => this.updateFilter('frontend_provider', values)}
                                    label={'Front End Provider'}
                                />
                            </div>

              </div>

              <div className='row'>
                <div className="col is-1-of-5">
                  <GhinFilterSelect
                    options={yesNoOptions}
                    singleChoice={true}
                    disabled={this.state.isGroupFiltered}
                    value={this.state.filters.join_renew_enabled}
                    onChange={values => this.updateFilter('join_renew_enabled', values)}
                    label={'Online Join and Renew'}
                  />
                </div>

                <div className="col col is-1-of-5">
                    <GhinFilterSelect
                        options={yesNoOptions}
                        singleChoice={true}
                        disabled={this.state.isGroupFiltered}
                        value={this.state.filters.is_trial}
                        onChange={values => this.updateFilter('is_trial', values)}
                        label={'Is Trial Association ?'}
                    />
                </div>
              </div>

            </div>

            <div className="col is-1-of-14">
              <div className="form__separator or top"></div>
							</div>

            <div className="col is-1-of-7">
                                <GhinFilterSelect
                                    options={this.props.availableAssociationGroups}
                                    singleChoice={true}
                                    value={this.state.filters.association_group_id}
                                    onChange={values => this.updateGroupFilter('association_group_id', values)}
                                    label={'Association Group'}
                                />
                            </div>

            <div className="filters__controls col is-1-of-14">
              <button className="btn fill gray top" onClick={this.reset.bind(this)}>Reset</button>
                    </div>

                </div>

                </div>

            </Fragment>
		);
	}
}
