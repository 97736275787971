import React, {Component, Fragment} from 'react';

import {SubmissionError, getFormValues, Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {InputField} from "../../../inputs/InputField";
import {required, requiredSelect} from "../../../inputs/validations";
import {bindActionCreators} from "redux";
import SharedService from "../../../services/api/shared";
import GolferService from "../../../services/api/golfer";
import {SelectInput} from "../../../inputs/Select";
import GhinTable from "../../shared/GhinTable";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";

class SearchMinorForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			countries: [],
			states: [],
			golfers: [],
			sorted: [{id: "first_name", desc:false}],
			page: 1,
			pages: 0,
			perPage: 10,
			selected: null,
			loading: true,
		}
	}

	componentDidMount() {
		SharedService.getCountriesAndStates()
			.then((response) => {
				this.setState({
					countries: response.countries.map((item) => {
						return {
							...item,
							value: item.code,
							label: item.name,
							states: item.states.map((state) => {
								return {
									...state,
									value: state.code,
									label: state.name.includes('Armed ') ? state.code : state.name
								}
							})
						}
					})
				})
			})
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onCountryChange(country) {
		this.props.change('state',{label: 'Select State', value: null});
		this.setState({
			states: country.states
		})
	}

	onSubmit(values) {
		if (values.ghin_or_email) {
			// eslint-disable-next-line no-useless-escape
			if (values.ghin_or_email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
				 return GolferService.findGolferByGhinOrEmail(null, values.ghin_or_email)
					.then(res=> {
						this.props.onGolferSelect(res.golfer);
					})
					.catch(err => {
						throw new SubmissionError({ghin_or_email: "No results found, please change your search criteria."})
					})
			} else {
				let reg = /^\d+$/;
				if (reg.test(values.ghin_or_email)) {
					return GolferService.findGolferByGhinOrEmail(values.ghin_or_email, null)
						.then(res => {
							this.props.onGolferSelect(res.golfer);
						})
						.catch(err => {
							console.log(err);
							throw new SubmissionError({ghin_or_email: "No results found, please change your search criteria."})
						});
				} else {
					throw new SubmissionError({ghin_or_email: "No results found, please change your search criteria."})
				}
			}
		} else {

			let params = [];

			params['sorting_criteria'] = this.state.sorted[0].id;
			params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
			params['per_page'] = this.state.perPage;
			params['page'] = this.state.page;
			params['state'] = values.state.value;
			params['country'] = values.country.value;
			params['last_name'] = values.last_name;
			if(values.first_name) params['first_name'] = values.first_name;


			return GolferService.findGolferByNameAndState(params)
				.then(res => {
					if (res.golfers && res.golfers.length === 1) {
						this.props.onGolferSelect(res.golfers[0]);
					} else if(res.golfers.length === 0) {
						throw new SubmissionError({last_name: "No results found, please change your search criteria."})
					} else {
						this.setState({
							values,
							golfers: res.golfers.map(golfer => { return {...golfer, ...{clubs: golfer.clubs.sort((a, b) => (a.club_name > b.club_name) ? 1 : -1)}}}),
							total: parseInt(res['total-count'], 10),
							per_page: parseInt(res['per-page'], 10),
							pages: parseInt(res['total-pages'], 10),
							loading: false,
						});
					}
				})
				.catch(err => {
					throw new SubmissionError({last_name: "No results found, please change your search criteria."})
				})
		}
	}

	getPage(){
		this.setState({
			loading: true
		});
		let params = [];

		params['sorting_criteria'] = this.state.sorted[0].id;
		params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		params['per_page'] = this.state.perPage;
		params['page'] = this.state.page;
		params['state'] = this.state.values.state.value;
		params['last_name'] = this.state.values.last_name;
		if(this.state.values.first_name) params['first_name'] = this.state.values.first_name;

		GolferService.findGolferByNameAndState(params)
			.then(res => {
				this.setState({
					golfers: res.golfers.map(golfer => { return {...golfer, ...{selected: false, clubs: golfer.clubs.sort((a, b) => (a.club_name > b.club_name) ? 1 : -1)}}}),
					total: parseInt(res['total-count'], 10),
					per_page: parseInt(res['per-page'], 10),
					pages: parseInt(res['total-pages'], 10),
					loading: false,
				});
			})
			.catch(err => {
				console.error(err);
			})
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;

		let columns = [
			{
				Header: 'Golfer Name',
				accessor: 'first_name',
				colClassName: 'col_user-name',
				Cell: props => <Fragment>
					{props.original.first_name} {props.original.middle_name} {props.original.last_name}
				</Fragment>
			},
			{
				Header: 'id',
				show: false,
				accessor: 'id'
			},
			{
				Header: 'Club Name',
				accessor: 'clubs',
				colClassName: 'col_user-name',
				Cell: props => <Fragment>
						{props.value.map(club => {
							return (<span>{club.club_name}<br/></span>)
						})}
				</Fragment>,
			},
			{
				Header: 'H.I.',
				accessor: 'handicap_index',
			},
		];
		return (
			<Fragment >
				{this.state.golfers.length === 0 ?
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit.bind(this))} ref={'form'}>

					<div className="row">
						<div className="col">
							<h3 className="form__title">GHIN Number or Email Address Search</h3>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="ghin_or_email">GHIN Number or Email Address <span>*</span></label>
							<Field component={InputField}
								   disabled={this.props.formValues && (this.props.formValues.last_name || this.props.formValues.first_name)}
								   type="text"
								   validate={(this.props.formValues && (this.props.formValues.last_name || this.props.formValues.first_name)) ? false : [required]}
								   name="ghin_or_email"
								   id="ghin_or_email" />
						</div>
					</div>

					<div className="form__separator or"></div>

					<div className="row">
						<div className="col">
							<h3 className="form__title">Last Name &amp; State/Province Search</h3>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="first_name">First Name</label>
							<Field component={InputField}
								   disabled={this.props.formValues && this.props.formValues.ghin_or_email}
								   type="text"
								   name="first_name"
								   id="first_name" />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="last_name">Last Name <span>*</span></label>
							<Field component={InputField}
								   disabled={this.props.formValues && this.props.formValues.ghin_or_email}
								   validate={(this.props.formValues && this.props.formValues.ghin_or_email) ? false : [required]}
								   type="text"
								   name="last_name"
								   id="last_name" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="country">Country <span>*</span></label>
							<Field name={'country'}
								   id={'country'}
								   component={SelectInput}
								   disabled={this.props.formValues && this.props.formValues.ghin_or_email}
								   onChange={this.onCountryChange.bind(this)}
								   validate={(this.props.formValues && this.props.formValues.ghin_or_email) ? false : [requiredSelect]}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={this.state.countries}/>
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="state">State</label>
							<Field name={'state'}
								   id={'state'}
								   component={SelectInput}
								   disabled={this.props.formValues && this.props.formValues.ghin_or_email}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={this.state.states}/>
						</div>
					</div>

					<div className="row">

						<div className="col is-1-of-1">
							<button type={'submit'} ref={'submit'} disabled={pristine || submitting} className="btn fill blue">Search</button>
						</div>
					</div>

				</form>
				:

				this.state.golfers.length > 1 &&
					<div className="select golfer">
						<div className="row">
							<div className="col">
								<h3 className="form__title">Select Minor</h3>
								<GhinTable
									idKey={'id'}
									hideSlelectAll={true}
									defaultPageSize={10}
									columns={columns}
									loading={this.state.loading}
									data={this.state.golfers}
									ref={(r) => this.table = r}
									pages={this.state.pages}
									manual
									pageSize={this.state.per_page}
									onRowSelect={(row) => {
										let golfers = [...this.state.golfers].map((i) => {
											if (i.selected) { return {...i, selected: false} }
											if (i.id === row.id) {
												return {
													...i,
													selected: !row.selected
												}
											} else {
												return i;
											}
										});
										this.setState({
											golfers,
											selected: row._original
										})
									}}
									onPageChange={(pageIndex) => this.setState({page: pageIndex+1}, () =>  this.getPage())}
									onPageSizeChange={(pageSize, pageIndex) => this.setState({
										per_page: pageSize,
										page: pageIndex
									}, () => this.getPage())}
									sortable={false}
								/>
							</div>
						</div>
						<div className={"row"}>
							<button disabled={!this.state.selected} onClick={()=> {this.props.onGolferSelect(this.state.selected)}} className="btn fill blue">Continue</button>
						</div>
					</div>
				}
			</Fragment>
		);
	}
}

SearchMinorForm = reduxForm({
	form: 'searchGolferForm',
	destroyOnUnmount: true
})(SearchMinorForm);

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({}, dispatch);
	return {
		dispatch,
		...actions
	};
}


function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		formValues: getFormValues('searchGolferForm')(state)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(SearchMinorForm));
