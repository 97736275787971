import { Fragment } from "react";
import EditAddonChargeForm from "../Forms/EditAddonChargeForm";

export default function EditAddonChargeModal(props) {
  return (
    <Fragment>
      <div className="modal__container narrow">

        <div className="modal__head">
          {
            props.operationType === "Edit" && <Fragment>
              <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
              <h2 className="modal__title">Edit Add-On</h2>
            </Fragment>
          }

          {
            props.operationType === "Create" && <Fragment>
              <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
              <h2 className="modal__title">Create Add-On</h2>
            </Fragment>
          }

          <button className="modal__close" onClick={() => { props.close_modal() }}><i className="material-icons-outlined">clear</i></button>

        </div>

        <div className="modal__body">
          <EditAddonChargeForm
            {...props}
          />
        </div>

      </div>
    </Fragment>
  )
}