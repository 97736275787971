import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect, withRouter} from 'react-router-dom';
import Header from '../shared/Header';
import Navigation from '../shared/Navigation';
import Footer from '../shared/Footer';
import BreadCrumbsNav from '../shared/BreadCrumbsNav';


import {connect} from "react-redux";
import GuardianService from "../../services/api/guardian";
import {getPermission} from "../../permissionManager";
import Account from "./Account";
import PageHead from "../shared/PageHead";
import AuditLog from "./AuditLog";
const generateGolferName = (row) => {
	return [row.prefix, row.first_name, row.middle_name, row.last_name, row.suffix].join(" ");
};

class Guardian extends Component {

	constructor(props) {

		super(props);

		this.state = {
			guardian: undefined,
			publicView: undefined //todo: what is this?
		};


	}

	queryGuardian() {
		const guardianId = this.props.match.params.id;
		//todo: what happens if 404 not found?
		GuardianService.getGuardianById(guardianId)
			.then(({guardian}) => {
				this.setState({
					guardian
				})
			})
	}

	componentDidMount() {
		this.queryGuardian();
	}


	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.match.params.id !== this.props.match.params.id){
			 this.queryGuardian();
		}
	}


	render() {
		if (!this.state.guardian) return null;
		const guardianName = generateGolferName(this.state.guardian);
		let accessLevel = 0;
		if (this.state.guardian.access_type === 'read') {
			accessLevel = 1;
		}
		if (this.state.guardian.access_type === 'manage') {
			accessLevel = 2
		}

		return (
			<Fragment>
				<Header/>
				<Navigation/>
				<BreadCrumbsNav guardian={guardianName}/>
				<main>

					<div className="container">


						<PageHead title={guardianName} publicView={this.state.publicView}/>

						<div className="page__sub__head">
							<ul className="golfer__data">
								{ this.state.guardian.email ? <li><a href={`mailto:${this.state.guardian.email}`}>{this.state.guardian.email}</a></li> : null}
							</ul>
						</div>


						<ul className="page__tabs">
							<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/account`}>Account</NavLink></li>
							{accessLevel > 1 &&  <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/audit-log`}>Audit Log</NavLink></li>}

						</ul>



						<section className="page__tab-panel">
							<Switch>
								<Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/account`}/>
								<Route path={`${this.props.match.url}/account`} component={() => (
									<Account canEditClub={getPermission(this.props.user_type, 'edit-club')}
											 user_type={this.props.user_type}
											 accessLevel={accessLevel}
											 props={this.props}
											 guardian={this.state.guardian}
											 updateGuardian={() => this.queryGuardian()}
											 archived={false}
											 publicView={this.props.user_type.includes('read-only')}/>
								)} />
								{accessLevel > 1 && <Route path={`${this.props.match.url}/audit-log`} component={() =>
									<AuditLog accessLevel={accessLevel} canEditClub={!this.props.user_type.includes('read-only')} {...this.props}/>} />}
							</Switch>
						</section>

					</div>
				</main>

				<Footer/>

			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user_type: state.user.access.user_type
	};
}

export default withRouter(connect(mapStateToProps, null)(Guardian));
