import React, {Component, Fragment} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import EditScoreModal from '../Modals/EditScoreModal';
import AuditHistory from '../Modals/AuditHistoryModal';
import ScoreLogs from '../Modals/ScoreLogs';
import ScoreService from "../../services/api/score";
import TableFilter from "./ScoreMaintenanceFilter";
import GhinTable from "../shared/GhinTable";
import moment from "moment";
import ConfirmationModalTypeB from "../Modals/ConfirmationModalTypeB";
import ReactTooltip from "react-tooltip";
import SuccessModal from "../Modals/SuccessModal";
import {getPermission} from "../../permissionManager";
import { getCleanName } from '../utils'

export default class ScoreMaintenance extends Component {

    constructor(props) {
		super(props);

		this.state = {
            editScoreModalIsOpen: false,
            deleteScoreModalIsOpen: false,
            roundAuditHistoryModalIsOpen: false,
			scoreLogsModal: false,
			scores: [],
			scores9Holes: [],
			loading: false,
			loading9Holes: false,
			sorted: [{id: "date_played", desc: false}],
			total: 0,
			total9Holes: 0,
			pages: 0,
			pages9Holes: 0,
			selectedScore: null,
			isExportDataOpened: false,
			filtersHidden: false,
      outdatedScoreModalOpen: false,
			params: {
            	golfer_id: null,
				page: 1,
				per_page: 25,
				days_of_week: '1,2,3,4,5,6,7'
			}
		};

        this.openEditScoreModal = this.openEditScoreModal.bind(this);
        this.openDeleteScoreModal = this.openDeleteScoreModal.bind(this);
        this.openRoundAuditHistoryModal = this.openRoundAuditHistoryModal.bind(this);
        this.getScores = this.getScores.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
	}

	getScores() {
    	let params = JSON.parse(JSON.stringify(this.state.params));

		if (this.state.sorted.length > 0) {
			params['sorting_criteria'] = this.state.sorted[0].id;
			params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (params && params['from_date_played']) {
			params['from_date_played'] = moment(params['from_date_played'],["MM-DD-YYYY", "YYYY-MM-DD"]).format('YYYY-MM-DD')
		}
		if (params && params['to_date_played']) {
			params['to_date_played'] = moment(params['to_date_played'],["MM-DD-YYYY", "YYYY-MM-DD"]).format('YYYY-MM-DD')
		}

		params['statuses'] = 'Validated';
      params['number_of_holes'] = '18';

		this.setState({
			loading: true,
			scores: []
		});

    	ScoreService.getAll({...params})
						.then(response => {
							this.setState({
								scores: response.Scores.map(score => {
									return {
										...score,
										course_display_name: getCleanName(
											{
												...score,
												...{placeholder:true}
											}
										)
									}
								}),
								loading: false,
								params: {
									...this.state.params,
									per_page: parseInt(response['per-page'], 10),
									from_date_played: params['from_date_played'],
									to_date_played: params['to_date_played']
								},
								total: parseInt(response['total-count'], 10),
								pages: parseInt(response['total-pages'], 10),
							})
						})
						.catch(err => {
							console.log(err);
						})


		// The 9 holes scores will be shown in a separate tab only if the golfer is not using the scaling method,
		// otherwise the 'Holding Tank' is not used

		if(this.props.golfer.use_scaling) {
			return
		}

		params['number_of_holes'] = '9';

    	this.setState( {
			loading9Holes: true,
			scores9Holes: []
		})

		ScoreService.getAll({...params})
						.then(response => {
							this.setState({
								scores9Holes: response.Scores.map(score => {
									return {
										...score,
										course_display_name: getCleanName({...score,...{placeholder:true}})
									}
								}),
								loading9Holes: false,
								params: {
									...this.state.params,
									per_page: parseInt(response['per-page'], 10)
								},
								total9Holes: parseInt(response['total-count'], 10),
								pages9Holes: parseInt(response['total-pages'], 10),
							})
						})
						.catch(err => {})
	}

	updateFilter(params) {
		this.setState({
			params: {
				...this.state.params,
				...params,
            page: 1
			}
		}, () => {
			this.getScores();
		})
	}

	componentDidMount() {
		Modal.setAppElement('body');
		let ghin;
		if(this.props.match.params.golfer_id.match(/^[0-9]*$/)) {
			ghin = this.props.match.params.golfer_id;
		} else {
			ghin = window.atob(this.props.match.params.golfer_id);
		}
    	this.setState({
			params: {
				...this.state.params,
				golfer_id: ghin
			}
		});
	}

	openEditScoreModal() {
		//this.setState({editScoreModalIsOpen: true});
    }

    openRoundAuditHistoryModal() {
		this.setState({roundAuditHistoryModalIsOpen: true});
    }

    openDeleteScoreModal() {
		this.setState({deleteScoreModalIsOpen: true});
	}

	closeModal(refreshData = false) {
		this.setState({
            editScoreModalIsOpen: false,
            deleteScoreModalIsOpen: false,
            roundAuditHistoryModalIsOpen: false,
			selectedScore: null,
			scoreLogsModal: false,
        }, () => {
			if (refreshData) this.getScores()
		});
	}

	getCSV() {
		return ScoreService.exportToCSV(this.state.params)
			.then(response => {
				window.open(response.url, "_blank")
			})
			.catch(err => {

			})

	}

	deleteScore() {
		ScoreService.deleteScore(this.state.selectedScore.id, this.state.selectedScore)
			.then(response => {
				this.setState({
					deleteScoreModalIsOpen: false,
					selectedScore: null
				}, () => {
					this.getScores()
				})
			})
			.catch(err => {
				console.error(err);
			})
	}

	getDeleteModalBody() {
    	let {selectedScore} = this.state;
    	return (
			<div className="row">
				<div className="col is-full">
					<table className="data__table">
						<colgroup>
							<col className="col_pico"/>
							<col className="col_date"/>
							<col className="col_score"/>
							<col className="col_slope"/>
							<col className=""/>
						</colgroup>
						<tbody>
						<tr>
							<td className="table-align-center">{selectedScore.score_type_display_full}</td>
							<td className="table-align-center">{moment(selectedScore.played_at).format('MM/DD/YYYY')}</td>
							<td className="table-align-center">{selectedScore.adjusted_gross_score}</td>
							<td className="table-align-center">{selectedScore.course_rating}/{selectedScore.slope_rating}</td>
							<td>{getCleanName(selectedScore)}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		)
	}

  setOutdatedScoreModalOpen(value){
    this.setState({outdatedScoreModalOpen: value})
  }

    render() {

    	const columns = [
			{
				accessor: 'revision',
				show: false

			},
			{
				Header: '', //Old header: Used
				accessor: 'used',
				Cell: props => <Fragment>{props.original.used && <i className="material-icons-outlined blue td-align-center small">star_border</i>}</Fragment>,
				thClassName: 'table-align-center is-1-of-6',
			},
			{
				Header: 'Type',
				accessor: 'score_type_display_full',
				thClassName: 'table-align-center is-1-of-5',
				className: 'table-align-center'
			},
			{
				Header: 'Date Played',
				accessor: 'played_at',
				Cell: props => this.props.publicView ? moment(props.original.played_at, 'YYYY-MM-DD').format('MM/YYYY') : moment(props.original.played_at, 'YYYY-MM-DD').format('MM/DD/YYYY'),
				className: 'table-align-center',
				thClassName: 'table-align-center is-5-of-10',
			},
			{
				Header:
				<Fragment>
				   Score
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='score'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='score' className="table-head__tooltip" type="light" place='top' effect='float' >
						The number of holes played is in parentheses. For 9-hole scores or a score based on less than 18 holes played, the score received an 18-hole Score Differential based on the number of holes played and expected score for the holes not played.
					</ReactTooltip>
				</Fragment>,
				accessor: 'adjusted_gross_score',
				Cell: props => 
				<Fragment>
					<div>
						{props.original.adjusted_gross_score}
						{
							props.original.adjusted_scaled_up_differential != null &&
							props.original.number_of_holes != 9 &&
							<a className='score-number_of_played_holes'>
								{" ("}{props.original.number_of_played_holes}{")"}
							</a>
						} 
						{
							props.original.edited && 
							<i 
								onClick={() => {this.setState({scoreLogsModal: true, selectedScore: props.original})}}
								style={{verticalAlign: 'text-top'}}
								className="material-icons-outlined blue">
								bookmark
							</i>
						}
					</div>
				</Fragment>,
				highlightColumn: true,
				highlightConditionKey: 'revision',
				className: 'table-align-center',
				thClassName: 'table-align-center is-1-of-3',
			},
			{
				Header: 'C.R./Slope',
				accessor: 'course_and_slope_rating',
				Cell: props => <Fragment>{parseFloat(props.original.course_rating).toFixed(1)} / {props.original.slope_rating}</Fragment>,
				className: 'table-align-center',
				thClassName: 'table-align-center is-1-of-2',
			},
			{
				Header: <Fragment>
					PCC
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='pcc'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip  id='pcc' className="table-head__tooltip" type="light" place='top' effect='float'>
						<strong>Playing Conditions Calculation </strong>
						The playing conditions calculation determines whether playing conditions on the day differed from normal conditions to the extent that an adjustment is needed to compensate. It is a statistical procedure that compares the scores submitted by players on the day against expected scoring patterns.
					</ReactTooltip>
				</Fragment>,
				accessor: 'pcc',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'pcc').length > 0 ? props.original.adjustments.filter(a => a.type === 'pcc')[0].display : '-'}</Fragment>,
				thClassName: 'table-align-center is-1-of-3',
				className: 'table-align-center'
			},
			{
				Header: <Fragment>
					Diff.
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='diff'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='diff' className="table-head__tooltip" type="light" place='top' effect='float'>
						<strong>Score Differential </strong> = (113/Slope Rating) * (Adjusted Gross Score – Course Rating – PCC). ESR and Committee Adjustments are applied to the previously calculated score differential.
					</ReactTooltip>
				</Fragment>,
				Cell: props => props.original.adjusted_scaled_up_differential == null 
					? parseFloat(props.original.differential).toFixed(1) 
					: props.original.adjusted_scaled_up_differential === 999 
						? '-' 
						: parseFloat(props.original.adjusted_scaled_up_differential).toFixed(1),
				accessor: 'differential',
				thClassName: 'table-align-center is-1-of-3',
				className: 'table-align-center'
			},
			{
				Header: <Fragment>
					ESR
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='esr'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='esr' className="table-head__tooltip" type="light" place='top' effect='float' >
						<strong>Exceptional Score Reduction </strong>
						When an exceptional score is posted to a player’s scoring record, the Handicap Index will be reduced in accordance with the exceptional score adjustment table.
					</ReactTooltip>
				</Fragment>,
				accessor: 'esr',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'esr').length > 0 ? props.original.adjustments.filter(a => a.type === 'esr')[0].value : '-'}</Fragment>,
				thClassName: 'table-align-center is-1-of-3',
				className: 'table-align-center'
			},
			{
				Header: <Fragment>
					Adj.
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='adj'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='adj' className="table-head__tooltip" type="light" place='top' effect='float' >
						<strong>Committee Adjustment</strong> When an adjustment is applied by the Handicap Committee, it is intended to provide a better reflection of a player’s demonstrated ability.
					</ReactTooltip>
				</Fragment>,
				accessor: 'adj',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'committee').length > 0 ? props.original.adjustments.filter(a => a.type === 'committee')[0].value : '-'}</Fragment>,
				thClassName: 'table-align-center is-1-of-3',
				className: 'table-align-center '
			},
			{
				Header: 'Course Name / Tee',
				accessor: 'course_display_name',
				show: !this.props.publicView,
				Cell: props => <Fragment>{getCleanName(props.original)}</Fragment>
			},
			{
				Header: 'Date Posted',
				accessor: 'posted_at',
				Cell: props => <Fragment>{this.props.publicView ? moment(props.value).format('MM/YYYY') : moment(props.value).format('MM/DD/YYYY HH:mm:ss')} </Fragment>,
				className: 'table-align-center',
				thClassName: 'table-align-center is-9-of-12',
			},
			{
				Header: '',
				sortable: false,
				show: !this.props.publicView && !this.props.archived && getPermission(this.props.user_type, 'manage-scores'),
				thClassName: 'is-2-of-5',
				Cell: props => (<span className='col_edit-delete'>
					{props.original.score_type !== 'P' && <button className={'btn lnk'} onClick={() => {this.setState({editScoreModalIsOpen: true, selectedScore: props.original})}}>Edit</button>}
					<button className={'btn lnk'} onClick={() => {this.setState({deleteScoreModalIsOpen: true, selectedScore: props.original})}}>Delete</button>
				</span>)
			}
			]

		const columns9Holes = [
			{
				accessor: 'revision',
				show: false

			},
			{
				Header: 'Type',
				accessor: 'score_type_display_full',
				colClassName: 'col_type',
				thClassName: 'table-align-center is-small is-1-of-5',
				className: 'table-align-center'
			},
			{
				Header: 'Date Played',
				accessor: 'played_at',
				Cell: props => this.props.publicView ? moment(props.original.played_at, 'YYYY-MM-DD').format('MM/YYYY') : moment(props.original.played_at, 'YYYY-MM-DD').format('MM/DD/YYYY'),
				className: 'table-align-center',
				thClassName: 'table-align-center is-7-of-10',
			},
			{
				Header: 'Score',
				accessor: 'adjusted_gross_score',
				Cell: props => <Fragment><span>{props.original.adjusted_gross_score} {props.original.edited && <i  onClick={() => {this.setState({scoreLogsModal: true, selectedScore: props.original})}} className="material-icons-outlined blue">bookmark</i>}</span></Fragment>,
				highlightColumn: true,
				highlightConditionKey: 'revision',
				className: 'table-align-center',
				thClassName: 'table-align-center is-6-of-10',
			},
			{
				Header: 'C.R./Slope',
				Cell: props => <Fragment>{parseFloat(props.original.course_rating).toFixed(1)} / {props.original.slope_rating}</Fragment>,
				className: 'table-align-center',
				thClassName: 'table-align-center is-3-of-4',
			},
			{
				Header: <Fragment>
					PCC
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='pcc'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='pcc' className="table-head__tooltip" type="light" place='top' effect='float'>
						<strong>Playing Conditions Calculation </strong>
						The playing conditions calculation determines whether playing conditions on the day differed from normal conditions to the extent that an adjustment is needed to compensate. It is a statistical procedure that compares the scores submitted by players on the day against expected scoring patterns.
					</ReactTooltip>
				</Fragment>,
				accessor: 'pcc',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'pcc').length > 0 ? props.original.adjustments.filter(a => a.type === 'pcc')[0].display : '-'}</Fragment>,
				thClassName: 'table-align-center is-1-of-2',
				className: 'table-align-center'
			},
			{
				Header: <Fragment>
					Diff.
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='diff'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='diff' className="table-head__tooltip" type="light" place='top' effect='float'>
						<strong>Score Differential </strong> = (113/Slope Rating) * (Adjusted Gross Score – Course Rating – PCC). ESR and Committee Adjustments are applied to the previously calculated score differential.
					</ReactTooltip>
				</Fragment>,
				Cell: props => parseFloat(props.original.differential).toFixed(1),
				accessor: 'differential',
				thClassName: 'table-align-center is-6-of-10',
				className: 'table-align-center'
			},
			{
				Header: <Fragment>
					ESR
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='esr'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='esr' className="table-head__tooltip" type="light" place='top' effect='float'>
						<strong>Exceptional Score Reduction </strong>
						When an exceptional score is posted to a player’s scoring record, the Handicap Index will be reduced in accordance with the exceptional score adjustment table.
					</ReactTooltip>
				</Fragment>,
				accessor: 'esr',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'esr').length > 0 ? props.original.adjustments.filter(a => a.type === 'esr')[0].value : '-'}</Fragment>,
				thClassName: 'table-align-center is-1-of-2',
				className: 'table-align-center'
			},
			{
				Header: <Fragment>
					Adj.
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='adj'><i className="material-icons v-mid m-left white small">info</i></a>
					<ReactTooltip id='adj' className="table-head__tooltip" type="light" place='top' effect='float' >
						<strong>Committee Adjustment</strong> When an adjustment is applied by the Handicap Committee, it is intended to provide a better reflection of a player’s demonstrated ability.
					</ReactTooltip>
				</Fragment>,
				accessor: 'adj',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'committee').length > 0 ? props.original.adjustments.filter(a => a.type === 'committee')[0].value : '-'}</Fragment>,
				thClassName: 'table-align-center is-1-of-2',
				className: 'table-align-center '
			},
			{
				Header: 'Course Name / Tee',
				accessor: 'course_display_name',
				show: !this.props.publicView,
				Cell: props => <Fragment>{getCleanName(props.original)}</Fragment>
			},
			{
				Header: 'Date Posted',
				accessor: 'posted_at',
				Cell: props => <Fragment>{this.props.publicView ? moment(props.value).format('MM/YYYY') : moment(props.value).format('MM/DD/YYYY HH:mm:ss')} </Fragment>,
				className: 'table-align-center',
				thClassName: 'table-align-center is-medium-large',
			},
			{
				Header: '',
				sortable: false,
				show: !this.props.publicView && !this.props.archived &&  (this.props.user_type === "super-admin" || this.props.user_type === "association-admin" || this.props.user_type === "club-admin"),
				thClassName: 'is-6-of-10',
				Cell: props => (<span className='col_edit-delete'>
					{props.original.score_type !== 'P' && <button className={'btn lnk'} onClick={() => {this.setState({editScoreModalIsOpen: true, selectedScore: props.original})}}>Edit</button>}
					<button className={'btn lnk'} onClick={() => {this.setState({deleteScoreModalIsOpen: true, selectedScore: props.original})}}>Delete</button>
				</span>)
			}
		];
		return (
            <Fragment>
              <ConfirmationModalTypeB
                wideClass={'narrow'}
                openModal={this.state.outdatedScoreModalOpen && !this.props.golfer.use_scaling}
                onConfirmAction={()=>{
                  this.setState({
                    outdatedScoreModalOpen: false,
                  })
                }}
                closeModal={()=>{
                  this.setState({
                    outdatedScoreModalOpen: false,
                  })
                }}
                infoText={["This 9-hole score is older than the twentieth oldest 18-hole score in " +
                           "the golfer's scoring record and will not be combined. The score has been " +
                           "posted successfully, but will not display in the scoring record and will " +
                           "not be considered when calculating the golfer's Handicap Index.",
                           "For historical purposes, the score can be found in the Audit Logs."]}
                confirmLabel={"OK"}
                modalIcon={"check_circle"}
              />
				{!this.state.filtersHidden && <TableFilter updateFiltered={this.updateFilter.bind(this)} club_groups={this.state.club_groups} golfer_use_scaling={this.props.golfer.use_scaling}/>}
				<div className="pre__table">
					<div className="row">
						{!this.props.publicView && <div className="col auto">
							<ul className="action__list push-left">
								<li><button onClick={() => {this.getCSV(columns)}} className="btn fill green">Export to Excel</button></li>
							</ul>
						</div>}
						<div className="col auto push-left">
							<ul className="table__legend">
								<li><i className="material-icons-outlined blue">star_border</i> Used in Current Revision</li>
								<li><span className="legend-item yellow_highlight"></span>Considered in Current Revision</li>
								<li><i className="material-icons-outlined blue">bookmark</i>Edited Score</li>
							</ul>
						</div>
						<div className="col auto push-right">
							<ul className="association__status">
								<li>Total Rounds = <strong>{this.state.total}</strong></li>
							</ul>
						</div>
						<div className="col auto">
							<button onClick={() => this.setState({filtersHidden: !this.state.filtersHidden})} className="btn fill gray">{this.state.filtersHidden ? 'Show' : 'Hide' } Filters <i className="material-icons-outlined">{this.state.filtersHidden ? 'add' : 'remove'}</i></button>
						</div>
					</div>
				</div>
				<GhinTable
					loading={this.state.loading}
					columns={columns}
					hideSelect
					pages={this.state.pages}
					page={this.state.params.page-1}
					manual
					total={this.state.total}
					pageSize={this.state.params.per_page}
					data={this.state.scores}
					ref={(r) => this.table = r}
					onPageChange={(pageIndex) => this.setState({params: {...this.state.params, page: pageIndex + 1}}, () =>  this.getScores())}
					onPageSizeChange={(pageSize, pageIndex) => this.setState({
						params: {
							...this.state.params,
							per_page: pageSize,
							page: pageIndex + 1
						}
					}, () => this.getScores())}
					sorted={this.state.sorted}
					onSortedChange={(newSorted, column, shiftKey) => {
						this.setState({
							sorted: newSorted
						}, () => {
							this.getScores()
						})
					}}
				/>
				{(this.state.scores9Holes.length === 0) ? null :
					<Fragment>
						<div className="row">
							<div className="col">
								<h2 className="section__title" style={{paddingTop: '15px'}}>9-Hole Scores</h2>
							</div>
						</div>
						<GhinTable
							sortable={false}
							loading={this.state.loading}
							columns={columns9Holes}
							hideSelect
							pages={this.state.pages}
							showPagination={false}
							manual
							total={this.state.total}
							pageSize={this.state.params.per_page}
							data={this.state.scores9Holes}
							ref={(r) => this.table = r}
							onPageChange={(pageIndex) => this.setState({params: {...this.state.params, page: pageIndex + 1}}, () =>  this.getScores())}
							onPageSizeChange={(pageSize, pageIndex) => this.setState({
								params: {
									...this.state.params,
									per_page: pageSize,
									page: pageIndex + 1
								}
							}, () => this.getScores())}
						/>
					</Fragment>
				}

				<div className="col auto push-left" style={{marginTop: 8}}>
					<ul className="table__legend">
						<li>
							Type: <span>H</span> - Home, <span>A</span> - Away, <span>C</span> - Competition, <span>N</span> - { this.props.golfer.use_scaling ? '' : 'Combined' } 9-Hole Rounds, <span>P</span> - Penalty, <span>E</span> - Exceptional
						</li>
					</ul>
				</div>
				{this.state.editScoreModalIsOpen &&
					<Modal
						isOpen={this.state.editScoreModalIsOpen}
						onRequestClose={() => {this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
						<EditScoreModal
							clubId={this.props.match.params.id}
							golfer={this.props.golfer}
							selectedScore={this.state.selectedScore}
              setOutdatedScoreModalOpen={(value)=>this.setOutdatedScoreModalOpen(value)}
							closeModal={(refreshData, showConfirmationModal) => {
								this.closeModal(refreshData);
								this.setState({
									successModalIsOpen: showConfirmationModal
								})
							}}
						/>
					</Modal>
				}

				{
					this.state.successModalIsOpen &&
					<SuccessModal
						openModal={true}
						onMainAction={() => {
							this.setState({successModalIsOpen: false})
						}}
						closeModal={() => {
							this.setState({successModalIsOpen: false})
						}}
						message={this.state.successModalIsOpen}
						mainLabel={"Ok"}
					/>
				}

				{this.state.deleteScoreModalIsOpen &&
					<ConfirmationModalTypeB
						wideClass={'narrow'}
						openModal={this.state.deleteScoreModalIsOpen}
						onConfirmAction={() => this.deleteScore()}
						onCancelAction={() => {
							this.setState({
								deleteScoreModalIsOpen: false
							})
						}}
						closeModal={() => {
							this.setState({
								deleteScoreModalIsOpen: false
							})
						}}
						bodyQuestion={this.getDeleteModalBody()}
						question={"Are you sure you want to delete the following score?"}
						cancelLabel={"No"}
						confirmLabel={"Yes"}
						modalIcon={"help_outline"}
					/>
				}

                <Modal
					isOpen={this.state.roundAuditHistoryModalIsOpen}
					onRequestClose={() => {this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AuditHistory closeModal={() => {this.closeModal()}} />
				</Modal>
				<Modal
					isOpen={this.state.scoreLogsModal}
					onRequestClose={() => {this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<ScoreLogs score={this.state.selectedScore} closeModal={() => {this.closeModal()}} />
				</Modal>
            </Fragment>
		);
	}
}
