import { Picky } from "react-picky"

export const PickySelect = (field) => {
  let fieldValue = field.selected || (field.input.value || []);
  
  return <div>
    {field.displayLabel !== false && <label>{field.label}</label>}
    <Picky
      id="picky-select"
      numberDisplayed={field.numberDisplayed}
      value={fieldValue}
      multiple={field.multiple}
      includeSelectAll={field.includeSelectAll}
      includeFilter={false}
      onChange={field.input.onChange}
      disabled={field.disabled}
      onClose={() => field.input.onBlur(field.input.value)}
      options={field.options}
      valueKey="value"
      labelKey="label"
      placeholder="Select..."
      buttonProps={{
        style: {
          border: field.meta.touched && field.meta.error ? "solid 1px #da1a31" : "",
          color: field.meta.touched && field.meta.error ? "#da1a31" : ""
        }
      }
      }
    />
    {!field.hideErrorMessages && field.meta.touched && (field.meta.error && <p className="validation__message is-error">{field.meta.error}</p>)}
  </div>
}
