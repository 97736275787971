import { Subject } from 'rxjs';

export class AddClubObservable {
	static _addClubSubject$ = new Subject();

	static get() {
		return this._addClubSubject$.asObservable();
	}

	static update(value = undefined) {
		this._addClubSubject$.next(value)
	}
}
