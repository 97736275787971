import React, {Component, Fragment} from 'react';
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";
import TableService from "../../../../services/tableService";


export default class TableFilter extends Component {

	constructor(props) {

		super(props);

		this.state = {
			filters: {full_name: null, membership_code: [], gender: null}
		};

	}

	updateFilter = (name, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		}, () => {
			this.updateTable();
		})
	};

	updateTable = debounce(() => {
		let filteredArray = TableService.filtersToArray(this.state.filters);
		this.props.updateFiltered(filteredArray);
	}, 500);

	reset() {
		this.setState({filters: {full_name: null, membership_code: [], gender: null}}, () => {
			this.updateTable();
		});
	}

	render() {
		return (
			<Fragment>

				<div className="filters">

					<div className="row">

						<div className="filters__inputs col">

							<div className="row">

								<div className={'col'}>

									<div className="row">
										<div className="col is-1-of-6">
											<label htmlFor="association-name">Name</label>
											<input type="text" autoComplete="off" value={this.state.filters.full_name || ''} name="association-name" id="association-name" onChange={(e) => {
												this.updateFilter('full_name', e.target.value)
											}}/>
										</div>

										<div className="col is-1-of-6">

											<GhinFilterSelect
												singleChoice
												options={['Active', 'Inactive']}
												value={this.state.filters.status}
												onChange={values => this.updateFilter('status', values)}
												label={'Membership Status'}
											/>

										</div>

										<div className="col is-1-of-6">

											<GhinFilterSelect
												options={this.props.membershipTypes}
												value={this.state.filters.membership_code}
												onChange={values => this.updateFilter('membership_code', values)}
												label={'Membership Code (MC)'}
											/>

										</div>


										<div className={"col is-1-of-6"}>
											<GhinFilterSelect
												singleChoice
												options={[{label: "Male", value: "M"}, {label: "Female", value: "F"}]}
												value={this.state.filters.gender}
												onChange={values => this.updateFilter('gender', values)}
												label={'Gender'}
											/>
										</div>
									</div>

								</div>

							</div>

						</div>

						<div className="filters__controls col is-1-of-7 jc-fe">
							<button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
						</div>

					</div>

				</div>

			</Fragment>
		);
	}
}
