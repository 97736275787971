import React, {Component, Fragment} from 'react';

import AddKioskForm from '../Forms/AddKioskForm';

export default class AddKioskModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
                        <h2 className="modal__title">Add Kiosk</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
                        <AddKioskForm clubName={this.props.clubName} facility={this.props.facility} closeModal={this.props.closeModal} clubId={this.props.clubId} associationId={this.props.associationId}/>
                    </div>

                </div>

            </Fragment>
		);
	}
}
