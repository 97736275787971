import React, {useState, Fragment, useEffect, useCallback} from 'react';
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "lodash.debounce";

export function Filter(props) {

  let [ state, setState ] = useState({
    golfer_id: "",
    email: "",
    sent: {
      label: "Delivered",
      value: true
    }
  });

	function updateFilter(name, value) {
    setState({
      ...state,
      [name]: value
    });
  };

	let updateTable = useCallback(
    debounce((filters) => {
      props.updateFiltered({
        ...filters,
        sent: filters.sent.label
      });
    }, 400),
    []
  );

  function reset() {
    setState({
      golfer_id: "",
      email: "",
      sent: {label: 'Delivered', value: true}
    });
  }

  useEffect(() => {
    if (props.resetFilters) {
      reset();
      props.setResetFilters(false);
    }
  }, [props.resetFilters]);

  useEffect(() => {
    updateTable(state);
  }, [state]);

  if (props.hideFilters) return null;

  return <>
    <div className='filters'>
      <div className="row">
        <div className="filters__inputs col">
          <div className="row">
            <div className="col is-1-of-3">
              <label htmlFor="golfer_id ">GHIN #</label>
              <input type="text" autoComplete="off" value={state.golfer_id} name="golfer_id" id="golfer_id" onChange={(e) => {
                updateFilter('golfer_id', e.target.value);
              }}/>
            </div>
            <div className="col is-1-of-3">
              <label htmlFor="email">Email Address</label>
              <input type="text" autoComplete="off" value={state.email} name="email" id="email" onChange={(e) => {
                updateFilter('email', e.target.value);
              }}/>
            </div>
            <div className="col is-1-of-3">
              <GhinFilterSelect
                options={[{label: 'Delivered', value: true},{label: 'Opened', value: "open"},{label: 'Not Delivered', value: false}]}
                value={state.sent}
                hideSelectAllOption={true}
                singleChoice={true}
                onChange={values => updateFilter('sent', values)}
                label={'Status'}
              />
            </div> 
          </div>
        </div>
        <div className="filters__controls col is-1-of-7 jc-fe">
          <button className="btn fill gray" onClick={() => reset()}>Reset</button>
        </div>
      </div>
    </div>
  </>;
}
