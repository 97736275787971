import React, { Fragment } from 'react';
import {NavLink, Switch, Route, Redirect} from 'react-router-dom';
import { Send } from './Send';
import { Statistics } from './statistics';
import { Test } from './Test';

export function FederationNewsletter() {
  return (
    <Fragment>
      <div className="vertical-tabs__container row">
        <ul className="vertical-tabs col">
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={'/manage/associations/newsletter/send'}>Send Email</NavLink></li>
          {/*<li><NavLink className="vertical-tab" activeClassName="is-active" to={'/manage/associations/newsletter/statistics'}>Statistics</NavLink></li> */}
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={'/manage/associations/newsletter/test'}>Test</NavLink></li>
        </ul>
        <div className="vertical-tabs__panel col">
          <Switch>
            <Redirect exact from="/manage/associations/newsletter" to="/manage/associations/newsletter/send"/>
            <Route path={`/manage/associations/newsletter/send`} component={Send}/>
            <Route path={`/manage/associations/newsletter/statistics`} component={Statistics}/>
            <Route path={`/manage/associations/newsletter/test`} component={Test}/>
          </Switch>
        </div>
      </div>
    </Fragment>
  );
}
