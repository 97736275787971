import request from "../shared/request"

export default class TransactionFeesServices {
  static getTransactions(associationId, params = {}) {
    return request({
      url: `/associations/${associationId}/transaction_fees.json`,
      method: "GET",
      params
    })
  }

  static createTransaction(association_id, data) {
    console.log(data)
    return request({
      url: `/associations/${association_id}/transaction_fees.json`,
      method: 'POST',
      data
    })
  }

  static updateTransaction(association_id, id, data) {
    return request({
      url: `/associations/${association_id}/transaction_fees/${id}.json`,
      method: 'PATCH',
      data
    })
  }

  static deleteTransaction(association_id, id) {
    return request({
      url: `/associations/${association_id}/transaction_fees/${id}.json`,
      method: 'DELETE'
    })
  }

  static getArchivedTransactions(association_id, id) {
    return request({
      url: `/associations/${association_id}/transaction_fees/${id}/edit_logs.json`,
      method: 'GET'
    })
  }

  static affectedJoinPages(association_id, id) {
    return request({
      url: `/associations/${association_id}/transaction_fees/${id}/join_pages.json`,
      method: 'GET'
    })
  }
  
  static getAffectedClubMembershipsCSV(association_id, id, download = false) {
    return request({
      url: `/associations/${association_id}/transaction_fees/${id}/export_to_csv.json`,
      method: 'GET',
      params: {download: download},
      responseType: 'blob'
    })
  }
}
