import React, {Component, Fragment} from 'react';
import TableFilter from "./TableFilter";
import GhinTable from "../../shared/GhinTable";
import debounce from 'debounce-promise';
import {NavLink} from 'react-router-dom';
import ClubService from "../../../services/api/club";

const QS = require("qs");

export default class AssociationSearch extends  Component {

    constructor(props) {
        super(props);

        const query = QS.parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });

        this.state = {
            filtered: [
              {id: "name", value: query.q || ''},
              {id:"is_test", value: false}],
            searchText: query.q || '',
            data: [],
            page: 1,
            per_page: 25,
            total: 0,
            loading: true
        };
    }

    loadAssociations() {
        this.setState({
            loading: true
        });
        return ClubService.getGolfAssociations("global").then((data) => {
            this.setState({
                loading: false,
                data: data.associations
            }, () => {
                this.loadAssociationSummary();
            })
        })
    }

    generateCountSummary(data) {
        return {
            total: data.length
        }
    }

    componentDidMount() {
        this.loadAssociations();
    }

    loadAssociationSummary = debounce(() => {
        const data = this.table.getFilteredData();
        this.setState({
            ...this.generateCountSummary(data)
        });
      }, 200);

    render() {
        const columns = [
            {
                Header: '#',
                accessor: 'id',
                colClassName: 'col_id'
            },
            {
                Header: 'aga',
                accessor: 'is_aga',
                show: false
            },
            {
                Header: 'is_test',
                accessor: 'is_test',
                show: false
            },
            {
                Header: 'is_trial',
                accessor: 'is_trial',
                show: false
            },
            {
                Header: 'association_group_id',
                accessor: 'association_group_id',
                show: false
            },
            {
                Header: 'Name',
                accessor: 'name',
                filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 || row['id'] === parseInt(filter.value, 10),
                Cell: props =>
					props.row._original.entitlement ?
						<Fragment>
							<NavLink to={`/manage/association/${props.row.id}/list`}>{props.value}</NavLink>
						</Fragment>
					:
						<span>{props.value}</span>
				,
                colClassName: 'col_assoc-name'
            },
            {
                Header: 'Status',
                accessor: 'status',
                colClassName: 'col_status'
            },
            {
                Header: 'City',
                accessor: 'city',
                colClassName: 'col_city'
            },
            {
                Header: 'State/Prov',
                accessor: 'state',
                colClassName: 'col_state'
            },
            {
                Header: 'Country',
                accessor: 'country',
                colClassName: 'col_country'
            },
            {
                Header: 'Contact Name',
                accessor: 'primary_contact_name',
                colClassName: 'col_contact'
            },
            {
                Header: 'Phone Number',
                accessor: 'telephone',
                colClassName: 'col_phone-number'
            },
            {
                Header: 'Email Address',
                accessor: 'email',
                colClassName: 'col_email',
				Cell: row => <Fragment>
					<a href={`mailto: ${row.value}`}>{row.value}</a>
				</Fragment>
            },
			{
                Header: 'Website',
                accessor: 'web_address',
                colClassName: 'col_email',
				Cell: row => <Fragment>
						<a href={`//${row.value}`}>{row.value}</a>
					</Fragment>
            }];


        return (
            <section className="default">

                <TableFilter
                    searchText={this.state.searchText}
                    updateFiltered={(filtered) => {
                        this.setState({
                            filtered, page: 1
                        }, () => this.loadAssociationSummary())
                    }}
                />

                <h2 className="section__title large">Association Results ({this.state.total ? this.state.total : 0})</h2>


                <GhinTable
                    columns={columns}
                    data={this.state.data}
                    filtered={this.state.filtered}
                    ref={(r) => this.table = r}
                    defaultSorted={[{
                        id: 'name',
                        desc: false
                    }]}
                    sortable={true}
                    loading={this.state.loading}
                    hideSelect={true}
                    pageSize={this.state.per_page}
                    onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.loadAssociationSummary())}
                    onPageSizeChange={(pageSize, pageIndex) => this.setState({
          						per_page: pageSize,
          						page: pageIndex
          					}, () => this.loadAssociationSummary())}
                />

            </section>
        )
    }
}
