import React, {Fragment, useState} from 'react';
import DatePicker from "react-datepicker";
import {checkKeyForDateInput} from "./helper.js"

export const DatePickerInput = (field, hideErrorMessages) => {
  const [clickedOutside, setClickedOutside] = useState(false)
  const [wasFocused, setWasFocused] = useState(false)
  return (
	<Fragment>
		<DatePicker
			className={`${field.className} ${field.meta.error && wasFocused && clickedOutside ? 'is-error' : ''}`}
			disabled={field.disabled}
			value={field.input.value}
			maxDate={field.maxDate}
			minDate={field.minDate}
			popperPlacement={field.propperPlacement}
			dropdownMode="select"
			dateFormatCalendar={field.dateFormatCalendar ? field.dateFormatCalendar : "MM/dd/yyyy" }
			dateFormat={field.dateFormat ? field.dateFormat : "MM/dd/yyyy"}
			showMonthDropdown={field.showMonthDropdown}
			showYearDropdown={field.showYearDropdown}
			selected={field.input.value}
			onChange={field.input.onChange}
			placeholderText={field.placeholderText ? field.placeholderText : ""}
			onFocus={e => {
        setWasFocused(true)
				if(!e.target.value && field.minDate)
					field.input.onFocus()
			}}
      onClickOutside={e => {
        setClickedOutside(true)
      }}
			onKeyDown={e => {checkKeyForDateInput(e)}}
		/>
		{(!field.hideErrorMessages && field.meta.error && ((wasFocused && clickedOutside) || field.meta.touched) && <p className="validation__message is-error">{field.meta.error}</p>)}
	</Fragment>
)
}
