import React, {Component, Fragment} from 'react';
import Modal from "react-modal"

import EditHeaderAndFooterForm from '../Forms/EditHeaderAndFooterForm';

export default class EditHeaderAndFooterModal extends Component {


	render() {
		return (
      <Modal
				isOpen={true}
				onRequestClose={() => { this.props.closeModal() }}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
      >
			<Fragment>
				<div className={`modal__container narrow ${this.props.className}`}>
					<div className="modal__head">
						<div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
						<h2 className="modal__title">Edit Header & Footer Bar</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
					</div>
					<div className="modal__body">
						<EditHeaderAndFooterForm associationId={this.props.associationId} emailSettings={this.props.emailSettings} closeModal={(data)=>{this.props.closeModal(data)}}/>
					</div>
				</div>
			</Fragment>
		</Modal>
		);
	}
}
