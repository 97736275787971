import React, {Component, Fragment} from 'react';

import EditLogoInformationForm from '../Forms/EditLogoInformationForm';
import EditImageInformationForm from '../Forms/EditImageInformationForm';
import EditImageInformationFormOld from '../Forms/EditImageInformationFormOld';


export default class EditImageInformationModal extends Component {


	render() {

		return (
                <Fragment>

                    <div className="modal__container narrow">

                        <div className="modal__head">

                            <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                            <h2 className="modal__title">{this.props.logo? "Edit Header Content Information" : "Edit Image Information"}</h2>
                            <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                        </div>

                        <div className="modal__body">
														{
														this.props.logo ? <EditLogoInformationForm errors={this.props.errors}
																																			 image={this.props.image}
																																			 has_ad_image_url={this.props.has_ad_image_url}
																																			 alt_text={this.props.alt_text}
																																			 email_settings={this.props.email_settings}
																																			 mobile_image1_ad_tag={this.props.mobile_image1_ad_tag}
																																			 mobile_image2_ad_tag={this.props.mobile_image2_ad_tag}
																																			 closeModal={(data) => {this.props.closeModal(data)}}/>
															:
	                            this.props.email ?
	                                <EditImageInformationFormOld email={this.props.email} closeModal={this.props.closeModal}/>
	                                :
	                                <EditImageInformationForm display_image_name = {this.props.display_image_name}
																														errors={this.props.errors} image={this.props.image}
																														has_ad_image_url={this.props.has_ad_image_url}
																														ad_image_url={this.props.ad_image_url}
																														remove_alt_text={this.props.remove_alt_text}
																														alt_text={this.props.alt_text}
																														mobile_image1_ad_tag={this.props.mobile_image1_ad_tag}
																														mobile_image2_ad_tag={this.props.mobile_image2_ad_tag}
																														is_deep_link={this.props.is_deep_link}
																														mobile_deep_links={this.props.mobile_deep_links}
																														closeModal={(data) => {this.props.closeModal(data)}}/>
														}
                        </div>

                    </div>

                </Fragment>
		);
	}
}
