import React, {Component, Fragment} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {DatePickerInput} from "../../inputs/DatePicker";
import {RadioButtons} from "../../inputs/RadioButtons";
import CourseRatingSystemService from "../../services/api/courseRatingSystem";
import debounce from "debounce-promise";
import {AsyncSelectInput} from "../../inputs/AsyncSelectInput";
import ScoreService from "../../services/api/score";
import moment from "moment";
import {customRequired, isNumber, totalScoreValue} from "../../inputs/validations";
import ManualCourseEditForm from "./ManualCourseEditForm";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import ConfirmationModalTypeB from "../Modals/ConfirmationModalTypeB";
import GolferService from "../../services/api/golfer";


class EditTotalScoreForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
			courses: [],
			teeSetsOptions: [],
			teeOptions: [],
			golfer: this.props.golfer,
			errors: [],
			missingTeeOrCourse: false,
			overrideModal: false
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.getCoursesOptions = this.getCoursesOptions.bind(this);
    this.setErrors = this.setErrors.bind(this);
    }

    componentDidMount() {
    	this.initializeScoreFromProps();
	}

	initializeScoreFromProps() {
		if (this.props.score) {
			let score = JSON.parse(JSON.stringify(this.props.score));
			score.played_at = new Date(moment(score.played_at, 'YYYY-MM-DD'));
			score.course_tee_lookup_method = 'search_courses';
			this.props.initialize({
				...score,
				slope: this.props.score.slope_rating,
				cr: parseFloat(this.props.score.course_rating),
				front9: this.props.score.front9_adjusted,
				frontCr: this.props.score.front9_course_rating,
				frontSlope: this.props.score.front9_slope_rating,
				back9: this.props.score.back9_adjusted,
				backCr: this.props.score.back9_course_rating,
				backSlope: this.props.score.back9_slope_rating,
				course_name_label: score.course_display_value,
				course_name: {label: score.course_display_value, value: -1},
				course_id: this.props.selectedAwayCourse ? this.props.selectedAwayCourse : '',
				tee_set_id: this.props.selectedTee ?
					(this.props.emptyCombined ?
						{
							label: this.props.selectedTee.teeName +  '(' + parseFloat(this.props.score.course_rating).toFixed(1) + '/' + this.props.score.slope_rating + '/' + this.props.selectedTee.par + ')',
							value: this.props.selectedTee.value
						}
						:
						this.props.selectedTee)
					:
					{label: score.tee_name, value: -1}
			});

		}
	}

	getCoursesOptions(searchTerm, callback) {
		if (searchTerm && searchTerm.length > 2) {
			return CourseRatingSystemService.searchCourses(searchTerm)
				.then(response => {
					let options = response.courses.map(c => {
						return {value: c.CourseID, label: `${c.FullName} ${c.City && c.State ? `(${c.City}, ${c.State})` : ''}`, unparsedLabel: c.FullName}
					});
					this.setState({
						courses: options
					});
					callback(options)
				})
				.catch(err => {

				})
		} return new Promise(resolve => {
			resolve([])
		})
	}

	onSubmit(values) {
    	let v = {
    		golfer_id: this.props.score.golfer_id,
        course_id: this.props.selectedAwayCourse ? parseInt(values.course_id.courseId) : null,
			course_name: !this.props.combinedScore ? (this.props.selectedAwayCourse ? values.course_id.label : values.course_name_label): values.course_name_label,
			played_at: moment(values.played_at).format("YYYY-MM-DD"),
        score_type: this.props.score_type,
			number_of_holes: this.props.score.number_of_holes,
			gender: this.state.golfer.gender,
			adjusted_gross_score: values.adjusted_gross_score,
			tee_name: `Manual / ${values['cr']} / ${values['slope']}`,
			tee_set_side: values.tee_set_side ? values.tee_set_side : this.props.score.number_of_holes === 18 ? 'All18' : 'F9' ,
			course_rating: parseFloat(values['cr']),
			is_manual: true,
			slope_rating: parseFloat(values['slope'])
		};

		if (values.course_name.value !== -1) {
			v['is_manual'] = false;
		}
		if(this.props.emptyCombined) {
			if(!this.props.selectedTee){
				v['is_manual'] = true;
			}
			v['tee_name'] = "";
			v['course_rating'] = this.props.score.course_rating;
			v['slope_rating'] = this.props.score.slope_rating;
			v['course_id'] = this.props.score.course_id;
			v['course_name'] = this.props.score.course_name;
			v['tee_set_side'] = "All18";
			v['tee_set_id'] = this.props.score.tee_set_id;
		}


    	if (this.props.combined_score_id && !this.props.emptyCombined) {
    		v['combined_score_id'] = this.props.combined_score_id;
    		v['score_index'] = this.props.selectedScoreIndex;
    		v['combined_9_hole_score'] = this.props.combined_9_hole_score;
		}


    	if (values.backCr && values.frontCr) {
    		v.front9_adjusted = parseFloat(values['front9']);
			v.front9_course_rating = parseFloat(values['frontCr']);
			v.front9_slope_rating = parseFloat(values['frontSlope']);
			v.back9_adjusted = parseFloat(values['back9']);
			v.back9_course_rating = parseFloat(values['backCr']);
			v.back9_slope_rating = parseFloat(values['backSlope']);
		}

    	return ScoreService.editScore({score: {...v}}, this.props.score.id)
			.then(response => {
        if (response['errors']) {
          this.setErrors({ "data": response});
        } else {
          this.props.closeModal(true);
          this.props.setOutdatedScoreModalOpen(!response.score.is_recent);
        }
			})
			.catch(err => {
        this.setErrors(err);
			})
	}
  
  setErrors(err) {
    let errors = [];
    if(err.data.errors) {
      Object.keys(err.data.errors).map(error => {
        const msg = err.data.errors[error]
				const display_msg = msg.includes('No TeeSet found') ? 'No TeeSet found' : msg
				errors.push(display_msg);
        return null;
      });
    }
    this.setState({errors});
  }

	removeScoreDifferentials() {
		GolferService.removeScoreDifferentials(this.props.score.golfer_id, this.props.score.id)
			.then(() => {
				this.props.closeModal(true, true);
			})
			.catch(err => {
				console.log(err);
			})
	}

	render() {
    	const {handleSubmit, pristine, submitting} = this.props,
			self = this;
    	if (!this.props.score) return null;

		return (
            <form onSubmit={handleSubmit(this.onSubmit)}>

                <div className="row">
                    <div className="col is-1-of-4">
                        <label>Date Played <span>*</span></label>
                        <Field
							maxDate={new Date()}
							minDate={new Date('1/1/1990')}
              showMonthDropdown
              showYearDropdown
							component={DatePickerInput}
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            dropdownMode="select"
							name={'played_at'}
                        />
                    </div>
                    <div className="col is-1-of-2">
                        <label>Score Type</label>
						<Field component={RadioButtons} parentClassName={'custom-radio__container'} fieldName={'score_type'} options={
							[
								{id: "home", label: "Home", value: "H"},
								{id: "away", label: "Away", value: "A"},
								{id: "tournament", label: "Competition", value: "T"}
							]
						}/>
                    </div>
                    <div className="col is-1-of-4">
                        <label>Holes</label>
						<Field component={RadioButtons} parentClassName={'custom-radio__container'} fieldName={'number_of_holes'} options={
							[
								{id: "18", label: "18", value: 18, isNumber: true, isDisabled: true},
								{id: "9", label: "9", value: 9, isNumber: true, isDisabled: true},
							]
						}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-1-of-2 jc-fs">
                        <label>Choose Course / Tee Lookup Method</label>
						<Field component={RadioButtons} parentClassName={'radio__container'} fieldName={'course_tee_lookup_method'} options={
							[
								{id: "home-courses-tees", label: "Home Courses/Tees", value: 'home_courses', className: 'simple__radio', isDisabled: true},
								{id: "course-tee-search", label: "Course/Tee Search", value: 'search_courses', className: 'simple__radio', isDisabled: true},
							]
						}/>
                    </div>

                    <div className={`col is-2-of-4`}>
						{!this.props.selectedAwayCourse ?
							<ManualCourseEditForm score={this.props.score} forcedManual={this.props.forcedManual && this.props.combinedScore} change={this.props.change} dynamicSelector={this.props.dynamicSelector}/>
							:
							<Fragment>
								<div className={"row"}>
									<div className={"col"}>
										<label htmlFor="course-played">Course Played <span>*</span></label>
										<Field name={'course_id'}
											   id={'course_id'}
											   hideErrorMessages={true}
											   disabled={this.props.emptyCombined}
											   validate={[customRequired({fieldName: 'Course Played'})]}
											   defaultOptions={this.state.courses}
											   loadOptions={debounce(self.getCoursesOptions, 1000)}
											   component={AsyncSelectInput}
											   onChange={(event, newValue, prevValue) => {
												   if (newValue) {
													   this.props.change('course_id', newValue);
												   } else {
													   this.props.change('course_id', null);
												   }
											   }}
											   className="react-select-container"
											   classNamePrefix="react-select"
										/>
									</div>
									{this.props.emptyCombined &&  this.props.selectedTee &&
										<div className="col">
											<label>Tee <span>*</span></label>
											<Field  className="react-select-container"
													component={SelectInput}
													disabled={true}
													name={'tee_set_id'}
													classNamePrefix="react-select"
													options={this.state.teeSetsOptions}/>
										</div>
									}
								</div>
								{this.props.emptyCombined &&  this.props.selectedTee ?
									<div className="row">
										{this.props.score.number_of_holes === 18 &&
										<div className="col is-1-of-3">
											<label htmlFor="front9">Front 9</label>
											<Field type={'text'} component={InputField}
												   name={'front9'}
												   disabled={this.props.score.number_of_holes === 18}/>
										</div>
										}
										{this.props.score.number_of_holes === 18 &&
										<div className="col is-1-of-3">
											<label htmlFor="back9">Back 9</label>
											<Field type={'text'} component={InputField}
												   name={'back9'}
												   disabled={this.props.score.number_of_holes === 18}/>
										</div>
										}
										<div
											className={`col is-1-of-3 ${this.props.score.number_of_holes !== 18 ? 'push-right' : ''}`}>
											<label
												htmlFor="adjusted_gross_score">Total {this.props.score.number_of_holes === 18 ? 18 : 9}<span>*</span></label>
											<Field hideErrorMessages={true}
												   validate={[customRequired({fieldName: 'Total Score'}), isNumber, totalScoreValue()]}
												   type={'text'} component={InputField}
												   name={'adjusted_gross_score'}/>
										</div>
									</div>
									:
									<div className={"row"}>
										<div className={"col"}>
											<ManualCourseEditForm score={this.props.score} change={this.props.change} asyncSelect={true} dynamicSelector={this.props.dynamicSelector}/>
										</div>
									</div>
								}
							</Fragment>

						}
                    </div>

                </div>

				{this.props.formValuesErrors && this.props.formFields &&
				<p>
					{Object.entries(this.props.formValuesErrors).map(([key, error]) => {
						if (this.props.formFields[key] && this.props.formFields[key].touched) {
							return (<span className={'validation__message is-error'}>{error}</span>);
						}
            return null;
					})}
				</p>
				}
				{this.state.errors.length > 0 &&
				<p>
					{this.state.errors.map(error=> {
						return (<span className={'validation__message is-error'}>{error}</span>);
					})}
				</p>
				}
                <div className="row align-right">
					{this.props.score.exceptional &&
					<div className="col is-1-of-4 ai-fe jc-c" style={{height:'40px'}}>
						{this.props.overriden ?
							'ESR Adjustment Overriden'
							:
							<button onClick={() => this.setState({overrideModal: true})} type={'button'} className="btn fill gray">Override ESR Adjustments</button>
						}
					</div>
					}
					{this.props.score.committee_master_score && <div className="col is-2-of-6">
						<button onClick={() => this.setState({confirmScoreDifferentialsRemoval: true})}
								type={'button'} className="btn fill gray">Remove Score Differentials
						</button>
					</div>}
                    <div className="col is-1-of-6">
                        <button onClick={() => this.props.closeModal()}  type={'button'} className="btn fill gray">Close</button>
                    </div>
                    <div className="col is-1-of-6">
                        <button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Edit Score</button>
                    </div>
                </div>
				{this.state.overrideModal &&
				<ConfirmationModalTypeB openModal={this.state.overrideModal}
										wideClass={"narrow"}
										onConfirmAction={() => {this.props.overrideScore(); this.setState({overrideModal: false}); }}
										modalIcon={"error_outline"}
										onCancelAction={() => {
											this.setState({overrideModal: false})
										}}
										closeModal={() => {
											this.setState({overrideModal: false})
										}}
										infoText={["Please confirm that you would like to override the ESR adjustments of this score"]}
										cancelLabel={"Close"}
										confirmLabel={"Confirm"}/>
				}
				{this.state.confirmScoreDifferentialsRemoval &&
				<ConfirmationModalTypeB openModal={true}
										wideClass={"narrow"}
										onConfirmAction={() => {this.removeScoreDifferentials(); this.setState({confirmScoreDifferentialsRemoval: false}); }}
										modalIcon={"error_outline"}
										onCancelAction={() => {
											this.setState({confirmScoreDifferentialsRemoval: false})
										}}
										closeModal={() => {
											this.setState({confirmScoreDifferentialsRemoval: false})
										}}
										infoText={["Please confirm that you would like to remove the score differentials adjustments."]}
										cancelLabel={"Close"}
										confirmLabel={"Confirm"}/>
				}
            </form>

		);
	}
}

EditTotalScoreForm = reduxForm({
	form: 'editScoreForm',
	destroyOnUnmount: true,
	touchOnChange: true
})(EditTotalScoreForm);

function mapStateToProps(state) {
	const selector = formValueSelector('editScoreForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		score_type: selector(state, 'score_type'),
		formValuesErrors: state.form['editScoreForm'] ? state.form['editScoreForm'].syncErrors : {},
		formFields: state.form['editScoreForm'] ? state.form['editScoreForm'].fields : {},
		dynamicSelector: (v) => selector(state, v)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditTotalScoreForm)));
