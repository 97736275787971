import React, {Component} from 'react';
import Modal from "react-modal";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {required} from "../../inputs/validations";
import {SelectInput} from "../../inputs/Select";
import {withRouter} from "react-router-dom";
import uniqBy from 'lodash.uniqby';
import {logoutUser} from "../Auth/actions";
import Cookies from 'universal-cookie';

class AffiliationChange extends Component {

	constructor(props) {
		super(props);

		this.state = {
			associations: [],
			clubs: [],
			options: [],
			selectedAssociation: null,
			hasAdditionalStep: false
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.buildOptions();
	}

	onSubmit(values) {
		let access = {};
		if (this.state.options.length > 0) {
			const cookies = new Cookies();
			cookies.remove('gotAlert', { path: '/' });
			if (values.association.isClubDerived) {
				access.homepage = values.association.role !== "score_only" ? `/manage/association/${values.association.association_id}/club/${values.association.value}` : '/rapid-score-entry';
				access.default_association_id = values.association.association_id;
				access.default_association_name = values.association.association_name;
				access.club_role = values.association.role;
				access.reporting_token = values.association.reporting_token;
				access.default_club_id = values.association.value;
				access.default_club_name = values.association.club_name;
				access.canChangeAffiliation = true;
				access.association_role = null;
				access.super_user = false;
				switch(values.association.role) {
					case "admin" : access.user_type = "club-admin"; break;
					case "read_only" : access.user_type = "club-read-only"; break;
					case "score_maintenance" : access.user_type = "club-score-maintenance"; break;
					case "score_only" : access.user_type = "score-only"; break;
					default: break;
				}
			} else {
				access.homepage =  values.association.role !== "score_only" ? `/manage/association/${values.association.value}` : '/rapid-score-entry';
				access.default_association_id = values.association.value;
				access.default_association_name = values.association.label;
				access.default_club_id = 0;
				access.default_club_name = null;
				access.club_role = null;
				access.reporting_token = values.association.reporting_token;
				access.association_role = values.association.role;
				switch(values.association.role) {
					case "admin" : access.user_type = "association-admin"; break;
					case "read_only" : access.user_type = "association-read-only"; break;
					case "score_maintenance" : access.user_type = "association-score-maintenance"; break;
					case "score_only" : access.user_type = "score-only"; break;
					default: break;
				}
				access.canChangeAffiliation = true;
				access.super_user = false;
			}
		}

		let cookies = new Cookies();
    cookies.remove('gotAssociationAlert', { path: '/'});
    cookies.remove('closedAssociationAlert', { path: '/'});
  
    this.props.dispatch({
      type: "@@ghin/CLEAR_ASSOCIATION_ALERT_DATA"
    })

		this.props.dispatch({
			type: '@@ghin/ADD_USER_PARSED_ACCESS',
			payload: access
		});
	}

	componentWillUpdate(nextProps, nextState, nextContext) {
		if(this.props.access.homepage !== nextProps.access.homepage) {
			if (this.props.closeModal) this.props.closeModal();
			this.props.history.push('/manage');
		}
		if((this.props.access.default_association_id !== nextProps.access.default_association_id || this.props.access.default_club_id !== nextProps.access.default_club_id) && nextProps.access.user_type === "score-only"){
			if (this.props.closeModal) this.props.closeModal();
			this.props.history.push('/auth');
		}
	}


	buildOptions() {
		let associations = this.props.associations.map(assoc => {
			return {
				value: assoc.golf_association.id,
				label: assoc.user_access.organization_name,
				association_id: assoc.golf_association.id,
				association_name: assoc.user_access.organization_name,
				role: assoc.user_access.role,
				isClubDerived: false,
				reporting_token: assoc.user_access.reporting_token
			}
		});

		let clubs = this.props.clubs.map(club => {
			return {
				value: club.club.id,
				label: club.user_access.organization_name + ' - ' + club.user_access.association_name,
				club_name: club.user_access.organization_name,
				isClubDerived:true,
				role: club.user_access.role,
				association_id: club.user_access.association_id,
				association_name: club.user_access.association_name,
				reporting_token: club.user_access.reporting_token
			}
		});

		let options = uniqBy([...associations, ...clubs], v => v.value);

		this.setState({options: options.sort((a, b) => a.label.localeCompare(b.label))}, ()=> {
			if(this.props.access) {
				this.props.initialize({association: this.state.options.filter(option => this.props.access.default_club_id ? (option.value === this.props.access.default_club_id && option.isClubDerived) : (option.association_id === this.props.access.default_association_id && !option.isClubDerived) )[0]} )
			}
		})
	}

	closeModal(e)
	{
		if (this.props.homepage) {
			e.preventDefault();
			this.props.closeModal()
		}
		else {
			this.props.logoutUser();
		}
	}


	render() {
		let {handleSubmit, pristine, submitting} = this.props;
		return (
			<section className={this.props.homepage ? '' : 'auth'}>
				<Modal
					isOpen={true}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<div className="modal__container narrow">

						<div className="modal__head">

							<div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
							<h2 className="modal__title">Select Account to Manage</h2>
							<button className="modal__close" onClick={() => {this.props.homepage ? this.props.closeModal() : this.props.logoutUser()}}><i className="material-icons-outlined">clear</i></button>

						</div>

						<div className="modal__body">
							<form onSubmit={handleSubmit(this.onSubmit)}>
								{this.state.options.length > 0 &&
								<div className="row">
									<div className="col is-1-of-2">
										<label htmlFor="association">Change Account <span>*</span></label>
										<Field options={this.state.options} className={'association_name'}
											   validate={[required]} component={SelectInput} type="text"
											   name="association" id="association"/>
									</div>
								</div>
								}

								<div className="row">
									<div className="col is-1-of-2">
										<button onClick={(e) => this.closeModal(e)} className="btn fill gray">Cancel</button>
									</div>
									<div className="col is-1-of-2">
										<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Submit</button>
									</div>
								</div>
							</form>
						</div>

					</div>
				</Modal>
			</section>
		);
	}
}

AffiliationChange = reduxForm({
	form: 'affiliationChangeAssociationForm',
	destroyOnUnmount: true
})(AffiliationChange);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		associations: state.user.rawAccess.associations,
		clubs: state.user.rawAccess.clubs,
		homepage: state.user.access.homepage,
		access: state.user.access,
		rawAccess: state.user.rawAccess
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({logoutUser}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AffiliationChange));
