import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, formValueSelector, initialize, reduxForm } from "redux-form";
import {
	isCustomNumeric,
	required,
	customMsgRequired,
	requiredSelect,
	isNumber,
	greaterThanCustomMessage,
	lowerThanCustomMessage,
	maxLength,
	alphaNumericOrPoint
} from "../../inputs/validations";
import { InputField } from "../../inputs/InputField";
import { SelectInput } from "../../inputs/Select";
import { addAlert } from "../shared/actions";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import ClubService from "../../services/api/club";

function MembershipFee(props) {
	const [error, setError] = useState("");
	const [membershipTypes, setMembershipTypes] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);

	const genderOptions = [
		{
			value: "male and female",
			label: "Male & Female",
		},
		{
			value: "male",
			label: "Male",
		},
		{
			value: "female",
			label: "Female",
		},
	];

	useEffect(() => {
		const getMembershipCodes = () => {
			ClubService.getMembershipTypes(props.clubId, {include_used_membership: 'false'})
				.then((res) => {
					if (res.membership_types) {
						setMembershipTypes(
							res.membership_types.map((mt) => {
								let bill_rate = parseFloat(mt.bill_rate).toFixed(2)
								return {
									amount: mt.bill_rate,
									label: `${mt.code} - ${mt.type} Membership - $${bill_rate}`,
									value: mt.club_rate_id,
								};
							})
						);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};

		getMembershipCodes();
	}, [props.clubId]);

	useEffect(() => {
		if (props.selectedMembershipFee) {
			const initialize = props.initialize;
			const fee = props.selectedMembershipFee;
			const genderEligibility = genderOptions.filter((g) => {
				return g.value === fee.gender;
			});
			const transactionFee = props.transactionFees.filter((tf) => {
				return tf.value === fee.golf_association_fee_id;
			});
			const membershipType = membershipTypes.filter((mt) => {
				return mt.value === fee.club_rate_id;
			});

			initialize({
				...fee,
				gender_eligibility: genderEligibility[0],
				transaction_fee: transactionFee[0],
				club_rate: membershipType[0],
				membershipType: membershipType[0],
			});
		} else {
			const transactionFee = props.transactionFees.filter((tf) => {
				return tf.is_default === true;
			});
			props.initialize({
				transaction_fee: transactionFee[0],
				gender_eligibility: {
					value: "male and female",
					label: "Male & Female",
				},
			});
		}
	}, [membershipTypes]);

  useEffect(() => {
    const membership_type = parseFloat(props.membershipType?.amount || 0.0)
    const transaction_fee = parseFloat(props.transactionFee?.amount || 0.0)
    const membership_amount = isNaN(parseFloat(props.membership_amount || 0.0)) ? 0.0 : parseFloat(props.membership_amount || 0.0)
    setTotalAmount(membership_type + transaction_fee + membership_amount);
	}, [props.membershipType, props.transactionFee, props.membership_amount]);

	const onSubmit = (values, dispatch, props) => {
		let data = JSON.parse(JSON.stringify(values));
		if (data.transaction_fee){
			if (data.transaction_fee.value !== '' && data.transaction_fee.value !== null)
				data.golf_association_fee_id = data.transaction_fee.value;
			else 
				data.no_transaction_fee = 'true';
		}
		data.club_rate_id = data.club_rate.value;
		data.gender_eligibility = values.gender_eligibility.value;
		delete data.transaction_fee;
		delete data.club_rate;
		
		if (!props.selectedMembershipFee) {
			ClubService.addMembershipFee(props.clubId, data)
				.then(() => {
					props.addAlert({
						type: "success",
						message:
							"Membership Fee has been successfully created.",
					});
					props.closeModal();
					props.reload_clubs();
				})
				.catch((e) => {
					var error = ""
          try {
            let key = Object.keys(e.data.errors)[0]
            error = e.data.errors[key][0]
          } catch {
            error = "An error has occurred"
          }
					props.closeModal();
          props.addAlert({
            type: 'error',
            message: error
          })
				});
		} else {
			ClubService.editMembershipFee(
				props.clubId,
				props.selectedMembershipFee.id,
				data
			)
				.then(() => {
					props.addAlert({
						type: "success",
						message: "Membership Fee has been successfully edited.",
					});
					props.closeModal();
					// reload
					props.reload_clubs();
				})
				.catch((e) => {
					var error = ""
          try {
            let key = Object.keys(e.data.errors)[0]
            error = e.data.errors[key][0]
          } catch {
            error = "An error has occurred"
          }
					props.closeModal();
          props.addAlert({
            type: 'error',
            message: error
          })
				});
		}
	};

	const [maximumAge, setMaximumAge] = useState(100);
	const [minimumAge, setMinimumAge] = useState(0);

	const { handleSubmit, pristine, valid, submitting } = props;

	return (
		<Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col">
						<label htmlFor="name">
							Membership Fee Name <span>*</span>
						</label>
						<Field
							component={InputField}
							type="text"
							validate={[customMsgRequired('Please provide a Membership Fee Name'), maxLength([50, "Value too long (max 50 characters)"])]}
							name="name"
							id="name"
						/>
					</div>
				</div>

				<div className="row">
					<div className="col">
						<label htmlFor="description">
							Membership Fee Description <span>*</span>
						</label>
						<Field
							component={InputField}
							type="text"
							validate={[customMsgRequired('Please provide a Membership Fee Description'), maxLength([200, "Value too long (max 200 characters)"])]}
							name="description"
							id="description"
						/>
					</div>
				</div>

        <div className="row">
					<div className="col is-1-of-2">
						<label htmlFor="expense_code">
							Expense Code
						</label>
						<Field
							component={InputField}
							type="text"
							validate={[alphaNumericOrPoint, maxLength([15, "Value too long (max 15 characters)"])]}
							name="expense_code"
							id="expense_code"
						/>
					</div>
          <div className="col is-1-of-2">
            <span style={{ marginTop: "27px" }}>Enter an Expense Code to track business activities in your association. (Not Required)</span>
          </div>
				</div>

				<div className="row" style={{marginBottom: 0}}>
					<div className="col">
						<span className={"red"} style={{fontStyle: "italic"}}>NOTE: This fee is effective immediately in the association's local timezone.</span>
					</div>
				</div>

				<hr
					style={{
						border: "0.5px solid #cccccc",
						marginTop: "25px",
						marginBottom: "25px",
					}}
				></hr>

				<div className="row">
					<div className="col is-3-of-5 centered">
						<label htmlFor="amount">
							Club Membership Fee <span>*</span>
						</label>
						<Field
							component={InputField}
              placeholder={'0.00'}
							type="text"
							validate={[customMsgRequired('Please provide a numerical Club Membership Fee amount'), isCustomNumeric(8)]}
							name="amount"
							id="amount"
						/>
					</div>
				</div>

				<div className="row">
					<div className="col is-3-of-5 centered">
						<label htmlFor="club_rate">
							Association Membership Code <span>*</span>
						</label>
						<Field
							component={SelectInput}
							validate={[requiredSelect]}
							name="club_rate"
							id="club_rate"
							className="react-select-container"
							classNamePrefix="react-select"
							options={membershipTypes}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col is-3-of-5 centered">
						<label htmlFor="transaction_fee">
							Association Transaction Fee
						</label>
						<Field
							component={SelectInput}
							name="transaction_fee"
							id="transaction_fee"
							className="react-select-container"
							classNamePrefix="react-select"
							options={props.transactionFees}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col is-3-of-5 centered">
						<label htmlFor="total_fee">Total Fee:</label>
						<div
							style={{
								textAlign: "center",
								borderRadius: "6px",
								backgroundColor: "#cccccc",
								padding: "10px",
							}}
						>
							${totalAmount.toFixed(2)}
						</div>
					</div>
				</div>

				<hr
					style={{
						border: "0.5px solid #cccccc",
						marginTop: "25px",
						marginBottom: "25px",
					}}
				></hr>

				<div className="row">
					<div className="col">
						<label htmlFor="age_restrictions">
							Eligibility Requirements
						</label>
						<p>
							If there are any gender or age requirements for this
							club membership, please indicate below.
						</p>
					</div>
				</div>

				<div className="row">
					<div
						className="col is-1-of-5"
						style={{ textAlign: "right", marginTop: "11px" }}
					>
						<label htmlFor="gender_eligibility">Gender</label>
					</div>
					<div className="col is-4-of-5">
						<div className="col is-1-of-2">
							<Field
								component={SelectInput}
								id="gender_eligibility"
								name="gender_eligibility"
								className="react-select-container"
								classNamePrefix="react-select"
								options={genderOptions}
							/>
						</div>
					</div>
				</div>

				<div className="row">
					<div
						className="col is-1-of-5"
						style={{ textAlign: "right", marginTop: "35px" }}
					>
						<label htmlFor="age">Age</label>
					</div>
					<div className="col is-4-of-5">
						<div style={{ flexDirection: "row", display: "flex" }}>
							<div className="col is-1-of-3">
								<label htmlFor="minimum_age">Minimum Age</label>
								<Field
									component={InputField}
									validate={[
										isNumber,
										lowerThanCustomMessage([
											maximumAge || "",
											"Minimum Age cannot be higher than Maximum Age",
										]),
										maxLength([3, "Value too long (max 3 digits)"])
									]}
									onChange={(e) => {
										setMinimumAge(e.target.value);
									}}
									type="text"
									name="minimum_age"
									id="minimum_age"
								/>
							</div>
							<div className="col is-1-of-3">
								<label htmlFor="maximum_age">Maximum Age</label>
								<Field
									component={InputField}
									validate={[
										isNumber,
										greaterThanCustomMessage([
											minimumAge || "",
											"Maximum Age cannot be lower than Minimum Age",
										]),
										maxLength([3, "Value too long (max 3 digits)"])
									]}
									onChange={(e) => {
										setMaximumAge(e.target.value);
									}}
									type="text"
									name="maximum_age"
									id="maximum_age"
								/>
							</div>
						</div>
					</div>
				</div>

				{error && (
					<span className={"validation__message is-error"}>
						{error}
					</span>
				)}
				<div className="row">
					<div className="col is-1-of-2">
						<button
							type={"button"}
							className="btn fill gray"
							onClick={() => props.closeModal()}
						>
							Cancel
						</button>
					</div>
					<div className="col is-1-of-2">
						<button
							type={"submit"}
							disabled={pristine || submitting || !valid || error}
							className="btn fill blue"
						>
							Save
						</button>
					</div>
				</div>
			</form>
		</Fragment>
	);
}

const MembershipFeeForm = reduxForm({
	form: "membershipFeeForm",
	destroyOnUnmount: true,
})(MembershipFee);

function mapStateToProps(state) {
	const selector = formValueSelector("membershipFeeForm");
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		membership_amount: selector(state, "amount"),
		transactionFee: selector(state, "transaction_fee"),
		membershipType: selector(state, "club_rate"),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ addAlert }, dispatch);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withKeyPress(MembershipFeeForm));
