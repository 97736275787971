import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {connect} from "react-redux";

const PrivateRoute = ({ component: Component, isAuthenticated, userType,  props, ...rest}) => (
	<Route
		{...rest}
		render={props_ => {
			return (isAuthenticated ?
					userType !== 'score-only' ?
						(<Component {...props_} {...props} />)
						:
						(props_.location.pathname === '/rapid-score-entry' || props_.location.pathname === '/affiliation/select'  ?
								(<Component {...props_} {...props} />)
								:
								(<Redirect to={{ pathname: '/rapid-score-entry', state: { from: props_.location} }} />))
					:
					(<Redirect to={{ pathname: '/auth/login', state: { from: props_.location} }} />)
			)
		}}
	/>
);

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		userType: state.user.access?.user_type,
	};
}

export default connect(mapStateToProps, null)(PrivateRoute);
