import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {isEmailAddress, required, socialMediaURL} from "../../inputs/validations";
import {InputField} from "../../inputs/InputField";
import AssociationService from "../../services/api/association";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {SelectInput} from "../../inputs/Select";


const socialMediaOptions = [
	{ value: "Facebook", label: "Facebook" },
	{ value: "Twitter", label: "Twitter" },
	{ value: "Instagram", label: "Instagram" },
	{ value: "None", label: "None" },
];
class EditAssocGeneralSettingsForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			status: false,
			preferred_social_media_platform: this.props.assocSettings.preferred_social_media_platform,
		}
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {
		values['preferred_social_media_platform'] = values['preferred_social_media_platform'].value;
		if (values['preferred_social_media_platform'] == 'None') {
			values['preferred_social_url'] = "";
			values['preferred_social_media_platform'] = "";
		}
		return AssociationService.editGolferProducts(props.assocId, props.assocSettings.id,values)
			.then(res => {
				props.addAlert({
					type:'success',
					message:'Association general settings has been successfully edited'
				});
				props.close(res.association_settings);
			})
			.catch(err=>{console.error(err)});
	}

	componentDidMount() {
		const social_media = this.props.assocSettings.preferred_social_media_platform;
		this.props.onRef(this);
		this.props.initialize({
			about_us: this.props.assocSettings.about_us,
			newsfeed_url: this.props.assocSettings.newsfeed_url,
			association_news_url: this.props.assocSettings.association_news_url,
			contact_association_email: this.props.assocSettings.contact_association_email,
			preferred_social_url: this.props.assocSettings.preferred_social_url,
			preferred_social_media_platform: social_media
																			 ? socialMediaOptions.find((item) => item['label'] === social_media)
																			 : socialMediaOptions.find((item) => item['label'] === "None"),
		})
	}


	render() {
		const {handleSubmit, pristine, submitting} = this.props;

		return (
            <Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
					<div className="row">
						<div className="col is-full">
							<label htmlFor="about_us">About Us Copy</label>
							<Field component={'textarea'} name="about_us" id="about_us" />
						</div>
					</div>

					<div className="row">
						<div className="col is-full">
							<label htmlFor="preferred_social_media_platform">Social Media Platform</label>
							<Field options={socialMediaOptions}
									component={SelectInput}
									onChange={(items) => this.setState({preferred_social_media_platform: items.label})}
									name="preferred_social_media_platform" id="preferred_social_media_platform"/>
						</div>
          </div>

					{(this.state.preferred_social_media_platform && ((this.state.preferred_social_media_platform != 'None')))
						&& <div className="row">
						<div className="col is-full">
							<label htmlFor="preferred_social_url">{this.state.preferred_social_media_platform} URL</label>
							<Field component={InputField} validate={socialMediaURL(this.state.preferred_social_media_platform.toLowerCase())} type="text" name="preferred_social_url" id="preferred_social_url" />
						</div>
					</div>}

					<div className="row">
						<div className="col is-full">
							<label htmlFor="newsfeed_url">News Feed</label>
							<Field component={InputField} type="text" name="newsfeed_url" id="newsfeed_url" />
						</div>
					</div>

					<div className="row">
						<div className="col is-full">
							<label htmlFor="association_news_url">Association News URL</label>
							<Field component={InputField} type="text" name="association_news_url" id="association_news_url" />
						</div>
					</div>

					<div className="row">
						<div className="col is-full">
							<label htmlFor="contact_association_email">Contact Email <span>*</span></label>
							<Field component={InputField} type="text" validate={[required,isEmailAddress]} name="contact_association_email" id="contact_association_email" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={() => this.props.close()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={pristine || submitting} className="btn fill blue">Save</button>
						</div>
					</div>
				</form>
            </Fragment>
		);
	}
}



EditAssocGeneralSettingsForm = reduxForm({
	form: 'editGolferProductsForm',
	destroyOnUnmount: true
})(EditAssocGeneralSettingsForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditAssocGeneralSettingsForm));
