import React, {Component, Fragment} from 'react';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {logoutUser} from "../Auth/actions";

class LogOutModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <h2 className="modal__title">Are you sure you want to log out?</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
                        <div className="row">
                            <div className="col">
                                <button type="button" onClick={() => {this.props.closeModal()}} className="btn fill gray">Cancel</button>
                            </div>
                            <div className="col">
                                <button type="submit" onClick={() => this.props.logoutUser()} className="btn fill blue">Continue</button>
                            </div>
                        </div>
                    </div>

                </div>

            </Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({logoutUser}, dispatch);
}

export default connect(null, mapDispatchToProps)(LogOutModal);
