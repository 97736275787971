import { Fragment, useCallback, useEffect, useState } from 'react';
import GhinTable from '../../../shared/GhinTable';
import ReactTooltip from "react-tooltip";
import TableFilter from './EmailsFilter';
import moment from 'moment';
import JoinAndRenewServices from '../../../../services/api/joinAndRenew';

import { addAlert } from "../../../shared/actions";
import { useDispatch } from 'react-redux';


export default function Emails(props) {

  const updateFilters = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const [sentEmailsTableData, setSentEmailsTableData] = useState([]);
  const [sentEmailsTableLoading, setSentEmailsTableLoading] = useState(false);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [pages, setPages] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    const length = Object.keys(filters).length;
    if (length !== 0)
      updateTable(filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [filters, page, perPage])

  const updateTable = (params) => {
    setSentEmailsTableLoading(true);
    params['page'] =  page + 1;
    params['per_page'] = perPage;

    JoinAndRenewServices.getSentEmails(props.associationId, params)
    .then(res => {
      setSentEmailsTableData(res.join_renew_sent_emails);
      setSentEmailsTableLoading(false);
      setTotal(parseInt(res["total-count"], 10));
      setPerPage(parseInt(res["per-page"], 10));
      setPages(parseInt(res["total-pages"], 10));
    })
    .catch(err => {
      console.log(err);
      setSentEmailsTableLoading(false);
    })
  }
  
  const resendEmail = (assoication_id, id) => {
    setSendEmailLoading(true);
    
    JoinAndRenewServices.resendEmail(assoication_id, id)
      .then(res => {
        dispatch(addAlert({
          type:'success',
          message:'The email has been successfully sent'
        }));
        setSendEmailLoading(false);
      })
      .catch(err => {
        console.log(err);
        setSendEmailLoading(false);
      })
  }

  const sentEmailsColumns = [
    {
      Header: 'GHIN Number',
      accessor: 'golfer_id',
      colClassName: 'col_ghin',
      Cell: props => props.value === -1 ? '-' : props.value
    },
    {
      Header: 'Email Addreess',
      accessor: 'email_address',
      colClassName: 'col_note'
    },
    {
      Header: 'Date/Time Sent',
      colClassName: 'col_date_edited',
      accessor: 'created_at',
      Cell: props => moment.utc(new Date(props.original.created_at), 'YYYY/MM/DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss')
    },
    {
      Header: 'Email Type',
      colClassName: 'col_date_edited',
      accessor: 'email_type'
    },
    {
      Header: 'Status',
      accessor: 'status',
      colClassName: 'col_method-small',
      Cell: props =>
        <div>
          {props.original.status === '-' ? 'requested' : props.original.status }
          {props.original.status !== 'Delivered' && props.original.status !== 'delivered' && props.original.error_message &&
          <>
            <a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for={`status-${props.original.id}`}><i className="material-icons v-mid m-left dark">info</i></a>
            <ReactTooltip id={`status-${props.original.id}`} className="table-head__tooltip" type="dark" place='top' effect={'float'}>
              {props.original.error_message}
            </ReactTooltip>
          </>}
        </div>
    },
    {
      Cell: data =>
      props.canEditAssociation && data.original.processed === false ?
        <button
          className="btn fill green"
          disabled={sendEmailLoading}
          onClick={() => {
            resendEmail(props.associationId, data.original.id);
          }}>
          Resend Email
        </button> : null
    }
  ];

  return (
    <Fragment>

      <div className='columns'>
        <div className='row'>
          <div className='col'>
            <div className='panel'>
              <div className='panel'>
                <div className='panel__head'>Sent Emails</div>
                <div className='panel__body'>
                  <div className='row'>
                    <div className='col'>
                      <TableFilter updateFilters={updateFilters} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col is-full'>
                      <GhinTable
                      idKey={'id'}
                      columns={sentEmailsColumns}
                      pages={pages}
                      page={page}
                      total={total}
                      pageSize={perPage}
                      manual
                      onPageChange={(index) => setPage(index)}
                      onPageSizeChange={(size, index) => {
                        setPerPage(size);
                        setPage(index);
                      }}
                      hideSelect={true}
                      sortable={true}
                      data = {sentEmailsTableData}
                      loading={sentEmailsTableLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}
