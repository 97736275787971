import {addValidator} from 'redux-form-validators';

export const checkDifference = addValidator({
	validator:(options, value, allValues) => {
		let current = allValues.low_hi_value;
		if(parseFloat(current)) {
			if (value) {
				let newValue = value.toString().startsWith('+') ? -1 * parseFloat(value) : parseFloat(value);
				if(newValue - current > 5) {
					return {
						defaultMessage:"This value is more than 5 strokes greater than the golfers Low Handicap Index. If this is ok, press “Save” to submit the modification."
					};
				}
			}
		}
	}
});
