import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import Modal from "react-modal";

class NotFound extends Component {
	render() {
		const email = (this.props.access.user_type === 'super-admin' || this.props.access.user_type === 'association-admin') ? 'customersupport@usga.org' : this.props.email
		return (
			<Fragment>
				<Modal
					isOpen={true}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay white"
					className="modal__content not-found"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={false}
					shouldFocusAfterRender={false}
				>
					<div className="modal__container not-found">
						<div className="modal__head white">
							<div className="modal__icon"><span className="exclamation">!</span></div>
						</div>
						<div className="modal__body center">
							<h2>Error Fore Oh Fore!</h2>
							<p>We are sorry! We have looked everywhere and can't find this page. It must be buried in the rough!</p>
							<br />
							<p>Please verify the URL or press the button below to go back and try again.</p>
							<div className="modal__button">
								<button
									type='submit'
									ref='submit'
									className="btn fill red"
									onClick={() => window.location.reload()}
								>
									Let’s try this again!
								</button>
							</div>
							<div className="modal__link">
								<a href={`mailto:${email}?subject=Error Fore Oh Fore&body=This page cannot be found: ${window.location.href}`}>CLICK HERE </a>
								to report this error to the
								{(this.props.access.user_type === 'super-admin' || this.props.access.user_type === 'association-admin') && <span> USGA/GHIN Support Team.</span>}
								{this.props.access.user_type === 'club-admin' && <span> {this.props.access.default_association_name}.</span>}
							</div>
						</div>
					</div>
				</Modal>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		access: state.user.access,
		email: state.club.email
	}
}

export default connect(mapStateToProps, null)(NotFound);
