import React, {Component, Fragment} from 'react';
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";
import GhinFilterSelect from "../../shared/GhinFilterSelect";
import TableService from "../../../services/tableService";


const yesNoOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];

export default class TableFilter extends Component {

    constructor(props) {

        super(props);

        this.state = {
            filters: {name: this.props.searchText,
              is_test: {value: false, label: "No"},
              is_trial: {value: false, label: "No"}}
        };

    }

    updateFilter = (name, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        }, () => {
            this.updateTable();
        })
    };

    updateTable = debounce(() => {
        let filteredArray = TableService.filtersToArray(this.state.filters);
        this.props.updateFiltered(filteredArray);
    }, 200);


    reset() {
        this.setState({filters: {name: "", is_aga: "", authorized: null, is_test: null, is_trial: null, is_dac: null, technology_provider: null}}, () => {
            this.updateTable();
        });
    }

    render() {
        return (
            <Fragment>
                <div className="filters columns">
                    <div className="row">
						<div className='filters__inputs col'>
							<div className={'row'}>
								<div className="col is-1-of-7">
									<label htmlFor="golfer-name">Association Name</label>
									<input type="text" autoComplete="off" value={this.state.filters.name} name="association-name" id="association-name" onChange={(e) => {
										this.updateFilter('name', e.target.value)
									}}/>
								</div>
								<div className="col is-1-of-7">
									<GhinFilterSelect
										options={[
											'Active',
											'Inactive',
											'Archived'
										]}
										value={this.state.filters.status}
										onChange={values => this.updateFilter('status', values)}
										label={'Status'}
									/>
								</div>
								<div className="col is-1-of-7">
									<GhinFilterSelect
										options={yesNoOptions}
										singleChoice={true}
										value={this.state.filters.is_aga}
										onChange={values => this.updateFilter('is_aga', values)}
										label={'Is AGA ?'}
									/>
								</div>
								<div className="col is-1-of-7">
									<GhinFilterSelect
										options={[
											'GHIN',
											'BlueGolf',
											'GolfNet',
											'AdminEscape'
										]}
										value={this.state.filters.FrontEndProvider}
										onChange={values => this.updateFilter('FrontEndProvider', values)}
										label={'Front End Provider'}
									/>
								</div>
								<div className="col is-1-of-7">
									<GhinFilterSelect
										options={yesNoOptions}
										singleChoice={true}
										value={this.state.filters.is_test}
										onChange={values => this.updateFilter('is_test', values)}
										label={'Is Test Association ?'}
									/>
								</div>
								<div className="col is-1-of-7">
									<GhinFilterSelect
										options={yesNoOptions}
										singleChoice={true}
										value={this.state.filters.is_trial}
										onChange={values => this.updateFilter('is_trial', values)}
										label={'Is Trial Association ?'}
									/>
								</div>
							</div>
						</div>
						<div className="filters__controls col is-1-of-7 jc-fe">
                            <button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
                        </div>
                    </div>

                </div>

            </Fragment>
        );
    }
}
