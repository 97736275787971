import React, {Component, Fragment} from 'react';
import Select from "react-select";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
const dataTokens = [
	'Golfer First Name',
	'Golfer Last Name',
	'Golfer Full Name',
	'Association Name',
	'Handicap Index',
	'GHIN Number',
	'Association Address 1',
	'Association Address 2',
	'Association City',
	'Association Postal Code',
	'Association Email Address',
	'Association Phone',
	'Association State',
	'Association Country',
	'Low Handicap Index',
	'Revision Date',
	'Home Club'
];

class EditLogoInformationForm extends Component {

  constructor(props) {
      super(props);
      this.state = {
          image: this.props.image,
		      alt_text: this.props.alt_text,
					mobile_image1_ad_tag: this.props.mobile_image1_ad_tag,
					mobile_image2_ad_tag: this.props.mobile_image2_ad_tag,
          email_settings: this.props.email_settings,
    			alignment: [
            {value: 'left', label: 'Align Image Left + Header Text Right'},
            {value: 'right', label: 'Align Image Right + Header Text Left'},
    				{value: 'center', label: 'Align Image Center (No Header Text)'}
    			],
  				headerInput: {
  						selectionStart: 0,
  						selectionEnd: 0
  				},
          tokens: false
      }
  }

  getImageAlignment()
  {
    return this.state.alignment.filter(r => r.value === this.state.email_settings.image_alignment)[0];
  }

  selectToken(tokenRaw, e) {
		const token = `[${tokenRaw}]`;
		const start =  this.headerRef.selectionStart;
		const end =  this.headerRef.selectionEnd;
		const header_text = `${this.state.email_settings.header_text.substring(0, start)}${token}${this.state.email_settings.header_text.substring(end)}`;

		this.setState({
				email_settings: {
						...this.state.email_settings,
						header_text
				}
		}, () => {
				this.headerRef.focus();
				this.headerRef.setSelectionRange(start + token.length, start + token.length)
		});
	}

	render() {
    if (!this.props.image) {
        return null;
    }

		return (
        <Fragment>

            <div className="row">
              <div className="col is-full">
                <div className="form__image-preview">
                  <img src={this.state.image} alt="" name=""/>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col is-full">
                <label>Image Url</label>
                <span className="image-preview__url">{this.state.image}</span>
              </div>
            </div>

            <div className="row">
              <div className="col is-full">
                <label htmlFor="image_alt_text">Alt Text</label>
                <input type="text"
                  value={this.state.alt_text}
                  onChange={(e) => this.setState({alt_text: e.target.value})}
                  autoComplete="off"
                  name="image_alt_text"
                  id="image_alt_text" />
                </div>
            </div>

            <div className="row">
							<div className="col is-full">
								<label htmlFor="image_alignment">Image Alignment</label>
								<Select
                  value={this.getImageAlignment()}
								  className="react-select-container"
									classNamePrefix="react-select"
									options={this.state.alignment}
                  onChange={(e) => this.setState({email_settings: {...this.state.email_settings, image_alignment: e.value}})}
                  name="image_alignment"
                  id="image_alignment"
								/>
							</div>
						</div>

						{this.state.email_settings.image_alignment !== "center" && <Fragment>
							<div className="col is-full">
								<label htmlFors="header_text">Header Text</label>
							</div>
							<div className="row">
								<div className="col auto jc-c">
			  					<button type={'button'} onClick={() => this.setState({tokens: !this.state.tokens})} className="btn lnk">Tokens<span className={`tokens-toggle ${this.state.tokens ? 'opened' : 'closed'}`}/> </button>
								</div>
							</div>
	  					{this.state.tokens && <div className="row">
	  						<div className="col is-full">
	  							<div className="data-tokens">
	  								{dataTokens.map((token) => {
	  									return <button type={'button'} key={token} onClick={(e) => this.selectToken(token, e)} className={'button token'}>{token}</button>
	  								})}
	  							</div>
	  						</div>
	  					</div>}
							<div className="row">
								<div className="col is-full">
	  							<input
									   type="text"
										 ref={(ref) => this.headerRef = ref}
										 value={this.state.email_settings.header_text}
										 onChange={(e) => this.setState({
											 email_settings:{
	                       ...this.state.email_settings,
												 header_text: e.target.value }
										 })}
										 onKeyUp={(e) => this.setState({
											 headerInput: {
												 selectionStart: e.target.selectionStart,
												 selectionEnd: e.target.selectionEnd }
										 })}
										 onFocus={(e) => this.setState({
											 headerInput: {
												 selectionStart: e.target.selectionStart,
												 selectionEnd: e.target.selectionEnd }
										 })}
									   name={'header_text'}
										 id={'header_text'} />
  							</div>
  						</div>
	            <div className="row">
	  						<div className="col is-full">
									<label>Please choose header text color option below:</label>
	  							<div className="radio__container">
	  								<input
	  									onChange={ () => { this.setState({email_settings: {...this.state.email_settings, use_app_primary_color_for_header_text: true}}) }}
	  									checked={this.state.email_settings.use_app_primary_color_for_header_text}
	  									id="use_primary_color"
	  									component={'input'}
	  									className="simple__radio"
	  									type="radio"
	  								/>
	                <label htmlFor="use_primary_color">Use Mobile App Primary Color</label>
	  								<input
	  									onChange={ () => { this.setState({email_settings: {...this.state.email_settings, use_app_primary_color_for_header_text: false}}) }}
	  									checked={!this.state.email_settings.use_app_primary_color_for_header_text}
	  									id="use_custom_color"
	  									component={'input'}
	  									className="simple__radio"
	  									type="radio"
	  								/>
	                <label htmlFor="use_custom_color">Use Custom Color</label>
									{!this.state.email_settings.use_app_primary_color_for_header_text &&
		              <div style={{display: 'flex', alignItems: 'center'}}>
		                <input type="color"
		                  value={`#${this.state.email_settings.header_text_color}`}
		                  onChange={(e) => this.setState({email_settings: {...this.state.email_settings, header_text_color: e.target.value.substring(1).toUpperCase()}})}
											name="color_picker"
		                  id="color_picker" />
		               </div>}
	  							</div>
	  						</div>
	  					</div>


						</Fragment>}

            <div className="row">
              <div className="col is-1-of-2">
                <button className="btn fill gray" type={'button'} onClick={() => this.props.closeModal()}>Cancel</button>
              </div>
              <div className="col is-1-of-2">
                <button className="btn fill blue" disabled={this.state.urlError} type={'submit'} ref={'submit'} onClick={() => this.props.closeModal({alt_text: this.state.alt_text, email_settings: this.state.email_settings})}>Save</button>
              </div>
            </div>

        </Fragment>
		);
	}
}

export default withKeyPress(EditLogoInformationForm);
