import { Fragment } from "react";
import EditTransactionFeeForm from "../Forms/EditTransactionFeeForm";
import { UpdateTransactionFee } from "../Manage/Association/JoinAndRenew/UpdateTransactionFee";

export default function EditTransactionFeeModal(props) {
  return (
    <Fragment>
      <div className="modal__container narrow">
        <div className="modal__head">
        {
            props.operationType === "Edit" && <Fragment>
              <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
              <h2 className="modal__title">Edit Transaction Fee</h2>
            </Fragment>
          }

          {
            props.operationType === "Create" && <Fragment>
              <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
              <h2 className="modal__title">Add Transaction Fee</h2>
            </Fragment>
          }

          <button className="modal__close" onClick={() => { props.closeModal() }}><i className="material-icons-outlined">clear</i></button>
        </div>
        <div className="modal__body">
          {!props.joinPagesModalIsOpen && <EditTransactionFeeForm
            {...props}
          />}
          {props.joinPagesModalIsOpen && <UpdateTransactionFee
            handleDownload={props.handleDownload}
            affectedJoinPages={props.affectedJoinPages}
            loadingAffectedJoinPages={props.loadingAffectedJoinPages}
            setJoinPagesModalIsOpen={props.setJoinPagesModalIsOpen}
            updateTransaction={props.updateTransaction}
            reloadTransactionFees={props.reload_transaction_fees}
            closeModal={props.closeModal}/>}
        </div>
      </div>
    </Fragment>
  )
}
