import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import JoinAndRenewServices from "../../../../services/api/joinAndRenew";
import EditAddonChargeModal from "../../../Modals/EditAddonChargeModal";
import GhinTable from "../../../shared/GhinTable";
import { addAlert } from "../../../shared/actions";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";
import AddonDetailsModal from "../../../Modals/AddonDetailsModal";

function AddOnCharges(props) {
  const [addons, set_addons] = useState([])
  const [addonAssigned, set_addonAssigned] = useState(false)
  const [addAddonModalIsOpen, set_addAddonModalIsOpen] = useState(false)
  const [selectedAddon, set_selectedAddon] = useState()
  const [operationType, set_operationType] = useState()
  const [loading, set_loading] = useState(false)
  const [deleteModalIsOpen, set_deleteModalIsOpen] = useState(false)
  const [addonDetailsModalIsOpen, setAddonDetailsModalIsOpen,] = useState(false);

  useEffect(() => {
    set_loading(true)
    JoinAndRenewServices.getAddonCharges(props.associationId).then((res) => {
      set_addons(res.addon_charges)
      set_addonAssigned(res.one_membership_assigned)
      set_loading(false)
    })
  }, [props.associationId])

  const reload_addons = () => {
    set_loading(true)
    JoinAndRenewServices.getAddonCharges(props.associationId).then((res) => {
      set_addons(res.addon_charges)
      set_addonAssigned(res.one_membership_assigned)
      set_loading(false)
    })
  }

  const toggle_addon_enabled = (data) => {
    const params = {
      is_enabled: !data.original.is_enabled
    }
    JoinAndRenewServices.updateAddonCharge(props.associationId, data.original.id, params).then(() => {
      reload_addons();
      props.addAlert({ type: 'success', message: `Add-On successfully ${!data.original.is_enabled ? "enabled" : "disabled"}` })
    }).catch((e) => {
      if (e.data.errors) {
        const data = e.data.errors
        props.addAlert({ type: "error", message: data[Object.keys(data)[0]][0] })
      }
    })
  }

  const columns = [
    {
      Header: "Add-On Name",
      accessor: "name"
    },
    {
      Header: "Add-On Description",
      accessor: "description"
    },
    {
      colClassName: "col_addons_status",
      Header: "Status",
      className: 'table-align-center',
      thClassName: 'table-align-center',
      accessor: "is_enabled",
      Cell: data => {
        return <Fragment>
		  <div>
		    <input type="checkbox"
			  defaultChecked={data.value}
			  disabled={!props.canEditAssociation}
		    />
		    <label
			  style={{ margin: 'auto', height: '22px' }}
			  onClick={(e) => {
			    toggle_addon_enabled(data)
			    }}>
		  	</label>
		  </div>
        </Fragment>
      }
    },
    {
      colClassName: 'col_addons_enabled',
      accessor: "is_enabled",
      className: 'table-align-center',
      thClassName: 'table-align-center',
      Cell: data => {
        return <div>{data.value ? "Enabled" : "Disabled"}</div>
      }
    },
    {
      colClassName: 'col_change_code',
      Header: "Fee",
      accessor: "amount",
      Cell: data => {
        return data.original.is_multiple_options ? 'Multiple Fee Options'
          :
          `$${data.value}`
      }
    },
    {
      Header: "Optional",
      accessor: "is_optional",
      colClassName: "col_gender",
      Cell: data => {
        return <div>{data.value ? "Yes" : "No"}</div>
      }
    },
    {
      Header: "Donation",
      accessor: "is_donation",
      colClassName: "col_gender",
      Cell: data => {
        return <div>{data.value ? "Yes" : "No"}</div>
      }
    },
    {
      className: 'table-align-center',
      thClassName: 'table-align-center',
      Cell: data => {
        if (!props.canEditAssociation)
          return <div></div>
        if (data.original.is_enabled) {
          return <div>
            <button className="btn lnk" onClick={() => {
              set_selectedAddon(data.original)
              setAddonDetailsModalIsOpen(true)
            }}>Details</button>
            <button className="btn lnk" style={{ marginLeft: "20px" }} onClick={() => {
              set_operationType("Edit")
              set_selectedAddon(data.original)
              set_addAddonModalIsOpen(true)
            }}>Edit</button>
          </div>
        }
        else {
          return <div>
            <button className="btn lnk" onClick={() => {
              set_selectedAddon(data.original)
              setAddonDetailsModalIsOpen(true)
            }}>Details</button>
            <button className="btn lnk" style={{ marginLeft: "20px" }} onClick={() => {
              set_operationType("Edit")
              set_selectedAddon(data.original)
              set_addAddonModalIsOpen(true)
            }}>Edit</button>
            <button className="btn lnk" style={{ marginLeft: "20px" }} onClick={() => {
              set_selectedAddon(data.original)
              set_deleteModalIsOpen(true)
            }}>Delete</button>
          </div>
        }
      }
    },
  ]

  return (
    <Fragment>
      <div className="columns">
        <div className="row">
          <div className="panel">
            <div className="panel__head">
              Add-On
            </div>
            <div className="panel__body">
              {addonAssigned ? (
                <>
                  <div className="row">
                    <div className="col">
                      <span>Create a new add-on to include on your Join Page. </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="red-note"><strong>Note:</strong> An Add-On cannot be deleted if it has been assigned to a join page.  To delete, you must first remove it from all pages it has been assigned to. To do this, press the “Manage Join Pages” button to the left and remove it from the applicable join pages. Once that is done the Add-On can be deleted from this page.</span>
                    </div>
                  </div>
                  {props.canEditAssociation && 
                  <div className="row">
                    <div className="col auto push-right">
                      <button className="btn fill green"
                              onClick={() => {
                                set_operationType("Create")
                                set_selectedAddon(null)
                                set_addAddonModalIsOpen(true)
                              }}
                      >Create Add-On
                      </button>
                    </div>
                  </div>}
                </>)
                : (
                  <>
                    <div className="row">
                      <div className="col">
                        <span>Create a new add-on to include on your Join Page. </span>
                      </div>
                      {props.canEditAssociation && <div className="col auto push-right">
                        <button className="btn fill green"
                                onClick={() => {
                                  set_operationType("Create")
                                  set_selectedAddon(null)
                                  set_addAddonModalIsOpen(true)
                                }}
                        >Create Add-On
                        </button>
                      </div>}
                    </div>
                  </>
                )}
              <div className="row">
                <div className="col jc-fs">
                  <GhinTable
                    loading={loading}
                    idKey={'addons'}
                    hideSelect
                    hidePagination={true}
                    data={addons}
                    sortable={false}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
      <Modal
        isOpen={addAddonModalIsOpen}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <EditAddonChargeModal
          selected_addon={selectedAddon}
          reload_addons={reload_addons}
          close_modal={() => { set_addAddonModalIsOpen(false) }}
          operationType={operationType}
          association_id={props.associationId}
        />
      </Modal>
      {deleteModalIsOpen && <ConfirmationModalTypeB openModal={true}
        wideClass={"narrow"}
        onConfirmAction={() => {
          JoinAndRenewServices.deleteAddonCharge(props.associationId, selectedAddon.id).then(() => {
            props.addAlert({ type: "success", message: "Add-On successfully deleted." })
            reload_addons()
          }).catch((e) => {
            if (e.data.errors) {
              const data = e.data.errors
              props.addAlert({ type: "error", message: data[Object.keys(data)[0]][0] })
            }
            reload_addons()
          }).finally(() => {
            set_deleteModalIsOpen(false)
          })
        }}
        modalIcon={"help_outline"}
        onCancelAction={() => {
          set_deleteModalIsOpen(false)
        }}
        closeModal={() => {
          set_deleteModalIsOpen(false)
        }}
        infoText={[`Are you sure you want to delete the ${selectedAddon.name} add-on charge?`]}
        cancelLabel={"No"}
        confirmLabel={"Yes"} />
      }
      <Modal
        isOpen={addonDetailsModalIsOpen}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <AddonDetailsModal
          closeModal={() => {setAddonDetailsModalIsOpen(false)}}
          selectedAddon={selectedAddon}
        />
      </Modal>
    </Fragment>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
} 

export default connect(null, mapDispatchToProps)(AddOnCharges);
