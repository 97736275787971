import React, { Component, Fragment } from 'react';
import PreviewEmailModal from '../../../Modals/PreviewEmailModal';
import { addAlert } from "../../../shared/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PreviewAndTest from '../Account/ERevision/PreviewAndTest';

class NewsletterPreviewAndTest extends Component {

  constructor(props) {

    super(props);

    this.state = {
      previewEmailModalIsOpen: false,
      previewGolfer: null,
    };

    this.openEmailPreviewModal = this.openEmailPreviewModal.bind(this);

  }

  openEmailPreviewModal() {
    this.setState({ previewEmailModalIsOpen: true });
  }

  closeModal() {
    this.setState({
      previewEmailModalIsOpen: false,
    });
  }

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <PreviewAndTest
              ghinPreviewGolfer={(e) => { this.setState({ previewGolfer: e.target.value }) }}
              associationId={this.props.associationId}
              addAlert={(data) => this.props.addAlert(data)}
              openEmailPreviewModal={this.openEmailPreviewModal}
            />
          </div>
        </div>
        {this.state.previewEmailModalIsOpen &&
          <PreviewEmailModal
            golferId={this.state.previewGolfer}
            associationId={this.props.associationId}
            closeModal={() => { this.closeModal() }}
          />}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ addAlert }, dispatch);
  return {
    dispatch,
    ...actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterPreviewAndTest);
