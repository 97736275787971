import React, { useState, useEffect } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { SelectInput } from "../../../../inputs/Select";
import { PickySelect } from "../../../../inputs/PickySelect";
import frontendProviders from "../../../shared/frontendProvider";
import { requiredMultiSelect, required, requiredSelect } from "../../../../inputs/validations";
import normalizeAssociationsDropdown from "./normalizeAssociationsDropdown";
import { statusOptions, configAssociationsOptions, configTemplatesOptions } from "./utils";
import { FederationAccount } from "../../../../services/api/federationAccount";
import Spinner from "./Spinner";

/**
 * 
 * @param {
 *  tempaltesOptions: an array of { label: bla, value: bla } representing templateName options. If NULL means the requet did not arrive yet.
 *  templatesError: a boolean. Yes if the GET templates request failed because of Sendgrid
 * } props 
 * @returns The Form that has to be placed inside the Settings box
 */
let SendForm = props => {
  const selectAll = {"value": "All", "label": "Select All"};
  const allfrontendProviders = [selectAll, ...frontendProviders];
  const sendgridAccountOptions = [{"value": "USGA Team", "label": "USGA Newsletters"}, {"value": "Handicap Team", "label": "USGA Handicap Newsletters"}];
  
  
  let [accountName, setAccountName] = useState('USGA Team');
  let [templates, setTemplates] = useState(null);
  let [templatesError, setTemplatesError] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  

  let templatesOptions = configTemplatesOptions(templates);
  let hasTemplates = isLoading || (templatesOptions && templatesOptions.length > 0);

  let [usedGps, setUsedGps] = useState(false);
  let [activeGpsSubscription, setActiveGpsSubscription] = useState(false);
  let [inactiveGpsSubscription, setInactiveGpsSubscription] = useState(false);
  let [noGpsSubscription, setNoGpsSubscription] = useState(false);
  // association values 
  let [associationsOptions, setAssociationsOptions] = useState(props.defaultAssociationsOptions);
  
  // needed for the first load
  useEffect(() => {
    setAssociationsOptions(props.defaultAssociationsOptions);
	}, [props.associations])
  
  // load templates when sendgrid acount changes
  useEffect(() => {
    props.dispatch(change('federationNewsletterSend', 'templateName', null));
    loadTemplates(accountName);
	}, [accountName])
  
  const loadTemplates = (sendgridAccountName) => {
    setIsLoading(true);
    FederationAccount.getNewsletterTemplates({sendgrid_account: sendgridAccountName})
    .then(res => {
      if (res.Templates) {
        if (Array.isArray(res.Templates.Template))
          setTemplates(res.Templates.Template);
        else
          setTemplates([res.Templates.Template]);
      }

      // The backend sends an empty string instead of an empty list
      if (res.Templates === "") {
        setTemplates([]);
      }

      if (res.Errors) {
        setTemplatesError(true);
      }
      setIsLoading(false);
    })
    .catch(err => {
      console.log(err);
      setIsLoading(false);
    });
  }
  
  let canSend = () => {
    return !props.valid || !hasTemplates || templatesError || isLoading || props.submitting;
  }

  const frontendProviderOnChange = (inputValue) => {
    const options = configAssociationsOptions(props.associations, inputValue.value);
    setAssociationsOptions(options);
    props.dispatch(change('federationNewsletterSend', 'associations', [options[0]]));
  }
  
  return (
    <form onSubmit={props.handleSubmit(props.onSubmit)}>
      <div className="row">
        <div className="col is-1-of-4">
          <label htmlFor="sendgridAccount">SendGrid Account <span>*</span></label>
          <Field
            name={'sendgridAccount'}
            component={SelectInput}
            className="react-select-container"
            classNamePrefix="react-select"
            options={ sendgridAccountOptions }
            isSearchable={false}
            isMulti={false}
            onChange={ (value) => setAccountName(value.value)}
          />
        </div>
        <div className="col is-1-of-4">
          <label htmlFor="templateName">Template Name <span>*</span></label>
          <Field
            name={'templateName'}
            component={SelectInput}
            className="react-select-container"
            classNamePrefix="react-select"
            options={ templatesOptions }
            isSearchable={false}
            isMulti={false}
            disabled = { templatesError }
          />
        </div>
        {/* The informative message, next to selecting association. */}
        {isLoading && <div className="col">
          <Spinner className="lds-ring" />
        </div>}
        <div className="col jc-fe">
          { !hasTemplates && !templatesError &&
            <div style={{marginBottom: "1rem"}}>
              <a href="https://sendgrid.com" style={{color: '#00365f'}} target= "_blank" rel="noreferrer">Click here to create SendGrid templates!</a>
              </div>
          }
          { templatesError &&
            <div style={{marginBottom: "0.5rem"}}>
             <p className={"validation__message is-error"}>Something went wrong because of SendGrid, please refresh the page!</p>
            </div>
          }
        </div>
      </div>

      <div className="row">
        <div className="col is-3-of-12">
          <label htmlFor="frontendProvider">Front End Provider <span>*</span></label>
          <Field      
            name={'frontendProvider'}
            component={SelectInput}
            className="react-select-container"
            classNamePrefix="react-select"
            options={allfrontendProviders}
            isMulti={false}
            validate={[required, requiredSelect]} 
            onChange={frontendProviderOnChange}
          />
        </div>
        <div className="col is-4-of-12">
          <label htmlFor="associations">Association(s) <span>*</span></label>
          <Field
            id="associations"
            name={'associations'}
            component={PickySelect}
            options={associationsOptions}
            multiple={true}
            validate={[requiredMultiSelect]}
            numberDisplayed={false}
            normalize={normalizeAssociationsDropdown}
            displayLabel={false}
          />
        </div>

        <div className="col is-3-of-12">
          <label htmlFor="golferStatus">Golfer Status <span>*</span></label>
          <Field
            name={'golferStatus'}
            component={SelectInput}
            className="react-select-container"
            classNamePrefix="react-select"
            options={statusOptions}
            isMulti={false}
          />
        </div>
      </div>
      
      <div className="row">
        <div className="col">
          <label htmlFor="usedGpsFreeTrials">Includes golfers that have:</label>
          <Field
            onClick={ () => setUsedGps(!usedGps) }
            id="usedGpsFreeTrials"
            value={usedGps}
            checked={usedGps}
            component="input"
            name="usedGpsFreeTrials"
            type="checkbox"
          />
          <label htmlFor="usedGpsFreeTrials" style={{color: "black"}}>Used Free GPS Trial(s)</label>
        </div>

        <div className="col">
          <label htmlFor="activeGpsSubscription" style={{visibility: "hidden"}}>Hidden</label>
          <Field
            onClick={ () => setActiveGpsSubscription(!activeGpsSubscription) }
            id="activeGpsSubscription"
            value={activeGpsSubscription}
            checked={activeGpsSubscription}
            component="input"
            name="activeGpsSubscription"
            type="checkbox"
          />
        <label htmlFor="activeGpsSubscription" style={{color: "black"}}>Active GPS Subscription</label>
        </div>
        <div className="col">
          <label htmlFor="inactiveGpsSubscription" style={{visibility: "hidden"}}>Hidden</label>
          <Field
            onClick={ () => setInactiveGpsSubscription(!inactiveGpsSubscription) }
            id="inactiveGpsSubscription"
            value={inactiveGpsSubscription}
            checked={inactiveGpsSubscription}
            component="input"
            name="inactiveGpsSubscription"
            type="checkbox"
          />
        <label htmlFor="inactiveGpsSubscription" style={{color: "black"}}>Inactive GPS Subscription</label>
        </div>
        <div className="col">
          <label htmlFor="noGpsSubscription" style={{visibility: "hidden"}}>Hidden</label>
          <Field
            onClick={ () => setNoGpsSubscription(!noGpsSubscription) }
            id="noGpsSubscription"
            value={noGpsSubscription}
            checked={noGpsSubscription}
            component="input"
            name="noGpsSubscription"
            type="checkbox"
          />
        <label htmlFor="noGpsSubscription" style={{color: "black"}}>No GPS Subscription</label>
        </div>

        <div className="col is-1-of-7">
          <label htmlFor="sendButton" style={{visibility: "hidden"}}>Hidden</label>
          <button className="btn fill green" name="sendButton" type="submit" disabled={canSend()}>Send Email Now</button>
        </div>
      </div>
    </form>
  )
}

SendForm = reduxForm({
  // a unique name for the form
  form: 'federationNewsletterSend',
  destroyOnUnmount: true
})(SendForm)

export default SendForm
