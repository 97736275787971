import React, {Component, Fragment} from 'react';

import GolferAddressForm from '../Forms/GolferAddressForm';

export default class GolferAddressModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">{this.props.actionType}</i></div>
                        <h2 className="modal__title">{this.props.actionType.charAt(0).toUpperCase() + this.props.actionType.slice(1)} {this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)} Golfer Address Information</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <GolferAddressForm  type={this.props.type} actionType={this.props.actionType} golfer={this.props.golfer} close={(data)=>this.props.closeModal(data)} />

                    </div>

                </div>

            </Fragment>
		);
	}
}
