import { Fragment, useState} from 'react';
import EditAssociationAlertForm from '../Forms/EditAssociationAlertForm';

export default function EditAssociationAlertModal(props) {

  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const title = "Admin Portal Alert";

  return (
    <Fragment>

      <div className="modal__container narrow">

        <div className="modal__head">
            { isConfirmationModal ?
              <Fragment>
                <div className="modal__icon"><i className="material-icons-outlined">warning</i></div>
                <h2 className="modal__title">Are You Sure?</h2>
              </Fragment>
              :
              <Fragment>
                <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                <h2 className="modal__title">Edit {title}</h2>
                <button className="modal__close" onClick={() => {props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
              </Fragment>
            }
        </div>

        <div className="modal__body">

            <EditAssociationAlertForm
              assocId={props.assocId}
              data={props.data}
              close={(data) => props.closeModal(data)}
              confirmModal={() => setIsConfirmationModal(true)}
            />

        </div>

      </div>

    </Fragment>
  )

}