import request from "../shared/request"

export default class PaymentGatewayServices {
  static getSettings(id) {
    return request({
      url: `/associations/${id}/payment_gateway_settings.json`,
      method: "GET"
    })
  }

  static updateSettings(id, params) {
    return request({
      url: `/associations/${id}/payment_gateway_settings.json`,
      method: "PATCH",
      data: params
    })
  }

  static deleteSetting(id) {
    return request({
      url: `/associations/${id}/payment_gateway_settings.json`,
      method: "DELETE"
    })
  }
}