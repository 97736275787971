import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import EditSurveySetupModal from '../../../Modals/EditSurveySetupModal';
import AddAssociationModal from '../../../Modals/AddAssociationModal';
import EditAssociationModal from '../../../Modals/EditAssociationModal';
import {FederationAccount} from "../../../../services/api/federationAccount";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import {addAlert} from "../../../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {parseISO} from 'date-fns';
import moment from "moment";

class SurveySetup extends Component {

	constructor() {
		super();
		this.state = {
			editSurveyModalIsOpen: false,
			editAssociationModalIsOpen: false,
			addAssociationModalIsOpen: false,
			confirmModalIsOpen: false,
			survey: null,
			deleteSurveyAssociation: null,
			editSurveyAssociation:null,
		};
		this.openAddAssociationModal = this.openAddAssociationModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.deleteSurveyAssociation = this.deleteSurveyAssociation.bind(this);
	}

	componentDidMount(){
		this.getSurvey();
	}

	openAddAssociationModal() {
	this.setState({addAssociationModalIsOpen: true});
	}

	deleteSurveyAssociation() {
		FederationAccount.deleteSurveyAssociation(this.state.deleteSurveyAssociation)
			.then(res => {
				this.props.addAlert({
					type:'success',
					message:'Association successfully deleted'
				});
			this.setState({confirmModalIsOpen: false, deleteSurveyAssociation: null}, () => this.getSurvey())
		})
		.catch(err => {console.error(err)});
	}

	closeEditSurveyModal(updatedSurvey) {
		updatedSurvey = updatedSurvey != null ? updatedSurvey : this.state.survey;
		updatedSurvey.start_date = moment(updatedSurvey.start_date).format("YYYY-MM-DD");
		updatedSurvey.end_date =  moment(updatedSurvey.end_date).format("YYYY-MM-DD");
		this.setState({
			editSurveyModalIsOpen: false,
			survey: updatedSurvey
		});
	}

	closeModal() {
		this.getSurvey();
		this.exitModal();
    }

	exitModal() {
		this.setState({
				addAssociationModalIsOpen: false,
				editAssociationModalIsOpen: false,
				editSurveyModalIsOpen: false
				});
			this.getSurvey();
	}

	getSurvey() {
		FederationAccount.getSurvey().then(data => {
			this.setState({survey: data.survey});
		});
	}

	getFacilityName(facilities) {
		if (facilities) {
			return facilities.map(ga => ga[1]).join(", ");
		}
	}

	showAssociations(associations)
	{
		if (associations) {
			return associations.map(ga => ga.name).join(", ");
		}
	}

	render() {
		return (
			<Fragment>
				<div className="columns">
					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">Survey Setup</div>
								<div className="panel__body">
									<div className="row">
										<div className="col auto push-right">
											<button onClick={() => this.setState({editSurveyModalIsOpen: true})} className="btn lnk">Edit</button>
										</div>
									</div>
									{this.state.survey != null ? <div className="row">
										<div className="col jc-fs">
											<div className="data-set__container">
												<div className="data-set">
													<span className="data-label">Is Enabled?:</span>
													<span>{this.state.survey.enabled ? 'Yes' : 'No'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Status:</span>
													<span>{this.state.survey.active ? 'Active' : 'Inactive'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Question:</span>
													<span>{this.state.survey.question}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Start Date:</span>
													<span>{moment(new Date(parseISO(this.state.survey.start_date))).format("MM/DD/YYYY")}</span>
												</div>
												<div className="data-set">
													<span className="data-label">End Date:</span>
													<span>{moment(new Date(parseISO(this.state.survey.end_date))).format("MM/DD/YYYY")}</span>
												</div>

												{this.state.survey.golf_associations && this.state.survey.golf_associations.map((golf_association, key)=>{
														return (
															<Fragment key={key}>
															<div className="data-set"></div>
																	<div className="columns">
																			<div className="row">
																					<div className="col">
																							<div className="panel">
																									<div className="panel__head">{golf_association.name}
																											<div className="panel__end">
																												<button	className="btn lnk" style={{marginRight: "30px"}}
																													onClick={() => this.setState({editSurveyAssociation: golf_association, editAssociationModalIsOpen: true})}>Edit
																												</button>
																												<button className="btn lnk red"
																													onClick={() => this.setState({confirmModalIsOpen: true, deleteSurveyAssociation: golf_association.survey_association_id})}>Delete
																												</button>
																											</div>
																									</div>
																									<div className="panel__body">
																										<h6 className="upload__label">	Facilities	</h6>
																											{(golf_association.all_facilities === true) ?
																												<div>
																													<h5 className="upload__info"> All facilities</h5>
																													{golf_association.excluded_facilities.length !== 0 && <h6 className="upload__label">	Excluded Facilities	</h6>}
																													<h5 className="upload__info"> {this.getFacilityName(golf_association.excluded_facilities)} </h5>
																												</div>
																											:
																												<div>
																												<h5 className="upload__info"> {this.getFacilityName(golf_association.facilities)}</h5>
																											 </div>
																										 }
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
															</Fragment>
														)})}
											</div>
										</div>
									</div> : <div></div>}
									<div className="col is-1-of-7 push-right">
											<button onClick={() => this.openAddAssociationModal()} className="btn fill green ">Add Association</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					isOpen={this.state.addAssociationModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={false}
					shouldFocusAfterRender={false}
					>
					<AddAssociationModal survey={this.state.survey} closeModal={()=>{this.closeModal()}} />
				</Modal>

				<Modal
					isOpen={this.state.editSurveyModalIsOpen}
					onRequestClose={()=>{this.closeEditSurveyModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={false}
					shouldFocusAfterRender={false}
					>
					<EditSurveySetupModal survey={this.state.survey} exitModal={()=>{this.exitModal()}} closeModal={(updatedSurvey)=>{this.closeEditSurveyModal(updatedSurvey)}} />
				</Modal>

				<Modal
					isOpen={this.state.editAssociationModalIsOpen}
					onRequestClose={()=>{this.closeEditAssociationModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={false}
					shouldFocusAfterRender={false}
					>
					<EditAssociationModal  assoc={this.state.editSurveyAssociation} closeModal={()=>{this.closeModal()}} />
				</Modal>

				<ConfirmationModal
					question={`Are you sure you want to delete this entry?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({confirmModalIsOpen: false})}
					onConfirmAction={() => this.deleteSurveyAssociation()}
					openModal={this.state.confirmModalIsOpen}
					closeModal={() => this.setState({confirmModalIsOpen: false})}
				/>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		federation: state.federation
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveySetup);
