import { useState } from "react";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { addAlert } from "../shared/actions";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { required, maxLength } from "../../inputs/validations";
import { Textarea } from "../../inputs/Textarea";

function RejectGolfer(props) {

  const [reason, setReason] = useState('');

  const maxReasonLength = 500;

  const onSubmit = (values, _, props) => {
    return props.onConfirm(values);
  }

  const { handleSubmit, pristine, submitting, invalid } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col">
          <p>Why is {props.selectedGolfer.full_name} being rejected?</p>
        </div>
      </div>
      <div className="row">
        <div className="col is-1-of-2">
          <label>Reason <span>*</span></label>
        </div>
        <div className="col is-2-of-2" style={{ textAlign: "right" }}>
          <label className={ reason.length > maxReasonLength ? 'length-error' : 'blue' } htmlFor="note">{reason.length || 0}  / {maxReasonLength}</label>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Field
            component={Textarea}
            validate={[required, maxLength([500, "Text too long (max 500 characters)"])]}
            type="text"
            name="reason"
            id="reason"
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill gray" onClick={() => props.closeModal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type={'submit'} disabled={pristine || submitting || invalid} className="btn fill blue">Reject</button>
        </div>
      </div>
    </form>
  );
}

const RejectGolferForm = reduxForm({
  form: 'RejectGolferForm',
  destroyOnUnmount: true
})(RejectGolfer)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(RejectGolferForm));