import React, {Component} from 'react';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import AssociationService from '../../../../../services/api/association';


class ReorderContentBlocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial_email_contents: this.props.email_contents,
      email_contents: this.props.email_contents
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.email_contents) !== JSON.stringify(this.props.email_contents)) {
      if (JSON.stringify(this.state.initial_email_contents.map(el => el.id)) === JSON.stringify(prevProps.email_contents.map(el => el.id))) {
        this.setState({
          initial_email_contents: this.props.email_contents,
          email_contents: this.props.email_contents
        });
      } 
    }
  }

  SortableItem = sortableElement(
    ({value}) => (
      <li style={{listStyleType: "none", cursor: "move", marginLeft: "12px"}}>
        <span className="data-label">
          <span>
            <i className="material-icons">menu</i>
          </span>
          <span className="drag-drop-item_name">
            {value}
          </span>
        </span>
      </li>
    )
  );

  SortableContainer = sortableContainer(({children}) => {
    return <ul>{children}</ul>;
  });

  modified=() => {
    return JSON.stringify(this.state.initial_email_contents) !== JSON.stringify(this.state.email_contents)}

  resetBlocks=() => {
    this.setState({
      email_contents: [...this.state.initial_email_contents]
    });
  }

  orderBlocks=() => {
    AssociationService.updateBlocksOrder(this.props.associationId, this.state.email_contents.map(el => el.id))
      .then(res => {
        this.props.loadEmails();
        this.setState({
          initial_email_contents: [...this.state.email_contents]
        });
      });
  }

  onSortStart=() => {
    document.body.style.cursor = 'move'
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    document.body.style.cursor = 'default';
    this.setState({
      email_contents: arrayMoveImmutable(this.state.email_contents, oldIndex, newIndex),
    });
  };

  render() { 
    return(
      <div className="row">
        <div className="col">
          <div className="panel">
            <div className="panel__head">Reorder Content Blocks</div>
            <div className="panel__body">
              {this.props.canEditAssociation ?
              <>
                <div className="row">
                  <div className="col jc-fs">
                    Drag and drop content blocks into the order you would like them to appear in the email.
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                      <this.SortableContainer onSortStart={this.onSortStart} onSortEnd={this.onSortEnd}>
                        {this.state.email_contents.map((entry, index) => {
                          return <this.SortableItem key={`item-${entry.block_name}`} index={index} value={entry.block_name} />
                        })}
                      </this.SortableContainer>
                  </div>
                </div>
                <br/>
                <div className="row">
                      <div className="col is-1-of-2">
                          <button disabled={!this.modified()} type={'button'} onClick={() => this.resetBlocks()} className="btn fill gray">Cancel</button>
                      </div>
                      <div className="col is-1-of-2">
                          <button disabled={!this.modified()} type={'submit'} onClick={() => this.orderBlocks()} className="btn fill blue">Save</button>
                      </div>
                </div>
              </>
              :
              <div className="row">
                <div className="col">
                  <ul>
                    {this.state.email_contents.map((entry) => {
                      return <li style={{listStyleType: "none", margin: "0 0 6px 12px"}}>
                      <span className="data-label">
                        {entry.block_name}
                      </span>
                    </li>
                    })}
                  </ul>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ReorderContentBlocks;