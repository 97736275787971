import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Header from '../../../shared/Header';
import Navigation from '../../../shared/Navigation';
import BreadCrumbsNav from '../../../shared/BreadCrumbsNav';
import Footer from '../../../shared/Footer';

import AssociationService from "../../../../services/api/association";
import UsersService from "../../../../services/api/users";
import SharedService from "../../../../services/api/shared";
import { logoutUser } from "../../../../screens/Auth/actions";
import { buildAccess } from "../../../../permissionManager";
import { getRedirectUrl } from "./HubspotConstants"


class HubspotConnect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      localStorageReady: !!props.user?.access,
      termsToSave: null
    }

    if (!props.user?.access)
      this.refreshLocalStorage()
    else
      this.requestHubspotTokens()
  }

  requestHubspotTokens() {
    const query = new URLSearchParams(this.props.location.search);
    const authCode = query.get('code')
    const redirectUrl = getRedirectUrl()
    if (authCode) {
      AssociationService.requestHubspotTokens({ authCode, redirectUrl })
        .then((response) => {
          window.location.replace(`${window.location.origin}/manage/association/${response.golf_association_id}/ghin-connect/hubspot`)
        })
        .catch(err => {
          console.error(err);
        })
    }
  }

  async refreshLocalStorage() {
    const terms = (await SharedService.getFederationTerms()).terms
    this.setState({ termsToSave: terms });

    const user = (await UsersService.currentUser()).user

    this.props.dispatch({
      type: "@@ghin/ADD_LOGIN_USER_DATA",
      payload: {
        id: user.user_id,
        first_name: user.first_name,
        last_name: user.last_name
      }
    });

    let access, parsedAccess

    try {
      access = await UsersService.accesses()
      const emails = await UsersService.emails()

      parsedAccess = buildAccess(access, emails);

      if (!parsedAccess)
        throw 'Your account is inactive'

    } catch (error) {
      this.props.logoutUser();
    }

    this.props.dispatch({
      type: '@@ghin/ADD_USER_PARSED_ACCESS',
      payload: parsedAccess
    });
    this.props.dispatch({
      type: '@@ghin/ADD_USER_ACCESS',
      payload: access
    });
    this.props.dispatch({
      type: '@@ghin/ADD_TERMS',
      payload: this.state.termsToSave
    });

    if (parsedAccess){
      this.setState({ localStorageReady: true })
      this.requestHubspotTokens()
    }
  }


  render() {
    return (
      <Fragment>
        {this.state.localStorageReady &&
          <>
            <Header />
            <Navigation />
            <BreadCrumbsNav />
          </>
        }

        <main>
          <div className="container narrow">

            <h2
              className="page__title"
              style={{ textAlign: 'center' }}
            >
              Hubspot Connect
            </h2>

            <div
              style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}
            >
              <span
                style={{ position: 'absolute' }}
                className="data-table__loader"></span>
            </div>
          </div>
        </main>

        {this.state.localStorageReady && <Footer />}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ logoutUser }, dispatch);
  return {
    dispatch,
    ...actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HubspotConnect);

