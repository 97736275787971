import React, {Component, Fragment} from 'react';
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";
import GhinFilterSelect from "../../shared/GhinFilterSelect";
import ClubService from "../../../services/api/club";
import AssociationService from "../../../services/api/association";
import TableService from "../../../services/tableService";



const relationshipTypes = [
	{label: 'Father', value: 'Father'},
	{label: 'Mother', value: 'Mother'},
	{label: 'Legal Guardian', value: 'Legal Guardian'},
	{label: 'Coach', value: 'Coach'},
	{label: 'Other', value: 'Other'}
];

export default class TableFilter extends Component {

	constructor(props) {

		super(props);

		this.state = {
			associations: [],
			clubs: [],
			filters: {search: this.props.searchText}
		};

	}

	componentDidMount() {
		this.loadAssociations();
	}
	updateFilter = (name, value) => {

		let newState = {
			filters: {
				...this.state.filters,
				[name]: value
			},
		};

		if (name === 'association_id') {
			newState['filters']['club'] = null;
			newState['clubs']  = [];
		}

		this.setState(newState, () => {
			this.updateTable();

			if (name === 'association_id') {
				this.loadClubs(value.value);
			}
		})
	};

	updateTable = debounce(() => {
		let filteredArray = TableService.filtersToArray(this.state.filters);
		this.props.updateFiltered(filteredArray);
	}, 200);


	reset() {
		this.setState({filters: {search: "", email: "", gender: "", authorized: null, is_test: null, is_dac: null, technology_provider: null, club_id: null, association_id: null}}, () => {
			this.updateTable();
		});
	}

	loadAssociations() {
		return ClubService.getGolfAssociations("global").then((data) => {
			this.setState({
				associations: data.associations.map((item) => {
					return {
						label: item.name,
						value: item.id
					}
				}).sort((a, b) => a.label.localeCompare(b.label) )
			})
		})
	}

	loadClubs(association_id) {
		return AssociationService.getAllClubs(association_id, {}).then((data) => {
			this.setState({
				clubs: data.clubs.map((item) => {
					return {
						label: item.club_name,
						value: item.id
					}
				}).sort((a, b) => a.label.localeCompare(b.label) )
			})
		});
	}

	render() {
		return (
			<Fragment>
				<div className="filters columns">
					<div className="row">
						<div className={"filters__inputs col"}>
							<div className={"row"}>
								<div className='filters__inputs col'>
									<div className={'row'}>
										<div className="col is-1-of-7">
											<label htmlFor="association-name">Guardian Name</label>
											<input type="text" autoComplete="off" value={this.state.filters.search} name="golfer-name" id="golfer-name" onChange={(e) => {
												this.updateFilter('search', e.target.value)
											}}/>
										</div>

										<div className="col is-1-of-6">
											<GhinFilterSelect
												options={['Active', 'Inactive', 'Archived']}
												value={this.state.filters.status}
												onChange={values => this.updateFilter('status', values)}
												label={'Guardianship Status'}
											/>
										</div>

										<div className="col is-1-of-6">
											<GhinFilterSelect
												singleChoice={true}
												options={relationshipTypes}
												value={this.state.filters.relationship}
												onChange={values => this.updateFilter('relationship', values)}
												label={'Relationship'}
											/>
										</div>


										<div className="col is-1-of-6">
											<label htmlFor="association-name">Email</label>
											<input type="text" autoComplete="off" value={this.state.filters.email} name="email-name" id="email-name" onChange={(e) => {
												this.updateFilter('email', e.target.value)
											}}/>
										</div>
									</div>
								</div>
								<div className="filters__controls col is-1-of-7 jc-fe">
									<button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
								</div>
							</div>

						</div>
					</div>

				</div>

			</Fragment>
		);
	}
}
