import React, { Component, Fragment } from 'react';
import Modal from "react-modal";

export default class StopHubspotConnectionModal extends Component {

  render() {
    return (
      <Modal
        isOpen={true}
        onRequestClose={this.props.closeModal}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
      >
        <Fragment>

          <div className="modal__container narrow">

            <div className="modal__head">

              <h2 className="modal__title">Are you sure you want to stop Hubpost Integration?</h2>
              <button className="modal__close" onClick={this.props.closeModal}><i className="material-icons-outlined">clear</i></button>

            </div>

            <div className="modal__body">
              <div className="row">
                <div className="col">
                  <button type="button" onClick={this.props.closeModal} className="btn fill gray">Cancel</button>
                </div>
                <div className="col">
                  <button type="submit" onClick={this.props.onSubmit} className="btn fill red">Stop Sync</button>
                </div>
              </div>
            </div>

          </div>

        </Fragment>
      </Modal>
    );
  }
}
