import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import {required, maxLength, isEmailAddress, requiredSelect, alphaNumeric, isUrl, isNumber} from "../../inputs/validations";
import SharedService from "../../services/api/shared";
import AssociationService from "../../services/api/association";
import {addAlert} from '../shared/actions';
import frontendProviders from "../shared/frontendProvider";
import usgaTmAccountManager from "../shared/usgaTmAccountManager";
import regionalAffairsDirectors from "../shared/regionalAffairsDirectors";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class AddNewAssociationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            membershipCodes: [{label: 'J', value: 'J'}, {label: 'R', value: 'R'}],
            startDate: new Date(),
            timezones: [],
            countries: [],
            states: [],
			errors: []
        };

        this.onSubmit = this.onSubmit.bind(this);
    }


    onSubmit(values) {
    	let data = JSON.parse(JSON.stringify(values));

		data.association.parent_federation = data.association.parent_federation.value;
		data.association.timezone = data.association.timezone.value;
		data.association.status = "Active";
		data.association.frontend_provider = data.association.frontend_provider.value;
		data.association.command_user = data.association.command_user ? 'true' : 'false';
		data.association.handicap_program_lic = data.association.handicap_program_lic ? 'true' : 'false';
		data.association.course_rating_lic = data.association.course_rating_lic ? 'true' : 'false';
		if (data.association.regional_affairs_director) data.association.regional_affairs_director = data.association.regional_affairs_director.value;
		if (data.association.usga_tm_account_manager) data.association.usga_tm_account_manager = data.association.usga_tm_account_manager.value;
    	data.primary_address.state = data.primary_address.state.code;
    	data.primary_address.country = data.primary_address.country.code;


        return AssociationService.createAssociation(data)
			.then((res) => {
				this.props.addAlert({type: 'success', message: 'Association has been successfully created !'});
				this.props.onSuccess(res);
			})
			.catch(err => {
				let errors = [];

				Object.keys(err.data.errors).forEach(error => {
					if (err.data.errors[error].length > 1) {
						err.data.errors[error].forEach(errorChild => {
							errors.push(error.replace(/_/g, ' ') + ' ' + errorChild);
						})
					} else {
						errors.push(err.data.errors[error][0]);
					}
				});

				this.setState({errors})
			});
    }

    componentDidMount() {
        SharedService.getTimezones().then((response) => {
            this.setState({
                timezones: response.time_zones.map((item) => {
                    return {
                        ...item,
                        label: item.display,
                        value: item.code
                    }
                })
            })
        });

        SharedService.getCountriesAndStates()
            .then((response) => {
                this.setState({
                    countries: response.countries.map((item) => {
                        return {
                            ...item,
                            value: item.name,
                            label: item.name,
                            states: item.states.map((state) => {
                                return {
                                    ...state,
                                    label: state.name.includes('Armed ') ? state.code : state.name
                                }
                            })
                        }
                    })
                })
            })
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

    onCountryChange(country) {
        this.setState({
            states: country.states
        })
    }

	render() {
        const {handleSubmit, submitting} = this.props;

        return (
            <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>

                <div className="row">
                    <div className="col is-full">
                        <span className="label" htmlFor="club-name">Allied Golf Association ? <span>*</span></span>
                        <div className="radio__container">

                            <Field
                                id="assoc-yes"
                                value={"true"}
                                onClick={
                                    () => {
                                        this.setState((prevState) => {
                                            return {isChecked: !prevState.isChecked};
                                        });
                                    }
                                }
                                name={'association.allied_assn'}
                                component={'input'}
                                checked={this.state.isChecked}
                                className="simple__radio"
                                type="radio"
                            />
                            <label htmlFor="assoc-yes">Yes</label>

                            <Field
                                id="assoc-no"
                                value={"false"}
                                name={'association.allied_assn'}
                                onClick={
                                    () => {
                                        this.setState((prevState) => {
                                            return {isChecked: !prevState.isChecked};
                                        });
                                    }
                                }
                                checked={!this.state.isChecked}
                                className="simple__radio"
                                component={'input'}
                                type="radio"
                            />
                            <label htmlFor="assoc-no">No</label>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor="association.name">Association Name <span>*</span></label>
                        <Field component={InputField} validate={[required, maxLength([60, 'The Association Name cannot exceed 60 characters.'])]} type="text" name="association.name" id="association.name" />
                    </div>
                </div>

                <div className="row">
                    <div className="col is-1-of-3">
                        <label htmlFor="association.short_name">Short Name</label>
                        <Field component={InputField} format={value => value ? value.slice(0,8) : ""}  validate={[alphaNumeric]} type="text" name="association.short_name" id="association.short_name" />
                    </div>
                    <div className="col is-1-of-3">
                        <label htmlFor="association.parent_federation">Parent Golf Federation <span>*</span></label>
                        <Field validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" name="association.parent_federation" options={[{value: 'United States Golf Association', label: 'United States Golf Association'}]} value={'1'} />
                    </div>
                    <div className="col is-1-of-3">
                        <label htmlFor="association.course_rating_id">Course Rating ID <span>*</span></label>
                        <Field component={InputField} validate={[required, isNumber]} type="text" name="association.course_rating_id" />
                    </div>
                </div>
				        {/* not needed now */}
                {/*<div className="row">*/}
                {/*    <div className="col is-1-of-3">*/}
                {/*        <Field component={'input'} type="checkbox" name="association.command_user" id="association.command_user" />*/}
                {/*        <label htmlFor="association.command_user">Command User</label>*/}
                {/*    </div>*/}
                {/*    <div className="col is-1-of-3">*/}
                {/*        <Field component={'input'} type="checkbox" name="association.handicap_program_lic" id="association.handicap_program_lic" />*/}
                {/*        <label htmlFor="association.handicap_program_lic">Handicap Program Lic.</label>*/}
                {/*    </div>*/}
                {/*    <div className="col is-1-of-3">*/}
                {/*        <Field component={'input'} type="checkbox" name="association.course_rating_lic" id="association.course_rating_lic" />*/}
                {/*        <label htmlFor="association.course_rating_lic">Course Rating Lic.</label>*/}
                {/*    </div>*/}
                {/*</div>*/}

				        <div className="row">
                  <div className="col is-full">
                    <label>Front End Provider <span>*</span></label>
                    <Field name={'association.frontend_provider'} id={'association.frontend_provider'} validate={[required, requiredSelect]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={frontendProviders}/>
                  </div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label>Timezone <span>*</span></label>
                        <Field name={'association.timezone'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={this.state.timezones}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label>Country <span>*</span></label>
                        <Field onChange={this.onCountryChange.bind(this)} name={'primary_address.country'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={this.state.countries}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor="primary_address.street_1">Address 1 <span>*</span></label>
                        <Field component={InputField} type="text" name="primary_address.street_1" id="primary_address.street_1" validate={[required, maxLength([46, "Value too long (max 46 characters)"])]}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor="primary_address.street_2">Address 2</label>
                        <Field component={InputField} type="text" name="primary_address.street_2" id="primary_address.street_2" validate={[maxLength([46, "Value too long (max 46 characters)"])]}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-3-of-7">
                        <label htmlFor="primary_address.city">City <span>*</span></label>
                        <Field component={InputField} validate={[required]} type="text" name="primary_address.city" id="primary_address.city" />

                    </div>
                    <div className="col is-2-of-7">
                        <label htmlFor="primary_address.state">State <span>*</span></label>
                        <Field name={'primary_address.state'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={this.state.states}/>
                    </div>
                    <div className="col is-2-of-7">
                        <label htmlFor="primary_address.zip">Postal Code <span>*</span></label>
                        <Field component={InputField} validate={[required]} type="text" name="primary_address.zip" id="primary_address.zip" />
                    </div>
                </div>

                <div className="row">
                    <div className="col is-1-of-2">
                        <label htmlFor="association.telephone">Phone <span>*</span></label>
                        <Field component={InputField} validate={[required]} type="text" name="association.telephone" id="association.telephone" />
                    </div>
                    <div className="col is-1-of-2">
                        <label htmlFor="website">Website</label>
                        <Field component={InputField} type="text" validate={[isUrl]} name="association.web_address" id="association.website" />
                    </div>
                </div>

                <div className="row">
                    <div className="col is-1-of-2">
                        <label htmlFor="email">Email <span>*</span></label>
                        <Field component={InputField} type="text" validate={[isEmailAddress, required]} name="association.email" id="association.email" />
                    </div>
                    <div className="col is-1-of-2">
                        <span className="label" htmlFor="club-name">Test ? <span>*</span></span>
                        <div className="radio__container">

                            <Field
                                onClick={
                                    () => {
                                        this.setState((prevState) => {
                                            return {is_test: !prevState.is_test};
                                        });
                                    }
                                }
                                checked={this.state.is_test}

                                id="test-yes"
                                value={"true"}
                                name={'association.is_test'}
                                component={'input'}
                                className="simple__radio"
                                type="radio"
                            />
                            <label htmlFor="test-yes">Yes</label>

                            <Field
                                onClick={
                                    () => {
                                        this.setState((prevState) => {
                                            return {is_test: !prevState.is_test};
                                        });
                                    }
                                }
                                checked={!this.state.is_test}
                                id="test-no"
                                value={"false"}
                                name={'association.is_test'}
                                component={'input'}
                                className="simple__radio"
                                type="radio"
                            />
                            <label htmlFor="test-no">No</label>
                        </div>
                    </div>
                </div>

				<div className="row">
					<div className="col is-full">
						<label htmlFor="association.usga_tm_account_manager">USGA TM Account Manager <span>*</span></label>
						<Field name="association.usga_tm_account_manager"
							   id="association.usga_tm_account_manager"
							   component={SelectInput}
							   validate={[requiredSelect]}
							   className="react-select-container"
							   classNamePrefix="react-select"
							   options={usgaTmAccountManager}/>

					</div>
				</div>

				<div className="row">
					<div className="col is-full">
						<label htmlFor="association.regional_affairs_director">Regional Affairs Director <span>*</span></label>
						<Field name="association.regional_affairs_director"
							   id="association.regional_affairs_director"
							   component={SelectInput}
							   validate={[requiredSelect]}
							   className="react-select-container"
							   classNamePrefix="react-select"
							   options={regionalAffairsDirectors}/>

					</div>
				</div>

				{this.state.errors.length > 0 &&<div className={"row"}>
					<div className={"col is-full"}>
						<p>
							{this.state.errors.map(error => {
								return (<span
									className={'validation__message is-error'}>{error.charAt(0).toUpperCase() + error.substring(1)}</span>);
							})}
						</p>
					</div>
				</div>}


                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={this.props.closeModal}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
                        <button className="btn fill blue" disabled={submitting} type={'submit'} ref={'submit'}>Save</button>
                    </div>
                </div>

            </form>
		);
	}
}


AddNewAssociationForm = reduxForm({
    form: 'addAssociationForm',
    destroyOnUnmount: true
})(AddNewAssociationForm);

function mapStateToProps() {
    return {
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        initialValues: {
        	association: {
				allied_assn: "false",
				is_test: "false",
				timezone: {
					code: "Eastern Time (US & Canada)",
					display: "GMT-05:00 Eastern Time (US & Canada) (America/New_York)",
					label: "GMT-05:00 Eastern Time (US & Canada) (America/New_York)",
					value: "Eastern Time (US & Canada)"
				}
			}
		}
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({addAlert}, dispatch);
    return {
        dispatch,
        ...actions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddNewAssociationForm));
