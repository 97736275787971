export let configurationColumns = [
  {
    Header: 'Associations Included in this Mailing',
    accessor: 'associations',
    thClassName: 'is-4-of-12',
    Cell: props => props.original.associations.join(", ")
  },
  {
    Header: 'Used GPS Free Trials',
    accessor: 'used_gps_free_trials',
    className: 'table-align-center',
    thClassName: 'table-align-center is-2-of-12',
    Cell: props => props.original.used_gps_free_trials ? "Yes" : "No"
  },
  {
    Header: 'Active GPS Subscription',
    accessor: 'active_gps_subscription',
    className: 'table-align-center',
    thClassName: 'table-align-center is-2-of-12',
    Cell: props => props.original.active_gps_subscription ? "Yes" : "No"
  },
  {
    Header: 'Inactive GPS Subscription',
    accessor: 'inactive_gps_subscription',
    className: 'table-align-center',
    thClassName: 'table-align-center is-2-of-12',
    Cell: props => props.original.inactive_gps_subscription ? "Yes" : "No"
  },
  {
    Header: 'No GPS Subscription',
    accessor: 'no_gps_subscription',
    className: 'table-align-center',
    thClassName: 'table-align-center is-2-of-12',
    Cell: props => props.original.no_gps_subscription ? "Yes" : "No"
  }
];
