import React, {Component, Fragment} from 'react';
import GhinTable from "../../../shared/GhinTable";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import {checkKeyForDateInput} from "../../../../inputs/helper.js"
import moment from "moment";
import AssociationService from "../../../../services/api/association";
import {Picky} from 'react-picky';
import {Collapse} from 'react-collapse';
import 'react-picky/dist/picky.css'; // Include CSS
const options = [
	{ value: 'initial', label: 'Initial (New Billing Cycle)' },
	{ value: 'incremental', label: 'Incremental' }

];

export default class ClubBillingReportAssoc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: {
				initial: null,
				last: [],
				current: null
			},
			club_billing_report_logs: [],
      advanced_options_open: false,
      advanced_options: null,
      initial_options: null,
			type: null,
			error: null
		};
	this.handleChange = this.handleChange.bind(this);
	}
	componentDidMount() {
		this.getBillingReportActivity();
    this.getAdvancedBillingOptions();
		this.interval = setInterval(
			() => {
				this.getBillingReportActivity();
			}, 3000
		);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	updateFilter = (name, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		})
	};

  getAdvancedBillingOptions() {
    AssociationService.getPrimary(this.props.associationId)
      .then(res => {
        this.setState({
          initial_options: {
            ...res.club_billing_advanced
          },
          advanced_options: {
            ...res.club_billing_advanced
          }
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

	getBillingReportActivity() {
		AssociationService.getClubBillingReportLogs(this.props.associationId)
			.then(res => {
				this.setState( {
					club_billing_report_logs:
						res.club_billing_report_logs.map(log => {
							return {
								...log,
								...{
									report_type: log.report_type.charAt(0).toUpperCase() + log.report_type.slice(1) ,
									export_date_time: log.export_date_time && moment(log.export_date_time).format('MM/DD/YYYY HH:mm:ss'),
									initial_billing_revision_date: log.initial_billing_revision_date && moment(log.initial_billing_revision_date).format('MM/DD/YYYY'),
									last_billing_revision_date: log.last_billing_revision_date && moment(log.last_billing_revision_date).format('MM/DD/YYYY'),
									current_billing_revision_date: log.current_billing_revision_date && moment(log.current_billing_revision_date).format('MM/DD/YYYY')
								}
							}
						}),
				});
			})
			.catch(err => {
				console.log(err);
			})
	}

	onSubmit(){

		let formatedFilters = {
				initial: this.state.filters.initial && moment(this.state.filters.initial).format('MM-DD-YYYY'),
				last: this.state.filters.last.map((i) =>  moment(i.value, 'MM/DD/YYYY').format('MM-DD-YYYY')),
				current: this.state.filters.current && moment(this.state.filters.current).format('MM-DD-YYYY')
			};

    let advancedSettings = {
      ...this.state.advanced_options
    }

		AssociationService.getClubBillingReportRun(this.props.associationId, this.state.type, formatedFilters, advancedSettings)
			.then(res => {
				if(res.message === "Ok") {
					this.setState({
						error: null,
						filters: {
							initial: null,
							last: [],
							current: null
						},
            initial_options: this.state.advanced_options
					},() => this.getBillingReportActivity());}
			})
			.catch(error =>{
				if(error.data.errors && error.data.errors.billing_revision_date) {
					this.setState({error: error.data.errors.billing_revision_date[0]});
				}

			})
	}

	handleChange(e) {
		this.setState({
			type: e.value,
			filters: {
				initial: null,
				last: [],
				current: null
			}
		})
	}

  setBillingDate(date) {
    if (date.currentTarget.value?.length < 8) return;
    let currentInputDate = new Date(date.currentTarget.value);
    let today = new Date();
    if (currentInputDate.toString() === 'Invalid Date' || currentInputDate > today ) {
      date.currentTarget.value = moment(today.value).format('MM/DD/YYYY');
    }
  }

	render() {
		let billingReportActivityLog = [
			{
				Header: 'Export Date/Time',
				accessor: 'export_date_time',
				sortable: true,
				colClassName: 'col-export-date',
			},
			{
				Header: 'Billing Cycle',
				sortable: true,
				accessor: 'report_type',
				colClassName: 'col-billing-cycle'
			},
			{
				Header: 'User',
				accessor: 'user_name',
				sortable: true,
				colClassName: 'col-report-user_name'
			},
			{
				Header: 'Initial Billing Date',
				sortable: true,
				accessor: 'initial_billing_revision_date',
				colClassName: 'col-billing-date',
			},
			{
				Header: 'Last Billing Date',
				sortable: true,
				accessor: 'last_billing_revision_date',
				colClassName: 'col-billing-date',
			},
			{
				Header: 'Current Billing Date',
				sortable: true,
				accessor: 'current_billing_revision_date',
				colClassName: 'col-billing-date',
			},
			{
				accessor: "url",
				colClassName: "col_actions_centered",
				Cell: props => props.original.status === "ready" ?
					<Fragment>
						<a href={props.original.url} target={"_blank"} rel="noreferrer" className={"btn lnk"}>Download</a>
					</Fragment>
					:
					<span className={"gray bold"}>In Progress</span>,
			}

		];

		const initialBillingDatesArray = [...new Set(this.state.club_billing_report_logs.map((item) => item.initial_billing_revision_date))].filter((i)=>i);
		const currentBillingDatesArray = [...new Set(this.state.club_billing_report_logs.map((item) => item.current_billing_revision_date))].filter((i)=>i);
		const lastBillingDatesArray = [...new Set([...initialBillingDatesArray, ...currentBillingDatesArray])].filter( date => moment(date) < moment());
		const initialBillingDates = initialBillingDatesArray.sort((a, b) => moment(b, 'MM/DD/YYYY')._d - moment(a, 'MM/DD/YYYY')._d).map((entry) => ({label: entry, value: entry}));

		let lastBillingDates = lastBillingDatesArray
			.sort((a, b) => moment(b, 'MM/DD/YYYY')._d - moment(a, 'MM/DD/YYYY')._d).map((entry) => ({label: entry, value: entry}))
			.filter((item) => moment(item.value, 'MM/DD/YYYY').isSameOrAfter(moment(this.state.filters.initial, 'MM/DD/YYYY')));

		return (
            <Fragment>
				{this.props.canEditAssociation && <div className="row">
                    <div className="col">
                        <div className="panel">
                            <div className="panel__head">Club Billing</div>
                            <div className="panel__body">
                                <div className="row">
                                    <div className="col">
                                        <p className="gray"><i>Use the club billing export to generate a recommended count of active golfers for billing. For initial billing, select the start date of your fiscal year. For incremental billing, specify the start date of your fiscal year, all dates in which you previously billed this year, and the date through which you want to bill. When generating a list of billable golfers for incremental billing, all golfers who were active on a previous bill date will be excluded for the remainder of that billing year.</i></p>
                                    </div>
                                </div>
								<div className="row">
									<div className="col is-1-of-4">
										<label>Billing Cycle</label>
										<Select className="react-select-container" classNamePrefix="react-select" options={options} onChange={this.handleChange}/>
									</div>
									{this.state.type && this.state.type === 'initial' &&
										<div className="col is-1-of-4">
											<label>Initial Billing Date</label>
											<DatePicker
												selected={this.state.filters.initial ? this.state.filters.initial : null  }
												className={'date'}
												dropdownMode="select"
												dateFormat={'MM/dd/yyyy'}
												maxDate={new Date()}
												showMonthDropdown
												showYearDropdown
												defaultSorted={[{
													id: 'export_date_time',
													desc: false
												}]}
												onChange={(value) => {
													if (value) {
														this.updateFilter('initial', value)
													} else {
														this.updateFilter('initial', null)
													}
												}}
                        onChangeRaw={(date) => this.setBillingDate(date)}
												onKeyDown={e => {checkKeyForDateInput(e)}}
											/>
											<p className="sub-input__info">Annual Billing Start Date</p>
										</div>
									}
									{this.state.type && this.state.type === 'incremental' &&
									<div className="col is-1-of-4">
										<label>Initial Billing Date</label>
										<Select className="react-select-container" value={this.state.filters.initial ? initialBillingDates.filter((i) => i.value === this.state.filters.initial) : null} classNamePrefix="react-select" options={initialBillingDates} onChange={(item) => this.updateFilter('initial', item.value)}/>
										<p className="sub-input__info">Annual Billing Start Date</p>
									</div>
									}

									{this.state.type === 'incremental' &&
										<Fragment>
											<div className="col is-1-of-4">
												<label>Previous Billing Dates</label>
												<Picky
													options={lastBillingDates}
													value={this.state.filters.last || []}
													multiple={true}
													includeSelectAll={true}
													includeFilter={false}
													onChange={(items) => this.updateFilter('last', items)}
													dropdownHeight={600}
													disabled={!this.state.filters.initial}
													valueKey="value"
													labelKey="label"
													placeholder='Select...'
												/>
												<p className="sub-input__info">Active golfers from these previously billed dates will be excluded from the current billing report. {this.state.filters.last && this.state.filters.last.length > 3 ? <React.Fragment><br/><br/>Selected Dates: {this.state.filters.last.map((i) => i.value).join(", ")}</React.Fragment>: null}</p>
											</div>

											<div className="col is-1-of-4">
												<label>Current Billing Date</label>
												<DatePicker
													selected={this.state.filters.current ? this.state.filters.current : null}
													className={'date'}
													dropdownMode="select"
													dateFormat={'MM/dd/yyyy'}
													maxDate={new Date()}
													showMonthDropdown
													showYearDropdown
													onChange={(value) => {
														if (value) {
															this.updateFilter('current', value)
														} else {
															this.updateFilter('current', null)
														}
													}}
                          onChangeRaw={(date) => this.setBillingDate(date)}
													onKeyDown={e => {checkKeyForDateInput(e)}}
												/>
												<p className="sub-input__info">Active Golfers as of this date</p>
											</div>
										</Fragment>
									}
								</div>
                <div className="row">
                  <div className="col is-1-of-10">
                    <label style={{cursor: "pointer"}} onClick={() => this.setState({advanced_options_open: !this.state.advanced_options_open})}>
                      Advanced
                      <span className="material-icons-outlined collapse-arrow">
                        {this.state.advanced_options_open ? "expand_less" : "expand_more"}
                      </span>
                    </label>
                  </div>
                </div>
                <Collapse isOpened={this.state.advanced_options_open}>
                { this.state.advanced_options &&
                  <div className="col collapse-block">
                    <div className="row">
                      <div className="col is-1-of-3">
                        <span className="label">Timezone</span>
                        <div className="radio__container">
                          <input
                            onClick={
                              () => {
                                this.setState({
                                  advanced_options: {
                                    ...this.state.advanced_options,
                                    billing_timezone_format: 'utc'
                                  }
                                });
                              }}
                            defaultChecked={this.state.advanced_options.billing_timezone_format === 'utc'}
                            id="utc"
                            value={false}
                            name={'timezone'}
                            component={'input'}
                            className="simple__radio"
                            type="radio"
                          />
                          <label htmlFor="utc">UTC</label>

                          <input
                            onClick={
                              () => {
                                this.setState({
                                  advanced_options: {
                                    ...this.state.advanced_options,
                                    billing_timezone_format: 'association timezone'
                                  }
                                });
                              }}
                            defaultChecked={this.state.advanced_options.billing_timezone_format === 'association timezone'}
                            id="association_timezone"
                            value={false}
                            name={'timezone'}
                            component={'input'}
                            className="simple__radio"
                            type="radio"
                          />
                          <label htmlFor="association_timezone">Association Timezone</label>
                        </div>
                      </div>
                      {
                        this.props.join_and_renew_enabled &&
                        <div className="col is-1-of-3">
                          <span className="label">Joined Online</span>
                          <div className="radio__container">
                            <input
                              onClick={
                                () => {
                                  this.setState({
                                    advanced_options: {
                                      ...this.state.advanced_options,
                                      billing_joined_online: 'all'
                                    }
                                  });
                                }}
                              defaultChecked={this.state.advanced_options.billing_joined_online === 'all'}
                              id="joined_online_all"
                              value={false}
                              name={'joined_online'}
                              component={'input'}
                              className="simple__radio"
                              type="radio"
                            />
                            <label htmlFor="joined_online_all">All</label>

                            <input
                              onClick={
                                () => {
                                  this.setState({
                                    advanced_options: {
                                      ...this.state.advanced_options,
                                      billing_joined_online: 'yes'
                                    }
                                  });
                                }}
                              defaultChecked={this.state.advanced_options.billing_joined_online === 'yes'}
                              id="joined_online_yes"
                              value={false}
                              name={'joined_online'}
                              component={'input'}
                              className="simple__radio"
                              type="radio"
                            />
                            <label htmlFor="joined_online_yes">Yes</label>

                            <input
                              onClick={
                                () => {
                                  this.setState({
                                    advanced_options: {
                                      ...this.state.advanced_options,
                                      billing_joined_online: 'no'
                                    }
                                  });
                                }}
                              defaultChecked={this.state.advanced_options.billing_joined_online === 'no'}
                              id="joined_online_no"
                              value={false}
                              name={'joined_online'}
                              component={'input'}
                              className="simple__radio"
                              type="radio"
                            />
                            <label htmlFor="joined_online_no">No</label>
                          </div>
                        </div>
                      }
                    </div>
                    <div style={{ borderBottom: '1px solid #cccccc', marginBottom: '20px' }}></div>
                    <div className="row">
                      <div className={`col is-1-of-${this.props.join_and_renew_enabled ? '4' : '3'}`}>
                        <span className="label">Include Invoice Number?</span>
                        <div className="radio__container">
                          <input
                            onClick={
                              () => {
                                this.setState({
                                  advanced_options: {
                                    ...this.state.advanced_options,
                                    include_invoice_number: true
                                  }
                                });
                              }}
                            defaultChecked={this.state.advanced_options.include_invoice_number}
                            id="include_invoice"
                            value={false}
                            name={'include_invoice_number'}
                            component={'input'}
                            className="simple__radio"
                            type="radio"
                          />
                          <label htmlFor="include_invoice">Yes</label>

                          <input
                            onClick={
                              () => {
                                this.setState({
                                  advanced_options: {
                                    ...this.state.advanced_options,
                                    include_invoice_number: false
                                  }
                                });
                              }}
                            defaultChecked={!this.state.advanced_options.include_invoice_number}
                            id="not_include_invoice"
                            value={false}
                            name={'include_invoice_number'}
                            component={'input'}
                            className="simple__radio"
                            type="radio"
                          />
                          <label htmlFor="not_include_invoice">No</label>
                        </div>
                      </div>
                      {
                        this.props.join_and_renew_enabled &&
                        <>
                          <div className="col is-1-of-4">
                            <span className="label">Include Joined Online?</span>
                            <div className="radio__container">
                                <input
                                  onClick={
                                    () => {
                                      this.setState({
                                        advanced_options: {
                                          ...this.state.advanced_options,
                                          include_joined_online: true
                                        }
                                      });
                                    }}
                                  defaultChecked={this.state.advanced_options.include_joined_online}
                                  id="include_joined_online_yes"
                                  value={false}
                                  name={'include_joined_online'}
                                  component={'input'}
                                  className="simple__radio"
                                  type="radio"
                                />
                                <label htmlFor="include_joined_online_yes">Yes</label>

                                <input
                                  onClick={
                                    () => {
                                      this.setState({
                                        advanced_options: {
                                          ...this.state.advanced_options,
                                          include_joined_online: false
                                        }
                                      });
                                    }}
                                  defaultChecked={!this.state.advanced_options.include_joined_online}
                                  id="include_joined_online_no"
                                  value={false}
                                  name={'include_joined_online'}
                                  component={'input'}
                                  className="simple__radio"
                                  type="radio"
                                />
                                <label htmlFor="include_joined_online_no">No</label>
                            </div>
                          </div>
                          <div className="col is-1-of-4">
                            <span className="label">Include Total?</span>
                            <div className="radio__container">
                              <input
                                onClick={
                                  () => {
                                    this.setState({
                                      advanced_options: {
                                        ...this.state.advanced_options,
                                        include_total: true
                                      }
                                    });
                                  }}
                                defaultChecked={this.state.advanced_options.include_total}
                                id="include_total_yes"
                                value={false}
                                name={'include_total'}
                                component={'input'}
                                className="simple__radio"
                                type="radio"
                              />
                              <label htmlFor="include_total_yes">Yes</label>

                              <input
                                onClick={
                                  () => {
                                    this.setState({
                                      advanced_options: {
                                        ...this.state.advanced_options,
                                        include_total: false
                                      }
                                    });
                                  }}
                                defaultChecked={!this.state.advanced_options.include_total}
                                id="include_total_no"
                                value={false}
                                name={'include_total'}
                                component={'input'}
                                className="simple__radio"
                                type="radio"
                              />
                              <label htmlFor="include_total_no">No</label>
                            </div>
                          </div>
                          <div className="col is-1-of-4">
                            <span className="label">Include Association/Club fee?</span>
                            <div className="radio__container">
                              <input
                                onClick={
                                  () => {
                                    this.setState({
                                      advanced_options: {
                                        ...this.state.advanced_options,
                                        include_fee: true
                                      }
                                    });
                                  }}
                                defaultChecked={this.state.advanced_options.include_fee}
                                id="include_fee_yes"
                                value={false}
                                name={'include_fee'}
                                component={'input'}
                                className="simple__radio"
                                type="radio"
                              />
                              <label htmlFor="include_fee_yes">Yes</label>

                              <input
                                onClick={
                                  () => {
                                    this.setState({
                                      advanced_options: {
                                        ...this.state.advanced_options,
                                        include_fee: false
                                      }
                                    });
                                  }}
                                defaultChecked={!this.state.advanced_options.include_fee}
                                id="include_fee_no"
                                value={false}
                                name={'include_fee'}
                                component={'input'}
                                className="simple__radio"
                                type="radio"
                              />
                              <label htmlFor="include_fee_no">No</label>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                }
                </Collapse>


                                <div className="row">
									{this.state.error && <div className="col"><span className={'validation__message is-error'}>{this.state.error}</span></div> }
                                    <div className="col auto push-right">
                                        <button style={{marginTop: "15px"}} className="btn fill green"  disabled={!this.state.type || (this.state.type === "initial" ? !this.state.filters.initial : (!this.state.filters.initial || !this.state.filters.current || !this.state.filters.last))} onClick={() => this.onSubmit()}>
                                          {JSON.stringify(this.state.advanced_options) !== JSON.stringify(this.state.initial_options) ?
                                           'Save and Run'
                                           :
                                           'Run'
                                          }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="row">
                    <div className="col">
                        <div className="panel">
                            <div className="panel__head">Billing Report Activity Log</div>
                            <div className="panel__body">
                                <div className="row">
                                    <div className="col">
                                        <p>The activity log shows a snapshot of previous report activities for your association</p>
                                    </div>
                                </div>
								<div className="row">
									<div className="col is-full">
										<GhinTable
											idKey={'id'}
											pageSize={this.state.club_billing_report_logs.length}
											hidePagination={true}
											columns={billingReportActivityLog}
											hideSelect={true}
											data={this.state.club_billing_report_logs}
											defaultSorted={[{
												id: 'export',
												desc: false
											}]}
											sortable={true}
										/>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
		);
	}
}
