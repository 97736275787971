import React, {Component, Fragment} from 'react';
import Table from './Table';

export default class AssociationList extends Component {

	render() {
		return (
            <Fragment>

                <Table {...this.props}/>

            </Fragment>
		);
	}
}
