import React, {Component, Fragment} from 'react';
import {reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import AssociationService from "../../services/api/association";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";


class EditHeaderAndFooterForm extends Component {

	constructor(props) {
			super(props);
			this.state = {
				email_settings: {
					show_header_bar: this.props.emailSettings.show_header_bar,
					show_footer_bar: this.props.emailSettings.show_footer_bar,
					use_app_primary_color_for_header_and_footer_bar: this.props.emailSettings.use_app_primary_color_for_header_and_footer_bar,
					header_and_footer_bar_color: this.props.emailSettings.header_and_footer_bar_color
				}
			}
	}
	componentDidMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	saveSetup() {
		let email_settings = {...this.state.email_settings};
		return AssociationService.editEmailSettings(this.props.associationId, this.props.emailSettings.id, {email_settings: email_settings})
			.then(res => {
				this.props.addAlert({
					type:'success',
					message: 'Header & Footer Bars have been successfully edited'
				});
				this.props.closeModal(res.email_settings);
			})
			.catch(err => {
				console.error(err);
			})
	}

	render() {
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={this.handleSubmit} ref={'form'}>

					<div className="row">
						<div className="col is-full">
							<label>Show Header Bar?</label>
							<div className="radio__container">
								<input
									onChange={ () => { this.setState({email_settings: {...this.state.email_settings, show_header_bar: true}}) }}
									checked={this.state.email_settings.show_header_bar}
									id="show_header_bar"
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
							<label htmlFor="show_header_bar">Yes</label>
								<input
									onChange={ () => { this.setState({email_settings: {...this.state.email_settings, show_header_bar: false}}) }}
									checked={!this.state.email_settings.show_header_bar}
									id="dont_show_header_bar"
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
							<label htmlFor="dont_show_header_bar">No</label>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col is-full">
							<label>Show Footer Bar?</label>
							<div className="radio__container">
								<input
									onChange={ () => { this.setState({email_settings: {...this.state.email_settings, show_footer_bar: true}}) }}
									checked={this.state.email_settings.show_footer_bar}
									id="show_footer_bar"
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
							<label htmlFor="show_footer_bar">Yes</label>
								<input
									onChange={ () => { this.setState({email_settings: {...this.state.email_settings, show_footer_bar: false}}) }}
									checked={!this.state.email_settings.show_footer_bar}
									id="dont_show_footer_bar"
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
							<label htmlFor="dont_show_footer_bar">No</label>
							</div>
						</div>
					</div>

					{(this.state.email_settings.show_header_bar || this.state.email_settings.show_footer_bar) &&
					<Fragment>
						<div className="row">
							<div className="col is-full">
								<label>Please choose a bar color option below:</label>
								<div className="radio__container">
									<input
										onChange={ () => { this.setState({email_settings: {...this.state.email_settings, use_app_primary_color_for_header_and_footer_bar: true}}) }}
										checked={this.state.email_settings.use_app_primary_color_for_header_and_footer_bar}
										id="use_primary_color"
										component={'input'}
										className="simple__radio"
										type="radio"
									/>
								<label htmlFor="use_primary_color">Use Mobile App Primary Color</label>
									<input
										onChange={ () => { this.setState({email_settings: {...this.state.email_settings, use_app_primary_color_for_header_and_footer_bar: false}}) }}
										checked={!this.state.email_settings.use_app_primary_color_for_header_and_footer_bar}
										id="use_custom_color"
										component={'input'}
										className="simple__radio"
										type="radio"
									/>
								<label htmlFor="use_custom_color">Use Custom Color</label>
								{!this.state.email_settings.use_app_primary_color_for_header_and_footer_bar &&
								<div style={{display: 'flex', alignItems: 'center'}}>
									<input type="color"
										value={`#${this.state.email_settings.header_and_footer_bar_color}`}
										onChange={(e) => this.setState({email_settings: {...this.state.email_settings, header_and_footer_bar_color: e.target.value.substring(1).toUpperCase()}})}
										name="color_picker"
										id="color_picker" />
								 </div>}
								</div>
							</div>
						</div>
					</Fragment>}

					<div className="row">
						<div className="col is-1-of-2">
							<button className="btn fill gray" type={'button'} onClick={() => this.props.closeModal()}>Close</button>
						</div>
						<div className="col is-1-of-2">
							<button className="btn fill blue" type={'button'} onClick={() => this.saveSetup()}>Save</button>
						</div>
					</div>
				</form>
      </Fragment>
		);
	}
}


EditHeaderAndFooterForm = reduxForm({
	form: 'editSetupForm',
	destroyOnUnmount: true
})(EditHeaderAndFooterForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditHeaderAndFooterForm));
