import React, {Component} from 'react'

// Component that handles the messaing protocol between Admin Portal and 
// Report Writer to share outer scroll position and other data
export default class ReportingMessagingProtocol extends Component {
	constructor(props) {
    super(props)
		this.setIframeRef = this.setIframeRef.bind(this)
		this.receiveMessage = this.receiveMessage.bind(this)
    this.iframeRef = null
		this.state = {
			refreshCounter: 0,
		}
  }

	receiveMessage(event) {
		if (!event.data) {
			return
		}

		const iframe = this.iframeRef
		if (!iframe) {
			return
		}

		if (event.data.type === 'rw-scroll-query') {
			const scroll = Math.max(0, window.scrollY - iframe.offsetTop)
			event.source.postMessage(
				{
					type: 'rw-scroll-response',
					scroll,
				},
				'*'
			)
    }
		else if (event.data.type === 'rw-permission-error') {
			if (this.state.refreshCounter < 4) {
				// Reload the iframe
				const iframeElement = iframe.childNodes[0]
				iframeElement.src += ''
				this.setState({
					refreshCounter: x => x + 1
				})
			}
		}
	}

	componentDidMount() {
		window.addEventListener('message', this.receiveMessage)
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.receiveMessage)
	}

	setIframeRef(ref) {
		this.iframeRef = ref
	}

	render() {
		return (
			<div ref={this.setIframeRef}>
        {this.props.children}
      </div>
		);
	}
}
