import React, {Component, Fragment} from 'react';

import SelectAddMinorMethod from './SelectAddMinorMethod';
import SearchMinor from './SearchMinor';
import AddExistingMinorForm from './AddExistingMinorForm';
import SuccessModal from "../../Modals/SuccessModal";

export default class AddMinorForm extends Component {

    constructor(props) {

        super(props);
        this.state = {
            selectMethod: false,
            searchMinor: true,
            addMinor: false,
			existingMinor: null,
			golfer: null,
        }

    }

    showSearch() {
        this.setState({
            selectMethod: false,
            searchMinor: true
        });
    }

    showAdd() {
        this.setState({
            selectMethod: false,
            addMinor: true
        });
    }

    showExistingForm(golfer){
    	this.setState({
			selectMethod: false,
			searchMinor: false,
			addMinor: false,
			existingMinor: golfer
		})
	}

    resetToSelect() {
        this.setState({
            selectMethod: false,
            searchMinor: true,
            addMinor: false,
			golfer: null,
			existingMinor: null,
        });
    }

    showSuccess(golfer) {
    	this.setState({
			selectMethod:false,
			searchMinor:false,
			addMinor: false,
			existingMinor: null,
			golfer,
		})
	}

	render() {
		return(
			<Fragment>

				{this.state.selectMethod && <SelectAddMinorMethod
					showSearch={()=>{this.showSearch()}}
					closeModal={() => {this.props.closeModal()}}
					showAdd={()=>{this.showAdd()}}
				/>}

				{this.state.searchMinor && <SearchMinor
					onGolferSelect={(data)=>{this.showExistingForm(data)}}
					resetToSelect={()=>{this.resetToSelect()}}
					closeModal={() => {this.props.closeModal()}}
				/>}


				{this.state.existingMinor && <AddExistingMinorForm
					golfer={this.state.existingMinor}
					guardian={this.props.guardian}
					resetToSelect={()=>{this.resetToSelect()}}
					closeModal={() => {this.props.closeModal()}}
					showSuccess={(data)=>{this.showSuccess(data)}}
				/>}

				{this.state.golfer && <SuccessModal
					openModal={this.state.golfer}
					onMainAction={() => {
						this.props.closeModal(true);
					}}
					onSecundaryAction={() => {
						this.resetToSelect();
					}}
					closeModal={() => {
						this.props.closeModal();
					}}
					golfer={this.state.golfer}
					mainLabel={"Continue to Setup"}
					secundaryLabel={"Add Minor"}
				/>}

			</Fragment>
		)
    }
}
