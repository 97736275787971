import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Field, reduxForm} from "redux-form";
import {InputField} from "../../inputs/InputField";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {required} from "../../inputs/validations";

class AddContentBlockForm extends Component {

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  onSubmit(values) {
    this.props.onSubmit(values);
    this.props.closeModal();
  }

  render() {
    let {handleSubmit, valid, submitting} = this.props;
    return (
      <Fragment>

        <form onSubmit={handleSubmit(this.onSubmit)}>

          <div className="row">
            <div className="col">
              <label htmlFor="name">Name of Content Block <span>*</span></label>
              <Field component={InputField} validate={[required]} type="text" autoComplete="off" name="name" id="name" />
            </div>
          </div>

          <div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={() => this.props.closeModal()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'} disabled={!valid || submitting} className="btn fill blue">Save</button>
						</div>
					</div>

        </form>

      </Fragment>
    );
  }
}

AddContentBlockForm = reduxForm({
  form: 'addContentBlockForm',
  destroyOnUnmount: true
})(AddContentBlockForm);

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddContentBlockForm));