import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssociationService from "../../../../services/api/association";
import GhinTable from "../../../shared/GhinTable";
import { columnsObjectMapping, readOnlyProps } from "./HubspotConstants"


class ObjectMappingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectMapping: this.props.objectMapping,
      editMappingMode: false,
      loading: false,
      lastInputChanged: null,
    };
  }

  updateObjectMapping(objectMapping) {
    const objectName = this.props.rowInfo.original.id

    this.setState({ loading: true })
    AssociationService
      .updateHubspotMappings(this.props.associationId, { field_mappings: { [objectName]: objectMapping } })
      .then(() => {
        this.setState({
          loading: false,
          editMappingMode: false,
          objectMapping: objectMapping
        })
        this.props.updateHubspotObjectsMappings(objectName, objectMapping)
      })
      .catch(err => {
        console.error(err);
      })
  }

  render() {
    const columnsObjectMappingEdit = [
      {
        Header: 'GHIN Field',
        accessor: 'ghin_field',
        thClassName: 'is-3-of-9',
        sortable: false,
      },
      {
        Header: 'Map?',
        accessor: 'is_mapped',
        thClassName: 'is-2-of-9 table-align-center',
        sortable: false,
        Cell: props =>
          <span className='col_edit-delete'>
            <input type="checkbox"
              checked={props.row.is_mapped}
              onChange={() => {
                const newObjectMapping = this.state.objectMapping
                  .map(e => e.ghin_field === props.row.ghin_field
                    ? { ...props.original, is_mapped: !props.row.is_mapped }
                    : e)
                this.setState({ objectMapping: newObjectMapping })
              }}
              name={`${props.row['ghin_field']}-map`}
              id={`item-${props.row['ghin_field']}-map`} />
            <label htmlFor={`item-${props.row['ghin_field']}-map`}></label>
          </span>,
      },
      {
        Header: 'Map to (HubSpot)',
        accessor: 'hubspot_field',
        thClassName: 'is-4-of-9',
        sortable: false,
        Cell: props =>
          <input
            type='text'
            disabled={readOnlyProps[this.props.rowInfo.original.id].includes(props.original.ghin_field)}
            className={!props.original.hubspot_field && props.original.is_mapped ? 'is-error' : ''}
            autoFocus={this.state.lastInputChanged === props.original.ghin_field}
            defaultValue={props.original.hubspot_field}
            onChange={(e) => {
              const newField = e.target.value
              const newObjectMapping = this.state.objectMapping
                .map(e => e.ghin_field === props.row.ghin_field
                  ? { ...props.original, hubspot_field: newField }
                  : e)
              this.setState({ objectMapping: newObjectMapping, lastInputChanged: props.row.ghin_field })
            }}
          ></input>
      },
    ];
    return (
      <tr style={{ backgroundColor: 'transparent' }}>
        <td colSpan={9} style={{ padding: "10px" }}>
          <div className="row">
            <div className="col">
              <div className="panel">
                <div className="panel__head">
                  Field Mapping for {this.props.rowInfo.original.ghin}
                </div>
                <div className="panel__body" style={{ position: 'relative' }}>
                  {
                    this.state.loading &&
                    <div
                      style={{ width: '100%', height: '100%', top: '0', left: 0, backgroundColor: '#efefef80', position: 'absolute' }}
                    >
                      <span
                        style={{ margin: 'auto' }}
                        className="data-table__loader"></span>
                    </div>
                  }
                  <div className="row">
                    {
                      this.props.editable &&
                      <div
                        className={`col push-right ${this.state.editMappingMode ? 'is-1-of-6' : 'auto'}`}
                      >
                        {
                          this.state.editMappingMode ?
                            <button
                              className="btn fill blue"
                              disabled={this.state.objectMapping.some(e => !e.hubspot_field && e.is_mapped)}
                              onClick={() => {
                                this.updateObjectMapping(this.state.objectMapping)
                              }}
                            >
                              Save changes
                            </button>
                            :
                            <button
                              className="btn lnk"
                              onClick={() => { this.setState({ editMappingMode: true }) }}
                            >
                              Edit
                            </button>
                        }
                      </div>
                    }
                  </div>
                  <div className="row">
                    <div className="col jc-fs" style={{ padding: '0 100px' }}>
                      <GhinTable
                        columns={this.state.editMappingMode ? columnsObjectMappingEdit : columnsObjectMapping}
                        data={this.state.objectMapping}
                        // total={this.state.objectMapping.length}
                        hideSelect={true}
                        hideSelectAll={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({}, dispatch);
  return {
    dispatch,
    ...actions,
  };
}

export default connect(mapDispatchToProps)(ObjectMappingDetails);