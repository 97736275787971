import React, {Component, Fragment} from 'react';
import GolferService from "../../../services/api/golfer";
import {bindActionCreators} from "redux";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {SelectInput} from "../../../inputs/Select";
import {required} from "../../../inputs/validations";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";
import {InputField} from "../../../inputs/InputField";
import {processErrorMessage} from "../../../services/shared/errorHelper";
const relationshipTypes = [
	{label: 'Father', value: 'Father'},
	{label: 'Mother', value: 'Mother'},
	{label: 'Legal Guardian', value: 'Legal Guardian'},
	{label: 'Coach', value: 'Coach'},
	{label: 'Other', value: 'Other'}
];

class AddExistingGuardianForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			associations: [],
			clubs: [],
			membershipTypes: [],
			error: null,
			membershipTypesLoaded: false
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		this.setState({
			startDate: date
		});
	}




	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values) {

		let guardianData = {
			relationship: values.relationship.value,
			other_relationship: values.other_relationship
		};

		if (this.props.guardian.golfer_id) {
			guardianData['golfer_id'] =  this.props.guardian.golfer_id;
			if (!this.props.guardian.email) {
				guardianData['email'] = values.email
			}
		} else {
			guardianData = {
				...guardianData,
				email: this.props.guardian.email
			}
		}

		guardianData = {
			...guardianData,

		};
		return GolferService.createGuardian(this.props.golfer.id, guardianData).then(res => {
			this.props.showSuccess({...this.props.guardian});
		})
			.catch(error => {

				let errorMessage = processErrorMessage(error.data.errors);
				this.setState({
					error: errorMessage
				});
			});

	}

	render() {
		const {handleSubmit, pristine, valid, submitting} = this.props;
		const showRelationshipTextInput =  this.props.relationship && this.props.relationship.value === 'Other';

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
					<div className="row with-padding ">
							Name: &#xA0; <strong>{this.props.guardian.first_name} {this.props.guardian.middle_name} {this.props.guardian.last_name}</strong>
					</div>
					{this.props.guardian.golfer_id ? <div className="row with-padding ">
							GHIN #: &#xA0; <strong>{this.props.guardian.golfer_id}</strong>
					</div> : null }
					{
						this.props.guardian.email ? (
							<div className="row with-padding ">
								Email Address: &#xA0; <strong>{this.props.guardian.email}</strong>
							</div>
						) : null
					}


					{
						!this.props.guardian.email ? (
							<div className={'row'}>
								<div className="col is-1-of-2">
									<label htmlFor="email">Guardian Email <span>*</span></label>
									<Field component={InputField}
										   validate={[required]}
										   type="text"
										   name="email"
										   id="email"/>
								</div>
							</div>
						) : null
					}

					<div className={'row'}>
						<div className="col is-1-of-2">
							<label htmlFor="golfer.relationship">Relationship to Minor <span>*</span></label>

							<Field name={'relationship'}
								   id={'relationship'}
								   validate={[required]}
								   component={SelectInput}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={relationshipTypes}
							/>
						</div>
						{
							showRelationshipTextInput ? (<div className="col is-2-of-2">
								<label htmlFor="other_relationship">What is the relationship ? <span>*</span></label>

								<Field component={InputField}
									   validate={[required]}
									   type="text"
									   name="other_relationship"
									   id="other_relationship"/>
							</div>) : null
						}



					</div>




					{this.state.error && <span className={'validation__message is-error'}>{this.state.error}</span>}


					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} onClick={(e) => {
								e.preventDefault();
								this.props.resetToSelect()
							}} className="btn fill gray">Back
							</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'} disabled={pristine || !valid || submitting} className="btn fill blue">Add Guardian
							</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

AddExistingGuardianForm = reduxForm({
	form: 'addExistingGuardianForm',
	destroyOnUnmount: true
})(AddExistingGuardianForm);

function mapStateToProps(state) {
	const selector = formValueSelector('addExistingGuardianForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		access: state.user.access,
		relationship: selector(state, 'relationship'),



	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddExistingGuardianForm));


