import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Associations from "./Associations";
import Association from "./Association";
import Club from "./Club";
import Golfer from "../Golfer";
import Guardian from "../Guardian";
import AuditLog from './Association/AuditLog';
import HubspotConnect from '../../screens/Manage/Association/GhinConnect/HubspotConnect'
import NotFound from "../NotFound/NotFound";

export default class Manage extends Component {
	render() {
		return (
			<Switch>
				<Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/associations`}/>
				<Route path={`${this.props.match.url}/associations`} component={Associations}/>
				<Route path={`${this.props.match.url}/association/:association_id/club/:id/golfer/:golfer_id`} component={Golfer}/>
				<Route path={`${this.props.match.url}/association/:association_id/club/:id`} component={Club}/>
				<Route path={`${this.props.match.path}/association/ghin-connect/hubspot`} component={HubspotConnect}/>
				<Route path={`${this.props.match.path}/association/:id`} component={Association}/>
				<Route path={`${this.props.match.path}/association/:id/audit-log`} component={AuditLog}/>
				<Route path={`${this.props.match.path}/guardian/:id`} component={Guardian}/>
				<Redirect exact from={`${this.props.match.url}/guardian`} to={`${this.props.match.url}/associations`}/>
				<Route component={NotFound} />
			</Switch>
		)
	}
}
