import React, {Component, Fragment} from 'react';

import EditAddressForm from '../Forms/EditAddressForm';

export default class EditAddressModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Edit {this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1) } Address Information</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <EditAddressForm type={this.props.type} for={this.props.for} assoc={this.props.assoc} close={(data)=>this.props.closeModal(data)} />

                    </div>

                </div>

            </Fragment>
		);
	}
}
