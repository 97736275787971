import React, {Component} from 'react';
import {Field, isDirty, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputField} from "../../inputs/InputField";
import {SelectInput} from "../../inputs/Select";
import {required, isEmailAddress} from "../../inputs/validations";
import UsersService from "../../services/api/users";

class EditUserModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
			roles: [
				{value: 'admin', label: 'Administrator'},
				{value: 'read_only', label: 'Admin-Read-Only'},
				{value: 'score_only', label: 'Admin-Rapid-Score-Entry-Only'},
				{value: 'score_maintenance', label: 'Admin-Score Posting & Maintenance'}
			],
			error: false,
			active: false
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		let role = this.state.roles.filter(r => r.value === this.props.user.user_access.role);
		let user = {
			...this.props.user.user,
			role: role[0],
			roleId: this.props.user.user_access.id,
			active: this.props.user.user_access.active === 'false' ? false: true,
		};
		this.props.initialize(user)
		this.setState({
			active: this.props.user.user_access.active === 'false' ? false: true,
		})
	}

	onSubmit(values) {
		let id = values.roleId;
		delete values['id'];
		delete values['roleId'];
    if (id === undefined)
      id = this.props.user.user_access.id
		UsersService.update({
			user_access: {
				...values,
				role: values.role.value,
				active: values.active ? 'true' : 'false'
			}}, id)
			.then(response => {
				this.props.closeModal();
			})
			.catch(err => {
				console.error(err.data.error);
				if(err.data.error) {
					this.setState({error: err.data.error})
				}
			})
	}

	render() {
		const {handleSubmit, pristine, submitting, userType} = this.props;
		const isAdmin = userType === "super-admin" || userType === "association-admin" || userType === "club-admin";
		return (
			<div className="modal__container narrow">
				<div className="modal__head">
					<div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
					<h2 className="modal__title">Edit {this.props.user.user.first_name} {this.props.user.user.last_name}</h2>
					<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
				</div>

				<div className="modal__body">
					<form onSubmit={handleSubmit(this.onSubmit)}>
						<div className="row">
							<div className="col is-1-of-2">
								<label htmlFor="prefix">Prefix</label>
								<Field component={InputField} type="text" name="prefix" id="prefix" />
							</div>
						</div>

						<div className="row">
							<div className="col is-1-of-2">
								<label htmlFor="first_name">First Name <span>*</span></label>
								<Field validate={[required]} component={InputField} type="text" name="first_name" id="first_name" />
							</div>
							<div className="col is-1-of-2">
								<label htmlFor="middle_name">Middle Name</label>
								<Field component={InputField} type="text" name="middle_name" id="middle_name" />
							</div>
						</div>

						<div className="row">
							<div className="col is-1-of-2">
								<label htmlFor="last_name">Last Name <span>*</span></label>
								<Field validate={[required]} component={InputField} type="text" name="last_name" id="last_name" />
							</div>
							<div className="col is-1-of-2">
								<label htmlFor="suffix">Suffix</label>
								<Field component={InputField} type="text" name="suffix" id="suffix" />
							</div>
						</div>

						<div className="row">
							<div className="col is-1-of-2">
								<label htmlFor="phone">Phone <span>*</span></label>
								<Field validate={[required]} component={InputField} type="text" name="phone" id="phone" />
							</div>
							<div className="col is-1-of-2">
								<label htmlFor="email">Email <span>*</span></label>
								<Field validate={[required, isEmailAddress]} component={InputField} type="text" disabled={!isAdmin} name="email" id="email" />
							</div>
						</div>

						<div className="row">
							<div className="col is-1-of-2">
								<label htmlFor="role">Role <span>*</span></label>
								<Field name={'role'}
									   component={SelectInput}
									   className="react-select-container"
									   classNamePrefix="react-select"
									   options={this.state.roles}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col is-1-of-2 ">
								<Field
									onClick={
										() => {
											this.setState((prevState) => {
												return {active: !prevState.active};
											});
										}
									}
									checked={this.state.active}
									id="active"
									value={true}
									name={'active'}
									component={'input'}
									type="checkbox"
								/>
								<label htmlFor="active">Active</label>
							</div>
						</div>

						{this.state.error && <span className={'validation__message is-error'}>{this.state.error}</span>}


						<div className="row">
						<div className="col is-1-of-2">
							<button type="button" onClick={() => {this.props.closeModal()}} className="btn fill gray">Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type="submit"  disabled={pristine || submitting} className="btn fill blue">Save</button>
							</div>
						</div>

					</form>
				</div>

			</div>

		)
	}
}


EditUserModal = reduxForm({
	form: 'editAssocUser',
	destroyOnUnmount: true
})(EditUserModal);


function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		dirty: isDirty('editAssocUser'),
		userType: state.user.access.user_type
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal)
