import { Route, Switch } from "react-router-dom";
import JoinPages from "./JoinPages";
import ManageJoinPage from "./ManageJoinPage";

function index(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.url}/join-pages`} render={() =>
        <JoinPages {...props} />}
      />
      <Route path={`${props.match.url}/join-pages/create`} render={() =>
        <ManageJoinPage {...props} />}
      />
    </Switch>
  )
}

export default index;
