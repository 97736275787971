import React, {Component, Fragment} from 'react';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class EditKioskImageInformationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
          image: this.props.image,
          tag: this.props.tag,
        }
    }

	render() {
		return (
            <Fragment>
                <div className="row">
                    <div className="col is-full">
                        <label>Image Url</label>
                        <span className="image-preview__url">{this.state.image}</span>
                    </div>
                </div>
                <div className="row">
                  <div className="col is-full">
                    <label htmlFor="image-tag">Image Name</label>
                    <input type="text"
                         value={this.state.tag}
                         onChange={(event) => this.setState({
                           tag: event.target.value
                         })}
                         autoComplete="off"
                         name="image-tag"
                         id="image-tag" />
                  </div>
                </div>
                {this.props.errors && <div className="row"> <div className="col is-1-of-2"> <span className={'validation__message is-error'}>{this.props.errors}</span> </div> </div>}
                <div className="row">
                    <div className="col is-1-of-2">
                        <button className="btn fill gray" type={'button'} onClick={() => this.props.closeModal()}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
                        <button className="btn fill blue" type={'submit'} ref={'submit'} onClick={() => this.props.closeModal({tag: this.state.tag })}>Save</button>
                    </div>
                </div>
            </Fragment>
		);
	}
}

export default withKeyPress(EditKioskImageInformationForm);
