import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {required} from "../../inputs/validations";
import {InputField} from "../../inputs/InputField";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import AlertsService from "../../services/api/alerts";


class EditMobileVersionForm extends Component {


	constructor(props){
		super(props);
		this.state = {
			isActive: true
		}
	}

	onSubmit(values, dispatch, props) {
		AlertsService.updateVersionNumbers(values)
			.then( res => {
				let versions = {};
				res.version_numbers.forEach(version => {
					versions[version.source] = version;
				});
				props.close(versions)
			})
			.catch(err => {console.log(err)})
	}

	componentDidMount() {
		this.props.initialize({
			ios_bundle_version: this.props.data.ios && this.props.data.ios.value,
			android_version_name: this.props.data.android && this.props.data.android.value
		})
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
					<div className={"row margin-bottom-32"}>
						<div className={"col"}>
							<strong className={"medium"}>The GHIN Mobile Apps will display a pop-up when the golfer accesses the app and the version number of the installed app is lower than the server version number.</strong>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor={'address.city'}>iOS Bundle Short Version <span>*</span></label>
							<Field component={InputField}
								   type="text"
								   validate={[required]}
								   name={'ios_bundle_version'}
								   id={'ios_bundle_version'} />

						</div>
					</div>
					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor={'address.city'}>Android Version Name <span>*</span></label>
							<Field component={InputField}
								   type="text"
								   validate={[required]}
								   name={'android_version_name'}
								   id={'android_version_name'} />

						</div>
					</div>
                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={() => this.props.close(null)}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
						<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Save</button>
                    </div>
                </div>

				</form>
			</Fragment>
		);
	}
}


EditMobileVersionForm = reduxForm({
	form: 'editMobileVersionForm',
	destroyOnUnmount: true
})(EditMobileVersionForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditMobileVersionForm));
