import React, {Component} from 'react';
import Modal from "react-modal";

class SuccessModal extends Component {

	constructor(props) {
		super(props);
		this.onConfirm = this.onConfirm.bind(this);
	}

	onConfirm() {
		this.props.onConfirmAction();
	}

	render() {
		return(
			<Modal
				isOpen={this.props.openModal}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={true}
				shouldFocusAfterRender={false}
			>
				<div className="modal__container success narrow">
					<div className="modal__head">
						<div className={"success-icon"}>

							<i className={`material-icons-outlined ${this.props.warning ? 'warning' : ''}`}>{this.props.warning ? 'find_in_page' : 'check'}</i>
						</div>
						<button className="modal__close" onClick={() => this.props.closeModal()}><i className="material-icons-outlined">clear</i></button>
					</div>

					<div className="modal__body">
						<h2 className="modal__title">{this.props.warning ? this.props.warningTitle : 'Success!'}</h2>
						{this.props.golfer && <>
              <p>{this.props.golfer.first_name} {this.props.golfer.middle_name} {this.props.golfer.last_name} has been added successfully.</p>
              <p style={{'margin-top': '10px'}}>GHIN Number: <span style={{fontWeight: '700'}}>{this.props.golfer.id}</span></p>
            </>}
            {this.props.club && <p>{this.props.club.club.club_name} has been added successfully.</p>}
            {this.props.association && <p>{this.props.association.association.name} has been added successfully.</p>}
						{this.props.count >= 0 && <p> {this.props.count} push notifications have been approved successfully.</p>}
						{this.props.successfully_approved >= 0 && <p> {this.props.successfully_approved} push notifications have been approved successfully.</p>}
						{this.props.unsuccessfully_approved >= 0 && <p> {this.props.unsuccessfully_approved} push notifications could not be approved.</p>}
						{this.props.message && typeof this.props.message === "object" &&
            <>
              {this.props.message}
              <br></br>
              <p>To export these records for review, use the button below.</p>
              {this.props.club_activation &&
              <p>Golfer records are currently being processed and will be emailed to you ONLY when there are records for you to review.</p>
              }
            </>
            }
						<div className="actions" style={this.props.golfer ? {'margin-top': '20px'} : {}}>
							<div className="action">
								{this.props.children}
							</div>
							<div className="action">
								{this.props.mainLabel && <button type="submit" onClick={() => {this.props.onMainAction()}} className="btn fill blue">{this.props.mainLabel}</button>}
							</div>
							<div className="action">
								{this.props.secundaryLabel && <button type="button" onClick={() => {this.props.onSecundaryAction()}} className="btn ">{this.props.secundaryLabel}</button>}
							</div>
              <div className="action">
                {this.props.specialButton && <button type="button" onClick={(e) => {e.preventDefault(); window.location.href=this.props.specialButton}} className="btn">Export Records for Review</button>}
              </div>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

export default SuccessModal;
