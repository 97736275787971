import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';

import Header from '../shared/Header';
import Navigation from '../shared/Navigation';
import BreadCrumbsNav from '../shared/BreadCrumbsNav';
import Footer from '../shared/Footer';

import AddGolferModal from '../Modals/AddGolferModal';
import AddNewClubModal from '../Modals/AddNewClubModal';
import AddNewAssociationModal from '../Modals/AddNewAssociationModal';

export default class Home extends Component {

	constructor(props) {

		super(props);
	
		this.state = {
          AddGolferModalIsOpen: false,
          AddClubModalIsOpen: false,
          AddAssocModalIsOpen: false
		};
	
        this.openGolferAddModal = this.openGolferAddModal.bind(this);
        this.openClubAddModal = this.openClubAddModal.bind(this);
        this.openAssocAddModal = this.openAssocAddModal.bind(this);

		this.closeModal = this.closeModal.bind(this);

	}

	openGolferAddModal() {
		this.setState({AddGolferModalIsOpen: true});
    }
    
    openClubAddModal() {
		this.setState({AddClubModalIsOpen: true});
    }
    
    openAssocAddModal() {
		this.setState({AddAssocModalIsOpen: true});
	}

	closeModal(data) {
		if(data && data.type === "golfer") {
			this.props.history.push(`/manage/association/${data.golfer.association_id}/club/${data.golfer.club_id}/golfer/${data.golfer.id}`);
		}
		if(data && data.type === "club"){
			this.props.history.push(`/manage/association/${data.club.club.golf_association_id}/club/${data.club.club.id}/account`);
		}
		this.setState({
            AddGolferModalIsOpen: false,
            AddClubModalIsOpen: false,
            AddAssocModalIsOpen: false
        });
	}

	componentWillMount() {
        Modal.setAppElement('body');
    }

	render() {
		return (
            <Fragment>
                <Header />
				<Navigation />
				<BreadCrumbsNav />

                    <main>
                        <div className="container narrow">

                            <h2 className="page__title">Welcome USGA Admin</h2>

                            <ul className="home__modal-triggers">
                                <li><button onClick={this.openGolferAddModal} className="home__modal-trigger"><i className="material-icons-outlined">add_circle_outline</i>Add Golfer</button></li>
                                <li><button onClick={this.openClubAddModal} className="home__modal-trigger"><i className="material-icons-outlined">add_circle_outline</i>Add Club</button></li>
                                <li><button onClick={this.openAssocAddModal} className="home__modal-trigger"><i className="material-icons-outlined">add_circle_outline</i>Add Association</button></li>
                            </ul>

                        </div>
                    </main>

					<Modal
						isOpen={this.state.AddGolferModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
						<AddGolferModal closeModal={(data)=>{this.closeModal(data)}} />
					</Modal>

                    <Modal
						isOpen={this.state.AddClubModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
						<AddNewClubModal closeModal={(data)=>{this.closeModal(data)}} />
					</Modal>

                    <Modal
						isOpen={this.state.AddAssocModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
						<AddNewAssociationModal closeModal={()=>{this.closeModal()}} />
					</Modal>

				<Footer />
            </Fragment>
		);
	}
}
