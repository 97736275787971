import React, {Component, Fragment} from 'react';
import {Field, getFormValues} from "redux-form";
import {InputField} from "../../inputs/InputField";
import {
	customRequired,
	isNumeric,
	required,
	totalScoreValue
} from "../../inputs/validations";
import {connect} from "react-redux";

class ManualCourseEditForm extends Component {


	changeTotal(event, newValue, prevValue, fieldName, totalFieldName) {
		if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
			let valueToChange = parseFloat(this.props.dynamicSelector(fieldName)) || 0;
			this.props.change(totalFieldName, valueToChange);
		}
		if (newValue.length === 1) {
			if (parseFloat(newValue) === 0 || !parseFloat(newValue)) {
				event.preventDefault();
				return;
			}
			let valueToChange = parseFloat(this.props.dynamicSelector(fieldName)) || 0;
			this.props.change(totalFieldName, valueToChange + parseFloat(newValue));
		}
		if (fieldName !== 'backSlope' && fieldName !== 'frontSlope' && fieldName !=='slope') {
			if(newValue && ((newValue.length === 3 && !/^[0-9]{1,2}([.])?$/.test(newValue)) || (newValue.length === 2 && !/^\d+$/.test(newValue)) || (newValue.length > 3 && !/^[0-9]{1,2}([.][0-9]{1})?$/.test(newValue)))){
				event.preventDefault();
			} else {
				if( (newValue >=2) && (/^[0-9]{1,2}([.])?$/.test(newValue) || /^[0-9]{1,2}([.][0-9]{1})?$/.test(newValue))) {
					let valueToChange = parseFloat(this.props.dynamicSelector(fieldName)) || 0;
					if(parseInt(valueToChange + parseFloat(newValue)) === parseFloat(valueToChange + parseFloat(newValue))) {
						valueToChange = parseInt(valueToChange + parseFloat(newValue))
					} else {
						valueToChange = parseFloat(valueToChange + parseFloat(newValue)).toFixed(1);
					}
					this.props.change(totalFieldName, valueToChange );
				}
			}

		} else {
			if(newValue && (newValue.length > 3 || !/^[0-9]{1,3}?$/.test(newValue))){
				event.preventDefault();
			}
			if(newValue.length >= 1 && newValue.length <=3) {
				let valueToChange = parseFloat(this.props.dynamicSelector(fieldName)) || 0;
				this.props.change(totalFieldName, Math.floor((valueToChange + parseFloat(newValue))/2));
			}
		}

	}

	componentDidMount() {
		this.props.change('asd', 'asd')
	}


	render() {
		const {formValues} = this.props;
		if(!formValues) return null;
		return (
			<Fragment>
				{!this.props.asyncSelect && <div className="row">
					<div className="col">
						<label  htmlFor={'name'}>Course Name <span>*</span></label>
						<Field validate={[required]} component={InputField} disabled={(this.props.course ? true : false) || this.props.forcedManual} type="text" name={'course_name_label'} id={'course_name_label'}/>
					</div>
				</div>}

				<div className="row">
					<div className="col "></div>
					<div className="col  is-1-of-4">Score</div>
					<div className="col  is-1-of-4">CR</div>
					<div className="col  is-1-of-4">Slope</div>
				</div>

				<div className="row">
					<div className="col jc-c ai-fe ">Front 9</div>
					<div className="col  is-1-of-4">
						<Field disabled={(this.props.score.number_of_holes === 9 ? false : (formValues.adjusted_gross_score && (!formValues.front9 && !formValues.back9))) || this.props.forcedManual}
							   component={InputField}
							   type="text"
							   name={(this.props.score.number_of_holes === 9 && this.props.score.is_manual) ? 'adjusted_gross_score' : 'front9'}
							   id={(this.props.score.number_of_holes === 9 && this.props.score.is_manual) ? 'total' : 'front9'}
							   onChange={(event, newValue, prevValue) => {
									//this.props.changeWithForm('totalForm', 'front9', newValue);
									if(!(this.props.score.number_of_holes === 9 && this.props.score.is_manual)) {
										if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
											let backValue = parseInt(this.props.dynamicSelector('back9')) || 0;
											if (this.props.score.number_of_holes === 9 && this.props.score.is_manual) {
												this.props.change('adjusted_gross_score', newValue);
											} else {
												this.props.change('adjusted_gross_score', backValue);
											}
											//this.props.changeWithForm('totalForm', 'total', backValue);
										}

										if (newValue.length === 1) {
											if (parseInt(newValue) === 0) {
												event.preventDefault();
												return;
											}

											let backValue = parseInt(this.props.dynamicSelector('back9')) || 0;
											if (!(this.props.score.number_of_holes === 9 && this.props.score.is_manual)) this.props.change('adjusted_gross_score', backValue + parseInt(newValue));
											//this.props.changeWithForm('totalForm', 'total', backValue + parseInt(newValue));
										}
										if (newValue.length > 2) {
											event.preventDefault();
											return;
										}
										if (newValue.length === 2) {
											let backValue = parseInt(this.props.dynamicSelector('back9')) || 0;
											if (!(this.props.score.number_of_holes === 9 && this.props.score.is_manual)) this.props.change('adjusted_gross_score', backValue + parseInt(newValue));
											//this.props.changeWithForm('totalForm', 'total', backValue + parseInt(newValue));
											//document.getElementsByClassName('back9')[0].focus();
										}
									} else {
										if(newValue && !/^[0-9]{1,2}?$/.test(newValue)) {
											event.preventDefault();
										}
									}
								}}/>
					</div>
					<div className="col  is-1-of-4">
						<Field onChange={(e, newValue, prevValue) => {
									if (!(this.props.score.number_of_holes === 9 && this.props.score.is_manual)) {
										this.changeTotal(e, newValue, prevValue, 'backCr', 'cr',null);
									} else {
										if(newValue && !/^[0-9]{1,2}\.?[0-9]*$/.test(newValue)) {
											e.preventDefault();
										}
									}
								}}
							   disabled={(this.props.score.number_of_holes === 9 ? false : (formValues.cr && !formValues.frontCr && !formValues.backCr)) || this.props.forcedManual}
							   component={InputField}
							   type="text"
							   name={(this.props.score.number_of_holes === 9 && this.props.score.is_manual) ? 'cr' : 'frontCr'}
							   id={'frontCr'}/>
					</div>
					<div className="col  is-1-of-4">
						<Field onChange={(e, newValue, prevValue) => {
									if (!(this.props.score.number_of_holes === 9 && this.props.score.is_manual)) {
										this.changeTotal(e, newValue, prevValue, 'backSlope', 'slope',null);
									} else {
										if(newValue && !/^[0-9]{1,3}?$/.test(newValue)) {
											e.preventDefault();
										}
									}
								}}
							   disabled={(this.props.score.number_of_holes === 9 ? false : (formValues.slope && !formValues.frontSlope && !formValues.backSlope)) || this.props.forcedManual}
							   component={InputField}
							   type="text"
							   name={(this.props.score.number_of_holes === 9 && this.props.score.is_manual) ? 'slope' : 'frontSlope'}
							   id={'frontSlope'}/>
					</div>
				</div>

				<div className="row">
					<div className="col jc-c ai-fe ">Back 9</div>
					<div className="col  is-1-of-4">
						<Field disabled={((this.props.score.number_of_holes === 9 ? formValues.tee_set_side !== "F9" : (formValues.adjusted_gross_score && (!formValues.front9 && !formValues.back9))) || this.props.forcedManual) || (this.props.score.number_of_holes === 9 && this.props.score.is_manual)}
							   className={'back9'}
							   component={InputField}
							   type="text"
							   name={'back9'}
							   id={'asd'}
							   onChange={(event, newValue, prevValue) => {
									//this.props.changeWithForm('totalForm', 'back9', newValue);
									if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
										let frontValue = parseInt(this.props.dynamicSelector('front9')) || 0;
										this.props.change('adjusted_gross_score', frontValue);
										//this.props.changeWithForm('totalForm', 'total', frontValue);
									}
									if (newValue.length === 1) {
										if (parseInt(newValue) === 0) {
											event.preventDefault();
											return;
										}
										let frontValue = parseInt(this.props.dynamicSelector('front9')) || 0;
										this.props.change('adjusted_gross_score', frontValue + parseInt(newValue));
										//this.props.changeWithForm('totalForm', 'total', frontValue + parseInt(newValue));
									}
									if (newValue.length > 2) {
										event.preventDefault();
									}
									if(newValue.length === 2) {
										let frontValue = parseInt(this.props.dynamicSelector('front9')) || 0;
										this.props.change('adjusted_gross_score', frontValue + parseInt(newValue));
										//this.props.changeWithForm('totalForm', 'total', frontValue + parseInt(newValue));
									}
								}}/>
					</div>
					<div className="col  is-1-of-4">
						<Field onChange={(e, newValue, prevValue) => this.changeTotal(e, newValue, prevValue, 'frontCr', 'cr',null)}
							   disabled={(this.props.score.number_of_holes === 9 ? true : (formValues.cr && (!formValues.frontCr && !formValues.backCr))) || (this.props.score.number_of_holes === 9 && this.props.score.is_manual)  || this.props.forcedManual}
							   component={InputField}
							   type="text"
							   name={'backCr'}
							   id={'backCr'}/>
					</div>
					<div className="col  is-1-of-4">
						<Field onChange={(e, newValue, prevValue) => this.changeTotal(e, newValue, prevValue, 'frontSlope', 'slope',null)}
							   disabled={(this.props.score.number_of_holes === 9 ? true : (formValues.slope && (!formValues.frontSlope && !formValues.backSlope))) || (this.props.score.number_of_holes === 9 && this.props.score.is_manual)  || this.props.forcedManual}
							   component={InputField}
							   type="text"
							   name={'backSlope'}
							   id={'backSlope'}/>
					</div>
				</div>

				<div className="row">
					<div className="col jc-c ai-fe">Total 18</div>
					<div className="col  is-1-of-4">
						<Field disabled={((formValues.front9 || formValues.back9)) || (this.props.score.number_of_holes === 9 && this.props.score.is_manual)}
							   component={InputField}
							   onChange={(e, newValue, prevValue) => {
								   if(newValue && !/^[0-9]{1,3}?$/.test(newValue)) {
									   e.preventDefault()
								   }
							   }}
							   hideErrorMessages={true}
							   validate={!(this.props.score.number_of_holes === 9 && this.props.score.is_manual) && [customRequired({fieldName: 'Total Score'}), isNumeric, totalScoreValue()]}
							   type="text"
							   name={(this.props.score.number_of_holes === 9 && this.props.score.is_manual) ? 'score' : 'adjusted_gross_score'}
							   id={'adjusted_gross_score'}/>
					</div>
					<div className="col  is-1-of-4">
						<Field disabled={(formValues.frontCr ) || (formValues.backCr) || (this.props.score.number_of_holes === 9 && this.props.score.is_manual) || this.props.forcedManual}
							   validate={!(this.props.score.number_of_holes === 9 && this.props.score.is_manual) && [customRequired({fieldName: 'Total CR'}), isNumeric]}
							   component={InputField}
							   onChange={(e, newValue, prevValue) => {
								   if(newValue && !/^[0-9]{1,3}\.?[0-9]*$/.test(newValue)) {
									   e.preventDefault();
								   }
							   }}
							   hideErrorMessages={true}
							   type="text"
							   name={(this.props.score.number_of_holes === 9 && this.props.score.is_manual) ? 'courseRating' : 'cr'}
							   id={'cr'}/>
					</div>
					<div className="col  is-1-of-4">
						<Field disabled={(formValues.frontSlope) || (formValues.backSlope) || (this.props.score.number_of_holes === 9 && this.props.score.is_manual) || this.props.forcedManual}
							   validate={!(this.props.score.number_of_holes === 9 && this.props.score.is_manual) && [customRequired({fieldName: 'Total Slope'}), isNumeric]}
							   component={InputField}
							   onChange={(e, newValue, prevValue) => {
								   if(newValue && !/^[0-9]{1,3}?$/.test(newValue)) {
									   e.preventDefault()
								   }
							   }}
							   hideErrorMessages={true}
							   type="text"
							   name={(this.props.score.number_of_holes === 9 && this.props.score.is_manual) ? 'slopeRating' : 'slope'}
							   id={'slope'}/>
					</div>
				</div>
				<div className={'row'}></div>

			</Fragment>
		);
	}
}


function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		formValues: getFormValues('editScoreForm')(state)
	};
}

export default connect(mapStateToProps, null)(ManualCourseEditForm);
