import React, {Component, Fragment} from 'react';
import {reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import AssociationService from "../../services/api/association";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import HtmlEditor from "../shared/HtmlEditor";

const dataTokens = [
	'Golfer First Name',
	'Golfer Last Name',
	'Golfer Full Name',
	'Association Name',
	'Handicap Index',
	'GHIN Number',
	'Association Address 1',
	'Association Address 2',
	'Association City',
	'Association Postal Code',
	'Association Email Address',
	'Association Phone',
	'Association State',
	'Association Country',
	'Low Handicap Index',
	'Revision Date',
	'Home Club'
];

class EditSalutationForm extends Component {

	constructor(props) {
			super(props);
			this.state = {
				tokens: false,
				email_settings: {
					show_sub_header: this.props.emailSettings.show_sub_header,
              show_salutation: this.props.emailSettings.show_salutation,
              salutation: this.props.emailSettings.salutation
				}
			}
	}
	componentDidMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	selectToken(tokenRaw, e) {
    const token = `[${tokenRaw}]`;
    this.htmlEditor.appendText(token)
  }

	onSave() {
		let email_settings = {...this.state.email_settings};
		if (email_settings.show_salutation) {
      email_settings.salutation = this.htmlEditor.getHtml();
    }
		delete email_settings.id;
		this.setState({
      email_settings: {
        ...this.state.email_settings,
        salutation: email_settings.salutation
      }
    });
		return AssociationService.editEmailSettings(this.props.associationId, this.props.emailSettings.id, {email_settings: email_settings})
			.then(res => {
				this.props.addAlert({
					type:'success',
					message: 'Sub-Header & Salutation has been successfully edited'
				});
				this.props.closeModal(res.email_settings);
			})
			.catch(err => {
				console.error(err);
			})
	}

	render() {
		return (
			<Fragment>
	      <div className="row">
					<div className="col is-full">
	          <label>Show Sub-Header?</label>
						<div className="radio__container">
							<input
								onChange={ () => { this.setState({email_settings: {...this.state.email_settings, show_sub_header: true}}) }}
								checked={this.state.email_settings.show_sub_header}
	              id="show_sub_header"
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
	          <label htmlFor="show_sub_header">Yes</label>
							<input
								onChange={ () => { this.setState({email_settings: {...this.state.email_settings, show_sub_header: false}}) }}
								checked={!this.state.email_settings.show_sub_header}
	              id="dont_show_sub_header"
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
	          <label htmlFor="dont_show_sub_header">No</label>
						</div>
					</div>
				</div>
	      <div className="row">
					<div className="col is-full">
	          <label>Show Salutation?</label>
						<div className="radio__container">
							<input
								onChange={ () => { this.setState({email_settings: {...this.state.email_settings, show_salutation: true}}) }}
								checked={this.state.email_settings.show_salutation}
	              id="show_salutation"
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
	          <label htmlFor="show_salutation">Yes</label>
							<input
								onChange={ () => { this.setState({email_settings: {...this.state.email_settings, show_salutation: false}}) }}
								checked={!this.state.email_settings.show_salutation}
	              id="dont_show_salutation"
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
	          <label htmlFor="dont_show_salutation">No</label>
						</div>
					</div>
				</div>
	      {this.state.email_settings.show_salutation &&
				<Fragment>
					<div className="col is-full">
              <label>Salutation</label>
					</div>
					<div className={"row"}>
						<div className={"col auto jc-c"}>
							<button type={'button'} onClick={() => this.setState({tokens: !this.state.tokens})} className="btn lnk">Tokens<span className={`tokens-toggle ${this.state.tokens ? 'opened' : 'closed'}`}/> </button>
						</div>
					</div>
					{this.state.tokens && <div className="row">
						<div className="col is-full">
							<div className={'data-tokens'}>
								{dataTokens.map((token) => {
									return <button type={'button'} key={token} onClick={(e) => this.selectToken(token, e)} className={'button token'}>{token}</button>
								})}
							</div>
						</div>
					</div>}
          <div className="row">
            <div className="col">
              <HtmlEditor
                ref={(ref) => this.htmlEditor = ref}
                html={this.state.email_settings.salutation}
              />
            </div>
          </div>
	      </Fragment>}
        <div className={'row'}>
          <div className={'col is-1-of-2'}>
						<button className='btn fill gray' onClick={() => this.props.closeModal()}>Cancel</button>
          </div>
          <div className={'col is-1-of-2'}>
						<button className='btn fill blue' onClick={() => this.onSave()}>Save</button>
          </div>
        </div>
			</Fragment>)
	}
}


EditSalutationForm = reduxForm({
	form: 'editSalutationForm',
	destroyOnUnmount: true
})(EditSalutationForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditSalutationForm));
