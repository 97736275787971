import Design from "./Design"
import NewsletterPreviewAndTest from "./NewsletterPreviewAndTest"
import { Fragment } from "react";
import { Redirect, Route, Switch, withRouter, NavLink } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Campaigns from "../Account/ERevision/Campaigns/Campaigns";

function Newsletter(props) {
  return (
    <Fragment>
      <div className="vertical-tabs__container row">
        <ul className="vertical-tabs col">
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/design`}>Design</NavLink></li>
          {process.env.REACT_APP_TYPE !== "hv" && <Fragment>
            <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/statistics`}>Statistics</NavLink></li>
            <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/preview-and-tests`}>Preview & Test</NavLink></li>
          </Fragment>}
        </ul>
        <div className="vertical-tabs__panel col">
          <Switch>
            <Redirect exact from={`${props.match.url}`} to={`${props.match.url}/design`} />
            <Route path={`${props.match.path}/design`} component={() =>
              <Design
                canEditAssociation={props.canEditAssociation}
                associationId={props.associationId} />} />
            {process.env.REACT_APP_TYPE !== "hv" && <Route path={`${props.match.path}/statistics`} component={() =>
              <Campaigns
                associationId={props.associationId}
                canEditAssociation={props.canEditAssociation} />} />}
            {process.env.REACT_APP_TYPE !== "hv" && <Route path={`${props.match.path}/preview-and-tests`} component={() =>
              <NewsletterPreviewAndTest
                canEditAssociation={props.canEditAssociation}
                associationId={props.associationId} />} />}
          </Switch>
        </div>
      </div>
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    userType: state.user.access.user_type,
  };
}

export default compose(withRouter,connect(mapStateToProps))(Newsletter);
