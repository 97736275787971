import React, {Component, Fragment} from 'react';
import GhinTable from "../shared/GhinTable";
import ScoreService from "../../services/api/score";
import moment from "moment";
import ReactTooltip from "react-tooltip";

export default class ScoreLogs extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			logs: [],
			show_column: false,
			first_column: null,
			second_column: null,
		}
	}
	componentDidMount() {
		ScoreService.getLogs(this.props.score.id)
			.then(res => {
				this.setState({
					loading: false,
					logs: res.scores.map(score => {
						return{
							...score,
							played_at: moment(score.played_at).format('MM/DD/YYYY'),
							edited_date: moment(score.edited_date).format('MM/DD/YY HH:mm:ss'),
							cr_and_slope: score.course_rating + '/' + score.slope_rating,
							course_and_tee: score.course_display_name + (score.tee_name ? ' - ' + score.tee_name : ''),
							front9_adjusted: score.front9_adjusted,
							back9_adjusted: score.back9_adjusted
						}
					}),
					show_column: (res.scores[0].front9_adjusted) ? true : false
				});
			})
			.catch(err => {console.log(err)});
	}

	render() {
		let total_score_width = this.state.show_column ? 'col_min' : 'col_date'
		let columns = [
			{
				Header: 'Type',
				accessor: 'score_type_display_full',
				thClassName: 'table-align-center',
				className: 'table-align-center',
				colClassName: 'col_id'
			},
			{
				Header: 'Date Played',
				accessor: 'played_at',
				colClassName: 'col_date',
				className: 'table-align-center'
			},
			{
				Header:'Front 9',
				accessor: 'front9_adjusted',
				thClassName: 'table-align-center',
				className: 'table-align-center',
				colClassName: 'col_holes',
				show: this.state.show_column
			},
			{
				Header: 'Back 9',
				accessor: 'back9_adjusted',
				thClassName: 'table-align-center',
				className: 'table-align-center',
				colClassName: 'col_holes',
				show: this.state.show_column
			},
			{
				Header: 'Total Score',
				accessor: 'adjusted_gross_score',
				thClassName: 'table-align-center',
				className: 'table-align-center',
				colClassName: total_score_width
			},
			{
				Header: 'C.R./Slope',
				accessor: 'cr_and_slope',
				Cell: props => <Fragment>{parseFloat(props.original.course_rating).toFixed(1)} / {props.original.slope_rating}</Fragment>,
				thClassName: 'table-align-center',
				className: 'table-align-center',
				colClassName: 'col_date',
			},
			{
				Header: <Fragment>
					PCC
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='pcc'><i className="material-icons v-mid m-left white">info</i></a>
					<ReactTooltip id='pcc' className="table-head__tooltip" type="light" place='top' effect={'float'}>
						<strong>Playing Conditions Calculation </strong>
						The playing conditions calculation determines whether playing conditions on the day differed from normal conditions to the extent that an adjustment is needed to compensate. It is a statistical procedure that compares the scores submitted by players on the day against expected scoring patterns.
					</ReactTooltip>
				</Fragment>,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'pcc').length > 0 ? props.original.adjustments.filter(a => a.type === 'pcc')[0].display : '-'}</Fragment>,
				accessor: 'pcc',
				sortable: false,
				className: 'table-align-center',
				thClassName: 'table-align-center',
				colClassName: 'col_status',
			},
			{
				Header: <Fragment>
					Diff.
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='diff'><i className="material-icons v-mid m-left white">info</i></a>
					<ReactTooltip id='diff' className="table-head__tooltip" type="light" place='top' effect={'float'}>
						<strong>Score Differential </strong> = (113/Slope Rating) * (Adjusted Gross Score – Course Rating – PCC). ESR and Committee Adjustments are applied to the previously calculated score differential.
					</ReactTooltip>
				</Fragment>,
				Cell: props => parseFloat(props.original.differential).toFixed(1),
				accessor: 'differential',
				className: 'table-align-center',
				thClassName: 'table-align-center',
				colClassName: 'col_status',
			},
			{
				Header: <Fragment>
					ESR
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='esr'><i className="material-icons v-mid m-left white">info</i></a>
					<ReactTooltip id='esr' className="table-head__tooltip" type="light" place='top'effect={'float'}>
						<strong>Exceptional Score Reduction </strong>
						When an exceptional score is posted to a player’s scoring record, the Handicap Index will be reduced in accordance with the exceptional score adjustment table.
					</ReactTooltip>
				</Fragment>,
				accessor: 'esr',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'esr').length > 0 ? props.original.adjustments.filter(a => a.type === 'esr')[0].display : 'N/A'}</Fragment>,
				className: 'table-align-center',
				thClassName: 'table-align-center',
				colClassName: 'col_status',
			},
			{
				Header: <Fragment>
					Adj.
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='adj'><i className="material-icons v-mid m-left white">info</i></a>
					<ReactTooltip  id='adj' className="table-head__tooltip" type="light" place='top' effect={'float'}>
						<strong>Committee Adjustment</strong> When an adjustment is applied by the Handicap Committee, it is intended to provide a better reflection of a player’s demonstrated ability.
					</ReactTooltip>
				</Fragment>,
				accessor: 'adj',
				sortable: false,
				Cell: props => <Fragment>{props.original.adjustments.filter(a => a.type === 'committee').length > 0 ? props.original.adjustments.filter(a => a.type === 'committee')[0].display : '-'}</Fragment>,
				className: 'table-align-center',
				thClassName: 'table-align-center',
				colClassName: 'col_status',
			},
			{
				Header: 'Course Name / Tee',
				accessor: 'course_and_tee',
			},
			{
				Header: 'Date Edited',
				accessor: 'edited_date',
				colClassName: 'col_date_and_time',
			},
			{
				Header: 'Edited By User',
				accessor: 'edited_by',
			},
		];
		return (
            <Fragment>

                <div className="modal__container ultra-wide">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">bookmark</i></div>
                        <h2 className="modal__title">Score Edit Log</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
						<GhinTable
							idKey={'id'}
							style={this.state.logs.length > 10 && {height: '452px', overflowY: 'auto'}}
							columns={columns}
							loading={this.state.loading}
							hideSelect={true}
							data={this.state.logs}
							ref={(r) => this.table = r}
							hidePagination={true}
							defaultSorted={[{
								id: 'Name',
								desc: false
							}]}
							sortable={true}
						/>
						<div className="row margin-top-16">
							<div className="col">
								<p><strong>Note:</strong> ESR data is not available for scores posted prior to 09/23/2020 and will display as "N/A".</p>
							</div>
						</div>
						<div className="row">
							<div className="col push-right is-1-of-6">
								<button onClick={() => this.props.closeModal()} className="btn fill blue">Close</button>
							</div>
						</div>
                    </div>

                </div>

            </Fragment>
		);
	}
}
