import React, {Component, Fragment} from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import HtmlEditor from "../shared/HtmlEditor";
const dataTokens = [
	'Golfer First Name',
	'Golfer Last Name',
	'Golfer Full Name',
	'Association Name',
	'Handicap Index',
	'GHIN Number',
	'Association Address 1',
	'Association Address 2',
	'Association City',
	'Association Postal Code',
	'Association Email Address',
	'Association Phone',
	'Association State',
	'Association Country',
	'Low Handicap Index',
	'Revision Date',
	'Home Club'
];
export default class EditContentBlockForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
        	tokens: false
		}
    }

	selectToken(tokenRaw, e) {
		const token = `[${tokenRaw}]`;
		this.htmlEditorRef.appendText(token)

	}

    onSave() {
        const html = this.htmlEditorRef.getHtml();
        this.props.closeModal(html);
    }

    render() {
		return (
            <Fragment>

				<div className={"row"}>
					<div className={"col auto jc-c"}>
						<button onClick={() => this.setState({tokens: !this.state.tokens})} className="btn lnk">Tokens<span className={`tokens-toggle ${this.state.tokens ? 'opened' : 'closed'}`}/> </button>
					</div>
				</div>
				{this.state.tokens && <div className="row">
					<div className="col is-full">
						<div className={'data-tokens'}>
							{dataTokens.map((token) => {
								return <button key={token} onClick={(e) => this.selectToken(token, e)} className={'button token'}>{token}</button>
							})}
						</div>
					</div>
				</div>}

				<div className="row">
                    <div className="col is-full">
                        <HtmlEditor html={this.props.content} ref={(ref) => this.htmlEditorRef = ref}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={() => this.props.closeModal(this.props.content)}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
                        <button type={'submit'} ref={'submit'} className="btn fill blue" onClick={this.onSave.bind(this)}>Save</button>
                    </div>
                </div>

            </Fragment>
		);
	}
}
