import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';

import AddContentBlockForm from '../Forms/AddContentBlockForm';

export default class AddContentBlockModal extends Component { 

  render() {
    return (
            <Modal
              isOpen={true}
              onRequestClose={() => this.props.closeModal()}
              contentLabel="Modal"
              portalClassName="modal"
              overlayClassName="modal__overlay"
              className="modal__content"
              bodyOpenClassName="modal--is-open"
              htmlOpenClassName="prevent-scroll"
              shouldCloseOnOverlayClick={true}
              shouldFocusAfterRender={false}
            >
              <Fragment>

                  <div className="modal__container narrow">

                      <div className="modal__head">

                          <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
                          <h2 className="modal__title">Add Content Block</h2>
                          <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons outlined">clear</i></button>

                      </div>  
                    
                      <div className="modal__body">

                          <AddContentBlockForm onSubmit={(data) => this.props.onSubmit(data)} closeModal={(data) => this.props.closeModal(data)}></AddContentBlockForm>

                      </div>

                  </div>

              </Fragment>
            </Modal>
    );
  }
}