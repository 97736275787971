import { Fragment } from "react";

export default function TransactionInformationModal(props) {
  
  const transaction = props.transaction;
  if (transaction === null)
    return null;
  
  const paymentDetails = transaction.payment_details;
  const club_membership_data = paymentDetails.membership_data;
  const association_membership_data = paymentDetails.golf_association_rate;
  const transaction_info = paymentDetails.transaction_info;
  
  const member_type = `${association_membership_data.membership_code} - ${association_membership_data.rate_type} Membership`;
  
  const failedStatus = transaction.status !== 'SUCCESS';
  return (
    <Fragment>

      <div className="modal__container semi-narrow">
        <div className="modal__head">
          <div className="modal__icon"><i className="material-icons-outlined">attach_money</i></div>
          <h2 className="modal__title">Transaction Information</h2>
          <button className="modal__close" onClick={() => {props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
        </div>

        <div className="modal__body">
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Payment Identifier:</strong></div>
            <div className="col is-3-of-5">{transaction.id}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Payment Description:</strong></div>
            <div className="col is-3-of-5">Online Join & Renew</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>member_name:</strong></div>
            <div className="col is-3-of-5">{transaction.full_name}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>membership_name:</strong></div>
            <div className="col is-3-of-5">{transaction.club_name}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>uid:</strong></div>
            <div className="col is-3-of-5">{transaction.transaction_id}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>membership_type_name:</strong></div>
            <div className="col is-3-of-5">{club_membership_data.name}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>member_type:</strong></div>
            <div className="col is-3-of-5">{member_type}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>transaction_type:</strong></div>
            <div className="col is-3-of-5">{`${transaction.transaction_type} (${props.transactionTypes[transaction.transaction_type]})`}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Includes Refund:</strong></div>
            <div className="col is-3-of-5">{transaction.refundable ? 'true' : 'false'}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Merch Trans Type Code:</strong></div>
            <div className="col is-3-of-5">{transaction.transaction_type}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Transaction Amount:</strong></div>
            <div className="col is-3-of-5">{`$${transaction.amount}`}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Date / Time:</strong></div>
            <div className="col is-3-of-5">{transaction.created_at}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Merch Trans Result Code:</strong></div>
            <div className="col is-3-of-5" style={failedStatus ? {color: 'red'} : {}}>{failedStatus ? 'declined' : 'approved'}</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Transaction Message:</strong></div>
            <div className="col is-3-of-5" style={failedStatus ? {color: 'red'} : {}}>
              {
                `${transaction.transaction_message} (AVS=${transaction_info.avsresponse}, CVV=${transaction_info.cvvresponse}, Response=${transaction.response_code})`
              }
            </div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Auth Code:</strong></div>
            <div className="col is-3-of-5">{transaction_info.authcode ? transaction_info.authcode : '-' }</div>
          </div>
          <div className="row">
            <div className="col is-2-of-5 ai-fe"><strong>Response Code:</strong></div>
            <div className="col is-3-of-5">{transaction.response_code}</div>
          </div>

          <div className="row no-margin">
							<div className="col">
								<button type='submit'  onClick={() => props.closeModal()} className="btn fill gray">Close</button>
							</div>
						</div>
        </div>
      </div>

    </Fragment>
  );
}
