import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import UsersService from "../../services/api/users";

export default class Terms extends Component {


	componentDidMount(){
		UsersService.accesses()
	}
	render() {
		return (
            <Fragment>
				<div className="simple__page">
					<div className="container">
						<p className="text-align-center">UNITED STATES GOLF ASSOCIATION</p>
						<p className="text-align-center">TERMS OF SERVICE AGREEMENT</p>
						<p className="text-align-center">(Last Updated May 2018)<b><i></i></b></p>
						<p>Welcome to GHIN Technology Services (including GHIN.com, GHIN Kiosks, Tournament Pairing Program and USGA Tournament Management) distributed through the USGA’s (or its licensor’s) web sites and properties (“<b>USGA Web Sites</b>”) and their associated USGA mobile applications (“<b>USGA</b> <b>Mobile Apps</b>”), if any. &nbsp;The USGA Web Sites and the USGA Mobile Apps are herein collectively referred to as the “<b>USGA Products</b>”.</p>
						<p>PLEASE CAREFULLY READ THESE TERMS OF SERVICE (HEREAFTER, THE “<b>AGREEMENT</b>”).&nbsp; THIS AGREEMENT IS A LEGALLY BINDING AGREEMENT BETWEEN THE UNITED STATES GOLF ASSOCIATION (“<b>USGA</b>”) AND THE INDIVIDUAL ACCEPTING THIS AGREEMENT (“<b>AUTHORIZED USER</b>” OR “<b>YOU</b>” AND “<b>YOUR</b>”).&nbsp; This Agreement governs the access and use of THE USGA PRODUCTS.&nbsp;The USGA’s information collection and use policies with respect to user information are set forth in the <Link to={"./privacy-policy"}>Privacy Policy</Link>, which is incorporated herein by reference for all purposes.</p>
						<p>USE OF AND ACCESS TO THE USGA PRODUCTS BY AUTHORIZED USER IN ACCORDANCE WITH THIS AGREEMENT IS CONDITIONED UPON AUTHORIZED USER ACCEPTING THIS AGREEMENT BY CLICKING ON THE “I AGREE” CHECKBOX OR SIMILAR ATTESTATION UPON REGISTRATION AND/OR LOGIN.&nbsp; IF YOU ARE USING AND ACCESSING THE USGA PRODUCTS AS AN AUTHORIZED USER, YOUR CONTINUED USE OF AND ACCESS TO ANY USGA PRODUCT IS DEEMED TO BE ACCEPTANCE OF THIS AGREEMENT.&nbsp; YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER AND AUTHORITY TO ENTER INTO THIS AGREEMENT AND THAT IF YOU ARE PROVIDING ANY PERSONAL INFORMATION AT THE TIME OF REGISTRATION OR AT ANY TIME WHILE USING THE USGA PRODUCTS, YOU ARE 13 YEARS OF AGE OR OLDER. &nbsp;IF YOU ARE UNDER THE AGE OF 13, YOU ARE NOT PERMITTED TO PROVIDE ANY INFORMATION THROUGH THE USGA PRODUCTS.</p>
						<p>For purposes of this Agreement, the following definitions apply.</p>
						<p>“<b>Personal Information</b>” means any personally identifiable information and other potentially sensitive information, including, but not limited to, an individual’s name, employer, social security number, driver’s license number or state identification card number, account number, credit card, debit card or other payment instrument information, security code, access code or password, birthdate, address, email address or other contact information,&nbsp;GHIN number or other golfer identification number, and certain golf play statistics (such as where played) regarding such individual that can be used on its own or with other information to identify, contact, or locate a single individual, or to identify an individual in context.</p>
						<p>“<b>Registered User</b>” means an Authorized User who has access to the USGA Products by way of a user name and password or other access credentials (e.g., GGID).<b><i></i></b></p>
						<h3>1.&nbsp;USGA PRODUCT SUBSCRIPTION AND LICENSE</h3>
						<p>a.&nbsp;&nbsp;USGA Web Sites.&nbsp; Subject to the terms and conditions of this Agreement and Authorized User's compliance with this Agreement, USGA hereby grants to Authorized User during the Subscription Term (as defined below) a limited, personal, non-commercial, non-exclusive, non-transferrable, non-assignable, revocable access to the USGA Web Sites to which such Authorized User is permitted access.</p>
						<p>b.&nbsp;USGA Mobile Apps.&nbsp; Subject to the terms and conditions of this Agreement and Authorized User's compliance with this Agreement, USGA hereby grants to Authorized User during the Subscription Term (as defined below) a limited, personal, non-commercial, non-exclusive, non-transferrable, non-assignable, revocable license under copyright to reproduce a copy of the USGA Mobile App on Authorized User’s mobile device solely for personal use to access the functionalities to which such Authorized User is permitted access.</p>
						<h3>2.&nbsp;REGISTRATION AND USER ACCOUNT</h3>
						<p>a.&nbsp;Authorized User Credentials.&nbsp; To the extent Authorized User has access to any USGA Product as a Registered User, Authorized User acknowledges the access credentials are personal to Authorized User and Authorized User shall not share such access credentials with any other person for any purpose.</p>
						<h3>3.&nbsp;SUPPORT</h3>
						<p>USGA, through is suppliers and agents, may provide support to Authorized User for the USGA Products. &nbsp;USGA may, in its discretion, provide written materials in form of user guides, instruction manuals, quick reference guides, demonstration or tutorial videos or white papers (“<b>Documentation</b>”).&nbsp; To the extent such Documentation is provided to Authorized User, such Documentation shall be used by Authorized User solely to support use of the USGA Products in accordance with this Agreement and such Documentation shall not be reproduced, distributed, displayed or modified for any purpose.&nbsp; Authorized User shall destroy all copies of the Documentation upon termination of this Agreement.</p>
						<h3>4.&nbsp;TERM; TERMINATION.</h3>
						<p>This Agreement commences on the date Authorized User accepts this Agreement and shall remain in effect while Authorized User continues to access the USGA Products or the USGA terminates this Agreement (whichever is earlier) (“<b>Subscription Term</b>”).</p>
						<p>The USGA may terminate, change, suspend or discontinue any aspect of the USGA Products at any time with or without notice.&nbsp; Without limiting the foregoing, USGA may restrict, suspend or terminate Authorized User’s access or use of the USGA Products if USGA believes Authorized User is in breach of this Agreement or applicable law, or for any other reason without notice or liability. Upon termination of this Agreement for any reason, Authorized User must cease all use of the USGA Products. &nbsp;Termination of this Agreement shall not limit any of USGA’s rights or remedies at law or in equity.</p>
						<h3>5.&nbsp;PROPRIETARY RIGHTS</h3>
						<p>Authorized User acknowledges and agrees that USGA and/or its licensors own and retain all right, title, and interest in and to the USGA Products and any intellectual property contained therein, including without limitation, any and all, technology, software, code, user interfaces, trademarks and other items posted thereon or used in connection or associated with the USGA Products.</p>
						<p>Except as expressly granted in Section 1(b) with respect to the USGA Mobile Apps, no license or right under any intellectual property rights in the USGA Products are granted or implied by this Agreement.&nbsp; Specifically, Authorized User shall not (a) modify, port, translate, localize or create derivative works of the USGA Products or the intellectual property contained therein; (b) decompile, disassemble, reverse engineer or attempt to reconstruct, identify or discover any source code, underlying ideas, underlying user interface techniques or algorithms of the USGA Products or intellectual property contained therein by any means whatsoever, or disclose any of the foregoing; or (c) sell, lease, license, sublicense, copy, market, distribute or offer as a service the USGA Products or the intellectual property contained therein. Authorized User shall not remove, obscure or alter any copyright or trademark notices or any trademarks contained in the USGA Products.</p>
						<p>The trademarks, logos, service marks and trade names that may be displayed on or within the USGA Products are registered and unregistered trademarks of the USGA and other entities or persons (collectively, the “<b>Trademarks</b>”), and may not be used unless authorized in writing by the Trademark owner.&nbsp; Nothing contained in the USGA Products should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed on or within the USGA Products without the USGA’s written permission or that of the third party rights holder.</p>
						<h3>6.&nbsp;&nbsp;SUBMITTED MATERIALS</h3>
						<p>Unless specifically requested, the USGA does not solicit, nor does the USGA wish to receive, any confidential, secret or proprietary information or other material from Authorized User through the USGA Products, by e-mail or in any other way.&nbsp; Any information, proposals, requests, creative works, pictures, photographs, documents, letters, demos, ideas, suggestions, concepts, methods, systems, designs, drawings, renderings, plans, techniques or other materials score entries, and other non-personal information concerning golf events (collectively “<b>Submitted Materials</b>”) submitted, posted, uploaded or sent or transmitted on or through the USGA Products in any manner will be deemed not to be confidential or secret, and may be used by the USGA in any manner consistent with this Agreement.&nbsp; &nbsp;&nbsp;</p>
						<p>By submitting, uploading, posting or sending Submitted Materials through, to or on the USGA Products, Authorized User: (A) represent and warrant that either (i) Authorized User’s Submitted Materials are original to Authorized User and that no other party has any rights thereto or (ii) that Authorized User has the necessary licenses, rights, consents and permissions to use such Submitted Materials and use them in the USGA Products for the use of the USGA and its applicable service providers in accordance with this Agreement, and that any “moral rights” in the Submitted Materials have been waived; and (B) Authorized User grants to the USGA, Regional Associations, member clubs and Approved Technology Providers (if any) that can access and use the USGA Products, and their respective service providers, a royalty-free, unrestricted, worldwide, perpetual, irrevocable, non-exclusive and fully transferable, assignable and sublicensable right and license to use, copy, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, display, make, sell and export such material (in whole or part) and/or to incorporate it (in whole or in part) in other works (including, without limitation, other USGA products and services), products or services in any form, media, or technology now known or later developed.&nbsp; The USGA cannot be responsible for maintaining any Submitted Material that Authorized User provides to the USGA or on or through USGA Products, and the USGA may delete or destroy any such Submitted Material at any time. As used herein, the term “<b>Regional Associations</b>” shall mean Allied Golf Associations, State and Regional Golf Associations and any other golf associations that can access and use the USGA Products, and the term “<b>Approved Technology Providers</b>” shall mean golf related technology providers who have been pre-approved by the USGA.</p>
						<h3>7.&nbsp;PRIVACY POLICY</h3>
						<p>To the extent Authorized User provides any Personal Information through the USGA Products, the USGA treats such Personal Information in accordance with its <Link to={"./privacy-policy"}>Privacy Policy located here</Link>.&nbsp;&nbsp;<b>Notwithstanding the foregoing, if You are under the age of 13, You acknowledge and understand that You may only use the USGA Products to view information through the USGA Products and shall not provide any Personal Information through the USGA Products.</b></p>
						<h3>8.&nbsp;RULES OF CONDUCT</h3>
						<p>While using or accessing the USGA Products, in addition to any other supplementary terms and conditions that may be posted or otherwise made available in connection with particular components of the USGA Products, areas or features on the USGA Products, Authorized User agrees that Authorized User shall not:</p>
						<p>•&nbsp; impersonate any person or entity or misrepresent Authorized User’s affiliation with any other person or entity;</p>
						<p>•&nbsp; use, resell, redistribute, republish or exploit in whole or in part any portion of the USGA Products (including its content) for any commercial or promotional purposes, or contact any other user of the USGA Products for commercial or promotional purposes, or offer to buy or sell any product or service on or through Authorized User’s activities on the USGA Products or in a Forum (as defined below) on the USGA Products except as authorized by USGA;</p>
						<p>•&nbsp; alter, edit, delete, remove, fail to display, otherwise change the meaning or appearance of, or repurpose any component of the USGA Products or its contents, including, without limitation, any trademarks, trade names, logos, service marks, promotional taglines, or any other proprietary content or proprietary rights notices included therein or thereon;</p>
						<p>•&nbsp; obtain or attempt to gain unauthorized access to other computer systems, materials, information or any services available on or through the USGA Products through any means, including through means not intentionally made publicly available or provided for in the USGA Products;</p>
						<p>•&nbsp; engage in spidering, screen scraping, database scraping, harvesting of e-mail addresses or other personal information, or any other automatic or unauthorized means of accessing, logging-in or registering on USGA Products, or obtaining lists of users or other information from or through the USGA Products, including, without limitation, any information residing on any server or database connected to the USGA Products;</p>
						<p>•&nbsp; use the USGA Products or any of its respective features and services in any manner that could interrupt, damage, disable, overburden or impair the USGA Products or interfere with any other party's use and enjoyment of the USGA Products, including, without limitation, sending mass unsolicited messages or “flooding” servers with requests;</p>
						<p>•&nbsp; circumvent, reverse engineer, decrypt, or otherwise alter or interfere (or attempt, encourage or support anyone else’s attempt to do any of the foregoing) with the USGA Products, in whole or in part;</p>
						<p>•&nbsp; frame any part of the USGA Products in any third party sites or content or any third party sites or content in any USGA Product, in any manner that would constitute or could be interpreted as an endorsement or sponsorship by the USGA or its service providers of any third party site, content, information or other materials, or in any manner that would violate the terms and conditions of any such third party sites;</p>
						<p>•&nbsp; upload, post, transmit, distribute or otherwise publish to, on or through the USGA Products, any information, content or materials which are false, fraudulent, misleading, unlawful, threatening, abusive, harassing, hateful, racially, ethnically or otherwise objectionable, libelous, defamatory, obscene, vulgar, offensive, pornographic, profane, sexually explicit or indecent, including without limitation, any material which encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any local, state, national or international law;</p>
						<p>•&nbsp; use the USGA Products to, or in any other manner, violate, plagiarize or infringe the rights of third parties, including without limitation, copyright, trademark, trade secret, confidentiality, contract, patent, rights of privacy or rights of publicity or any other proprietary or legal right;</p>
						<p>•&nbsp; upload, post, publish, distribute or otherwise transmit any information or material which constitutes or contains a virus, spyware, or other harmful component, or which contains any embedded links, advertising, chain letters or pyramid schemes of any kind; or</p>
						<p>•&nbsp; use the USGA Products, in whole or in part, in violation of any applicable law.</p>
						<h3>9.&nbsp;&nbsp;PUBLIC FORUMS</h3>
						<p>The USGA Products may include certain features that allow Authorized Users to interact and communicate with each other and/or with the general public (such as messaging services, chat services, bulletin boards, message boards, blogs and other similar forums, areas and services) (collectively, the “<b>Forums</b>”).&nbsp; All of the terms and conditions set forth in this Agreement (as well as any other applicable, supplementary terms and conditions posted or otherwise made available in connection with particular features or services on the USGA Products), are applicable to Authorized User’s use of the Forums.&nbsp; It is important to remember that content submitted to a Forum may be recorded and stored in multiple places, both on databases that service the USGA Products and elsewhere on the Internet, and Authorized User may have no control over who will access or view them eventually.&nbsp; Therefore, Authorized User should be careful and selective about the information that Authorized User choose to disclose in such Forums and on the USGA Products in general about Authorized User and others, and in particular, Authorized User should not disclose any sensitive, personally identifiable, proprietary or confidential information in Authorized User’s submissions to the Forums.</p>
						<h3>10.&nbsp;RIGHT TO MONITOR AND EDITORIAL CONTROL</h3>
						<p>The USGA reserves the right (but does not have nor assume any obligation) to monitor and/or review all information and materials posted or otherwise submitted to the USGA Products (including, without limitation, the Forums).&nbsp; The USGA is not responsible for any such materials.&nbsp; However, the USGA reserves the right at all times to disclose any information as necessary to satisfy any law, regulation or government request, or to edit, refuse to post or to remove any information or materials, in whole or in part, that in the USGA’s sole discretion, are objectionable or in violation of Agreement, the USGA’s policies or applicable law.&nbsp; The USGA may also impose limits on certain features or restrict Authorized User’s access to part or all of the USGA Products, without notice or penalty, if the USGA believes Authorized User is in breach of this Agreement or applicable law, or for any other reason without notice or liability.</p>
						<h3>11.&nbsp;&nbsp;THIRD PARTY WEBSITES AND PRODUCTS</h3>
						<p>a.&nbsp;Links and Integrated Applications.&nbsp; Authorized User may be able to link from the USGA Products to third party web sites and/or third party web sites may link to the USGA Products (in each case, such third party web site is referred to as a <b>“Linked Site”</b>).&nbsp; The USGA Products may also be integrated with third party applications to provide Authorized User with additional features and an enhanced experience (“<b>Integrated Applications</b>”).&nbsp; Authorized User acknowledges and agrees that the USGA has no responsibility for any information, content, advertising, products, services or other materials on any Linked Sites or Integrated Applications, and that Integrated Applications and links to Linked Sites do not necessarily constitute an endorsement, approval or sponsorship thereof by the USGA. If Authorized User chooses to rely on any Linked Site or Integrated USGA Products, Authorized User is doing so at Authorized User’s own risk and Authorized User assumes all responsibilities and consequences resulting from such choice. Linked Sites and Integrated Applications may be subject to separate end user license agreements, terms or service or terms of use.</p>
						<h3>12.&nbsp;&nbsp;INDEMNIFICATION</h3>
						<p>Authorized User agrees to defend, indemnify and hold the USGA, and its directors, officers, employees, agents, service providers and licensors harmless from any and all claims, liabilities, costs and expenses, including reasonable attorneys' fees, arising in any way from (i) Authorized User’s use of the USGA Products, (ii) Authorized User’s Submitted Materials, in whole or in part, or (iii) Authorized User’s breach or violation of the law or this Agreement.&nbsp; The USGA reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by Authorized User, and in such case, Authorized User agrees to cooperate with any such defense.</p>
						<h3>13.&nbsp;&nbsp;DISCLAIMER OF WARRANTIES</h3>
						<p>TO THE EXTENT PERMITTED BY LAW, THE USGA PRODUCTS, INCLUDING, WITHOUT LIMITATION, ALL SERVICES, FORUMS, CONTENT, FUNCTIONS, DOWNLOADS AND MATERIALS CONTAINED THEREIN, IS PROVIDED “AS IS,” “AS AVAILABLE,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION, DATA, DATA PROCESSING SERVICES, UPTIME OR UNINTERRUPTED ACCESS, ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, USEFULNESS, CORRECTNESS, PRECISION, THOROUGHNESS, COMPLETENESS OR CONTENT OF INFORMATION, AND ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, AND THE USGA HEREBY DISCLAIMS ANY AND ALL SUCH WARRANTIES, EXPRESS AND IMPLIED.</p>
						<p>TO THE EXTENT PERMITTED BY LAW, THE USGA DOES NOT WARRANT THAT THE USGA PRODUCTS, INCLUDING, WITHOUT LIMITATION, ALL SERVICES, FORUMS, CONTENT, FUNCTIONS, DOWNLOADS AND MATERIALS CONTAINED THEREIN, WILL BE TIMELY, SECURE, UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED IN A TIMELY MANNER OR AT ALL.</p>
						<p>TO THE EXTENT PERMITTED BY LAW, USGA MAKES NO WARRANTY THAT THE USGA PRODUCTS, IN WHOLE OR IN PART, WILL MEET AUTHORIZED USER REQUIREMENTS.&nbsp; NO ADVICE, RESULTS OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY AUTHORIZED USER FROM USGA, SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.</p>
						<p>USGA ALSO ASSUMES NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR, ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT, AUTHORIZED USER’S COMPUTER OR MOBILE EQUIPMENT OR ANY OTHER PROPERTY ON ACCOUNT OF AUTHORIZED USER’S ACCESS TO, USE OF, OR BROWSING IN THE USGA PRODUCTS OR AUTHORIZED USER’S DOWNLOADING OF THE USGA MOBILE APP OR THE AUTHORIZED USER’S DOWNLOADING OR UPLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO, OR AUDIO FROM THE USGA PRODUCTS.</p>
						<p>IF AUTHORIZED USER IS DISSATISFIED WITH THE USGA PRODUCTS, AUTHORIZED USER SHOULD DISCONTINUE USE.&nbsp; USGA DOES NOT NECESSARILY ENDORSE, SUPPORT, SANCTION, ENCOURAGE OR AGREE WITH ANY SITE CONTENT OR ANY SUBMITTED MATERIALS, OR ANY OPINION, RECOMMENDATION, CONTENT, LINK, DATA OR ADVICE EXPRESSED OR IMPLIED THEREIN, AND USGA EXPRESSLY DISCLAIMS, TO THE EXTENT PERMITTED BY LAW, ANY AND ALL LIABILITY IN CONNECTION WITH SUBMITTED MATERIALS AND ANY OTHER CONTENT, MATERIALS OR INFORMATION AVAILABLE ON OR THROUGH THE USGA PRODUCTS CREATED OR PROVIDED BY AUTHORIZED USERS OR OTHER THIRD PARTIES.</p>
						<p>WITHOUT LIMITATION OF THE ABOVE IN THIS SECTION AND TO THE EXTENT PERMITTED BY LAW, USGA AND ITS AFFILIATES, SUPPLIERS AND LICENSORS MAKE NO WARRANTIES OR REPRESENTATIONS REGARDING ANY PRODUCTS OR SERVICES PROVIDED VIA THE USGA PRODUCTS, AND HEREBY DISCLAIM, AND AUTHORIZED USER HEREBY WAIVES, ANY AND ALL WARRANTIES AND REPRESENTATIONS MADE IN PRODUCT OR SERVICES LITERATURE, FREQUENTLY ASKED QUESTIONS DOCUMENTS AND OTHERWISE ON THE USGA PRODUCTS OR IN CORRESPONDENCE WITH USGA AND ITS AGENTS.&nbsp; ANY PRODUCTS AND SERVICES PROVIDED VIA THE USGA PRODUCTS ARE PROVIDED BY USGA (OR ITS LICENSORS OR THIRD PARTY PROVIDERS OR SUPPLIERS) “AS IS”, EXCEPT TO THE EXTENT, IF AT ALL, OTHERWISE SET FORTH IN A LICENSE OR SUBSCRIPTION AGREEMENT SEPARATELY ENTERED INTO IN WRITING BETWEEN AUTHORIZED USER AND USGA OR ITS LICENSORS OR SUPPLIERS.</p>
						<h3>14.&nbsp;&nbsp;LIMITATION OF LIABILITY</h3>
						<p>TO THE EXTENT PERMITTED BY LAW, IN NO EVENT, INCLUDING BUT NOT LIMITED TO NEGLIGENCE, SHALL USGA OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, LICENSORS, OR SERVICE PROVIDERS (COLLECTIVELY, THE “<b>PROTECTED ENTITIES</b>”) BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM, OR DIRECTLY OR INDIRECTLY RELATED TO, THE USE OF, OR THE INABILITY TO USE, USGA PRODUCTS OR CONTENT, MATERIALS AND FUNCTIONS OF OR RELATED THERETO, AUTHORIZED USER’S PROVISION OF INFORMATION OR CONTENT VIA THE USGA PRODUCTS, LOST BUSINESS OR LOST SALES, EVEN IF SUCH PROTECTED ENTITY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp; SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY.&nbsp; IN NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF THE PROTECTED ENTITIES TO AUTHORIZED USER FOR ANY DIRECT DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR OTHERWISE) ARISING FROM THIS AGREEMENT OR AUTHORIZED USER’S USE OF THE USGA PRODUCTS EXCEED ONE DOLLAR ($1.00).</p>
						<h3>15.&nbsp;&nbsp;JURISDICTIONAL ISSUES</h3>
						<p>The USGA does not represent that the USGA Products or any contents contained therein are appropriate or available for use in any location outside of the United States.&nbsp; Authorized Users who choose to access the USGA Products from locations outside of the United States do so on their own initiative, and are responsible for compliance with local laws, if and to the extent local laws are applicable.&nbsp; To the extent local laws require additional terms, this Agreement may be subject to separate jurisdictional terms, if any and as applicable.&nbsp; The USGA Products are subject to United States export controls. The USGA Products may not be downloaded or otherwise exported or re-exported (i) into (or to a national or resident of) Cuba, Iran, North Korea, Sudan, Syria or any other country to which the United States has embargoed goods; or (ii) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Commerce Department's Table of Deny Orders; or (iii) in any other manner that violates U.S. law. By using the USGA Products, Authorized User represents and warrants that Subscriber is not located in, under the control of, or a national or resident of any such country or on any such list.</p>
						<h3>16.&nbsp;&nbsp;CHANGES TO THIS AGREEMENT</h3>
						<p>USGA has the right to change or modify this Agreement, or to impose new conditions on Authorized User’s use of the USGA Products, at any time.&nbsp; Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by any means including, but not limited to, posting within the USGA Products, by electronic mail, by messaging, or by any other means from which Authorized User may obtain notice thereof.&nbsp; Any use of the USGA Products by Authorized User subsequent to such notice shall be deemed to constitute Authorized User’s acceptance of such changes, modifications, additions, or deletions.</p>
						<h3>17.&nbsp;&nbsp;COPYRIGHT</h3>
						<p>USGA respects the intellectual property rights of others, and require that the people who use the products and services of the USGA do the same.&nbsp; If any person or entity believes that work has been copied in a way that constitutes copyright infringement, please forward the following information to the USGA’s Copyright Agent, designated as such pursuant to the Digital Millennium Copyright Act, 17 U.S.C. § 512(c)(2) (the “<b>DMCA</b>”), named below:</p>
						<p>•&nbsp; Authorized User’s address, telephone number, and email address;</p>
						<p>•&nbsp; A description of the copyrighted work that Authorized User claims has been infringed;</p>
						<p>•&nbsp; A description of where the alleged infringing material is located;</p>
						<p>•&nbsp; A statement by Authorized User that Authorized User has a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
						<p>•&nbsp; An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; and</p>
						<p>•&nbsp; A statement by Authorized User, made under penalty of perjury, that the above information in Authorized User’s Notice is accurate and that Authorized User is the copyright owner or authorized to act on the copyright owner's behalf.</p>
						<p>Copyright Agent:</p>
						<p>Belinda Berman</p>b
						<p>United States Golf Association</p>
						<p>77 Liberty Corner Road</p>
						<p>Liberty Corner, NJ 07938</p>
						<p>Phone: (908) 234-2300 x 1034</p>
						<p>Fax: (908) 719-9593</p>
						<p>Email: <a adhocenable="false" href="mailto:DMCA-agent@usga.org" target="_blank" rel="noopener noreferrer">DMCA-agent@usga.org</a></p>
						<p>USGA will respond to notices of claimed copyright infringement in accordance with the DMCA.</p>
						<h3>18.&nbsp;&nbsp;MISCELLANEOUS</h3>
						<p>This Agreement and the relationship between Authorized User and the USGA shall be governed by the laws of the United States and the State of New York, without regard to its conflict of law provisions.&nbsp; Authorized User agrees that any cause of action that may arise under this Agreement shall be commenced and be heard in the appropriate court in the State of New York, County of New York.&nbsp; Authorized User agrees to submit to the personal and exclusive jurisdiction of the courts located within New York County in the State of New York.&nbsp; The USGA’s failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.&nbsp; If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.</p>
					</div>
				</div>
            </Fragment>
		);
	}
}
