import { Fragment, useState } from "react";
import Modal from "react-modal";
import EditJoinClubSettingsModal from "../../../Modals/EditJoinClubSettingsModal";

export default function Setup(props) {

  const joinClubSettings = props.joinClubSettings;

  const [editModalOpened, setEditModalOpened] = useState(false);

  const closeModal = () => {
    setEditModalOpened(false);
  }

  return (
    <Fragment>
      <div className="panel">
        <div className="panel__head">
          Setup
          { !props.canEditAssociation && <span className="red-note">Please contact your association to change these settings.</span> }
        </div>
        <div className="panel__body">
          {
            (!joinClubSettings.enable_online_join && !joinClubSettings.enable_online_renewal) ?
            <Fragment>
              <div className="row">
                <div className="col">
                  <div className="data-set__container">
                    <div className="data-set">
                      <span className="data-label">Join & Renew Enabled?</span>
                      <span>No</span>
                      {
                        props.canEditAssociation &&
                        <div
                          onClick={() => setEditModalOpened(true)}
                          style={{marginLeft: '12px'}}
                          className="btn lnk">
                          Edit
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
            :
            <Fragment>
              <div className="row">
                <div className="col">
                  <span className="subtitle">Enable Join & Renew</span>
                  <p>Join and Renew is currently enabled for your club. The following settings have been setup by your association.</p>
                  <div className="data-set__container subparagraph">
                    <div className="data-set">
                      <span className="data-label">Join & Renew Enabled?</span>
                      <span>{ joinClubSettings.enable_online_join ? "Yes" : "No" }</span>
                    </div>
                    <div className="data-set">
                      <span className="data-label">Online Renewals Enabled?</span>
                      <span>{ joinClubSettings.enable_online_renewal ? "Yes" : "No" }</span>
                    </div>
                    <div className="data-set">
                      <span className="data-label">Auto Renew Enabled?</span>
                      <span>{ joinClubSettings.enable_auto_renewal ? "Yes" : "No" }</span>
                    </div>
                  </div>
                </div>
                {
                  props.canEditAssociation &&
                  <div className="col auto push-right">
                    <button
                        onClick={() => setEditModalOpened(true)}
                        className="btn fill green push-right"
                      >
                        Edit
                    </button>
                  </div>
                }
              </div>
              
              <hr style={{ border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px' }}></hr>

              <div className="row">
                <div className="col">
                  <span className="subtitle">Approving Golfers</span>
                  <p>Set this flag to "yes" if approval is needed prior to adding golfers to this club when they join online.</p>
                  <div className="data-set__container subparagraph">
                    <div className="data-set">
                      <span className="data-label">Approval Needed?</span>
                      <span>{ joinClubSettings.approval_needed ? "Yes" : "No" }</span>
                    </div>
                  </div>
                </div>
              </div>

              <hr style={{ border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px' }}></hr>

              <div className="row">
                <div className="col">
                  <span className="subtitle">Join & Renew Search By Zip Code</span>
                  <p>If the search radius zip code is different than the club's primary address zip code, please enter it here. If it is the same, leave blank.</p>
                  <div className="data-set__container subparagraph">
                    <div className="data-set">
                      <span className="data-label">Join & Renew Search Zip Code </span>
                      <span>{ joinClubSettings.zip_code || '-' }</span>
                    </div>
                  </div>
                </div>
              </div>

              <hr style={{ border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px' }}></hr>

              <div className="row">
                <div className="col">
                  <span className="subtitle">Membership Reminder Emails for Manually Added Golfers</span>
                  <p>Membership reminder emails can be sent to golfers who were manually added to your club and have a membership end date set. The reminder email will contain a link to join the club online and will be sent 30 days, 15 days, and 3 days before the end date.</p>
                  <div className="data-set__container subparagraph">
                    <div className="data-set">
                      <span className="data-label">Send reminders to manually added golfers? </span>
                      <span>{ joinClubSettings.enable_reminders_manual_golfers ? "Yes" : "No" }</span>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          }
        </div>
      </div>

      <Modal
        isOpen={editModalOpened}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <EditJoinClubSettingsModal
          closeModal={() => closeModal()}
          joinClubSettings={{...props.joinClubSettings, club_name: props.club.club_name}}
          reloadGolfersCount={() => props.reloadGolfersCount()}
          updateSettings={() => props.updateSettings()}
        />
      </Modal>
        
    </Fragment>
  )
}
