import request from '../shared/request';
import simpleRequest from '../shared/simpleRequest';

export default class AlertsService {
	static getAlerts(active,source) {
		return simpleRequest({
			url: `federations/1/product_alerts.json?active=${active}&source=${source}`,
			method: 'GET',
		})
	}
	static updateAlert(data) {
		return request({
			url: `federations/1/product_alerts.json`,
			method: 'POST',
			data
		})
	}

	static getVersionNumbers() {
		return request({
			url: `federations/1/version_numbers.json?source=all`,
			method: 'GET',
		})
	}

	static updateVersionNumbers(data) {
		return request({
			url: `federations/1/version_numbers.json`,
			method: 'POST',
			data
		})
	}
}
