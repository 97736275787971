import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { addAlert } from "../shared/actions";
import { connect } from "react-redux";
import EditPushNotificationForm from "../Forms/EditPushNotificationForm";
import EditPushNotificationForFederationForm from "../Forms/EditPushNotificationForFederationForm";

class EditNotificationModal extends Component {
	render() {
		return (
			<Fragment>
				<div className={`modal__container narrow`}>
					<div className="modal__head">
						<div className="modal__icon">
							<i className="material-icons-outlined">edit</i>
						</div>
						<h2 className="modal__title">Edit Push Notification</h2>
						<button
							className="modal__close"
							onClick={() => {
								this.props.closeModal();
							}}
						>
							<i className="material-icons-outlined">clear</i>
						</button>
					</div>

					<div className="modal__body">
						{this.props.forFederation ? (
							<EditPushNotificationForFederationForm
								push_notification_setting={
									this.props.push_notification_setting
								}
								user={this.props.user}
								close={() => this.props.closeModal()}
								setTableRefresh={(value) =>
									this.props.setTableRefresh(value)
								}
							/>
						) : (
							<EditPushNotificationForm
								push_notification_setting={
									this.props.push_notification_setting
								}
								associationId={this.props.associationId}
								user={this.props.user}
								close={() => this.props.closeModal()}
								setTableRefresh={(value) =>
									this.props.setTableRefresh(value)
								}
							/>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(null, mapDispatchToProps)(EditNotificationModal);
