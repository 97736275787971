import React, {Component, Fragment} from 'react';

import AddUserForm from '../Forms/AddUserForm';

export default class AddUserModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
                        <h2 className="modal__title">Add User</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
                        <AddUserForm closeModal={this.props.closeModal} users={this.props.usersList} clubId={this.props.clubId} associationId={this.props.associationId}/>
                    </div>

                </div>

            </Fragment>
		);
	}
}
