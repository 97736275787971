import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {parseISO} from 'date-fns';
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FederationAccount} from "../../services/api/federationAccount";
import {InputField} from "../../inputs/InputField";
import {DatePickerInput} from "../../inputs/DatePicker";
import {required} from "../../inputs/validations";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import moment from "moment";


class EditSurveySetupForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			survey: this.props.survey
		}
	}

	componentDidMount() {
		let survey = this.props.survey;
		survey.start_date = parseISO(survey.start_date);
		survey.end_date = parseISO(survey.end_date);
		this.props.initialize(this.props.survey);
		this.props.onRef(this);
	}

	onSubmit(values, dispatch, props) {
		let data = JSON.parse(JSON.stringify(values));
		data.start_date = moment(new Date(data.start_date)).format("YYYY-MM-DD");
		data.golf_associations = null
		data.end_date = moment(new Date(data.end_date)).format("YYYY-MM-DD");
		FederationAccount.updateSurvey(data)
			.then(res => {
				props.addAlert({
					type:'success',
					message:'Survey has been updated successfully'
				});
				props.close(res.survey);
			})
			.catch(err => {
				console.error(err)
			});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
				<div className="row">
					<div className="col is-full">
						<label>Is Enabled?</label>
						<div className="radio__container">
							<Field
								onClick={ () => { this.setState({survey: {...this.state.survey, enabled: true}}) }}
								checked={this.state.survey.enabled}
								id="enabled"
								value={true}
								name={'enabled'}
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
							<label htmlFor="enabled">Yes</label>
							<Field
								onClick={ () => { this.setState({survey: {...this.state.survey, enabled: false}}) }}
								checked={!this.state.survey.enabled}
								id="not_enabled"
								value={false}
								name={'enabled'}
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
							<label htmlFor="not_enabled">No</label>
						</div>
					</div>
				</div>

					<div className="row">
						<div className="col is-full">
							<label htmlFor="question">Question <span>*</span></label>
							<Field component={InputField} validate={[required]} type="text" name="question" id="question" />
						</div>
					</div>

					<div className='row'>
						<div className="col is-1-of-2">
							<label htmlFor="start_date">Start Date</label>
							<Field className={'date'}
								showMonthDropdown
								showYearDropdown
								minDate={new Date()}
								name={'start_date'}
								value={null}
								id={'start_date'}
								component={DatePickerInput}
								placeholder="Select ..." />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="end_date">End Date</label>
							<Field className={'date'}
								showMonthDropdown
								showYearDropdown
								minDate={new Date()}
								name={'end_date'}
								value={null}
								id={'end_date'}
								component={DatePickerInput}
								placeholder="Select ..." />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={()=>this.props.exit()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={pristine || submitting} className="btn fill blue">Save</button>
						</div>
					</div>

				</form>
			</Fragment>
		);
	}
}

EditSurveySetupForm = reduxForm({
	form: 'editSurveySetupForm',
	destroyOnUnmount: true
})(EditSurveySetupForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditSurveySetupForm));
