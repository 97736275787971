import React, {Component, Fragment} from 'react';

import EditMembershipTypeForm from '../Forms/EditMembershipTypeForm';

export default class EditMembershipTypeModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Edit Membership Type</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <EditMembershipTypeForm associationId={this.props.associationId} membership={this.props.membership} joinRenewEnabled={this.props.joinRenewEnabled} closeModal={(data)=>{this.props.closeModal(data)}} />

                    </div>

                </div>

            </Fragment>
		);
	}
}
