import React, {Component, Fragment} from 'react';

export default class AlertModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container very-narrow">

                    <div className="modal__head red">

                        <div className="modal__icon"/>
                        <h2 className="modal__title">Important Alert</h2>
                    </div>

                    <div className="modal__body">
						<div className={"row"}>
							<div className={"col"}>
								<p><strong>{this.props.data.message_title}</strong></p>
							</div>
						</div>
						<div className={"row"}>
							<div className={"col"}>
								<p>{this.props.data.message_body}</p>
							</div>
						</div>
						<div className="row no-margin">
							<div className="col is-1-of-2 push-right">
								<button type={'submit'}  onClick={() => this.props.closeModal()} className="btn fill another-red">Close</button>
							</div>
						</div>
                    </div>

                </div>

            </Fragment>
		);
	}
}
