import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import EditClubAccountModal from '../../../Modals/EditClubAccountModal';
import EditAddressModal from '../../../Modals/EditAddressModal';
import UploadImage from "../../../../inputs/UploadImage";
import ClubService from "../../../../services/api/club";
import moment from "moment";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {updateClub} from "./Actions";

class PrimaryClub extends Component {

    static defaultProps = {
        center: {lat: 40.001869, lng: -75.311804},
        zoom: 10
    };

	constructor() {

		super();

		this.state = {
			EditClubAccountModalIsOpen: false,
			EditAddressModalIsOpen: false,
			EditAddressModalType: null,
			club: null,
			addresses: [],
			logo: null,
			clearLogoModalIsOpen:false
		};

		this.openEditClubAccountModal = this.openEditClubAccountModal.bind(this);
		this.openEditAddressModal = this.openEditAddressModal.bind(this);

	}

	openEditClubAccountModal() {
		this.setState({EditClubAccountModalIsOpen: true});
	}

	openEditAddressModal(type) {
		this.setState({EditAddressModalIsOpen: true, EditAddressModalType:type});
	}

	closeModal(data) {
		this.setState({
			club: data ? data : this.state.club,
			EditClubAccountModalIsOpen: false,
			EditAddressModalIsOpen: false,
		});
		if (data) {
			this.props.updateClub({...data.club})
      this.props.updateJoinClubSettings();
		}
	}

	removeLogo(){
		ClubService.editClubLogo(this.props.associationId, this.state.club.club.id,{delete: "true"})
			.then(res => {
				this.setState({
					club: res,
					clearLogoModalIsOpen: false
				});
			})
			.catch(err=>{console.error(err)});
	}

	saveLogo(file){
		ClubService.editClubLogo(this.props.associationId,this.state.club.club.id, {logo: file, 'alt_text': ''})
			.then(res => {
				this.setState({club: res})
			})
			.catch(err=>{console.error(err)});
	}

	setAltText(data){
		ClubService.editClubLogo(this.props.associationId,this.state.club.club.id, {alt_text: data})
			.then(res => {
				this.setState({club: res})
			})
			.catch(err=>{console.error(err)});
	}

	componentDidMount() {
		ClubService.getClubAddresses(this.props.associationId,this.props.club.id)
			.then(res => {
				this.setState({club: res});
				this.props.updateClub(res)
			})
			.catch(err => {console.error(err)});
	}

	render() {
		let club = this.state.club && this.state.club.club,
			primary_address = this.state.club && this.state.club.primary_address,
			primary_contact_address = this.state.club && this.state.club.primary_contact_address,
			mailing_address = this.state.club && this.state.club.mailing_address,
			mailing_contact_address = this.state.club && this.state.club.mailing_contact_address,
			billing_address = this.state.club && this.state.club.billing_address,
			billing_contact_address = this.state.club && this.state.club.billing_contact_address;
		return (
            <Fragment>

				{club && <div className="columns">

                    <div className="row">
                        <div className="col">
                            <div className="panel">
                                <div className="panel__head">Basic Information</div>
                                <div className="panel__body">
									{((this.props.canEditAssociation && process.env.REACT_APP_TYPE !== "hv") || this.props.user_type === 'super-admin') && <div className="row">
                                        <div className="col auto push-right">
                                            <button onClick={this.openEditClubAccountModal} className="btn lnk">Edit</button>
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col jc-fs">
                                            <div className="data-set__container">
                                                <div className="data-set">
                                                    <span className="data-label">Club Number:</span>
                                                    <span>{club.id}</span>
                                                </div>
												{(club.club_number === club.id) ? null :
													<div className="data-set">
													<span className="data-label">GHP ID:</span>
													<span>{`${club.golf_association_id}${club.club_number}`}</span>
													</div>
												}
                                                <div className="data-set">
                                                    <span className="data-label">Club Name:</span>
                                                    <span>{club.club_name}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Short Name:</span>
                                                    <span>{club.short_name}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Association Name:</span>
                                                    <span>{this.props.association.name}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Handicap Chairperson:</span>
                                                    <span>{club.handicap_chairperson}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Phone:</span>
                                                    <span>{club.phone}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Email:</span>
                                                    <span><a href={`mailto: ${club.email}`}>{club.email}</a></span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Website:</span>
													{club.website && <span><a href={`https://${club.website}`} target='_blank' rel='noopener noreferrer'>{club.website}</a></span>}
                                                </div>
																								{club.jr_enabled && <div className='data-set'>
																									<span className='data-label'>Join & Renew Search Zip Code:</span>
																									<span>{club.online_zip_code}</span>
																								</div>}
                                            </div>
                                        </div>
                                        <div className="col jc-fs">
                                            <div className="data-set__container">
                                                <div className="data-set">
                                                    <span className="data-label">Status:</span>
                                                    <span>{club.status}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Last Status Update:</span>
                                                    <span>{moment(club.status_date).format('MM/DD/YYYY')}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Club Creation Date:</span>
                                                    <span>{moment(club.created_at).format('MM/DD/YYYY')}</span>
                                                </div>
												{club.inactive_flag && <div className="data-set">
													<span className="data-label">Future {club.inactive_flag === 'Activate' ? 'Activation' : 'Inactivation'} Date:</span>
													<span>{moment(club.inactive_date).format('MM/DD/YYYY')}</span>
												</div>}
												{((this.props.canEditAssociation && process.env.REACT_APP_TYPE !== "hv") || this.props.user_type === 'super-admin') &&<div className="data-set">
                                                    <span className="data-label">USGA TM Version:</span>
                                                    <span>{club.usga_version}</span>
                                                </div>}
                                                <div className="data-set">
                                                    <span className="data-label">Club Category:</span>
                                                    <span>{club.club_category}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Club Type:</span>
                                                    <span>{club.club_type}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Is Test Club?:</span>
                                                    <span>{club.is_test ? "Yes" : "No"}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Authorized?:</span>
                                                    <span>{club.authorized ? "Yes" : "No"}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Is DAC?:</span>
                                                    <span>{club.is_dac ? "Yes" : "No"}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Front End Provider:</span>
                                                    <span>{club.technology_provider}</span>
                                                </div>
                        												<div className="data-set">
                        													<span className="data-label">USGA/AGA Club?:</span>
                        													<span>{club.is_aga ? "Yes" : "No"}</span>
                        												</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

					<div className='row'>
						<div className="col jc-fs">
							<div className="panel">
								<div className="panel__head">
									Addresses
								</div>
								<div className="panel__body">

									<table className="data__table">
										<colgroup>
											<col className="col_golfer-name"/>
											<col className="col_golfer-name"/>
											<col className="col_golfer-name"/>
											{((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || this.props.user_type === 'super-admin') && <col className="col_edit"/>}
										</colgroup>
										<thead>
										<tr>
											<th>Address Type</th>
											<th>Address</th>
											<th>Contact Information</th>
											{((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || this.props.user_type === 'super-admin') && <th className='col_edit'></th>}
										</tr>
										</thead>
										<tbody>
										<tr>
											<td>Primary</td>
											<td>
												{primary_address.street_1} {primary_address.street_1 && <br/>}
												{primary_address.street_2} {primary_address.street_2  && <br/>}
												{[primary_address.city, primary_address.state_name, primary_address.zip, primary_address.country_name].filter(v => v).join(', ')}
											</td>
											<td>
												{primary_contact_address.first_name} {primary_contact_address.last_name}<br/>
												{primary_contact_address.email}{primary_contact_address.email && <br/>}
												{primary_contact_address.phone}
											</td>
											{((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || this.props.user_type === 'super-admin') && <td className='col_edit'>
												<div className="address__head">
													<button onClick={() => this.openEditAddressModal('primary')}
															className="btn lnk">Edit
													</button>
												</div>
											</td>}
										</tr>
										<tr>
											<td>Mailing</td>
											<td>
												{mailing_address.street_1} {mailing_address.street_1 && <br/>}
												{mailing_address.street_2} { mailing_address.street_2  && <br/>}
												{[mailing_address.city, mailing_address.state_name, mailing_address.zip, mailing_address.country_name].filter(v => v).join(', ')}
											</td>
											<td>
												{mailing_contact_address.first_name} {mailing_contact_address.last_name}<br/>
												{mailing_contact_address.email}{mailing_contact_address.email && <br/>}
												{mailing_contact_address.phone}
											</td>
											{((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || this.props.user_type === 'super-admin') &&<td className='col_edit'>
												 <div className="address__head">
													<button onClick={() => this.openEditAddressModal('mailing')}
															className="btn lnk">Edit
													</button>
												</div>
											</td>}
										</tr>
										<tr>
											<td>Billing</td>
											<td>
												{billing_address.street_1} {billing_address.street_1 && <br/>}
												{billing_address.street_2} { billing_address.street_2  && <br/>}
												{[billing_address.city, billing_address.state_name, billing_address.zip, billing_address.country_name].filter(v => v).join(', ')}
											</td>
											<td>
												{billing_contact_address.first_name} {billing_contact_address.last_name}<br/>
												{billing_contact_address.email}{billing_contact_address.email && <br/>}
												{billing_contact_address.phone}
											</td>
											{((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || this.props.user_type === 'super-admin') &&<td className='col_edit'>
												 <div className="address__head">
													<button onClick={() => this.openEditAddressModal('billing')}
															className="btn lnk">Edit
													</button>
												</div>
											</td>}
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<div className="panel">
								<UploadImage title={`Club Logo`}
											 image={club.logo}
											 alt_text={club.alt_text}
											 minimumHeight={155}
											 minimumWidth={155}
											 readOnly={process.env.REACT_APP_TYPE !== "hv" ? !this.props.canEditClub : this.props.user_type !== 'super-admin'}
											 square={true}
											 logo={true}
                       logoDescription={'The Club Logo will be used in tabular reporting.'}
											 onSave={(data) => {this.saveLogo(data)}}
											 onRemove={() => {this.removeLogo()}}
											 onSetAltText={(data) => this.setAltText(data)}
								/>
							</div>
						</div>
					</div>

                </div>}
                <Modal
						isOpen={this.state.EditClubAccountModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
                    <EditClubAccountModal superAdmin={this.props.user_type === "super-admin"} club={this.state.club && this.state.club.club} associationId={this.props.associationId} closeModal={(data)=>{this.closeModal(data)}} />
                </Modal>

				<Modal
					isOpen={this.state.EditAddressModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditAddressModal type={this.state.EditAddressModalType}  for={'club'} assoc={this.state.club} closeModal={(data)=>{this.closeModal(data)}} />
				</Modal>

            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user_type: state.user.access.user_type,
		clubProfile: state.club
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({updateClub}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryClub);
