import React, { Component, Fragment } from "react";
import TableFilter from "./TableFilter";
import GhinTable from "../../../shared/GhinTable";
import { connect } from "react-redux";
import moment from "moment";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";
import EditNotificationModal from "../../../Modals/EditNotificationModal";
import CopyNotificationModal from "../../../Modals/CopyNotificationModal";
import ReviewNotificationModal from "../../../Modals/ReviewNotificationModal";
import { FederationAccount } from "../../../../services/api/federationAccount";

import { addAlert } from "../../../shared/actions";
import { bindActionCreators } from "redux";
import ReactTooltip from "react-tooltip";

import Modal from "react-modal";

class Table extends Component {
	constructor() {
		super();

		this.state = {
			push_notifications: [],
			tableFilterIsHidden: false,
			deteletNotificationModal: false,
			editNotificationModal: false,
			copyNotificationModal: false,
			reviewNotificationModal: false,
			exportUrlForPushNotifications: null,
			selectedNotification: null,
			loading: false,
			per_page: 25,
			page: 0,
			filtered: [],
			sorted: [{ id: "created_at", desc: true }],
		};
		this.deteletNotification = this.deteletNotification.bind(this);
	}

	toggleTableFilter() {
		this.setState({
			tableFilterIsHidden: !this.state.tableFilterIsHidden,
		});
	}

	updateFilter(filtered) {
		this.setState(
			{
				filtered,
				page: 0,
			},
			() => {
				this.loadPushNotifications();
			}
		);
	}

	loadPushNotifications() {
		const params = this.state.filtered;

		if (this.state.sorted.length > 0) {
			params["sorting_criteria"] = this.state.sorted[0].id;
			if (params["sorting_criteria"] === "schedule.scheduled_at") {
				params["sorting_criteria"] = "scheduled_at";
			}
			params["order"] = this.state.sorted[0].desc ? "desc" : "asc";
		}

		if (!this.state.filtered.notification_text)
			params["notification_text"] = null;

		this.setState({ loading: true });
		FederationAccount.getPushNotification(
			this.state.page + 1,
			this.state.per_page,
			this.props.associationId,
			params
		)
			.then((res) => {
				this.setState({
					push_notifications: res.push_notification_settings,
					total: parseInt(res["total-count"], 10),
					per_page: parseInt(res["per-page"], 10),
					pages: parseInt(res["total-pages"], 10),
					loading: false,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	componentDidMount() {
		this.refreshTable();
	}

	componentDidUpdate() {
		this.refreshTable();
	}

	refreshTable() {
		if (this.props.tableNeedsRefresh === true) {
			this.loadPushNotifications();
			this.props.setTableRefresh(false);
		}
	}

	deteletNotification() {
		FederationAccount.deletePushNotification(
			this.props.associationId,
			this.state.selectedNotification.id
		)
			.then(() => {
				this.setState({
					selectedNotification: null,
					deteletNotificationModal: false,
				});
				this.loadPushNotifications();
				this.props.addAlert({
					type: "success",
					message:
						"Push Notification has been successfully deleted !",
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	exportToExcel() {
		const params = this.state.filtered;

		FederationAccount.exportNotificationHistory(
			this.props.associationId,
			params
		)
			.then((response) => {
				window.open(response.url, "_blank");
			})
			.catch((err) => {
				console.log(err);
			});
	}

	closeModal() {
		this.setState({
			editNotificationModal: false,
		});
	}

  render() {
		const columns = [
			{
				Header: "Created On",
				accessor: "created_at",
				Cell: (props) => (
					<Fragment>
						{moment(new Date(props.value + " UTC")).format(
							"MM/DD/YYYY HH:mm:ss"
						)}{" "}
					</Fragment>
				),
				thClassName: "is-6-of-9",
				sortable: true,
			},
			{
				Header: "Scheduled",
				accessor: "schedule.scheduled_at",
				Cell: (props) => (
					<Fragment>
						{props.value === null
							? "-"
							: moment
									.utc(new Date(props.value + " UTC"))
									.format("MM/DD/YYYY HH:mm:ss")}{" "}
					</Fragment>
				),
				thClassName: "is-6-of-9",
				sortable: true,
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: (props) => (
					<Fragment>
						{props.value === null
							? ""
							: props.value === "awaiting approval"
							? "Awaiting Approval"
							: props.value.charAt(0).toUpperCase() +
							  props.value.slice(1)}
						{props.value === "rejected" && (
							<Fragment>
								<a
									href="#donothing"
									onClick={(e) => {
										e.preventDefault();
									}}
									data-tip
									data-for="status"
								>
									<i className="material-icons v-mid m-left darl-blue">
										info
									</i>
								</a>
								<ReactTooltip
									id="status"
									className="table-head__tooltip extra_strong"
									type="light"
									place="top"
									effect={"float"}
								>
									{props.row._original.notes}
								</ReactTooltip>
							</Fragment>
						)}
					</Fragment>
				),
				thClassName: "is-8-of-15",
				sortable: true,
			},
			{
				Header: "Notification Text",
				accessor: "message.body",
				sortable: false,
      },
      {
        Header: "",
        sortable: false,
        accessor: "buttons",
        Cell: (props) => (
					<span className="col_edit-delete">
            {
							(props.row._original.status === "scheduled" ||
								props.row._original.status ===
									"awaiting approval") && (
								<button
									className={"btn lnk"}
									onClick={() => {
										this.setState({
											reviewNotificationModal: true,
											selectedNotification:
												props.row._original,
										});
									}}
								>
									Review
								</button>
							)}
						{props.row._original.status !== "sent" && (
							<button
								className={"btn lnk"}
								onClick={() => {
									this.setState({
										editNotificationModal: true,
										selectedNotification:
											props.row._original,
									});
								}}
							>
								Edit
							</button>
						)}
						<button
							className={"btn lnk"}
							onClick={() => {
								this.setState({
									copyNotificationModal: true,
									selectedNotification: props.row._original,
								});
							}}
						>
							Copy
						</button>
						{props.row._original.status !== "sent" && (
							<button
								className={"btn lnk"}
								onClick={() => {
									this.setState({
										deteletNotificationModal: true,
										selectedNotification:
											props.row._original,
									});
								}}
							>
								Delete
							</button>
						)}
					</span>
				),
				thClassName: "is-8-of-10",
			},
		];

		return (
			<Fragment>
				<TableFilter
					updateFiltered={this.updateFilter.bind(this)}
					hideFilters={this.state.tableFilterIsHidden}
				/>
				<div className="pre__table">
					<div className="row">
						<div className="col auto">
							<ul className="action__list push-left">
								<li>
									<button
										className="btn fill green big"
										onClick={() => {
											this.exportToExcel();
										}}
									>
										Export to Excel
									</button>
								</li>
							</ul>
						</div>

						<div className="col auto push-right">
							<div className="row">
								<div className="col auto">
									{!this.state.tableFilterIsHidden && (
										<button
											onClick={this.toggleTableFilter.bind(
												this
											)}
											className="btn fill gray"
										>
											Hide Filters{" "}
											<i className="material-icons-outlined">
												remove
											</i>
										</button>
									)}
									{this.state.tableFilterIsHidden && (
										<button
											onClick={this.toggleTableFilter.bind(
												this
											)}
											className="btn fill gray"
										>
											Show Filters{" "}
											<i className="material-icons-outlined">
												add
											</i>
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={"row"}>
					<div className={"col"}>
						<span className={"bold"}> Notification History</span>
					</div>
				</div>
				<GhinTable
					loading={this.state.loading}
					columns={columns}
					pages={this.state.pages}
					page={this.state.page}
					total={this.state.total}
					pageSize={this.state.per_page}
					onPageChange={(pageIndex) =>
						this.setState({ page: pageIndex }, () =>
							this.loadPushNotifications()
						)
					}
					onPageSizeChange={(pageSize, pageIndex) =>
						this.setState(
							{
								per_page: pageSize,
								page: pageIndex,
							},
							() => this.loadPushNotifications()
						)
					}
					data={this.state.push_notifications}
					sorted={this.state.sorted}
					onSortedChange={(newSorted, column, shiftKey) => {
						this.setState(
							{
								sorted: newSorted,
								page: 0,
							},
							() => {
								this.loadPushNotifications();
							}
						);
					}}
					manual
					hideSelect
				/>
				<ConfirmationModalTypeB
					wideClass={"narrow"}
					isOpen={this.state.deteletNotificationModal}
					openModal={this.state.deteletNotificationModal}
					onConfirmAction={() => this.deteletNotification()}
					onCancelAction={() => {
						this.setState({ deteletNotificationModal: false });
					}}
					closeModal={() => {
						this.setState({ deteletNotificationModal: false });
					}}
					question={
						"Are you sure you want to remove this Push Notification?"
					}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>
				<Modal
					isOpen={this.state.editNotificationModal}
					onRequestClose={() => {
						this.closeModal();
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditNotificationModal
					  forFederation={true}
						user={this.props.user}
						closeModal={() => this.closeModal()}
						push_notification_setting={
							this.state.selectedNotification
						}
						setTableRefresh={(value) =>
							this.props.setTableRefresh(value)
						}
					/>
				</Modal>

				<Modal
					isOpen={this.state.copyNotificationModal}
					onRequestClose={() => {
						this.closeModal();
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<CopyNotificationModal
						forFederation={true}
						onCancelAction={() => {
							this.setState({ copyNotificationModal: false });
						}}
						closeModal={() => {
							this.setState({ copyNotificationModal: false });
						}}
						push_notification_setting={
							this.state.selectedNotification
						}
						setTableRefresh={(value) =>
							this.props.setTableRefresh(value)
						}
						associationId={this.props.associationId}
						user={this.props.user}
					/>
				</Modal>

				<Modal
					isOpen={this.state.reviewNotificationModal}
					onRequestClose={() => {
						this.closeModal();
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<ReviewNotificationModal
						associationId={this.props.associationId}
						user={this.props.user}
						selectedNotification={this.state.selectedNotification}
						onCancelAction={() => {
							this.setState({ reviewNotificationModal: false });
						}}
						closeModal={() => {
							this.setState({ reviewNotificationModal: false });
						}}
						setTableRefresh={(value) =>
							this.props.setTableRefresh(value)
						}
					/>
				</Modal>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		access: state.user.access,
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Table);
