import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import EditAlertModal from '../../../Modals/EditAlertModal';
import EditMobileVersionModal from "../../../Modals/EditMobileVersionModal";
import AlertsService from "../../../../services/api/alerts";
import {connect} from "react-redux";

class AlertManagement extends Component {

	constructor() {
		super();
		this.state = {
            editAlertModalIsOpen: false,
			editMobileVersionModalIsOpen: false,
			alerts: {},
			versions: {}
        };
        this.openEditAlertModal = this.openEditAlertModal.bind(this);
	}

	componentDidMount() {
		AlertsService.getAlerts('all', 'all')
			.then(res => {
				let alerts = {};
				res.product_alerts.forEach(alert => {
					alerts[alert.source] = alert;
				});
				this.setState( {
					alerts: alerts
				})
			})
			.catch(err => {
				console.log(err)
			});
		AlertsService.getVersionNumbers()
			.then(res => {
				let versions = {};
				res.version_numbers.forEach(version => {
					versions[version.source] = version;
				});
				this.setState( {
					versions: versions
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	openEditAlertModal(type) {
		this.setState({
			editAlertModalIsOpen: type
		})
	}

	closeEditAlertModal(data) {
		let alerts = this.state.alerts;
		if (data) alerts[this.state.editAlertModalIsOpen] = data;
		this.setState({
			editAlertModalIsOpen: false,
			alerts: alerts
		})
	}

	openEditMobileVersionModal() {
		this.setState({
			editMobileVersionModalIsOpen: true
		})
	}

	closeEditMobileVersionModal(data) {
		this.setState({
			editMobileVersionModalIsOpen: false,
			versions: data? data : this.state.versions
		})
	}

	render() {
		if (!this.props.access.notification_user) {
			this.props.history.push(this.props.access.homepage);
		}
		return (
            <Fragment>

                <div className="columns">

                    <div className="row">
                        <div className="col">
                            <div className="panel">
								<div className="panel__head">
									Admin Portal Alert
									<div className='panel__end'>
										{this.state.alerts.admin && this.state.alerts.admin.active ?
											<span className="pill green">Alert is Active</span>
											:
											<span className="pill white">Alert is not Active</span>
										}
									</div>
								</div>
                                <div className="panel__body">
                                    <div className="row">
                                        <div className="col auto push-right">
                                            <button onClick={()=>this.openEditAlertModal('admin')} className="btn lnk">Edit</button>
                                        </div>
                                    </div>
									<div className={"row margin-bottom-32"}>
										<div className={"col"}>
											<strong className={"medium"}>The "Banner Alert Message" will be displayed at the top of each page within the Admin Portal. When the banner is clicked, a modal will open and display the Message Title and Body.</strong>
										</div>
									</div>
                                    <div className="row ">
                                        <div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Banner Alert Message</label>
												<input disabled={true}
													   value={this.state.alerts.admin && this.state.alerts.admin.banner_alert_message}
													   autoComplete="off"
													   type={"text"}/>
											</div>
										</div>
                                    </div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Message Title</label>
												<input disabled={true}
													   autoComplete="off"
													   value={this.state.alerts.admin && this.state.alerts.admin.message_title}
													   type={"text"}/>
											</div>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Message Body</label>
												<textarea disabled={true}
														  className={"large-alert"}
														  value={this.state.alerts.admin && this.state.alerts.admin.message_body}/>
											</div>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="active_for">Display alert for:</label>
												<input disabled={true}
													   autoComplete="off"
													   value={this.state.alerts.admin && this.state.alerts.admin.active_for}
													   type={"text"}/>
											</div>
										</div>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>

					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">
									GHIN.com Alert
									<div className='panel__end'>
										{this.state.alerts.ghin && this.state.alerts.ghin.active ?
											<span className="pill green">Alert is Active</span>
											:
											<span className="pill white">Alert is not Active</span>
										}
									</div>
								</div>
								<div className="panel__body">
									<div className="row">
										<div className="col auto push-right">
											<button onClick={()=>this.openEditAlertModal('ghin')} className="btn lnk">Edit</button>
										</div>
									</div>
									<div className={"row margin-bottom-32"}>
										<div className={"col"}>
											<strong className={"medium"}>The "Banner Alert Message" will be displayed at the top of GHIN.com.  When the banner is clicked, a modal will open and display the Message Title and Body.</strong>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Banner Alert Message</label>
												<input disabled={true}
													   value={this.state.alerts.ghin && this.state.alerts.ghin.banner_alert_message}
													   autoComplete="off"
													   type={"text"}/>
											</div>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Message Title</label>
												<input disabled={true}
													   autoComplete="off"
													   value={this.state.alerts.ghin && this.state.alerts.ghin.message_title}
													   type={"text"}/>
											</div>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Message Body</label>
												<textarea disabled={true} className={"large-alert"} value={this.state.alerts.ghin && this.state.alerts.ghin.message_body}/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">
									GHIN Mobile App Alert
									<div className='panel__end'>
										{this.state.alerts.mobile && this.state.alerts.mobile.active ?
											<span className="pill green">Alert is Active</span>
											:
											<span className="pill white">Alert is not Active</span>
										}
									</div>
								</div>
								<div className="panel__body">
									<div className="row">
										<div className="col auto push-right">
											<button onClick={()=>this.openEditAlertModal('mobile')} className="btn lnk">Edit</button>
										</div>
									</div>
									<div className={"row margin-bottom-32"}>
										<div className={"col"}>
											<strong className={"medium"}>The GHIN Mobile App Alert will display as a pop-up when the golfer accesses the app.</strong>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Message Title</label>
												<input disabled={true}
													   value={this.state.alerts.mobile && this.state.alerts.mobile.message_title}
													   autoComplete="off"
													   type={"text"}/>
											</div>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Message Body</label>
												<textarea disabled={true} className={"large-alert"} value={this.state.alerts.mobile && this.state.alerts.mobile.message_body}/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">
									GHIN Mobile App Version Numbers
								</div>
								<div className="panel__body">
									<div className="row">
										<div className="col auto push-right">
											<button onClick={()=>this.openEditMobileVersionModal()} className="btn lnk">Edit</button>
										</div>
									</div>
									<div className={"row margin-bottom-32"}>
										<div className={"col"}>
											<strong className={"medium"}>The GHIN Mobile Apps will display a pop-up when the golfer accesses the app and the version number of the installed app is lower than the server version number.</strong>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">iOS Bundle Short Version</label>
												<input disabled={true}
													   value={this.state.versions.ios && this.state.versions.ios.value}
													   autoComplete="off"
													   type={"text"}/>
											</div>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Android Version Name</label>
												<input disabled={true}
													   value={this.state.versions.android && this.state.versions.android.value}
													   autoComplete="off"
													   type={"text"}/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

                </div>

				<Modal
					isOpen={this.state.editAlertModalIsOpen ? true : false}
					onRequestClose={()=>{this.closeEditAlertModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={false}
					shouldFocusAfterRender={false}
				>
					<EditAlertModal data={this.state.alerts[this.state.editAlertModalIsOpen]} type={this.state.editAlertModalIsOpen} closeModal={(data)=>{this.closeEditAlertModal(data)}} />
				</Modal>

				<Modal
					isOpen={this.state.editMobileVersionModalIsOpen ? true : false}
					onRequestClose={()=>{this.closeEditMobileVersionModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={false}
					shouldFocusAfterRender={false}
				>
					<EditMobileVersionModal data={this.state.versions} profile={this.props.federation} closeModal={(data)=>{this.closeEditMobileVersionModal(data)}} />
				</Modal>

            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		access : state.user.access
	};
}

export default connect(mapStateToProps, null)(AlertManagement)
