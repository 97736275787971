import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputField} from "../../inputs/InputField";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import AlertsService from "../../services/api/alerts";
import {SelectInput} from "../../inputs/Select";

class EditAlertForm extends Component {


	constructor(props){
		super(props);
		this.state = {
			active: true,
			active_for: null,
      confirmAction: false,
			active_changed: false,
			options: [{ label: "All Association Admin", value: "Association Admins" },
								{ label: "All Club Admin", value: "Club Admins" },
				        { label: "All Association Admin and All Club Admin", value: "All Admins" }],
		}
    this.addGhinSure = "Are you sure you want to display this alert on GHIN.com?";
    this.removeGhinSure = "Are you sure you want to remove this alert from displaying on GHIN.com?";

    this.addAdminSure = "Are you sure you want to display this alert within the Admin Portal?";
    this.removeAdminSure = "Are you sure you want to remove this alert from displaying within the Admin Portal?"

    this.addMobileSure = "Are you sure you want to display this alert within the GHIN Mobile App?";
    this.removeMobiSure = "Are you sure you want to remove this alert from displaying within the GHIN Mobile App?";
	}

	onSubmit(values, dispatch, props) {
		values.active = values.active ? "true" : "false";
		if (values.active_for){
			values.active_for = values.active_for.value
		}

		AlertsService.updateAlert(values)
			.then( res => {
				props.close(res.product_alert)
			})
			.catch(err => {console.log(err)})
	}

	showConfirm() {
		if(this.state.active_changed === true){
			this.setState({confirmAction: true});
			this.props.confirmModal();
		}
	}

	componentDidMount() {
		const active_for_label = { 'All Admins': 'All Association Admin and All Club Admin', 'Club Admins': 'All Club Admin', 'Association Admins': 'All Association Admin' }
		if(this.props.data) {
			const active_for = {label: active_for_label[this.props.data.active_for], value: this.props.data.active_for}
			this.setState({active: this.props.data.active});
			this.props.initialize({...this.props.data, active_for: active_for});
		} else {
			this.setState({active: false});
			this.props.initialize({
				banner_alert_message: "",
				message_title: "",
				message_body: "",
				active: false,
				source: this.props.type,
				federation_id: 1
			});
		}
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
    let active = this.state.active;
		let message = "";
    let youSureMessage = "";
		let adminPortalAlert = false;
		switch (this.props.type) {
			case 'ghin' :
				message = 'The "Banner Alert Message" will be displayed at the top of GHIN.com.  When the banner is clicked, a modal will open and display the Message Title and Body.';
				youSureMessage = active ? this.addGhinSure : this.removeGhinSure;
        break;
			case 'admin' :
				message = 'The "Banner Alert Message" will be displayed at the top of each page within the Admin Portal. When the banner is clicked, a modal will open and display the Message Title and Body.';
				youSureMessage = active ? this.addAdminSure : this.removeAdminSure;
				adminPortalAlert = true;
        break;
			default:
				message = "The GHIN Mobile App Alert will display as a pop-up when the golfer accesses the app.";
        youSureMessage = active ? this.addMobileSure : this.removeMobiSure;
				break;
		}
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
          {this.state.confirmAction?
            <Fragment>
              <h2>{youSureMessage}</h2>
              <div className="row">
                <div className="col is-1-of-2">
                  <button type={'button'} onClick={() => this.props.close(null)} className="btn fill gray">Cancel</button>
                </div>
                <div className="col is-1-of-2">
                  <button type={'submit'} ref={'submit'} className="btn fill blue">Confirm</button>
                </div>
              </div>
            </Fragment>
          :
            <Fragment>
						{adminPortalAlert===false?
							<Fragment>
								<div className="row">
	                <div className="col auto push-right">
	                  <Field
	                    onClick={
	                      () => {
	                        this.setState({active: !this.state.active, active_changed: true});
	                      }
	                    }
	                    checked={this.state.active}
	                    id={'active'}
	                    value={true}
	                    name={'active'}
	                    component={'input'}
	                    type="checkbox"
	                  />
	                  <label htmlFor={'active'}>Display Alert</label>
	                </div>
	              </div>
							</Fragment>
							:
							<Fragment></Fragment>
						}
              <div className={"row margin-bottom-32"}>
                <div className={"col"}>
                  <strong className={"medium"}>{message}</strong>
                </div>
              </div>
              {this.props.type !== "mobile" && <div className="row">
                <div className="col">
                  <label htmlFor={'banner_alert_message'}>Banner Alert Message</label>
                  <Field component={InputField}
                      type="text"
                      name={'banner_alert_message'}
                      id={'banner_alert_message'} />

                </div>
              </div>}
              <div className="row">
                <div className="col">
                  <label htmlFor={'message_title'}>Message Title</label>
                  <Field component={InputField}
                      type="text"
                      name={'message_title'}
                      id={'message_title'} />

                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor={'message_body'}>Message Body</label>
                  <Field component={"textarea"}
                      className={"large-alert"}
                      name={'message_body'}
                      id={'message_body'} />

                </div>
              </div>
							{adminPortalAlert?
		            <Fragment>
									<div className="row">
										<div className="col is-3-of-4">
											<label htmlFor="active_for">Display alert for: </label>
											<Field
												name={'active_for'}
												component={SelectInput}
												className="react-select-container"
												classNamePrefix="react-select"
												options={this.state.options}
												isSearchable={false}
												/>
										</div>
										<div className="col auto push-right center-checkbox">
											<Field
												onClick={
													() => {
														this.setState({active: !this.state.active, active_changed: true});
													}
												}
												checked={this.state.active}
												id={'active'}
												value={true}
												name={'active'}
												component={'input'}
												type="checkbox"
											/>
											<label htmlFor={'active'}>Display Alert</label>
										</div>
									</div>
								</Fragment>
								:
								<Fragment></Fragment>
							}
              <div className="row">
                  <div className="col is-1-of-2">
                      <button type={'button'} className="btn fill gray" onClick={() => this.props.close(null)}>Cancel</button>
                  </div>
                  <div className="col is-1-of-2">
                    <button
                      disabled={pristine || submitting}
                      type={'submit'}
											ref={'submit'}
                      className="btn fill blue"
                      onClick={() => {this.showConfirm()}}
                    >
                      Save
                    </button>
                  </div>
              </div>
            </Fragment>
          }
				</form>
			</Fragment>
		);
	}
}


EditAlertForm = reduxForm({
	form: 'editAlertForm',
	destroyOnUnmount: true
})(EditAlertForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditAlertForm));
