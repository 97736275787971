import { Fragment } from "react";
import RejectGolferForm from "../Forms/RejectGolferForm";

export default function RejectGolferModal(props) {
  return (
    <Fragment>
      <div className="modal__container narrow">
        <div className="modal__head">
          <h2 className="modal__title">Reject Golfer</h2>
          <button className="modal__close" onClick={() => props.closeModal()}><i className="material-icons-outlined">clear</i></button>
        </div>
        <div className="modal__body">
          <RejectGolferForm {...props} />
        </div>
      </div>
    </Fragment>
  )
}