export class ImageService {
	static convertToBase64(file) {
		return new Promise((resolve, cancel) => {
			const reader = new FileReader();
			reader.onload = function (e) {
				resolve(e.target.result);
			};
			reader.readAsDataURL(file);
		})
	}

	static getImageDimensions(image) {
		return new Promise((resolve, cancel) => {
			let i = new Image();
			i.src = image;
			i.onload = function () {
				resolve({
					width: this.width,
					height: this.height
				});
			}
		});
	}

}
