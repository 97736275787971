import React, {Component, Fragment} from 'react';
import {Picky} from 'react-picky';
import 'react-picky/dist/picky.css'; // Include CSS
import Select from 'react-select';

export default class GhinFilterSelect extends Component {

    render() {
        const {options, value, onChange, label, singleChoice, disabled, isSearchable, hideSelectAllOption, numberDisplayed, includeFilter} = this.props;

        const computedOptions = options.map((item) => {
            if (typeof item === 'string') {
                return {
                    label: item,
                    value: item
                }
            } else {
                return item;
            }
        });


        if (singleChoice) {
            return (
                <Fragment>
                    <label>{label}</label>
                    <Select
                        value={value}
                        onChange={onChange}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={
                            hideSelectAllOption ?
								computedOptions
								:
								[{label: "Select All", value: undefined}, ...computedOptions]

                        }
						isDisabled={disabled}
                        isSearchable={isSearchable === true}
                        isMulti={false}
                    />
                </Fragment>
            );
        }


        return (
           <Fragment>
               <label>{label}</label>
               <Picky
                   numberDisplayed = {numberDisplayed}
                   className={this.props.widthAuto === false ? 'width__100' : ''}
                   options={computedOptions}
                   value={value || []}
                   multiple={true}
                   includeSelectAll={true}
                   includeFilter={includeFilter ? includeFilter : false}
                   onChange={onChange}
                   dropdownHeight={600}
				   disabled={disabled}
                   valueKey="value"
                   labelKey="label"
                   placeholder='Select...'

               />
           </Fragment>
        );
    }
}
