import { Fragment, useEffect, useState } from "react";
import Modal from 'react-modal';
import EditAssociationAlertModal from "../../../Modals/EditAssociationAlertModal";
import AssociationAlertsService from "../../../../services/api/associationAlerts";

export default function AlertAssoc(props) {

  const [editAlertModalIsOpen, setEditAlertModalIsOpen] = useState(false);
  const [alert, setAlert] = useState({});

  useEffect(() => {
    AssociationAlertsService.getAlert(props.associationId)
      .then(res => {
        setAlert(res.association_alert);
      })
      .catch(err => {
        console.log(err);
      })
  }, [props.associationId]);

  const closeEditAlertModal = (data) => {
    if (data) setAlert(data);
    setEditAlertModalIsOpen(false);
  };

		return (
      <Fragment>

        <div className="columns">
          <div className="row">
            <div className="col">
              <div className="panel">
								<div className="panel__head">
								  Admin Portal Alert
									<div className='panel__end'>
										{alert && alert.active ?
											<span className="pill green">Alert is Active</span>
											:
											<span className="pill white">Alert is not Active</span>
										}
									</div>
								</div>
                <div className="panel__body">
                  <div className="row">
                    <div className="col auto push-right">
                      {props.canEditAssociation && <button onClick={() => setEditAlertModalIsOpen(true)} className="btn lnk">Edit</button>}
                    </div>
                  </div>
									<div className={"row margin-bottom-32"}>
										<div className={"col"}>
											<strong className={"medium"}>The content in the “Banner Alert Message” field will be displayed at the top of each page within the Admin Portal for club admin within your association.  When the banner is clicked, a modal will open and display the content of the “Message Title” and “Message Body” fields.  Uncheck “Display Alert” to stop the message from displaying.</strong>
										</div>
									</div>
                  <div className="row">
                    <div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Banner Alert Message</label>
												<input disabled={true}
													   value={alert.banner_alert_message || ''}
													   autoComplete="off"
													   type={"text"}/>
											</div>
										</div>
                  </div>
									<div className="row">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Message Title</label>
												<input disabled={true}
													   autoComplete="off"
													   value={alert.message_title || ''}
													   type={"text"}/>
											</div>
										</div>
									</div>
									<div className="row ">
										<div className="col is-4-of-6 centered">
											<div className="col">
												<label htmlFor="facility-name">Message Body</label>
												<textarea disabled={true}
														  className={"large-alert"}
														  value={alert ? alert.message_body : ''}/>
											</div>
										</div>
									</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
					isOpen={editAlertModalIsOpen}
					onRequestClose={() => closeEditAlertModal()}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={false}
					shouldFocusAfterRender={false}
				>
        <EditAssociationAlertModal assocId={props.associationId} data={alert} closeModal={(data) => closeEditAlertModal(data)} />
				</Modal>

      </Fragment>
    );
}
