import React, {Fragment, useEffect, useState} from "react";
import GhinTable from "../../../shared/GhinTable";
import ClubService from "../../../../services/api/club";

export default function ClubMembershipFees(props) {
  const [clubMembershipFees, setClubMembershipFees] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      setLoading(true);
      ClubService.getMembershipFees(props.club.id).then((res) => {
        if (cancel) return;
        setClubMembershipFees(res.club_membership_fees);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    }

    fetchData();

    return () => {
      cancel = true;
    }
  }, [props.club.id])

  const formatGender = (gender) => {
    if (gender === "male") return "Male";
    else if (gender === "female") return "Female";
    else return "Male & Female";
  };

  const formatAge = (minimumAge, maximumAge) => {
    if (minimumAge && maximumAge) return `${minimumAge} to ${maximumAge}`;
    else if (!maximumAge && minimumAge)
      return `Age ${minimumAge} and older`;
    else if (!minimumAge && maximumAge)
      return `No older than ${maximumAge}`;
    else return "No age restrictions";
  };

  const calculateTotalAmount = (fee) => {
    return parseFloat(fee.golf_association_membership_amount || 0.0) +
      parseFloat(fee.golf_association_fee_amount || 0.0) +
      parseFloat(fee.amount || 0.0)
  };

  const columns = [
    {
      Header: "Membership Fee Name",
      colClassName: "col_fee_name",
      accessor: "name",
    },
    {
      Header: "Description",
      colClassName: "col_fee_description",
      accessor: "description",
    },
    {
      Header: "Club Fee",
      colClassName: "col_fee",
      Cell: (data) => {
        return `$${data.original.amount.toFixed(2)}`;
      }
    },
    {
      Header: "Eligibility Requirements",
      colClassName: "col_eligibility",
      Cell: (data) => {
        return (
          <ul>
            <li>Gender:{" "}{formatGender(data.original.gender)}</li>
            <li>Age:{" "}{formatAge(data.original.minimum_age, data.original.maximum_age)}</li>
          </ul>
        );
      }
    },
    {
      Header: "Association Membership Code",
      Cell: (data) => {
        return (
          <ul>
            <li>{`${data.original.golf_association_membership_name} - $${parseFloat(data.original.golf_association_membership_amount).toFixed(2)}`}</li>
            <li>{data.original.golf_association_fee_name ? `${data.original.golf_association_fee_name} - $${parseFloat(data.original.golf_association_fee_amount).toFixed(2)}` : null}</li>
          </ul>
        );
      }
    },
    {
      Header: "Total Fee",
      colClassName: "col_fee",
      Cell: (data) => {
        return <center> ${calculateTotalAmount(data.original).toFixed(2)} </center>;
      }
    }
  ];

  return (
    <Fragment>
      <div className="panel">
        <div className="panel__head">
          Club Membership Fees
          { !props.canEditAssociation && <span className="red-note">Please contact your association to change these settings.</span> }
        </div>
        <div className='panel__body'>
          <div className="row">
            <div className="col">
              <p>
                The table below shows available Membership Fees for GHIN Join & Renew. The Club Fee indicates the amount
                paid to the Club, the Association Membership Code and Online Transaction Fee shows the amount paid to
                the Association, and the Total Fee is the amount a golfer will pay to join online.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col regular_font">
              <GhinTable
                idKey={"id"}
                columns={columns}
                hideSelect={true}
                hidePagination={true}
                sortable={false}
                data={clubMembershipFees}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}