import React, {Component, Fragment} from 'react';

import EditClubStatusForm from '../Forms/EditClubStatusForm';
import SuccessModal from "./SuccessModal";

export default class EditClubStatusModal extends Component {

	constructor(props){
		super(props);
		this.state = {
			success: false,
		}
	}
	render() {
		const clubs = [...this.props.clubs];

		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">{this.props.type && (this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1))} Clubs</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

						{this.state.success ?
							<SuccessModal
								openModal={this.state.success}
								onMainAction={() => {
									this.props.closeModal('refresh')
								}}
								warning={this.state.data.unsuccessfully_activated_clubs > 0 || this.state.data.unsuccessfully_inactivated_clubs > 0}
								warningTitle={(this.state.data.successfully_activated_clubs > 0 || this.state.data.successfully_inactivated_clubs > 0) ? 'Some records need your review' : 'Please Review'}
								message={this.props.type ==="activate" ?
									<Fragment>
										{this.state.data.successfully_activated_clubs !== 0 && <p>{this.state.data.successfully_activated_clubs} { this.state.data.successfully_activated_clubs > 1 ? "clubs have" : "club has"} been <span className={'underline'}>activated</span> successfully.</p>}
										{this.state.data.unsuccessfully_activated_clubs !== 0 && <p><span className={'validation__message is-error'}>{this.state.data.unsuccessfully_activated_clubs} {this.state.data.unsuccessfully_activated_clubs > 1 ? "clubs" : "club"} could not be <span className={'underline'}>activated</span>.</span></p>}
									</Fragment>
									:
									<Fragment>
										{this.state.data.successfully_inactivated_clubs !== 0 && <p>{this.state.data.successfully_inactivated_clubs} { this.state.data.successfully_inactivated_clubs > 1 ? "clubs have" : "club has"} been <span className={'underline'}>inactivated</span> successfully.</p>}
										{this.state.data.unsuccessfully_inactivated_clubs !== 0 && <p><span className={'validation__message is-error'}>{this.state.data.unsuccessfully_inactivated_clubs} {this.state.data.unsuccessfully_inactivated_clubs > 1 ? "clubs" : "club"} could not be <span className={'underline'}>inactivated</span>.</span></p>}
									</Fragment>

								}
								closeModal={() => {
									this.props.closeModal('refresh')
								}}
                club_activation={this.state.data.successfully_activated_clubs > 0}
								golfer={this.state.golfer}
								mainLabel={"OK"}
								specialButton={this.state.data.download_url ? this.state.data.download_url : null}
							/>
							:
							<EditClubStatusForm  type={this.props.type} isAllSelected={this.props.isAllSelected} filters={this.props.filters} clubs={clubs} onSuccess={(now, data)=>this.setState({success: true, now, data})} associationId={this.props.assoc} close={(data)=>this.props.closeModal(data)}/>}
                    </div>

                </div>

            </Fragment>
		);
	}
}
