import React, { Component, Fragment } from "react";
import UploadDefaultKioskImage from "../../../../inputs/UploadDefaultKioskImage";
import UploadDefaultMobileImage from "../../../../inputs/UploadDefaultMobileImage";
import { FederationAccount } from "../../../../services/api/federationAccount";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAlert } from "../../../shared/actions";

class PromotionalImages extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editImageInformationModalIsOpen: false,
			editKioskImageInformationModalIsOpen: false,
			settings: null,
			image: null,
			error: null,
		};
	}

	closeModal(data) {
		this.setState({
			settings: data ? data : this.state.settings,
		});
	}

	removeImage(type) {
		FederationAccount.removeDefaultImage(
			this.props.associationId,
			this.state.settings.id,
			{ ["delete_" + type]: true }
		)
			.then((res) => {
				this.setState({ settings: res.federation_settings });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	saveImage(file, type) {
		FederationAccount.editDefaultImageMobileApp(
			this.props.federationId,
			this.state.settings.id,
			{ [type]: file }
		)
			.then((res) => {
				this.setState({ settings: res.federation_settings });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	setImageURL(data, type, successfullyCallBack, errorCallBack) {
		if (data) {
			FederationAccount.editDefaultGolferProducts(
				this.props.federationId,
				this.state.settings.id,
				{
					[type + "_ad_url"]: data.ad_image_url,
					mobile_image1_ad_tag: data.mobile_image1_ad_tag,
					mobile_image2_ad_tag: data.mobile_image2_ad_tag,
					["is_" + type + "_ad_url_deep_link"]: data.is_deep_link,
				}
			)
				.then((res) => {
					this.setState({
						settings: res.federation_settings,
						error: null,
					});
					successfullyCallBack(data);
				})
				.catch((err) => {
					this.setState({ error: err.data.error });
					console.error(err.data.error);
				});
			errorCallBack(data);
		}
	}

	setKioskImageURL(data, id, index, successfullyCallBack, errorCallBack) {
		let kiosk_images = this.state.kiosk_images;

		if (data) {
			FederationAccount.editDefaultKioskImage(
				this.props.federationId,
				id,
				{
					kiosk_image: { tag: data.tag },
				}
			)
				.then((res) => {
					kiosk_images[index] = res.federation_kiosk_image;
					this.setState({ kiosk_images, error: null });
					successfullyCallBack(data);
				})
				.catch((err) => {
					this.setState({ error: err.data.error });
					console.error(err.data.error);
				});
			errorCallBack(data);
		}
	}

	addNewDefaultKioskImage() {
		let kiosk_images = this.state.kiosk_images;
		FederationAccount.addDefaultKioskImage(this.props.federationId)
			.then((res) => {
				kiosk_images.push(res.federation_kiosk_image);
				this.setState({ kiosk_images });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	saveKioskImage(file, id, index) {
		let kiosk_images = this.state.kiosk_images;
		FederationAccount.uploadDefaultKioskImage(
			this.props.federationId,
			id,
			file
		)
			.then((res) => {
				kiosk_images[index] = res.federation_kiosk_image;
				this.setState({ kiosk_images });
			})
			.catch((err) => {
				console.error(err);
			});
	}
	removeKioskImage(id, index) {
		let kiosk_images = this.state.kiosk_images;
		FederationAccount.removeDefaultKioskImage(this.props.federationId, id)
			.then((res) => {
				kiosk_images[index] = res.federation_kiosk_image;
				this.setState({ kiosk_images });
			})
			.catch((err) => {
				console.error(err);
			});
	}
	deleteKioskImage(id, index) {
		let kiosk_images = this.state.kiosk_images;
		FederationAccount.deleteDefaultKioskImage(this.props.federationId, id)
			.then((res) => {
				kiosk_images.splice(index, 1);
				this.setState({ kiosk_images });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	componentDidMount() {
		FederationAccount.getGolferProductsForFederation(
			this.props.federationId
		)
			.then((res) => {
				FederationAccount.getFederationKioskImages(
					this.props.federationId
				)
					.then((response) => {
						this.setState({
							settings: res.federation_settings,
							kiosk_images: response.federation_kiosk_images,
						});
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((error) => {
				console.error(error);
			});
	}

	render() {
		return (
			<Fragment>
				{this.state.settings && (
					<Fragment>
						<div className="row">
							<div className="col">
								<div className="panel">
									<div className="panel__head">
										Default Promotional Images on Kiosk
									</div>
									<div className="panel__body">
										<h5 className="upload__title">
											These images will appear at the
											bottom of all screens within the
											Kiosk when no images have been
											uploaded for an Allied Golf
											Association.
										</h5>
										<ul className="upload__requirements">
											<li>Required Width = 728 px</li>
											<li>Required Height = 90 px</li>
											<li>Required Format = PNG, JPG</li>
										</ul>
										<div className="columns">
											{this.state.kiosk_images &&
												this.state.kiosk_images.map(
													(kiosk_image, key) => {
														return (
															<div className="row">
																<div className="col">
																	<div className="panel">
																		<UploadDefaultKioskImage
																			title={`Promotional Image on Kiosk (${
																				key +
																				1
																			})`}
																			key={
																				kiosk_image.id
																			}
																			image={
																				kiosk_image.image_url
																			}
																			tag={
																				kiosk_image.tag
																			}
																			removable={
																				true
																			}
																			requiredHeight={
																				90
																			}
																			requiredWidth={
																				728
																			}
																			errors={
																				this
																					.state
																					.error
																			}
																			onSave={(
																				data
																			) => {
																				this.saveKioskImage(
																					data,
																					kiosk_image.id,
																					key
																				);
																			}}
																			onRemove={() => {
																				this.removeKioskImage(
																					kiosk_image.id,
																					key
																				);
																			}}
																			onDelete={() => {
																				this.deleteKioskImage(
																					kiosk_image.id,
																					key
																				);
																			}}
																			onsetKioskImageURL={(
																				data,
																				successfullyCallBack,
																				errorCallBack
																			) =>
																				this.setKioskImageURL(
																					data,
																					kiosk_image.id,
																					key,
																					successfullyCallBack,
																					errorCallBack
																				)
																			}
																		/>
																	</div>
																</div>
															</div>
														);
													}
												)}

											{
												<div className="row">
													<div className="col auto push-right">
														<button
															className="btn fill green"
															onClick={() => {
																this.addNewDefaultKioskImage();
															}}
														>
															Add Promotional
															Images on Kiosk
														</button>
													</div>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<div className="panel">
									<div className="panel__head">
										Default Promotional Images on Mobile App
									</div>
									<div className="panel__body">
										<h5 className="upload__title">
											These images will appear on the
											Golfer Home screen of the GHIN
											Mobile App when no images have been
											uploaded for an Allied Golf
											Association.
										</h5>
										<ul className="upload__requirements">
											<li>Required Width = 1920 px</li>
											<li>Required Height = 800 px</li>
											<li>Required Format = PNG, JPG</li>
										</ul>
										<div className="columns">
											<div className="row">
												<div className="col">
													<div className="panel">
														<UploadDefaultMobileImage
															title={
																"Sentry Image on Mobile App - Permanent Placement"
															}
															image={
																this.state
																	.settings
																	.mobile_app_promotional_image1
															}
															ad_image_url={
																this.state
																	.settings
																	.mobile_image1_ad_url
															}
															mobile_image1_ad_tag={
																this.state
																	.settings
																	.mobile_image1_ad_tag
															}
															is_deep_link={
																this.state
																	.settings
																	.is_mobile_image1_ad_url_deep_link
															}
															requiredWidth={1920}
															requiredHeight={800}
															display_image_name={
																"mobile_image1_ad_tag"
															}
															errors={
																this.state.error
															}
															onSave={(data) => {
																this.saveImage(
																	data,
																	"mobile_app_promotional_image1"
																);
															}}
															onRemove={() => {
																this.removeImage(
																	"image1"
																);
															}}
															onsetImageURL={(
																data,
																successfullyCallBack,
																errorCallBack
															) =>
																this.setImageURL(
																	data,
																	"mobile_image1",
																	successfullyCallBack,
																	errorCallBack
																)
															}
														/>
													</div>
												</div>
											</div>
											{
												<div className="row">
													<div className="col">
														<div className="panel">
															<UploadDefaultMobileImage
																title={
																	"Sentry Image on Mobile App - Placement 2"
																}
																image={
																	this.state
																		.settings
																		.mobile_app_promotional_image2
																}
																ad_image_url={
																	this.state
																		.settings
																		.mobile_image2_ad_url
																}
																mobile_image2_ad_tag={
																	this.state
																		.settings
																		.mobile_image2_ad_tag
																}
																is_deep_link={
																	this.state
																		.settings
																		.is_mobile_image2_ad_url_deep_link
																}
																requiredWidth={
																	1920
																}
																requiredHeight={
																	800
																}
																display_image_name={
																	"mobile_image2_ad_tag"
																}
																errors={
																	this.state
																		.error
																}
																onSave={(
																	data
																) => {
																	this.saveImage(
																		data,
																		"mobile_app_promotional_image2"
																	);
																}}
																onRemove={() => {
																	this.removeImage(
																		"image2"
																	);
																}}
																onsetImageURL={(
																	data,
																	successfullyCallBack,
																	errorCallBack
																) =>
																	this.setImageURL(
																		data,
																		"mobile_image2",
																		successfullyCallBack,
																		errorCallBack
																	)
																}
															/>
														</div>
													</div>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(mapDispatchToProps)(PromotionalImages);
