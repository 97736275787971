import React, {Component} from 'react';
import GhinModal from "./GhinModal";
import {CSVLink} from "react-csv";
import PropTypes from 'prop-types';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
class ExportDataModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			type: 'filtered'
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.isOpen && this.props.isOpen) {
			this.setState({type: this.props.isAllSelected ?  'filtered' :  this.props.disableSelected ? 'filtered' : 'selected'})
		}
		if (this.props.isOpen) {
			this.props.onRef(this);
		}
		if (!this.props.isOpen){
			this.props.onRef(undefined);
		}
	}

	render() {
		const {isOpen, onRequestClose, filename} = this.props;
		return (
			<GhinModal
				isOpen={isOpen}
				onRequestClose={onRequestClose}
			>
				<div className="modal__container narrow">

					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
						<h2 className="modal__title">Export to CSV</h2>
						<button className="modal__close" onClick={() => {
							onRequestClose()
						}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">

						<div className="row">
							<div className="col is-1-of-1">
								<span className="label" htmlFor="club-name">What would you like to export <span>*</span></span>
								<div className="radio__container">
									<input
										type="radio"
										className="simple__radio"
										name="filtered"
										checked={this.state.type === 'filtered'}
										id="filtered"
										onChange={() => this.setState({
											type: 'filtered'
										})}
									/>
									<label htmlFor="filtered">Filtered list</label>
									<input
										type="radio"
										className="simple__radio"
										name="selected"
										id="selected"
										disabled={this.props.disableSelected}
										checked={this.state.type === 'selected'}
										onChange={() => this.setState({
											type: 'selected'
										})}
									/>
									<label className={this.props.disableSelected ? 'disabled' : ''} htmlFor="selected">Selected</label>
									{/*<input*/}
									{/*	type="radio"*/}
									{/*	className="simple__radio"*/}
									{/*	name="all"*/}
									{/*	id="all"*/}
									{/*	checked={this.state.type === 'all'}*/}
									{/*	onChange={() => this.setState({*/}
									{/*		type: 'all'*/}
									{/*	})}*/}
									{/*/>*/}
									{/*<label htmlFor="all">Select All</label>*/}
								</div>
							</div>

						</div>


						<div className="row">
						<div className="col is-1-of-2">
							<button type="button" className="btn fill gray" onClick={() => onRequestClose()}>Cancel</button>
							</div>
							<div className="col is-1-of-2">
								{this.props.type === "link" ? this.renderCsvLink(filename) : this.renderCustomButton()}
							</div>


						</div>


					</div>


				</div>

			</GhinModal>
		);
	}

	renderCustomButton() {
		return <button type="submit" ref="submit" onClick={() => {
			return this.props.onDownloadClick(this.state.type);
		}} className="btn fill blue">Submit</button>;
	}

	renderCsvLink = (filename) => {
		return (<CSVLink
			className="btn fill blue"
			filename={filename}
			ref="submit"
			onClick={() => {this.props.type === "link" && this.props.onDownloadClick()}}
			data={this.props.data.filter((i) => this.state.type === "selected" ? i.selected : true)}
			headers={this.props.columns.filter((i) => typeof i.Header === "string" && i.show !== false)
				.map((i) => ({label: i.Header, key: i.accessor}))}>
			Submit
		</CSVLink>)
	}
}

ExportDataModal.defaultProps = {
	columns: [],
	data: [],
	isOpen: false
};

ExportDataModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	selected: PropTypes.bool,
	isAllSelected: PropTypes.bool,
	data: PropTypes.array.isRequired,
	onDownloadClick: PropTypes.func,
	onRequestClose: PropTypes.func,
	filename: PropTypes.string,
	columns: PropTypes.array.isRequired
};

export default withKeyPress(ExportDataModal);
