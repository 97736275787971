import React, {Component, Fragment} from 'react';
import {findDOMNode} from 'react-dom';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {required, isEmailAddress} from "../../inputs/validations";
import {InputField} from "../../inputs/InputField";
import AssociationService from "../../services/api/association";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

const dataTokens = [
	'Golfer First Name',
	'Golfer Last Name',
	'Golfer Full Name',
	'Association Name',
	'Handicap Index',
	'GHIN Number',
	'Association Address 1',
	'Association Address 2',
	'Association City',
	'Association Postal Code',
	'Association Email Address',
	'Association Phone',
	'Association State',
	'Association Country',
	'Low Handicap Index',
	'Revision Date',
	'Home Club'
];

class EditSetupForm extends Component {

	constructor(props) {
			super(props);
			this.state = {
				tokens: false,
				email_settings: {
					from_name: this.props.emailSettings.from_name,
					reply_to_email_address: this.props.emailSettings.reply_to_email_address,
					subject: this.props.emailSettings.subject
				},
				subjectInput: {
						selectionStart: 0,
						selectionEnd: 0
				}
			}
	}

	componentDidMount() {
		this.props.initialize({
			from_name: this.props.emailSettings.from_name,
			reply_to_email_address: this.props.emailSettings.reply_to_email_address,
			subject: this.props.emailSettings.subject
		})
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	selectToken(tokenRaw, e) {
		const token = `[${tokenRaw}]`;
		const start =  this.subjectRef.selectionStart;
		const end =  this.subjectRef.selectionEnd;
		const subject = `${this.state.email_settings.subject.substring(0, start)}${token}${this.state.email_settings.subject.substring(end)}`;
		this.setState({
			email_settings: {
				...this.state.email_settings,
				subject
			}
		}, () => {
			this.props.change('subject',subject)
			this.subjectRef.focus()
			this.subjectRef.setSelectionRange(start + token.length, start + token.length)
		});
	}

	saveSetup() {
		let email_settings = {...this.state.email_settings};
		return AssociationService.editEmailSettings(this.props.associationId, this.props.emailSettings.id, {email_settings: email_settings})
			.then(res => {
				this.props.addAlert({
					type:'success',
					message: 'Setup has been successfully edited'
				});
				this.props.closeModal(res.email_settings);
			})
			.catch(err => {
				console.error(err);
			})
	}

	render() {
		const { pristine, submitting, valid} = this.props;
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={this.handleSubmit} ref={'form'}>
					<div className="row">
						<div className="col is-full">
							<label htmlFor="from_name">From Name <span>*</span></label>
							<Field component={InputField}
							   type="text"
							   validate={[required]}
								 onChange={(e) => this.setState({
									 email_settings:{
										 ...this.state.email_settings,
										 from_name: e.target.value }
								 })}
							   name={'from_name'}
							   id={'from_name'} />
						</div>
					</div>
					<div className="row">
						<div className="col is-full">
							<label htmlFor="reply_to_email_address">Reply-To Email Address <span>*</span></label>
							<Field component={InputField}
								   type="text"
								   validate={[required, isEmailAddress]}
									 onChange={(e) => this.setState({
										 email_settings:{
											 ...this.state.email_settings,
											 reply_to_email_address: e.target.value }
									 })}
								   name={'reply_to_email_address'}
								   id={'reply_to_email_address'} />
						</div>
					</div>
					<div className="col is-full">
						<label htmlFor="subject">Email Subject Line <span>*</span></label>
					</div>
					<div className={"row"}>
						<div className={"col auto jc-c"}>
							<button type={'button'} onClick={() => this.setState({tokens: !this.state.tokens})} className="btn lnk">Tokens<span className={`tokens-toggle ${this.state.tokens ? 'opened' : 'closed'}`}/> </button>
						</div>
					</div>
					{this.state.tokens && <div className="row">
						<div className="col is-full">
							<div className={'data-tokens'}>
								{dataTokens.map((token) => {
									return <button type={'button'} key={token} onClick={(e) => this.selectToken(token, e)} className={'button token'}>{token}</button>
								})}
							</div>
						</div>
					</div>}
					<div className="row">
						<div className="col is-full">
							<Field
								component={InputField}
								type="text"
								validate={[required]}
								onChange={(e) => this.setState({
									email_settings:{
										...this.state.email_settings,
										subject: e.target.value }
								})}
								ref={ref => {
									const node = findDOMNode(ref);
									if (node)
										this.subjectRef = node.querySelector("input");
								}}
								name={'subject'}
								id={'subject'} />
						</div>
					</div>
					<div className="row">
						<div className="col is-1-of-2">
							<button className="btn fill gray" type={'button'} onClick={() => this.props.closeModal()}>Close</button>
						</div>
						<div className="col is-1-of-2">
							<button className="btn fill blue" disabled={pristine || submitting || !valid} type={'button'} onClick={() => this.saveSetup()}>Save</button>
						</div>
					</div>
				</form>
      </Fragment>
		);
	}
}


EditSetupForm = reduxForm({
	form: 'editSetupForm',
	destroyOnUnmount: true
})(EditSetupForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditSetupForm));
