import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect, withRouter} from 'react-router-dom';

import PrimaryGolfer from './PrimaryGolfer';
import ClubMembership from './ClubMembership';
import SubscriptionManagement from './SubscriptionManagement';
import Guardianship from "./Guardianship";
import MinorManagement from "../../Guardian/Account/MinorManagement";
import moment from "moment";

class Account extends Component {
	render() {
		let accessLevel = 0;
		if (this.props.publicView) {
			accessLevel = 1;
		}
		if (this.props.canEditClub && !this.props.publicView) {
			accessLevel = 2;
		}

		let golfer = this.props.golfer;
		const golferAgeInYears = golfer.date_of_birth ? moment().diff(moment(golfer.date_of_birth).format(), 'years') : 30;
		const isMinorGolfer = golferAgeInYears < 13;
		const isJuniorGolfer = golferAgeInYears >= 13 && golferAgeInYears < 19;

		return (
			<Fragment>

				<div className="vertical-tabs__container row">
					<ul className="vertical-tabs col">
						<li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/primary`}>Primary</NavLink></li>
						{golfer.is_trial ? null : process.env.REACT_APP_TYPE !== "hv" && <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/club-memberships`}>Club Memberships</NavLink></li>}
						{golfer.is_trial ? null : <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/subscription-management`}>Subscription Management</NavLink></li>}
						{isMinorGolfer || isJuniorGolfer || this.props.golfer.has_guardians ? <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/guardianship`}>Guardianship</NavLink></li> : null}
						{/** //todo: how to tell if this is a guardian ? **/}
						{this.props.golfer.is_guardian ? <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/minor-management`}>Guardianship</NavLink></li> : null}

					</ul>
					<div className="vertical-tabs__panel col">
						<Switch>
							<Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/primary`}/>
							<Route path={`${this.props.match.url}/primary`} component={(props) =>
								<PrimaryGolfer canEditClub={this.props.canEditClub && !this.props.publicView}
											   updateGolfer={(data) =>this.props.updateGolfer(data)}
											   golfer={this.props.golfer}
											   archived={this.props.archived}
											   publicView={this.props.publicView}
											   associationId={this.props.associationId}
											   clubId={this.props.match.params.id}/> }/>
							{process.env.REACT_APP_TYPE !== "hv" && <Route path={`${this.props.match.path}/club-memberships`} component={() =>
								<ClubMembership canEditClub={this.props.canEditClub && !this.props.publicView}
												canEditAssociation={this.props.canEditAssociation} 
												canDeleteAffiliations={this.props.canDeleteAffiliations && !this.props.publicView}
												golfer={this.props.golfer}
												updateGolfer={() => this.props.updateGolfer()}
												archived={this.props.archived}
												publicView={this.props.publicView}
												associationId={this.props.associationId}
												clubId={this.props.match.params.id}/>} />}
							{<Route path={`${this.props.match.url}/subscription-management`} component={(props) =>
								<SubscriptionManagement canEditClub={this.props.canEditClub && !this.props.publicView}
															 golfer={this.props.golfer}
															 updateGolfer={(data) => this.props.updateGolfer(data)}
															 archived={this.props.archived}
															 publicView={this.props.publicView}
															 associationId={this.props.associationId}
															 clubId={this.props.match.params.id}/>} />}

							{<Route path={`${this.props.match.url}/guardianship`} component={(props) =>
								<Guardianship canEditClub={this.props.canEditClub && !this.props.publicView}
											  publicView={this.props.publicView}
											  golfer={this.props.golfer}
											  updateGolfer={(data) => this.props.updateGolfer(data)}
								/>} />}
							{<Route path={`${this.props.match.url}/minor-management`} component={(props) =>
								<MinorManagement guardian={{...this.props.golfer}} accessLevel={accessLevel} canEditClub={this.props.canEditClub && !this.props.publicView}   publicView={this.props.publicView}/>} />}


						</Switch>
					</div>
				</div>

			</Fragment>
		);
	}
}


export default withRouter(Account);
