import { Fragment } from "react";
import GhinTable from "../shared/GhinTable";
import moment from "moment";

const calculateTotalAmount = (fee) => {
	return parseFloat(fee.golf_association_membership_amount || 0.0) +
		parseFloat(fee.golf_association_fee_amount || 0.0) +
		parseFloat(fee.amount || 0.0)
};

export default function MembershipFeeDetailsModal(props) {
	const fee = props.selectedMembershipFee;
	const totalAmount = calculateTotalAmount(fee);
	const associationMembership = `${fee.golf_association_membership_name} - $${fee.golf_association_membership_amount.toFixed(2)}`;
	const transactionFee = fee.golf_association_fee_name ? `${fee.golf_association_fee_name} - $${fee.golf_association_fee_amount.toFixed(2)}`: null;

	const formatGender = (gender) => {
		if (gender === "male and female") return "Male & Female";
		else if (gender === "male") return "Male";
		else return "Female";
	};

	const formatAge = (minimumAge, maximumAge) => {
		if (minimumAge && maximumAge) return `${minimumAge} to ${maximumAge}`;
		else if (!maximumAge && minimumAge)
			return `Age ${minimumAge} and older`;
		else if (!minimumAge && maximumAge)
			return `No older than ${maximumAge}`;
		else return "No age restrictions";
	};

	const columns = [
		{
			Header: "Membership Fee Name",
			colClassName: "col_fee_name",
			accessor: "name",
		},
		{
			Header: "Description",
			colClassName: "col_fee_description",
			accessor: "description",
		},
		{
			Header: (
				<>
					<h1>Effective</h1>
					<h1>Date</h1>
				</>
			),
			thClassName: "table-align-center",
			colClassName: "col_fee_date",
			accessor: "date",
			Cell: (data) => {
				return (
					<center>
						{moment(data.original.fee_effective_date).format("MM/DD/YYYY")}
					</center>
				);
			},
		},
		{
			Header: "Club Fee",
			colClassName: "col_fee",
			Cell: (data) => {
				return `$${data.original.amount}`;
			},
		},
		{
			Header: "Eligibility Requirements",
			colClassName: "col_requirements",
			Cell: (data) => {
				return (
					<div>
						<p>
							Gender:{" "}
							{formatGender(data.original.gender)}
						</p>
						<p>
							Age:{" "}
							{formatAge(
								data.original.minimum_age,
								data.original.maximum_age
							)}
						</p>
					</div>
				);
			},
		},
		{
			Header: "Association Membership Code",
			Cell: (data) => {
				return (
					<div>
						<p>{associationMembership}</p>
						<p>{transactionFee}</p>
					</div>
				);
			},
		},
		{
			Header: "Total Fee",
			colClassName: "col_fee",
			Cell: (data) => {
				return <center> ${totalAmount.toFixed(2)} </center>;
			},
		},
	];

	return (
		<Fragment>
			<div className="modal__container extra-wide">
				<div className="modal__head">
					<div className="modal__icon">
						<i className="material-icons-outlined">edit</i>
					</div>
					<h2 className="modal__title">
						Standard Membership Fee Details
					</h2>
					<button
						className="modal__close"
						onClick={() => props.closeModal()}
					>
						<i className="material-icons-outlined">clear</i>
					</button>
				</div>
				<div className="modal__body">
					<div className="row">
						<div className="col">
							<h1>
								The table below shows details for this
								membership fee. To edit, close this modal and
								click on Edit.
							</h1>
						</div>
					</div>
					<div className="row">
						<div className="col is-full">
							<GhinTable
								idKey={"id"}
								columns={columns}
								hideSelect={true}
								hidePagination={true}
								sortable={false}
								data={props.data}
							/>
						</div>
					</div>
					<div className="row" style={{marginTop: "0"}}>
						<div className="col is-1-of-2" style={{marginRight: "0", marginLeft: "auto", width: "200px"}}>
							<button
								type={"button"}
								className="btn fill gray"
								onClick={() => props.closeModal()}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
