import React, {Component, Fragment} from 'react';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {required} from "../../inputs/validations";
import GolferService from "../../services/api/golfer";
import {DatePickerInput} from "../../inputs/DatePicker";
import {bindActionCreators} from "redux";
import moment from "moment";


class WithdrawHandicapIndexForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			confirmAction: false
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.props.initialize({expiration_date: new Date(moment().add(1,"days"))})
	}

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));

		data.type = "withdraw";
		data.expiration_date =  moment(data.expiration_date).format("YYYY-MM-DD");

		return GolferService.handicapModification(this.props.golfer.id, data)
			.then(res => {
				this.props.addAlert({type: 'success', message: 'Withdraw of Handicap Index has been successfully applied!'});
				this.props.closeModal(res)
			})
			.catch(err => {
				console.log(err)
			})
	}

	render() {
		const {handleSubmit, submitting} = this.props;

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>

					{submitting ?
						<table style={{width: '100%'}}>
							<tbody>
							<tr>
								<td style={{textAlign: 'center'}}>
									<span className="data-table__loader"></span>
								</td>
							</tr>
							</tbody>
						</table>
						:
						<Fragment>
							{this.state.confirmAction ?
								<Fragment>
									<h2>Are you sure you want to withdraw the Handicap Index for  {this.props.golfer.player_name}?</h2>
									<div className="row">
										<div className="col is-1-of-2">
											<button type={'button'} onClick={() => this.props.closeModal()} className="btn fill gray">Cancel</button>
										</div>
										<div className="col is-1-of-2">
											<button type={'submit'} ref={'submit'} className="btn fill blue">Confirm</button>
										</div>
									</div>
								</Fragment>
								:
								<Fragment>
									<div className="row">
										<div className="col is-1-of-2">
											<label htmlFor="expiration_date">Expiration Date <span>*</span></label>
											<Field minDate={new Date(moment().add(1,"days"))}
												className={'date'}
												showMonthDropdown
												showYearDropdown
												validate={[required]}
												name={'expiration_date'}
												id={'expiration_date'}
												component={DatePickerInput}
												placeholder="Select ... " />
										</div>
									</div>

									<div className="row">
										<div className="col is-1-of-2">
											<button type={'button'} onClick={() => this.props.closeModal()} className="btn fill gray" >Cancel</button>
										</div>
										<div className="col is-1-of-2">
											<button type={'button'} onClick={(e) => {e.preventDefault(); this.setState({confirmAction: true}); this.props.confirmModal()}} className="btn fill blue">Withdraw</button>
										</div>
									</div>
								</Fragment>}
							</Fragment>}
						</form>
					</Fragment>
		);
	}
}

 WithdrawHandicapIndexForm = reduxForm({
	form: ' withdrawHandicapIndexForm',
	destroyOnUnmount: true
})( WithdrawHandicapIndexForm  );

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress( WithdrawHandicapIndexForm));
