import React, {Component, Fragment} from 'react';
import GhinFilterSelect from "../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";
import {checkKeyForDateInput} from "../../inputs/helper.js"
import DatePicker from "react-datepicker";
import moment from "moment";

export default class TableFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			filters: {
				course_name: "",
				score_type: null,
				days_of_week: [
					{label: 'Monday', value: 1},
					{label: 'Tuesday', value: 2},
					{label: 'Wednesday', value: 3},
					{label: 'Thursday', value: 4},
					{label: 'Friday', value: 5},
					{label: 'Saturday', value: 6},
					{label: 'Sunday', value: 7}
				],
				holes_played: null,
				date_ranges: [{label: 'Date Played', value: 'played_at'}],
				from_date_played: null,
				to_date_played: null
			}
		};
	}

	setInitialState() {
		this.setState({
			filters: {
				course_name: null,
				score_type: null,
				days_of_week: [
					{label: 'Monday', value: 1},
					{label: 'Tuesday', value: 2},
					{label: 'Wednesday', value: 3},
					{label: 'Thursday', value: 4},
					{label: 'Friday', value: 5},
					{label: 'Saturday', value: 6},
					{label: 'Sunday', value: 7}
				],
				holes_played: null,
				date_ranges: [{label: 'Date Played', value: 'played_at'}],
				from_date_played: null,
				to_date_played: null
			}
		}, () =>
			this.updateTable()
		)
	}

	componentDidMount() {
		this.updateFilter()
	}

	updateFilter = (name, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		}, () => {
			this.updateTable();
		})
	};

	updateTable = debounce(() => {
		let filters = JSON.parse(JSON.stringify(this.state.filters));
		Object.keys(filters).forEach(f => {
			if (filters[f] !== null) {
				if (Array.isArray(filters[f])) {
					let fv = filters[f];
					fv = fv.map(v => v.value).join(",");
					filters[f] = fv;
				}
				if (typeof filters[f] === 'object') {
					filters[f] = filters[f].value;
				}
			}
		});
		this.props.updateFiltered(filters);
	}, 500);

	reset() {
		this.setInitialState();
	}

	render() {
		return (
			<Fragment>

				<div className="filters">

					<div className="row">

						<div className="filters__inputs col">

							<div className="row">

								<div className={'col'}>

									<div className="row">
										<div className="col is-1-of-6">
											<label htmlFor="association-name">Course Name</label>
											<input type="text" autoComplete="off" value={this.state.filters.course_name} name="course_name" id="course_name" onChange={(e) => {
												this.updateFilter('course_name', e.target.value)
											}}/>
										</div>

										<div className={"col is-1-of-7"}>
											<GhinFilterSelect
												options={[{label:'H', value: 'H'}, {label:'A', value: 'A'}, {label:'C', value: 'T'}, {label:'N', value: 'C'}, {label: 'NC', value: 'CT'}, {label:'P', value: 'P'}, {label:'E', value: 'E'}]}
												value={this.state.filters.score_type}
												onChange={values => {
													this.updateFilter('score_type', values)
												}}
												label={'Score Type'}
											/>
										</div>

										<div className={"col is-1-of-7"}>
											<GhinFilterSelect
												options={[
													{label: 'Monday', value: 1},
													{label: 'Tuesday', value: 2},
													{label: 'Wednesday', value: 3},
													{label: 'Thursday', value: 4},
													{label: 'Friday', value: 5},
													{label: 'Saturday', value: 6},
													{label: 'Sunday', value: 7}
												]}
												value={this.state.filters.days_of_week}
												onChange={values => {
													this.updateFilter('days_of_week', values)
												}}
												label={'Days of Week'}
											/>
										</div>

										{/* The holes played filter will be used only for scaling golfers,
											 otherwise the scores will be separeted in 2 diffrent tables:
											 	- one for 18
											 	- one for 9 scores that are not yet combined */}
										{this.props.golfer_use_scaling &&
										<div className={"col is-1-of-7"}>
											<GhinFilterSelect
												options={[
													{label: '18-Hole', value: 18},
													{label: '9-Hole', value: 9},
													{label: 'Other', value: 0}
												]}
												value={this.state.filters.holes_played}
												onChange={values => {
													this.updateFilter('holes_played', values)
												}}
												label={'Holes Played'}
											/>
										</div>
										}

										<div className={"col is-1-of-7"}>
											<GhinFilterSelect
												singleChoice
												options={[{label: 'Date Played', value: 'played_at'}, {label: 'Date Posted', value: 'posted_at'}]}
												value={this.state.filters.date_ranges}
												onChange={values => {
													this.updateFilter('date_ranges', values)
												}}
												label={'Date Range'}
											/>
										</div>

										<div className="col is-1-of-7">

											<label>From</label>
											<DatePicker
												selected={this.state.filters.from_date_played ? new Date(this.state.filters.from_date_played) : null}
												className={`date`}
												dropdownMode="select"
												dateFormat={'MM/dd/yyyy'}
												showMonthDropdown
												showYearDropdown
												onKeyDown={e => {checkKeyForDateInput(e)}}
												onChange={(value) => {
													if (value) {
                            let from_date_played = moment(value);
                            if (this.state.filters.to_date_played && from_date_played > this.state.filters.to_date_played)
                              from_date_played = this.state.filters.to_date_played;
														this.updateFilter('from_date_played', from_date_played)
													} else {
														this.updateFilter('from_date_played', null)
													}
												}}
											/>

										</div>

										<div className="col is-1-of-7">
											<label>To</label>
											<DatePicker
												selected={this.state.filters.to_date_played ? new Date(this.state.filters.to_date_played) : null}
												className={`date`}
												dropdownMode="select"
												dateFormat={'MM/dd/yyyy'}
												showMonthDropdown
												showYearDropdown
												onKeyDown={e => {checkKeyForDateInput(e)}}
												onChange={(value) => {
													if (value) {
                            let to_date_played = moment(value);
                            if (this.state.filters.from_date_played && to_date_played < this.state.filters.from_date_played)
                              to_date_played = this.state.filters.from_date_played;
														this.updateFilter('to_date_played', to_date_played)
													} else {
														this.updateFilter('to_date_played', null)
													}
												}}
											/>
										</div>

									</div>

								</div>
							</div>

						</div>

						<div className="filters__controls col is-1-of-7 jc-fe">
							<button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
						</div>

					</div>

				</div>

			</Fragment>
		);
	}
}
