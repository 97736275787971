import React, {Component, Fragment} from 'react';
import debounce from "lodash.debounce";
import {AsyncSelectInput} from "../../inputs/AsyncSelectInput";
import {required} from "../../inputs/validations";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {addAlert} from "../shared/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CourseRatingSystemService from "../../services/api/courseRatingSystem";
import {SelectInput} from "../../inputs/Select";
import ClubService from "../../services/api/club";
import FacilityService from "../../services/api/facilities";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class AddHomeCourseForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			facilities: [],
			facility: null,
			courses: [],
			isPrimary: true,
			error: null,
			loading:  false
		}
		this.getFacilities = this.getFacilities.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values,dispatch, props) {

		let checkedCourses = this.state.courses.filter(course => values[course.CourseId].is_checked);

		let data = {
			facility: {
				is_primary: values.is_primary,
				facility_id: this.state.facility.FacilityId,
				home_courses: checkedCourses.map(course => {
					let c = {
						course_id: course.CourseId,
					};

					if (values[course.CourseId] && values[course.CourseId].default_male) {
						c.default_male_tee_id = values[course.CourseId].default_male.value;
					}

					if (values[course.CourseId] && values[course.CourseId].default_female) {
						c.default_female_tee_id = values[course.CourseId].default_female.value
					}

					return c;
				})
			}
		};

		return ClubService.addClubHomeCourses(props.clubId, data)
			.then(res => {
				props.addAlert({
					type:'success',
					message:'Home Courses successfully added!'
				});
				this.props.closeModal(res)
			})
			.catch(err =>{
				if(err.data.errors === "This home course was already added!") {
					this.setState({error: "This home course was already added!"});
				}
				let errors = '';
				if (Array.isArray(err.data.errors)) {
					err.data.errors.map(error => {
						errors += error + '<br/>';
						return null;
					})
				} else {
					errors = err.data.errors;
				}
				throw new SubmissionError({name: errors})
			})
	}

	goBack(){
		this.props.initialize();
		this.setState({facility:null})
	}

	getFacilities(name, callback) {
		if (name && name.length > 2) {
			return FacilityService.getList(name)
				.then(res => {
					if(!res.errors) {
						let facilities = res.map(facility => {
							return {...facility, ...{value: facility.FacilityId, label: facility.FacilityName + " " + (facility.City && facility.State ? `(${facility.City}, ${facility.State})` : '')}}
						});
						facilities.sort((a, b) => (a.label > b.label) ? 1 : -1);
						this.setState({facilities});
						callback(facilities)
					} else {
						callback([]);
					}
				})
				.catch(err => {
					this.setState({facilities: []});
					callback([]);
					console.error(err)})
		} else {
			this.setState({facilities: []});
			callback([]);
		}
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>


				{!this.state.facility ?
					(!this.state.loading ? <Fragment>
						<div className="row">
							<div className="col">
								<label htmlFor="facility-name">Facility Name <span>*</span></label>
								<Field onChange={(event, newValue, prevValue) => {
									if (newValue) {
										this.setState({loading: true});
										FacilityService.getById(newValue.value)
											.then(res => {
												let courses = [];
												Promise.all(res.map(course => {
													return CourseRatingSystemService.getCourseDetails(course.CourseId)
														.then(res => {
															let formatedCourse = res;
															formatedCourse.TeeSets = res.TeeSets.map(tee => {
																return {...tee, ...{label: tee.TeeSetRatingName, value: tee.TeeSetRatingId}}
															}).sort((a, b) => (a.label > b.label) ? 1 : -1);
															formatedCourse.isChecked = true;
															courses.push(formatedCourse);
														})
														.catch(err => {console.error(err)});
												}))
													.then(res => {
														courses.sort((a, b) => (a.CourseName > b.CourseName) ? 1 : -1);
														this.setState({
															facility: newValue,
															courses,
															loading: false
														}, ()=>{
															this.state.courses.map(course => {
																this.props.change((course.CourseId + '.is_checked'), true);
																return null;
															})
															this.props.change('is_primary', true);
														})
													})
													.catch(err => {console.error(err)})
											})
											.catch(err => {
												console.log(err)
											})
									}
								}}
									   defaultOptions={this.state.facilities}
									   loadOptions={debounce(this.getFacilities, 1000)}
									   component={AsyncSelectInput}
									   noOptionsMessage={'Please select the facility'}
									   searchable={true}
									   type="text"
									   autoComplete="off"
									   validate={[required]}
									   id="name"
									   maxMenuHeight={170}
									   name="name"
									   placeholder="Enter Facility Name" />
							</div>
						</div>
						<div className="row">
							<div className="col"></div>
						</div>
					</Fragment>
							:
						<table style={{width: '100%'}}>
							<tbody >
							<tr>
								<td style={{textAlign: 'center'}}>
									 <span className="data-table__loader"></span>
								</td>
							</tr>
							</tbody>
						</table>
					)
					:

					<Fragment>
						<div className="row">
							<div className="col">
								<h3 className="form__title">{this.state.facility.FacilityName}</h3>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<p className="form__description">Please select Home Courses and Default Tees (not required) below. If this facility is the Primary Facility, check the checkbox below.</p>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<table className="data__table normal-overflow">
									<thead>
									<tr>
										<th className={'primary'}></th>
										<th>Course Name</th>
										<th>Default Male Tee</th>
										<th>Default Female Tee</th>
									</tr>
									</thead>
									<tbody>
									{this.state.courses.length ? this.state.courses.map((course, key) => {
										return (
											<tr key={key}>
												<td className={'primary'}>
													<Field
														onClick={
															() => {
																let courses = this.state.courses.map(c => {
																	if(c.CourseId === course.CourseId){
																		course.isChecked = !course.isChecked;
																		return course
																	} else {
																		return c;
																	}
																});
																this.setState({courses});
															}
														}
														checked={course.isChecked}
														id={course.CourseId + '.is_checked'}
														value={true}
														name={course.CourseId + '.is_checked'}
														component={'input'}
														type="checkbox"
													/>
													<label htmlFor={course.CourseId + '.is_checked'}></label>
												</td>
												<td>{course.CourseName.length > 20 ? course.CourseName.slice(0, 20) + '...': course.CourseName}</td>
												<td>
													{(course.TeeSets.length === 0 || course.TeeSets.filter(tee => tee.Gender === "Male").length === 0) ?
														"No Tee Available"
														:
													<Field name={course.CourseId + '.default_male'}
														   id={course.CourseId + '.default_male'}
														   component={SelectInput}
														   className="react-select-container"
														   classNamePrefix="react-select"
														   options={[...[{label:'None',value: null}] ,...course.TeeSets.filter(tee => tee.Gender === "Male")]}
													/>}
												</td>
												<td>
													{(course.TeeSets.length === 0 || course.TeeSets.filter(tee => tee.Gender === "Female").length === 0) ?
														"No Tee Available"
														:
													<Field name={course.CourseId + '.default_female'}
														   id={course.CourseId + '.default_female'}
														   component={SelectInput}
														   className="react-select-container"
														   classNamePrefix="react-select"
														   options={[...[{label:'None',value: null}] ,...course.TeeSets.filter(tee => tee.Gender === "Female")]}
													/>}
												</td>
											</tr>
										)}) :
										<td colSpan={4}><span>No courses at the current facility</span></td>
									}
									</tbody>
								</table>
							</div>
						</div>

						<div className="row">
							{this.state.error && <div className="col"><span className={'validation__message is-error'}>{this.state.error}</span></div>}
							<div className="col auto push-right">
								<Field
									onClick={
										() => {
											this.setState({isPrimary: !this.state.isPrimary});
										}
									}
									checked={this.state.isPrimary}
									id={'is_primary'}
									value={true}
									name={'is_primary'}
									component={'input'}
									type="checkbox"
								/>
								<label htmlFor={'is_primary'}>Primary Facility</label>
							</div>
						</div>

						<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={()=>{this.goBack()}}>Back</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  className="btn fill blue" disabled={pristine || submitting || this.state.courses.length === 0 || (
									this.state.courses.filter(course => course.isChecked).length === 0
								)}>Save</button>
							</div>
						</div>

					</Fragment>
				}

            </form>
		);
	}
}

AddHomeCourseForm = reduxForm({
	form: 'addHomeCourseForm',
	destroyOnUnmount: true
})(AddHomeCourseForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddHomeCourseForm));
