import React, { Component, Fragment } from "react";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PromotionalImages from "./PromotionalImages";
import PushNotification from "./PushNotification";

class GolferProducts extends Component {
	render() {
		return (
			<Fragment>
				<div className="vertical-tabs__container row">
					<ul className="vertical-tabs col">
						<li>
							<NavLink
								className="vertical-tab"
								activeClassName="is-active"
								to={
									"/manage/associations/golfer-products/promotional-images"
								}
							>
								Promotional Images
							</NavLink>
						</li>
						<li>
							<NavLink
								className="vertical-tab"
								activeClassName="is-active"
								to={
									"/manage/associations/golfer-products/push-notifications"
								}
							>
								Push Notifications
							</NavLink>
						</li>
					</ul>
					<div className="vertical-tabs__panel col">
						<Switch>
							<Redirect
								exact
								from="/manage/associations/golfer-products"
								to="/manage/associations/golfer-products/promotional-images"
							/>
							<Route
								path={`/manage/associations/golfer-products/promotional-images`}
								component={PromotionalImages}
							/>
							<Route
								path={`/manage/associations/golfer-products/push-notifications`}
								component={() => (
									<PushNotification user={this.props.user} />
								)}
							/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
	};
}

export default connect(mapStateToProps, null)(GolferProducts);
