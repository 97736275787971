import React, {Fragment, useEffect, useState} from "react";
import GhinTable from "../shared/GhinTable";
import moment from "moment/moment";
import AssociationService from "../../services/api/association";
import {numberFormat} from "../shared/numberFormat";
import ReactTooltip from "react-tooltip";

export default function MembershipTypeEditLogModal(props) {
  const [archivedMemberships, setArchivedMemberships] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      setLoading(true);
      const association_id = props.association_id;
      const membership_id = props.selectedMembership.id;

      AssociationService.getArchivedMembershipTypes(association_id, membership_id)
        .then((res) => {
          if (cancel) return;

          const current_membership = {
            membership_code: props.selectedMembership.code,
            membership_description: props.selectedMembership.description,
            rate_type: props.selectedMembership.type,
            amount: props.selectedMembership.bill_rate,
            status: ['true', true].includes(props.selectedMembership.active) ? 'Active' : 'Inactive',
            created_at: props.selectedMembership.updated_at,
            locked: props.selectedMembership.locked
          }

          const allMemberships = [{
            ...current_membership,
            changedColumns: {}
          }, ...res.membership_types.map(membership => ({...membership, changedColumns: {}}))];

          for (let i = allMemberships.length - 2; i >= 0; i--) {
            const current = allMemberships[i];
            const next = allMemberships[i + 1];

            if (current.membership_code !== next.membership_code)
              current.changedColumns.membership_code = true;
            if (current.membership_description !== next.membership_description)
              current.changedColumns.membership_description = true;
            if (current.rate_type !== next.rate_type)
              current.changedColumns.rate_type = true;
            if (parseFloat(current.amount) !== parseFloat(next.amount))
              current.changedColumns.amount = true;
            if (current.status !== next.status)
              current.changedColumns.status = true;
          }

          setArchivedMemberships(allMemberships);
          setLoading(false);
        })
        .catch(error => {
          if (cancel) return;
          console.error("Error:", error);
          setLoading(false);
        });
    }

    fetchData();

    return () => {
      cancel = true;
    };
  }, [props.selectedMembership.id, props.association_id]);

  const columns = [{
    Header: "Date",
    accessor: "created_at",
    colClassName: 'col_date_edited',
    Cell: (data) => {
      return <span>{moment(data.value).format('MM/DD/YYYY HH:mm:ss')}</span>;
    },
  }, {
    Header: "Membership Code",
    accessor: "membership_code",
    colClassName: 'col_membership_code',
    Cell: (data) => {
      const className = data?.original?.changedColumns['membership_code'] ? "changed-column" : "";
      return <span className={className}>{data.value}</span>;
    },
  }, {
      Header: <Fragment>
        Is Locked?
        <a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='offer_type'><i className="material-icons v-mid m-left white small">info</i></a>
        <ReactTooltip id={`offer_type`} className="table-head__tooltip" place='bottom' effect={'float'}>
          <div style={{padding: '10px 0px'}}>
            <p><strong>Locked Membership Code:</strong> When a membership code is locked it means that any
              golfers assigned to it cannot be reassigned to another Membership Type. A locked code is
              denoted with the light blue lock icon. If you have questions, please contact your association.</p>
          </div>
        </ReactTooltip>
      </Fragment>,
      accessor: 'locked',
      colClassName: 'col_method-extra-small',
      Cell: (data) => {
        return (
          <div style={{display: "flex", justifyContent: "center"}}>
            {data.value &&
              <i className="material-icons-outlined lock-icon-container"></i>
            }
          </div>
        )
      }
    }, {
    Header: "Membership Fee Description",
    accessor: "membership_description",
    Cell: (data) => {
      const className = data?.original?.changedColumns['membership_description'] ? "changed-column" : "";
      return <span className={className}>{data.value}</span>;
    },
  }, {
    Header: "USGA Membership Type",
    accessor: "rate_type",
    Cell: (data) => {
      const className = data?.original?.changedColumns['rate_type'] ? "changed-column" : "";
      return <span className={className}>{data.value}</span>;
    },
  }, {
    Header: "Bill Rate",
    accessor: "amount",
    colClassName: 'col_method-extra-small',
    Cell: (data) => {
      const className = data?.original?.changedColumns['amount'] ? "changed-column" : "";
      return <span className={className}>{numberFormat(data.value)}</span>;
    },
  }, {
    Header: "Status",
    accessor: "status",
    colClassName: 'col_method-extra-small',
    Cell: (data) => {
      const className = data?.original?.changedColumns['status'] ? "changed-column" : "";
      return <span className={className}>{data.value}</span>;
    },
  }];

  return (
    <Fragment>
      <div className="modal__container extra-wide">
        <div className="modal__head">
          <div className="modal__icon">
            <i className="material-icons-outlined">edit</i>
          </div>
          <h2 className="modal__title">
            {props.selectedMembership.code} Edit Log
          </h2>
          <button
            className="modal__close"
            onClick={() => props.closeModal()}
          >
            <i className="material-icons-outlined">clear</i>
          </button>
        </div>
        <div className="modal__body">
          <div className="row">
            <div className="col">
              <h1>
                The table below indicates any historical changes that have been made to this membership code. The
                bottom-most fee is the initial version. The top row is the most recent change. The data indicated with
                red text indicates what values were changed at a given time.
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col is-full">
              <GhinTable
                idKey={"id"}
                columns={columns}
                hideSelect={true}
                hidePagination={true}
                sortable={false}
                data={archivedMemberships}
                loading={loading}
              />
            </div>
          </div>
          <div className="row" style={{marginTop: "0"}}>
            <div className="col is-1-of-2" style={{marginRight: "0", marginLeft: "auto", width: "200px"}}>
              <button
                type={"button"}
                className="btn fill gray"
                onClick={() => props.closeModal()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
