import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {addAlert} from '../shared/actions'
import GolferService from "../../services/api/golfer";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import { required, maxLength } from '../../inputs/validations';
import { Textarea } from '../../inputs/Textarea';

class EditGolferStatusForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			timeZones: [],
			allied_assn: false,
			command_user: false,
			handicap_program_lic: false,
			course_rating_lic: false,
			status: false,
			membershipTypes: [],
			error: null,
			confirmationError: null,
			confirmation: false,
			is_archived: false,
      deceased: false,
      details_count: this.props.golfer.deceased_details ? this.props.golfer.deceased_details.length : 0
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {

		GolferService.getAffiliations(this.props.golfer.id)
			.then(res => {
				let clubIds = [];
				if(res.club_affiliations) {
					res.club_affiliations.forEach(club => {
						clubIds.push(club.club_id);
					})
				} else {
					clubIds = [this.props.golfer.club_id]
				}
				this.setState({
					is_archived: this.props.golfer.status === "Archived",
          deceased: this.props.golfer.deceased,
          original_deceased: this.props.golfer.deceased,
					clubIds
				}, ()=> {
					this.props.initialize({
						...this.props.golfer,
						...{is_archived: this.props.golfer.status === "Archived"}
					});
				});
			})
			.catch(err => {
				console.log(err);
			})

		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {
    if (this.state.is_archived) {
      const params = {
        deceased: this.state.deceased,
        deceased_details: values.deceased_details
      }
      return GolferService.archiveGolfer(values.id, params).then(res => {
        props.addAlert({
          type: 'success',
          message: 'Golfer has been successfully updated'
        });
        props.close(res);
      })
        .catch(error => {
          error = error.data.errors
          this.setState({
            error: error[Object.keys(error)[0]]
          })
          console.error(error[Object.keys(error)[0]])
        });
    }
    else {
		let data = {};
		if(!this.state.is_archived) {
			data.status = "Active"
		}
		return Promise.all([this.state.clubIds.map(clubId => {
			return GolferService.inactivateGolfers(clubId, {golfer_ids: [props.golfer.id]})
		})]).then(res=>{
			props.addAlert({
				type:'success',
				message:'Golfer has been successfully unarchived'
			});
			props.close(res);
		})
			.catch(error => {
				this.setState({
					error: error.data.errors
				})
				console.error(error)
			});
    }
	}

	showConfirm(data) {
		this.setState({
			confirmation: true,
			confirmationError: data.error,
			toPost: data.golfer,
		});
	}

	onConfirm(){
		return GolferService.updateGolfer(this.props.golfer.club_id, this.props.golfer.id, this.state.toPost)
			.then(res => {
				this.props.addAlert({
					type:'success',
					message:'Golfer has been successfully edited'
				});
				this.props.close(res);
			})
			.catch(error => {
				console.error(error)
			});
	}


	render() {
		const {handleSubmit, pristine, submitting} = this.props;
    const detailsMaxLength = 100;
		return (
            <Fragment>
				{!this.state.confirmation && <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>

					<div className="row">
            <div className="col is-1-of-4 jc-fe ">
							<Field
								onClick={
									() => {
										this.setState({
											is_archived: !this.state.is_archived
										});
									}
								}
                checked={this.state.is_archived || this.state.deceased}
								id="is_archived"
								value={true}
								name={'is_archived'}
								component={'input'}
								type="checkbox"
                disabled={this.state.deceased}
							/>
							<label htmlFor="is_archived">Archived</label>
						</div>
            <div className="col is-1-of-4 jc-fe ">
              <Field
                onClick={
                  () => {
                    this.setState({
                      deceased: !this.state.deceased
                    });
                  }
                }
                checked={this.state.deceased}
                id="deceased"
                value={true}
                name={'deceased'}
                component={'input'}
                type="checkbox"
              />
              <label htmlFor="deceased">Deceased</label>
            </div>
          </div>

          {this.state.deceased && <Fragment>
            <div className="row">
              <div className='col is-1-of-2'>
                <label htmlFor="deceased_details">Details <span>*</span> </label>
              </div>
              <div className="col is-2-of-2" style={{ textAlign: "right" }}>
                <label className={(this.state.details_count > detailsMaxLength ? 'length-error' : 'blue')} htmlFor="note">{this.state.details_count}  / {detailsMaxLength}</label>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field component={Textarea}
                  validate={[required, maxLength([100, 'Details cannot exceed 100 characters.'])]}
                  type="text"
                  name="deceased_details"
                  id="deceased_details"
                  className={'medium'}
                  onChange={(e) => this.setState({ details_count: e.target.value.length })}
                />
              </div>
            </div>
          </Fragment>}

          {(!this.state.original_deceased && this.state.deceased) && <div className='row' style={{ 'color': 'red' }}>
            <div className='col jc-fe'>
              <p>You have indicated this golfer is deceased. Saving this form will inactivate all club memberships, archive the golfing record, unsubscribe the golfer from the Newsletter & Push Notifications. In addition, it will trigger an email notification to all administrators of active clubs this golfer belongs to.</p>
              <p style={{ 'paddingTop': '10px' }}>If you proceed and this is a mistake, you will have to restore all settings manually.</p>
              <p style={{ 'paddingTop': '10px' }}>If you are sure you would like to continue, press "Save".</p>
            </div>
          </div>}

					{this.state.error && <span className={'validation__message is-error'}>{this.state.error}</span>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={()=>this.props.close()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={pristine || submitting} className="btn fill blue">Save</button>
						</div>
					</div>
				</form>}

            </Fragment>
		);
	}
}

EditGolferStatusForm = reduxForm({
	form: 'editGolferStatusForm',
	destroyOnUnmount: true
})(EditGolferStatusForm);

function mapStateToProps(state) {
	const selector = formValueSelector('editGolferAccountForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		membership_code: selector(state, 'membership_code'),
		date_of_birth: selector(state, 'date_of_birth')
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditGolferStatusForm));
