import React, {Component} from "react";
import {addAlert} from "../../../../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class Salutation extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
      return (

          <div className="row">
            <div className="col">
              <div className="panel">
                <div className="panel__head">Sub-Header & Salutation</div>
                <div className="panel__body">
                  {!this.props.readOnly &&
                  <div className="row">
                    <div className="col auto push-right">
                      {<button onClick={this.props.assocOpenEditSalutationModal} className="btn lnk">Edit</button>}
                    </div>
                  </div>}
                  <div className="row">
                    <div className="col jc-fs">
                      The default sub-header content includes the golfers name, GHIN Number, Handicap Index and Low Handicap Index.
                    </div>
                  </div>
                  <div className="row">
                    <div className="col jc-fs">
                      <div className="data-set__container left">
                        <div className="data-set">
                          <span className="data-label">Show Sub-Header?</span>
                          <span className={"data-value"}>{this.props.emailSettings.show_sub_header ? "Yes" : "No"}</span>
                        </div>
                        <div className="data-set">
                          <span className="data-label">Show Salutation?</span>
                          <span className={"data-value"}>{this.props.emailSettings.show_salutation ? "Yes" : "No"}</span>
                        </div>
                        {this.props.emailSettings.show_salutation &&
                        <div className="data-set">
                          <span className="data-label">Salutation:</span>
                          <span className={"data-value"} dangerouslySetInnerHTML={{__html: this.props.emailSettings.salutation}} />
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )
    }
}


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({addAlert}, dispatch);
    return {
        dispatch,
        ...actions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Salutation);
