import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import {addAlert} from "../../shared/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import GhinTable from "../../shared/GhinTable";
import {getAllPermissions, getPermission} from "../../../permissionManager";
import AddGuardianModal from "../../Modals/AddGuardianModal";
import GolferService from "../../../services/api/golfer";
import moment from "moment";
import {NavLink} from 'react-router-dom';
import EditGuardianModal from "../../Modals/EditGuardianModal";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";

const generateGolferName = (row) => {
	return row.last_name + (row.suffix ? ' ' + row.suffix + ', ' : ', ') + (row.prefix ?  row.prefix + ' ' : '') + row.first_name + ' ' + (row.middle_name ? row.middle_name : '');
};
class Guardianship extends Component {

	constructor(props) {

		super(props);

		this.state = {
			editEmailSubscriptionModal: false,
			guardians: [],
			loading: true,
			AddGuardianModalIsOpen: false,
			editGuardian: false,
			deleteGuardian: false,
		};

	}




	componentDidMount() {
		this.getGuardians()
		Modal.setAppElement('body');
	}

	getGuardians() {
		const golferId = this.props.golfer.id;
		GolferService.getGuardians(golferId)
			.then(async ({guardians}) => {
				guardians = await Promise.all(guardians.map(async (g) => {
					if (g.golfer_id) {
						const {golfer} = await GolferService.getGolferByGHIN(g.golfer_id);
						golfer.clubs.forEach((club) => {
							if (club.association_id === this.props.golfer.association_id && club.id.toString() === this.props.golfer.club_id) {
								g = {
									...g,
									golf_association_id: club.association_id,
									club_id: club.id
								}
							}
						})
					}
					return g;
				}));
				this.setState({
					guardians: guardians.map((guardian) => {
						return {
							...guardian,
							status_date: guardian.status_date ? moment(guardian.status_date).format("MM/DD/YYYY") : "",
						}
					}),
					loading: false
				})
			}).catch((e) => console.error(e));
	}

	deleteGuardian(){
		const golferId = this.props.golfer.id;
		const guardianId = this.state.deleteGuardian.id;
		GolferService.deleteGuardian(guardianId,golferId)
			.then((res) => {
				this.setState({deleteGuardian: false}, () => {
					this.props.addAlert({
						type:'success',
						message:'Guardian has been successfully removed.'
					});
					this.props.updateGolfer();
				})

				//do somehting
			}).catch((e) => console.log(e));
	}

	closeAddGuardianModal() {
		this.setState({
			AddGuardianModalIsOpen: false
		});
		if (this.props.updateGolfer) {
			this.props.updateGolfer();
		}
		this.getGuardians();
	}

	render() {

		let allPermisions = getAllPermissions(this.props.rawAccess);

		let columns = [
			{
				Header: 'Guardian Name',
				Cell: props =>
				{
					if (props.original.golfer_id) {
						return (
							<Fragment>
								<NavLink to={`/manage/association/${props.original.golf_association_id}/club/${props.original.club_id}/golfer/${props.original.golfer_id}`}>{generateGolferName(props.original)}</NavLink>
							</Fragment>
						)
					} else {
						return (
							<Fragment>
								<NavLink to={`/manage/guardian/${props.original.id}`}>{generateGolferName(props.original)}</NavLink>
							</Fragment>
						)
					}
				}
			},
			{
				Header: 'GHIN Number',
				Cell: props => {
					return (<>{props.original.golfer_id || "N/A"}</>)
				}
			},
			{
				Header: 'Phone',
				accessor: 'phone_number',
			},
			{
				Header: 'Email',
				accessor: 'email',
			},
			{
				Header: 'Relationship to Minor',
				Cell: (props) => <>{props.original.relationship === 'Other'  ? `Other - ${props.original.other_relationship}` : props.original.relationship}  </>
			},
			{
				Header: 'Guardianship Status',
				accessor: 'status',
			},
			{
				Header: 'Status Date',
				accessor: 'status_date',
			},
			{
				Header: '',
				accessor: 'edit',
				show: this.props.canEditClub &&!this.props.archived && !this.props.publicView && (allPermisions.associations.filter(assoc => assoc === this.props.golfer.association_id).length > 0 || allPermisions.clubs.filter(assoc => assoc === parseInt(this.props.golfer.club_id)).length > 0 || this.props.user_type === "super-admin"),
				Cell: props => {
					if (allPermisions.associations.filter(assoc => assoc === this.props.golfer.association_id).length > 0 || allPermisions.clubs.filter(assoc => assoc === parseInt(this.props.golfer.club_id)).length > 0 || this.props.user_type === "super-admin") {
						return (
								<span className='col_edit-delete'>
								<button className="btn lnk" onClick={()=>{ this.setState({editGuardian: props.original})}}>Edit</button>
								<button className="btn lnk" onClick={()=>{ this.setState({deleteGuardian: props.original})}}>Delete</button>
								</span>
						)
					} else {
						return null;
					}
				}
			}
		];

		const golferAgeInYears = this.props.golfer.date_of_birth && moment().diff(moment(this.props.golfer.date_of_birth).format(), 'years')
		const isJuniorGolfer = golferAgeInYears >= 13 && golferAgeInYears <= 19;
		const golferIsMinorWithoutGuardian = this.props.golfer.is_minor &&  !this.props.golfer.has_guardians && !isJuniorGolfer

		return (
			<Fragment>

				{this.props.golfer && <div className="columns">

					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">Guardian Management</div>
								<div className="panel__body">

									{this.props.golfer.status !=="Archived" && this.props.canEditClub && <div className="row">
										{process.env.REACT_APP_TYPE !== "hv" && !this.props.publicView && getPermission(this.props.user_type, 'edit-club') && golferIsMinorWithoutGuardian ?
											<div className="col jc-c">
												<span className={"red"}><strong>Alert:</strong> A guardian is required for minor golfers. Without a guardian, scores cannot be posted via GHIN Mobile App or ghin.com for this golfer.</span>
												<span style={{paddingTop: "10px"}}><strong>Note:</strong> To add another guardian press "Add Guardian".  To edit an existing guardian press "Edit".  To remove a guardian, press "Delete".</span>
											</div>
											:
											<div className="col jc-c">
												<span style={{paddingTop: "10px"}}><strong>Note:</strong> To add another guardian press "Add Guardian".  To edit an existing guardian press "Edit".  To remove a guardian, press "Delete".</span>
											</div>}

										<div className="col auto push-right">
											<button className="btn fill green" onClick={() => this.setState({AddGuardianModalIsOpen: true})}>Add Guardian </button>
										</div>
									</div>}

									<div className='row'>
										<div className="col jc-fs">

											<GhinTable
												loading={this.state.loading}
												columns={columns}
												ref={(r) => this.table = r}
												hideSelect
												hidePagination={true}
												data={this.state.guardians}
												sortable={true}
											/>

										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>}
				<Modal
					isOpen={this.state.AddGuardianModalIsOpen}
					onRequestClose={()=>{this.closeAddGuardianModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddGuardianModal golfer={this.props.golfer}
								   closeModal={(data)=>{this.closeAddGuardianModal(data)}} />
				</Modal>

				<Modal
					isOpen={this.state.editGuardian}
					onRequestClose={()=>this.setState({editGuardian: undefined})}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditGuardianModal
						title={'Edit Guardianship Details'}
						guardian={this.state.editGuardian}
						edit_status={true}
						golfer={this.props.golfer}
						closeModal={() => {
							this.setState({
								editGuardian: undefined
							});
							this.getGuardians();
							if (this.props.updateGolfer) {
								this.props.updateGolfer();
							}
						}}
					/>
				</Modal>
				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.deleteGuardian}
					onConfirmAction={() => this.deleteGuardian()}
					onCancelAction={()=>{this.setState({deleteGuardian: false})}}
					closeModal={()=>{this.setState({deleteGuardian: false})}}
					question={"Remove Guardian?"}
					infoText={[`Are you sure you want to remove ${this.state.deleteGuardian.first_name + ' ' + this.state.deleteGuardian.last_name} as a guardian for ${this.props.golfer.player_name}?`]}
					note={this.props.golfer.is_under_13 && this.state.guardians.filter(g=> g.status === "Active").length === 1 ? 'An active guardian is required for minor golfers. Without a guardian scores and interaction with ghin.com and the GHIN Mobile App will be prohibited. Please activate or add a guardian for this golfer from the Account tab.' : null}
					cancelLabel={"Cancel"}
					confirmLabel={"Submit"}
					modalIcon={"help_outline"}
				/>
			</Fragment>
		);
	}
}
function mapStateToProps(state){
	return {
		user_type: state.user.access.user_type,
		rawAccess: state.user.rawAccess,
	}
}
function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Guardianship);
