// This makes sure that when clicking any of the "All" buttons, all the other buttons get unchecked.
// And when clicking any other button while any "All" is already checked, then it will uncheck the "All"

const normalizeAssociationsDropdown = (newValue, previousValue) => {
  if (!newValue) {
    return newValue;
  }

  if (!previousValue) {
    return newValue;
  }
  let hadSelectAll = false;
  let hadSelectAllAllied = false;
  let newHasSelectAll = false;
  let newHasSelectAllAllied = false;

  // Without this check, when selecting only "All Associations" or "All Allied Associations"
  // a bug would happen: after clicking outside of the dropdown, the value will be erased.
  // this is because when clicking outside the dropdown, the field believes a new value was issued
  // and the normalize is called with newValue: [AllAssociations], previousValue: [AllAssociations]
  if (previousValue.length === 1 && newValue.length === 1) {
    return newValue;
  }

  previousValue.forEach((element, index, array) => {
    if (element.value === -1) hadSelectAllAllied = true;
    if (element.value === -2) hadSelectAll = true;
  })

  newValue.forEach(element => {
    if (element.value === -1) newHasSelectAllAllied = true;
    if (element.value === -2) newHasSelectAll = true;
  })

  if (hadSelectAll) {
    let goodValues = newValue.filter(element => element.value !== -2);
    return goodValues;
  }

  if (hadSelectAllAllied) {
    let goodValues = newValue.filter(element => element.value !== -1)
    return goodValues;
  }

  if(newHasSelectAll) {
    let goodValues = newValue.filter(element => element.value === -2);
    return goodValues;
  }

  if (newHasSelectAllAllied) {
    let goodValues = newValue.filter(element => element.value === -1);
    return goodValues;
  }

  return newValue;
}

export default normalizeAssociationsDropdown;
