import React, {Component, Fragment} from 'react';
import SpecialUpdateForm from '../Forms/SpecialUpdateForm';
import SuccessModal from "./SuccessModal";

export default class SpecialUpdateModal extends Component {

	constructor(props){
		super(props);
		this.state = {
			success: false,
		}
	}

	render() {
		let specialUpdate = this.state.specialUpdate;
		return (
			<Fragment>

				<div className="modal__container narrow">

					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">warning</i></div>
						<h2 className="modal__title">{this.props.title}</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">

						<div className="row">
							<div className="col is-1-of-1">
								<span className="label">{this.props.description}</span>
							</div>

						</div>
						{this.state.success ?
							<SuccessModal
								openModal={this.state.success}
								onMainAction={() => this.props.closeModal("success")}
								warning={specialUpdate.inactive_clubs > 0 || specialUpdate.inactive_golfers > 0}
								warningTitle={(specialUpdate.active_clubs > 0 || specialUpdate.active_golfers > 0) ? 'Some records need your review' : 'Please Review'}
								message={
									<Fragment>
										{(specialUpdate.active_clubs > 0 ||specialUpdate.active_golfers > 0) && <p><span>A Special Update has been successfully performed on
											{specialUpdate.active_clubs > 0 &&  ` ${specialUpdate.active_clubs} ${(specialUpdate.active_clubs > 1 || specialUpdate.active_clubs === 0) ? " clubs" : " club"}.`}
											{specialUpdate.active_golfers > 0 && ` ${specialUpdate.active_golfers} ${(specialUpdate.active_golfers > 1 || specialUpdate.active_golfers === 0) ? " golfers" : " golfer"}.`}
										</span></p>}
										{(specialUpdate.inactive_clubs > 0) &&
										<p><span
											className="special-update__warning">
												A Special Update could not be performed on
											{specialUpdate.inactive_clubs > 0 ?
												` ${specialUpdate.inactive_clubs} ${(specialUpdate.inactive_clubs > 1 || specialUpdate.inactive_clubs === 0) ? " clubs" : " club"}.`
												: null}
											</span></p>
										}
										{specialUpdate.inactive_golfers > 0 &&
										<p><span className="special-update__warning">
										A Special Update could not be performed on
											{specialUpdate.inactive_golfers ?
												` ${specialUpdate.inactive_golfers} ${(specialUpdate.inactive_golfers > 1 || specialUpdate.inactive_golfers === 0) ? " golfers" : " golfer"}.`
												: null
											}

											</span></p>
										}

									</Fragment>
								}
								closeModal={() => {this.props.closeModal("success")}}
								specialButton={specialUpdate.download_url ? specialUpdate.download_url : null}
								mainLabel={"OK"}
							/>
							:
							<SpecialUpdateForm loading={this.state.loading} closeModal={this.props.closeModal} confirmAction={()=> this.startSpecialUpdate()}/>}

					</div>

				</div>

			</Fragment>
		);
	}

	startSpecialUpdate() {
		this.setState({
			loading: true
		});
		this.props.confirmAction().then((data) => {
			this.setState({
				success: true,
				loading: false,
				specialUpdate: data
			});
		});
	}
}
