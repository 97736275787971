import React, {Component, Fragment} from 'react';
import Dropdown, {DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import OptionDatePicker from './OptionDatePicker';


export default class DateRageDropDown extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      showDropdow: false
    }
    this.onClose = this.hideDropdow.bind(this);
    this.showDropdow = this.showDropdow.bind(this);
  }
  
  hideDropdow = () => {
    this.setState({
      showDropdow: false
    })
  }
  
  showDropdow = () => {
    this.setState({
      showDropdow: true
    })
  }

  render() {
    return (
      <Fragment>
        <span className="dropdown_overlay" hidden={!this.state.showDropdow} onClick={this.hideDropdow}/>
        <Dropdown className="date-range-dropdown" active={this.state.showDropdow}>
            <DropdownTrigger className="date-range-dropdown-trigger" onClick={this.showDropdow}>
              <span>{this.props.value}</span>
            </DropdownTrigger>
            <DropdownContent>
                <OptionDatePicker
                  updateDates={this.props.updateDates}
                  updateLabel={this.props.updateLabel}
                  close={this.hideDropdow}
                  show={this.showDropdow}
                  />
            </DropdownContent>
          </Dropdown>
      </Fragment>
    );

  }

}
