import React from 'react';
import Select from 'react-select';
import {isMobile} from 'react-device-detect';

export const SelectInput = (field) => (
	<div>
		<Select
			id="state-select"
			className={`${field.className} ${field.meta.touched && field.meta.error ? 'is-error' : ''}`}
			clearable={false}
			classNamePrefix="react-select"
			value={field.input.value}
			onChange={field.input.onChange}
			// onFocus={()=>field.input.onFocus(field.input.value)} am intampinat o problema aici... este chiar necesara actiunea de on focus?
			onBlur={event => isMobile ? event.preventDefault() : field.input.onBlur(field.input.value)}
			options={field.options}
			name={field.input.name}
			style={field.style}
			isMulti={field.isMulti}
			isDisabled={field.disabled}
			noOptionsMessage={()=>{if (field.noOptionsMessage) return field.noOptionsMessage;}}
			backspaceRemoves={field.backspaceRemoves}
			searchable={field.searchable || false}
		/>
		{!field.hideErrorMessages && field.meta.touched && (field.meta.error && <p className="validation__message is-error">{field.meta.error}</p>)}
	</div>
);
