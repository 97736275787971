import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';

import EditImageInformationModal from '../screens/Modals/EditImageInformationModal';
import ConfirmationModal from "../screens/Modals/ConfirmationModal";
import {ImageService} from "../services/imageService";

export default class UploadImage extends Component {

	constructor(props) {

		super(props);

		this.state = {
			editImageInformationModalIsOpen: false,
			image: this.props.image,
			alt_text: this.props.alt_text,
			ad_image_url: this.props.ad_image_url,
			confirmModalIsOpen: false,
			confirmElementDelete: false
		};

		this.openEditImageInformationModal = this.openEditImageInformationModal.bind(this);

	}


	openEditImageInformationModal() {
		this.setState({editImageInformationModalIsOpen: true});
	}

	closeModal() {
		this.setState({
			confirmModalIsOpen: false,
			editImageInformationModalIsOpen: false
		})
	}

	closeImageModal(data) {
		if(data) {
			this.setState({
				editImageInformationModalIsOpen: false,
				alt_text: data ? data.alt_text : this.state.alt_text,
				ad_image_url: data ? data.ad_image_url : this.state.ad_image_url
			}, () => {
				if (this.props.has_ad_image_url) {
					this.props.onSetAltText(data);
				} else {
					this.props.onSetAltText(data.alt_text);
				}
			})
		} else {
			this.setState({editImageInformationModalIsOpen: false});
		}

	}

	async setImage(file) {
		const renderUrl = await ImageService.convertToBase64(file);
		const dimensions = await ImageService.getImageDimensions(renderUrl);

		if ((this.props.requiredWidth && this.props.requiredHeight) && (dimensions.width !== this.props.requiredWidth && dimensions.height !== this.props.requiredHeight)) {
			return alert("Image size not right");
		}

		if (this.props.square && dimensions.width !== dimensions.height) {
			return alert("Image ratio not right");
		}

		if ((this.props.minimumWidth && this.props.minimumHeight) && (dimensions.width < this.props.minimumWidth && dimensions.height !== this.props.minimumHeight)) {
			return alert("Image size not right");
		}

		this.saveImage(file);
	}

	removeImage() {
		this.setState({confirmModalIsOpen:true});
	}

	saveImage(file, type) {
		this.props.onSave(file);
	}

	componentDidMount() {
		this.setState({
			image: this.props.image,
			alt_text: this.props.alt_text
		})
	}

	componentDidUpdate(prevProps) {
		//set the state with data from props
		if(this.props.image !== prevProps.image || this.props.alt_text !== prevProps.alt_text || this.props.ad_image_url !== prevProps.ad_image_url ){
			this.setState({
				image: this.props.image,
				alt_text: this.props.alt_text,
				ad_image_url: this.props.ad_image_url
			})
		}
	}

	render() {
		return (
			<Fragment>

				<div className="panel__head">
					{this.props.title}
					{(this.props.removable && !this.props.readOnly) && <div className="panel__end">
						<button className="btn lnk red" onClick={() => this.setState({
							confirmElementDelete: true
						})}>Delete</button>
					</div>}
				</div>
				<div className="panel__body">
					<div className="upload__item">
						{!this.props.readOnly && <div className="upload__zone">
							<Dropzone accept={['image/png', 'image/jpg', 'image/jpeg']}
									  onDrop={acceptedFiles => acceptedFiles.length > 0 && this.setImage(acceptedFiles[0])}
									  multiple={false}
									  disabled={this.state.image ? true : false}
							>
								{({getRootProps, getInputProps}) => (
									<div className="dropzone" {...getRootProps()}>
										<input {...getInputProps()} />
										<i className="material-icons-outlined">cloud_upload</i>
										<p>Drag &amp; Drop file here to upload</p>
										<p>or</p>
										<button disabled={this.state.image}
												className="btn fill green">Choose from computer
										</button>
									</div>
								)}
							</Dropzone>
						</div>}
						{this.state.image ?
							<div className="upload__details">
								<div className="row">
									<div className="col is-3-of-7">
										<figure className="large" style={{width: 200}}>
											<img src={this.state.image}
												 alt={this.props.alt_text ? this.props.alt_text : 'ghin-admin'}
												 name={this.props.alt_text ? this.props.alt_text : 'ghin-admin'}
												 style={{width: 200}}/>
										</figure>
									</div>
									<div className="col is-4-of-7 jc-fs">
										<div className="upload_item">
											<h6 className="upload__label">Image Url:</h6>
											<a className="upload__url" href={this.state.image}>{this.state.image}</a>
											<h6 className="upload__label">Alt Text:</h6>
											<span className="upload__info">{this.state.alt_text}</span>
											{this.props.has_ad_image_url && <Fragment>
												<h6 className="upload__label">Redirect URL:</h6>
												<span className="upload__info">{this.state.ad_image_url}</span>
											</Fragment>}
										</div>
									</div>
								</div>
							</div> :
							(this.props.logo ?
								<div className="upload__details">
									{
										this.props.logoDescription &&
										<h5 className="upload__title">{this.props.logoDescription}</h5>
									}
									<ul className="upload__requirements">
										<li>Ratio = 1:1</li>
										<li>Minimum Width & Height = 155 px</li>
										<li>Required Format = PNG, JPG</li>
									</ul>
								</div>
									:
									<div className="upload__details"></div>
							)

						}
						{!this.props.readOnly && <div className="upload__controls list">
								{this.state.image &&
								<button
									onClick={() => this.openEditImageInformationModal()}
									className="btn lnk">
									Edit
								</button>}
								<button disabled={!this.state.image}
										className="btn fill gray"
										onClick={() => {this.removeImage()}}>
									<i className="material-icons-outlined no-margin">clear</i>
								</button>
							</div>}

					</div>
				</div>

				<Modal
					isOpen={this.state.editImageInformationModalIsOpen}
					onRequestClose={() => {
						this.closeModal()
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					{this.state.editImageInformationModalIsOpen &&
						<EditImageInformationModal image={this.state.image} has_ad_image_url={this.props.has_ad_image_url} ad_image_url={this.state.ad_image_url} alt_text={this.state.alt_text} closeModal={(data) => this.closeImageModal(data)}/>
					}
				</Modal>

				<ConfirmationModal openModal={this.state.confirmModalIsOpen}
								   onConfirmAction={()=>{this.setState({confirmModalIsOpen: false});this.props.onRemove()}}
								   onCancelAction={()=>{this.setState({confirmModalIsOpen: false})}}
								   closeModal={()=>{this.setState({confirmModalIsOpen: false})}}
								   question={"Are you sure?"}
								   cancelLabel={"No"}
								   confirmLabel={"Yes"}/>

				<ConfirmationModal
					question={`Are you sure you want to delete this entry?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({confirmElementDelete: false})}
					onConfirmAction={() => this.props.onDelete()}
					openModal={this.state.confirmElementDelete}
					closeModal={() => this.setState({confirmElementDelete: false})}
				/>

			</Fragment>
		);
	}
}
