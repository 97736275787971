import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import moment from 'moment';
import {removeAlert} from './actions'

class Alerts extends Component {

	constructor(props) {
        super(props);

        this.state = {
			alerts: []
        };
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	componentDidUpdate(prevProps){
		if(prevProps.alerts.length !== this.props.alerts.length){
			this.setState({alerts: this.props.alerts})
		}
	}

	componentDidMount() {
		let self = this;

		this.interval = setInterval(() => {
			if(this.state.alerts.length > 0) {
				this.state.alerts.map( (alert,key) => {
					if(moment().unix() > alert.timestamp){
						self.removeElement(key);
					}
					return null;
				})

			}}, 1000);
	}

	removeElement(key){
		this.props.removeAlert(key);
	}

	render() {

		return (
				<div className={'alerts_list'}>
					{this.state.alerts.length > 0 && this.state.alerts.map( (alert, key) => {
						return (
							<li key={key} className={alert.type} onClick={()=>{this.removeElement(key)}}>
								<span className={'alert-label'}>{alert.type}:</span>
								<span className={'alert-message'}>{alert.message}</span>
							</li>
						);
					}) }
				</div>
		);
	}
}

function mapStateToProps(state) {
	return {alerts: state.alerts}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({removeAlert}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
