import request from '../shared/request';

export default class GuardianService {
	static getGuardianById(guardianId) {
		return request( {
				url: `/guardians/${guardianId}.json`
			}
		)
	}

	static getGolfers(guardianId) {
		return request( {
				url: `/guardians/${guardianId}/golfers.json`
			}
		)
	}

	static getGuardians(params) {
		return request({
			url: `/guardians.json`,
			params
		})
	}

	static addMinor(guardianId, golfer) {
		return request({
			url: `/guardians/${guardianId}/add_minor.json`,
			method: 'POST',
			data: {
				golfer
			}
		})
	}

	static updateGuardian(guardianId, guardian) {
		return request({
			url: `/guardians/${guardianId}.json`,
			method: 'PATCH',
			data: {
				guardian
			}
		})
	}

	static getLogs(guardianId, params) {
		return request({
			url: `/guardians/${guardianId}/logs.json`,
			method: 'GET',
			params
		});
	}

	static getDigitalProfile(guardian_id) {
		return request({
			url: `/guardians/${guardian_id}/get_digital_profile_information.json`,
			method: 'GET'
		})
	}

	static sendInvitation(guardian_id) {
		return request({
			url: `/guardians/${guardian_id}/send_invitation_to_create_digital_profile.json`,
			method: 'POST',
			data: {
				source: "admin"
			}

		})
	}

	static removeDigitalProfile(guardian_id) {
		return request({
			url: `/guardians/${guardian_id}/remove_digital_profile.json`,
			method: 'DELETE'
		})
	}

	static generateInvitationLink(guardian_id) {
		return request({
			url: `/guardians/${guardian_id}/generate_set_password_url.json`,
			method: 'GET',
			params: {
				url_type: "profile_creation"
			}
		})
	}

	static generateResetPasswordLink(guardian_id) {
		return request({
			url: `/guardians/${guardian_id}/generate_set_password_url.json`,
			method: 'GET',
			params: {
				url_type: "reset_password"
			}
		})
	}

}
