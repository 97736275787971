import request from '../shared/request';

export default class AssociationAlertsService {
  static getAlert(assocId) {
		return request({
			url: `associations/${assocId}/alert.json`,
			method: 'GET',
		});
	}

	static updateAlert(assocId, data) {
		return request({
			url: `associations/${assocId}/alert.json`,
			method: 'PATCH',
			data
		});
	}

}