import React, {Component, Fragment} from 'react';
import PlayerEntry from "./PlayerEntry";
import DefaultSelector from "./DefaultSelector";

class MultiplePlayersEntry extends Component {

	constructor(props) {
		super(props);

		this.state = {
			startDate: new Date(),
			dateSetup: [
				{value: 'specific', label: 'Specific Date'},
				{value: 'ascending', label: 'Ascending Date'},
				{value: 'descending', label: 'Descending Date'},
			],
			scoreType: [
				{ value: 'H', label: 'Home'},
				{ value: 'A', label: 'Away'},
				{ value: 'T', label: "Competition"},
			],
			holesOptions: [
				{ value: "18", label: '18 (9 + 9)' },
				{ value: "18t", label: '18 Total' },
				{ value: "9", label: '9 Total' },
			],
			courses: [],
			teeSetsOptions: [],
			selectedCourse: {}
		};
	}

	render() {
		return (
			<Fragment>
				<DefaultSelector />
				<PlayerEntry addEntry={this.props.addEntry}/>
			</Fragment>
		);
	}

}

export default MultiplePlayersEntry;
