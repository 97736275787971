export let get_campaigns_columns = (onDetailsClick) => {
  return [
    {
      Header: 'Date Sent',
      accessor: 'date',
      colClassName: 'col_username',
      className: 'table-align-center',
      thClassName: 'table-align-center'
    },
    {
      Header: 'Template Name',
      accessor: 'name',
      colClassName: 'col_golfer-name-short',
      className: 'table-align-center',
      thClassName: 'table-align-center',
      sortable: false,
    },
    {
      Header: '# Sent',
      accessor: 'processed_count',
      colClassName: 'col_status',
      className: 'table-align-center',
      thClassName: 'table-align-center',
      sortable: false,
    },
    {
      Header: '# Delivered',
      accessor: 'delivered_count',
      colClassName: 'col_centered_checkbox',
      className: 'table-align-center',
      thClassName: 'table-align-center',
      sortable: false,
    },
    {
      Header: '# Not Delivered',
      accessor: 'error_count',
      colClassName: 'col_date',
      className: 'table-align-center',
      thClassName: 'table-align-center',
      sortable: false,
    },
    {
      Header: '# Total Opens',
      accessor: 'open_total_count',
      colClassName: 'col_golfer-name-short',
      className: 'table-align-center',
      thClassName: 'table-align-center',
      sortable: false,
    },
    {
      Header: '# Unique Opens',
      accessor: 'open_unique_count',
      colClassName: 'col_phone-number',
      className: 'table-align-center',
      thClassName: 'table-align-center',
      sortable: false,
    },
    {
      Header: 'Open Rate',
      accessor: 'open_unique_percent',
      colClassName: 'col_phone-number',
      className: 'table-align-center',
      thClassName: 'table-align-center',
      sortable: false,
    },
    {
      Header: '',
      sortable: false,
      Cell: props => ( <td className='col_delete'>
      <button className={'btn lnk'} onClick={ () => onDetailsClick(props) }>Details</button>
      </td>)
    }
  ];
};
