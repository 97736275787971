import React, {Fragment} from 'react';
import {Field} from "redux-form";

export const RadioButtons = ({ fieldName, options, parentClassName }) => (
	<div className={parentClassName}>
		<Field
			component={({input, options}) => (
				options.map(option => <Fragment key={option.id} >
					<input
						disabled={option.isDisabled || options.disabled}
						className={option.className || ''}
						id={option.id}
						type='radio'
						{...input}
						value={option.value}
						checked={option.isNumber ? parseInt(option.value) === parseInt(input.value) : option.value === input.value}
					/>
					<label htmlFor={option.id}>{option.label}</label>
				</Fragment>)
			)}
			name={fieldName}
			options={options}
		/>
	</div>
);
