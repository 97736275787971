import React, {Component, Fragment} from 'react';
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "lodash.debounce";
import TableService from "../../../../services/tableService";

export default class TableFilter extends Component {

	constructor() {
		super();
		this.state = {
			filters:
				{
					golfer_id: '',
					email: '',
					is_sent: {label: 'Delivered', value: true},
				}
		};
		this.updateFilter = this.updateFilter.bind(this);
	}

	updateFilter = (name, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        }, () => {
            this.updateTable();
        })
    };

	componentDidMount() {
		this.updateTable();
	}

	updateTable = debounce(() => {
        let filteredArray = TableService.filtersToArray(this.state.filters);
        this.props.updateFiltered(filteredArray);
    }, 200);

    reset() {
        this.setState({
				filters: {
					golfer_id: '',
					email: '',
					is_sent: {label: 'Delivered', value: true},
				}
		}, () => {
            this.updateTable();
        });
    }

	render() {

    	if (this.props.hideFilters) return null;

		return (
            <Fragment>

                <div className="filters">

                <div className="row">

                    <div className="filters__inputs col">

                        <div className="row">

                            <div className={'col'}>
								<div className="row">
									<div className="col is-1-of-3">
										<label htmlFor="golfer_id ">GHIN #</label>
										<input type="text" autoComplete="off" value={this.state.filters.golfer_id} name="golfer_id" id="golfer_id" onChange={(e) => {
											this.updateFilter('golfer_id', e.target.value);
										}}/>
									</div>
									<div className="col is-1-of-3">
										<label htmlFor="email">Email Address</label>
										<input type="text" autoComplete="off" value={this.state.filters.email} name="email" id="email" onChange={(e) => {
											this.updateFilter('email', e.target.value);
										}}/>
									</div>

									<div className="col is-1-of-3">

										<GhinFilterSelect
											options={[{label: 'Delivered', value: true},{label: 'Opened', value: "open"},{label: 'Clicked', value: "click"},{label: 'Not Delivered', value: false}]}
											value={this.state.filters.is_sent}
											hideSelectAllOption={true}
											singleChoice={true}
											onChange={values => this.updateFilter('is_sent', values)}
											label={'Status'}
										/>

									</div>

								</div>

							</div>
						</div>

                    </div>

                    <div className="filters__controls col is-1-of-7 jc-fe">
                        <button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
                    </div>

                </div>

                </div>

            </Fragment>
		);
	}
}
