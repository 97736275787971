import React, {Component, Fragment} from 'react';

import SelectAddGuardianMethod from './SelectAddGuardianMethod';
import SearchGuardian from './SearchGuardian';
import AddNewGuardianForm from './AddNewGuardianForm';
import AddExistingGuardianForm from './AddExistingGuardianForm';
import SuccessModal from "../../Modals/SuccessModal";

export default class AddGuardianForm extends Component {

    constructor(props) {

        super(props);
        this.state = {
            selectMethod: true,
            searchGuardian: false,
            addGuardian: false,
			existingGuardian: null,
			golfer: null,
        }

    }

    showSearch() {
        this.setState({
            selectMethod: false,
            searchGuardian: true
        });
    }

    showAdd() {
        this.setState({
            selectMethod: false,
            addGuardian: true
        });
    }

    showExistingForm(golfer) {
    	this.setState({
			selectMethod: false,
			searchGuardian: false,
			addGuardian: false,
			existingGuardian: golfer
		})
	}

    resetToSelect() {
        this.setState({
            selectMethod: true,
            searchGuardian: false,
            addGuardian: false,
			guardian: null,
			existingGuardian: null,
        });
    }

    showSuccess(guardian) {
    	this.setState({
			selectMethod:false,
			searchGuardian:false,
			addGuardian: false,
			existingGuardian: null,
			guardian,
		})
	}

	render() {
		return(
			<Fragment>

				{this.state.selectMethod && <SelectAddGuardianMethod
					showSearch={()=>{this.showSearch()}}
					closeModal={() => {this.props.closeModal()}}
					showAdd={()=>{this.showAdd()}}
				/>}

				{this.state.searchGuardian && <SearchGuardian
					onGuardianSelect={(data)=>{this.showExistingForm(data)}}
					resetToSelect={()=>{this.resetToSelect()}}
					closeModal={() => {this.props.closeModal()}}
				/>}

				{this.state.addGuardian && <AddNewGuardianForm
					golfer={this.props.golfer}
					resetToSelect={()=>{this.resetToSelect()}}
					closeModal={() => {this.props.closeModal()}}
					showSuccess={(data)=>{this.showSuccess(data)}}
				/>}

				{this.state.existingGuardian && <AddExistingGuardianForm
					guardian={this.state.existingGuardian}
					golfer={this.props.golfer}
					resetToSelect={()=>{this.resetToSelect()}}
					closeModal={() => {this.props.closeModal()}}
					showSuccess={(data)=>{this.showSuccess(data)}}
				/>}

				{this.state.guardian && <SuccessModal
					openModal={this.state.guardian}
					onMainAction={() => {
						this.props.closeModal();
					}}
					onSecundaryAction={() => {
						this.resetToSelect();
					}}
					closeModal={() => {
						this.props.closeModal();
					}}
					golfer={this.state.guardian}
					mainLabel={"Continue to Setup"}
					secundaryLabel={"Add Guardian"}
				/>}

			</Fragment>
		)
    }
}
