import React, {Component, Fragment} from 'react';

export default class AddMembershipTypeModal extends Component {

	constructor(props) {
		super(props);
		this.state = {loading:true}
	}

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <h2 className="modal__title">USGA Admin Portal Terms of Service</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
						{this.state.loading &&
							<table style={{width: '100%'}}>
								<tbody>
								<tr>
									<td style={{textAlign: 'center'}}>
										<span className="data-table__loader"></span>
									</td>
								</tr>
								</tbody>
							</table>
						}
							<Fragment>
								<div className="row" style={this.state.loading ? {display: 'none'} : null}>
									<div className="col">
										To continue, please read and agree to the following:
									</div>
								</div>
								<div className="row" style={this.state.loading ? {display: 'none'} : null}>
									<div className="col">
										<iframe title="Terms 1" width="100%" id={"iframe"} height="160px" onLoad={()=> {this.setState({loading:false});}} src={this.props.terms[0]} />
									</div>
								</div>
								{this.props.terms[1] && <div className="row" style={this.state.loading ? {display: 'none'} : null}>
									<div className="col">
										<iframe title="Terms 2" width="100%" id={"iframe"} height="160px" src={this.props.terms[1]} />
									</div>
								</div>}
								{this.props.terms[2] && <div className="row" style={this.state.loading ? {display: 'none'} : null}>
									<div className="col">
										<iframe  title="Terms 3" width="100%" id={"iframe"} height="160px" src={this.props.terms[2]} />
									</div>
								</div>}
								<div className="row" style={this.state.loading ? {display: 'none'} : null}>
									<div className="col">
										<button type="button" onClick={() => {this.props.onDecline()}} className="btn fill gray">Decline</button>
									</div>

									<div className="col">
										<button type="submit" onClick={() => {this.setState({loading:true}); this.props.onConfirm()}} className="btn fill blue">I Agree</button>
									</div>
								</div>
							</Fragment>

                    </div>

                </div>

            </Fragment>
		);
	}
}
