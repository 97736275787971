import React, {Component, Fragment} from 'react';

import ChangeClubsMembershipTypeForm from '../Forms/ChangeClubsMembershipTypeForm';
import SuccessModal from "./SuccessModal";

export default class ApplyClubsMembershipTypeModal extends Component {
	constructor(props){
		super(props);
		this.state = {
			success: false,
		}
	}
	render() {
		const clubs = [...this.props.clubs];
		const isAllSelected = this.props.isAllSelected;
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Change Clubs Membership</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
						{this.state.success ?
							<SuccessModal
								openModal={this.state.success}
								warning={this.state.data.unsuccessful > 0}
								warningTitle={this.state.data.successful > 0 ? 'Some records need your review' : 'Please Review'}
								onMainAction={() => {
									this.props.closeModal('refresh')
								}}
								message={
									<Fragment>
										{this.state.data.successful > 0 && <p><span>{`Membership Types for ${this.state.data.successful} ${this.state.data.successful > 1 ? 'clubs have' : 'club have'} been successfully ${this.props.type === "apply" ? 'applied' : 'removed'}.`}</span></p>}
										{this.state.data.unsuccessful > 0 && <p><span className={'validation__message is-error'}>{`Membership Types for ${this.state.data.unsuccessful} ${this.state.data.unsuccessful > 1 ? 'clubs' : 'club'} could not be ${this.props.type === "apply" ? 'applied' : 'removed'}.`}</span></p>}
									</Fragment>
								}
								closeModal={() => {
									this.props.closeModal('refresh')
								}}
								golfer={this.state.golfer}
								mainLabel={"OK"}
								specialButton={this.state.data.download_url ? this.state.data.download_url : null}
							/>
							:
                        <ChangeClubsMembershipTypeForm type={this.props.type} isAllSelected={isAllSelected} filters={this.props.filters} association={this.props.association} clubs={clubs} closeModal={(data) => {this.props.closeModal(data)}}  onSuccess={(data)=>this.setState({data: data, success: true})} />}

                    </div>

                </div>

            </Fragment>
		);
	}
}
