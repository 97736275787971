import React from 'react';

export function withKeyPress(Component) {
	return class WithKeyPress extends React.Component {


		componentDidMount() {
			document.addEventListener("keypress", this._handleEnterKey.bind(this), false);
		}
		componentWillUnmount() {
			document.removeEventListener("keypress", this._handleEnterKey.bind(this), false);
		}

		_handleEnterKey(e) {
			if(e.keyCode === 13 && this.component && this.component.refs.submit) {
				if(this.component.refs.submit && this.component.refs.submit.link) {
					this.component.refs.submit.link.click();
				} else {
					this.component.refs.submit.click();
				}
			}
		}
		render() {
			return (
				<Component {...this.props} onRef={data => this.component = data} />
			)

		}
	}
}
