import {Fragment, useEffect, useState} from "react";
import GhinTable from "../shared/GhinTable";
import ClubService from "../../services/api/club";
import moment from "moment/moment";

export default function ClubMembershipFeeEditLogModalIsOpen(props) {
  const [archivedFees, setArchivedFees] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      setLoading(true);
      const club_id = props.clubId;
      const fee_id = props.selectedMembershipFee.id;

      ClubService.getArchivedMembershipFees(club_id, fee_id)
        .then((res) => {
          if (cancel) return;

          const current_fee = {
            ...props.selectedMembershipFee,
            created_at: props.selectedMembershipFee.updated_at,
          }

          const allFees = [{
            ...current_fee,
            changedColumns: {}
          }, ...res.club_membership_fees.map(fee => ({...initializeFee(fee), changedColumns: {}}))];
          
          for (let i = allFees.length - 2; i >= 0; i--) {
            const current = allFees[i];
            const next = allFees[i + 1];

            columns.forEach(column => {
              const key = column.accessor;
              if (column.isTotalAmount) {
                if (calculateTotalAmount(current).toFixed(2) !== calculateTotalAmount(next).toFixed(2))
                  current.changedColumns[key] = true;
              } else if (column.isAmount) {
                if (parseFloat(current[key]).toFixed(2) !== parseFloat(next[key]).toFixed(2)) {
                  current.changedColumns[key] = true;
                }
              } else if (column.isDate) {
                  if (moment(current[key]).format("MM/DD/YYYY") !== moment(next[key]).format("MM/DD/YYYY")) {
                    current.changedColumns[key] = true;
                  }
              } else {
                if (current[key] !== next[key]) {
                  current.changedColumns[key] = true;
                }
              }
            });
          }

          setArchivedFees(allFees);
          setLoading(false);
        })
        .catch(error => {
          if (cancel) return;
          console.error("Error:", error);
          setLoading(false);
        });
    }

    fetchData();

    return () => {
      cancel = true;
    };
  }, [props.selectedMembershipFee.id, props.clubId]);
  
  const initializeFee = (fee) => {
    const date = fee.fee_end_date.split('T')[0];
    const fee_end_date = `${date}T00:00:00.000Z`;

    return {
      ...fee,
      fee_end_date: fee_end_date
    }
  }

  const formatGender = (gender) => {
    if (gender === "male and female") return "Male & Female";
    else if (gender === "male") return "Male";
    else return "Female";
  };

  const calculateTotalAmount = (fee) => {
    return parseFloat(fee.golf_association_membership_amount || 0.0) +
      parseFloat(fee.golf_association_fee_amount || 0.0) +
      parseFloat(fee.amount || 0.0)
  };
  
  const showDateInUserTimezone = (date) => {
    let utcDateTime = moment(date).utc();
    utcDateTime.set({'hour': moment().utc().hours(), 'minute': moment().utc().minutes(), 'seconds': moment().utc().seconds()});
    return utcDateTime.local().format('MM/DD/YYYY');
  };

  const columns = [{
    Header: "Date",
    colClassName: "col_edited_date",
    accessor: "created_at",
    isAmount: false,
    Cell: (data) => {
      return <span>{moment(data.original.created_at).format('MM/DD/YYYY HH:mm:ss')}</span>;
    },
  }, {
    Header: (
      <>
        <h1>Membership</h1>
        <h1>Fee Name</h1>
      </>
    ),
    thClassName: "table-align-center",
    colClassName: "col_membership_name",
    className: "text_overflow",
    accessor: "name",
    isAmount: false,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className}>{data.original.name}</span>;
    },
  }, {
    Header: (
      <>
        <h1>Membership</h1>
        <h1>Fee Description</h1>
      </>
    ),
    thClassName: "table-align-center",
    colClassName: "col_membership_name",
    className: "text_overflow",
    accessor: "description",
    isAmount: false,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className}>{data.original.description}</span>;
    },
  }, {
    Header: "Effective Date",
    thClassName: "table-align-center",
    colClassName: "col_club_fee_date",
    accessor: "fee_effective_date",
    isAmount: false,
    isDate: true,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className} style={{
        margin: "auto",
        display: "table"
      }}>{data.original.fee_effective_date ? showDateInUserTimezone(data.original.fee_effective_date) : '-'}</span>;
    },
  }, {
    Header: "End Date",
    thClassName: "table-align-center",
    colClassName: "col_club_fee_date",
    accessor: "fee_end_date",
    isAmount: false,
    isDate: true,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className} style={{
        margin: "auto",
        display: "table"
      }}>{data.original.fee_end_date ? showDateInUserTimezone(data.original.fee_end_date) : '-'}</span>;
    },
  }, {
    Header: (
      <>
        <h1>Club</h1>
        <h1>Membership</h1>
        <h1>Fee</h1>
      </>
    ),
    thClassName: "table-align-center",
    accessor: "amount",
    isAmount: true,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className} style={{
        margin: "auto",
        display: "table"
      }}>{`$${parseFloat(data.original.amount).toFixed(2)}`}</span>;
    },
  }, {
    Header: (
      <>
        <h1>Association</h1>
        <h1>Membership</h1>
        <h1>Fee</h1>
      </>
    ),
    thClassName: "table-align-center",
    accessor: "golf_association_membership_amount",
    isAmount: true,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className} style={{
        margin: "auto",
        display: "table"
      }}>{`$${parseFloat(data.original.golf_association_membership_amount).toFixed(2)}`}</span>;
    },
  }, {
    Header: (
      <>
        <h1>Association</h1>
        <h1>Transaction</h1>
        <h1>Fee</h1>
      </>
    ),
    thClassName: "table-align-center",
    accessor: "golf_association_fee_amount",
    isAmount: true,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className} style={{
        margin: "auto",
        display: "table"
      }}>{data.original.golf_association_fee_amount == null ? '-' : `$${parseFloat(data.original.golf_association_fee_amount).toFixed(2)}`}</span>;
    },
  }, {
    Header: (
      <>
        <h1>Total</h1>
        <h1>Fee</h1>
      </>
    ),
    thClassName: "table-align-center",
    isAmount: true,
    isTotalAmount: true,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className}
                   style={{margin: "auto", display: "table"}}>${calculateTotalAmount(data.original).toFixed(2)}</span>;
    },
  }, {
    Header: "Gender",
    thClassName: "table-align-center",
    colClassName: "col_gender_eligibility",
    accessor: "gender",
    isAmount: false,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className}>{formatGender(data.original.gender)}</span>;
    },
  }, {
    Header: (
      <>
        <h1>Min</h1>
        <h1>Age</h1>
      </>
    ),
    thClassName: "table-align-center",
    colClassName: "col_age",
    accessor: "minimum_age",
    isAmount: false,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className} style={{
        margin: "auto",
        display: "table"
      }}>{data.original.minimum_age ? data.original.minimum_age : '-'}</span>;
    },
  }, {
    Header: (
      <>
        <h1>Max</h1>
        <h1>Age</h1>
      </>
    ),
    thClassName: "table-align-center",
    colClassName: "col_age",
    accessor: "maximum_age",
    isAmount: false,
    Cell: (data) => {
      const className = data?.original?.changedColumns[data.column.id] ? "changed-column" : "";
      return <span className={className} style={{
        margin: "auto",
        display: "table"
      }}>{data.original.maximum_age ? data.original.maximum_age : '-'}</span>;
    },
  }];

  return (
    <Fragment>
      <div className="modal__container ultra-wide">
        <div className="modal__head">
          <div className="modal__icon">
            <i className="material-icons-outlined">edit</i>
          </div>
          <h2 className="modal__title">
            {props.selectedMembershipFee.name} Edit Log
          </h2>
          <button
            className="modal__close"
            onClick={() => props.closeModal()}
          >
            <i className="material-icons-outlined">clear</i>
          </button>
        </div>
        <div className="modal__body">
          <div className="row">
            <div className="col">
              <h1>
                The table below indicates any historical changes that have been made to this club membership fee. The
                bottom-most fee is the initial version. The top row is the most recent change. The data indicated with
                red text indicates what values were changed at a given time.
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col is-full">
              <GhinTable
                idKey={"id"}
                columns={columns}
                hideSelect={true}
                hidePagination={true}
                sortable={false}
                data={archivedFees}
                loading={loading}
              />
            </div>
          </div>
          <div className="row" style={{marginTop: "0"}}>
            <div className="col is-1-of-2" style={{marginRight: "0", marginLeft: "auto", width: "200px"}}>
              <button
                type={"button"}
                className="btn fill gray"
                onClick={() => props.closeModal()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
