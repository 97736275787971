export default class TableService {
    static filtersToArray(filters) {
        let filteredArray = [];
        Object.keys(filters || {})
            .filter((key) => {
                if (filters[key] === null) {
                    return false;
                }
                if (Array.isArray(filters[key]) && filters[key].length === 0) {
                    return false;
                }
                return true;
            })
            .forEach((key) => {
                let value = filters[key];


                if (Array.isArray(value)) {
                    value = filters[key].map((i) => {
                        if (typeof i === 'object') {
                            return i.value;
                        } else {
                            return i;
                        }
                    })
                } else {
                    if (typeof value === 'object' && typeof value.value !== 'undefined') {
                        value = value.value;
                    } else {
                        if (typeof value === 'object' && value.value === undefined) {
                            value = undefined;
                        }
                    }
                }

                filteredArray.push({
                    id: key,
                    value
                });
            });
        return filteredArray.filter((item) => {
            return item.value !== undefined
        });
    }

    static filtersArrayToParams(filters) {
        let params = {};
        filters.forEach((filter) => {
            if (Array.isArray(filter.value)) {
                params[filter.id] = filter.value.join(",");
            } else {
                params[filter.id] = typeof filter.value === 'object' ? filter.value.value : filter.value;
            }
        });
        return params;
    }
}