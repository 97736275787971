import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {formValueSelector, reduxForm} from "redux-form";
import {addAlert} from '../shared/actions'
import GolferService from "../../services/api/golfer";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

import GhinGuardianFormComponent from "../shared/GhinGuardianFormComponent";
import {relationshipTypes} from "../../services/shared/guardianRelationshipTypes";
import GuardianService from "../../services/api/guardian";
import {processErrorMessage} from "../../services/shared/errorHelper";


class EditGuardianForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			timeZones: [],
			allied_assn: false,
			command_user: false,
			handicap_program_lic: false,
			course_rating_lic: false,
			status: false,
			membershipTypes: [],
			error: null,
			confirmationError: null,
			confirmation: false,
			is_archived: false,
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.props.initialize({
			guardian: {
				...this.props.guardian,
				relationship: relationshipTypes.find((entry) => entry.value === this.props.guardian.relationship)
			}
		})
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {
		let guardianData = {
			...values.guardian,

		};


		const onSuccess = () => {
			this.props.addAlert({
				type:'success',
				message:'Golfer has been successfully edited'
			});
			this.props.close({golfer: values});
		};

		const onError = (err) => {
			if (err.data) {
				let errorMessage = processErrorMessage(err.data.errors);
				this.setState({error: errorMessage});
			}
		}
		const guardianId = this.props.guardian.guardian_id || this.props.guardian.id;

		['id', 'golfer_id', 'club_id', 'golf_association_id', 'status_date', 'minor_Name'].forEach((entry) => {
			delete guardianData[entry];
		});



		if (this.props.golfer) {
			guardianData = {
				...guardianData,
				relationship: values.guardian.relationship.value
			}

			GolferService
				.updateGuardian(this.props.golfer.id, guardianId, guardianData)
				.then(onSuccess)
				.catch(onError)
		} else {
			GuardianService
				.updateGuardian(this.props.guardian.id, guardianData)
				.then(onSuccess)
				.catch(onError)

		}

	}




	render() {
		const {handleSubmit, pristine, submitting} = this.props;

		return (
			<Fragment>
				{!this.state.confirmation && <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>

					<GhinGuardianFormComponent edit_status={this.props.edit_status} hide_search={true} guardian={this.props.guardianEdited} hide_relationship={!!!this.props.golfer} change={this.props.change}/>

					{this.state.error && <span className={'validation__message is-error'}>{this.state.error}</span>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" disabled={submitting} onClick={()=>this.props.close()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={pristine || submitting} className="btn fill blue">Save</button>
						</div>
					</div>
				</form>}



			</Fragment>
		);
	}
}

EditGuardianForm = reduxForm({
	form: 'editGuardianForm',
	destroyOnUnmount: true
})(EditGuardianForm);

function mapStateToProps(state) {
	const selector = formValueSelector('editGuardianForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		guardianEdited: selector(state, 'guardian')
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditGuardianForm));
