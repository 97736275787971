import React, {Component, Fragment} from 'react';

import Header from '../shared/Header';
import Navigation from '../shared/Navigation';
import Footer from '../shared/Footer';

import {Switch, Route} from 'react-router-dom';

import GolferSearch from "./Golfer/GolferSearch";
import AssociationSearch from "./Association/AssociationSearch";
import ClubSearch from "./Club/ClubSearch";
import PageHead from "../shared/PageHead";
import UsersService from "../../services/api/users";
import GuardianSearch from "./Guardian/GuardianSearch";
import AdminUserSearch from "./AdminUser/AdminUserSearch";

export default class Search extends Component {

	componentDidMount() {
		UsersService.accesses()
	}

	render() {
		return (
            <Fragment>
                <Header />
				<Navigation />

                    <main>
                        <div className="container">
							<PageHead title={this.props.location.pathname.split("/")[2] === 'admin_user' ? 'Admin User Search' : (this.props.location.pathname.split("/")[2] + ' Search')}/>
                            <Switch>
                                <Route path={`${this.props.match.url}/golfer`} component={() => <GolferSearch {...this.props}/>} props={this.props}/>
                                <Route path={`${this.props.match.url}/club`} component={() => <ClubSearch {...this.props}/>} props={this.props}/>
                                <Route path={`${this.props.match.url}/association`} component={() => <AssociationSearch {...this.props}/>} props={this.props}/>
                                <Route path={`${this.props.match.url}/guardian`} component={() => <GuardianSearch {...this.props}/>} props={this.props}/>
																<Route path={`${this.props.match.url}/admin_user`} component={() => <AdminUserSearch {...this.props}/>} props={this.props}/>

                            </Switch>
                        </div>
                    </main>

				<Footer />




            </Fragment>
		);
	}
}
