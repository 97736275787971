import React, {Component, Fragment} from 'react';
import AssociationService from "../../../../services/api/association";
import TableService from "../../../../services/tableService";
import {bindActionCreators} from "redux";
import {updateAssociation} from "./Actions";
import {connect} from "react-redux";
import {addAlert} from '../../../shared/actions'
import GhinTable from "../../../shared/GhinTable";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";
import TableFilter from "./DigitalProfileEmailsTableFilter.js"
import moment from "moment";

class DigitalProfilesAssoc extends Component {

  constructor(props) {
		super(props);
		this.state = {
			supportedAssociations: ["132"],
      digitalProfileEmails: [],
      loadingDigitalProfileEmails: true,
      sorted: {id: "date_sent", desc: true},
      page: 1,
      per_page: 25,
      total: 0,
      pages: 0,
      filtered: []
    };
	}

	sendEmails() {
		AssociationService
      .digitalProfilesInvitation(this.props.associationId)
		  .then(res => {
  			this.props.addAlert({
  				type:'success',
  				message:'Invitation emails have been successfully sent to golfers.'
  			})
		  })
		  .catch(err=>{console.error(err);})
	}

  getDigitalProfileEmails(){
  	this.setState({
		loadingDigitalProfileEmails: true,
	})
    const params = {
      ...TableService.filtersArrayToParams(this.state.filtered)
    }

		if (this.state.sorted) {
			params['sorting_criteria'] = this.state.sorted.id;
			params['order'] = this.state.sorted.desc ? 'desc' : 'asc';
		}

		switch (params.is_sent) {
			case 'open': params.status = 'open'; params.is_sent = true; break;
			case 'click': params.status = 'click'; params.is_sent = true; break;
			default: params.status = null; break;
		}

		AssociationService.getDigitalProfileEmails(this.state.page, this.state.per_page, this.props.associationId, params)
			.then(res => {
				this.setState({
					digitalProfileEmails: res.digital_profile_emails,
					loadingDigitalProfileEmails: false,
          total: parseInt(res['total-count'], 10),
					per_page: parseInt(res['per-page'], 10),
					pages: parseInt(res['total-pages'], 10),
				});
			})
			.catch(err => {
        this.setState({
					loadingDigitalProfileEmails: false,
				});
				console.error(err)
			})
	}

  getPage(){
    this.setState({
      loadingDigitalProfileEmails: true
    });
    this.getDigitalProfileEmails();
  }

	componentDidMount(){
    this.getDigitalProfileEmails();
	}

  updateFilter(filtered) {
    this.setState({
			filtered,
			page: 1
		});
		this.getPage();
  }

	render() {
    const digitalProfileEmailsColumns = [
			{
				Header: 'GHIN Number',
				accessor: 'ghin_number',
			},
			{
				Header: 'Email Address',
				accessor: 'email',
			},
      		{
				Header: 'Date/Time Sent',
				accessor: 'date_sent',
				Cell: props => moment(new Date(props.original.date_sent), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss'),
			},
			{
				Header: 'Email Type',
				accessor: 'template_type',
			},
			{
				Header: 'Status',
				accessor: 'status',
			},
			{
				Header: 'Error Message',
				accessor: 'error_message',
			},
		];
		return (
			<Fragment>
			{this.props.super_user && <div className="row">
					<div className="col">
						<div className="panel">
							<div className="panel__head">Digital Profiles</div>
							<div className="panel__body">
								<div className={'row'}>
									<div className={'col'}>
										<p>Send an invitation email to create a Digital Profile to all active golfers in this Association who have not yet created a Digital Profile.</p>
									</div>
								</div>
								<div className={'row'}>
									<div className="col auto push-right">
										<button className="btn fill green" onClick={() => this.setState({emailConfirmModalIsOpen: true})}>Send Emails</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
        <div className="row">
					<div className="col">
						<div className="panel">
							<div className="panel__head">Digital Profile Emails</div>
							<div className="panel__body">
              <div className={"row"}>
                <div className={"col"}>
                  <TableFilter updateFiltered={this.updateFilter.bind(this)} hideFilters={false} />
                </div>
              </div>
					<div className={'row'}>
						<div className={'col'}>
							<div style={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
								<span className={"red"}>Please note, there may be a slight delay in updating the table.</span>
								<i  className="material-icons-outlined" style={{cursor: 'pointer'}} onClick={() =>{ this.getDigitalProfileEmails()}}>refresh</i>
							</div>
						</div>
					</div>

				<div className={'row'}>
					<div className={'col'}>
                    <GhinTable
                      loading={this.state.loadingDigitalProfileEmails}
                      columns={digitalProfileEmailsColumns}
                      data={this.state.digitalProfileEmails}
                      ref={(r) => this.table = r}
                      defaultSorted={[{id: "date_sent", desc: true}]}
                      hideSlelectAll={true}
                      hideSelect
                      sortable={true}
                      defaultPageSize={this.state.per_page}
                      pageSize={this.state.per_page}
                      pages={this.state.pages}
                      total={this.state.total}
                      manual={true}
                      onPageChange={(pageIndex) => this.setState({page: pageIndex+1}, () =>  this.getPage())}
                      onPageSizeChange={(pageSize, pageIndex) => this.setState({
                        per_page: pageSize,
                        page: pageIndex + 1
                      }, () => this.getPage())}
                      onSortedChange={(newSorted) => {
                        this.setState({
                          sorted: newSorted[0],
                          page: 1
                      }, () => this.getPage())}
                    }
                    />
                  </div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.emailConfirmModalIsOpen}
					onConfirmAction={()=>{this.setState({emailConfirmModalIsOpen: false});this.sendEmails()}}
					onCancelAction={()=>{this.setState({emailConfirmModalIsOpen: false})}}
					closeModal={()=>{this.setState({emailConfirmModalIsOpen: false})}}
					question={"Are you sure you want to send the Digital Profile invitation emails?"}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		super_user: state.user.access.super_user
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({updateAssociation, addAlert}, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalProfilesAssoc);
