import React, {Component, Fragment} from 'react';

import ChangeGolferMembershipForm from '../Forms/ChangeGolferMembershipForm';
import SuccessModal from "./SuccessModal";

export default class ChangeGolferMembershipModal extends Component {

	constructor(props){
		super(props);
		this.state = {
			success: false,
		}
	}
	render() {
		return (
			<Fragment>

				<div className="modal__container narrow">

					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
						<h2 className="modal__title">Change Membership Code for {this.props.golfers.length} {this.props.golfers.length > 1 ? 'Golfers' : 'Golfer'}</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">
						{this.state.success ?
							<SuccessModal
								openModal={this.state.success}
								onMainAction={() => {
									this.props.closeModal('refresh')
								}}
								warning={this.state.data.meta.unsuccessfully_changed > 0}
								warningTitle={this.state.data.meta.successfully_changed > 0 ? 'Some records need your review' : 'Please Review'}
								message={<Fragment>
									{this.state.data.meta.successfully_changed > 0 && `The Membership Code of  ${this.state.data.meta.successfully_changed} ${this.state.data.meta.successfully_changed > 1 ? 'golfers' : 'golfer'} has been successfully changed.`}
									{this.state.data.meta.unsuccessfully_changed > 0 && <Fragment> <br/> <span className={'validation__message is-error'}>{`The Membership Code of ${this.state.data.meta.unsuccessfully_changed} ${this.state.data.meta.unsuccessfully_changed > 1 ? 'golfers' : 'golfer'} could not be changed.`}</span></Fragment>}
									</Fragment>
								}
								closeModal={() => {
									this.props.closeModal('refresh')
								}}
								mainLabel={"OK"}
								specialButton={this.state.data.meta.download_url ? this.state.data.meta.download_url : null}
							/>
							:
							<ChangeGolferMembershipForm golfers={this.props.golfers}
														onSuccess={(data) => this.setState({success: true, data: data})}
														filters={this.props.filters}
														isAllSelected={this.props.isAllSelected}
														associationId={this.props.assoc} clubId={this.props.club}
														close={(data) => this.props.closeModal(data)}
														canEditAssociation={this.props.canEditAssociation}/>
						}
					</div>

				</div>

			</Fragment>
		);
	}
}
