import React, {Component} from "react";


// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';



import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/css/plugins/colors.min.css';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditor from 'froala-editor';

FroalaEditor.RegisterCommand('insertHTML', {
    title: 'Insert HTML',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
        this.html.insert('Some Custom HTML.');
        this.undo.saveStep();
    }
});

export default class HtmlEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            html: this.props.html
        };

        this.appendText = this.appendText.bind(this);
      this.editorRef = React.createRef();
    }

    getHtml() {
		if(this.state.editor.codeView.isActive()) {
			return this.state.editor.codeView.get();
		} else {
			return this.state.editor.html.get(true);
		}
    }


    appendText(text) {
        this.state.editor.html.insert(text);
    }




    handleModelChange(model) {
        this.setState({
            html: model
        });
    }


  render() {
    const self = this;

        return (
          <FroalaEditorComponent
                tag='textarea'
                model={this.state.html}
                onModelChange={this.handleModelChange.bind(this)}
            config={{
              charCounterMax: self.props.maxChar ? self.props.maxChar : null,
              charCounterCount: self.props.maxChar,
					          attribution: false,
                    key: (process.env.REACT_APP_TYPE === "pilot" ? (window.location.origin.indexOf('admin-pilot') !== -1 ? process.env.REACT_APP_FROALA_KEY : 'kRB4zB4D4C3E1F1G1qB-22A-16wpB1G-10frjC10D7D5B4B2A3D3E2J3C6==' ) :  process.env.REACT_APP_FROALA_KEY),
                    toolbarButtons: {
                        'moreText': {
                            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                        },
                        'moreParagraph': {
                            'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                        },
                        'moreRich': {
                            'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
                        },
                        'moreMisc': {
                            'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                            'align': 'right',
                            'buttonsVisible': 2
                        }
                    },
                    heightMin: 150,
                    events : {
                        initialized: function () {
                            self.setState({
                                editor: this
                            })
                        },
                        'focus' : (e, editor) => {
                            if (this.props.onFocus) {
                                this.props.onFocus();
                            }
                            // this.props.onFocus ? this.props.onFocus() : null;
                      },
                      'contentChanged': function () {
                        if (self.props.onChange) {
                          self.props.onChange({
                            target: {
                              value: self.state.html
                            }
                          });
                        }
                        }
                    }
                }}
            />
        )
    }
}
