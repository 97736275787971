import React, {Component, Fragment} from 'react';
import Dropdown, {DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {logoutUser} from "../Auth/actions";
import Modal from 'react-modal';
import Alerts from './Alerts';
import MainAlert from './MainAlert';
import AssociationAlert from './AssociationAlert';
import {addAlert} from './actions'
import { withRouter } from 'react-router-dom'

import Select from 'react-select';

import LogOutModal from '../Modals/LogOutModal';
import AffiliationChange from "../Affiliation/Change";
//import SharedService from "../../services/api/shared";

const searchObjects = [
    {
        placeholder: "Enter Name, GHIN # or Email Address",
        label: "Golfer",
        value: "golfer",
    },
    {
        placeholder: "Enter Club Name or Number",
        label: "Club",
        value: "club",
    },
    {
        placeholder: "Enter Association Name or Number",
        label: "Association",
        value: "association",
    },
	{
		placeholder: "Enter Name, GHIN # or Email Address",
		label: "Guardian",
		value: "guardian",
	},
  {
      placeholder: "Enter User Name or Email Address",
      label: "Admin User",
      value: "admin_user",
  },
];

class Header extends Component {

	constructor(props) {

        super(props);

        this.state = {
            logOutModalIsOpen: false,
			affiliationSelectIsOpen: false,
            selectedSearchObject: 0,
			searchText: ''
        };

        this.openLogOutModal = this.openLogOutModal.bind(this);
		this.closeModal = this.closeModal.bind(this);


	}

    openLogOutModal() {
		this.setState({logOutModalIsOpen: true});
	}

    closeModal() {
		this.setState({logOutModalIsOpen: false});
	}

	onSearchTextChange(event) {
        this.setState({searchText: event.target.value, objectNotFound: false})
    }

    performSearch(e) {
        this.props.history.push(`/search/${searchObjects[this.state.selectedSearchObject].value}?q=${encodeURI(this.state.searchText)}`);
        this.setState({
			searchText: ''
		});
        e.preventDefault();

    }
	render() {
    let assocId = this.props.user.access.default_association_id;
		return (
            <Fragment>
				<Alerts/>
                <header>
					<MainAlert />
          <AssociationAlert assocId={assocId}/>
                    <div className="container">
                        <div className="header__container row">

                            <div className="logo__container col is-1-of-4 push-left">
                                <h1 className="usga__logo">USGA Logo</h1>
                                <span>{process.env.REACT_APP_TYPE !== "hv" ? 'Admin' : 'Testing'} <br/> Portal</span>
                            </div>

							{process.env.REACT_APP_TYPE !== "hv" && this.props.access.user_type !== 'score-only' && <form onSubmit={(e) => {this.state.searchText ? this.performSearch(e) : e.preventDefault()}} className="search__container col auto push-right">
                                {this.state.objectNotFound && <p>Not found</p>}
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={searchObjects}
                                    isSearchable={false}
                                    isMulti={false}
                                    value={searchObjects[this.state.selectedSearchObject]}
                                    onChange={(a) => {
                                       this.setState({
                                           selectedSearchObject: searchObjects.indexOf(a),
                                           objectNotFound: false
                                       })
                                    }}
                                />
                                <input className="search"
                                       onChange={this.onSearchTextChange.bind(this)}
                                       type="text"
                                       autoComplete="off"
                                       name="search"
                                       id="search"
                                       placeholder={searchObjects[this.state.selectedSearchObject].placeholder}
                                       value={this.state.searchText || ''}
                                />
                                <button className="btn search" type={'submit'} ref={'submit'}><i className="material-icons-outlined">search</i></button>
                            </form>}

                            <div className="profile__container col">
                                <Dropdown>
                                    <DropdownTrigger>{this.props.user.first_name} {this.props.user.last_name}<i className="material-icons-outlined">keyboard_arrow_down</i></DropdownTrigger>
                                    <DropdownContent>
                                        <ul>
											{this.props.access.canChangeAffiliation && <li><div onClick={() => this.setState({affiliationSelectIsOpen: true})} className="dropdown__option" >Change Account</div></li>}
											<li><div onClick={() => this.openLogOutModal()} className="dropdown__option" >Log Out</div></li>
                                        </ul>
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </header>

                <Modal
					isOpen={this.state.logOutModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
                    <LogOutModal closeModal={()=>{this.closeModal()}} />
                </Modal>
				{this.props.access.canChangeAffiliation && this.state.affiliationSelectIsOpen && <AffiliationChange closeModal={() => this.setState({affiliationSelectIsOpen: false})}/>}
            </Fragment>
		);
	}
}
function mapStateToProps(state) {
	return {
		user: state.user,
		access: state.user.access
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({logoutUser, addAlert}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
