import Cookies from "universal-cookie";
import AuthService from "../../../services/api/auth";
import UsersService from "../../../services/api/users";

export function logoutUser() {
	return (dispatch) => {
		let cookies = new Cookies();
		AuthService.logout({user_token: cookies.get('token')});
		cookies.remove('token', {path: '/'});
		cookies.remove('gotAlert', { path: '/' });
		cookies.remove('closedAlert', { path: '/' });
    cookies.remove('gotAssociationAlert', { path: '/'});
    cookies.remove('closedAssociationAlert', { path: '/'});
		dispatch({
			type: "@@ghin/ADD_LOGIN_USER_DATA",
			payload: {first_name: null, last_name: null, user_id: null}
		});
		dispatch({
			type: "@@ghin/USER_AUTHENTICATED",
			payload: false
		});
    dispatch({
      type: "@@ghin/CLEAR_ASSOCIATION_ALERT_DATA"
    });
	}
}

export function getUserAccess() {
	return (dispatch) => {
		UsersService.accesses()
			.then(response => {
				dispatch({
					type: '@@ghin/ADD_USER_ACCESS',
					payload: response
				})
			})
			.catch(err => {

			})
	}
}
