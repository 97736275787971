import React, {Component, Fragment} from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import {addAlert} from '../../../shared/actions'
import {bindActionCreators} from "redux";
import AddAssocGroupModal from '../../../Modals/AddAssocGroupModal';
import ClubService from "../../../../services/api/club";
import AssociationTable from "./AssociationTable";
import {GolfAssociationGroup} from "../../../../services/api/federations";
import EditAssocGroupModal from "../../../Modals/EditAssocGroupModal";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import {connect} from "react-redux";

class AssociationGroup extends Component {

	constructor(props) {
		super(props);
		this.state = {
            addAssocGroupModalIsOpen: false,
			editAssocGroupModalIsOpen: false,
			openDeleteConfirmation: false,
			checkAllAvailable: false,
			checkAllSelected: false,
			loaded: false,
			availableAssociations: [],
			initialAssociationsState: [],
			selectedAssociations: [],
			assocGroupOptions: [],
			selectedGroup: null
		};
        this.openAddAssocGroupModal = this.openAddAssocGroupModal.bind(this);
		this.toggleAvailableAssociation = this.toggleAvailableAssociation.bind(this);
		this.toggleSelectedAssociation = this.toggleSelectedAssociation.bind(this);
		this.toggleAllAvailable = this.toggleAllAvailable.bind(this);
		this.toggleAllSelected = this.toggleAllSelected.bind(this);
		this.onGroupChange = this.onGroupChange.bind(this);
		this.deleteGroup = this.deleteGroup.bind(this);
	}

    openAddAssocGroupModal() {
		this.setState({addAssocGroupModalIsOpen: true});
	}

    closeModal() {
		this.setState({
            addAssocGroupModalIsOpen: false,
        });
	}

	getGroups(callback) {
		GolfAssociationGroup.list()
			.then(response => {
				let assocGroupOptions = response.golf_association_groups.map(gassoc => {
					gassoc.value = gassoc.id;
					gassoc.label = gassoc.name;
					return gassoc;
				}).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);
				this.setState({
					assocGroupOptions
				}, callback)
			})
			.catch(err => {

			});
	}

	deleteGroup() {
		GolfAssociationGroup.delete(this.state.selectedGroup.id)
			.then(() => {
				this.props.addAlert({type:'success',message:'Group has been successfully deleted'});
				this.setState({
					selectedGroup: null,
					openDeleteConfirmation: false
				});
				this.getGroups();
				this.onGroupChange(null)
			})
			.catch(err => {

			})
	}

	updateGroup() {
		let selectedAssociations = this.state.selectedAssociations.map(association => association.id);
		let data = {name: this.state.selectedGroup.name, association_ids: selectedAssociations};
		GolfAssociationGroup.update(this.state.selectedGroup.id, data)
			.then(response => {
				this.props.addAlert({type:'success',message:'Group has been successfully saved'});
				let selectedGroup = response.golf_association_group;
				selectedGroup.label = selectedGroup.name;
				selectedGroup.value = selectedGroup.id;
				this.setState({
					selectedGroup
				});
				this.getGroups();
			})
			.catch(err => {
				console.error(err);
			})
	}

    componentDidMount() {
        Modal.setAppElement('body');
		this.getGroups();
		ClubService.getGolfAssociations()
			.then(response => {
				response.associations.map(association => association.checked = false);
				this.setState({
					loaded: true,
					availableAssociations: response.associations,
					initialAssociationsState: response.associations
				})
			})
			.catch(err => {

			})
    }

    toggleAvailableAssociation(index, checked) {
		let associations = this.state.availableAssociations;
		associations.filter(assoc => {
			if (assoc.id === index) {
				assoc.checked = checked;
			}
			return assoc;
		});
		this.setState({
			availableAssociations: associations
		})
	}

	toggleSelectedAssociation(index, checked) {
		let associations = this.state.selectedAssociations;
		associations.filter(assoc => {
			if (assoc.id === index) {
				assoc.checked = checked;
			}
			return assoc;
		});
		this.setState({
			selectedAssociations: associations
		})
	}

	toggleAllAvailable(checked) {
		let availableAssociations = this.state.availableAssociations;
		availableAssociations.map(assoc => assoc.checked = checked);
		this.setState({
			availableAssociations,
			checkAllAvailable: checked
		});
	}

	toggleAllSelected(checked, callback) {
		let selectedAssociations = this.state.selectedAssociations;
		selectedAssociations.map(assoc => assoc.checked = checked);
		this.setState({
			selectedAssociations,
			checkAllSelected: checked
		}, callback);
	}

	moveSelectedAssociations(direction) {
		if (direction === 'right') {
			let filteredAssociations = this.state.availableAssociations.filter(assoc => assoc.checked === true).map(assoc => {assoc.checked = false; return assoc;});
			let selectedAssociations = [...this.state.selectedAssociations, ...filteredAssociations];
			let availableAssociations = this.state.availableAssociations.filter(assoc => selectedAssociations.indexOf(assoc) < 0);
			this.setState({
				selectedAssociations,
				availableAssociations,
				checkAllAvailable: false
			})
		} else {
			let selectedAssociations = this.state.selectedAssociations.filter(assoc => assoc.checked !== true);
			let filteredAssociations = this.state.selectedAssociations.filter(assoc => assoc.checked === true).map(assoc => {assoc.checked = false; return assoc;});
			let selectedAvailableAssociations = [...this.state.availableAssociations, ...filteredAssociations];
			let availableAssociations = this.state.initialAssociationsState.filter(assoc => selectedAvailableAssociations.indexOf(assoc) >= 0);
			this.setState({
				availableAssociations,
				selectedAssociations,
				checkAllSelected: false
			})
		}
	}

	onGroupChange(selectedGroup) {
		this.setState({
			availableAssociations: this.state.initialAssociationsState.map(a => {a.checked = false; return a}),
			selectedAssociations: []
		}, () => {
			if (selectedGroup) {
				selectedGroup.golf_associations.map(gassoc => {
					this.toggleAvailableAssociation(gassoc.association_id, true);
					return null;
				});
				this.moveSelectedAssociations('right');
			}
			this.setState({
				selectedGroup
			})
		});

	}

	render() {
		let checkedAssociationsLength = this.state.availableAssociations.filter(a => a.checked).length;
		let selectedAssociationsLength = this.state.selectedAssociations.filter(a => a.checked).length;
		return (
            <Fragment>

                <div className="assoc-g__head row">
                    <div className="col is-1-of-4">
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
							onChange={this.onGroupChange}
							isDisabled={!this.state.loaded}
                            options={this.state.assocGroupOptions}
                            isSearchable={false}
							value={this.state.selectedGroup}
                        />
                    </div>
					{this.props.canEditAssociations && this.state.selectedGroup &&
						<div className="col is-1-of-4 push-left">
							<ul className="assoc-g__controls">
								<li><button className="btn" onClick={(e) => {e.preventDefault(); this.setState({editAssocGroupModalIsOpen: true})}}>Edit Name</button></li>
								<li><button className="btn" onClick={(e) => {e.preventDefault(); this.setState({openDeleteConfirmation: true})}}>Delete</button></li>
							</ul>
						</div>
					}
					{this.props.canEditAssociations && <div className="col is-1-of-8 push-right">
                        <button className="btn fill green" onClick={this.openAddAssocGroupModal} >Add Group</button>
                    </div>}
                </div>

                <div className="assoc-g__body row">
                    <div className="col is-5-of-11">
                        <div className="assoc-g__column">
                            <div className="assoc-g__column__head">
                                Available Associations ({this.state.availableAssociations.length})
                            </div>
                            <div className="assoc-g__column__body">
                                <AssociationTable checkAll={this.state.checkAllAvailable} isMain={true} toggleAllAvailable={this.toggleAllAvailable} toggleAvailableAssociation={this.toggleAvailableAssociation} associations={this.state.availableAssociations}/>
                            </div>
                        </div>
                    </div>
                    <div className="col is-1-of-11">
						{this.props.canEditAssociations &&<div className="assoc-g__transition-controls">
                            <button disabled={!this.state.selectedGroup || !checkedAssociationsLength} className={`btn fill ${(!this.state.selectedGroup  || !checkedAssociationsLength) ? 'gray' : 'blue' }`} onClick={() => this.moveSelectedAssociations("right")}><i className="material-icons-outlined">chevron_right</i></button>
                            <button disabled={!this.state.selectedGroup || !selectedAssociationsLength} className={`btn fill ${(!this.state.selectedGroup || !selectedAssociationsLength) ? 'gray' : 'blue'}`} onClick={() => this.moveSelectedAssociations("left")}><i className="material-icons-outlined">chevron_left</i></button>
                        </div>}
                    </div>
                    <div className="col is-5-of-11">
                        <div className="assoc-g__column">
                            <div className="assoc-g__column__head">
								{this.state.selectedGroup && (`${this.state.selectedGroup.name} (${this.state.selectedAssociations.length})`)}
                            </div>
                            <div className="assoc-g__column__body">
                                <AssociationTable checkAll={this.state.checkAllSelected} isMain={false} toggleAllSelected={this.toggleAllSelected} toggleSelectedAssociation={this.toggleSelectedAssociation} associations={this.state.selectedAssociations} />
                            </div>
                        </div>
                    </div>
                </div>

				{this.props.canEditAssociations && <div className="assoc-g__foot row">
                    <div className="col is-1-of-8 push-right">
                        <button disabled={!this.state.selectedGroup} className="btn outline gray" onClick={(e) => {e.preventDefault(); this.onGroupChange(null)}}>Cancel</button>
                    </div>
                    <div className="col is-1-of-8">
                        <button disabled={!this.state.selectedGroup} className="btn fill blue" onClick={(e) => {e.preventDefault(); this.updateGroup()}}>Save Group</button>
                    </div>
                </div>}

                <Modal
						isOpen={this.state.addAssocGroupModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Add Association Group"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
                    <AddAssocGroupModal selectGroup={(group) => {this.getGroups(this.onGroupChange(group))}} closeModal={()=>{this.closeModal()}} />
                </Modal>

				<Modal
					isOpen={this.state.editAssocGroupModalIsOpen}
					onRequestClose={()=>{this.setState({editAssocGroupModalIsOpen: false})}}
					contentLabel="Add Association Group"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditAssocGroupModal selectGroup={(group) => {this.getGroups(this.onGroupChange(group))}} closeModal={() => this.setState({editAssocGroupModalIsOpen: false})} selectedGroup={this.state.selectedGroup} />
				</Modal>

				<ConfirmationModal
					question={`Are you sure you want to delete ${this.state.selectedGroup ? this.state.selectedGroup.name : ''}?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({openDeleteConfirmation: false})}
					onConfirmAction={this.deleteGroup}
					openModal={this.state.openDeleteConfirmation}
					closeModal={() => this.setState({openDeleteConfirmation: false})}
				/>

            </Fragment>
		);
	}
}


function mapDispatchToProps(dispatch) {
	return bindActionCreators({addAlert}, dispatch);
}

export default connect(null, mapDispatchToProps)(AssociationGroup);
