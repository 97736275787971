import React, {Component} from 'react';
import moment from "moment";


export default class GolferRecord extends Component {

	render() {
		return (
			<div className="row">
				<div className="col is-full">
					<table className="data__table merge">
						<tbody>
						<tr>
							<td>GHIN Number</td>
							<td>{this.props.golfer && this.props.golfer.id}</td>
						</tr>
						<tr>
							<td>Gender</td>
							<td>{this.props.golfer && (this.props.golfer.gender || '-')}</td>
						</tr>
						<tr>
							<td>Prefix</td>
							<td>{this.props.golfer && (this.props.golfer.prefix || '-')}</td>
						</tr>
						<tr>
							<td>First Name</td>
							<td>{this.props.golfer && (this.props.golfer.first_name || '-')}</td>
						</tr>
						<tr>
							<td>Middle Name</td>
							<td>{this.props.golfer && (this.props.golfer.middle_name || '-')}</td>
						</tr>
						<tr>
							<td>Last Name</td>
							<td>{this.props.golfer && (this.props.golfer.last_name || '-')}</td>
						</tr>
						<tr>
							<td>Sufix</td>
							<td>{this.props.golfer && (this.props.golfer.suffix || '-')}</td>
						</tr>
						<tr>
							<td>Date Of Birth</td>
							<td>{this.props.golfer && ((this.props.golfer.date_of_birth && moment(this.props.golfer.date_of_birth).format("MM/DD/YYYY")) || '-')}</td>
						</tr>
						<tr>
							<td>Email</td>
							<td>{this.props.golfer && (this.props.golfer.email || '-')}</td>
						</tr>
						<tr>
							<td>Digital Profile?</td>
							<td>{this.props.golfer && ((this.props.golfer.has_digital_profile !== undefined && (!!this.props.golfer.has_digital_profile ? 'Yes' : 'No')) || '-')}</td>
						</tr>
						<tr>
							<td>Active GPS Subscription?</td>
							<td>{this.props.golfer && ((this.props.golfer.gps_subscribed !== undefined && (!!this.props.golfer.gps_subscribed ? 'Yes' : 'No')) || '-')}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		)
	}


}
