import React, {Component, Fragment} from 'react';

import "react-datepicker/dist/react-datepicker.css";
import GhinTable from "../../../shared/GhinTable";
import moment from 'moment';
import TableFilter from "../../../shared/AuditLogsFilter";
import ClubService from "../../../../services/api/club";
import SharedService from '../../../../services/api/shared';

export default class AuditLog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			pages: 0,
			total: 0,
			per_page: 10,
			loading: true,
			change_codes_array: [],
		}
		this.updateFilter = this.updateFilter.bind(this);
		this.getLogs = this.getLogs.bind(this);
	}

	componentDidMount() {
		this.loadCodeChanges();
	}

	updateFilter(params) {
		let filter_change_codes = params && params['change_codes']
		let username = params && params['username']

		// set start date
		let start_date = params && params['start_date']
		if (start_date != null) {
			start_date = moment(new Date(start_date)).startOf('day');
			start_date = start_date.toISOString();
		}

		// set end date
		let end_date = params && params['end_date']
		if (end_date != null) {
			end_date = moment(new Date(end_date)).endOf('day');
			end_date = end_date.toISOString();
		}

		filter_change_codes = filter_change_codes ? filter_change_codes.split(',') : filter_change_codes
		this.setState({
			change_codes: filter_change_codes,
			username: username,
			start_date: start_date,
			end_date: end_date,
			page: 0
		}, () => {
			this.getLogs();
		})
	}

	getRequestParams() {
		let params = {
			page: (this.state.page + 1),
			per_page: this.state.per_page
		};

		if (this.state.change_codes){
			params['change_codes'] =  this.state.change_codes;
		}

		if (this.state.username){
			params['username'] =  this.state.username;
		}

		if (this.state.start_date){
			params['start_date'] =  this.state.start_date;
		}

		if (this.state.end_date){
			params['end_date'] =  this.state.end_date;
		}

		return params;
	}

	loadCodeChanges() {
		SharedService.getCodeChanges('club')
			.then(res=>{
				this.setState({
				change_codes_array: res['change_codes']
				})
			})
			.catch(err => {
				console.error(err);
			})
	}

	getLogs(){
		this.setState({
			loading: true,
			logs: []
		});

		const params = this.getRequestParams();

		ClubService.getLogs(this.props.match.params.id, params)
			.then(res => {
				this.setState({
					loading: false,
					logs: res.club_changes.map((change,key) => {
						return {
							...change,
							id: key,
							date_and_time: change.date_and_time  && moment(moment.utc(change.date_and_time).toDate()).format("MM-DD-YYYY HH:mm:ss"),
							change_code: change.change_code,
							new: change.details.length > 0 && change.details.map(detail => {return detail[0]}),
							old: change.details.length > 0 && change.details.filter(detail => detail.length === 2).map(detail => {return detail[1]}),
							username: change.username
						}
					}),
					total: parseInt(res['total-count'], 10),
					per_page: parseInt(res['per-page'], 10),
					pages: parseInt(res['total-pages'], 10),
				});
			})
			.catch(err => {
				console.log(err);
			})
	}

	render() {

		let columns = [
			{
				Header: 'Date & Time',
				accessor: 'date_and_time',
				colClassName: 'col_date_and_time'
			},
			{
				Header: 'Change Code',
				accessor: 'change_code',
				colClassName: 'col_change_code'
			},
			{
				Header: "New",
				accessor: "new",
				Cell: (props) => {
					return (
						<Fragment>
							{props.row.new.length > 0 &&
								props.row.new.map((el, key) => {
									return (
										<p key={key}>
											{el.split(/:(.+)/)[0]}
											{el.split(/:(.+)/)[1]? ":" : ""}
											<strong>{el.split(/:(.+)/)[1]}</strong>
										</p>
									);
								})}
						</Fragment>
					);
				},
			},
			{
				Header: "Old",
				accessor: "old",
				Cell: (props) => {
					return (
						<Fragment>
							{props.row.old.length > 0 &&
								props.row.old.map((el, key) => {
									return (
										<p key={key}>
											{el.split(/:(.+)/)[0]}
											{el.split(/:(.+)/)[1]? ":" : ""}
											<strong>{el.split(/:(.+)/)[1]}</strong>
										</p>
									);
								})}
						</Fragment>
					);
				},
			},
			{
				Header: 'Username',
				accessor: 'username',
				colClassName: 'col_username'
			},
		];
		return (
			<Fragment>
				<TableFilter updateFiltered={this.updateFilter.bind(this)} change_codes_array={this.state.change_codes_array}/>

				<GhinTable
					loading={this.state.loading}
					columns={columns}
					pages={this.state.pages}
					page={this.state.page}
					total={this.state.total}
					manual={true}
					pageSize={this.state.per_page}
					data={this.state.logs}
					hideSelect={true}
					sortable={false}
					ref={(r) => this.table = r}
					onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.getLogs())}
					onPageSizeChange={(pageSize, pageIndex) => this.setState({
						per_page: pageSize,
						page: pageIndex
					}, () => this.getLogs())}
				/>

			</Fragment>
		)
	}

}
