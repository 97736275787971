import React, {Component, Fragment} from 'react';

import EditAlertForm from '../Forms/EditAlertForm';

export default class EditAlertModal extends Component {

  constructor(props) {
		super(props);

		this.state = {
			isConfirmationModal: false
		};
	}

  confirmModal() {
		this.setState({isConfirmationModal: true})
	}

	render() {
		let title = "";
		switch (this.props.type) {
			case 'ghin' :
				title = "GHIN.com Alert";
				break;
			case 'admin' :
				title = "Admin Portal Alert";
				break;
			default:
				title = "GHIN Mobile App Alert";
				break;
		}
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">
                        { this.state.isConfirmationModal?
                          <Fragment>
								            <div className="modal__icon"><i className="material-icons-outlined">warning</i></div>
								            <h2 className="modal__title">Are You Sure?</h2>
							            </Fragment>
                          :
                          <Fragment>
                            <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                            <h2 className="modal__title">Edit {title}</h2>
                            <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
                          </Fragment>
                        }
                    </div>

                    <div className="modal__body">

                        <EditAlertForm
                          data={this.props.data}
                          type={this.props.type}
                          close={(data)=>this.props.closeModal(data)}
                          confirmModal={() => this.confirmModal()}
                        />

                    </div>

                </div>

            </Fragment>
		);
	}
}
