import React, {Component} from "react";

class Setup extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render(){
        return(
            <div className="row">
                <div className="col">
                    <div className="panel">
                        <div className="panel__head">Setup</div>
                        <div className="panel__body">
							              {!this.props.readOnly && <div className="row">
                                <div className="col auto push-right">
                                    <button onClick={this.props.assocOpenEditSetupModal} className="btn lnk">Edit</button>
                                </div>
                            </div>}
                            <div className="row">
                                <div className="col jc-fs">
                                    <div className="data-set__container left">
                                        <div className="data-set">
                                            <span className="data-label">From Name:</span>
                                            <span className={"data-value"}>{this.props.emailSettings.from_name}</span>
                                        </div>
                                        <div className="data-set">
                                            <span className="data-label">Reply-To Email Address:</span>
                                            <span className={"data-value"}>{this.props.emailSettings.reply_to_email_address}</span>
                                        </div>
                                        <div className="data-set">
                                            <span className="data-label">Email Subject Line:</span>
                                            <span className={"data-value"}>{this.props.emailSettings.subject}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )};


}
export default Setup;
