import React, {Component, Fragment} from 'react';

import EditClubAccountForm from '../Forms/EditClubAccountForm';

export default class EditAccountModal extends Component {
	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Edit Primary Account Information</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <EditClubAccountForm  club={this.props.club} associationId={this.props.associationId} closeModal={(data)=>{this.props.closeModal(data)}}/>

                    </div>

                </div>

            </Fragment>
		);
	}
}
