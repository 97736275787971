import Modal from 'react-modal';
import React, {Component} from 'react';

export default class GhinModal extends Component {

    render() {
        return (
            <Modal
                contentLabel="Modal"
                portalClassName="modal"
                overlayClassName="modal__overlay"
                className="modal__content"
                bodyOpenClassName="modal--is-open"
                htmlOpenClassName="prevent-scroll"
                shouldCloseOnOverlayClick={true}
                shouldFocusAfterRender={true}
				autoFocus={true}
                {...this.props}
            >
                {this.props.children}
            </Modal>
        );
    }
}
