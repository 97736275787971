import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import ContactUsModal from '../Modals/ContactUsModal';
import {connect} from "react-redux";
import moment from "moment";

class Footer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            ContactUsModalIsOpen: false,
        };

        this.openContactUsModal = this.openContactUsModal.bind(this);
		this.closeModal = this.closeModal.bind(this);

	}


	componentDidMount() {
		let canSeeHelpButton = this.props.access.user_type === "super-admin" || this.props.access.user_type === "association-admin" || this.props.access.user_type === "association-read-only";
		if(canSeeHelpButton) {
			if(window.$zopim) {
				let launcher = document.getElementById("launcher")
				if (launcher) {
					launcher.style.display = "block";
					launcher.style.width= "auto";
				}

			}
		} else {
			if(window.$zopim) {
				let launcher = document.getElementById("launcher")
				if (launcher) {
					launcher.style.display= "none";
				}
			}
		}
	}

	componentWillUnmount() {
		if(window.$zopim) {
			let launcher = document.getElementById("launcher")
			if (launcher) {
				launcher.style.display= "none";
			}
		}
	}

	openContactUsModal() {
		this.setState({ContactUsModalIsOpen: true});
	}

    closeModal() {
		this.setState({ContactUsModalIsOpen: false});
	}

	render() {
		return (
            <Fragment>
                <footer ref={el => (this.div = el)}>
                    <div className="container">
                        <div className="footer__container">
                            <ul className="footer__navigation">
                                <li><button onClick={() => this.openContactUsModal()} className="btn lnk footer_lnk">Contact Us</button></li>
								{this.props.terms.policies && <li><a className="footer__navigation__link" href={this.props.terms.policies} target="_blank" rel="noreferrer">Privacy Policy</a></li>}
								{this.props.terms.ghin_terms && <li><a className="footer__navigation__link" href={this.props.terms.ghin_terms} target="_blank" rel="noreferrer">GHIN Terms of Service</a></li>}
								{this.props.terms.admin_terms && <li><a className="footer__navigation__link" href={this.props.terms.admin_terms} target="_blank" rel="noreferrer">Admin Portal Terms of Service</a></li>}
                            </ul>
                            <div className="footer__copyright">
                                <p>&copy; {moment().format('YYYY')} United States Golf Association. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </footer>

                <Modal
						isOpen={this.state.ContactUsModalIsOpen}
						onRequestClose={()=>{this.closeModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={true}
						shouldFocusAfterRender={false}
					>
                    <ContactUsModal closeModal={()=>{this.closeModal()}} />
                </Modal>

            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
    	terms: state.terms,
		access: state.user.access
	}
}

export default connect(mapStateToProps, null)(Footer);
