import React, {Component} from 'react'
import ReportingMessagingProtocol from './ReportingMessagingProtocol'

export default class EmailDeliveries extends Component {

	render() {
		return (
			<ReportingMessagingProtocol>
				<iframe title="Email deliveries" width="100%" height="1000px" src={this.props.iframeUrl}/>
			</ReportingMessagingProtocol>
		);
	}

}
