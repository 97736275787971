import React, {Component, Fragment} from 'react';

import EditEmailSubscriptionForm from '../Forms/EditEmailSubscriptionForm';

export default class EditAddressModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Edit Email Subscription</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <EditEmailSubscriptionForm onSubmit={(value) => this.props.onSubmit(value)} subscription={this.props.subscription} close={(data)=>this.props.closeModal(data)} />

                    </div>

                </div>

            </Fragment>
		);
	}
}
