import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import AlertModal from "../Modals/AlertModal";
import AlertsService from "../../services/api/alerts";
import {toggleAlert, saveAlert} from "./actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Cookies from 'universal-cookie';
import { store } from '../../store';

class MainAlert extends Component {

	constructor(props) {
        super(props);

        this.state = {
			showAlert: false,
			infoModal: false
		        };

	}
	componentDidMount() {
		let logged_in = false;
		if(Object.keys(store.getState().user).length !== 0){
			const user_data = store.getState().user;
			var user_type = user_data.access?.user_type;
			logged_in = user_data.user_id !== undefined;
			
			if (user_type === 'score-only')
				user_type = user_data.access.association_role === 'score_only' ? "association-score-only" : "club-score-only"
		}
		
		const cookies = new Cookies();
		let alert = cookies.get("gotAlert");
		if(!alert) {
			AlertsService.getAlerts('true','admin')
				.then(res => {
					if (res.product_alerts.length > 0 && logged_in &&
					(res.product_alerts[0].active_for === "All Admins" || (["association-admin", "association-read-only", "association-score-maintenance", "association-score-only"].includes(user_type) && res.product_alerts[0].active_for === "Association Admins")
					|| (["club-admin", "club-read-only", "club-score-maintenance", "club-score-only"].includes(user_type) && res.product_alerts[0].active_for === "Club Admins"))){
								this.props.saveAlert(res.product_alerts[0]);
								const cookies = new Cookies();
								cookies.set('gotAlert', true, {path: '/', expires:  new Date((new Date()).getTime() + 1000 * 3600 * 2)});
					} else {
						this.props.toggleAlert(false);
						this.props.saveAlert();
						const cookies = new Cookies();
						cookies.remove('gotAlert', { path: '/' });
						cookies.remove('closedAlert', { path: '/' });
					}

				})
				.catch(err => {
					console.log(err)
				})
		}

		const cookies2 = new Cookies();
		let closedAlert = cookies2.get("closedAlert");
		if(!closedAlert) {
			this.props.toggleAlert(true);
		}
	}

	hideAlert() {
		const cookies = new Cookies();
		cookies.set('closedAlert', true, {path: '/', expires:  new Date((new Date()).getTime() + 1000 * 3600 * 24)});
		this.props.toggleAlert(false);
	}

	showModal() {
		this.setState({infoModal: true})
	}
	closeModal() {
		this.setState({infoModal: false})
	}

	render() {
		let {alert, showAlert} = this.props;

		if(!alert || !showAlert) {
			return null;
		}
		return (
			<Fragment>
				<div className={"main__alert red"} onClick={() => this.showModal()}>
					<div className={"icon"}/><span>{alert.banner_alert_message}</span>
          <button className="hide_alert" onClick={() => {this.hideAlert()}}/>
				</div>
				<Modal
					isOpen={this.state.infoModal}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="custom__modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AlertModal data={alert} closeModal={() => this.closeModal()}/>
				</Modal>
			</Fragment>

		);
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({toggleAlert, saveAlert}, dispatch);
}

function mapStateToProps(state) {
	return {
		showAlert: state.productAlerts.showAlert,
		alert: state.productAlerts.alert,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainAlert);
