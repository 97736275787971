import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import EditImageInformationModal from '../../../Modals/EditImageInformationModal';
import AssociationService from "../../../../services/api/association";
import diff from 'object-diff';
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import EditSetupModal from '../../../Modals/EditSetupModal';
import EditHeaderAndFooterModal from '../../../Modals/EditHeaderAndFooterModal';
import EditSalutationModal from '../../../Modals/EditSalutationModal';
import AddContentBlockModal from '../../../Modals/AddContentBlockModal';
import EditContentBlockTitleModal from '../../../Modals/EditContentBlockTitleModal';
import { addAlert } from "../../../shared/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Salutation from '../Account/ERevision/Salutation';
import HeaderContentSetup from '../Account/ERevision/HeaderContentSetup';
import Setup from '../Account/ERevision/Setup';
import HeaderAndFooter from '../Account/ERevision/HeaderAndFooter';
import ReorderContentBlocks from '../Account/ERevision/ReorderContentBlocks';
import EditContentBlockForm from '../../../Forms/EditContentBlockForm';
import ContentPreview from "./ContentPreview";

class Design extends Component {

  constructor(props) {

    super(props);

    this.state = {
      editImageInformationModalIsOpen: false,
      editContentBlockModalIsOpen: false,
      editSetupModalIsOpen: false,
      editSalutationModalIsOpen: false,
      editHeaderAndFooterModalIsOpen: false,
      addContentBlockModalIsOpen: false,
      editContentBlockTitleModalIsOpen: false,
      confirmEmailDelete: false,
      confirmEmailPictureDelete: false,
      email_contents: [],
      original_email_contents: [],
      email_settings: null,
    };

    this.openEditImageInformationModal = this.openEditImageInformationModal.bind(this);
    this.openEditContentBlockModal = this.openEditContentBlockModal.bind(this);
    this.openEditSetupModal = this.openEditSetupModal.bind(this);
    this.openEditSalutationModal = this.openEditSalutationModal.bind(this);
    this.openHeaderAndFooterModal = this.openHeaderAndFooterModal.bind(this);
    this.openAddContentBlockModal = this.openAddContentBlockModal.bind(this);
    this.openEditContentBlockTitleModal = this.openEditContentBlockTitleModal.bind(this);
  }

  openEditImageInformationModal(index) {
    this.setState({ editImageInformationModalIsOpen: index });
  }

  openEditContentBlockModal(index) {
    //if the content block is already opened, close it
    if (this.state.editContentBlockModalIsOpen === index) {
      this.setState({ editContentBlockModalIsOpen: false });
    } else {
      this.setState({ editContentBlockModalIsOpen: index });
    }

  }

  openEditSetupModal() {
    this.setState({ editSetupModalIsOpen: true });
  }

  openEditSalutationModal() {
    this.setState({ editSalutationModalIsOpen: true });
  }

  openHeaderAndFooterModal() {
    this.setState({ editHeaderAndFooterModalIsOpen: true });
  }

  openAddContentBlockModal() {
    this.setState({ addContentBlockModalIsOpen: true });
  }

  openEditContentBlockTitleModal(index) {
    this.setState({ editContentBlockTitleModalIsOpen: index });
  }

  closeModal(data) {
    if (data) {
      this.setState({
        editImageInformationModalIsOpen: false,
        editContentBlockModalIsOpen: false,
        editSetupModalIsOpen: false,
        editSalutationModalIsOpen: false,
        editHeaderAndFooterModalIsOpen: false,
        addContentBlockModalIsOpen: false,
        editContentBlockTitleModalIsOpen: false,
        email_settings: data ? { ...data, id: this.state.email_settings.id } : this.state.email_settings,
      }, () => this.saveEmail(data));
    } else {
      this.setState({
        editImageInformationModalIsOpen: false,
        editContentBlockModalIsOpen: false,
        editSetupModalIsOpen: false,
        editSalutationModalIsOpen: false,
        editHeaderAndFooterModalIsOpen: false,
        addContentBlockModalIsOpen: false,
        editContentBlockTitleModalIsOpen: false,
      });
    }
  }

  getUpdatedStateForEntry(index, field_name, field_value, state = null) {

    let newState = {};

    newState.email_contents = state ? state.email_contents.slice(0) : this.state.email_contents.slice(0);
    newState.email_contents[index] = {
      ...newState.email_contents[index],
      [field_name]: field_value

    }
    return newState;

  }

  closeContentModal(data) {


    let newState = {
      editContentBlockModalIsOpen: false
    };

    newState = {
      ...newState,
      ...this.getUpdatedStateForEntry(this.state.editContentBlockModalIsOpen, 'block', data)
    }

    this.setState(newState, () => {
      this.saveAll();
    });
  }

  updateEmailSettings(data) {
    this.setState({
      email_settings: data
    })
  }

  /**
   * figure out if the content should be updated or created
   * @param email
   * @returns {*}
   */
  saveEmail(email) {

    let data = {
      ...email
    };

    delete data.preview;
    if (email.block_name) {
      if (!email.id) {
        return AssociationService.createEmailContent(this.props.associationId, data)
          .then(res => (this.loadEmails()))
          .catch(err => {
            console.error(err);
            this.setState({
              email_contents: this.state.original_email_contents
            });
          });
      } else {
        return AssociationService.updateEmailContent(this.props.associationId, data).then(res => (this.loadEmails()));
      }
    }
  }


  /**
   * detect changed items and save them
   * first we filter elements that really need changes
   * we save items and reload the list afterwords
   */
  saveAll() {
    return Promise.all(
      this.state.email_contents.filter((item) => {

        /**
         * if this item does not have an id, it's a new item and we need to persist it
         */
        if (!item.id) {
          return true;
        }

        /**
         * find object by it's id, using index is unreliable
         * @type {*}
         */
        const oldObject = this.state.original_email_contents.find((old) => old.id === item.id);

        /**
         * mostly this case should never happen.
         */
        if (!oldObject) {
          return true;
        }

        const changed = diff(oldObject, item);

        /**
         * if keys changed, update this item
         */
        return Object.keys(changed).length > 0
      })
        .map((entry) => {
          return this.saveEmail(entry);
        })).then(() => {
          this.loadEmails();
        })
  }

  loadEmails() {
    AssociationService.getEmailContents(this.props.associationId)
      .then(res => {
        const emailContents = res.email_contents.map((entry) => {
          return {
            ...entry,
            preview: entry.image_url
          }
        });
        this.setState({
          email_contents: emailContents.slice(0),
          original_email_contents: emailContents.slice(0)
        });
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadEmailSettings() {
    AssociationService.getEmailSettings(this.props.associationId)
      .then(res => {
        this.setState({
          email_settings: res.email_settings
        })
      })
      .catch(err => {
        console.error(err);
      })
  }

  componentDidMount() {
    this.loadEmails();
    this.loadEmailSettings();
  }


  addNewEmail(data) {
    this.setState({
      email_contents: [
        ...this.state.email_contents,
        {
          block: '',
          block_name: data.name
        }
      ]
    }, () => {
      this.saveAll();
    })
  }

  fileToUrl(file) {
    return new Promise((resolve, cancel) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    })
  }

  realImgDimension(src) {
    return new Promise((resolve, cancel) => {
      let i = new Image();
      i.src = src;
      i.onload = function () {
        resolve({
          width: this.width,
          height: this.height
        });
      }
    });

  }


  async setEmailFile(index, file) {

    const renderUrl = await this.fileToUrl(file);

    let newState = {
      ...this.getUpdatedStateForEntry(index, 'image', file, this.state)
    };
    newState = {
      ...newState,
      ...this.getUpdatedStateForEntry(index, 'preview', renderUrl, newState)
    }

    this.setState(newState, () => {
      this.saveAll();
    })
  }

  async clearEmailFile(index) {
    let newState = {
      ...this.getUpdatedStateForEntry(index, 'delete_image', true, this.state)
    };
    newState = {
      ...newState,
      ...this.getUpdatedStateForEntry(index, 'preview', null, newState),
      confirmEmailPictureDelete: false
    };

    this.setState(newState, () => {
      this.saveAll();
    })
  }

  deleteEmail(index) {
    this.setState({
      confirmEmailDelete: false
    });
    AssociationService
      .deleteEmailContent(this.props.associationId, this.state.email_contents[index])
      .then((res) => {
        this.setState({
          confirmEmailDelete: false
        }, () => {
          this.loadEmails();
        });
      })
  }

  closeImageModal(data) {
    let newState = {
      editImageInformationModalIsOpen: false
    };

    if (data) {
      newState = {
        ...newState,
        ...this.getUpdatedStateForEntry(this.state.editImageInformationModalIsOpen, 'alt_text', data.alt_text)
      }
    }

    this.setState(newState, () => {
      this.saveAll();
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.email_settings && <Setup
          emailSettings={this.state.email_settings}
          readOnly={!this.props.canEditAssociation}
          assocOpenEditSetupModal={this.openEditSetupModal}
        />}
        {this.state.email_settings && <HeaderContentSetup
          canEditAssociation={this.props.canEditAssociation}
          emailSettings={this.state.email_settings}
          updateEmailSettings={(data) => this.updateEmailSettings(data)}
          associationId={this.props.associationId}
        />}
        {this.state.email_settings && <HeaderAndFooter
          emailSettings={this.state.email_settings}
          readOnly={!this.props.canEditAssociation}
          assocOpenHeaderAndFooterModal={this.openHeaderAndFooterModal}
        />}
        {this.state.email_settings ? <Salutation
          associationId={this.props.associationId}
          emailSettings={this.state.email_settings}
          readOnly={!this.props.canEditAssociation}
          assocOpenEditSalutationModal={this.openEditSalutationModal}
        /> : null}
        <div className="row">
          <div className="col">
            <div className="panel">
              <div className="panel__head">Newsletter Content</div>
              <div className="panel__body">
                <h5 className="upload__title">
                  Content and images will appear in the newsletter in the order they are displayed below.
                </h5>
                <div className="columns">
                  {this.state.email_contents.map((entry, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col">
                          {/*TODO implement the UploadImage component*/}
                          <div className="panel">
                            <div className="panel__head">
                              {entry.block_name ? entry.block_name : 'Nameless block'}&nbsp;&nbsp;
                              {this.props.canEditAssociation &&
                                <>
                                  <button className="btn lnk"
                                    onClick={() => { this.openEditContentBlockTitleModal(index + 1) }}>Edit</button>
                                  <div className="panel__end">
                                    <button className="btn lnk red"
                                      onClick={() => this.setState({
                                        confirmEmailDelete: index
                                      })}>Delete
                                    </button>
                                  </div>
                                </>}
                            </div>
                            <div className="panel__body">
                              <div className="upload__item">
                                {entry.preview ?
                                  <Fragment>
                                    <div className="upload__details">
                                      <div className="row">
                                        <div className="col is-1-of-2">
                                          <img src={entry.preview}
                                            alt="" name=""
                                            style={{ width: "100%" }} />
                                        </div>
                                        <div className="col is-1-of-2 jc-fs">
                                          <div className="upload_item">
                                            <h6 className="upload__label">Image Url:</h6>
                                            <a className="upload__url" href={entry.image_url}>{entry.image_url}</a>
                                            <h6 className="upload__label">Alt Text:</h6>
                                            <span className="upload__info">{entry.alt_text}</span>
                                            <h6 className="upload__label">Redirect URL:</h6>
                                            <span className="upload__info">{entry.ad_image_url}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="upload__controls list">
                                      <button
                                        onClick={() => this.openEditImageInformationModal(index)}
                                        className="btn lnk">Edit
                                      </button>
                                      <button
                                        onClick={() => this.setState({ confirmEmailPictureDelete: index })}
                                        className="btn fill gray"><i className="material-icons-outlined no-margin">clear</i>
                                      </button>
                                    </div>
                                  </Fragment>
                                  :
                                  <Fragment>
                                    {this.props.canEditAssociation &&
                                      <Fragment>
                                        <div className="upload__zone">
                                          <Dropzone
                                            accept={['image/png', 'image/jpg', 'image/jpeg']}
                                            onDrop={acceptedFiles => acceptedFiles.length > 0 && this.setEmailFile(index, acceptedFiles[0])}
                                            multiple={false}>
                                            {({ getRootProps, getInputProps }) => (
                                              <div
                                                className="dropzone" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <i className="material-icons-outlined">cloud_upload</i>
                                                <p>Drag &amp; Drop file here to upload</p>
                                                <p>or</p>
                                                <button className="btn fill green">Choose from computer</button>
                                              </div>
                                            )}
                                          </Dropzone>
                                        </div>
                                        <div className="upload__details">
                                          <ul className="upload__requirements">
                                            <li>Required Format = PNG, JPG</li>
                                          </ul>
                                        </div>
                                      </Fragment>}
                                  </Fragment>
                                }
                              </div>
                              <div className="separator dark"></div>
                              <div className="row">
                                <div className="col">
                                  <h4 className="section_title">Content Block</h4>
                                </div>
                                {this.props.canEditAssociation &&
                                  <div className="col auto push-right jc-c">
                                    <button
                                      onClick={() => this.openEditContentBlockModal(index)}
                                      className="btn lnk">Edit
                                    </button>
                                  </div>
                                }
                              </div>
                              {this.state.editContentBlockModalIsOpen !== false && this.state.editContentBlockModalIsOpen === index ?
                                <EditContentBlockForm
                                  closeModal={this.closeContentModal.bind(this)}
                                  content={this.state.email_contents[this.state.editContentBlockModalIsOpen].block || ''}
                                /> :
                                <Fragment>
                                  <div className="separator light"></div>
                                  <ContentPreview content={entry.block}/>
                                </Fragment>}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {this.props.canEditAssociation &&
                  <div className="row margin-top-16">
                    <div className="col auto push-right">
                      <button className="btn fill green"
                        onClick={() => { this.openAddContentBlockModal(); }}>
                        Add New Content Area
                      </button>
                    </div>
                  </div>}
              </div>
            </div>
            {this.state.email_contents.length > 0 &&
              <ReorderContentBlocks
                canEditAssociation={this.props.canEditAssociation}
                loadEmails={() => this.loadEmails()}
                associationId={this.props.associationId}
                email_contents={this.state.email_contents}
              />}
          </div>
        </div>
        <Modal
          isOpen={this.state.editImageInformationModalIsOpen !== false}
          onRequestClose={() => { this.closeModal() }}
          contentLabel="Modal"
          portalClassName="modal"
          overlayClassName="modal__overlay"
          className="modal__content"
          bodyOpenClassName="modal--is-open"
          htmlOpenClassName="prevent-scroll"
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={false}
        >
          {this.state.editImageInformationModalIsOpen !== false &&
            <EditImageInformationModal
              closeModal={(data) => { this.closeModal(data) }}
              email={this.state.email_contents[this.state.editImageInformationModalIsOpen]}
            />}
        </Modal>
        {this.state.editSetupModalIsOpen &&
          <EditSetupModal
            associationId={this.props.associationId}
            emailSettings={this.state.email_settings}
            closeModal={(data) => { this.closeModal(data) }}
          />}
        {this.state.editHeaderAndFooterModalIsOpen &&
          <EditHeaderAndFooterModal
            associationId={this.props.associationId}
            emailSettings={this.state.email_settings}
            closeModal={(data) => { this.closeModal(data) }}
          />}
        {this.state.editSalutationModalIsOpen &&
          <EditSalutationModal
            associationId={this.props.associationId}
            emailSettings={this.state.email_settings}
            closeModal={(data) => { this.closeModal(data) }}
          />}
        {this.state.addContentBlockModalIsOpen &&
          <AddContentBlockModal
            onSubmit={(data) => { this.addNewEmail(data) }}
            closeModal={(data) => { this.closeModal(data) }}
          />}
        {this.state.editContentBlockTitleModalIsOpen &&
          <EditContentBlockTitleModal
            email={this.state.email_contents[this.state.editContentBlockTitleModalIsOpen - 1]}
            closeModal={(data) => { this.closeModal(data) }}
          />}
        <ConfirmationModal
          question={`Are you sure you want to delete this entry?`}
          confirmLabel={'Continue'}
          cancelLabel={'Cancel'}
          onCancelAction={() => this.setState({ confirmEmailDelete: false })}
          onConfirmAction={() => this.deleteEmail(this.state.confirmEmailDelete)}
          openModal={this.state.confirmEmailDelete !== false}
          closeModal={() => this.setState({ confirmEmailDelete: false })}
        />
        <ConfirmationModal
          question={`Are you sure you want to delete this picture?`}
          confirmLabel={'Continue'}
          cancelLabel={'Cancel'}
          onCancelAction={() => this.setState({ confirmEmailPictureDelete: false })}
          closeModal={() => this.setState({ confirmEmailPictureDelete: false })}
          onConfirmAction={() => {
            this.clearEmailFile(this.state.confirmEmailPictureDelete);
            this.setState({ confirmEmailPictureDelete: false })
          }}
          openModal={this.state.confirmEmailPictureDelete !== false}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ addAlert }, dispatch);
  return {
    dispatch,
    ...actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Design);
