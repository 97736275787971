import React, {Component} from 'react';

export default class MySavedReports extends Component {

	render() {
		return (
			<iframe title="My Saved reports" width="100%" height="1000px" src={this.props.iframeUrl} />
		);
	}

}
