import React, {Component, Fragment} from 'react';

import EditClubMembershipForm from '../Forms/EditClubMembershipForm';

export default class EditClubMembershipModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Edit Club Membership Details</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal(null)}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <EditClubMembershipForm 
													golfer={this.props.golfer} 
													clubMembership={this.props.clubMembership} 
													closeModal={(data)=>this.props.closeModal(data)} 
													canEditClub={this.props.canEditClub}
	 											  canEditAssociation={this.props.canEditAssociation}
													/>

                    </div>

                </div>

            </Fragment>
		);
	}
}
