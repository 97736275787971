import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {InputField} from "../../inputs/InputField";
import {SelectInput} from "../../inputs/Select";
import {validDay, required, graterThan} from "../../inputs/validations";
import { DatePickerInput } from '../../inputs/DatePicker';
import moment from 'moment';
import AssociationService from '../../services/api/association';



const dayOrderArray = [{label:'first', value:1},{label:'second', value:2},{label:'third', value:3},{label:'fourth', value:4}];
const weekDayArray =  [{label:'Sunday', value:0},{label:'Monday', value:1},{label:'Tuesday', value:2},{label:'Wednesday', value:3},{label:'Thursday', value:4},{label:'Friday', value:5},{label:'Saturday', value:6}]
class EditEmailSubscriptionForm extends Component {


	constructor(props){
		super(props);
		this.state = {
			countries: [],
			states: [],
			addressAsPrimary: false,
			contactAsPrimary: false,
			type: 'default',
			week_day: [],
			x_weeks: 0,
			month_type: 'day',
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		let {subscription} = this.props;
		if (subscription.subscribed === "Yes") {
      let date_range_details = {
        specified_date_range: !subscription.newsletter_all_year,
        newsletter_start_date: !subscription.newsletter_all_year && subscription.newsletter_start_date ? new Date(moment(subscription.newsletter_start_date, 'MM/DD')) : new Date(),
        newsletter_end_date: !subscription.newsletter_all_year && subscription.newsletter_end_date ? new Date(moment(subscription.newsletter_end_date, 'MM/DD')) : new Date()
      }
			if (subscription.schedule_type === "Weekly") {
				this.props.initialize({
					newsletter_enabled: true,
          ...date_range_details,
					week_day: subscription.week_day,
					x_weeks: subscription.x_weeks
				});
				this.setState({
					newsletter_enabled: true,
          specified_date_range: !subscription.newsletter_all_year,
					type: 'weekly',
					week_day: typeof subscription.week_day  === "string" ? [subscription.week_day] :  subscription.week_day
				});
			} else if (subscription.schedule_type === "Monthly") {

				if (subscription.month_day) {
					this.props.initialize({
						newsletter_enabled: true,
            ...date_range_details,
						month_day: subscription.month_day,
						x_monthsDay: subscription.x_months
					});
					this.setState({
						newsletter_enabled: true,
            specified_date_range: !subscription.newsletter_all_year,
						type: 'monthly',
						month_type: 'day',
					});
				} else {
					this.props.initialize({
						newsletter_enabled: true,
            ...date_range_details,
            week_day: weekDayArray[subscription.week_day],
						week_day_order: dayOrderArray[subscription.week_day_order - 1],
						x_monthsOther: subscription.x_months
					});
					this.setState({
						newsletter_enabled: true,
            specified_date_range: !subscription.newsletter_all_year,
						type: 'monthly',
						month_type: 'other',
					});
				}
			} else {
				this.props.initialize({
          newsletter_enabled: true,
          ...date_range_details
        });
				this.setState({
          newsletter_enabled: true,
          specified_date_range: !subscription.newsletter_all_year
        });
			}
		} else {
			this.props.initialize({newsletter_enabled: false});
		}
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {
		let data;
		if (this.state.newsletter_enabled) {
      data = {
        newsletter_enabled: true
      }
      if(this.state.specified_date_range === true) {
        data = {
          ...data,
          newsletter_all_year: false,
          newsletter_start_date: values.newsletter_start_date ? moment(values.newsletter_start_date).format('MM-DD') : '',
          newsletter_end_date: values.newsletter_end_date ? moment(values.newsletter_end_date).format('MM-DD') : ''
        }
      }
      else {
        data = { 
          ...data,
          newsletter_all_year: true
        }
      }
			if (this.state.type === "weekly") {
				data = {
					...data,
					week_day: this.state.week_day.length > 1 ? this.state.week_day : this.state.week_day[0],
					x_weeks: values.x_weeks
				}
			} else if (this.state.type === "monthly") {
				if (this.state.month_type === "day") {
					data = {
						...data,
						month_day: parseInt( values.month_day),
						x_months: parseInt(values.x_monthsDay)
					}
				} else {
					data = {
						...data,
						week_day_order: values.week_day_order.value,
						week_day: values.week_day.value,
						x_months: parseInt(values.x_monthsOther)
					}
				}
			} 
		} else {
			data = {newsletter_enabled: false}
		}
    
		AssociationService.editCampaignSettings(this.props.associationId, data)
    .then(res => {
      this.props.updateAssociation();
	  this.props.close(null);
    });
	}

	toggleDay(dayNumber) {
		this.setState((prevState) => {
			let days = prevState.week_day;
			if(days.filter(day => day === dayNumber).length ===0){
				days.push(dayNumber);
			} else {
				days = days.filter(day => day !== dayNumber)
			}
			return {week_day: days};
		});
	}

	render() {
		const {handleSubmit, pristine, valid,  submitting} = this.props;
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
                <div className="row">
					<div className="col is-1-of-2">
						<span className="label">Subscribed?</span>
						<div className="radio__container">

							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {newsletter_enabled: !prevState.newsletter_enabled};
										});
									}
								}
								checked={this.state.newsletter_enabled}

								id="dac-yes"
								value={true}
								name={'newsletter_enabled'}
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
							<label htmlFor="dac-yes">Yes</label>

							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {newsletter_enabled: !prevState.newsletter_enabled};
										});
									}
								}
								checked={!this.state.newsletter_enabled}
								id="dac-no"
								value={false}
								name={'newsletter_enabled'}
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
							<label htmlFor="dac-no">No</label>

						</div>
					</div>
                </div>

					{this.state.newsletter_enabled &&
          <Fragment>
            <div className="row">
              <div className="col is-1-of-2">
                <span className="label">Please choose the date range:</span>
                <div className="radio__container">
                  <Field
                    onClick={
                      () => {
                        this.setState({
                          specified_date_range: false
                        });
                      }}
                    checked={!this.state.specified_date_range}
                    id="all-year"
                    value={false}
                    name={'date_range'}
                    component={'input'}
                    className="simple__radio"
                    type="radio"
                  />
                  <label htmlFor="all-year">All Year</label>

                  <Field
                    onClick={
                      () => {
                        this.setState({
                          specified_date_range: true
                        });
                      }}
                    checked={this.state.specified_date_range}
                    id="date-range"
                    value={false}
                    name={'date_range'}
                    component={'input'}
                    className="simple__radio"
                    type="radio"
                  />
                  <label htmlFor="date-range">Only During Specified Date Range</label>

                </div>
              </div>
            </div>

            {this.state.specified_date_range && 
            <Fragment>
              <div className="row">
                <div className="col is-1-of-3">
                  <span className="label">Start Date <span>*</span></span>
                  <Field className={'date'}
                      showMonthDropdown
                      validate={[required]}
                      dateFormatCalendar={'MM/dd'}
                      dateFormat={'MM/dd'}
                      propperPlacement={'top'}
                      name={'newsletter_start_date'}
                      id={'newsletter_start_date'}
                      component={DatePickerInput}
                      placeholder="Select ..." />
                </div>
                <div className="col is-1-of-3">
                  <span className="label">End Date <span>*</span></span>
                  <Field className={'date'}
                      showMonthDropdown
                      validate={[required]}
                      dateFormatCalendar={'MM/dd'}
                      dateFormat={'MM/dd'}
                      propperPlacement={'top'}
                      name={'newsletter_end_date'}
                      id={'newsletter_end_date'}
                      component={DatePickerInput}
                      placeholder="Select ..." />
                </div>
                <div className="col is-1-of-3">
                </div>
              </div>
            </Fragment>
            }
						<div className="row">
							<div className="col is-1-of-2">
								<span className="label">Please choose delivery schedule:</span>
								<div className="radio__container">
									<Field
										onClick={
											() => {
												this.setState({
													type: 'weekly',
													week_day: [],
												});
											}
										}
										checked={this.state.type === "weekly"}
										id="type-weekly"
										value={false}
										name={'type'}
										component={'input'}
										className="simple__radio"
										type="radio"
									/>
									<label htmlFor="type-weekly">Weekly</label>

									<Field
										onClick={
											() => {
												this.setState({
													type: "monthly",
													week_day: ''
												});
											}
										}
										checked={this.state.type === "monthly"}
										id="type-monthly"
										value={false}
										name={'type'}
										component={'input'}
										className="simple__radio"
										type="radio"
									/>
									<label htmlFor="type-monthly">Monthly</label>

									<Field
										onClick={
											() => {
												this.setState({
													type: "default"
												});
											}
										}
										checked={this.state.type === "default"}
										id="type-default"
										value={false}
										name={'type'}
										component={'input'}
										className="simple__radio"
										type="radio"
									/>
									<label htmlFor="type-default">1st & 15th of each month</label>
							</div>
						</div>
					</div>
						{this.state.type === "weekly" && <div className={'form_box'}>
							<div className={'row'}>
								<div className={'col'}>
									<div style={{display: 'flex', alignItems: 'center'}}>Recur every <Field component={InputField} className={"small"} hideErrorMessages={true} parse={(value) => {if(isNaN(parseInt(value))){return ''} else {return parseInt(value)}}} validate={this.state.type === "weekly" && [required, graterThan(0)]} type="text" name="x_weeks" id="x_weeks" /> week(s) on:</div>
								</div>

							</div>
							<div className={'row fr top-m jc-fs'}>
								<div className="col is-1-of-5">
									<Field
										onClick={
											() => {
												this.toggleDay(0);
											}
										}
										checked={this.state.week_day.filter(d => d === 0).length !== 0}
										id="week_day1"
										value={false}
										name={'week_day'}
										component={'input'}
										type="checkbox"
									/>
									<label htmlFor="week_day1">Sunday</label>
								</div>
								<div className="col is-1-of-5 ">
									<Field
										onClick={
											() => {
												this.toggleDay(1);
											}
										}
										checked={this.state.week_day.filter(d => d === 1).length !== 0}
										id="week_day2"
										value={false}
										name={'week_day'}
										component={'input'}
										type="checkbox"
									/>
									<label htmlFor="week_day2">Monday</label>
								</div>
								<div className="col is-1-of-5">
									<Field
										onClick={
											() => {
												this.toggleDay(2);
											}
										}
										checked={this.state.week_day.filter(d => d === 2).length !== 0}
										id="week_day3"
										value={false}
										name={'week_day'}
										component={'input'}
										type="checkbox"
									/>
									<label htmlFor="week_day3">Tuesday</label>
								</div>
								<div className="col is-1-of-5">
									<Field
										onClick={
											() => {
												this.toggleDay(3);
											}
										}
										checked={this.state.week_day.filter(d => d === 3).length !== 0}
										id="week_day4"
										value={false}
										name={'week_day'}
										component={'input'}
										type="checkbox"
									/>
									<label htmlFor="week_day4">Wednesday</label>
								</div>
								<div className="col is-1-of-5">
									<Field
										onClick={
											() => {
												this.toggleDay(4);
											}
										}
										checked={this.state.week_day.filter(d => d === 4).length !== 0}
										id="week_day5"
										value={false}
										name={'week_day'}
										component={'input'}
										type="checkbox"
									/>
									<label htmlFor="week_day5">Thursday</label>
								</div>
								<div className="col is-1-of-5">
									<Field
										onClick={
											() => {
												this.toggleDay(5);
											}
										}
										checked={this.state.week_day.filter(d => d === 5).length !== 0}
										id="week_day6"
										value={false}
										name={'week_day'}
										component={'input'}
										type="checkbox"
									/>
									<label htmlFor="week_day6">Friday</label>
								</div>
								<div className="col is-1-of-5">
									<Field
										onClick={
											() => {
												this.toggleDay(6);
											}
										}
										checked={this.state.week_day.filter(d=> d === 6).length !== 0}
										id="week_day7"
										value={false}
										name={'week_day'}
										component={'input'}
										type="checkbox"
									/>
									<label htmlFor="week_day7">Saturday</label>
								</div>
							</div>
							{this.state.type === "weekly" &&  this.state.week_day.length === 0 && <span className={'validation__message is-error'}>Select at least one day of the week</span>}

						</div>}
						{this.state.type === "monthly" &&
							<div className={'form_box'}>
								<div className={'row'}>
									<div className="col">
										<div style={{display: 'flex', alignItems: 'center'}}>
											<Field
												onClick={
													() => {
														this.setState({month_type: "day"});
													}
												}
												checked={this.state.month_type === "day"}
												id="monthTypeDay"
												value={true}
												name={'month_type'}
												component={'input'}
												type="checkbox"
											/>
											<label htmlFor="monthTypeDay">Day</label>
											<Field component={InputField}
												   className={"small"}
												   hideErrorMessages={true}
												   validate={this.state.month_type === 'day' && [required, validDay]}
												   disabled={this.state.month_type !== 'day'}
												   type="text"
												   name="month_day"
												   id="month_day" />
											of every
											<Field component={InputField}
												   className={"small"}
												   hideErrorMessages={true}
												   disabled={this.state.month_type !== 'day'}
												   validate={this.state.month_type === 'day' && [required]}
												   type="text"
												   name="x_monthsDay"
												   id="x_months" />
											month(s)
										</div>
									</div>
								</div>
								<div className={'row fr top-m jc-fs'}>
									<div className="col">
										<div style={{display: 'flex', alignItems: 'center'}}>
											<Field
												onClick={
													() => {
														this.setState((prevState) => {
															return {month_type: 'other'};
														});
													}
												}
												checked={this.state.month_type === 'other'}
												id="monthTypeOther"
												value={true}
												name={'monthType'}
												component={'input'}
												type="checkbox"
											/>
											<label htmlFor="monthTypeOther">The</label>
											<Field component={SelectInput}
												   options={dayOrderArray}
												   className="react-select-container small"
												   classNamePrefix="react-select"
												   disabled={this.state.month_type !== 'other'}
												   hideErrorMessages={true}
												   validate={this.state.month_type !== 'day' && [required]}
												   type="text"
												   name="week_day_order"
												   id="week_day_order" />
											<Field component={SelectInput}
												   options={weekDayArray}
												   className="react-select-container small"
												   classNamePrefix="react-select"
												   hideErrorMessages={true}
												   disabled={this.state.month_type !== 'other'}
												   validate={this.state.month_type !== 'day' && [required]}
												   type="text"
												   name="week_day"
												   id="week_day" />
											<span style={{marginLeft: 10}}>of every</span>
											<Field component={InputField}
												   className={"small"}
												   disabled={this.state.month_type !== 'other'}
												   hideErrorMessages={true}
												   validate={this.state.month_type !== 'day' && [required]}
												   type="text"
												   name="x_monthsOther"
												   id="x_months" />
											month(s)
										</div>
									</div>
								</div>
							</div>
						}
					</Fragment>}

                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={() => this.props.close(null)}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
						<button disabled={pristine || !valid || (this.state.type === "weekly" &&  this.state.week_day.length === 0) || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Save</button>
                    </div>
                </div>

				</form>
			</Fragment>
		);
	}
}


EditEmailSubscriptionForm = reduxForm({
	form: 'editEmailSubscriptionForm',
	destroyOnUnmount: true
})(EditEmailSubscriptionForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditEmailSubscriptionForm));
