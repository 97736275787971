export function associationAlertsReducer(state = {}, action) {
	switch(action.type) {
		case '@@ghin/SHOW_ASSOCIATION_ALERT':
			return {
				...state,
				showAlert: action.payload
			};
		case '@@ghin/ASSOCIATION_ALERT_DATA':
			return {
				...state,
				alert: action.payload
			};
    case '@@ghin/CLEAR_ASSOCIATION_ALERT_DATA':
      return {
        ...state,
        alert: null,
        showAlert: false
      }
		default:
			return state;
	}
}
