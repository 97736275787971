import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import GhinTable from '../../../shared/GhinTable';
import TransactionsFilter from './TransactionsFilter.js';
import moment from 'moment';
import Modal from 'react-modal';
import JoinAndRenewServices from '../../../../services/api/joinAndRenew';
import PaymentDetailModal from '../../../Modals/PaymentDetailModal';
import TransactionInformationModal from '../../../Modals/TransactionInformationModal';
import RefundModal from '../../../Modals/RefundModal';
import usePrevious from '../../../shared/customHooks/usePrevious';
import ReactTooltip from "react-tooltip";

const initial_pagy_data = { 
  page: 1, per_page: 25,
  total_count: 0, pages: 0
}

let status = true;

const transaction_types = { 'sale': 'Membership', 'refund': 'Refund' }

export default function Transactions(props) {

  const updateFilters = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const closeModal = () => {
    setShowPaymentDetailModal(false);
    setShowTransactionInformationModal(false);
    setShowRefundModal(false);
  }

  const buttonStyle = {
    textDecoration: 'underline',
    cursor: 'pointer'
  };

  const [showFilters, setShowFilters] = useState(true);
  const [showPaymentDetailModal, setShowPaymentDetailModal] = useState(false);
  const [showTransactionInformationModal, setShowTransactionInformationModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [transactions, setTransactions]  = useState([]);
  const [loadTransactions, setLoadTransactions] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [pagy, setPagy] = useState(initial_pagy_data);
  const [filters, setFilters] = useState({});
  const [sorted, setSorted] = useState([{ id: 'created_at', desc: true }]);
  const [sortingChanged, setSortingChanged] = useState(false);
  const prevFilters = usePrevious(filters);
  const canEditAssociation =  props.canEditAssociation; 

  if (filters !== undefined && filters !== null && prevFilters !== undefined && prevFilters !== null)
    status = JSON.stringify(filters) !== JSON.stringify(prevFilters) ? Math.random() : status;

  useEffect(() => {
    updateTable({ ...pagy, ...filters });
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [pagy.page, pagy.per_page, status, sortingChanged]) 

  const updateTable = (params) => {
    setLoadTransactions(true);
    delete params['total_count'];
    delete params['pages'];
    params['sorting_criteria'] = sorted[0].id || 'created_at';
    params['order'] = sorted[0].desc ? 'desc' : 'asc';
    JoinAndRenewServices.getTransactions(props.associationId, params)
      .then((res) => {
        setLoadTransactions(false);
        setTransactions(res.transactions);
        setPagy({ 
          ...pagy,
          per_page: parseInt(res['per-page']),
          total_count: parseInt(res['total-count']),
          pages: parseInt(res['total-pages'])
        });
      })
      .catch(() => setLoadTransactions(false));
  }

  const updateTableOnRefund = () => {
    setPagy(initial_pagy_data);
    setSorted([{ id: 'created_at', desc: true }])
    setFilters({});
    updateTable({...pagy, ...filters});
  }

  const exportToExcel = () => {
    let params = {...filters}
    JoinAndRenewServices.exportTransactions(props.associationId, params)
			.then((response) => {
				window.open(response.url, "_blank");
			})
			.catch((err) => {
				console.log(err);
			});
  }

  const columns = [
    {
      Header: 'Date',
      accessor: 'updated_at',
      colClassName: 'col_date_and_time',
      Cell: props => moment(new Date(props.original.created_at), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss')
    },
    {
      Header: 'Golfer Name',
      accessor: 'name',
      colClassName: 'col_method',
      Cell: props => `${props.original.last_name}, ${props.original.first_name}`
    },
    {
      Header: 'GHIN #',
      accessor: 'golfer_id',
      colClassName: 'col_ghin_number'
    },
    {
      Header: 'Club ID',
      accessor: 'club_id',
      colClassName: 'col_diff'
    },
    {
      Header: 'MC',
      accessor: 'membership_code',
      colClassName: 'col_holes'
    },
    {
      Header: 'Transaction Type',
      accessor: 'transaction_type',
      colClassName: 'col_method-small',
      Cell: props => 
        <span style={buttonStyle} onClick={() => {
            setSelectedTransaction(props.original);
            setShowPaymentDetailModal(true);}}>
          { transaction_types[props.original.transaction_type] }
        </span>
    },
    {
      Header: 'Transaction #',
      accessor: 'transaction_id',
      colClassName: 'col_method-small',
      Cell: props => 
        <span style={buttonStyle} onClick={() => {
            setSelectedTransaction(props.original);
            setShowTransactionInformationModal(true);}}>
          {props.original.transaction_id}
        </span>
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: props =>
        props.original.status === 'SUCCESS' ?
        <span style={{color: 'green', display: 'table', margin: '0 auto'}} className="material-icons-outlined">check_circle</span>
        :
        <span style={{color: 'red', display: 'table', margin: '0 auto'}} className="material-icons-outlined">do_not_disturb_on</span>
      ,
      colClassName: 'col_holes'
    },
    { 
      Header: 'Amount',
      accessor: 'amount',
      colClassName: 'col_other_adj',
      thClassName: 'table-align-right',
      Cell: props =>
      {
        if (props.original.status === 'SUCCESS' )
        {
          if (props.original.transaction_type === 'refund')
            return <span style={{color: 'red', display: 'table', float: 'inline-end'}}>-${props.original.amount}</span>
          else if(props.original.used_promo_code){
            const is_discount = props.original.payment_details.promotional_codes.some(pc => pc.is_discount);
            const toolTipId = `${props.original.transaction_id}-${props.original.id}`
            return (
              <div style={{display: 'flex', flexDirection: 'row', float: 'inline-end'}}>
                <i data-tip data-for={toolTipId} className="material-icons" style={{color: "#00365f", margin: is_discount ? '3px 5px 0 0' : '7px 5px 0 0', fontSize: '22px'}}>local_offer</i>
                <ReactTooltip id={toolTipId} className="table-head__tooltip" place='bottom' effect={'float'}>
                  <div style={{padding: '10px 0px'}}>
                    {
                      props.original.payment_details.promotional_codes.map(pc => {
                        return <p key={Math.random()}>Offer Applied: {pc.code}</p>
                      })
                    }
                  </div>
                </ReactTooltip>
                {
                  is_discount ?
                  <div style={{display: 'flex', flexDirection: 'column', lineHeight: 1.1, alignItems: 'flex-end'}}>
                    <span>${props.original.amount}</span>
                    <span style={{color: '#6c757d', fontStyle: 'italic', fontSize: '0.85em'}}>
                    Reg ${(parseFloat(props.original.amount) + props.original.payment_details.promotional_codes.reduce((acc, pc) => acc + (pc.discount_amount || 0), 0)).toFixed(2)}
                    </span>
                  </div>
                  :
                  <span style={{display: 'table', margin: '7px 0px'}}>${props.original.amount}</span>
                }
              </div>
            )
          }
          else
            return <span style={{display: 'table', float: 'inline-end'}}>${props.original.amount}</span>
        }
        else
          return <span style={{display: 'table', float: 'inline-end'}}>-</span>
      }
    },
    {
      collClassName: 'col_golfer-name-short',
      accessor: 'refundable',
      Cell: props => {
        if (canEditAssociation && props.original.refundable)
          return <span style={{color: 'green', display: 'table', margin: '0 auto', cursor: 'pointer'}} onClick={() => {setShowRefundModal(true); setSelectedTransaction(props.original);}} className="material-icons-outlined">currency_exchange</span>; 
        else 
          return null;
      }
    }
  ]
  
  return (
    <Fragment>

      <div className='columns'>
        <div className='row'>
          <div className='col'>
            <div className='panel'>
              <div className='panel__head'>Transactions</div>
              <div className='panel__body'>
                <div className='row'>
                  <div className='col'>
                    { showFilters && <TransactionsFilter associationId={props.associationId} updateFilters={updateFilters} /> }
                    <div className='pre__table'>
                      <div className='row'>
                        <div className='col auto push-left'>
                          <ul className='action__list push-left'>
                            <li>
                              <button className='btn fill green'
                                onClick={exportToExcel}>
                                  Export to Excel
                                </button>
                            </li>
                          </ul>
                        </div>
                        <div className='col auto centered'>
                          <ul className='table__legend'>
                            <li>
                              <i style={{color: 'green'}} className="material-icons-outlined icon-position">check_circle</i>
                              Transaction Successful
                            </li>
                            <li>
                              <i style={{color: 'red'}} className="material-icons-outlined icon-position">do_not_disturb_on</i>
                              Transaction Failed
                            </li>
                            <li>
                              <i style={{color: 'green'}} className="material-icons-outlined icon-position">currency_exchange</i>
                              Issue Refund
                            </li>
                            <li>
                              <i style={{color: '#00365f'}} className="material-icons icon-position">local_offer</i>
                              Offer Applied
                            </li>
                          </ul>
                        </div>
                        <div className='col auto push-right'>
                          <div className='row'>
                            <div className='col auto'>
                              {
                                showFilters ?
                                <button className='btn fill gray' onClick={() => setShowFilters(false)}>
                                  Hide Filters <i className='material-icons-outlined'>remove</i>
                                </button>
                                :
                                <button className='btn fill gray' onClick={() => setShowFilters(true)}>
                                  Show Filters <i className='material-icons-outlined'>add</i>
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <GhinTable
                      loading={loadTransactions}
                      columns={columns}
                      hideSelect={true}
                      pages={pagy.pages}
                      page={pagy.page-1}
                      manual
                      total={pagy.total_count}
                      pageSize={pagy.per_page}
                      data={transactions}
                      onPageChange={(pageIndex) => setPagy({...pagy, page: pageIndex+1})}
                      onPageSizeChange={(pageSize) => setPagy({...pagy, page: 1, per_page: pageSize})}
                      onSortedChange={(newSorted) => {
                        setPagy({...pagy, page: 1})
                        setSorted(newSorted);
                        setSortingChanged(!sortingChanged)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showPaymentDetailModal}
        onRequestClose={() => closeModal()}
        contentLabel='Modal'
        portalClassName='modal'
        overlayClassName='modal__overlay'
        className='modal__content'
        bodyOpenClassName='modal--is-open'
        htmlOpenClassName='prevent-scroll'
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
      >
        <PaymentDetailModal
          closeModal={() => closeModal()}
          transaction={selectedTransaction}
        />
      </Modal>

      <Modal
        isOpen={showTransactionInformationModal}
        onRequestClose={() => closeModal()}
        contentLabel='Modal'
        portalClassName='modal'
        overlayClassName='modal__overlay'
        className='modal__content'
        bodyOpenClassName='modal--is-open'
        htmlOpenClassName='prevent-scroll'
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
      >
      <TransactionInformationModal
          closeModal={() => closeModal()}
          association={props.association}
          transaction={selectedTransaction}
          transactionTypes={transaction_types}
        />
      </Modal>

      <Modal
        isOpen={showRefundModal}
        onRequestClose={() => closeModal()}
        contentLabel='Modal'
        portalClassName='modal'
        overlayClassName='modal__overlay'
        className='modal__content'
        bodyOpenClassName='modal--is-open'
        htmlOpenClassName='prevent-scroll'
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
      >
        <RefundModal
          closeModal={() => closeModal()}
          updateTableOnRefund={updateTableOnRefund}
          transaction={selectedTransaction}
        />
      </Modal>

    </Fragment>
  )
}
