import React, {Component, Fragment} from 'react';

export default class ContactUsModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">mail_outline</i></div>
                        <h2 className="modal__title">Association Support</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
                        <div className="row">
                            <div className="col is-1-of-5 jc-fs"><span className="label24">Email:</span></div>
                            <div className="col"><a className="mailto_lnk" href="mailto:customersupport@usga.org">customersupport@usga.org</a></div>
                        </div>
                        <div className="row">
                            <div className="col is-1-of-5 jc-fs"><span className="label24">Support Hours:</span></div>
                            <div className="col">
                                <p>
                                    <strong>Monday-Friday: 7:30 am - 7:00 pm (EST)</strong>
                                </p>
                                <p>
                                    <strong>Saturday &amp; Sunday: 9:00 am - 5:00 pm (EST)</strong>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </Fragment>
		);
	}
}
