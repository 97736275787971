import React, {Component} from 'react';
import {Field, formValueSelector, reduxForm, initialize} from "redux-form";
import {InputField} from "../../../inputs/InputField";
import {connect} from "react-redux";
import UsersService from "../../../services/api/users";
import NewUser from "./NewUser";
import {isEmailAddress, required} from "../../../inputs/validations";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";

class AddUserForm extends Component {

    constructor(props) {

        super(props);
        this.state = {
            selectMethod: true,
            showExistingUser: false,
			existingUserMessage: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

    showExistingUser() {
        this.setState({
            selectMethod: false,
            showExistingUser: true,
            showNewUser: false
        });
    }

    showNewUser() {
        this.setState({
            selectMethod: false,
            showExistingUser: false,
            showNewUser: true
        });
    }

    resetToSelect() {
        this.setState({
            selectMethod: true,
            showExistingUser: false,
            showNewUser: false
        });
    }

	onSubmit(values) {
    	let user = this.props.users ? this.props.users.filter(user => { return user.Email === values.email}) : '';

    	if (user.length > 0) {
    		this.setState({existingUserMessage:true})
		} else {
			return UsersService.search(encodeURIComponent(values.email), this.props.associationId, this.props.clubId)
				.then(response => {
					if (response.users.length === 0) {
						this.setState({
							showNewUser: true
						}, () => {
							this.props.dispatch(initialize('newUserForm', {email: values.email, active:true}))
						})
					} else {
						this.setState({
							showExistingUser: true
						}, () => {
							let self = this;
							self.props.dispatch(initialize('newUserForm', {...response.users[0], ...{active:true}}))
						})
					}
				})
				.catch(err => {

				})
		}


	}

	componentDidMount() {
    this.props.onRef(this);
		setTimeout(function() {
			document.getElementsByTagName('form')[0].querySelector('input').focus()
		}, 100)

	}

	render() {
    	let {handleSubmit,pristine, submitting, anyTouched, valid} = this.props;
    	if (this.state.existingUserMessage) {
			if (this.props.clubId) {
				return 'This user already has a role in this club.';
			} else {
				return 'This user already has a role in this association.';
			}
		}
		if (this.state.showExistingUser) {
			return <NewUser clubId={this.props.clubId} associationId={this.props.associationId} existingUser={true}  closeModal={this.props.closeModal} />
		} else if (this.state.showNewUser) {
			return <NewUser clubId={this.props.clubId} associationId={this.props.associationId} existingUser={false} closeModal={this.props.closeModal} data={{email: this.props.email}} resetToSelect={()=>{this.resetToSelect()}} />
		}
		return (
			<form onSubmit={handleSubmit(this.onSubmit)}>
				<div className="row">
					<div className="col is-1-of-2">
						<label for="email">Email <span>*</span></label>
						<Field validate={[required, isEmailAddress]} component={InputField} type="text" autoComplete="off" name="email" id="preemailfix" />
					</div>
					<div className={`col is-1-of-2 ${ (!anyTouched || valid) ?'jc-fe'  : 'jc-c'}`}>
						<button disabled={pristine || submitting || !valid} className="btn fill blue" type={'submit'} ref={'submit'}>Continue</button>
					</div>
				</div>
			</form>
		)
	}
}

AddUserForm = reduxForm({
	form: 'playerSearchForm',
	destroyOnUnmount: true
})(AddUserForm);

function mapStateToProps(state) {
	const selector = formValueSelector('playerSearchForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		email: selector(state, 'email')
	}
}

export default connect(mapStateToProps, null)(withKeyPress(AddUserForm));
