import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { bindActionCreators } from "redux";
import AssociationAlertsService from '../../services/api/associationAlerts';
import Modal from 'react-modal';
import { toggleAssociationAlert, saveAssociationAlert } from "./actions";
import AssociationAlertModal from '../Modals/AssociationAlertModal';

function AssociationAlert(props) {

  // const [showAlert, setShowAlert] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  useEffect(() => {
    if (props.assocId === 0) return;

    const cookies = new Cookies();
    if (!cookies.get('gotAssociationAlert')) {
      AssociationAlertsService.getAlert(props.assocId)
        .then(res => {
          if (res.association_alert && res.association_alert.active) {
            props.saveAssociationAlert(res.association_alert);
            const cookies = new Cookies();
            cookies.set('gotAssociationAlert', true, { path: '/', expires: new Date((new Date()).getTime() + 1000 * 3600 * 2)});
          } else {
            props.toggleAssociationAlert(false);
            props.saveAssociationAlert();
            const cookies = new Cookies();
            // The alert is not active, so we consider that we received then closed it.
            // Now while we are on this (user, association) pair, we will not request it again.
            cookies.set('gotAssociationAlert', true, { path: '/', expires: new Date((new Date()).getTime() + 1000 * 3600 * 2)});
            cookies.set('closedAssociationAlert', true, {path: '/', expires:  new Date((new Date()).getTime() + 1000 * 3600 * 24)});
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
    const cookies2 = new Cookies();
    if (!cookies2.get('closedAssociationAlert'))
      props.toggleAssociationAlert(true);
  });

  const hideAlert = () => {
    const cookies = new Cookies();
		cookies.set('closedAssociationAlert', true, {path: '/', expires:  new Date((new Date()).getTime() + 1000 * 3600 * 24)});
    props.toggleAssociationAlert(false);
  }
  return (
     props.alert && props.showAlert ?
    <Fragment>
      <div className="main__alert orange" onClick={() => setInfoModal(true)}>
        <div className="icon"/><span>{props.alert.banner_alert_message}</span>
        <button className="hide_alert" onClick={() => hideAlert()}/>
      </div>
				<Modal
					isOpen={infoModal}
					onRequestClose={()=>setInfoModal(false)}
					contentLabel="Modal"
					portalClassName="custom__modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AssociationAlertModal data={props.alert} closeModal={() => setInfoModal(false)}/>
				</Modal>
    </Fragment>
    :
    null
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({toggleAssociationAlert, saveAssociationAlert}, dispatch);
}

function mapStateToProps(state) {
  return {
    showAlert: state.associationAlerts.showAlert,
    alert: state.associationAlerts.alert,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociationAlert);