import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ClubService from "../../services/api/club";
import AssociationService from "../../services/api/association";
import {InputField} from "../../inputs/InputField";
import {SelectInput} from "../../inputs/Select";
import {required, requiredMultiSelect} from "../../inputs/validations";
import FacilityService from "../../services/api/facilities";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class EditKioskForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			clubs: [],
			courses: [],
			showKeyboard: this.props.data.show_keyboard
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		let data = this.props.data;
		data.clubs.map(c => {c.value = c.club_id; c.label = c.club_name; c.id = c.club_id; return c});
		// check if the course name is duplicate
		let halfName = (data.default_course_name.length - 1) / 2;
		let first = data.default_course_name.substring(0, halfName);
		let second = data.default_course_name.substring(halfName + 1, data.default_course_name.length);
		if(first === second) { data.default_course_name = first}

		data.default_course = {value: data.default_course, label: data.default_course_name};
		this.props.initialize(data);
		this.getHomeCourses(data.clubs);
		this.getAllClubs(data);
		this.props.onRef(this);
	}

	getAllClubs(data) {
		AssociationService.getAllClubs(this.props.associationId)
			.then(response => {
				let clubs = response.clubs;

				clubs.map(club => {club.value = club.id; club.label = club.club_name; return club});

				this.setState({
					clubs: clubs.sort((a, b) => a['label'].toString().localeCompare(b['label'].toString()))
				}, () => {
					this.props.change('clubs', data.clubs);
				})
			})
			.catch(err => {

			})
	}

	getHomeCourses(clubs) {
		let data = this.props.data;

		let courses = clubs.map(club => {return FacilityService.facilityHomeCourses(club.id)});
		Promise.all(courses)
			.then(response => {
				let facilities = [];
				response.forEach(res => {
					res.facilities.length > 0 &&
					res.facilities.map(facility => facilities.push(facility))
				});

				if (facilities) {
					let courses_long = facilities.map(f => {
						return f.home_courses.map(c => {
							c.value = c.course_id;
							c.label = c.name === f.name ? c.name : `${f.name} ${c.name}`;
							return c;
						});
					}).reduce((acc, val) => acc.concat(val), []) // the same as using .flat() (Edge legacy does not support .flat())
					let t;
					let courses = courses_long.filter((t={}, a=>!(t[a.course_id]=a.course_id in t)));
					this.setState({
						courses
					}, () => {
						let selectedCourse = this.state.courses.filter(c => c.value === data.default_course)[0];
						this.props.change('default_course', selectedCourse);
					})
				}
			})
			.catch(err => {
				console.log(err);
			})

	}
	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));
		data.clubs = data.clubs.map(c => c.id);
		data.default_course = data.default_course.value;

		return ClubService.updateKiosk(this.props.associationId, this.props.clubId, this.props.data.id, data)
			.then(response => {
				this.props.closeModal()
			})
			.catch(err => {

			})
	}

	render() {

		let {handleSubmit, submitting} = this.props;
		return (
			<Fragment>

				<form onSubmit={handleSubmit(this.onSubmit)}>

					<div className="row">
						<div className="col">
							<label htmlFor="club_display_name">Kiosk Display Name <span>*</span></label>
							<Field validate={[required]} component={InputField} type="text" autoComplete="off" name="club_display_name" id="club_display_name" />
						</div>
					</div>

					<div className="row">
						<div className="col">
							<label htmlFor="nickname">Nickname</label>
							<Field component={InputField} type="text" autoComplete="off" name="nickname" id="nickname" />
						</div>
					</div>

					<div className="row">
						<div className="col">
							<label htmlFor="clubs">Club(s) <span>*</span></label>
							<Field
								validate={[requiredMultiSelect]}
								name={'clubs'}
								component={SelectInput}
								onChange={(newValue)=> {
									if(newValue.length > 0) {
										this.getHomeCourses(newValue);
									} else {
										this.setState({courses: []})
									}
								}}
								className="react-select-container"
								classNamePrefix="react-select"
								options={this.state.clubs}
								isSearchable={false}
								isMulti={true}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col">
							<label htmlFor="default-course">Default Course <span>*</span></label>
							<Field
								name={"default_course"}
								component={SelectInput}
								noOptionsMessage={"Home Courses and Default Tees needs to be configured prior to completing the Kiosk setup."}
								className="react-select-container"
								classNamePrefix="react-select"
								options={this.state.courses}
								isSearchable={false}
								isMulti={false}
							/>
						</div>
					</div>

					<div className={"row"}>
						<div className="col is-full">
							<span className="label" htmlFor="club-name">On-Screen Keyboard</span>
							<div className="radio__container">

								<Field
									id="show-keyboard-yes"
									value={"true"}
									onClick={
										() => {
											this.setState((prevState) => {
												return {showKeyboard: !prevState.showKeyboard};
											});
										}
									}
									name={'show_keyboard'}
									component={'input'}
									checked={this.state.showKeyboard}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="show-keyboard-yes">On</label>

								<Field
									id="show-keyboard-no"
									value={"false"}
									name={'show_keyboard'}
									onClick={
										() => {
											this.setState((prevState) => {
												return {showKeyboard: !prevState.showKeyboard};
											});
										}
									}
									checked={!this.state.showKeyboard}
									className="simple__radio"
									component={'input'}
									type="radio"
								/>
								<label htmlFor="show-keyboard-no">Off</label>

							</div>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={() => this.props.closeModal()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} disabled={submitting} ref={'submit'} className="btn fill blue">Save</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

EditKioskForm = reduxForm({
	form: 'editKioskForm',
	destroyOnUnmount: true
})(EditKioskForm)

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditKioskForm))
