export const statusOptions = [
  {
    label: "Active Only",
    value: "active_only"
  },
  {
    label: "Inactive Only",
    value: "inactive_only"
  },
  {
    label: "Active and Inactive",
    value: "active_and_inactive"
  }
];

export function configTemplatesOptions(templates) {
  if (templates) {
    let parsed = templates.map(tmp => {
      return {
        label: tmp.name,
        value: tmp.id
      };
    })

    return parsed;
  }

  if (templates == null) return null;

  return [];
}

// Creates the initial values for the Send Emails tab form.
export function configInitialValues(associations, accountName, send) {
  let result = {
    associations: []
  };

  if (associations && associations.length > 0) {
    result.associations = [associations[0]]
  }
  
  result.sendgridAccount = {"value": "USGA Team", "label": "USGA Newsletters"};
  result.frontendProvider = {"value": "GHIN", "label": "GHIN"};
  result.golferStatus = statusOptions[0];

  return result;
}

// Creates the initial values for the Test Email tab form.
export function configInitialValuesTest() {
  let result = {}

  result.sendgridAccount = {"value": "USGA Team", "label": "USGA Newsletters"};

  return result;
}

export function configAssociationsOptions(associations, frontend_provider = undefined) {
  const staticValues = [
    {
      label: "All Allied Golf Associations",
      value: -1,
    },
    {
      label: "All Golf Associations",
      value: -2,
    }
  ];

  if (associations) {
    let activeAssociations = associations.filter(assoc => assoc.status === 'Active');
    if (frontend_provider && frontend_provider !== 'All')
      activeAssociations = activeAssociations.filter(assoc => assoc.frontend_provider === frontend_provider);

    let parsed = activeAssociations.map(tmp => {
      return {
        label: tmp.name,
        value: tmp.id
      };
    });

    return staticValues.concat(parsed);
  }

  return staticValues;
}

// values: the form data values (test send emails tab)
// returns an object containing the input in the format needed for creating a "test send email" request
export function prepareTestSendData(values) {
  let result = {};

  result.golfer_id = values.ghinNumber;
  result.template_id = values.templateName.value;
  result.email = values.emailAddress;
  result.sendgrid_account = values.sendgridAccount.value;

  return result;
}

// values: the form data values (Send Emails tab)
// returns an object containing the input in the format needed for creating a "send emails" request
export function prepareSendData(values) {
  let result = {
    used_gps_free_status: false,
    active_gps_subscription: false,
    inactive_gps_subscription: false,
    no_gps_subscription: false,
    all_allied_golf_associations: false,
    all_golf_associations: false,
    golfer_status: values.golferStatus.value,
    template_id: values.templateName.value,
    frontend_provider: values.frontendProvider.value,
    sendgrid_account: values.sendgridAccount.value
  }

  let idsNeeded = true;

  if (values.associations[0].value === -1) {
    result.all_allied_golf_associations = true;
    idsNeeded = false;
  }

  if (values.associations[0].value === -2) {
    result.all_golf_associations = true
    idsNeeded = false;
  }

  if (values.activeGpsSubscription) {
    result.active_gps_subscription = true
  }
  
  if (values.inactiveGpsSubscription) {
    result.inactive_gps_subscription = true
  }
  
  if (values.noGpsSubscription) {
    result.no_gps_subscription = true
  }

  if (values.usedGpsFreeTrials) {
    result.used_gps_free_status = true
  }

  // This array is not used on backend if all_allied_golf_associations or all_golf_associations is true
  if (idsNeeded) {
    result.associations = values.associations.map(assoc => assoc.value);
  }

  return result;
}

// campaignSorted: the sorting object emitted by the table callback onSortedChange
// returns a new object containing the keys needed by the loadCampaigns request sorting logic
export function load_campaigns_sorting(campaignSorted) {
  let sorting_criteria = null;
  let order = null;
  switch(campaignSorted.id) {
    case "date":
      sorting_criteria = "date_sent";
      order = campaignSorted.desc ? "DESC" : "ASC";
      break;
    default:
      sorting_criteria = "date_sent";
      order = campaignSorted.desc ? "DESC" : "ASC";
  }
  let response = {
    sorting_criteria: sorting_criteria,
    order: order
  }

  return response;
}
// detailsSorted: the sorting object emitted by the table callback onSortedChange
// returns a new object containing the keys needed by the loadDetails request sorting logic
// Created another function different from load_campaigns_sorting in case the ids will ever intersect and mean something different
export function load_details_sorting(detailsSorted) {
  let sorting_criteria = null;
  let order = null;
  switch(detailsSorted.id) {
    case "ghin_number":
      sorting_criteria = "ghin_number";
      break;
    case "email":
      sorting_criteria = "email";
      break;
    case "status":
      sorting_criteria = "status";
      break;
    case "created_at":
      sorting_criteria = "created_at";
      break;
    default:
      sorting_criteria = "ghin_number";
  }

  order = detailsSorted.desc ? "DESC" : "ASC";

  let response = {
    sorting_criteria: sorting_criteria,
    order: order
  }

  return response;
}

// Comparator function to be used for sorting an array of GolfAssociation by name
export function compareAssociationsByName(first, second) {
  if (first.name < second.name) return -1;

  if (first.name > second.name) return 1;

  return 0;
}
