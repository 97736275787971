import React, {Component, Fragment} from 'react';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {isUrlFormat} from "../../inputs/validations";

class EditImageInformationForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: this.props.email,
			urlError: null,
		}
	}

	render() {
		if (!this.state.email) {
			return null;
		}
		return (
			<Fragment>

				<div className="row">
					<div className="col is-full">
						<div className="form__image-preview">
							<img src={this.state.email.preview} alt="" name=""/>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col is-full">
						<label>Image Url</label>
						<span className="image-preview__url">{this.state.email.image_url}</span>
					</div>
				</div>

				<div className="row">
					<div className="col is-full">
						<label htmlFor="image-alt-text">Alt Text</label>
						<input type="text"
							   value={this.state.email.alt_text}
							   onChange={(event) => this.setState({
								   email: {
									   ...this.state.email,
									   alt_text: event.target.value
								   }
							   })}
							   autoComplete="off"
							   name="image-alt-text"
							   id="image-alt-text" />
					</div>
				</div>

				<div className="row">
					<div className="col is-full">
						<label htmlFor="image-redirect-url">Redirect URL</label>
						<input type="url"
							   value={this.state.email.ad_image_url}
							   onChange={(event) => {this.setState({
								   email: {
									   ...this.state.email,
									   ad_image_url: event.target.value,
								   },
								   urlError: isUrlFormat(event.target.value)
							   })}}
							   className={this.state.urlError ? "is-error" : ""}
							   autoComplete="off"
							   name="image-redirect-url"
							   id="image-redirect-url" />
						{this.state.urlError &&  <span className={'validation__message is-error'}>{this.state.urlError}</span>}
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<button className="btn fill gray" type={'button'}onClick={() => this.props.closeModal()}>Cancel</button>
					</div>
					<div className="col is-1-of-2">
						<button className="btn fill blue" disabled={this.state.urlError} type={'submit'} ref={'submit'} onClick={() => {this.props.closeModal(this.state.email)}}>Save</button>
					</div>
				</div>

			</Fragment>
		);
	}
}
export default withKeyPress(EditImageInformationForm);
