import request from "../shared/request";

/**
 * todo: rename this from Club to something more relevant
 */
export default class FacilityService {

	static getList(name) {
		return request({
			url: `/Facilities/Search.json?name=${name}`,
			method: 'GET'
		})
	}

	static facilityHomeCourses(clubId) {
        return request({
            url: `/clubs/${clubId}/facility_home_courses.json`,
            method: 'GET'
        });
    }

	static getById(id) {
		return request({
			url: `/Facilities/${id}/Courses.json`,
			method: 'GET'
		})
	}


}
