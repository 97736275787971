import { Fragment, useState, useEffect } from 'react';
import GhinFilterSelect from '../../../shared/GhinFilterSelect';
import 'react-picky/dist/picky.css';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { checkKeyForDateInput } from '../../../../inputs/helper';
import AssociationService from "../../../../services/api/association";
import JoinAndRenewServices from "../../../../services/api/joinAndRenew";

const initial_filters = {
  search: '',
  club_id: '',
  membership_code: [],
  auto_renew: [],
  discount_code: [],
  transaction_type: [],
  transaction_number: '',
  status: [],
  from_date: '',
  to_date: ''
}

export default function TransactionsFilter(props) {

  const [filters, setFilters] = useState(initial_filters);
  const [membershipCodes, setMembershipCodes] = useState([]);
  const [discountCodes, setDiscountCodes] = useState([]);

  useEffect(() => { 
    getMembershipTypes();
    getDiscountCodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 
  
  useEffect(() => {
    const queryParams = setQueryParams(filters)

    let timer = setTimeout(() => {
      props.updateFilters(queryParams)
      }, 500);

    return () => {
      clearTimeout(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const getMembershipTypes = () => {
    const params = { include_used_membership: 'false'}
    AssociationService.getMembershipTypes(props.associationId, params)
    .then(res => {
      const membership_data = res.membership_types ? res.membership_types.map(membershipType => {
        return {label: membershipType.code + ' - ' + membershipType.description, value: membershipType.code};
      }).sort((a, b) => a['label'].toString().localeCompare(b['label'].toString())) : [];

      const uniq_membersip_data = [...new Map(membership_data.map(item => [item["value"], item])).values()];
      setMembershipCodes(uniq_membersip_data);
    })
    .catch(err => {
      console.log('error')
    })
	}
  
  const getDiscountCodes = () => {
    const params = { page: 1, per_page: 100, sorting_criteria: 'code', order: 'asc'}
    JoinAndRenewServices.getPromotionalCodes(props.associationId, params)
      .then(res => {
        const discount_data = res.promotional_codes ? res.promotional_codes.map(promotional_code => {
          return {label: promotional_code.code, value: promotional_code.code};
        }) : [];
  
        setDiscountCodes(discount_data);
      })
      .catch(err => {
        console.log(err);
      })
	}

  const setQueryParams = (inputData) => {
    let nonEmptyFilters = cleanEmptyParams(inputData);
    nonEmptyFilters = cleanEmptyParamsArray(nonEmptyFilters);

    if (nonEmptyFilters.auto_renew) {
      const autoRenew = nonEmptyFilters.auto_renew.map((value) => value.value).toString();
      nonEmptyFilters = {...nonEmptyFilters, auto_renew: autoRenew};
    }

    if (nonEmptyFilters.status) {
      const status = nonEmptyFilters.status.map((value) => value.value).toString();
      nonEmptyFilters = {...nonEmptyFilters, status: status};
    }

    if (nonEmptyFilters.transaction_type) {
      const transaction_type = nonEmptyFilters.transaction_type.map((value) => value.value).toString();
      nonEmptyFilters = {...nonEmptyFilters, transaction_type: transaction_type};
    }

    if (nonEmptyFilters.membership_code) {
      const membership_code = nonEmptyFilters.membership_code.map((value) => value.value).toString();
      nonEmptyFilters = {...nonEmptyFilters, membership_code: membership_code};
    }
    
    if (nonEmptyFilters.discount_code) {
      const discount_code = nonEmptyFilters.discount_code.map((value) => value.value).toString();
      nonEmptyFilters = {...nonEmptyFilters, discount_code: discount_code};
    }

    return nonEmptyFilters;
  }

  
  const cleanEmptyParams = (obj) => {
    return Object.entries(obj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
  }

  const cleanEmptyParamsArray = (obj) => {
    return Object.entries(obj).reduce((a,[k,v]) => (Array.isArray(v) && v.length === 0 ? a : (a[k]=v, a)), {});
  }

  return (
    props.hideFilters ? null :
    <Fragment>

      <div className='filters'>
        <div className='row'>
          <div className='filters__inputs col'>
            <div className='row'>
              <div className='col'>
                <div className='row'>
                  <div className='col is-4-of-12'>
                    <label htmlFor='ghin-or-golfer'>GHIN Number or Golfer Name</label>
                    <input type='text'
                      autoComplete='off'
                      name='ghin-or-golfer'
                      id='ghin-or-golfer'
                      value={filters.search}
                      onChange={(e) => setFilters({...filters, search: e.target.value})}
                    />
                  </div>
                  <div className='col is-2-of-12'>
                    <label htmlFor='club_id'>Club ID</label>
                    <input type='text'
                      autoComplete='off'
                      name='club_id'
                      id='club_id'
                      value={filters.club_id}
                      onChange={(e) => setFilters({...filters, club_id: e.target.value})}
                    />
                  </div>
                  <div className='col is-2-of-12'>
                    <GhinFilterSelect
                      options={membershipCodes}
                      value={filters.membership_code}
                      onChange={(value) => setFilters({...filters, membership_code: value})}
                      label={'MC'}
                    />
                  </div>
                  <div className='col is-2-of-12'>
                    <GhinFilterSelect
                      options={[{label: 'Yes', value: 'true'}, {label: 'No', value: 'false'}]}
                      value={filters.auto_renew}
                      onChange={(value) => setFilters({...filters, auto_renew: value})}
                      label={'Auto-Renew'}
                    />
                  </div>
                  <div className='col is-2-of-12'>
                    <GhinFilterSelect
                      options={discountCodes}
                      value={filters.discount_code}
                      onChange={(value) => setFilters({...filters, discount_code: value})}
                      label={'Discount Codes'}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col is-2-of-12'>
                    <GhinFilterSelect
                      options={[{label: 'Membership', value: 'sale'}, {label: 'Refund', value: 'refund'}]}
                      value={filters.transaction_type}
                      onChange={(value) => setFilters({...filters, transaction_type: value})}
                      label={'Transaction Type'}
                    />
                  </div>
                  <div className='col is-2-of-12'>
                    <label htmlFor='transaction_no'>Transaction #</label>
                    <input type='text'
                      autoComplete='off'
                      value={filters.transaction_number}
                      name='transaction_no'
                      id='transaction_no'
                      onChange={(e) => setFilters({...filters, transaction_number: e.target.value})}
                    />
                  </div>
                  <div className='col is-2-of-12'>
                    <GhinFilterSelect
                      options={[{label: 'Successful', value: 'SUCCESS'}, {label: 'Failed', value: 'FAILED'}]}
                      value={filters.status}
                      onChange={(value) => setFilters({...filters, status: value})}
                      label={'Transaction Status'}
                    />
                  </div>
                  <div className="col is-3-of-12">
                      <label>Transaction Date From</label>
                      <DatePicker
                        selected={filters.from_date ? new Date(filters.from_date) : null}
                        maxDate={filters.to_date ? new Date(filters.to_date) : new Date()}
                        className={`date`}
                        dropdownMode='select'
                        dateFormat={'MM/dd/yyyy'}
                        showMonthDropdown
                        showYearDropdown
                        onKeyDown={e => {checkKeyForDateInput(e)}}
                        onChange={(value) => {
                          if (value) {
                            const date = new Date(value)
                            const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
                            setFilters({ ...filters, from_date: dateString });
                          }
                        }}
                      />
                  </div>
                  <div className="col is-3-of-12">
                    <label>Transaction Date To</label>
                    <DatePicker
                      selected={filters.to_date ? new Date(filters.to_date) : null}
                      maxDate={new Date()}
                        minDate={filters.from_date ? new Date(filters.from_date) : null}
                      className={`date`}
                      dropdownMode='select'
                      dateFormat={'MM/dd/yyyy'}
                      showMonthDropdown
                      showYearDropdown
                      onKeyDown={e => {checkKeyForDateInput(e)}}
                      onChange={(value) => {
                        if (value) {
                          const date = new Date(value)
                          const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
                          setFilters({ ...filters, to_date: dateString });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='filters__controls col is-1-of-7'>
            <label htmlFor='reset' style={{visibility: 'hidden'}}>Reset</label>
            <button className='btn fill gray' onClick={() => {setFilters(initial_filters)}}>Reset</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
