import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import EditImageInformationModal from '../screens/Modals/EditImageInformationModal';
import ConfirmationModal from "../screens/Modals/ConfirmationModal";
import EditContentBlockForm from '../screens/Forms/EditContentBlockForm';

export default class UploadLogo extends Component {

	constructor(props) {

		super(props);

		this.state = {
			editImageInformationModalIsOpen: false,
			editCustomHTMLModalIsOpen: false,
			image: this.props.image,
			alt_text: this.props.alt_text,
			email_settings: {
				header_content_setup: this.props.email_settings.header_content_setup,
				header_text: this.props.email_settings.header_text,
				header_text_color: this.props.email_settings.header_text_color,
				use_app_primary_color_for_header_text: this.props.email_settings.use_app_primary_color_for_header_text,
				preferred_app_color: this.props.email_settings.preferred_app_color,
				custom_html: this.props.email_settings.custom_html,
				image_alignment: this.props.email_settings.image_alignment
			},
			confirmModalIsOpen: false,
			confirmElementDelete: false,
			alignment: [
				{value: 'left', label: 'Align Image Left + Header Text Right'},
				{value: 'right', label: 'Align Image Right + Header Text Left'},
				{value: 'center', label: 'Align Image Center (No Header Text)'}
			]
		};

		this.openEditImageInformationModal = this.openEditImageInformationModal.bind(this);
		this.openCustomHTMLModal = this.openCustomHTMLModal.bind(this);

	}


	openEditImageInformationModal() {
		this.setState({editImageInformationModalIsOpen: true});
	}

	openCustomHTMLModal() {
		this.setState({editCustomHTMLModalIsOpen: true});
	}

	closeModal() {
		this.setState({
			confirmModalIsOpen: false,
			editImageInformationModalIsOpen: false
		})
	}

	closeImageModal(data) {
		if(data) {
			this.setState({
				editImageInformationModalIsOpen: false,
				alt_text: data ? data.alt_text : this.state.alt_text,
				email_settings: data ? data.email_settings : this.state.email_settings
			}, () => { this.props.onSetAltText(this.state.alt_text, this.state.email_settings)})
		} else {
			this.setState({editImageInformationModalIsOpen: false});
		}

	}

	async setImage(file) {
		this.saveImage(file);
	}

	removeImage() {
		this.setState({confirmModalIsOpen:true});
	}

	saveImage(file, type) {
		this.props.onSave(file);
	}

	getImageAlignment() {
		return this.state.alignment.filter(r => r.value === this.state.email_settings.image_alignment)[0].label;
	}

	closeCustomHTMLModal(data) {
		this.setState(
			{email_settings: {
				...this.state.email_settings,
				custom_html: data},
			editCustomHTMLModalIsOpen: false},
			() => this.props.onSaveSettings(this.state.email_settings))
  }

	componentDidMount() {
		this.setState({
			image: this.props.image,
			alt_text: this.props.alt_text
		})
	}

	componentDidUpdate(prevProps) {
		//set the state with data from props
		if(this.props.image !== prevProps.image || this.props.alt_text !== prevProps.alt_text){
			this.setState({
				image: this.props.image,
				alt_text: this.props.alt_text
			})
		}
	}

	render() {
		return (
			<Fragment>
				<div className="panel__head">
					{this.props.title}
					{(this.props.removable && !this.props.readOnly) && <div className="panel__end">
						<button className="btn lnk red" onClick={() => this.setState({
							confirmElementDelete: true
						})}>Delete</button>
					</div>}
				</div>
				<div className="panel__body">
					<div className="row">
						<div className="col is-full">
							<div className="radio__container">
								<input
									onChange={ () => { this.setState({email_settings: {...this.state.email_settings, header_content_setup: "default"}}, () => this.props.onChangeSetup(this.state.email_settings)) }}
									checked={this.state.email_settings.header_content_setup === "default"}
									id="default_header"
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="default_header">Use Default Setup</label>
								<input
									onChange={ () => { this.setState({email_settings: {...this.state.email_settings, header_content_setup: "custom"}}, () => this.props.onChangeSetup(this.state.email_settings)) }}
									checked={this.state.email_settings.header_content_setup === "custom"}
									id="custom_header"
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="custom_header">Use Custom HTML</label>
							</div>
						</div>
					</div>
					{this.state.email_settings.header_content_setup === "default" &&
						<div className="upload__item">
							{this.state.image ?
							<div className="upload__details">
								<div className="row">
									<div className="col is-1-of-2">
										<center>
											<figure >
												<img src={this.state.image}
													 alt={this.props.alt_text ? this.props.alt_text : 'ghin-admin'}
													 name={this.props.alt_text ? this.props.alt_text : 'ghin-admin'}
													 style={{ maxWidth: "100%" }}/>
											</figure>
										</center>
									</div>
									<div className="col is-1-of-2 jc-fs">
										<div className="upload_item">
											<h6 className="upload__label">Image Url:</h6>
											<a className="upload__url" href={this.state.image}>{this.state.image}</a>
											<h6 className="upload__label">Alt Text:</h6>
											<span className="upload__info">{this.state.alt_text}</span>
											<h6 className="upload__label">Image Alignment:</h6>
											<span className="upload__info">{this.getImageAlignment()}</span>
											{this.state.email_settings.header_text.trim() !== "" && this.state.email_settings.image_alignment !== "center" &&
											<Fragment>
												<h6 className="upload__label">Header Text:</h6>
												<span className="upload__info">{this.state.email_settings.header_text}</span>
												<Fragment><h6 className="upload__label">Header Text Color:</h6>
													<div>
														<span className="upload__info">#{this.state.email_settings.use_app_primary_color_for_header_text ? this.state.email_settings.preferred_app_color : this.state.email_settings.header_text_color}</span>
														<span className="circle" style={{ backgroundColor: `#${this.state.email_settings.use_app_primary_color_for_header_text ? this.state.email_settings.preferred_app_color : this.state.email_settings.header_text_color}`}}></span>
													</div>
												</Fragment>
											</Fragment>}
										</div>
									</div>
								</div>
							</div>
							:
							(!this.props.readOnly && <Fragment>
								<div className="upload__zone">
									<Dropzone accept={['image/png', 'image/jpg', 'image/jpeg']}
											  onDrop={acceptedFiles => acceptedFiles.length > 0 && this.setImage(acceptedFiles[0])}
											  multiple={false}
											  disabled={this.state.image ? true : false}
									>
										{({getRootProps, getInputProps}) => (
											<div className="dropzone" {...getRootProps()}>
												<input {...getInputProps()} />
												<i className="material-icons-outlined">cloud_upload</i>
												<p>Drag &amp; Drop file here to upload</p>
												<p>or</p>
												<button disabled={this.state.image}
														className="btn fill green">Choose from computer
												</button>
											</div>
										)}
									</Dropzone>
								</div>
								<div className="upload__details">
									<ul className="upload__requirements">
										<li>Required Format = PNG, JPG</li>
										<li>The maximum image height is 155 px. Any images uploaded with an image height greater than 155 px will be resized proportionately.</li>
									</ul>
								</div>
							</Fragment>)}
							{!this.props.readOnly && <div className="upload__controls list">
									{this.state.image &&
									<button
										onClick={() => this.openEditImageInformationModal()}
										className="btn lnk">
										Edit
									</button>}
									<button disabled={!this.state.image}
											className="btn fill gray"
											onClick={() => {this.removeImage()}}>
										<i className="material-icons-outlined no-margin">clear</i>
									</button>
								</div>}

						</div>}
						{this.state.email_settings.header_content_setup === "custom" &&
						<div>
							<div className="row">
									<div className="col">
											<h4 className="section_title">Custom HTML</h4>
									</div>
									<div className="col auto push-right jc-c">
											<button
													onClick={() => this.openCustomHTMLModal()}
													className="btn lnk">Edit
											</button>
									</div>
							</div>
							{this.state.editCustomHTMLModalIsOpen ?
									<EditContentBlockForm
											closeModal={this.closeCustomHTMLModal.bind(this)}
											content={this.state.email_settings.custom_html || ''}
									/> :
									<Fragment>
										<div className="content_preview"
												 dangerouslySetInnerHTML={{__html: this.state.email_settings.custom_html}}/>
									</Fragment>}
						</div>
						}
				</div>

				<Modal
					isOpen={this.state.editImageInformationModalIsOpen}
					onRequestClose={() => {
						this.closeModal()
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					{this.state.editImageInformationModalIsOpen &&
						<EditImageInformationModal image={this.state.image} alt_text={this.state.alt_text} logo={true} email_settings={this.state.email_settings} closeModal={(data) => this.closeImageModal(data)}/>
					}
				</Modal>

				<ConfirmationModal openModal={this.state.confirmModalIsOpen}
					onConfirmAction={()=>{this.setState({confirmModalIsOpen: false});this.props.onRemove()}}
					onCancelAction={()=>{this.setState({confirmModalIsOpen: false})}}
					closeModal={()=>{this.setState({confirmModalIsOpen: false})}}
					question={"Are you sure?"}
					cancelLabel={"No"}
					confirmLabel={"Yes"}/>

				<ConfirmationModal
					question={`Are you sure you want to delete this entry?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({confirmElementDelete: false})}
					onConfirmAction={() => this.props.onDelete()}
					openModal={this.state.confirmElementDelete}
					closeModal={() => this.setState({confirmElementDelete: false})}
				/>

			</Fragment>
		);
	}
}
