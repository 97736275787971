import { Fragment, useCallback, useEffect, useState } from "react";
import GhinTable from "../../../../shared/GhinTable";
import JoinAndRenewServices from "../../../../../services/api/joinAndRenew";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAlert } from "../../../../shared/actions";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import GenericModal from "../../../../Modals/GenericModal";
import EditJoinPageNameForm from "../../../../Forms/EditJoinPageNameForm";
import EditSelectedMembershipsForm from "../../../../Forms/EditSelectedMembershipsForm";
import EditSelectedAddonsForm from "../../../../Forms/EditSelectedAddonsForm";
import EditJoinPageCustomizationForm from "../../../../Forms/EditJoinPageCustomizationForm";
import previewContent from "./previewContent";

function JoinPagesDetails(props) {
  const [name, set_name] = useState(props.data.name)
  const [addons, set_addons] = useState([])
  const [addons_loading, set_addons_loading] = useState(false)
  const [clubs, set_clubs] = useState([])
  const [clubsPages, set_clubsPages] = useState(0)
  const [clubsPageSize, set_clubsPageSize] = useState(25)
  const [clubsPage, set_clubsPage] = useState(1)
  const [clubs_loading, set_clubs_loading] = useState(false)
  const [selectAddonsModalOpen, set_selectAddonsModalOpen] = useState(false)
  const [selectMembershipsModalOpen, set_selectMembershipsModalOpen] = useState(false)
  const [editJoinPageNameOpen, set_editJoinPageNameOpen] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [editCustomizationOpen, set_editCustomizationOpen] = useState(false)
  
  const summaryMode = props.hide_edit;

  const reload_addons = useCallback(() => {
    const join_page_data = props.data
    set_addons_loading(true)
    JoinAndRenewServices.getAddonCharges(join_page_data.golf_association_id, {
      join_page_id: join_page_data.id
    }).then((res) => {
      set_addons(res.addon_charges)
    }).finally(() => {
      set_addons_loading(false)
    })
  }, [props.data])

  const load_clubs = useCallback(() => {
    set_clubs_loading(true);
    const join_page_data = {
      id: props.data.id,
      golf_association_id: props.data.golf_association_id
    };
    const params = {
      hide_used: 'true',
  		hide_join_page_ids: 'false',
      is_aga: 'false',
      join_page_id: join_page_data.id,
      page: clubsPage,
      per_page: clubsPageSize
    }
    JoinAndRenewServices.getAssociationClubMembershipFees(join_page_data.golf_association_id, params).then((res) => {
      set_clubsPages(parseInt(res['total-pages']))
      set_clubsPage(Math.min(parseInt(res['total-pages']), clubsPage))
      let clubs = [];
      const response = Object.values(res.club_memberships);
      response.forEach(club => {
        let memberships = [];

        club["club_membership_fees"].forEach(fee => {
    			const include = fee.join_page_ids.includes(join_page_data.id);
    			if (include)
    				memberships.push(fee);
    		});

        let club_details = {
          club_id: club["club_id"],
          club_name: club["club_name"],
          join_and_renew_enabled: club["join_and_renew_enabled"],
          status: club["club_status"],
          has_club_memberships: club["has_club_memberships"],
          club_membership_fees: memberships
         };

         clubs.push(club_details);
      });
      set_clubs(clubs);
      set_clubs_loading(false);
    }).finally(() => {
      set_clubs_loading(false);
    });

  }, [props.data.golf_association_id, props.data.id, clubsPage, clubsPageSize])

  useEffect(() => {
    load_clubs();
  }, [load_clubs])

  useEffect(() => {
    reload_addons()
  }, [reload_addons])

  const calculateTotalAmount = (fee) => {
    const total_amount = parseFloat(fee.golf_association_membership_amount || 0.0) +
      parseFloat(fee.golf_association_fee_amount || 0.0) +
      parseFloat(fee.amount || 0.0)
    return total_amount.toFixed(2)
  };


  const columns = [
    {
      Header: "Add-On Name",
      accessor: "name"
    },
    {
      Header: "Description",
      accessor: "description"
    },
    {
      Header: "Fee",
      accessor: "amount",
      thClassName: 'is-1-of-2',
      Cell: data => data.original.is_multiple_options ? 'Multiple Fee Options'
        :
        `$${data.value}`
    },
    {
      Header: "Optional?",
      accessor: "is_optional",
      thClassName: 'is-1-of-2',
      Cell: data => data.value ? "Yes" : "No"
    },
    {
      Header: "Donation?",
      accessor: "is_donation",
      colClassName: "col_gender",
      Cell: data => data.value ? "Yes" : "No"
    }
  ]

  const club_group_summary_column = [
    {
      Header: "Club Number",
      accessor: "club_id",
      thClassName: 'is-1-of-3'
    },
    {
      Header: "Club Name",
      accessor: "club_name",
      thClassName: 'is-2-of-2'
    },
    {
      Header: "Club Membership Fee Detail",
      accessor: "club_membership_fees",
      Cell: data => {
      return (
        <Fragment>
          	<ul>
              {data.row.club_membership_fees.map((m) =>{
                return (<li key={m.id}>
                  {m.name} (${calculateTotalAmount(m)})
                </li>)
              })}
            </ul>
        </Fragment>
      )}
    },
    {
      Header: "Join & Renew",
      accessor: "join_and_renew_enabled",
      thClassName: 'is-1-of-3',
      Cell: data => <div style={{'textAlign': 'center'}}> {data.value ? "Enabled": "Disabled"} </div>
    },
  ]

	const club_group_column = [
	  {
		Header: "Club Number",
		accessor: "club_id",
		thClassName: 'is-1-of-3'
	  },
	  {
		Header: "Club Name",
		accessor: "club_name",
		thClassName: 'is-2-of-2'
	  },
	  {
		Header: "Join & Renew",
		accessor: "join_and_renew_enabled",
		thClassName: 'is-1-of-3',
		Cell: data => <div style={{'textAlign': 'center'}}> {data.value ? "Enabled": "Disabled"} </div>
	  },
	  {
		Header: "Setup",
		accessor: "join_and_renew_enabled",
		thClassName: 'is-2-of-3',
		Cell: data => {
      if (props.data.has_credentials && data.original.join_and_renew_enabled && data.original.status === 'Active' && data.original.has_club_memberships) {
        return <p style={{ color: "green", textAlign: 'center'}}>Complete</p>
      }

      return <div className="center-join-pages-logos">
        <a href="#donothing" onClick={(e) => { e.preventDefault() }} data-tip data-for={`join-page-${data.original.club_id}`}><i className="material-icons v-mid m-left yellow">info</i></a>
        <ReactTooltip id={`join-page-${data.original.club_id}`} className="table-head__tooltip"  place='bottom' effect={'float'}>
          <div style={{padding: '10px 0px', textAlign: 'center'}}>
            <p>The following information is required to complete the setup of this join page. The steps already marked are complete</p>
            <br></br>
            <div className='row'> 
              {!props.data.has_credentials && <i className="material-icons v-mid m-left green">radio_button_unchecked</i>}
              {props.data.has_credentials && <i className="material-icons v-mid m-left green">check_circle</i>}
              <p style={{paddingLeft: '3px'}}>Association Commerce Provider Credentials</p>
            </div>
            <div className='row'> 
              {data.original.status !== 'Active' && <i className="material-icons v-mid m-left green">radio_button_unchecked</i>}
              {data.original.status === 'Active' && <i className="material-icons v-mid m-left green">check_circle</i>}
              <p style={{paddingLeft: '3px'}}>Active Club</p>
            </div>
            <div className='row'> 
              {!data.original.join_and_renew_enabled && <i className="material-icons v-mid m-left green">radio_button_unchecked</i>}
              {data.original.join_and_renew_enabled && <i className="material-icons v-mid m-left green">check_circle</i>}
              <p style={{paddingLeft: '3px'}}>Join & Renew Enabled</p>
            </div>
            <div className='row'> 
              {!data.original.has_club_memberships && <i className="material-icons v-mid m-left green">radio_button_unchecked</i>}
              {data.original.has_club_memberships && <i className="material-icons v-mid m-left green">check_circle</i>}
              <p style={{paddingLeft: '3px'}}>Club needs to have at least 1 club membership fee</p>
            </div>
          </div>
        </ReactTooltip>
        <p style={{color: '#FF9900', paddingLeft: '3px'}}> Incomplete</p>
      </div>
		  }
	  },
	  {
		Header: "Club Join Page URL",
		accessor: "club_membership_fees",
      Cell: data => <div
        style={{
          'textAlign': 'center',
          'overflow': 'hidden'
        }}
      > <a href={`${props.data.url + '/club/' + data.original.club_id}`}>{props.data.url + '/club/' + data.original.club_id}</a></div>
	  },
	  {
		thClassName: 'is-2-of-4',
		Cell: data => {
      const enabled = props.data.has_credentials && props.data.has_memberships && data.original.join_and_renew_enabled && data.original.status === 'Active';
      
		  return <Fragment>
			<button className="btn fill blue"
        disabled = {!enabled}
			  style={{ width: "95px", margin: "auto" }}
			  onClick={() => {
          navigator.clipboard.writeText(props.data.url + '/club/' + data.original.club_id);
			    props.addAlert({
				  type: 'success',
            message: `Join Link copied to clipboard`
				});
			  }}
			>Copy Link</button>
		  </Fragment>
	    }
	  }
	]

  return (
    <Fragment>
      <td colSpan={9} style={{ padding: "10px" }}>
        <div className="columns">
          <div className="row">
            <div className="col">
              <div className={props.hide_edit ? "" : "panel"}>
                <div className="panel__body">
                  <div className="row centered-items hr-margin-24-top hr-margin-24-bottom">
                    <div className="col auto">
                      <h2 className="black-text no-wrap">
                        {name.toUpperCase()} JOIN PAGE DETAILS
                      </h2>
                    </div>
                  </div>
                  <div className="row hr-margin-24-bottom">
                    <div className="col">
                      <p><strong>Page Name: </strong> <span className={`link-name-${props.data.id}`}>{name}</span></p>
                    </div>
                    {!props.hide_edit && props.canEditAssociation && <div className="col auto push-right">
                      <button
                        className="btn lnk"
                        onClick={() => set_editJoinPageNameOpen(true)}
                      >
                        Edit
                      </button>
                    </div>}
                  </div>
                  <hr style={{ border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px' }}></hr>
                  <div className="row">
                    <div className="col">
                      <span><strong>Selected Clubs & Club Memberships</strong></span>
                    </div>
                    {!props.hide_edit && props.canEditAssociation && <div className="col auto push-right">
                      <button
					    className="btn lnk"
					  	onClick={() => set_selectMembershipsModalOpen(true)}
					  >
                        Edit
                      </button>
                    </div>}
                  </div>
                  <div className="row">
                    <div className="col">
                      {summaryMode && <span>The following clubs and club memberships will display on this join page. If the club is inactive, is not enabled for Join & Renew, or does not have Club Membership Fees the club will not display on the resulting join page.</span>}
                      {!summaryMode && <Fragment>
                        <span>The following clubs and club memberships will display on this Join Page. Each club has been provided a Club Join Page URL which can be used on its website.</span>
                        <br></br>
                        <p><strong>Important Note:</strong>  If the club becomes inactive, is no longer enabled for Join & Renew, or no longer has Club Membership Fees the club will display as “Incomplete” below and will not display on the resulting join page.</p>
                      </Fragment>  
                    }
                    </div>
                  </div>
				  {props.hide_edit && <div className="row">
				    <div className="col">
					  <GhinTable
					    idKey={'id'}
					    hideSelect={true}
					    sortable={false}
					    hidePagination={false}
					    columns={club_group_summary_column}
					    data={clubs}
                        loading={clubs_loading}
                        pages={clubsPages}
                        page={clubsPage - 1}
                        manual
                        pageSize={clubsPageSize}
                        onPageChange={(index) => {
                          set_clubsPage(index + 1)
                        }}
                        onPageSizeChange={(pageSize, pageIndex) => {
                          set_clubsPageSize(pageSize)
                          set_clubsPage(pageIndex + 1)
                        }}
					  />
				    </div>
				  </div>}
				  {!props.hide_edit && <div className="row">
					<div className="col">
					  <GhinTable
						idKey={'id'}
						hideSelect={true}
						sortable={false}
						hidePagination={false}
						columns={club_group_column}
						data={clubs}
						loading={clubs_loading}
                        pages={clubsPages}
                        page={clubsPage - 1}
                        manual
                        pageSize={clubsPageSize}
                        onPageChange={(index) => {
                          set_clubsPage(index + 1)
                        }}
                        onPageSizeChange={(pageSize, pageIndex) => {
                          set_clubsPageSize(pageSize)
                          set_clubsPage(pageIndex + 1)
                        }}
					  />
					</div>
				  </div>}
                  <hr style={{ border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px' }}></hr>
                  <div className="row">
                    <div className="col">
                      <span><strong>Join Page Customization</strong></span>
                    </div>
                    {!props.hide_edit && props.canEditAssociation && <div className="col auto push-right">
                      <button
                        onClick={() => set_editCustomizationOpen(true)}
                        className="btn lnk"
                      >
                        Edit
                      </button>
                    </div>}
                  </div>
                  <div className="row">
                    <div className="col">
                      <span>To preview your join page setup, click the button below. {!props.hide_edit && <span>To edit, press the <strong>Edit</strong> button.</span>} </span>
                    </div>
                  </div>
                  <div className="row centered-items hr-margin-24-top hr-margin-24-bottom">
                    <div className="col is-1-of-3 auto">
                      <button
                        className="btn fill"
                        onClick={() => {
                          setPreviewOpen(true);
                        }}
                      >
                        CLICK HERE TO PREVIEW
                      </button>
                    </div>
                  </div>
                  <hr style={{ border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px' }}></hr>
                  <div className="row">
                    <div className="col is-1-of-2">
                      <span><strong>Add-Ons </strong>(OPTIONAL)</span>
                    </div>
                    {!props.hide_edit && props.canEditAssociation && <div className="col auto push-right">
                      <button
                        className="btn lnk"
                        onClick={() => set_selectAddonsModalOpen(true)}
                      >
                        Edit
                      </button>
                    </div>}
                  </div>
                  <div className="row">
                    <div className="col">
                      <span>The following Add-On's will be included with this Join & Renew Page. {!props.hide_edit && props.canEditAssociation && <Fragment>To create an Add-On Charge click <NavLink to={`add-ons`}>here</NavLink>.</Fragment>}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <GhinTable
                        idKey={'id'}
                        hideSelect={true}
                        sortable={false}
                        hidePagination={true}
                        columns={columns}
                        data={addons}
                        loading={addons_loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>

      <GenericModal
        isOpen={selectMembershipsModalOpen}
        child={EditSelectedMembershipsForm}
        icon={"edit"}
        title={"Edit Selected Memberships"}
        close_modal={() => { set_selectMembershipsModalOpen(false) }}
        reload_selected_clubs={load_clubs}
        join_page={props.data}
        reload_pages={props.reloadPages}
        className="width-860px"
      />

      <GenericModal
        isOpen={selectAddonsModalOpen}
        child={EditSelectedAddonsForm}
        icon={"edit"}
        title={"Edit Add-Ons"}
        close_modal={() => { set_selectAddonsModalOpen(false) }}
        selected_addons={addons}
        reload_selected_addons={reload_addons}
        join_page={props.data}
      />

      <GenericModal
        isOpen={editJoinPageNameOpen}
        icon={"edit"}
        child={EditJoinPageNameForm}
        updateJoinPageTableData={props.updateJoinPageTableData}
        title={"Edit Join Page Name"}
        close_modal={() => { set_editJoinPageNameOpen(false) }}
        join_page={props.data}
        name={name}
        set_name={set_name}
      />
      
      <GenericModal
        isOpen={previewOpen}
        className="semi-extra-narrow"
        icon={"edit"}
        child={previewContent}
        title={"Preview Join Page "}
        close_modal={() => { setPreviewOpen(false) }}
        url={`${props.data.url}/preview`}
      />

      <GenericModal
        isOpen={editCustomizationOpen}
        icon={"edit"}
        child={EditJoinPageCustomizationForm}
        title={"Edit Join Page"}
        close_modal={() => { set_editCustomizationOpen(false) }}
        join_page={props.data}
      />
    </Fragment>
  )
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ addAlert }, dispatch);
  return {
    dispatch,
    ...actions
  };
}

export default connect(null, mapDispatchToProps)(JoinPagesDetails);
