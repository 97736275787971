import React, {Component, Fragment} from 'react';
import AssociationService from "../../services/api/association";
import renderHTML from 'react-render-html';
import Modal from "react-modal";

export default class PreviewEmailModal extends Component {

	constructor(props){
		super(props);
		this.state = {
			preview: null
		}
	}

	componentDidMount() {
		if (this.props.campaignId) {
			AssociationService.previewSentEmail(this.props.associationId, this.props.campaignId)
				.then(res => {
					this.setState({preview: res})
				})
				.catch(err => {
					if(err.data && err.data.Error && err.data.Error){
						this.setState({preview: `<p className={"is-error"}/>${err.data.Error}</p>`});
					}
				});
		} else {
			AssociationService.previewEmail(this.props.associationId, this.props.golferId)
				.then(res=>{
					this.setState({preview: res})
				})
				.catch(err => {
					if(err.data && err.data.Error && err.data.Error){
						this.setState({preview: `<p className={"is-error"}/>${err.data.Error}</p>`});
					}
				});
		}

	}

	render() {
		return (
			<Modal
                isOpen={true}
                onRequestClose={() => {
                    this.props.closeModal()
                }}
                contentLabel="Modal"
                portalClassName="modal"
                overlayClassName="modal__overlay"
                className="modal__content"
                bodyOpenClassName="modal--is-open"
                htmlOpenClassName="prevent-scroll"
                shouldCloseOnOverlayClick={true}
                shouldFocusAfterRender={false}
            >
				<Fragment>

					<div className="modal__container wide">

						<div className="modal__head">

							<div className="modal__icon"><i className="material-icons-outlined">remove_red_eye</i></div>
							<h2 className="modal__title">Preview Email</h2>
							<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

						</div>

						<div className="modal__body">

							{this.state.preview && renderHTML(this.state.preview)}

						</div>

					</div>

				</Fragment>
			</Modal>
		);
	}
}
