import ClubService from "./club";
import request from "../shared/request";
import requestPreview from "../shared/requestPreview";

export default class AssociationService {
   	static getAssociation(id) {
       return ClubService.getGolfAssociations().then((data) => {
           return data.associations.find((item) => item.id === parseInt(id, 10)) || Promise.reject("Not found");
       })
   	}

	static getPrimary(id) {
		return request({
			url: `/golf_associations/${id}.json`,
			method: 'GET'
		})
	}

	static editPrimary(id, data) {

		let bodyFormData = new FormData();
		Object.keys(data).forEach((key) => {
			bodyFormData.append('association['+key+']', data[key]);
		});

		return request({
			url: `/golf_associations/${id}.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static editAddress(id, data) {
		return request({
			url: `/golf_associations/${id}.json`,
			method: 'PATCH',
			data: data
		})
	}

	static editCampaignSettings(assocId,data){
		return request({
			url: `/golf_associations/${assocId}/edit_campaign_settings.json`,
			method: 'PATCH',
			data: data
		})
	}

	static createAssociation(data) {

   		return request({
			url: `golf_associations.json`,
			method: 'POST',
			data: data
		})
	}


	static createEmailContent(association, data) {
   		return request({
			url: `/associations/${association}/email_contents.json`,
			method: 'POST',
			data: data
		})
	}


	static deleteEmailContent(association, data) {
   		return request({
            url: `/associations/${association}/email_contents/${data.id}.json`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
	}

	static updateEmailContent(association, data) {
        let bodyFormData = new FormData();
        Object.keys(data).forEach((key) => {
        	bodyFormData.append(key, data[key])
		});
   		return request({
			url: `/associations/${association}/email_contents/${data.id}.json`,
			method: 'PATCH',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: bodyFormData
		})
	}

  static updateBlocksOrder(association, data) {
    return request({
      url: `/associations/${association}/email_contents/update_blocks_order.json`,
      method: 'PATCH',
      data: {
        content_blocks_ids: data
      }
    })
  }

    static uploadEmailFile(association, id, data) {

        let bodyFormData = new FormData();
        bodyFormData.append('image', data);

        return request({
            url: `/associations/${association}/email_contents/${id}.json`,
            method: 'PATCH',
			headers: {
            	'Content-Type': 'multipart/form-data'
			},
            data: bodyFormData
        })
    }

	static getEmailContents(association) {
   		return request({
			url: `/associations/${association}/email_contents.json`,
			method: "GET"
		})
	}
	static getAllClubs(association, filters) {
   		return request({
			url: `/associations/${association}/clubs/get_all.json`,
			method: 'GET',
			params: filters
		})
	}

	static getClubs(association, params) {
   		return request({
			url: `/associations/${association}/clubs.json`,
			params,
			method: "GET"
		})
	}

	static createClubGroup(associationId, name) {
		return request({
			url: `/associations/${associationId}/club_groups.json?name=${name}`,
			method: 'POST'
		})
	}

	static updateClubGroup(associationId, clubId, data) {
   		return request({
			url: `/associations/${associationId}/club_groups/${clubId}.json`,
			method: 'PATCH',
			data
		})
	}

  static getClubsCsv(association, params) {
      return request({
          url: `/associations/${association}/clubs/export_to_csv.json`,
          params,
          method: "GET"
      })
  }

  static getSpecialUpdateCsv(association,url) {
	  return request({
		  url: `/associations/${association}/get_csv.json`,
		  params: {url: url},
		  method: 'GET'
	  })
  }

  static getSeasons(assocId){
    return request({
        url: `/CrsAssociations/${assocId}.json`,
        method: "GET"
    })
  }
	static getGolferProducts(association) {
		return request({
			url: `/associations/${association}/association_settings.json`,
			method: "GET"
		})
	}

	static editGolferProducts(assocId, settingsId, data) {
		return request({
			url: `/associations/${assocId}/association_settings/${settingsId}.json`,
			method: "PATCH",
			data: {association_settings: data}
		})
	}

	static editImageGolferProducts(assocId, settingsId, data) {

		let bodyFormData = new FormData();
		Object.keys(data).forEach((key) => {
			bodyFormData.append(key, data[key]);
		});

		return request({
			url: `/associations/${assocId}/association_settings/${settingsId}.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static getKioskImages(assocId){
		return request({
			url: `/associations/${assocId}/kiosk_images.json`,
			method: 'GET'
		})
	}

	static addKioskImage(assocId) {
		return request({
			url: `/associations/${assocId}/kiosk_images.json`,
			method: 'POST'
		})
	}

	static uploadKioskImage(assocId, id, data) {

		let bodyFormData = new FormData();
		bodyFormData.append('kiosk_image[image]', data);
		return request({
			url: `/associations/${assocId}/kiosk_images/${id}.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static editKioskImage(assocId, id, data) {
		return request({
			url: `/associations/${assocId}/kiosk_images/${id}.json`,
			method: 'PATCH',
			data: data

		})
	}

	static removeKioskImage(assocId, id) {
		return request({
			url: `/associations/${assocId}/kiosk_images/${id}.json?delete_image=true`,
			method: 'PATCH'
		})
	}

	static deleteKioskImage(assocId, id){
		return request({
			url: `/associations/${assocId}/kiosk_images/${id}.json`,
			method: 'DELETE'
		})
	}

	static getClubGroups(assocId, params = {}) {
   		return request({
			url: `/associations/${assocId}/club_groups.json`,
			method: 'GET',
      params: params
		})
	}

	static getEmailSettings(assocId) {
		return request({
			url: `/associations/${assocId}/email_settings.json`,
			method: 'GET'
		})
	}

	static uploadEmailLogo(assocId, emailId, data) {

		let bodyFormData = new FormData();
		bodyFormData.append('logo', data.logo);
		return request({
			url: `/associations/${assocId}/email_settings/${emailId}.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static deleteEmailLogo(assocId, emailId) {
		return request({
			url: `/associations/${assocId}/email_settings/${emailId}.json?delete_logo=true`,
			method: 'PATCH'
		})
	}


	static editEmailSettings(assocId, emailId, data) {
		return request({
			url: `/associations/${assocId}/email_settings/${emailId}.json`,
			method: 'PATCH',
			data
		})
	}

	static sendTestEmail(assocId, ghin, email) {
		return request({
			url: `/associations/${assocId}/send_erevision_email.json?ghin_number=${ghin}&email=${email}`
		})
	}

	static previewEmail(assocId, golferId) {
		return requestPreview({
			url: `/associations/${assocId}/preview_email?golfer_id=${golferId}`,
			method: 'GET'
		})
	}

	static previewSentEmail(assocId, campaignId) {
		return requestPreview({
			url: `/associations/${assocId}/campaigns/${campaignId}/preview_sent_newsletter.json`,
			method: 'GET'
		})
	}

	static getMembershipTypes(assocId, params = {}) {
		return request({
			url: `/associations/${assocId}/membership_types.json`,
			method: 'GET',
      params: params
		})
	}

	static createMembershipType(assocId, data) {
		return request({
			url: `/associations/${assocId}/membership_types.json`,
			method: 'POST',
			data
		})
	}

	static editMembershipType(assocId, id, data) {
		return request({
			url: `/associations/${assocId}/membership_types/${id}.json`,
			method: 'PATCH',
			data
		})
	}

	static deleteMembershipType(assocId, id) {
		return request({
			url: `/associations/${assocId}/membership_types/${id}.json`,
			method: 'DELETE'
		})
	}

	static getArchivedMembershipTypes(assocId, id) {
		return request({
			url: `/associations/${assocId}/membership_types/${id}/edit_logs.json`,
			method: 'GET'
		})
	}

	static getClubBillingReportLogs(assocId){
		return request({
			url: `/associations/${assocId}/billing_report/logs.json`,
			method: 'GET'
		})
	}

	static getClubBillingReportRun(assocId, type, filters, advancedSettings) {
		return request({
			url: `/associations/${assocId}/billing_report/run.json`,
			method: 'GET',
			params: type === "initial" ?
				{
					report_type: type,
					initial_billing_revision_date: filters.initial,
          ...advancedSettings
				} :
				{
					report_type: type,
					initial_billing_revision_date: filters.initial,
					last_billing_revision_dates: filters.last.join(","),
					current_billing_revision_date: filters.current,
          ...advancedSettings
				}

		})
	}
	static downloadBillingReport(assocId,id) {
		return request({
			url: `/associations/${assocId}/billing_report/${id}/get_xlsx.json?only_link=true`,
			method: 'GET'
		})
	}

	static subscribeUser(assocId, golferId, note) {
		return request({
			url: `/associations/${assocId}/subscribe_golfer.json?golfer_id=${golferId}&note=${note}`,
			method: 'GET'
		})
	}

	static unsubscribeUser(assocId, golferId, note) {
		return request({
			url: `/associations/${assocId}/unsubscribe_golfer.json?golfer_id=${golferId}&note=${note}`,
			method: 'GET'
		})
	}

	static digitalProfilesInvitation(assocId) {
		return request({
			url: `/associations/${assocId}/send_invitation_to_create_digital_profile.json`,
			method: 'POST'
		})
	}

	static getCampaigns(assocId, params) {
		return request({
			url: `/golf_associations/${assocId}/campaigns.json`,
      params,
      method: 'GET',
		})
	}

	static getCampaignDetails(assocId, campaignId, params) {
		return request({
			url: `/golf_associations/${assocId}/campaigns/${campaignId}/campaign_emails.json`,
			params,
			method: "GET"
		})
	}
	static exportCampaigns(assocId) {
		return request({
			url: `/golf_associations/${assocId}/export_campaigns.json?only_link=true`,
			method: "GET"
		})
	}

  static exportCampaign(assocId, campaignId) {
   return request({
     url: `/golf_associations/${assocId}/campaigns/${campaignId}/export.json?only_link=true`,
     method: "GET"
   })
  }

	static getLogs(assocId, params) {
		return request({
			url: `/associations/${assocId}/logs.json`,
			method: 'GET',
			params
		});
	}

  static getDigitalProfileEmails(page, per_page, association_id, params) {
    return request({
      url: `/digital_profile_emails.json?page=${page}&per_page=${per_page}&association_id=${association_id}`,
			method: 'GET',
      params
    })
  }

  static getPushNotification(page, per_page, association_id, params) {
    return request({
      url: `/associations/${association_id}/push_notification_settings.json?page=${page}&per_page=${per_page}`,
      method: 'GET',
      params
    })
  }

  static exportNotificationHistory(association_id, data) {
    return request({
      url: `/associations/${association_id}/push_notification_settings/export.json?only_link=true`,
      method: "POST", data
    })
  }

  static createPushNotificationSetting(association_id, data) {
    return request({
      url: `/associations/${association_id}/push_notification_settings.json`,
      method: "POST",
      data: AssociationService.dataToFormData(data)
    })
  }

  static deletePushNotification(association_id, id){
    return request({
      url: `/associations/${association_id}/push_notification_settings/${id}.json`,
      method: 'DELETE'
    })
  }

  static editPushNotification(association_id, id, data) {
    return request({
      url: `/associations/${association_id}/push_notification_settings/${id}.json`,
      method: 'PATCH',
      data: AssociationService.dataToFormData(data)
    })
  }

  static getPendingPushNotification(page, per_page, params) {
    return request({
      url: `/pending_push_notification_settings.json?page=${page}&per_page=${per_page}`,
      method: 'GET',
      params
    })
  }

  static approvePendingPushNotification(data) {
    return request({
      url: `/push_notification_settings/approve.json`,
      method: "POST",
      data
    })
  }

  static reviewPushNotification(association_id, id, data) {
    return request({
      url: `associations/${association_id}/push_notification_settings/${id}/review.json`,
      method: "POST",
      data
    })
  }

  static getAssociationClubMembershipFees(associationId, params) {
    return request({
      url: `/associations/${associationId}/club_memberships_fees.json`,
      method: 'GET',
      params
    })
  }

  static dataToFormData(data) {
    let bodyFormData = new FormData();

    Object.keys(data).forEach((key) => {
      if (typeof(data[key]) === 'object' && key !== 'image'){
        if (Array.isArray(data[key])) {
          data[key].forEach((value) => {
            bodyFormData.append(`${key}[]`, value);
          });
        }
        else {
          for (var hashKey in data[key]) {
            bodyFormData.append(`${key}[${hashKey}]`, data[key][hashKey]);
          }
        }
      }
      else bodyFormData.append(key, data[key]);
    });

    return bodyFormData;
  }

	static getHubspotMappings(association_id) {
		return request({
      url: `associations/${association_id}/hubspot_mappings.json`,
      method: "GET"
    })
	}

	static updateHubspotMappings(association_id, data) {
		return request({
      url: `associations/${association_id}/hubspot_mappings.json`,
      method: "PATCH",
			data
    })
	}

	static getHubspotSettings(association_id) {
		return request({
      url: `associations/${association_id}/hubspot_settings.json`,
      method: "GET"
    })
	}

	static updateHubspotSettings(association_id, data) {
		return request({
      url: `associations/${association_id}/hubspot_settings.json`,
      method: "POST",
			data
    })
	}

	static updateHubspotEnabledData(association_id, data) {
		return request({
      url: `associations/${association_id}/hubspot_enabled_data.json`,
      method: "POST",
			data
    })
	}

	static enableHubspotIntegration(association_id) {
		return request({
      url: `associations/${association_id}/enable_hubspot_integration.json`,
      method: "POST"
    })
	}

	static requestHubspotTokens(data) {
		return request({
      url: 'associations/request_hubspot_tokens.json',
      method: "POST",
			data
    })
	}

	static exportToHubspot(association_id) {
		return request({
      url: `associations/${association_id}/export_to_hubspot.json`,
      method: "POST"
		})
  }

  static createAgingOutReportActivity(association_id, data) {
		return request({
      url: `associations/${association_id}/aging_out_report_activities.json`,
      method: "POST",
      data: data
		})
  }

  static getAgingOutReportActivities(association_id, params) {
		return request({
      url: `associations/${association_id}/aging_out_report_activities.json`,
      method: "GET",
      params
		})
  }

  static updateGolfersFromReport(association_id, id) {
		return request({
      url: `associations/${association_id}/aging_out_report_activities/${id}/update.json`,
      method: "PATCH",
		})
  }
}
