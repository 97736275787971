import React, {Component, Fragment} from 'react';
import TableFilter from "./TableFilter";
import ClubService from "../../../services/api/club";
import moment from "moment/moment";
import {NavLink} from 'react-router-dom';
import GhinTable from "../../shared/GhinTable";
import TableService from "../../../services/tableService";
const QS = require("qs");

export default class ClubSearch extends  Component {

    constructor(props) {
        super(props);
        const query = QS.parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });
        this.state = {
            tableFilterIsHidden: false,
            associations: [],
            filtered: [
              {id: "club_name", value: query.q || ''},
              {id: "is_test", value: false}
            ],
            sorted: [{id: "club_name", desc: false}],
            searchText: query.q || '',
            isAllSelected: false,
            clubs: [],
            page: 0,
            per_page: 25,
            isExportDataOpened: false,
            selectedClubs: [],
            tableInternalData: [] //used to get filtered array from the table
        };
    }

    componentDidMount() {
        this.loadClubList();
    }

    getClubRequestParams() {
        let params = {
            page: (this.state.page) + 1,
            per_page: this.state.per_page,
            global_search: true
        };

        if (this.state.sorted.length > 0) {
            params['sorting_criteria'] = this.state.sorted[0].id;
            params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
        }

        params = {
            ...params,
            ...TableService.filtersArrayToParams(this.state.filtered)
        }
        return params;
    }

    loadClubList() {
        this.setState({
            loading: true
        });
        const params = this.getClubRequestParams();

        ClubService.getClubs(params).then((data) => {
            this.setState({
                loading: false,
                isAllSelected: false,
                clubs: data.clubs.map((i) => {
                    return {
                        ...i,
                        date: moment(i.date).format("MM/DD/YYYY")
                    }
                }),
                total: parseInt(data['total-count'], 10),
                per_page: parseInt(data['per-page'], 10),
                pages: parseInt(data['total-pages'], 10),
                inactive: parseInt(data['total-count'], 10) - parseInt(data.meta['active_clubs_count'], 10),
                active: parseInt(data.meta['active_clubs_count'], 10),

            })
        });
    }
    updateFilter(filtered) {
        this.setState({
            filtered,
            page: 0
        }, () => {
            this.loadClubList();
        })

    }

    render() {
        const columns = [
            {
                accessor: 'id',
                show: false,
                colClassName: 'col_club-name'
            },
            {
              accessor: 'club_category',
              show: false,
            },
            {
              accessor: 'technology_provider',
              show: false
            },
            {
                accessor: 'golf_association_id',
                show: false
            },
            {
                Header: 'Club Name',
                accessor: 'club_name',
                filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1,
                Cell: props =>
					props.row._original.entitlement ?
						<Fragment>
							<NavLink to={`/manage/association/${props.row.golf_association_id}/club/${props.row.id}`}>{props.value}</NavLink>
						</Fragment>
						:
						<span>{props.value}</span>
					,
                colClassName: 'col_club-name'
            },
            {
                Header: 'Status',
                accessor: 'status',
                colClassName: 'col_status'
            },
            {
                Header: 'City',
                accessor: 'city',
                colClassName: 'col_city'
            },
            {
                Header: 'State',
                accessor: 'state',
                colClassName: 'col_city'
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                colClassName: 'col_phone-number'
            },
            {
                Header: 'Email',
                accessor: 'email',
                colClassName: 'col_email',
				Cell: row => <Fragment>
					<a href={`mailto: ${row.value}`}>{row.value}</a>
				</Fragment>
            },
            {
                Header: 'Website',
                accessor: 'website',
                colClassName: 'col_website',
                Cell: row => <Fragment>
                    <a href={`//${row.value}`}>{row.value}</a>
                  </Fragment>
            }];

        return (
            <section className="default">

                <TableFilter updateFiltered={this.updateFilter.bind(this)} searchText={this.state.searchText}/>

                <h2 className="section__title large">Club Results ({this.state.total ? this.state.total : 0})</h2>

                <GhinTable
                    loading={this.state.loading}
                    columns={columns}
                    pages={this.state.pages}
					          page={this.state.page}
                    total={this.state.total}
                    manual
                    pageSize={this.state.per_page}
                    data={this.state.clubs}
                    ref={(r) => this.table = r}
                    onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.loadClubList())}
                    onPageSizeChange={(pageSize, pageIndex) => this.setState({
                        per_page: pageSize,
                        page: pageIndex
                    }, () => this.loadClubList())}
                    sorted={this.state.sorted}
                    onSortedChange={(newSorted, column, shiftKey) => {
                        this.setState({
                            sorted: newSorted,
							page: 0
                        }, () => {
                            this.loadClubList()
                        })
                    }}
                    hideSelect={true}
                />


            </section>
        )
    }
}
