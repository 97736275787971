import React, {Component, Fragment} from 'react';
import EditKioskImageInformationForm from '../Forms/EditKioskImageInformationForm';

export default class EditKioskImageInformationModal extends Component {

	render() {
		return (
          <Fragment>
              <div className="modal__container narrow">
                  <div className="modal__head">
                      <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                      <h2 className="modal__title">Edit Image Information</h2>
                      <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
                  </div>
                  <div className="modal__body">
			                  <EditKioskImageInformationForm errors={this.props.errors} image={this.props.image} tag={this.props.tag} closeModal={(data) => {this.props.closeModal(data)}}/>
                  </div>
              </div>
          </Fragment>
		);
	}
}
