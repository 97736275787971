import { Fragment, useCallback, useEffect, useState } from "react";
import EditContactSettingsReasonModal from "../../Modals/EditContactSettingsReasonModal";
import GolferService from "../../../services/api/golfer";
import GhinTable from "../../shared/GhinTable";
import Modal from "react-modal"
import { addAlert } from '../../shared/actions'
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";

function ContactSettings(props) {
  const get_decline_from_props = useCallback(() => {
    if (props.decline_contact_settings) {
      const contact_settings = props.decline_contact_settings
      const association_contact_settings = contact_settings.filter(s => s.organization_type === 'GolfAssociation')
      const club_contact_settings = contact_settings.filter(s => s.organization_type === 'Club')
      set_association_contact_settings(association_contact_settings)
      set_club_contact_settings(club_contact_settings)
    }
  }, [props.decline_contact_settings])

  const getContactSettings = () => {
    set_contact_settings_loading(true)
    GolferService.getContactSettings(props.golfer.id).then(res => {
      const contact_settings = res.decline_contact_settings
      const association_contact_settings = contact_settings.filter(s => s.organization_type === 'GolfAssociation')
      const club_contact_settings = contact_settings.filter(s => s.organization_type === 'Club')
      set_association_contact_settings(association_contact_settings)
      set_contact_settings_loading(false)
      set_club_contact_settings(club_contact_settings)

    }).catch(err => {
      set_association_contact_settings([])
      set_contact_settings_loading(false)
      set_club_contact_settings([])
    })
  }
  const is_disabled = (type, data) => {
    if (props.user_type === 'club-read-only' || props.user_type === 'association-read-only') return true
    if (props.golfer.status === 'Archived' || props.golfer.deceased) return true
    if (props.user_type === 'super-admin') return false
    if (props.user_type === 'club-admin' && type === 'GolfAssociation') return true
    if (props.associationId !== `${data.association_id}`) return true
    return false
  }

  const toggleDoNotContact = (data) => {
    var organization_id, organization_type
    if (data.organization_type === 'Club') {
      organization_type = 'Club'
      organization_id = data.club_id
    }
    else {
      organization_type = 'GolfAssociation'
      organization_id = data.association_id
    }
    const parameters = {
      "contact_organization_type": organization_type,
      "contact_organization_id": organization_id,
    }
    set_contact_settings_loading(true)
    if (data.declined) {
      GolferService.deleteContactSettings(props.golfer.id, parameters).then(() => {
        getContactSettings()
        props.addAlert({ type: 'success', message: 'Do Not Contact setting has been successfully edited' })
      }).catch((e) => {
        const error = typeof e.data.error === typeof 'string' ? e.data.error : e.data.errors[Object.keys(e.data.errors)[0]]
        props.addAlert({ type: 'error', message: error })
        getContactSettings()
      })
    }
    else {
      parameters['reason'] = ''
      data.declined = !data.declined
      GolferService.postContactSettings(props.golfer.id, parameters).then(() => {
        getContactSettings()
        props.addAlert({ type: 'success', message: 'Do Not Contact setting has been successfully edited' })
        set_selected_settings(data)
        set_edit_reason_modal_is_open(true)
      }).catch(e => {
        const error = typeof e.data.error === typeof 'string' ? e.data.error : e.data.errors[Object.keys(e.data.errors)[0]]
        props.addAlert({ type: 'error', message: error })
        getContactSettings()
      })
    }
  }

  const [association_contact_settings, set_association_contact_settings] = useState([])
  const [club_contact_settings, set_club_contact_settings] = useState([])
  const [contact_settings_loading, set_contact_settings_loading] = useState(false)
  const [selected_settings, set_selected_settings] = useState({})
  const [edit_reason_modal_is_open, set_edit_reason_modal_is_open] = useState(false)

  useEffect(() => {
    get_decline_from_props()
  }, [get_decline_from_props])

  const association_contact_settings_collumns = [
    {
      Header: 'Association',
      Cell: props => {
        return (
          <div>
            <p>{props.original.golf_association_name}</p>
          </div>
        )
      }
    },
    {
      Header: 'Do Not Contact',
      colClassName: 'col_type',
      Cell: props => {
        return (
          <Fragment>
            <input type="checkbox"
              disabled={is_disabled('GolfAssociation', props.original)}
              defaultChecked={props.original.declined}
            />
            <label
              style={{ margin: 'auto', "height": "40px" }}
              onClick={(e) => toggleDoNotContact(props.original)}
            ></label>
          </Fragment>
        )
      }
    },
    {
      Header: 'Reason',
      accessor: 'reason'
    },
    {
      colClassName: 'col_type',
      Cell: props => {
        if (!props.original.declined || is_disabled('GolfAssociation', props.original)) {
          return (<p style={{ "height": "40px" }}></p>)
        }
        if (props.original.reason === "") {
          return (<button onClick={() => {
            set_selected_settings(props.original)
            set_edit_reason_modal_is_open(true)
          }
          }
            className="btn fill green"
            style={{ "height": "40px" }}
          >Add Reason</button>
          )
        }
        else {
          return (
            <div style={{ 'textAlign': 'center' }}>
              <button className="btn lnk" onClick={() => {
                set_selected_settings(props.original)
                set_edit_reason_modal_is_open(true)
              }}>Edit Reason</button>
            </div>
          )
        }
      }
    }
  ]

  const club_contact_settings_collumns = [
    {
      Header: 'Club',
      Cell: props => <Fragment>
        <p className='blue'>{props.original.club_name} <br />
          <span className='gray italic'>{props.original.golf_association_name}</span></p>
      </Fragment>
    },
    {
      Header: 'Do Not Contact',
      colClassName: 'col_type',
      Cell: props => {
        return (
          <Fragment>
            <input type="checkbox"
              disabled={is_disabled('Club', props.original)}
              defaultChecked={props.original.declined}
            />
            <label
              style={{ margin: 'auto' }}
              onClick={(e) => toggleDoNotContact(props.original)}
            ></label>
          </Fragment>
        )
      }
    },
    {
      Header: 'Reason',
      accessor: 'reason'
    },
    {
      colClassName: 'col_type',
      Cell: props => {
        if (!props.original.declined || is_disabled('Club', props.original)) {
          return (<p style={{ "height": "40px" }}></p>)
        }
        if (props.original.reason === "") {
          return (<button onClick={() => {
            set_selected_settings(props.original)
            set_edit_reason_modal_is_open(true)
          }
          }
            className="btn fill green"
          >Add Reason</button>
          )
        }
        else {
          return (
            <div style={{ 'textAlign': 'center' }}>
              <button className="btn lnk" onClick={() => {
                set_selected_settings(props.original)
                set_edit_reason_modal_is_open(true)
              }}>Edit Reason</button>
            </div>
          )
        }
      }
    }
  ]

  return (
    <Fragment>
      <div class="row">
        <div className="col">
          <span><strong>Note:</strong> These settings are for association and club internal use only. They will not affect Newsletters or Push Notification settings in any way.</span>
        </div>
      </div>
      <div style={{ 'paddingTop': '8px' }}>
        <GhinTable
          loading={contact_settings_loading}
          columns={association_contact_settings_collumns}
          hideSelect
          hidePagination={true}
          data={association_contact_settings}
          sortable={false}
        />
      </div>

      <div style={{ 'paddingTop': '16px' }}>
        <GhinTable
          loading={contact_settings_loading}
          columns={club_contact_settings_collumns}
          hideSelect
          hidePagination={true}
          data={club_contact_settings}
          sortable={false}
        />
      </div>

      <Modal
        isOpen={edit_reason_modal_is_open}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <EditContactSettingsReasonModal
          selected_settings={selected_settings}
          reload_contact_settings={() => { getContactSettings() }}
          close_modal={() => { set_edit_reason_modal_is_open(false) }}
        />
      </Modal>
    </Fragment>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(null, mapDispatchToProps)(ContactSettings);
