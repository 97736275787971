import React, {Component, Fragment} from 'react';

import EditGolferStatusForm from '../Forms/EditGolferStatusForm';
import SuccessModal from "./SuccessModal";

export default class EditGolferStatusModal extends Component {

	constructor(props){
		super(props);
		this.state = {
			success: false,
			now: false
		}
	}
	render() {
		return (
			<Fragment>

				<div className="modal__container narrow">

					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
						<h2 className="modal__title">Edit Golfer Status</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">
						{this.state.success ?
							<SuccessModal
								openModal={this.state.success}
								onMainAction={() => {
									this.props.closeModal('refresh')
								}}
								message={
									<Fragment>Your request is currently being processed. You may need to refresh your screen to see changes.</Fragment>
								}
								closeModal={() => {
									this.props.closeModal(this.state.success)
								}}
								golfer={this.state.golfer}
								mainLabel={"Ok"}
							/>
							:
              <EditGolferStatusForm golfer={this.props.golfer} club={this.props.club} close={(data) => { if (data) { this.setState({ success: data }) } else { this.props.closeModal() } }} />}

					</div>

				</div>

			</Fragment>
		);
	}
}
