import React, {Component, Fragment} from 'react';

import AddNewAssociationForm from '../Forms/AddNewAssociationForm';
import SuccessModal from './SuccessModal';

export default class AddNewAssociation extends Component {

  constructor(){
		super();
		this.state = {
			association: null,
		}
	}

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
                        <h2 className="modal__title">Add New Association</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                      {!this.state.association ?
                        <AddNewAssociationForm onSuccess={(data)=> {this.setState({association: data})}} closeModal={this.props.closeModal}/>
                      :
                        <SuccessModal
                        openModal={this.state.association}
                        onMainAction={() => {
                          this.props.closeModal({type:'association', association: this.state.association});
                        }}
                        onSecundaryAction={() => {
                          this.setState({association: null});
                        }}
                        closeModal={() => {
                          this.props.setRefreshList(true);
                          this.props.closeModal()
                        }}
                        association={this.state.association}
                        mainLabel={"Continue to Setup"}
                        secundaryLabel={"Add Another Association"}
                      />
                      }


                    </div>

                </div>

            </Fragment>
		);
	}
}
