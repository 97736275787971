import React, {Component, Fragment} from 'react';

import {getFormValues, Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {InputField} from "../../../inputs/InputField";
import {required} from "../../../inputs/validations";
import {bindActionCreators} from "redux";
import SharedService from "../../../services/api/shared";
import GhinTable from "../../shared/GhinTable";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";
import GuardianService from "../../../services/api/guardian";
import {processErrorMessage} from "../../../services/shared/errorHelper";


const ComputeIndexKeyForGuardians  = function (guardians) {
	return guardians.map((guardian) => ({
		...guardian,
		gKey: `${guardian.id}-${guardian.golfer_id}`
	}))
};

class SearchGuardianForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			countries: [],
			states: [],
			golfers: [],
			sorted: [{id: "first_name", desc:false}],
			page: 1,
			pages: 0,
			per_page: 10,
			selected: null,
			loading: true,
		}
	}

	componentDidMount() {
		SharedService.getCountriesAndStates()
			.then((response) => {
				this.setState({
					countries: response.countries.map((item) => {
						return {
							...item,
							value: item.code,
							label: item.name,
							states: item.states.map((state) => {
								return {
									...state,
									value: state.code,
									label: state.name.includes('Armed ') ? state.code : state.name
								}
							})
						}
					})
				})
			})
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onCountryChange(country) {
		this.props.change('state',{label: 'Select State', value: null});
		this.setState({
			states: country.states
		})
	}

	onSubmit(values) {
		let params = [];

		params['sorting_criteria'] = 'full_name';
		params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		params['per_page'] = this.state.per_page;
		params['page'] = this.state.page;
		params['unique_guardians'] = true;
		params['global_search'] = false;
		params['search'] = values.ghin_or_email;

		GuardianService
			.getGuardians(params)
			.then((res) => {
				if (res.guardians && res.guardians.length === 1) {
					this.props.onGuardianSelect(res.guardians[0]);
				} else if(res.guardians.length === 0) {
					this.setState({
						error: "The information entered is invalid. This could be because there are no records that match the criteria or the corresponding record belongs to a minor."
					})
				} else {
					this.setState({
						values,
						golfers: ComputeIndexKeyForGuardians(res.guardians),
						total: parseInt(res['total-count'], 10),
						per_page: parseInt(res['per-page'], 10),
						pages: parseInt(res['total-pages'], 10),
						loading: false,
					});
				}

			}).catch(error => {

			let errorMessage = processErrorMessage(error.data.errors);
			this.setState({
				error: errorMessage
			});
		});
	}

	getPage() {
		this.setState({
			loading: true
		});
		let params = [];

		params['global_search'] = false;
		params['sorting_criteria'] = 'full_name';
		params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		params['per_page'] = this.state.per_page;
		params['page'] = this.state.page;
		params['search'] = this.state.values.ghin_or_email;
		params['unique_guardians'] = true;

		if(this.state.values.first_name) params['first_name'] = this.state.values.first_name;

		GuardianService.getGuardians(params)
			.then(res => {
				this.setState({
					golfers: ComputeIndexKeyForGuardians(res.guardians),
					total: parseInt(res['total-count'], 10),
					per_page: parseInt(res['per-page'], 10),
					pages: parseInt(res['total-pages'], 10),
					loading: false,
				});
			})
			.catch(error => {

				let errorMessage = processErrorMessage(error.data.errors);
				this.setState({
					error: errorMessage
				});
			});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;

		let columns = [
			{
				Header: 'Name',
				accessor: 'first_name',
				colClassName: 'col_user-name',
				Cell: props => <Fragment>
					{props.original.first_name} {props.original.middle_name} {props.original.last_name}
				</Fragment>
			},
			{
				Header: 'GHIN #',
				accessor: 'golfer_id',
				colClassName: 'col_ghin'
			},
			{
				Header: 'Email Address',
				accessor: 'email'
			},
			{
				Header: 'id',
				show: false,
				accessor: 'id'
			},
			{
				Header: 'gKey',
				show: false,
				accessor: 'gKey'
			}
		];
		return (
			<Fragment >
				{this.state.golfers.length === 0 ?
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit.bind(this))} ref={'form'}>

					<div className="row">
						<div className="col">
							<h3 className="form__title">Name or GHIN Number or Email Address Search</h3>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="ghin_or_email">Name or GHIN Number or Email Address <span>*</span></label>
							<Field component={InputField}
								   disabled={this.props.formValues && (this.props.formValues.last_name || this.props.formValues.first_name)}
								   type="text"
								   validate={(this.props.formValues && (this.props.formValues.last_name || this.props.formValues.first_name)) ? false : [required]}
								   name="ghin_or_email"
								   id="ghin_or_email" />
						</div>
					</div>

					{this.state.error && this.state.golfers.length === 0 && <span className={'validation__message is-error'}>{this.state.error}</span>}


					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} onClick={() => {this.props.resetToSelect()}} className="btn fill gray">Back</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'} disabled={pristine || submitting} className="btn fill blue">Search</button>
						</div>
					</div>

				</form>
				:

				this.state.golfers.length > 1 &&
					<div className="select golfer">
						<div className="row">
							<div className="col">
								<h3 className="form__title">Select Guardian</h3>
								<GhinTable
									idKey={'gKey'}
									hideSlelectAll={true}
									defaultPageSize={10}
									columns={columns}
									loading={this.state.loading}
									data={this.state.golfers}
									ref={(r) => this.table = r}
									pages={this.state.pages}
									manual
									pageSize={this.state.per_page}
									onRowSelect={(row) => {
										let golfers = [...this.state.golfers].map((i) => {
											if (i.selected) { return {...i, selected: false} }
											if (i.gKey === row.gKey) {
												return {
													...i,
													selected: !row.selected
												}
											} else {
												return i;
											}
										});
										this.setState({
											golfers,
											selected: row._original
										})
									}}
									onPageChange={(pageIndex) => this.setState({page: pageIndex+1}, () =>  this.getPage())}
									onPageSizeChange={(pageSize, pageIndex) => this.setState({
										per_page: pageSize,
										page: 1
									}, () => {
										this.getPage()
									})}
									sortable={false}
								/>
							</div>
						</div>

						<div className={"row"}>
							<button disabled={!this.state.selected} onClick={()=> {this.props.onGuardianSelect(this.state.selected)}} className="btn fill blue">Continue</button>
						</div>
					</div>
				}
			</Fragment>
		);
	}
}

SearchGuardianForm = reduxForm({
	form: 'searchGuardianForm',
	destroyOnUnmount: true
})(SearchGuardianForm);

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({}, dispatch);
	return {
		dispatch,
		...actions
	};
}


function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		formValues: getFormValues('searchGuardianForm')(state)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(SearchGuardianForm));
