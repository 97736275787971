import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {addAlert} from "../shared/actions";
import HtmlEditor from "../shared/HtmlEditor";
import {useCallback, useEffect, useState} from "react";
import JoinAndRenewServices from "../../services/api/joinAndRenew";
import {useParams} from "react-router-dom";

function EditClubJoinPageCustomizationForm(props) {
  const {id} = useParams();
  const [data, setData] = useState({
    header_text: "",
    page_description: "",
    club_details: "",
  })
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (props.join_page.id) {
      setLoading(true)

      JoinAndRenewServices.getClubJoinPageCustomization(id, props.join_page.id).then(res => {
        setData({...res.join_page_customization})
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [props.join_page.id])

  const submit = useCallback(() => {
    let key;
    const add_alert = props.addAlert
    const close_modal = props.close_modal

    setSubmitting(true)
    let form_data = new FormData();
    for (key in data) {
      form_data.append(key, data[key]);
    }

    JoinAndRenewServices.updateClubJoinPageCustomization(id, props.join_page.id, form_data
    ).then(() => {
      add_alert({
        type: 'success',
        message: 'Join Page successfully updated'
      })
      close_modal()
    }).catch((e) => {
      setSubmitting(false)
      let error;
      try {
        key = Object.keys(e.data.errors)[0]
        error = e.data.errors[key][0]
      } catch {
        error = "An error has occurred"
      }
      props.addAlert({
        type: 'error',
        message: error
      })
      console.log(e)
    })
  }, [data, props.join_page, props.addAlert, props.close_modal])


  if (loading)
    return (
      <td colSpan={9} style={{ padding: "10px" }}>
        <div className="row centered-items">
          <div className="col auto">
            <div className={'loader'}>
              <span className="data-table__loader"></span>
            </div>
          </div>
        </div>
      </td>
    )

  return (
    <div>
      <div className="row">
        <div className="col">
          <p>Add custom content that will display on the club join page.</p>
          <br/>
          <strong><span className="underline">Header Text</span></strong>
          <p>If you would like to display a header message on the club join page, please add it below.</p>
        </div>
      </div>
      <div className="row hr-margin-24-bottom">
        <div className="col is-full">
          <HtmlEditor
            html={data.header_text}
            onChange={(e) => setData({...data, header_text: e.target.value})}
          />
        </div>
      </div>

      <hr/>

      <div className="row hr-margin-24-top">
        <div className="col">
          <strong>
            <span className="underline">Club Description/Benefits</span>
          </strong>
          <p>Provide helpful information about your club memberships and benefits options.</p>
          <p>This information will display below the header text.</p>
        </div>
      </div>
      <div className="row hr-margin-24-bottom">
        <div className="col is-full">
          <HtmlEditor
            html={data.page_description}
            onChange={(e) => setData({...data, page_description: e.target.value})}
          />
        </div>
      </div>

      <hr/>

      <div className="row hr-margin-24-top">
        <div className="col">
          <strong>
            <span className="underline">About the Club</span>
          </strong>
          <p>Let potential members know more about your club. This promotional information will display in the club list
            on the association join page and provide you with an opportunity to get the interest of golfers searching
            for a club to join.</p>
        </div>
      </div>
      <div className="row hr-margin-24-bottom" style={{marginTop: "0px"}}>
        <div className="col is-full">
          <textarea
            className="textarea-club-details"
            onChange={(e) => setData({...data, club_details: e.target.value})}>
            {data.club_details}
          </textarea>
        </div>
      </div>

      <div className="row">
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill gray" onClick={() => props.close_modal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type={'submit'} disabled={submitting} onClick={submit}
                  className="btn fill blue">Save
          </button>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({addAlert}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditClubJoinPageCustomizationForm))
