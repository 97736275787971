import React, { Fragment } from 'react';
import GhinTable from "../shared/GhinTable";

const COLUMNS = [
   {
      accessor: 'used_in_revision',
      show: false
   },
   {
      Header: 'Played At',
      accessor: 'played_at',
      className: 'table-align-center',
      thClassName: 'col_date table-align-center',
      highlightColumn: true,
      highlightConditionKey: 'used_in_revision',
   },
   {
      Header: 'Posted At',
      accessor: 'posted_at',
      className: 'table-align-center',
      thClassName: 'col_date table-align-center',
      highlightColumn: true,
      highlightConditionKey: 'used_in_revision',
   },
   {
      Header: 'Adjusted Score',
      accessor: 'adjusted_gross_score',
      Cell: props => 
		<Fragment>
		{props.original.adjusted_gross_score} {props.original.scaled /*&& props.original.number_of_played_holes != "9"*/ && <a className='score-number_of_played_holes'>{" ("}{props.original.number_of_played_holes}{")"}</a>}
		</Fragment>,
      className: 'table-align-center',
      thClassName: 'col_score table-align-center',
      highlightColumn: true,
      highlightConditionKey: 'used_in_revision',
   },
   {
      Header: 'Course Rating ™',
      accessor: 'course_rating',
      className: 'table-align-center',
      thClassName: 'col_slope table-align-center',
      highlightColumn: true,
      highlightConditionKey: 'used_in_revision',
   },
   {
      Header: 'Slope Rating ™',
      accessor: 'slope_rating',
      className: 'table-align-center',
      thClassName: 'col_slope table-align-center',
      highlightColumn: true,
      highlightConditionKey: 'used_in_revision',
   },
   {
      Header: 'PCC',
      accessor: 'pcc',
      className: 'table-align-center',
      thClassName: 'col_pcc table-align-center',
      highlightColumn: true,
      highlightConditionKey: 'used_in_revision',
   },
   {
      Header: 'ESR',
      accessor: 'esr',
      className: 'table-align-center',
      thClassName: 'col_esr table-align-center',
      highlightColumn: true,
      highlightConditionKey: 'used_in_revision',
   },
   {
      Header: 'Other Adjustments',
      accessor: 'other_adjustments',
      className: 'table-align-center',
      thClassName: 'col_other_adj table-align-center',
      highlightColumn: true,
      highlightConditionKey: 'used_in_revision',
   },
   {
      Header: 'Diff. ™',
      accessor: 'adjusted_differential',
      className: 'table-align-center',
      thClassName: 'col_diff table-align-center',
      highlightColumn: true,
      highlightConditionKey: 'used_in_revision',
   }
];

export default function HandicapRevisionDetailsForm(props) {
   const revisionDetails = props.revisionDetails;
   const scores = revisionDetails.scores;

   const noPreviousRevision = revisionDetails.no_previous_revision;
   const isFullyDeveloped = scores.length === 20;

   return (
      <Fragment>
         <div className='revision_details' style={{marginBottom: 10}}>
            <h3><strong>{revisionDetails.golfer_name}</strong></h3>
            <h3>GHIN number: <strong>{revisionDetails.golfer_ghin_number}</strong></h3>
         </div>
         <div className="row">
            <div className="col">
               <GhinTable
                  loading={false}
                  columns={COLUMNS}
                  hideSelect
                  hidePagination
                  data={scores}
                  sortable
               />
            </div>
         </div>
         <div className='revision_details'>
            <div className={"row with-padding-vertical row-spacing"}>
               <div className={"col is-1-of-2 centered"}>
                  <h3 style={{marginBottom: '10px'}}>
                     <strong>Handicap Revision Supporting Values </strong>
                  </h3>
                  {
                  noPreviousRevision ?
                     <p>
                        <strong>This is the first revision.</strong>
                     </p>
                     :
                     <>
                        <p>
                           <strong>Handicap Index®</strong>
                           <strong> ({revisionDetails.revision_date_before_revision})</strong>
                           : {revisionDetails.handicap_index_before_revision}
                        </p>
                        <p>
                           <strong>Low Handicap Index ™</strong>
                           <strong> ({revisionDetails.revision_date_before_revision})</strong>
                           : {revisionDetails.low_handicap_index_before_revision}
                        </p>
                        <p>
                           {
                              revisionDetails.hidden_revision_before_revision ?
                              <>
                                 <strong>The Handicap Revision </strong>
                                 <strong> ({revisionDetails.revision_date_before_revision})</strong> is hidden
                              </>
                              :
                              <>
                              </>
                           }
                        </p>
                     </>
                  }
                  <p>
                     <strong> Lowest Score Differentials™ used: </strong>
                     {revisionDetails.best_8_differentials.map(d => d.toFixed(1)).join(', ')}
                  </p>
                  <p>
                     <strong>Sum of Score Differentials used: </strong>
                     {revisionDetails.total_differentials.toFixed(1)}
                  </p>
                  <p>
                     <strong>Average of the Score Differentials used: </strong>
                     {revisionDetails.average_8_20.toFixed(1)}
                  </p>
                  <p>
                     <strong>Eligible for Low Handicap Index: </strong>
                     {isFullyDeveloped ? "Yes" : "No"}
                  </p>
                  <p>
                     <strong>Handicap Index® unadjusted: </strong>
                     {revisionDetails.raw_handicap_index.toFixed(1)}
                  </p>
                  <p>
                     {
                        isFullyDeveloped ?
                           <>
                              <strong>Raw Low Handicap Index: </strong> {revisionDetails.raw_low_hi.toFixed(1)} ({revisionDetails.final_low_hi_date})
                           </>
                           :
                           <>
                              <strong>Raw Low Handicap Index </strong>
                              does not exist since the golfer is not fully developed
                           </>
                     }
                  </p>
                  <p>
                     {
                        isFullyDeveloped ?
                           <>
                              <strong>Low Handicap Index™ used in computation: </strong>
                              {revisionDetails.final_low_hi}
                           </>
                           :
                           <>
										<strong>Low Handicap Index™ </strong>
										does not exist since the golfer is not fully developed
                           </>
                     }
                  </p>
                  <br></br>
               </div>
               <div className={"col is-1-of-2 centered"}>
                  <h3 style={{marginBottom: '10px'}}>
                     <strong>Cap procedure supporting values</strong>
                  </h3>
                  <p>
                     <strong>Soft Cap Applied: </strong>
                     {
                        revisionDetails.soft_cap ?
								   <>
										Yes
									</>
                           :
                           <>
                              No
                           </>
                     }
                  </p>
                  <p>
                     <strong>Hard Cap Applied: </strong>
                     {
                        revisionDetails.hard_cap ?
									<>
										Yes
									</>
                           :
                           <>
                              No
                           </>
                     }
                  </p>
                  <p>
						   <strong>Handicap Index® Modified ? </strong>
                     {
                        revisionDetails.hi_modified ?
                           <>
                              Yes
                           </>
                           :
                           <>
                              No
                           </>
                     }
                  </p>
                  <p>
                     {
                        revisionDetails.hi_modified ?
                        <>
                           <strong>Handicap Index® Modification Value: </strong> {revisionDetails.handicap_modification_value.toFixed(1)}
                        </>
                        :
                        <>
                        </>
                     }
                  </p>
                  <p>
                     {
                        revisionDetails.hi_modified ?
                        <>
                           <strong>Handicap Index® Expiration Date: </strong> {revisionDetails.handicap_index_expiration_date}
                        </>
                        :
                        <>
                        </>
                     }
                  </p>
                  <p>
                     <strong>Low Handicap Index® modified ? </strong>
                     {
                        revisionDetails.low_hi_modified ?
                           <>
                              Yes
                           </>
                           :
                           <>
                              No
                           </>
                     }
                  </p>
                  <p>
                     {
                        revisionDetails.low_hi_modified ?
                        <>
                           <strong>Low Handicap Index® Modification Value: </strong> {revisionDetails.low_hi_modification_value.toFixed(1)}
                        </>
                        :
                        <>
                        </>
                     }
                  </p>
                  <p>
                     {
                        revisionDetails.low_hi_modified ?
                        <>
                           <strong>Low Handicap Index® Expiration Date: </strong> {revisionDetails.low_handicap_index_expiration_date}
                        </>
                        :
                        <>
                        </>
                     }
                  </p>
                  <br></br>

                  <h3>
                     <strong>Final Handicap Index®: </strong>
                     {revisionDetails.final_handicap_index}
                  </h3>
                  <h3>
                     {
                        isFullyDeveloped ?
                           <>
                              <strong>Final Low Handicap Index™: </strong>
                              {revisionDetails.final_low_hi}
                           </>
                           :
                           <>
										<strong>Final Low Handicap Index™ </strong>
										does not exist since the golfer is not fully developed
                           </>
                     }
                  </h3>
               </div>
            </div>
         </div>
      </Fragment>
   );
}
