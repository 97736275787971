import React, {Component} from 'react'
import ReportingMessagingProtocol from './ReportingMessagingProtocol'

export default class Templates extends Component {

	render() {
		return (
			<ReportingMessagingProtocol>
				<iframe title="Templates reports" width="100%" height="1000px" src={this.props.iframeUrl} />
			</ReportingMessagingProtocol>
		);
	}

}
