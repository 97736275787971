import React, {Component, Fragment} from 'react';
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css';
import GolferService from "../../../../services/api/golfer";
import debounce from "debounce-promise";
import DatePicker from "react-datepicker";

export default class TableFilter extends Component {
	defaultValues = { full_name: "", membership_code: [], status: [{label: "Active", value: "Active"}], merged: '', is_dac: "", membership: [], gender: "", in_group: [], isGroupFiltered: false, has_digital_profile: '',
		is_trial: this.props.isTrial ? [{label: "Yes", value: true}] : [{label: "No", value: false}],
		auto_renew_date_to: "", auto_renew_date_from: "", auto_renew_enabled: "", ojr_only: ""};
	clearValues = { full_name: "", membership_code: [], status: [], merged: '', is_dac: "", membership: [], gender: "", in_group: [], isGroupFiltered: true, has_digital_profile: '', auto_renew_date_to: '', is_trial: '', auto_renew_date_from: '', auto_renew_enabled: '', ojr_only: ""};

    constructor(props) {
        super(props);
        this.state = { ...this.defaultValues,  ...{club_groups_options: []}};
        this.setFilters = debounce(this.setFiltersD, 1000);
    }

	updateFilter = (name, value) => {
		this.setState({ [name]: value }, () => this.sendFilters(name === "merged" ? "merged": null));
    };

	updateGroupFilter = (name, value) => {
		if (value.value) {
			this.setState({ ...this.clearValues, ...{in_group: value}}, () => this.sendFilters(null));
		} else {
			this.resetFilters();
		}
	};

    setFiltersD  (filteredArray) {
			this.props.updateFiltered(filteredArray);
	}

	sendFilters() {
        let filteredArray = [];
        Object.keys(this.state).map((key) => {
        	if(key === "merged") {
        		filteredArray = filteredArray.map(filter => {
        			if (filter.id === "status") {
        				if (this.state[key].value === "Merged") {
							filter.value = filter.value.filter(status => status.value !== "Merged");
        					filter.value.push(this.state[key]);
							return filter;
        				} else {
							filter.value = filter.value.filter(status => status.value !== "Merged");
							return filter;
        				}
        			} else {
						return filter
					}
        		});
			} else {
				filteredArray.push({
					id: key,
					value: this.state[key]
				})
			}

			return null;
        });
        this.setFilters(filteredArray);
    }

	resetFilters() {
		this.setState({...this.defaultValues}, () => this.sendFilters() );
    }


	getGroups(callback) {
		GolferService.getGroups(this.props.club_id, {include_golfers: 'false'})
			.then(response => {
				let club_groups_options = response.golfer_groups.map(club => {
					club.value = club.id;
					club.label = club.name;
					return club;
				});
				this.setState({
					club_groups_options
				}, callback)
			})
			.catch(err => {

			});
	}

	componentDidMount(){
		this.getGroups();
	}

	render() {

		if (this.props.hideFilters) return null;
		return (
            <Fragment>
                <div className="filters">
					<div className="row">
						<div className="filters__inputs col">
							<div className="row">
								<div className={'col'}>
									<div className="row">
										<div className="col is-3-of-20">
											<label htmlFor="golfer-name">Golfer Name</label>
											<input type="text"
												   autoComplete="off"
												   value={this.state.full_name}
												   name="golfer-name"
												   id="golfer-name"
												   disabled={this.state.isGroupFiltered}
												   onChange={(e) => {
												this.updateFilter('full_name', e.target.value)
											}}/>
										</div>

										<div className="col is-3-of-20">
											<GhinFilterSelect
												options={['Active', 'Inactive', 'Archived']}
												value={this.state.status}
												disabled={this.state.isGroupFiltered}
												onChange={values => this.updateFilter('status', values)}
												label={'Membership Status'}
											/>
										</div>

										<div className="col is-3-of-20">
											<GhinFilterSelect
												singleChoice={true}
												options={[{label:'Yes', value: 'Merged'}, {label:'No', value: null}]}
												value={this.state.merged}
												disabled={this.state.isGroupFiltered}
												onChange={values => this.updateFilter('merged', values)}
												label={'Merged'}
											/>
										</div>

										{process.env.REACT_APP_TYPE !== "hv" && <div className="col is-3-of-20">
                                            <GhinFilterSelect
                                                options={this.props.membershipTypes.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1)}
                                                value={this.state.membership_code}
												disabled={this.state.isGroupFiltered}
                                                onChange={values => this.updateFilter('membership_code', values)}
                                                label={'Membership Code (MC)'}
                                            />
                    </div>}


										<div className="col is-3-of-20">
											<GhinFilterSelect
												singleChoice={true}
												options={[{label: 'Male', value: "M"}, {label: 'Female', value: "F"}]}
												value={this.state.gender}
												disabled={this.state.isGroupFiltered}
												onChange={value => this.updateFilter('gender', value)}
												label={'Gender'}
											/>
										</div>
									</div>



                  <div className="row">
                    <div className="col is-3-of-15">
                        <GhinFilterSelect
                          singleChoice={true}
                          options={[{label:'Yes', value: true}, {label:'No', value: false}]}
                          value={this.state.has_digital_profile}
                          disabled={this.state.isGroupFiltered}
                          onChange={values => this.updateFilter('has_digital_profile', values)}
                          label={'Digital Profile'}
                        />
										</div>
					  {this.props.autoRenewalEnabled && <div className="col is-3-of-15">
						  <GhinFilterSelect
							  singleChoice={true}
							  options={[{label:'Yes', value: true}, {label:'No', value: false}]}
							  value={this.state.ojr_only}
							  disabled={this.state.isGroupFiltered}
							  onChange={values => this.updateFilter('ojr_only', values)}
							  label={'OJR Golfers Only'}
						  />
					  </div>}
											{ this.props.autoRenewalEnabled && [<div className="col is-3-of-15">
	                      <GhinFilterSelect
	                        options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
	                        value={this.state.auto_renew_enabled}
	                        disabled={this.state.isGroupFiltered}
	                        onChange={values => this.updateFilter('auto_renew_enabled', values)}
	                        label={'Auto-Renew'}
	                      />
										</div>,
	                    <div className="col is-3-of-15">
	                      <label>Auto-Renew Date From</label>
	                      <DatePicker
	                        selected={this.state.auto_renew_date_from ? new Date(this.state.auto_renew_date_from) : null}
	                        className={`date`}
	                        dropdownMode="select"
	                        dateFormat={'MM/dd/yyyy'}
	                        showMonthDropdown
	                        showYearDropdown
	                        maxDate={this.state.auto_renew_date_to ? new Date(this.state.auto_renew_date_to) : null}
	                        onChange={(value) => {
								if (value) {
									this.updateFilter('auto_renew_date_from', value.toString());
								} else {
									this.updateFilter('auto_renew_date_from', '');
								}
	                        }}
	                      />
										</div>,
	                    <div className="col is-3-of-15">
	                      <label>Auto-Renew Date To</label>
	                      <DatePicker
	                        selected={this.state.auto_renew_date_to ? new Date(this.state.auto_renew_date_to) : ""}
	                        className={`date`}
	                        dropdownMode="select"
	                        dateFormat={'MM/dd/yyyy'}
	                        showMonthDropdown
	                        showYearDropdown
	                        minDate={this.state.auto_renew_date_from ? new Date(this.state.auto_renew_date_from) : ""}
	                        onChange={(value) => {
								if (value) {
									this.updateFilter('auto_renew_date_to', value.toString());
								} else {
									this.updateFilter('auto_renew_date_to', '');
								}
	                        }}
	                      />
										</div>]}
                  </div>

								</div>

							</div>
						</div>

            <div className="col is-1-of-13">
              <div className="form__separator or top"></div>
            </div>

            <div className="col is-2-of-13">
              <GhinFilterSelect
                singleChoice={true}
                value={this.state.in_group}
                options={this.state.club_groups_options.sort((a, b) => (a.label > b.label) ? 1 : -1)}
                onChange={values => { this.updateGroupFilter('in_group', values) }}
                label={'Golfer Group'}
              />
            </div>

            <div className="filters__controls col is-1-of-14">
                        <button className="btn fill gray top" onClick={()=>this.resetFilters()}>Reset</button>
                    </div>
					</div>
                </div>

            </Fragment>
		);
	}
}
