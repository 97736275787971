import { Fragment } from "react";
import EditOfferForm from "../Forms/EditOfferForm";
import AddOfferForm from "../Forms/AddOfferForm";

export default function AddOrEditOfferModal(props) {
  return (
    <Fragment>
      <div className="modal__container narrow">

        <div className="modal__head">
          {
            props.operationType === "Edit" && <Fragment>
              <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
              <h2 className="modal__title">Edit Offer</h2>
            </Fragment>
          }

          {
            props.operationType === "Create" && <Fragment>
              <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
              <h2 className="modal__title">Add Offer</h2>
            </Fragment>
          }

          <button className="modal__close" onClick={() => { props.closeModal() }}><i className="material-icons-outlined">clear</i></button>

        </div>

        <div className="modal__body">
          { props.operationType === "Edit" &&
            <EditOfferForm
              {...props}
            />
          }

          { props.operationType === "Create" &&
            <AddOfferForm
              {...props}
            />
          }
        </div>

      </div>
    </Fragment>
  )
}