import React, {Component, Fragment} from 'react';

import WithdrawHandicapIndexForm from '../Forms/WithdrawHandicapIndexForm';

export default class WithdrawHandicapIndexModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isConfirmationModal: false
		};
	}

	confirmModal() {
		this.setState({isConfirmationModal: true})
	}

	render() {
		return (
			<Fragment>
				<div className="modal__container narrow">
					<div className="modal__head">
						{this.state.isConfirmationModal ?
							<Fragment>
								<div className="modal__icon"><i className="material-icons-outlined">warning</i></div>
								<h2 className="modal__title">Are You Sure?</h2>
							</Fragment>
							:
							<Fragment>
								<div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
								<h2 className="modal__title">Withdraw Handicap Index</h2>
							</Fragment>
						}
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
					</div>
					<div className="modal__body">
						<WithdrawHandicapIndexForm golfer={this.props.golfer} closeModal={(data) =>this.props.closeModal(data)} confirmModal={() => this.confirmModal()}/>
					</div>
				</div>
			</Fragment>
		);
	}
}
