import React, { Component, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import ConfirmationModalTypeB from './ConfirmationModalTypeB';

import UsersService from '../../services/api/users';

export default class DeleteUserAccessModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      error: false
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    return (
      <Fragment>

				<ConfirmationModalTypeB
					wideClass={'narrow'}
          openModal={this.props.deleteUserAccessModalIsOpen}
					onConfirmAction={this.onSubmit}
					onCancelAction={()=>{this.props.closeModal()}}
					closeModal={()=>{this.props.closeModal()}}
					question={`Are you sure you want to remove the user ${this.props.user.user.last_name} ${this.props.user.user.first_name}?`}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
          error={this.state.error}
				/>

      </Fragment>
      

      

    )
  }

  onSubmit() {
    UsersService.deleteAccess(this.props.user.user_access.id).then(response => {
      if(response.Errors) throw(response.Errors)
      this.props.closeModal();
    })
      .catch(err => {
        console.error(err);
        if (err) {
          this.setState({ error: err })
        }
      })
  }

}

DeleteUserAccessModal = reduxForm({
  form: 'deleteAssocUserAccess',
  destroyOnUnmount: true
})(DeleteUserAccessModal)