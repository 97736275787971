import React, { Component, Fragment } from "react";
import EditNotificationsForm from "../Forms/EditNotificationsForm";

export default class EditNotificationsModal extends Component {
  render() {
    return (
      <Fragment>
        <div class="modal__container narrow">

          <div class="modal__head">

            <div class="modal__icon"><i class="material-icons-outlined">edit</i></div>
            <h2 class="modal__title">Edit Notification Settings</h2>
            <button class="modal__close" onClick={() => {this.props.closeModal()}}><i class="material-icons-outlined">clear</i></button>
          
          </div>

          <div class="modal__body">
            <div className="col bottom-margin">
              <span><strong>Note:</strong> Push Notifications must be enabled by the golfer within the GHIN Mobile App. As an Admin, you only have the ability to disable Push Notifications for the golfer.</span>
            </div>

            <EditNotificationsForm notifications_preferences={this.props.notifications_preferences} close={(data)=>this.props.closeModal(data)}
              golfer_id={this.props.golfer_id} club_id={this.props.club_id} />

          </div>

        </div>
      </Fragment>
    )
  }
}