import React, {Component, Fragment} from 'react';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import "react-datepicker/dist/react-datepicker.css";
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputField} from "../../inputs/InputField";
import {required} from "../../inputs/validations";
import GolferService from "../../services/api/golfer";
import moment from "moment";
import {DatePickerInput} from "../../inputs/DatePicker";
import {processErrorMessage} from "../../services/shared/errorHelper";


class ModifyLowHandicapIndexForm  extends Component {

    constructor(props) {
		super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
          confirmAction: false
        };
	}

	componentDidMount() {
		this.props.initialize({expiration_date: new Date(moment().add(1,"days")), value: this.props.golfer.low_hi})

	}

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));

		data.type = "low_hi";
		data.expiration_date =  moment(data.expiration_date).format("YYYY-MM-DD");

		return GolferService.handicapModification(this.props.golfer.id, data)
			.then(res => {
				this.props.addAlert({type: 'success', message: 'The Low H.I. modification has been successfully applied!'});
				this.props.closeModal(res)
			})
      .catch(err => {

				if(err.data) {
					let errorMessage = processErrorMessage(err.data.errors);
					this.props.addAlert({type: 'error', message: errorMessage})
          this.props.closeModal()
				}
				console.error(err)
			});
	}

	render() {
		const {handleSubmit, valid, submitting} = this.props;

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>

					{submitting ?
						<table style={{width: '100%'}}>
							<tbody >
							<tr>
								<td style={{textAlign: 'center'}}>
									<span className="data-table__loader"></span>
								</td>
							</tr>
							</tbody>
						</table>
						:
						<Fragment>
              {this.state.confirmAction ?
                <Fragment>
                  <h2>Are you sure you want to modify the Low H.I. for {this.props.golfer.player_name}?</h2>
                  <div className="row">
                    <div className="col is-1-of-2">
                      <button type={'button'} onClick={() => this.props.closeModal()} className="btn fill gray">Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
                      <button type={'submit'} ref={'submit'} className="btn fill blue">Confirm</button>
                    </div>
                  </div>
                </Fragment>
                :
                <Fragment>
                  <div className="row">
                    <div className="col is-1-of-2">
                      <label htmlFor="value">Modify Low H.I. <span>*</span></label>
                      <Field component={InputField} validate={[required]} type="text" name="value" id="value" />
                    </div>

                    <div className="col is-1-of-2">
                      <label htmlFor="expiration_date">Expiration Date <span>*</span></label>
                      <Field minDate={new Date(moment().add(1,"days"))}
										   maxDate={new Date(moment().add(1, "years"))}
										   className={'date'}
										   validate={[required]}
										   showMonthDropdown
										   showYearDropdown
										   name={'expiration_date'}
										   id={'expiration_date'}
										   component={DatePickerInput}
										   placeholder="Select ... " />
                   </div>
                 </div>
                 <div className="row">
                   <div className="col is-1-of-2">
                     <button type={'button'} onClick={() => this.props.closeModal()} className="btn fill gray">Cancel</button>
                   </div>
                   <div className="col is-1-of-2">
                     <button type={'button'} disabled={!valid} onClick={(e) => {e.preventDefault(); this.setState({confirmAction: true}); this.props.confirmModal()}} className="btn fill blue">Save</button>
                   </div>
                 </div>
               </Fragment>
             }
           </Fragment>
         }
       </form>
     </Fragment>
		);
	}
}
ModifyLowHandicapIndexForm   = reduxForm({
	form: 'modifyLowHandicapIndexForm',
	destroyOnUnmount: true
})(ModifyLowHandicapIndexForm  );

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(ModifyLowHandicapIndexForm));
