import React, {Component, Fragment} from 'react';

import "react-datepicker/dist/react-datepicker.css";

export default class SpecialUpdateForm extends Component {

    constructor(props) {
		super(props);

		this.state = {
            startDate: new Date()
		};

        this.handleChange = this.handleChange.bind(this);

	}

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

	render() {
		return (
            <Fragment>

				{this.props.loading ? <span className="data-table__loader clubs-special-update__loader"></span> :
					<div className="row">
						<div className="col is-1-of-2">
							<button className="btn fill gray" onClick={() => this.props.closeModal()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button className="btn fill blue" onClick={() => this.props.confirmAction()}>Confirm</button>
						</div>
					</div>}

            </Fragment>
		);
	}
}
