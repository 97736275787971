import React, {Component} from 'react';
import {Field} from "redux-form";
import {InputField} from "../../inputs/InputField";
import GolferService from "../../services/api/golfer";
import debounce from "debounce-promise";
import GolferGuardianForm from "./GhinGuardian/GolferGuardianForm";
import NonGolferGuardianForm from "./GhinGuardian/NonGolferGuardianForm";
import {required} from "../../inputs/validations";


class GhinGuardianFormComponent2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasGhinNumber: !props.hide_search,
			isGHINValid: undefined,
			isMinor: false,
			isJunior: false,
			assignGuardian: true,
			isEditGuardianForm: false,
			foundGolferGuardian: null

		};
		this.searchForGhinNumber = debounce(this.searchForGhinNumber.bind(this), 1000);
	}

	clearInput() {
		['email', 'first_name', 'last_name', 'middle_name', 'prefix', 'suffix', 'phone_number', 'golfer_id', 'relationship', 'other_relationship']
			.forEach((key) => {
				this.props.change(`guardian.${key}`, undefined);
				if (this.props.untouch) {
					this.props.untouch(`guardian.${key}`)

				}
			});
		this.setState({
			foundGolferGuardian: null
		})
	}


	loadGuardianData(golfer) {
		['email', 'first_name', 'last_name', 'middle_name', 'prefix', 'suffix', 'phone_number', 'golfer_id']
			.forEach((key) => {
				if (golfer.hasOwnProperty(key)) {
					this.props.change(`guardian.${key}`, golfer[key])
				}
			});
	}


	searchForGhinNumber(value) {
		this.clearInput();
		if (!value) {

			this.setState({
				foundGolferGuardian: null,
				isGHINValid: false,
				isGHINEmpty: true
			});
			return;
		}


		let reg = /^\d+$/;
		if (!reg.test(value)) {
			return this.setState({
				foundGolferGuardian: null,
				isGHINValid: false,
				isGHINEmpty: false
			})

		}

		this.clearInput();
		GolferService.findGolferByGhinOrEmail(value, null)
			.then(res => {
				const golfer = res.golfer;
				this.loadGuardianData({
					golfer_id: golfer.id
				});
				this.setState({
					isGHINValid: true,
					foundGolferGuardian: golfer,
					isGHINEmpty: false
				})
			})
			.catch(err => {
				this.setState({
					foundGolferGuardian: null,
					isGHINValid: false,
					isGHINEmpty: false
				})
			});
	}

	componentDidMount() {
		const isMinor = !!this.props.minor;
		const isJunior = !!this.props.junior && !isMinor;
		this.setState(({
			isJunior,
			isMinor,
			assignGuardian: !isJunior,
		}))
		this.props.change("assignGuardian", !isJunior);
	}

	componentDidUpdate(prevProps) {
		const isMinor = !!this.props.minor;
		const isJunior = !!this.props.junior && !isMinor;

		if (this.state.isMinor !== isMinor || this.state.isJunior !== isJunior) {
			this.setState(({
				isJunior,
				isMinor,
				assignGuardian: !isJunior,
			}))
			this.props.change("assignGuardian", !isJunior);
		}
		this.props.change("assignGuardian", this.state.assignGuardian);
	}


	render() {
		const {isMinor, isJunior} = this.state;
		const ghinError = (<span
			className={'validation__message is-error'}>{!this.state.isGHINEmpty ? 'No results found, please change your search criteria.' : 'This field is required'}</span>)
		return (
			<>
				<div className="form__separator padding-16-y"></div>
				<div className={'row'}>
					<div className="col is-full">
						{isMinor ?
							<span><strong>Note:</strong> The birthdate provided indicates this golfer is a minor. A guardian is required for golfers less than 13 years of age. The guardian must be at least 18 years of age.</span> : null}
						{isJunior ?
							<span><strong>Note:</strong> The birthdate provided indicates this golfer is a junior (> 13 but {"<"} 19 years of age). A guardian is not required, but can be added for Junior golfers. The guardian must be at least 18 years of age.</span> : null}
					</div>
				</div>

				{
					isJunior ? <div className="row">
						<div className="col is-full">
							<span className="label">Would you like to assign a guardian? <span>*</span></span>
							<div className="radio__container">
								<Field
									onClick={
										() => {
											this.setState((prevState) => {
												return {assignGuardian: !prevState.assignGuardian};
											});
                      this.props.selectGuardian();

										}
									}
									checked={this.state.assignGuardian}
									id="assignGuardian_yes"
									value={true}
									name={'golfer.assignGuardian'}
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="assignGuardian_yes">Yes</label>
								<Field
									onClick={
										() => {
											this.setState((prevState) => {
												return {assignGuardian: !prevState.assignGuardian};
											});
                      this.props.selectGuardian();

										}
									}
									checked={!this.state.assignGuardian}
									id="assignGuardian_no"
									value={false}
									name={'golfer.assignGuardian'}
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="assignGuardian_no">No</label>
							</div>
						</div>
					</div> : null
				}

				{
					this.state.assignGuardian ? (<>
						<div className="row">
							<div className="col is-full">
								<span
                  className="label">Does the guardian have a GHIN Number? {!this.props.is_archived && <span>*</span>}</span>
								<div className="radio__container">
									<Field
										onClick={
											() => {
												this.setState((prevState) => {
													return {
														hasGhinNumber: !prevState.hasGhinNumber,
														isGHINValid: undefined,
														isGHINEmpty: true
													};
												});
												this.clearInput();
											}
										}
										checked={this.state.hasGhinNumber}
										id="hasGhinNumber_yes"
										value={true}
										name={'golfer.hasGhinNumber'}
										component={'input'}
										className="simple__radio"
										type="radio"
									/>
									<label htmlFor="hasGhinNumber_yes">Yes</label>
									<Field
										onClick={
											() => {
												this.setState((prevState) => {
													return {
														hasGhinNumber: !prevState.hasGhinNumber,
														isGHINValid: undefined,
														isGHINEmpty: true
													};
												});
												this.clearInput();
											}
										}
										checked={!this.state.hasGhinNumber}
										id="hasGhinNumber_no"
										value={false}
										name={'golfer.hasGhinNumber'}
										component={'input'}
										className="simple__radio"
										type="radio"
									/>
									<label htmlFor="hasGhinNumber_no">No</label>
								</div>
							</div>
						</div>

						{
							this.state.hasGhinNumber ? (
								<div className="row">
									<div className="col is-1-of-2">
										<span style={{display: 'none'}}><Field component={InputField}
                      validate={!this.props.is_archived && [required]}
																			   type="text"
																			   name="guardian.golfer_id"
																			   id="guardian.golfer_id"
										/></span>

										<label htmlFor="guardian.golfer_id_search">Guardian GHIN
                      Number {!this.props.is_archived && <span>*</span>}</label>
										<input
											type="text"
											className={(this.state.isGHINValid === false ? ' is-error' : '')}
											onChange={(event) => this.searchForGhinNumber(event.target.value)}
											name="guardian.golfer_id_search"
											id="guardian.golfer_id_search"/>
										{this.state.isGHINValid === false ? (ghinError) : null}
									</div>
								</div>
							) : null
						}

						{
							this.state.foundGolferGuardian ? (
								<GolferGuardianForm guardian={this.state.foundGolferGuardian}
													formGuardian={this.props.formGuardian}/>) : null
						}

						{
							!this.state.hasGhinNumber ? (<NonGolferGuardianForm formGuardian={this.props.formGuardian}
																				clearInput={this.clearInput.bind(this)}
																				loadGuardianData={this.loadGuardianData.bind(this)}
                change={this.props.change}
                is_archived={this.props.is_archived}
              />) : null
						}


					</>) : null
				}

			</>
		)
	}
}

export default GhinGuardianFormComponent2;
