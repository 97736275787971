import React, {Component, Fragment} from 'react';
import EditSurveySetupForm from '../Forms/EditSurveySetupForm';

export default class EditSurveySetupModal extends Component {

	render() {
		return (
			<Fragment>
				<div className="modal__container narrow">
					<div className="modal__head">
						<div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
						<h2 className="modal__title">Edit Survey Setup</h2>
						<button className="modal__close" onClick = {() => this.props.exitModal()}><i className="material-icons-outlined">clear</i></button>
					</div>
					<div className="modal__body">
						<EditSurveySetupForm survey={this.props.survey} exit = {() => this.props.exitModal()} close={(updatedSurvey)=>this.props.closeModal(updatedSurvey)}/>
					</div>
				</div>
			</Fragment>
		);
	}
}
