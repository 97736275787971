export function clubReducer(state = {}, action) {
	switch(action.type) {
		case '@@ghin/UPDATE_CLUB':
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
}
