import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { InputField } from "../../../../../inputs/InputField";
import { maxLength, required } from "../../../../../inputs/validations";
import { withKeyPress } from "../../../../shared/highOrderComponent/withKeyPress";

function AssociationJoinPageNameFunction(props) {
  const history = useHistory();
  const { handleSubmit, invalid } = props;
  const serverError = props.serverError && props.serverError;

  useEffect(() => {
    const name = props.data.name
    const initialize = props.initialize
    initialize({
      name
    })
  }, [props.initialize, props.data.name])

  const onSubmit = (values, dispatch, props) => {
    props.set_data({
      ...props.data,
      name: values.name
    });
    props.push_join_page_changes().then(() => {
      history.push("./select-membership");
    })
  }

  return (
    <Fragment>
      <div className="hr-margin-24-top hr-margin-24-bottom">
        <div className="center_join_pages_navigation">
          Please enter a Join Page Name that helps you easily identify one page from another.  This name must be unique and no more than 100 characters in length.
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="row centered-items">
            <div className="col is-1-of-3">
              <label htmlFor="name">Page Name <span>*</span></label>
              <Field
                className={serverError ? 'is-error' : ''}
                component={InputField}
                validate={[required, maxLength([100, "The Name cannot exceed 100 characters"])]}
                type="text"
                name="name"
                id="name"
                onChange={(e) => {
                  props.set_data({
                    ...props.data,
                    name: e.target.value
                  });
                  props.clearServerError();
                }}
              />
            {serverError && <span class="validation__message is-error">{props.serverError}</span>}
            </div>
          </div>
        </div>
        <div className="row hr-margin-24-top">
          <div className="col is-1-of-4 push-right">
            <div className="row">
              <div className="col">
                <button type={'button'} className="btn fill gray" onClick={() => history.push("../../join-pages")}>Cancel</button>
              </div>
              <div className="col">
                <button type={'submit'} className="btn fill blue" disabled={invalid}>Next/Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment >
  )
}

const AssociationJoinPageName = reduxForm({
  form: 'AssociationJoinPageName',
  destroyOnUnmount: true
})(AssociationJoinPageNameFunction)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AssociationJoinPageName));
