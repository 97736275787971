import React, {Component, Fragment} from 'react';

import "react-datepicker/dist/react-datepicker.css";

import Header from '../shared/Header';
import Navigation from '../shared/Navigation';
import BreadCrumbsNav from '../shared/BreadCrumbsNav';
import Footer from '../shared/Footer';
import OnePlayerEntry from "./OnePlayer/OnePlayerEntry";
import MultiplePlayersEntry from "./MultiplePlayers/MultiplePlayersEntry";
import ScoreService from "../../services/api/score";
import ConfirmationModal from "../Modals/ConfirmationModal";
import Modal from "react-modal";
import UsersService from "../../services/api/users";

export default class RapidScoreEntry extends Component {

	constructor(props) {

		super(props);

		this.state = {
			entries: [],
			activeButton: 'multipleplayers',
			deleteScoreModalIsOpen: false,
			scoreToDelete: null
        };

		this.addEntry = this.addEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
    }

	componentDidMount() {
		UsersService.accesses()
		Modal.setAppElement('body');
	}

    componentDidUpdate(prevProps, prevState, snapshot) {

	}

	addEntry(entry) {
		let entries = [entry, ...this.state.entries];
		this.setState({
			entries
		})
	}

	showDeleteConfirmationModal(e, id, ghinNumber, uniqueId, datePlayed) {
		e.preventDefault();

		this.setState({
			deleteScoreModalIsOpen: true,
			scoreToDelete: {id, ghinNumber, uniqueId, datePlayed}
		});

	}

	deleteEntry(){

		let {id} = this.state.scoreToDelete;

		ScoreService.deleteScore(id)
			.then(response => {
				let entries = this.state.entries;
				entries = entries.filter(entry => entry.id !== id);
				this.setState(({
					entries,
					deleteScoreModalIsOpen: false,
					scoreToDelete: null,
				}))
			})
			.catch(err => {

			})
	}

	render() {
		return (
            <Fragment>
                <Header />
				<Navigation />
				<BreadCrumbsNav />

                    <main>
                        <div className="container">
							<div className="row">
								<div className="col auto">
									<ul className="inline__tabs">
										<li><button onClick={() => this.setState({activeButton: 'multipleplayers'})} className={`btn fill ${this.state.activeButton === 'multipleplayers' ? 'blue' : ''}`}>Many Players</button></li>
										<li><button onClick={() => this.setState({activeButton: 'oneplayer'})} className={`btn fill ${this.state.activeButton === 'oneplayer' ? 'blue' : ''}`}>One Player</button></li>
									</ul>
								</div>
							</div>
                            <section className="default">

								{this.state.activeButton === 'oneplayer' && <OnePlayerEntry addEntry={this.addEntry}/>}
								{this.state.activeButton === 'multipleplayers' && <MultiplePlayersEntry addEntry={this.addEntry} />}

								<table className="data__table margin-top">
									<colgroup>
										<col className="col_date"/>
										<col className="col_player-name"/>
										<col className="col_score"/>
										<col className="col_method-small"/>
										<col className=""/>
										<col className="col_tee"/>
										<col className="col_min"/>
										<col className="col_min"/>
										<col className="col_min"/>
										<col className="col_min"/>
									</colgroup>
									<thead>
									<tr>
										<th>Date Played</th>
										<th>Player Name</th>
										<th>Score Type</th>
										<th>Posting Method</th>
										<th>Course Played</th>
										<th>Tee</th>
										<th>Front 9</th>
										<th>Back 9</th>
										<th>Total</th>
										<th></th>
									</tr>
									</thead>
									<tbody>

									{this.state.entries.length === 0 && <tr><td colSpan="100%">No records to display</td></tr>}
									{this.state.entries.length > 0 && this.state.entries.map(entry => {
										return (
											<tr key={entry.id}>
												<td>{entry.played_at}</td>
												<td>{entry.firstName} {entry.lastName} [{entry.golfer_id}]</td>
												<td>{entry.score_type_display_full}</td>
												<td>{entry.number_of_holes_played}</td>
												<td>{entry.course_display_value}</td>
												<td>{entry.tee_name}</td>
												<td style={{textAlign: 'center'}}>{entry.front > 0 ? entry.front : ''}</td>
												<td style={{textAlign: 'center'}}>{entry.back > 0 ? entry.back : ''}</td>
												<td style={{textAlign: 'center'}}>{entry.total}</td>
												<td><button onClick={(e) => this.showDeleteConfirmationModal(e, entry.id, entry.golfer_id, entry.score_day_order, entry.played_at)} href="#">Delete</button></td>
											</tr>
										)
									})}

									</tbody>
								</table>

                            </section>
							<ConfirmationModal
								openModal={this.state.deleteScoreModalIsOpen}
								onConfirmAction={() => this.deleteEntry()}
								onCancelAction={() => {
									this.setState({
										deleteScoreModalIsOpen: false,
										scoreToDelete: null
									})
								}}
								closeModal={() => {
									this.setState({
										deleteScoreModalIsOpen: false,
										scoreToDelete: null
									})
								}}
								question={"Are you sure you want to delete this score?"}
								cancelLabel={"No"}
								confirmLabel={"Yes"}
							/>
                        </div>
                    </main>

                <Footer />
            </Fragment>
		);
	}
}
