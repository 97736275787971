import React, { useState, useEffect } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { useDispatch } from "react-redux";
import { SelectInput } from "../../../../inputs/Select";
import { InputField } from "../../../../inputs/InputField";
import { addAlert } from "../../../shared/actions"
import Spinner from "./Spinner";
import { configTemplatesOptions } from "./utils";
import { FederationAccount } from "../../../../services/api/federationAccount";
import { isEmailAddress, isNumber, required } from "../../../../inputs/validations";

let TestForm = props => {
  const dispatch = useDispatch();
  const sendgridAccountOptions = [{"value": "USGA Team", "label": "USGA Newsletters"}, {"value": "Handicap Team", "label": "USGA Handicap Newsletters"}];
  
  let [accountName, setAccountName] = useState('USGA Team');
  let [templates, setTemplates] = useState(null);
  let [templatesError, setTemplatesError] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  
  let templatesOptions = configTemplatesOptions(templates);
  
  let hasTemplates = isLoading || (templatesOptions && templatesOptions.length > 0);

  let isInvalid = () => {
    return props.invalid || props.submitting;
  }
  
  // load templates when sendgrid acount changes
  useEffect(() => {
    props.dispatch(change('federationNewsletterTest', 'templateName', null));
    loadTemplates(accountName);
  }, [accountName])
  
  const loadTemplates = (sendgridAccountName) => {
    setIsLoading(true);
    FederationAccount.getNewsletterTemplates({sendgrid_account: sendgridAccountName})
    .then(res => {
      if (res.Templates) {
        if (Array.isArray(res.Templates.Template))
         setTemplates(res.Templates.Template);
       else
         setTemplates([res.Templates.Template]);
      }

      // The backend sends an empty string instead of an empty list
      if (res.Templates === "") {
        setTemplates([]);
      }

      if (res.Errors) {
        setTemplatesError(true);
        let alertAction = addAlert({
          type: "error",
          message: "SendGrid failed to return the templates. Please refresh the page!"
        });
        dispatch(alertAction);  
      }
      setIsLoading(false);
    })
    .catch(err => {
      console.log(err);
      setIsLoading(false);
    });
  }
  
  // Download the templates. This effect will run only once.
  useEffect(() => {

  }, []);
  
  return (
    <form onSubmit={props.handleSubmit(props.onSubmit)}>
      <div className="row">
        <div className="col">
          { !hasTemplates && !templatesError &&  <a href="https://sendgrid.com" style={{color: '#00365f'}} target="_blank" rel="noreferrer">Click here to create SendGrid templates!</a> }
        </div>
      </div>
      <div className="row">
        <div className="col is-1-of-5">
          <label htmlFor="sendgridAccount">SendGrid Account <span>*</span></label>
          <Field
            name={'sendgridAccount'}
            component={SelectInput}
            className="react-select-container"
            classNamePrefix="react-select"
            options={ sendgridAccountOptions }
            isSearchable={false}
            isMulti={false}
            onChange={ (value) => setAccountName(value.value)}
          />
        </div>
        <div className="col is-1-of-4">
          <label htmlFor="templateName">Template Name <span>*</span>{isLoading && !templatesError  ? <span><Spinner className="lds-ring test"/></span> : null}</label>
          <Field
            name={'templateName'}
            component={SelectInput}
            className="react-select-container"
            classNamePrefix="react-select"
            options={ templatesOptions }
            isSearchable={false}
            isMulti={false}
            disabled = { templatesError }
          />
        </div>
        <div className="col is-1-of-6">
          <label htmlFor="ghinNumber">GHIN # <span>*</span></label>
          <Field
            component={InputField}
            validate={[required, isNumber]}
            id="ghinNumber"
            name="ghinNumber"
            type="text"
          />
        </div>
        <div className="col is-1-of-4">
          <label htmlFor="emailAddress">Email Address <span>*</span></label>
          <Field
            component={InputField}
            validate={[required, isEmailAddress]}
            id="emailAddress"
            name="emailAddress"
            type="text"
          />
        </div>
        <div className="col is-1-of-8">
          <label htmlFor="sendButton" style={{visibility: "hidden"}}>Hidden</label>
          <button className="btn fill green" name="sendButton" type="submit" disabled={isInvalid()}>Send Test Email</button>
        </div>
      </div>
    </form>
  )
}

TestForm = reduxForm({
  // a unique name for the form
  form: 'federationNewsletterTest'
})(TestForm)

export default TestForm
