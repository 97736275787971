import React, {Component, Fragment} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {DatePickerInput} from "../../inputs/DatePicker";
import {RadioButtons} from "../../inputs/RadioButtons";
import {InputField} from "../../inputs/InputField";
import CourseRatingSystemService from "../../services/api/courseRatingSystem";
import debounce from "debounce-promise";
import {AsyncSelectInput} from "../../inputs/AsyncSelectInput";
import {SelectInput} from "../../inputs/Select";
import moment from "moment";
import ScoreService from "../../services/api/score";
import {
	customRequired,
	isNumber
} from "../../inputs/validations";
import ConfirmationModalTypeB from "../Modals/ConfirmationModalTypeB";
import GolferService from "../../services/api/golfer";
import flatten from 'lodash.flatten'
import {orderTees} from "../../services/shared/teesFilter";


class EditTotalScoreForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			courses: !this.props.selectedTee && this.props.homeCourses.length > 0 &&  (this.props.score.number_of_holes === 9 ?
				this.props.homeCourses.filter(hc => hc.tee_set_side === "F9" || hc.tee_set_side === "B9") : this.props.homeCourses.filter(hc => hc.tee_set_side === "All18")),
      home_courses: flatten(this.props.homeCourses).map(el => el.courseId),
      auto_score_type: false,
      teeSetsOptions: this.props.score.number_of_holes === 9 ? this.props.teeSetsOptions.filter(ts=> ts.tee_set_side === "F9" || ts.tee_set_side === "B9") : this.props.teeSetsOptions.filter(ts => ts.tee_set_side === "All18"),
			teeOptions: [],
			golfer: this.props.golfer,
			overrideModal: false,
			errors: [],
			confirmScoreDifferentialsRemoval: false
		};
		this.onSubmit = this.onSubmit.bind(this);
		this.getCoursesOptions = this.getCoursesOptions.bind(this);
		this.getHomeCourses = this.getHomeCourses.bind(this);
		this.setErrors = this.setErrors.bind(this);
	}

	componentDidMount() {
		this.initializeScoreFromProps();
	}

	initializeScoreFromProps() {
		if (this.props.score) {
			let score = JSON.parse(JSON.stringify(this.props.score));
			score.played_at = new Date(moment(score.played_at, 'YYYY-MM-DD'));
			if (!this.props.selectedTee) {
				score.course_tee_lookup_method = 'home_courses';
			} else {
				score.course_tee_lookup_method = 'search_courses';
			}

			if (this.props.selectedTee) {
				score.course_name = this.props.selectedCourse;
				score.tee_set_id = this.props.selectedTee;
			} else {
				score.course_name = this.props.selectedCourse;
			}
			this.props.initialize(score);

		}
	}


	getHomeCourses() {
		if (this.state.nrHoles === '9') {
			this.setState({courses: this.props.homeCourses.filter(hc => hc.tee_set_side === "F9" || hc.tee_set_side === "B9")})
		} else {
			this.setState({courses: this.props.homeCourses.filter(hc => hc.tee_set_side === "All18")})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.score !== prevProps.score) {
			this.initializeScoreFromProps();
		} else {
			if (prevProps.score_type && this.props.score_type !== prevProps.score_type && (this.props.score.course_id && this.props.score.tee_set_id)) {
        if (!this.state.auto_score_type) {
          if (this.props.score_type === 'H' && prevProps.score_type !== "T") {
            this.props.change('course_tee_lookup_method', 'home_courses');
            this.getHomeCourses();
          }
          if(this.props.score_type === "A" && prevProps.score_type !== "T"){
            this.setState({
              courses: [],
              errors: []
            });
            this.props.change('course_tee_lookup_method', 'search_courses');
          }
          if ((this.props.score_type === 'A' && prevProps.score_type === 'H') ||
          (this.props.score_type === 'H' && prevProps.score_type === 'A')) {
            this.props.change('course_name', null);
            this.props.change('tee_set_id', null);
            this.props.untouch('course_name');
            this.props.untouch('tee_set_id');
          }
        }
        else {
          this.setState({
            auto_score_type: false
          });
        }
			}
			if (prevProps.course_tee_lookup_method && this.props.course_tee_lookup_method !== prevProps.course_tee_lookup_method) {
				if (this.props.course_tee_lookup_method === 'home_courses') {
					this.props.change('course_name', null);
					this.props.untouch('course_name');
					this.getHomeCourses();
				} else {
					this.props.change('course_name', null);
					this.props.change('tee_set_id', null);
					this.props.untouch('tee_set_id');
					this.props.untouch('course_name');
					this.setState({
						courses: [],
						errors: []
					})
				}
			}
		}

	}

	getCoursesOptions(searchTerm, callback) {
		if (searchTerm && searchTerm.length > 2) {
			return CourseRatingSystemService.searchCourses(searchTerm)
				.then(response => {
					let options = response.courses.map(c => {
						return {
							value: c.CourseID,
							label: `${c.FullName} ${c.City && c.State ? `(${c.City}, ${c.State})` : ''}`,
							unparsedLabel: c.FullName
						}
					});
					this.setState({
						courses: options
					});
					callback(options)
				})
				.catch(err => {

				})
		}
		return new Promise(resolve => {
			resolve([])
		})
	}

	getCourseDetails(courseId, courseName, homeTee, dontIntialize) {
    if (this.state.home_courses.includes(courseId)) {
      if (this.props.score_type === 'A') {
        this.props.change('score_type', 'H');
        this.setState({
          auto_score_type: true
        });
     }
    }
    else {
      if (this.props.score_type === 'H') {
        this.props.change('score_type', 'A');
        this.setState({
          auto_score_type: true
        });
     }
    }
		CourseRatingSystemService.getCourseDetails(courseId)
			.then(response => {
				let filteredTS = this.parseCourse(response);
				this.props.change('tee_set_id', null);
				this.setState({
					teeSetsOptions: this.props.number_of_holes === 9 ? filteredTS.filter(ts=> ts.tee_set_side === "F9" || ts.tee_set_side === "B9") : filteredTS.filter(ts => ts.tee_set_side === "All18"),
				})
			})
			.catch(err => {
				if (err.data && err.data.errors && err.data.errors.course_id === "is invalid") {
					this.props.change('slope', this.props.score.slope_rating);
					this.props.change('cr', parseFloat(this.props.score.course_rating));
					this.props.change('tee_set_id', {label: this.props.score.tee_name, value: -1});
					this.props.change('course_name_label', this.props.score.course_name);
					this.props.change('course_name', {label: courseName, value: null});
					this.props.untouch('course_name');
				}
			})
	}

	parseCourse(response, type) {
		let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.props.golfer.gender).map(ts => {
			let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
			let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
			let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
			return {label: ts.TeeSetRatingName,
				holes: ts.Holes,
				value: ts.TeeSetRatingId,
				courseId: response.CourseId,
				ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
				ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
				ratingBack: back &&`${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`,
				totalCourseRating: total && total.CourseRating,
				frontCourseRating: front && front.CourseRating,
				backCourseRating:  back && back.CourseRating,
				totalSlopeRating: total && total.SlopeRating,
				frontSlopeRating: front && front.SlopeRating,
				backSlopeRating:  back && back.SlopeRating,
				ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
			}
		});
		orderTees(filteredTS);
		filteredTS = filteredTS.reduce((res, current) => {
			let frontPar = current.holes.reduce((acc, h, index) => {
				if (index < 9) return acc + h.Par;
				return acc + 0;
			}, 0);
			let backPar = current.holes.reduce((acc, h, index) => {
				if (index >= 9) return acc + h.Par;
				return acc + 0;
			}, 0);
			let totalPar = current.holes.reduce((acc, h, index) => acc + h.Par, 0);
			let label = ''
			let front = {}
			if (current.ratingFront !== undefined) {
				label = current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`;
				front = {
					label: label,
					unparsedLabel: current.label + ' F9',
					value: current.value,
					holes: current.holes,
					courseId: current.courseId,
					teeSetID: current.value,
					tee_set_side: 'F9',
					course_rating: current.frontCourseRating,
					slope_rating: current.frontSlopeRating,
					teeName: current.teeName,
					par: frontPar,
				};
			}

			let back = {}
			if (current.ratingBack !== undefined) {
				label = current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`;
				back = {
					label: label,
					unparsedLabel: current.label + ' B9',
					value: current.value,
					holes: current.holes,
					courseId: current.courseId,
					teeSetID: current.value,
					tee_set_side: 'B9',
					course_rating: current.backCourseRating,
					slope_rating: current.backSlopeRating,
					teeName: current.teeName,
					par: backPar,
				};
			}
			let total = {}
			if (current.ratingTotal !== undefined) {
				label = current.label + "\n" + `(${current.ratingTotal} / ${totalPar})`;
				total = {
					label: label,
					value: current.value,
					holes: current.holes,
					courseId: current.courseId,
					teeSetID: current.value,
					tee_set_side: 'All18',
					course_rating: current.frontCourseRating,
					slope_rating: current.frontSlopeRating,
					teeName: current.teeName,
					par: totalPar,
				};
			}
			return res.concat([total, front, back])
		}, []);
		return filteredTS;
	}

	onSubmit(values) {
		let v = {
			golfer_id: this.props.score.golfer_id,
			course_id: this.props.course_tee_lookup_method === 'home_courses' ? values.course_name.courseId : values.course_name.value,
			course_name: values.course_name.label,
			course_rating: values.course_rating,
			slope_rating: values.slope_rating,
			tee_set_id: this.props.course_tee_lookup_method === 'home_courses' ? values.course_name.teeSetID : this.props.tee_set_id.value,
			tee_set_side: this.props.course_tee_lookup_method === 'home_courses' ? values.course_name.tee_set_side : this.props.tee_set_id.tee_set_side,
			played_at: moment(values.played_at).format("YYYY-MM-DD"),
			score_type: !this.props.emptyCombined ? this.props.score_type : "C",
			number_of_holes: this.props.number_of_holes,
			gender: this.state.golfer.gender,
			adjusted_gross_score: values.adjusted_gross_score,
			front9_adjusted: values.front9_adjusted,
			back9_adjusted: values.back9_adjusted
		};

		if (this.props.combined_score_id) {
			v['combined_score_id'] = this.props.combined_score_id;
			v['score_index'] = this.props.selectedScoreIndex;
			v['combined_9_hole_score'] = this.props.combined_9_hole_score;
		}
		return ScoreService.editScore({score: {...v}}, this.props.score.id)
			.then(response => {
				if (response['errors']) {
          this.setErrors({ "data": response});
        } else {
					this.props.closeModal(true);
	        this.props.setOutdatedScoreModalOpen(!response.score.is_recent);
				}
			})
			.catch(err => {
				console.log(err);
				let errors = [];
				if (err && err.data && err.data.errors) {
					Object.keys(err.data.errors).forEach(error => {
						if (err.data.errors[error].length > 1) {
							err.data.errors[error].forEach(errorChild => {
								errors.push(error.replace(/_/g, ' ') + ' ' + errorChild);
							})
						} else {
							errors.push(err.data.errors[error][0]);
						}
					});
				}
				this.setState({errors})
			});
	}

	setErrors(err) {
		let errors = [];
		if(err.data.errors) {
			Object.keys(err.data.errors).map(error => {
				const msg = err.data.errors[error]
				const display_msg = msg.includes('No TeeSet found') ? 'No TeeSet found' : msg
				errors.push(display_msg);
				return null;
			});
		}
		this.setState({errors});
	}

	removeScoreDifferentials() {
		GolferService.removeScoreDifferentials(this.props.score.golfer_id, this.props.score.id)
			.then(() => {
				this.props.closeModal(true, true);
			})
			.catch(err => {
				console.log(err);
			});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props,
			self = this;
		if (!this.props.score) return null;

		return (
			<form onSubmit={handleSubmit(this.onSubmit)}>

				<div className="row">
					<div className="col is-1-of-4">
						<label>Date Played <span>*</span></label>
						<Field
							maxDate={new Date()}
							minDate={new Date('1/1/1990')}
							showMonthDropdown
							showYearDropdown
							component={DatePickerInput}
							selected={this.state.startDate}
							onChange={this.handleChange}
							dropdownMode="select"
							name={'played_at'}
						/>
					</div>
					<div className="col is-1-of-2">
						<label>Score Type</label>
						<Field component={RadioButtons} parentClassName={'custom-radio__container'}
							   fieldName={'score_type'} options={
							[
								{id: "home", label: "Home", value: "H", isDisabled: this.props.migrated_combined || false},
								{id: "away", label: "Away", value: "A", isDisabled: this.props.migrated_combined || false},
								{
									id: "tournament",
									label: "Competition",
									value: "T",
                  isDisabled: this.props.migrated_combined || false
								}
							]
						}/>
					</div>
					<div className="col is-1-of-4">
						<label>Holes</label>
						<Field component={RadioButtons} parentClassName={'custom-radio__container'}
							   fieldName={'number_of_holes'} options={
							[
								{id: "18", label: "18", value: 18, isNumber: true, isDisabled: true},
								{id: "9", label: "9", value: 9, isNumber: true, isDisabled: true},
							]
						}/>
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2 jc-fs">
						<label>Choose Course / Tee Lookup Method</label>
						<Field component={RadioButtons} parentClassName={'radio__container'}
							   fieldName={'course_tee_lookup_method'} options={
							[
								{
									id: "home_coursess-tees",
									label: "Home Courses/Tees",
									value: 'home_courses',
									className: 'simple__radio',
									isDisabled: this.props.migrated_combined || false
								},
								{
									id: "course-tee-search",
									label: "Course/Tee Search",
									value: 'search_courses',
									className: 'simple__radio',
									isDisabled: this.props.migrated_combined || false
								},
							]
						}/>
					</div>
					<div className={`col is-${this.props.course_tee_lookup_method === 'home_courses' ? '2' : '1'}-of-${this.props.course_tee_lookup_method === 'home_courses' ? '2' : '4'}`}>

						<Fragment>
							<label
								htmlFor="course-played">Course{this.props.course_tee_lookup_method === 'home_courses' ? '/Tee' : ''} Played <span>*</span></label>
							<Field name={'course_name'}
								   id={'course_name'}
								   hideErrorMessages={true}
								   validate={[customRequired({fieldName: 'Course Played'})]}
								   defaultOptions={this.state.courses}
								   loadOptions={this.props.course_tee_lookup_method === 'home_courses' ? null : debounce(self.getCoursesOptions, 1000)}
								   component={AsyncSelectInput}
								   onChange={(event, newValue, prevValue) => {
									   if (newValue) {
										   if (this.props.course_tee_lookup_method === 'home_courses') {
											   this.getCourseDetails(newValue.courseId, newValue.label, newValue.teeSetID);
										   } else {
											   this.getCourseDetails(newValue.value, newValue.label, null, true);
										   }
										   this.props.change('course_id', newValue);

									   } else {
										   this.props.change('tee_set_id', null);
										   this.setState({
											   teeSetsOptions: []
										   });
                       if (this.props.score_type === 'H') {
                        this.setState({
                          auto_score_type: true
                        });
                        this.props.change('score_type', 'A');
                      }
									   }
								   }}
								   className="react-select-container"
								   classNamePrefix="react-select"
                   disabled={this.props.migrated_combined || false}
							/>
						</Fragment>

					</div>


					{this.props.course_tee_lookup_method === 'search_courses' &&
					<div className="col is-1-of-4 jc-fs">
						<label>Tee <span>*</span></label>
						<Field className="react-select-container"
							   component={SelectInput}
							   name={'tee_set_id'}
							   classNamePrefix="react-select"
							   disabled={this.props.migrated_combined || this.props.emptyCombined || false}
							   options={this.state.teeSetsOptions}/>
					</div>
					}
				</div>

				<div className="row">
					{this.props.number_of_holes === 18 &&
					<div className="col is-1-of-9 push-right">
						<label htmlFor="front9">Front 9</label>
						<Field type={'text'} component={InputField}
							   name={'front9_adjusted'}
							   disabled={this.props.number_of_holes === 18 && !this.props.front9_adjusted && !this.props.back9_adjusted  && this.props.adjusted_gross_score}
							   onChange={(event, newValue, prevValue) => {
								   if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
									   let backValue = parseInt(this.props.back9_adjusted) || 0;
									   this.props.change('adjusted_gross_score', backValue);
								   }
								   if (newValue.length === 1) {
									   if (parseInt(newValue) === 0) {
										   event.preventDefault();
										   return;
									   }

									   let backValue = parseInt(this.props.back9_adjusted) || 0;
									   this.props.change('adjusted_gross_score', backValue + parseInt(newValue));
								   }
								   if (newValue.length > 2) {
									   event.preventDefault();
									   return;
								   }
								   if(newValue.length === 2) {
									   let backValue = parseInt(this.props.back9_adjusted) || 0;
									   this.props.change('adjusted_gross_score', backValue + parseInt(newValue));
									   document.getElementsByClassName('back9_adjusted')[0].focus();
								   }
							   }}/>
					</div>
					}
					{this.props.number_of_holes === 18 &&
					<div className="col is-1-of-9">
						<label htmlFor="back9">Back 9</label>
						<Field type={'text'} component={InputField}
							   name={'back9_adjusted'}
							   className={'back9_adjusted'}
							   disabled={this.props.number_of_holes === 18 && !this.props.back9_adjusted && !this.props.front9_adjusted && this.props.adjusted_gross_score}
							   onChange={(event, newValue, prevValue) => {
								   if (prevValue && (prevValue.length === 1 || prevValue.length === 2) && newValue.length === 0) {
									   let frontValue = parseInt(this.props.front9_adjusted) || 0;
									   this.props.change('adjusted_gross_score', frontValue);
								   }
								   if (newValue.length === 1) {
									   if (parseInt(newValue) === 0) {
										   event.preventDefault();
										   return;
									   }
									   let frontValue = parseInt(this.props.front9_adjusted) || 0;
									   this.props.change('adjusted_gross_score', frontValue + parseInt(newValue));
								   }
								   if (newValue.length > 2) {
									   event.preventDefault();
								   }
								   if(newValue.length === 2) {
									   let frontValue = parseInt(this.props.front9_adjusted) || 0;
									   this.props.change('adjusted_gross_score', frontValue + parseInt(newValue));
								   }
							   }}/>
					</div>
					}
					<div
						className={`col is-1-of-9 ${this.props.number_of_holes !== 18 ? 'push-right' : ''}`}>
						<label
							htmlFor="adjusted_gross_score">Total {this.props.number_of_holes === 18 ? 18 : 9}<span>*</span></label>
						<Field hideErrorMessages={true}
							   validate={[customRequired({fieldName: 'Total Score'}), isNumber]}
							   type={'text'} component={InputField}
							   disabled={this.props.back9_adjusted || this.props.front9_adjusted}
							   name={'adjusted_gross_score'}/>
					</div>
				</div>


				{this.props.formValuesErrors && this.props.formFields &&
				<p>
					{Object.entries(this.props.formValuesErrors).map(([key, error]) => {
						if (this.props.formFields[key] && this.props.formFields[key].touched) {
							return (<span className={'validation__message is-error'}>{error}</span>);
						}
						return null;
					})}
				</p>
				}
				{this.state.errors.length > 0 &&
				<p>
					{this.state.errors.map((error,i)=> {
						return (<span key={i} className={'validation__message is-error'}>{error.charAt(0).toUpperCase() + error.substring(1)}</span>);
					})}
				</p>
				}
				<div className="row align-right">
					{this.props.score.exceptional &&
					<div className="col is-1-of-4 ai-fe jc-c" style={{height:'40px'}}>
						{this.props.overriden ?
							'ESR Adjustment Overriden'
							:
							<button onClick={() => this.setState({overrideModal: true})} type={'button'} className="btn fill gray">Override ESR Adjustments</button>
						}
					</div>
					}

					{this.props.score.committee_master_score && <div className="col is-2-of-6">
						<button onClick={() => this.setState({confirmScoreDifferentialsRemoval: true})}
								type={'button'} className="btn fill gray">Remove Score Differentials
						</button>
					</div>}

					<div className="col is-1-of-6">
						<button onClick={() => this.props.closeModal()}
								type={'button'} className="btn fill gray">Close
						</button>
					</div>



					<div className="col is-1-of-6">
						<button disabled={pristine || submitting} type={'submit'} ref={'submit'}
								className="btn fill blue">Edit Score
						</button>
					</div>

				</div>
				{this.state.overrideModal &&
				<ConfirmationModalTypeB openModal={this.state.overrideModal}
										wideClass={"narrow"}
										onConfirmAction={() => {this.props.overrideScore(); this.setState({overrideModal: false}); }}
										modalIcon={"error_outline"}
										onCancelAction={() => {
											this.setState({overrideModal: false})
										}}
										closeModal={() => {
											this.setState({overrideModal: false})
										}}
										infoText={["Please confirm that you would like to override the ESR adjustments of this score"]}
										cancelLabel={"Close"}
										confirmLabel={"Confirm"}/>
				}

				{this.state.confirmScoreDifferentialsRemoval &&
				<ConfirmationModalTypeB openModal={true}
										wideClass={"narrow"}
										onConfirmAction={() => {this.removeScoreDifferentials(); this.setState({confirmScoreDifferentialsRemoval: false}); }}
										modalIcon={"error_outline"}
										onCancelAction={() => {
											this.setState({confirmScoreDifferentialsRemoval: false})
										}}
										closeModal={() => {
											this.setState({confirmScoreDifferentialsRemoval: false})
										}}
										infoText={["Please confirm that you would like to remove the score differentials adjustments."]}
										cancelLabel={"Close"}
										confirmLabel={"Confirm"}/>
				}

			</form>
		);
	}
}

EditTotalScoreForm = reduxForm({
	form: 'editScoreForm',
	destroyOnUnmount: true,
	touchOnChange: true
})(EditTotalScoreForm);

function mapStateToProps(state) {
	const selector = formValueSelector('editScoreForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		score_type: selector(state, 'score_type'),
		number_of_holes: selector(state, 'number_of_holes'),
		course_tee_lookup_method: selector(state, 'course_tee_lookup_method'),
		course_name: selector(state, 'course_name'),
		tee_set_id: selector(state, 'tee_set_id'),
		front9_adjusted: selector(state, 'front9_adjusted'),
		back9_adjusted: selector(state, 'back9_adjusted'),
		adjusted_gross_score: selector(state, 'adjusted_gross_score'),
		formValuesErrors: state.form['editScoreForm'] ? state.form['editScoreForm'].syncErrors : {},
		formFields: state.form['editScoreForm'] ? state.form['editScoreForm'].fields : {},
		dynamicSelector: (v) => selector(state, v)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditTotalScoreForm)));
