import React, {Component, Fragment} from 'react';

export default class AuditHistsoryForm extends Component {

	render() {
		return (
            <Fragment>

                <div className="row">
                    <div className="col">
                        <table className="data__table">
                            <colgroup>
                                <col className="col_pico"/>
                                <col className="col_date"/>
                                <col className="col_score"/>
                                <col className="col_slope"/>
                                <col className="col_course-name"/>
                                <col className="col_date_edited"/>
                                <col className=""/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="table-align-center">...</th>
                                    <th>Date Played</th>
                                    <th>Score</th>
                                    <th>C.R./Slope</th>
                                    <th>Course Name / Tee</th>
                                    <th>Date Edited</th>
                                    <th>Edited By</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-align-center">H</td>
                                    <td className="table-align-center">07/25/2018</td>
                                    <td className="table-align-center">78</td>
                                    <td className="table-align-center">73.2/132</td>
                                    <td>GLENLAKES GOLF CLUB - VISTA-DU</td>
                                    <td>08-08-2018 03:35:37 PM</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-1-of-4 push-right">
                        <button type={'button'} className="btn fill gray">Close</button>
                    </div>
                </div>

            </Fragment>
		);
	}
}
