import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { addAlert } from "../shared/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { maxLength, required } from "../../inputs/validations";
import { Textarea } from "../../inputs/Textarea";
import AssociationService from "../../services/api/association";

class ReviewNotificationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			approved: true,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		 var approved = this.props.selectedNotification.status === 'rejected' ? false : true
		 var notes = this.props.selectedNotification.status === 'rejected' ? null : this.props.selectedNotification.notes

		 this.props.initialize({approved: approved, notes: notes});
		 this.setState({approved: approved, notes: notes});
		 this.props.onRef(this);
	}

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));

		data.user_id = this.props.user.user_id;
		data.review_action = this.state.approved ? "approve" : "reject";

		AssociationService.reviewPushNotification(
			this.props.associationId,
			this.props.selectedNotification.id,
			data
		)
			.then(() => {
				this.props.addAlert({type: 'success', message: 'Push Notification has been successfully reviewed !'});
				console.log("Push Notification was reviewed.");
				this.props.close();
				this.props.setTableRefresh(true);
			})
			.catch((err) => {
				console.error(err);
			});
	}

	render() {
		const { handleSubmit, invalid } = this.props;
		const noteMaxLength = 150;
		const required_validation = this.state.approved ? null : required;

		return (
			<Fragment>
				<form
					autoComplete="off"
					onSubmit={handleSubmit(this.onSubmit)}
					ref={"form"}
				>
					<div className="row">
						<div className="col is-1-of-2">
							<span className="label">Review</span>
							<div className="radio__container">
								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												approved: !prevState.approved,
											};
										});
									}}
									checked={this.state.approved}
									id="dac-yes"
									value={true}
									name={"approved"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="dac-yes">Approve</label>

								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												approved: !prevState.approved,
											};
										});
									}}
									checked={!this.state.approved}
									id="dac-no"
									value={false}
									name={"approved"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="dac-no">Reject</label>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="note">
								Notes: {!this.state.approved && <span>*</span>}
							</label>
						</div>
						<div
							className="col is-2-of-2"
							style={{ textAlign: "right" }}
						>
							<label
								className={
									this.state.count > noteMaxLength
										? "length-error"
										: "blue"
								}
								htmlFor="note"
							>
								{this.state.count} / {noteMaxLength}
							</label>
						</div>
					</div>
					<div className="f-row">
						<Field
							component={Textarea}
							className={"large"}
							validate={
								([
									maxLength([
										100,
										"Value too long (max 150 characters)",
									]),
								],
								required_validation)
							}
							name="notes"
							id="notes"
							type="text"
							onChange={(e) =>
								this.setState({ count: e.target.value.length })
							}
						/>
					</div>
					<div className="row">
						<div className="col is-1-of-2">
							<button
								type={"button"}
								className="btn fill gray"
								onClick={() => this.props.close(null)}
							>
								Cancel
							</button>
						</div>
						<div className="col is-1-of-2">
							<button
								disabled={invalid}
								type={"submit"}
								ref={"submit"}
								className="btn fill blue"
							>
								Save
							</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

ReviewNotificationForm = reduxForm({
	form: "editNotificationForm",
	destroyOnUnmount: true,
})(ReviewNotificationForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withKeyPress(ReviewNotificationForm));
