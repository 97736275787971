import { Fragment, useEffect, useState, useRef } from "react";
import GhinTable from "../../../shared/GhinTable";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAlert } from "../../../shared/actions";
import Modal from "react-modal";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import RejectGolferModal from "../../../Modals/RejectGolferModal";
import JoinAndRenewServices from "../../../../services/api/joinAndRenew";
import ReactTooltip from "react-tooltip";

function PendingMembers(props) {

  const [renewingMembers, setRenewingMembers] = useState(true);
  const [lapsedMembers, setLapsedMembers] = useState(true);
  const [newMembers, setNewMembers] = useState(true);
  const [pendingFilter, setPendingFilter] = useState(true);
  const [transactionFailedMembers, setTransactionFailedMembers] = useState(false);
  const [rejectedMembers, setRejectedMembers] = useState(false);

  const [loading, setLoading] = useState(false);
  const [pendingGolfers, setPendingGolfers] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);

  const [selectedRows, setSelectedRows] = useState({});
  const [unselectedRows, setUnselectedRows] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const [approveModalOpened, setApproveModalOpened] = useState(false);
  const [rejectModalOpened, setRejectModalOpened] = useState(false);
  const [selectedGolfer, setSelectedGolfer] = useState({});
  const [exportDisabled, setExportDisabled] = useState(false);

  const tooltipRef = useRef(null);

  const getPendingPembers = (pageChanged = false) => {
    setLoading(true);

    const filters = {
      renewing_members: renewingMembers,
      lapsed_members: lapsedMembers,
      new_members: newMembers,
      transaction_failed: transactionFailedMembers,
      rejected: rejectedMembers,
      pending: pendingFilter,
      page: page,
      per_page: perPage
    };

    JoinAndRenewServices.getPendingMembers(props.club.id, filters)
      .then(res => {
        setPendingGolfers(res.pending_golfers.map(e => {
          if (selectAll) {
            return { ...e, selected: !unselectedRows[e.id] }
          }
          return { ...e, selected: selectedRows[e.id] }
        }))
        setPerPage(parseInt(res["per-page"], 10));
        setPages(parseInt(res["total-pages"], 10));
        setTotal(parseInt(res['total-count'], 10));
        if (!pageChanged) setPage(1);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    getPendingPembers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.club, renewingMembers, lapsedMembers, newMembers, pendingFilter, transactionFailedMembers, rejectedMembers, perPage])

  useEffect(() => {
    getPendingPembers(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.club, page])

  const columns = [
    {
      accessor: 'id',
      show: false
    },
    {
      Header: 'Date',
      colClassName: 'col_username',
      accessor: 'date'
    },
    {
      Header: 'Golfer Name',
      colClassName: 'col_ghin',
      accessor: 'full_name'
    },
    {
      Header: 'GHIN #',
      colClassName: 'col_ghin_number',
      accessor: 'golfer_id',
      Cell: props => props.value || '-'
    },
    {
      Header: 'MC - Club Membership Fee Name',
      colClassName: 'col_user-name',
      Cell: props => `${props.original.golf_association_membership_name} - ${props.original.club_membership_fee_name}`
    },
    {
      Header: 'Zip Code',
      colClassName: 'col_status',
      accessor: 'zip_code',
      Cell: props => props.value || '-'
    },
    {
      Header: 'Age',
      colClassName: 'col_pico',
      accessor: 'age',
      Cell: props => props.value || '-'
    },
    {
      Header: 'Amount',
      colClassName: 'col_min',
      accessor: 'total_amount',
      Cell: props => `$${props.value.toFixed(2)}`
    },
    {
      colClassName: 'col_username',
      Cell: data => {
        return (
          data.original.rejected === true || data.original.transaction_failed === true ?
          (
            <Fragment>
              <div data-tip data-for={`reason-${data.original.id}`} data-event='click' style={{textAlign: 'center', textDecoration: 'underline', cursor: 'pointer'}}>
                {data.original.rejected ? 'Rejected' : 'Transaction Failed'}
              </div>
              <ReactTooltip
                id={`reason-${data.original.id}`}
                globalEventOff='click'
                clickable={true}
                place='bottom'
                effect='float'
                ref={tooltipRef}
              >
                <button 
                  type="button"
                  onClick={() => {
                    tooltipRef.current.tooltipRef = null;
                    tooltipRef.current.hideTooltip();
                  }}
                  className="exit-button top-right"
                >
                  <span className="exit-icon">X</span>
                </button>
                <ul style={{padding: '15px'}}>
                  {
                    data.original.rejected &&
                    <li>Rejected by: {data.original.rejected_by}</li>
                  }
                  <li>Date: {moment(data.original.updated_at).format("MM/DD/YYYY")}</li>
                  <li style={{maxWidth: '200px', whiteSpace: 'normal'}}>Reason: {data.original.rejected ? data.original.reject_reason : data.original.transaction_failed_reason}</li>
                </ul>
              </ReactTooltip>
            </Fragment>
          )
          :
          <Fragment>
          <span>
            <button
              className="btn fill green small_table_button"
              onClick={() => {
                setSelectedGolfer(data.original);
                setApproveModalOpened(true);
              }}
            >
              Approve
            </button>
            <button
              className="btn fill gray small_table_button"
              onClick={() => {
                setSelectedGolfer(data.original);
                setRejectModalOpened(true);
              }}
            >
              Reject
            </button>
          </span>
        </Fragment>
        );
      },
      show: props.canEditClub
    }
  ];

  const approve = (id) => {
    JoinAndRenewServices.approveMember(props.club.id, id)
      .then(() => {
        getPendingPembers();
        props.reloadGolfersCount();
        setApproveModalOpened(false);
        props.addAlert({ type: 'success', message: 'Pending Golfer approved successfully.' })
      })
      .catch(err => {
        console.log(err);
        let firstError = 'Something went wrong';
        if (err.data.message)
          firstError = err.data.message;  
        else
          firstError = err.data.errors[Object.keys(err.data.errors)[0]][0];

        props.addAlert({ type: 'error', message: firstError })
        setApproveModalOpened(false);
        getPendingPembers();
        props.reloadGolfersCount();
      })
  }

  const reject = (id, data) => {
    JoinAndRenewServices.rejectMember(props.club.id, id, data)
      .then(() => {
        getPendingPembers();
        props.reloadGolfersCount();
        setRejectModalOpened(false);
        props.addAlert({ type: 'success', message: 'Pending Golfer rejected successfully.' })
      })
      .catch(err => {
        console.log(err);
        props.addAlert({ type: 'error', message: 'An error has ocurred when trying to reject the golfer.' })
        setRejectModalOpened(false);
      })
  }

  const exportToExcel = () => {
    setExportDisabled(true);

    const params = {
      renewing_members: renewingMembers,
      lapsed_members: lapsedMembers,
      new_members: newMembers,
      transaction_failed: transactionFailedMembers,
      rejected: rejectedMembers,
      select_all: selectAll
    };

    if (selectAll) {
      const unselectedMembers = []
      Object.keys(unselectedRows).forEach((key) => {
        if (unselectedRows[key] === true) {
          unselectedMembers.push(key)
        }
      })
      if (unselectedMembers.length !== 0)
        params.unselected_members = unselectedMembers.join(',')
    }
    else {
      const selectedMembers = []
      Object.keys(selectedRows).forEach((key) => {
        if (selectedRows[key] === true) {
          selectedMembers.push(key)
        }
      })
      if (selectedMembers.length !== 0)
        params.selected_members = selectedMembers.join(',');
    };

    JoinAndRenewServices.exportPendingMembersToExcel(props.club.id, params)
      .then(res => {
        window.open(res.url, "_blank")
      })
      .catch(err => {
        console.log(err);
      })
    
    setExportDisabled(false);
  }

  return (
    <Fragment>
      <div className="columns">
        <div className="row">
          <div className="col">
            <div className="panel">
              <div className="panel__head">
                Pending Members
              </div>
              <div className="panel__body">
                <div className="row">
                  <div className="col">
                    The following golfers have registered online and are awaiting approval. Once approved, they will be added or reinstated to the club.
                  </div>
                </div>
                <div className="row" style={{justifyContent: "center"}}>
                  <div className="table__legend" style={{display: "inline-flex"}}>
                    <div className="col auto">
                      <h5 className="checkboxes_title">Member Type</h5>
                      <ul className="table__legend__checkboxes">
                        <li>
                          <input
                            type="checkbox"
                            defaultChecked={renewingMembers}
                            name="renewing_members"
                            id="renewing_members"
                            onClick={e => setRenewingMembers(e.target.checked)}
                          />
                          <label htmlFor="renewing_members">Renewing</label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            defaultChecked={lapsedMembers}
                            name="lapsed_members"
                            id="lapsed_members"
                            onClick={e => setLapsedMembers(e.target.checked)}
                          />
                          <label htmlFor="lapsed_members">Lapsed</label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            defaultChecked={newMembers}
                            name="new_members"
                            id="new_members"
                            onClick={e => setNewMembers(e.target.checked)}
                          />
                          <label htmlFor="new_members">New</label>
                        </li>
                      </ul>
                    </div>
                    <div className="col auto">
                      <h5>&nbsp;</h5>
                      <ul className="table__legend__checkboxes">
                        <li style={{display: "flex", height: "40px",}}>
                          <div className="vertical_line"></div>
                        </li>
                      </ul>
                    </div>
                    <div className="col auto">
                      <h5 className="checkboxes_title">Pending Status</h5>
                      <ul className="table__legend__checkboxes">
                        <li>
                          <input
                            type="checkbox"
                            defaultChecked={pendingFilter}
                            name="pending_members"
                            id="pending_members"
                            onClick={e => setPendingFilter(e.target.checked)}
                          />
                          <label htmlFor="pending_members">Pending</label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            defaultChecked={rejectedMembers}
                            name="rejected_members"
                            id="rejected_members"
                            onClick={e => setRejectedMembers(e.target.checked)}
                          />
                          <label htmlFor="rejected_members">Rejected</label>
                        </li>
                        <li>
                          <input
                            type="checkbox"
                            defaultChecked={transactionFailedMembers}
                            name="transaction_failed_members"
                            id="transaction_failed_members"
                            onClick={e => setTransactionFailedMembers(e.target.checked)}
                          />
                          <label htmlFor="transaction_failed_members">Failed Transactions</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col auto">
                    <button
                      className="btn fill green"
                      onClick={() => exportToExcel()}
                      disabled={exportDisabled || total === 0}
                    >
                      Export to Excel
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <GhinTable
                      idKey={'id'}
                      columns={columns}
                      data={pendingGolfers}
                      manual
                      loading={loading}
                      sortable={false}
                      total={total}
                      pages={pages}
                      page={page - 1}
                      pageSize={perPage}
                      isAllSelected={selectAll}
                      onPageChange={(pageIndex) => setPage(pageIndex + 1)}
                      onPageSizeChange={(pageSize, pageIndex) => { setPage(pageIndex + 1); setPerPage(pageSize) }}
                      onRowSelect={(row) => {
                        pendingGolfers.filter(e => e.id === row.id);
                        const newPendings = [...pendingGolfers].map((e) => {
                          if (e.id === row.id) {
                            if (selectAll) {
                              const newUnselected = { ...unselectedRows }
                              newUnselected[row.id] = !newUnselected[row.id]
                              setUnselectedRows(newUnselected)
                            }
                            else {
                              const newSelected = { ...selectedRows }
                              newSelected[row.id] = !newSelected[row.id]
                              setSelectedRows(newSelected)
                            }
                            return {
                              ...e,
                              selected: !row.selected
                            }
                          }
                          return e;
                        })
                        setPendingGolfers(newPendings)
                      }}
                      onSelectPage={() => {
                        const newSelected = { ...selectedRows }
                        const pendings = [...pendingGolfers].filter((e) => e.selected)
                        const newPendings = [...pendingGolfers].map((e) => {
                          newSelected[e.id] = !(pendings.length === pendingGolfers.length)
                          return {
                            ...e,
                            selected: !(pendings.length === pendingGolfers.length)
                          }
                        })
                        setSelectedRows(newSelected)
                        setPendingGolfers(newPendings)
                      }}
                      onSelectAll={() => {
                        const newPendings = [...pendingGolfers].map((e) => {
                          return {
                            ...e,
                            selected: !selectAll
                          }
                        })
                        setUnselectedRows([])
                        setSelectedRows([])
                        setSelectAll(!selectAll)
                        setPendingGolfers(newPendings)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        question={'Approve Golfer'}
        confirmLabel={'Approve'}
        cancelLabel={'Cancel'}
        bodyQuestion={`Add ${selectedGolfer.full_name} to the ${props.club.club_name}?`}
        onCancelAction={() => setApproveModalOpened(false)}
        onConfirmAction={() => approve(selectedGolfer.id)}
        openModal={approveModalOpened}
        closeModal={() => setApproveModalOpened(false)}
      />

      <Modal
        isOpen={rejectModalOpened}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <RejectGolferModal
          selectedGolfer={selectedGolfer}
          onConfirm={(data) => reject(selectedGolfer.id, data)}
          closeModal={() => setRejectModalOpened(false)}
        />
      </Modal>

    </Fragment>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch)
}

export default connect(null, mapDispatchToProps)(PendingMembers);
