import moment from "moment";

// converts string "yyyy-mm-dd" to "mm/dd/yyyy"
// this should be used only for dates to be displayed properly for any user's tz
export const stringDateFormat = (stringDate, format = 'MM/DD/YYYY') => {
  const date = stringDate.split("-");
  
  format = format.replace("YYYY", date[0]);
  format = format.replace("MM", date[1]);
  format = format.replace("DD", date[2]);
  return format;
}

// convert any time in user's tz
export const stringDateTimeFormat = (stringDateTime, format = 'MM/DD/YYYY') => {
  return moment(stringDateTime).format(format);
}
