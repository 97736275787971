import React, {Component, Fragment} from 'react';
import {Field, reduxForm, SubmissionError} from "redux-form";
import {addAlert} from "../shared/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CourseRatingSystemService from "../../services/api/courseRatingSystem";
import {SelectInput} from "../../inputs/Select";
import ClubService from "../../services/api/club";
import FacilityService from "../../services/api/facilities";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class EditHomeCourseForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			facility: null,
			courses: [],
			isPrimary: true,
			loading: true,
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(values,dispatch, props) {
		let home_courses = [];
		this.state.courses.map(course => {
			let c = {};
			if (values[course.CourseId].is_checked) {
				c.course_id = course.CourseId;

				if (values[course.CourseId] && values[course.CourseId].id){
					c.id = values[course.CourseId].id;
				}
				if (values[course.CourseId] && values[course.CourseId].default_male) {
					c.default_male_tee_id = values[course.CourseId].default_male.value;
				}

				if (values[course.CourseId] && values[course.CourseId].default_female) {
					c.default_female_tee_id = values[course.CourseId].default_female.value
				}
			} else {
				if( values[course.CourseId].id) {
					c = {
						id: values[course.CourseId].id,
						delete: true
					}
				}
			}

			if(c.course_id || c.id) {
				home_courses.push(c);
			}
			return null;

		});
		let data = {
			facility: {
				is_primary: values.is_primary,
				facility_id: this.props.facility.facility_id,
				home_courses
			}
		};

		return ClubService.updateClubHomeCourses(props.facility.id, props.clubId.id, data)
			.then(res => {
				props.addAlert({
					type:'success',
					message:'Home Courses successfully edited!'
				});
				this.props.closeModal(res);
			})
			.catch(err =>{
				let errors = '';
				if (Array.isArray(err.data.errors)) {
					err.data.errors.map(error => {
						errors += error + '<br/>';
						return null;
					})
				} else {
					errors = err.data.errors;
				}
				throw new SubmissionError({name: errors})
			})
	}

	componentDidMount() {
		let courses = [];
		FacilityService.getById(this.props.facility.facility_id)
			.then(res => {
				Promise.all(res.map(course => {
					return CourseRatingSystemService.getCourseDetails(course.CourseId)
						.then(res => {
							let formatedCourse = res;
							formatedCourse.TeeSets = res.TeeSets.map(tee => {
								return {...tee, ...{label: tee.TeeSetRatingName, value: tee.TeeSetRatingId}}
							}).sort((a, b) => (a.label > b.label) ? 1 : -1);

							formatedCourse.isChecked = this.props.facility.home_courses.filter(c => course.CourseId === c.course_id ).length > 0;
							courses.push(formatedCourse);
						})
						.catch(err => {console.error(err)});
				}))
					.then(res => {
						courses.sort((a, b) => (a.CourseName > b.CourseName) ? 1 : -1);
						this.setState({
							courses,
							loading: false,
							isPrimary: this.props.facility.is_primary
						}, ()=>{
							let courses = {};
								this.state.courses.map(c => {
									courses[c.CourseId] = {
										is_checked: false
									}
									return null;
								});
								this.props.facility.home_courses.map( course => {
								courses[course.course_id] = {
											is_checked: true,
											id: course.id,
											default_male:  course.default_tee_male  ? {value: parseInt(course.default_tee_male.id), label:  course.default_tee_male.name} : '',
											default_female: course.default_tee_female ? {value:  parseInt(course.default_tee_female.id), label:  course.default_tee_female.name} : '',
										};
								return null;
							});
							this.props.initialize({
								...{is_primary: this.props.facility.is_primary, facility_id: this.props.facility_id},
								...courses,
							});
						})
					})
					.catch(err => {console.error(err)})

			})
			.catch(err => {console.error(err)})

		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}


	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
				{this.state.loading ?
					<table style={{width: '100%'}}>
						<tbody >
						<tr>
							<td style={{textAlign: 'center'}}>
								<span className="data-table__loader"></span>
							</td>
						</tr>
						</tbody>
					</table>
					:
					<Fragment>
						<div className="row">
							<div className="col">
								<h3 className="form__title">{this.props.facility.name}</h3>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<p className="form__description">Please select Home Courses and Default Tees (not required) below. If this facility is the Primary Facility, check the checkbox below.</p>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<table className="data__table normal-overflow">
									<thead>
									<tr>
										<th className={'primary'}></th>
										<th>Course Name</th>
										<th>Default Male Tee</th>
										<th>Default Female Tee</th>
									</tr>
									</thead>
									<tbody>
									{this.state.courses.length > 0 && this.state.courses.map((course, key) => {
										return (
											<tr key={key}>
												<td className={'primary'}>
													<Field
														onClick={
															(event) => {
																if(!(this.props.cantRemove && this.state.courses.filter(c => c.isChecked).length === 1 && course.isChecked)) {
																	let courses = this.state.courses.map(c => {
																		if (c.CourseId === course.CourseId) {
																			course.isChecked = !course.isChecked;
																			return course
																		} else {
																			return c;
																		}
																	});
																	this.setState({courses});
																}
															}
														}
														onChange={(e) => {if(this.props.cantRemove && this.state.courses.filter(c => c.isChecked).length === 1 && course.isChecked) e.preventDefault()}}
														checked={course.isChecked}
														id={course.CourseId + '.is_checked'}
														value={true}
														name={course.CourseId + '.is_checked'}
														component={'input'}
														type="checkbox"
													/>
													<label htmlFor={course.CourseId + '.is_checked'}></label>
												</td>
												<td>{course.CourseName.length > 20 ? course.CourseName.slice(0, 20) + '...': course.CourseName}</td>
												<td>
													{(course.TeeSets.length === 0 || course.TeeSets.filter(tee => tee.Gender === "Male").length === 0) ?
														"No Tee Available"
														:
														<Field name={course.CourseId + '.default_male'}
															   id={course.CourseId + '.default_male'}
															   component={SelectInput}
															   className="react-select-container"
															   classNamePrefix="react-select"
															   options={[...[{label:'None',value: null}] ,...course.TeeSets.filter(tee => tee.Gender === "Male")]}
														/>
													}
												</td>
												<td>

													{(course.TeeSets.length === 0 || course.TeeSets.filter(tee => tee.Gender === "Female").length === 0) ?
														"No Tee Available"
														:
														<Field name={course.CourseId + '.default_female'}
															   id={course.CourseId + '.default_female'}
															   component={SelectInput}
															   className="react-select-container"
															   classNamePrefix="react-select"
															   options={[...[{label:'None',value: null}] ,...course.TeeSets.filter(tee => tee.Gender === "Female")]}
														/>
													}
												</td>
											</tr>
										)}
										)
									}
									</tbody>
								</table>
							</div>
						</div>

						<div className="row">
							<div className="col auto push-right">
								<Field
									onClick={
										() => {
											this.setState({isPrimary: !this.state.isPrimary});
										}
									}
									checked={this.state.isPrimary}
									id={'is_primary'}
									value={true}
									name={'is_primary'}
									component={'input'}
									type="checkbox"
								/>
								<label htmlFor={'is_primary'}>Primary Facility</label>
							</div>
						</div>

						<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={()=>{this.props.closeModal()}}>Close</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  className="btn fill blue" disabled={pristine || submitting || this.state.courses.length === 0} >Save</button>
							</div>
						</div>

					</Fragment>}

			</form>
		);
	}
}

EditHomeCourseForm = reduxForm({
	form: 'editHomeCourseForm',
	destroyOnUnmount: true
})(EditHomeCourseForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditHomeCourseForm));
