import React, {Component, Fragment} from 'react';
import moment from 'moment';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {required} from "../../inputs/validations";
import ClubService from "../../services/api/club";
import {addAlert} from '../shared/actions'
import {DatePickerInput} from "../../inputs/DatePicker";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";


class EditClubStatusForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			now: true,
			future: false,
      option: 'inactive',
			type: props.isAllSelected? 'filtered' : 'selected'
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.props.change('now', true);
    this.props.change('option', 'inactive');
		this.props.onRef(this);
		this.setState({
			type: this.props.isAllSelected? 'filtered' : 'selected'
		})
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {
		let params = {};
		if(this.state.type === "filtered") {
			params = {...params, ...props.filters};
			delete params['page'];
			delete params['per_page'];
		}

		if (values.future && !values.date) {
			throw new SubmissionError({date: "This field is required"})
		}
		let data = {};

		if (!this.props.isAllSelected) {
			data.clubs = props.clubs
		} else {
			params = {...params, ...props.filters};
			delete params['page'];
			delete params['per_page'];
			data['select_all'] = true;
			data.unchecked_items = props.clubs
		}
		if (values.future) {
			data.from_date = moment(values.date).format('YYYY-MM-DD')
		}

		if(props.type === "activate") {
      data.option = values.option;
			return ClubService.activateClubs(props.associationId, data, params).then(res=>{
				props.onSuccess(values.now, res);
			})
		} else {
			return ClubService.inactivateClubs(props.associationId, data, params).then(res=>{
				props.onSuccess(values.now, res);
			})
		}
	}


	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
            <Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
					<span className="label" >When would you like to {this.props.type} the selected {this.props.clubs.length > 1 ? 'clubs' : 'club'}?</span>

					<div className="row">
						<div className="col is-1-of-2">
							<Field
								onClick={
									() => {
										this.setState({
											now: true,
											future: false
										});
										this.props.change('future', false)
									}
								}
								checked={this.state.now}
								id="now"
								value={true}
								name={'now'}
								component={'input'}
								type="checkbox"
							/>
							<label htmlFor="now">Now</label>

						</div>
					</div>

					<div className={'row'}>
						<div className="col is-1-of-2">
							<Field
								onClick={
									() => {
										this.setState({
											now: false,
											future: true
										});
										this.props.change('now', false);
										this.props.change('date', '');
									}
								}
								checked={this.state.future}
								id="future"
								value={true}
								name={'future'}
								component={'input'}
								type="checkbox"
							/>
							<label htmlFor="future">Future Date</label>
						</div>
					</div>

          {this.props.type === 'activate' &&
          <>
            <span className="label" >How would you like to handle the membership status of the golfers when the {this.props.clubs.length > 1 ? 'clubs are' : 'club is'} activated?</span>

              <div className="row">
                <div className="col">
                  <Field
                    onClick={
                      () => {
                        this.setState({
                          option: 'inactive'
                        });
                        this.props.change('option', 'inactive');
                      }
                    }
                    checked={this.state.option === 'inactive'}
                    id="inactive"
                    value={true}
                    name={'inactive'}
                    component={'input'}
                    type="checkbox"
                  />
                  <label htmlFor="inactive">Leave status set to inactive</label>

                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Field
                    onClick={
                      () => {
                        this.setState({
                          option: 'last_status'
                        });
                        this.props.change('option', 'last_status')
                      }
                    }
                    checked={this.state.option === 'last_status'}
                    id="last"
                    value={true}
                    name={'last'}
                    component={'input'}
                    type="checkbox"
                  />
                  <label htmlFor="last">Return status to what it was at the time of inactivation</label>

                </div>
              </div>

              <div className={'row'}>
                <div className="col">
                  <Field
                    onClick={
                      () => {
                        this.setState({
                          option: 'active'
                        });
                        this.props.change('option', 'active');
                      }
                    }
                    checked={this.state.option === 'active'}
                    id="active"
                    value={true}
                    name={'active'}
                    component={'input'}
                    type="checkbox"
                  />
                  <label htmlFor="active">Make all members active</label>
                </div>
              </div>
          </>
          }

					{this.state.future &&<div className="row">
						<div className="col is-1-of-3">
							<label htmlFor="date">Date <span>*</span></label>
							<Field minDate={new Date(moment().add(1, 'days'))}
								   className={'date'}
								   showMonthDropdown
								   showYearDropdown
								   value={null}
								   validate={[required]}
								   name={'date'}
								   id={'date'}
								   component={DatePickerInput}
									 onFocus={e => this.props.change('date', new Date(moment().add(1,'days')))}
								   placeholder="Select ..." />
						</div>
					</div>}

                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={()=>this.props.close()}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
						<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Submit</button>
                    </div>
                </div>
				</form>
            </Fragment>
		);
	}
}

EditClubStatusForm = reduxForm({
	form: 'editClubStatusForm',
	destroyOnUnmount: true
})(EditClubStatusForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditClubStatusForm));
