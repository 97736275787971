import { Fragment, useEffect, useState, useRef } from "react";
import GhinTable from "../../../shared/GhinTable";
import { connect } from "react-redux";
import { addAlert } from "../../../shared/actions";
import { bindActionCreators } from "redux";
import ConfirmationModal from "../../../../screens/Modals/ConfirmationModal";
import React from "react";
import JoinAndRenewServices from "../../../../services/api/joinAndRenew";
import Spinner from "../../Associations/FederationNewsletter/Spinner";

function ValidZipCodes(props) {
	const [deleteAllModalOpened, setDeleteAllModalOpened] = useState(false);
	const [deleteModalOpened, setDeleteModalOpened] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const closeModal = () => {
		setDeleteAllModalOpened(false);
		setDeleteModalOpened(false);
	};
	
	const readOnlyAccess = () => {
		const disallowedTypes = [
			"association-read-only",
			"club-read-only",
			"read-only",
			"association-score-maintenance",
			"club-score-maintenance"
		];

		return disallowedTypes.includes(props.userType);
	}

	const columns = [
		{
			Header: "Valid Zip Codes",
			accessor: "zip_codes",
			className: "table-align-center",
			thClassName: "col_date table-align-center",
			Cell: (data) => {
				return `${data.original}`;
			},
		},
		{
			Header: (
				<Fragment>
					<button
						className="btn lnk white"
						onClick={() => setDeleteAllModalOpened(true)}
					>
						Remove All
					</button>
				</Fragment>
			),
			highlightColumn: true,
			sortable: false,
			className: "table-align-center",
			thClassName: "col_date table-align-center",
			accessor: "remove",
			show: !readOnlyAccess(),
			Cell: (props) => (
				<button
					className="btn lnk"
					onClick={() => {
						setDeleteModalOpened(true);
						setDeleteId(props.row._original);
					}}
				>
					Remove
				</button>
			),
		},
	];

	const [zipCodes, setZipCodes] = useState([]);
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(25);
	const [pages, setPages] = useState(null);
	const [total, setTotal] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingBulkUpload, setLoadingBulkUpload] = useState(false);
	const [formValues, setFormValues] = useState({});
	const [sorted, setSorted] = useState([{ desc: false }]);
	const [sortingChanged, setSortingChanged] = useState(false);
	const [order, setOrder] = useState(false);

	const handleChange = (e) => {
		setFormValues({ ...formValues, [e.target.id]: e.target.value });
	};

	const fileInputRef = useRef(null);

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (event) => {
		setLoadingBulkUpload(true);
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			console.log("Uploading file:", selectedFile);
			const formData = new FormData();
			formData.append("file", selectedFile);
			JoinAndRenewServices.uploadFile(props.club.id, formData)
				.then(() => {
					props.addAlert({
						type: "success",
						message: "Zip Code added successfully.",
					});
					setLoadingBulkUpload(false);
					getClubZipCodes(true);
				})
				.catch((err) => {
					console.log(err);
					props.addAlert({
						type: "error",
						message: err.data.errors,
					});
					setLoadingBulkUpload(false);
				});
		} else {
			console.log("No file selected");
			setLoadingBulkUpload(false);
		}
	};

	const addZipCode = () => {
		JoinAndRenewServices.addClubZipCode(props.club.id, formValues)
			.then(() => {
				props.addAlert({
					type: "success",
					message: "Zip Code added successfully.",
				});
				getClubZipCodes(true);
			})
			.catch((err) => {
				console.log(err);
				props.addAlert({
					type: "error",
					message: err.data.errors.club_zip_code,
				});
			});
		setFormValues({});
	};
	const getClubZipCodes = (pageChanged = false) => {
		setLoading(true);

		const filters = {
			page: page + 1,
			per_page: perPage,
			order: sorted[0].desc ? "desc" : "asc",
		};

		JoinAndRenewServices.getClubZipCode(props.club.id, filters)
			.then((res) => {
				setZipCodes(
					res["zip_codes"].map((zip_code) => {
						return zip_code["zip_code"];
					})
				);
				setPerPage(parseInt(res["per-page"], 10));
				setPages(parseInt(res["total-pages"], 10));
				setTotal(parseInt(res["total-count"], 10));
				if (!pageChanged) setPage(1);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const removeClubZipCode = (zip_code) => {
		JoinAndRenewServices.removeClubZipCodes(props.club.id, {
			zip_code: zip_code,
		})
			.then(() => {
				closeModal();
				getClubZipCodes(true);
				props.addAlert({
					type: "success",
					message: "Zip Code removed successfully.",
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const removeClubZipCodes = () => {
		JoinAndRenewServices.removeClubZipCodes(props.club.id, {
			delete_all: true,
		})
			.then(() => {
				closeModal();
				getClubZipCodes(true);
				props.addAlert({
					type: "success",
					message: "All Zip Codes removed successfully.",
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getClubZipCodes(true);
	}, [props.club.id, page, order]);
	return (
		<Fragment>
			<div className="panel">
				<div className="panel__head">Valid Zip Codes</div>
				<div className="panel__body">
					<p>
						{" "}
						Zip Codes in the table below are the only zip codes that
						are valid when a golfer joins the club via GHIN Join &
						Renew.
					</p>

					<div className="row margin-top-16">
						{
							<div className="col">
								<p className="red-note">
									<strong>Note:</strong> A golfer can still be
									added manually via the Admin Portal if they
									have a zip code that is not in the list
									below.
								</p>
							</div>
						}
					</div>
					<p>
						{" "}
						To add a new zip code to the list, enter it in the "Add
						Zip Code" field and press the "Add" button. To remove a
						zip code, use the "Remove" link in that row. To remove
						all zip codes use "Remove All" link.
					</p>
					<br></br>
					<p>
						{" "}
						To bulk upload from an excel file, use the "Bulk Upload"
						button.
					</p>
					<br></br>

					<div className="row" style={{ width: "950px" }}>
						<div
							className="col is-1-of-5"
							style={{ marginLeft: "105px" }}
						>
							<form style={{ height: "65px" }}>
								<label htmlFor="zip_code">Add Zip Code</label>

								<input
									type="text"
									id="zip_code"
									value={formValues.zip_code || ""}
									onChange={handleChange}
									disabled={readOnlyAccess()}
								/>
							</form>
						</div>
						<div
							className="col is-1-of-5 push-right push-top"
							style={{ marginLeft: "0px" }}
						>
							<button
								className="btn fill green"
								onClick={() => {
									addZipCode();
								}}
								disabled={readOnlyAccess() || !formValues.zip_code}
							>
								Add
							</button>
						</div>
						<div
							className="col is-1-of-5 push-right push-top"
							style={{ marginLeft: "285px" }}
						>
							<div>
								<button
									className="btn fill green"
									onClick={handleButtonClick}
									disabled={readOnlyAccess() || loadingBulkUpload}
								>
									{loadingBulkUpload && (
										<Spinner className="lds-ring white mr-5" />
									)}
									Bulk Upload
								</button>
								<input
									type="file"
									ref={fileInputRef}
									accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
									style={{ display: "none" }}
									onChange={handleFileChange}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col is-4-of-5 centered">
							<GhinTable
								idKey={"id"}
								columns={columns}
								hideSelect={true}
								sortable={true}
								data={zipCodes}
								loading={loading}
								pages={pages}
								page={page}
								total={total}
								pageSize={perPage}
								manual
								onPageChange={(pageIndex) => setPage(pageIndex)}
								onPageSizeChange={(pageSize, pageIndex) => {
									setPage(pageIndex);
									setPerPage(pageSize);
								}}
								onSortedChange={(newSorted) => {
									setPage(0);
									setPerPage(25);
									setSorted(newSorted);
									setSortingChanged(!sortingChanged);
									let order =
										newSorted[0]["desc"] === true
											? true
											: false;
									setOrder(order);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<ConfirmationModal
				question={`Are you sure you want to remove all zip codes?`}
				confirmLabel={"Continue"}
				cancelLabel={"Cancel"}
				onCancelAction={() => setDeleteAllModalOpened(false)}
				onConfirmAction={() => removeClubZipCodes()}
				openModal={deleteAllModalOpened}
				closeModal={() => closeModal()}
			/>
			<ConfirmationModal
				question={`Are you sure you want to remove this zip code?`}
				confirmLabel={"Continue"}
				cancelLabel={"Cancel"}
				onCancelAction={() => setDeleteModalOpened(false)}
				onConfirmAction={() => removeClubZipCode(deleteId)}
				openModal={deleteModalOpened}
				closeModal={() => closeModal()}
			/>
		</Fragment>
	);
}

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidZipCodes);
