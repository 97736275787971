import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const createCancelableRequest = () => {
	var call;
	return function(url) {
		if (call) {
			call.cancel();
		}
		url = process.env.REACT_APP_API_URL + url;
		call = axios.CancelToken.source();

		let options = {
			cancelToken: call.token
		};

		if (cookies.get('token')) {
			options.headers = {
				"Authorization": "Bearer " + cookies.get('token')
			};
		}

		return axios.get(url, options)
			.catch(err => {
				if (axios.isCancel(err)) {
					console.log('First request canceled');
				}
			});
	}
}

export default createCancelableRequest;
