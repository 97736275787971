import { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { compose } from "redux";
import Setup from "./Setup";
import PendingMembers from "./PendingMembers";
import {bindActionCreators} from "redux";
import {addAlert} from "../../../shared/actions";
import ClubMembershipFees from "./ClubMembershipFees";
import ClubJoinPages from "./ClubJoinPages";
import ValidZipCodes from "./ValidZipCodes";


function JoinAndRenew(props) {
  return (
    <Fragment>
      <div className="vertical-tabs__container row">
        <ul className="vertical-tabs col">
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/setup`} onClick={async () => await props.reloadGolfersCount()}>Setup</NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/club-membership-fees`} onClick={async () => await props.reloadGolfersCount()}>
            <div>
              <p>Club</p>
              <p>Membership Fees</p>
            </div>
          </NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/club-join-pages`} onClick={async () => await props.reloadGolfersCount()}>Club Join Pages</NavLink></li>
          <li><NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/valid-zip-codes`}>Valid Zip Codes</NavLink></li>
          {
            props.joinClubSettings.approval_needed &&
            <li>
              <div style={{ position: 'relative' }}>
                {
                 (props.joinClubSettings.enable_online_join || props.joinClubSettings.enable_online_renewal) && props.pendingGolfersCount > 0 &&
                 <div className="pending-count-circle">{props.pendingGolfersCount}</div>
                }
                <NavLink className="vertical-tab" activeClassName="is-active" to={`${props.match.url}/pending-members`} onClick={async () => await props.reloadGolfersCount()}>Pending Members</NavLink>
              </div>
              
            </li>
          }
        </ul>
        <div className="vertical-tabs__panel col">
          <Switch>
            <Redirect exact from={`${props.match.url}`} to={`${props.match.url}/setup`} />
            <Route path={`${props.match.path}/setup`} render={() =>
              <Setup
                {...props}
            />} />
            <Route path={`${props.match.path}/club-membership-fees`} render={() =>
              <ClubMembershipFees
                {...props}
            />} />
            <Route path={`${props.match.path}/club-join-pages`} render={() =>
              <ClubJoinPages
                {...props}
              />} />
             <Route path={`${props.match.path}/valid-zip-codes`} render={() =>
              <ValidZipCodes
                {...props}
            />} />
            <Route path={`${props.match.path}/pending-members`} render={() =>
              <PendingMembers
                {...props}
            />} />
          </Switch>
        </div>
      </div>
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    userType: state.user.access.user_type,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(JoinAndRenew);