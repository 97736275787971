import React, {Component, Fragment} from 'react';

export default class SelectAddGuardianMethod extends Component {

	render() {
		return (
            <Fragment>

                <div className="row">
                    <div className="col">
                        <button onClick={() => {this.props.showSearch()}} className="btn outline gray">Search for Guardian</button>
                    </div>
                    <div className="col">
                        <button onClick={() => {this.props.showAdd()}} className="btn outline gray">Add New Guardian</button>
                    </div>
                </div>

            </Fragment>
		);
	}
}
