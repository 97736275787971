import React, { Component, Fragment } from "react";
import UploadKioskImage from "../../../../inputs/UploadKioskImage";
import UploadMobileImage from "../../../../inputs/UploadMobileImage";
import AssociationService from "../../../../services/api/association";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAlert } from "../../../shared/actions";
import { updateAssociation } from "../Account/Actions";

class PromotionalImages extends Component {
	constructor(props) {
		super(props);

		this.state = {
			use_default_images: false,
			image: null,
		};
	}

	removeImage(type) {
		AssociationService.editImageGolferProducts(
			this.props.associationId,
			this.state.settings.id,
			{ ["delete_" + type]: true }
		)
			.then((res) => {
				this.setState({ settings: res.association_settings });
			})
			.catch((err) => {
				console.error(err);
			});
	}
	saveImage(file, type) {
		AssociationService.editImageGolferProducts(
			this.props.associationId,
			this.state.settings.id,
			{ [type]: file }
		)
			.then((res) => {
				this.setState({ settings: res.association_settings });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	setImageURL(data, type, successfullyCallBack, errorCallBack) {
		if (data) {
			AssociationService.editGolferProducts(
				this.props.associationId,
				this.state.settings.id,
				{
					[type + "_ad_url"]: data.ad_image_url,
					mobile_image1_ad_tag: data.mobile_image1_ad_tag,
					mobile_image2_ad_tag: data.mobile_image2_ad_tag,
				}
			)
				.then((res) => {
					this.setState({
						settings: res.association_settings,
						error: null,
					});
					successfullyCallBack(data);
				})
				.catch((err) => {
					this.setState({ error: err.data.error });
					console.error(err.data.error);
				});
			errorCallBack(data);
		}
	}

	setKioskImageURL(data, id, index, successfullyCallBack, errorCallBack) {
		let kiosk_images = this.state.kiosk_images;

		if (data) {
			AssociationService.editKioskImage(this.props.associationId, id, {
				kiosk_image: { tag: data.tag },
			})
				.then((res) => {
					kiosk_images[index] = res.kiosk_image;
					this.setState({ kiosk_images, error: null });
					successfullyCallBack(data);
				})
				.catch((err) => {
					this.setState({ error: err.data.error });
					console.error(err.data.error);
				});
			errorCallBack(data);
		}
	}

	addNewKioskImage() {
		let kiosk_images = this.state.kiosk_images;
		AssociationService.addKioskImage(this.props.associationId)
			.then((res) => {
				kiosk_images.push(res.kiosk_image);
				this.setState({ kiosk_images });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	addNewKioskImageWithImage(file) {
		let kiosk_images = this.state.kiosk_images;
		AssociationService.addKioskImage(this.props.associationId)
			.then((res) => {
				kiosk_images.push(res.kiosk_image);
				AssociationService.uploadKioskImage(
					this.props.associationId,
					res.kiosk_image.id,
					file
				).then((res) => {
					kiosk_images[kiosk_images.length - 1] = res.kiosk_image;
					this.setState({
						kiosk_images,
						use_default_images: this.calculateUseDefaultImages(),
					});
				});
				this.setState({ kiosk_images });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	saveKioskImage(file, id, index) {
		let kiosk_images = this.state.kiosk_images;
		AssociationService.uploadKioskImage(this.props.associationId, id, file)
			.then((res) => {
				kiosk_images[index] = res.kiosk_image;
				this.setState({
					kiosk_images,
					use_default_images: this.calculateUseDefaultImages(),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}
	removeKioskImage(id, index) {
		let kiosk_images = this.state.kiosk_images;
		AssociationService.removeKioskImage(this.props.associationId, id)
			.then((res) => {
				kiosk_images[index] = res.kiosk_image;
				this.setState({
					kiosk_images,
					use_default_images: this.calculateUseDefaultImages(),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}
	deleteKioskImage(id, index) {
		let kiosk_images = this.state.kiosk_images;
		AssociationService.deleteKioskImage(this.props.associationId, id)
			.then((res) => {
				kiosk_images.splice(index, 1);
				this.setState({
					kiosk_images,
					use_default_images: this.calculateUseDefaultImages(),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	calculateUseDefaultImages() {
		return (
			this.onlyEmptyKioskImages() &&
			!this.onlyEmptyKioskImages(this.state.federation_kiosk_images) &&
			this.state.federation_kiosk_images !== null &&
			this.state.federation_kiosk_images.length !== 0
		);
	}

	componentDidMount() {
		AssociationService.getGolferProducts(this.props.associationId)
			.then((res) => {
				AssociationService.getKioskImages(this.props.associationId)
					.then((response) => {
						this.setState({
							settings: res.association_settings,
							kiosk_images: response.kiosk_images,
							federation_kiosk_images:
								response.federation_kiosk_images,
							use_default_images:
								this.onlyEmptyKioskImages(
									response.kiosk_images
								) &&
								!this.onlyEmptyKioskImages(
									response.federation_kiosk_images
								) &&
								response.federation_kiosk_images &&
								response.federation_kiosk_images.length > 0,
						});
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((error) => {
				console.error(error);
			});
	}

	onlyEmptyKioskImages(images = this.state.kiosk_images) {
		if (images == null) return true;
		let onlyEmpty = true;
		images.forEach((element) => {
			if (element.image_url != null) onlyEmpty = false;
		});
		return onlyEmpty;
	}

	render() {
		return (
			<Fragment>
				{this.state.settings && (
					<Fragment>
						<div className="row">
							<div className="col">
								<div className="panel">
									<div className="panel__head">
										Promotional Images on Kiosk
									</div>
									<div className="panel__body">
										<h5 className="upload__title">
											These images will appear at the
											bottom of all screens within the
											Kiosk.
										</h5>
										<ul className="upload__requirements">
											<li>Required Width = 728 px</li>
											<li>Required Height = 90 px</li>
											<li>Required Format = PNG, JPG</li>
										</ul>
										<div className="columns">
											{(this.state.use_default_images
												? this.state
														.federation_kiosk_images
												: this.state.kiosk_images
											).map((kiosk_image, key) => {
												return (
													<div
														className="row"
														key={
															key +
															(this.state
																.use_default_images
																? 0
																: 1000)
														}
													>
														<div className="col">
															<div className="panel">
																<UploadKioskImage
																	title={`Promotional Image on Kiosk (${
																		key + 1
																	})`}
																	image={
																		kiosk_image.image_url
																	}
																	tag={
																		kiosk_image.tag
																	}
																	use_default_images={
																		this
																			.state
																			.use_default_images
																	}
																	readOnly={
																		!this
																			.props
																			.canEditAssociation
																	}
																	removable={
																		true
																	}
																	requiredHeight={
																		90
																	}
																	requiredWidth={
																		728
																	}
																	errors={
																		this
																			.state
																			.error
																	}
																	onSave={(
																		data
																	) => {
																		this.saveKioskImage(
																			data,
																			kiosk_image.id,
																			key
																		);
																	}}
																	onUploadCustomImage={(
																		data
																	) => {
																		this.addNewKioskImageWithImage(
																			data
																		);
																	}}
																	onRemove={() => {
																		this.removeKioskImage(
																			kiosk_image.id,
																			key
																		);
																	}}
																	onDelete={() => {
																		this.deleteKioskImage(
																			kiosk_image.id,
																			key
																		);
																	}}
																	onsetKioskImageURL={(
																		data,
																		successfullyCallBack,
																		errorCallBack
																	) =>
																		this.setKioskImageURL(
																			data,
																			kiosk_image.id,
																			key,
																			successfullyCallBack,
																			errorCallBack
																		)
																	}
																/>
															</div>
														</div>
													</div>
												);
											})}

											{this.props.canEditAssociation &&
												!this.state
													.use_default_images && (
													<div className="row">
														<div className="col auto push-right">
															<button
																className="btn fill green"
																onClick={() => {
																	this.addNewKioskImage();
																}}
															>
																Add Promotional
																Images on Kiosk
															</button>
														</div>
													</div>
												)}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<div className="panel">
									<div className="panel__head">
										Promotional Images on Mobile App
									</div>
									<div className="panel__body">
										<h5 className="upload__title">
											These images will appear on the
											Golfer Home screen of the GHIN
											Mobile App.
										</h5>
										<ul className="upload__requirements">
											<li>Required Width = 1920 px</li>
											<li>Required Height = 800 px</li>
											<li>Required Format = PNG, JPG</li>
										</ul>
										<div className="columns">
											<div className="row">
												<div className="col">
													<div className="panel">
														<UploadMobileImage
															title={
																"Promotional Image on Mobile App - Placement 1"
															}
															image={
																this.state
																	.settings
																	.mobile_app_promotional_image1
															}
															ad_image_url={
																this.state
																	.settings
																	.mobile_image1_ad_url
															}
															mobile_image1_ad_tag={
																this.state
																	.settings
																	.mobile_image1_ad_tag
															}
															is_default_image={
																this.state
																	.settings
																	.default_mobile_app_promotional_image1
															}
															update_association_settings={
																this
																	.updateAssociationSettings
															}
															readOnly={
																!this.props
																	.canEditAssociation
															}
															requiredWidth={1920}
															requiredHeight={800}
															display_image_name={
																"mobile_image1_ad_tag"
															}
															errors={
																this.state.error
															}
															onSave={(data) => {
																this.saveImage(
																	data,
																	"mobile_app_promotional_image1"
																);
															}}
															onRemove={() => {
																this.removeImage(
																	"image1"
																);
															}}
															onsetImageURL={(
																data,
																successfullyCallBack,
																errorCallBack
															) =>
																this.setImageURL(
																	data,
																	"mobile_image1",
																	successfullyCallBack,
																	errorCallBack
																)
															}
														/>
													</div>
												</div>
											</div>
											{(this.state.settings
												.mobile_app_promotional_image2 ||
												this.props
													.canEditAssociation) && (
												<div className="row">
													<div className="col">
														<div className="panel">
															<UploadMobileImage
																title={
																	"Promotional Image on Mobile App - Placement 2"
																}
																image={
																	this.state
																		.settings
																		.mobile_app_promotional_image2
																}
																ad_image_url={
																	this.state
																		.settings
																		.mobile_image2_ad_url
																}
																mobile_image2_ad_tag={
																	this.state
																		.settings
																		.mobile_image2_ad_tag
																}
																is_deep_link={
																	this.state
																		.settings
																		.is_mobile_image2_ad_url_deep_link
																}
																is_default_image={
																	this.state
																		.settings
																		.default_mobile_app_promotional_image2
																}
																update_association_settings={
																	this
																		.updateAssociationSettings
																}
																readOnly={
																	!this.props
																		.canEditAssociation
																}
																requiredWidth={
																	1920
																}
																requiredHeight={
																	800
																}
																display_image_name={
																	"mobile_image2_ad_tag"
																}
																errors={
																	this.state
																		.error
																}
																onSave={(
																	data
																) => {
																	this.saveImage(
																		data,
																		"mobile_app_promotional_image2"
																	);
																}}
																onRemove={() => {
																	this.removeImage(
																		"image2"
																	);
																}}
																onsetImageURL={(
																	data,
																	successfullyCallBack,
																	errorCallBack
																) =>
																	this.setImageURL(
																		data,
																		"mobile_image2",
																		successfullyCallBack,
																		errorCallBack
																	)
																}
															/>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ updateAssociation, addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(mapDispatchToProps)(PromotionalImages);
