import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputField} from "../../inputs/InputField";
import AssociationService from "../../services/api/association";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {SelectInput} from "../../inputs/Select";
import { isUrlFormat, required } from '../../inputs/validations';

const tournamentsOptions = [
  {
    label: "Tournaments",
    value: "Tournaments",
  },
  {
    label: "Championships",
    value: "Championships",
  },
  {
    label: "Competitions",
    value: "Competitions",
  },
  {
    label: "Events",
    value: "Events",
  }
];

// Validator for requiring an option.
const notEmptyOption = value => {
  if (!value)
    return 'This field is required';

  // Returning undefined means we do NOT have a validation error.
  if (value.label && value.value)
    return undefined;

  return 'This field is required';
};

class EditTournamentsAndEventsForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			status: false,
		}
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {
    let data = {
      tournaments_and_events_url: values.tournaments_and_events_url,
      tournaments_and_events_category: values.tournaments_and_events_category.value
    }
		return AssociationService.editGolferProducts(props.assocId, props.assocSettings.id, data)
			.then(res => {
				props.addAlert({
					type:'success',
					message:'Tournaments and Events settings has been successfully edited'
				});
				props.close(res.association_settings);
			})
			.catch(err=>{console.error(err)});
	}

	componentDidMount() {

		this.props.onRef(this);
		this.props.initialize({
      tournaments_and_events_url: this.props.assocSettings.tournaments_and_events_url,
      tournaments_and_events_category: {
        label: this.props.assocSettings.tournaments_and_events_category,
        value: this.props.assocSettings.tournaments_and_events_category
      }
		})
	}

	render() {

		const {handleSubmit, pristine, submitting, invalid} = this.props;

		return (
            <Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
					<div className="row">
						<div className="col is-full">
							<label htmlFor="tournaments_and_events_url">Tournaments & Events URL:</label>
							<Field
                component={InputField}
                type="text"
                name="tournaments_and_events_url"
                id="tournaments_and_events_url"
                validate={[isUrlFormat, required]}
              />
						</div>
					</div>

					<div className="row">
						<div className="col is-full">
							<label htmlFor="category">Label</label>
              <Field
                name="tournaments_and_events_category"
                id="tournaments_and_events_category"
                className="react-select-container"
                classNamePrefix="react-select"
                options={tournamentsOptions}
                component={SelectInput}
                validate={notEmptyOption}
              />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={() => this.props.close()}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={invalid || pristine || submitting} className="btn fill blue">Save</button>
						</div>
					</div>
				</form>
            </Fragment>
		);
	}
}



EditTournamentsAndEventsForm = reduxForm({
	form: 'editGolferProductsForm',
	destroyOnUnmount: true
})(EditTournamentsAndEventsForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditTournamentsAndEventsForm));
