import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect} from 'react-router-dom';
import Modal from 'react-modal';
import Header from '../../shared/Header';
import Navigation from '../../shared/Navigation';
import BreadCrumbsNav from '../../shared/BreadCrumbsNav';
import Footer from '../../shared/Footer';
import AssociationList from './AssociationList';
import AssociationGroup from './AssociationGroup';
import Account from '../Federation/Account/index';
import PushNotificationList from './PushNotificationList';
import GolferProducts from './GolferProducts';
import {connect} from "react-redux";
import {updateFederation} from "../Federation/Account/Actions";
import {FederationAccount} from "../../../services/api/federationAccount";
import {bindActionCreators} from "redux";
import {getPermission} from "../../../permissionManager";
import PageHead from "../../shared/PageHead";
import { FederationNewsletter } from "./FederationNewsletter/index"

class Federation extends Component {

	constructor(props) {

		super(props);

		this.state = {
			AddGolferModalIsOpen: false,
			AddClubModalIsOpen: false,
			AddAssocModalIsOpen: false,
			refreshList: false
		};

        this.openGolferAddModal = this.openGolferAddModal.bind(this);
        this.openClubAddModal = this.openClubAddModal.bind(this);
        this.openAssocAddModal = this.openAssocAddModal.bind(this);
        this.setRefreshList = this.setRefreshList.bind(this);
		this.closeModal = this.closeModal.bind(this);

	}

	setRefreshList(value) {
		this.setState({
			refreshList: value
		})
	}

	openGolferAddModal() {
		this.setState({AddGolferModalIsOpen: true});
    }

    openClubAddModal() {
		this.setState({AddClubModalIsOpen: true});
    }

    openAssocAddModal() {
		this.setState({AddAssocModalIsOpen: true});
	}

	closeModal(data) {
		if(data && data.type === "golfer") {
			this.props.history.push(`/manage/association/${data.golfer.association_id}/club/${data.golfer.club_id}/golfer/${data.golfer.id}`);
		}
		if(data && data.type === "club"){
			this.props.history.push(`/manage/association/${data.club.club.golf_association_id}/club/${data.club.club.id}/account`);
		}
		this.setState({
            AddGolferModalIsOpen: false,
            AddClubModalIsOpen: false,
            AddAssocModalIsOpen: false
        });
	}

	componentDidMount() {
		FederationAccount.get().then(data => {
			this.props.updateFederation(data.federation);
		});
        Modal.setAppElement('body');
    }

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.location.pathname !== prevProps.location.pathname) {
			if (this.props.user_type !== "super-admin") {
				if (this.props.match.url !== this.props.homepage) {
					this.props.history.push(this.props.homepage);
				}
			}
		}
	}

	render() {

		return (
            <Fragment>
                <Header />
				<Navigation />
				<BreadCrumbsNav />

                    <main>
                        <div className="container">
							{/*setRefreshList*/}
							<PageHead title={this.props.federation.name} setRefreshList={(data)=>this.setRefreshList(data)}/>

                            <ul className="page__tabs">
                                <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/list`}>Association List</NavLink></li>
                                <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/group`}>Association Groups</NavLink></li>
                                <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/account`}>Account</NavLink></li>
                                <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/newsletter`}>Newsletter Setup</NavLink></li>
																{this.props.push_notification_user && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/push-notifications`}>Push Notifications</NavLink></li>}
																<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/golfer-products`}>Golfer Products</NavLink></li>
														</ul>

                            <section className="page__tab-panel">
                                <Switch>
                                    <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/list`}/>
                                    <Route path={`${this.props.match.url}/list`} render={(props) => <AssociationList refreshList={this.state.refreshList}/>}/>
                                    <Route path={`${this.props.match.url}/group`} render={(props) => <AssociationGroup canEditAssociations={getPermission(this.props.user_type, 'edit-associations')}/>}/>
                                    <Route path={`${this.props.match.url}/account`} component={Account}/>
																		<Route path={`${this.props.match.url}/push-notifications`} render={(props) => <PushNotificationList user={this.props.user}/>}/>
																		<Route path={`${this.props.match.url}/golfer-products`} component={GolferProducts}/>
                                    <Route path={`${this.props.match.url}/newsletter`} component={FederationNewsletter}/>
                                </Switch>
                            </section>

                        </div>
                    </main>

				<Footer />
            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		federation: state.federation,
		user_type : state.user.access.user_type,
		user : state.user,
		push_notification_user: state.user.access.push_notification_user,
		homepage: state.user.access.homepage
	};
}


function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({updateFederation}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Federation);
