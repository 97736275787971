import React, {Component, Fragment} from 'react';
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";
import GhinFilterSelect from "../../shared/GhinFilterSelect";
import ClubService from "../../../services/api/club";
import TableService from "../../../services/tableService";
import technologyProviders from "../../shared/technologyProvider.json"

const yesNoOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];

export default class TableFilter extends Component {

    constructor(props) {

        super(props);

        this.state = {
            associations: [],
            filters: {
              club_name: this.props.searchText,
              is_test: {value: false, label: "No"},
							is_trial: {value: false, label: "No"}
            }
        };

    }

    componentDidMount() {
        this.loadAssociations();
    }
    
    componentDidUpdate(prevProps, prevState) {
      if (prevState.filters.is_test !== this.state.filters.is_test) {
        this.loadAssociations();
      }
    }
    
    updateFilter = (name, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        }, () => {
            this.updateTable();
        })
    };

    updateTable = debounce(() => {
        let filteredArray = TableService.filtersToArray(this.state.filters);
        this.props.updateFiltered(filteredArray);
    }, 200);


    reset() {
        this.setState({filters: {status: "", club_name: "", club_type: null, association_id: "", authorized: null, is_test: null, is_trial: null, is_dac: null, technology_provider: null}}, () => {
            this.updateTable();
        });
    }

    loadAssociations() {
        return ClubService.getGolfAssociations("global").then((data) => {
          var associations = data.associations
          if (this.state.filters.is_test.value === false) {
            associations = associations.filter((e) => e.is_test === false)
          }
            this.setState({
              associations: associations.map((item) => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                }).sort((a, b) => a['label'].toString().localeCompare(b['label'].toString()))
            })
        })
    }

    render() {
        return (
            <Fragment>
                <div className="filters columns">
                    <div className="row">
                        <div className={"filters__inputs col"}>
                            <div className={"row"}>
								<div className='filters__inputs col'>
									<div className={'row'}>
										<div className="col is-1-of-6">
											<label htmlFor="association-name">Club Name</label>
											<input type="text" autoComplete="off" value={this.state.filters.club_name} name="club-name" id="club-name" onChange={(e) => {
												this.updateFilter('club_name', e.target.value)
											}}/>
										</div>
										<div className={'col is-1-of-6'}>
											<GhinFilterSelect
												isSearchable={true}
												singleChoice={true}
												options={this.state.associations}
												value={this.state.filters.association_id}
												onChange={values => this.updateFilter('association_id', values)}
												label={'Association'}
											/>
										</div>
										<div className="col is-1-of-6">
											<GhinFilterSelect
												options={['Active', 'Inactive', 'Archived']}
												value={this.state.filters.status}
												onChange={values => this.updateFilter('status', values)}
												label={'Club Status'}
											/>
										</div>
										<div className="col is-1-of-6">
											<GhinFilterSelect
												options={['Affiliate', 'Military', 'Private', 'Public', 'Resort', 'Semi-Private', 'Not Specified']}
												value={this.state.filters.club_category}
												onChange={values => this.updateFilter('club_category', values)}
												label={'Club Category'}
											/>
										</div>

										<div className="col is-1-of-6">
											<GhinFilterSelect
												options={['Type 1', 'Type 2', 'Type 3']}
												value={this.state.filters.club_type}
												onChange={values => this.updateFilter('club_type', values)}
												label={'Club Type'}
											/>
										</div>
										<div className="col is-1-of-6">
											<GhinFilterSelect
												options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}
												value={this.state.filters.authorized}
												singleChoice={true}
												onChange={values => this.updateFilter('authorized', values)}
												label={'Authorized?'}
											/>
										</div>
									</div>

									<div className={'row'}>
										<div className="col is-1-of-6">
											<GhinFilterSelect
												singleChoice={true}
												options={[{value: true, label: 'DAC only'}, {value: false, label: 'Non-DAC'}]}
												value={this.state.filters.is_dac}
												onChange={values => this.updateFilter('is_dac', values)}
												label={'Handicap Oversight'}
											/>
										</div>
										<div className="col is-1-of-6">
											<GhinFilterSelect
												options={technologyProviders}
												value={this.state.filters.technology_provider}
												onChange={values => this.updateFilter('technology_provider', values)}
												label={'Front End Provider'}
											/>
										</div>
										<div className="col is-1-of-6">
											<GhinFilterSelect
												options={yesNoOptions}
												singleChoice={true}
												value={this.state.filters.is_test}
												onChange={values => this.updateFilter('is_test', values)}
												label={'Is Test club ?'}
											/>
										</div>
                    <div className="col is-1-of-6">
                      <GhinFilterSelect
                        options={yesNoOptions}
                        singleChoice={true}
                        value={this.state.filters.is_trial}
                        onChange={values => this.updateFilter('is_trial', values)}
                        label={'Is Trial club ?'}
                      />
                    </div>
									</div>
								</div>
								<div className="filters__controls col is-1-of-7 jc-fe">
									<button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
								</div>
                            </div>

                        </div>
                    </div>

                </div>

            </Fragment>
        );
    }
}
