import React, {Component, Fragment} from 'react';

import Header from '../shared/Header';
import Navigation from '../shared/Navigation';
import BreadCrumbsNav from '../shared/BreadCrumbsNav';
import Footer from '../shared/Footer';

import GolferMerges from './GolferMerges';
import UsersService from "../../services/api/users";

export default class Merges extends Component {

	constructor(props) {
        super(props);
        this.state = {
            super_user: false
        }
    }

	componentDidMount() {
		UsersService.accesses().then((e) => {this.setState({super_user: e.super_user})});
	}

	render() {
		return (
            <Fragment>
                <Header />
				<Navigation />
				<BreadCrumbsNav />

                    <main>
                        <div className="container">

                            <div className="page__head">
                                <h2 className="page__title">Merges</h2>
                            </div>

                            <ul className="page__tabs">
                                <li><a href="#donothing" onClick={(e) => {e.preventDefault()}} className="page__tab is-active">Golfer</a></li>
                                <span className="message-align red">When performing merges, please choose the golfer with the richest revision history as the winner.</span>
                            </ul>

                            <section className="page__tab-panel">
                                <GolferMerges fromAccountCommand={this.props.location.fromAccountProps} superUser={this.state.super_user}/>
                            </section>

                        </div>
                    </main>

                <Footer />
            </Fragment>
		);
	}
}
