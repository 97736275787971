import React, {Component, Fragment} from 'react';
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";
import technologyProviders from "../../../shared/technologyProvider.json"
import TableService from "../../../../services/tableService";

export default class TableFilter extends Component {

	constructor(props) {

		super(props);

		this.state = {
			filters: process.env.REACT_APP_TYPE !== "hv" ?
				{
					club_name: '',
					membership_code: [],
					authorized: {label: 'Yes', value:true},
					is_test: props.test_assoc ? {label: 'Yes', value: true} : {label: 'No', value:false},
					is_trial: props.trial_assoc ? {label: 'Yes', value: true} : {label: 'No', value:false},
					is_dac: null,
					status: [{label: 'Active', value: 'Active'}],
					technology_provider: {label: "GHIN", value: "GHIN"} ,
					in_group: [],
					join_and_renew_enabled: null
				}
				:
				{
					club_name: '',
					status: [{label: 'Active', value: 'Active'}],
				},
			isGroupFiltered: false
		};
	}

	updateFilter = (name, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            }
        }, () => {
            this.updateTable();
        })
    };

	updateGroupFilter = (name, value) => {
		if (value.value) {
			this.setState({
				filters: {
					club_name: '',
					membership_code: [],
					authorized: null,
					is_test: null,
					is_trial: null,
					is_dac: null,
					status: [],
					technology_provider: null,
					join_and_renew_enabled: null,
					[name]: value
				},
				isGroupFiltered: true
			}, () => {
				this.updateTable();
			})
		} else {
			this.reset()
		}
	}

	componentDidMount() {
		this.updateTable();
	}

	updateTable = debounce(() => {
        let filteredArray = TableService.filtersToArray(this.state.filters);
        this.props.updateFiltered(filteredArray);
    }, 200);

    reset() {
        this.setState({filters: process.env.REACT_APP_TYPE !== "hv" ?
				{
					club_name: '',
					membership_code: [],
					authorized: {label: 'Yes', value:true},
					is_test: this.props.test_assoc ? {label: 'Yes', value: true} : {label: 'No', value:false},
					is_dac: null,
					is_trial: this.props.trial_assoc ? {label: 'Yes', value: true} : {label: 'No', value:false},
					status: [{label: 'Active', value: 'Active'}],
					technology_provider: {label: "GHIN", value: "GHIN"} ,
					in_group: [],
					join_and_renew_enabled: null
				}
				:
				{
					club_name: '',
					status: [{label: 'Active', value: 'Active'}],
				},
			isGroupFiltered: false
		}, () => {
            this.updateTable();
        });
    }

	render() {

    	if (this.props.hideFilters) return null;

		return (
            <Fragment>

                <div className="filters">

                <div className="row">

                    <div className="filters__inputs col">

                        <div className="row">

                            <div className={'col'}>

                            <div className="row">
                                <div className="col is-1-of-5">
                                    <label htmlFor="association-name">Club Name</label>
                                    <input type="text"
										   autoComplete="off"
										   value={this.state.filters.club_name}
										   name="association-name"
										   id="association-name"
										   disabled={this.state.isGroupFiltered}
										   onChange={(e) => {
                                        this.updateFilter('club_name', e.target.value)
                                    }}/>
                                </div>

                                <div className={"col is-1-of-5"}>
                                    <GhinFilterSelect
                                        options={['Type 1', 'Type 2', 'Type 3']}
                                        value={this.state.filters.club_type}
										disabled={this.state.isGroupFiltered}
                                        onChange={values => this.updateFilter('club_type', values)}
                                        label={'Club Type'}
                                    />
                                </div>


                                <div className="col is-1-of-5">

                                    <GhinFilterSelect
                                        options={['Active', 'Inactive', 'Archived']}
                                        value={this.state.filters.status}
										disabled={this.state.isGroupFiltered}
                                        onChange={values => this.updateFilter('status', values)}
                                        label={'Club Status'}
                                    />

                                </div>

                                <div className="col is-1-of-5">

                                    <GhinFilterSelect
                                        options={['Affiliate', 'Military', 'Private', 'Public', 'Resort', 'Semi-Private', 'Municipal', 'WORE', 'Not Specified']}
                                        value={this.state.filters.club_category}
										disabled={this.state.isGroupFiltered}
                                        onChange={values => this.updateFilter('club_category', values)}
                                        label={'Club Category'}
                                    />

                                </div>

                                <div className="col is-1-of-5">
                                    <GhinFilterSelect
                                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}
                                        value={this.state.filters.authorized}
                                        singleChoice={true}
										disabled={this.state.isGroupFiltered}
                                        onChange={values => this.updateFilter('authorized', values)}
                                        label={'Authorized?'}
                                    />
                                </div>

                            </div>


							{process.env.REACT_APP_TYPE !== "hv" &&  <div className="row">

                                <div className="col is-1-of-5">
                                    <GhinFilterSelect
										options={this.props.membershipTypes.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1)}
										value={this.state.filters.membership_code}
										disabled={this.state.isGroupFiltered}
                                        onChange={values => this.updateFilter('membership_code', values)}
                                        label={'Membership Code (MC)'}
                                    />
                                </div>

                                <div className="col is-1-of-5">
                                    <GhinFilterSelect
                                        singleChoice={true}
                                        options={[{value: true, label: 'DAC only'}, {value: false, label: 'Non-DAC'}]}
                                        value={this.state.filters.is_dac}
										disabled={this.state.isGroupFiltered}
                                        onChange={values => this.updateFilter('is_dac', values)}
                                        label={'Handicap Oversight'}
                                    />
                                </div>

                                <div className="col is-1-of-5">
                                    <GhinFilterSelect
                                        singleChoice={true}
                                        options={technologyProviders}
                                        value={this.state.filters.technology_provider}
										disabled={this.state.isGroupFiltered}
                                        onChange={values => this.updateFilter('technology_provider', values)}
                                        label={'Front End Provider'}
                                    />
                                </div>

                                <div className="col is-1-of-5">
                                    <GhinFilterSelect
                                        singleChoice={true}
                                        options={
                                            [{label: 'Yes', value: true}, {label: 'No', value: false}]
                                        }
                                        value={this.state.filters.is_test}
										disabled={this.state.isGroupFiltered}
                                        onChange={values => this.updateFilter('is_test', values)}
                                        label={'Is Test Club?'}
                                    />
                                </div>

																{this.props.join_renew_enabled &&
																	<div className="col is-1-of-5">
																		<GhinFilterSelect
																			singleChoice={true}
																			options={
																				[{label: 'Yes', value: true}, {label: 'No', value: false}]
																			}
																			value={this.state.filters.join_and_renew_enabled}
																			disabled={this.state.isGroupFiltered}
																			onChange={values => this.updateFilter('join_and_renew_enabled', values)}
																			label={'Join & Renew Enabled?'}
																		/>
																	</div>
																}



                            </div>}

                        </div>
						</div>

                    </div>

					<div className="col is-1-of-14 ">
						<div className="form__separator or top"></div>
					</div>

					<div className="col is-1-of-7">
						<GhinFilterSelect
							singleChoice={true}
							options={
								this.props.club_groups
							}
							value={this.state.filters.in_group}
							onChange={values => this.updateGroupFilter('in_group', values)}
							label={'Club Group'}
						/>
					</div>

                    <div className="filters__controls col is-1-of-14">
                        <button className="btn fill gray top" onClick={this.reset.bind(this)}>Reset</button>
                    </div>

                </div>

                </div>

            </Fragment>
		);
	}
}
