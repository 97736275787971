import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import ReactTooltip from "react-tooltip";
import ConfirmationModal from "../screens/Modals/ConfirmationModal";
import {ImageService} from "../services/imageService";
import EditKioskImageInformationModal from '../screens/Modals/EditKioskImageInformationModal';

export default class UploadKioskImage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			image: this.props.image,
			editKioskImageInformationModalIsOpen: false,
			confirmModalIsOpen: false,
			confirmElementDelete: false,
			ad_image_url: this.props.ad_image_url,
			tag: this.props.tag,
			displayError: false
		};
		this.openEditKioskImageInformationModal = this.openEditKioskImageInformationModal.bind(this);
		this.onSucefullyResponse = this.onSucefullyResponse.bind(this);
		this.onErrorResponse = this.onErrorResponse.bind(this);
	}

	openEditKioskImageInformationModal() {
		this.setState({editKioskImageInformationModalIsOpen: true, displayError: false});
	}

	closeModal() {
		this.setState({confirmModalIsOpen: false, editKioskImageInformationModalIsOpen: false, displayError: false})
	}

	closeImageModal(data) {
		if(data) {
			this.props.onsetKioskImageURL(data, this.onSucefullyResponse, this.onErrorResponse);
		} else {
			this.setState({editKioskImageInformationModalIsOpen: false});
		}
	}

	onSucefullyResponse(data) {
		this.setState({
			editKioskImageInformationModalIsOpen: false,
			ad_image_url: data ? data.ad_image_url : this.state.ad_image_url,
			tag: data ? data.tag : this.state.tag,
			displayError: false
		})
	}

	onErrorResponse(data) {
		this.setState({
			editKioskImageInformationModalIsOpen: true,
			displayError: true
		})
	}

	async setImage(file) {
		const renderUrl = await ImageService.convertToBase64(file)
		const dimensions = await ImageService.getImageDimensions(renderUrl)

		if ((this.props.requiredWidth && this.props.requiredHeight) && (dimensions.width !== this.props.requiredWidth && dimensions.height !== this.props.requiredHeight)) {
			return alert("Image size not right");
		}

		this.saveImage(file)
	}

	removeImage() {
		this.setState({confirmModalIsOpen:true})
	}

	saveImage(file, type) {
		this.props.onSave(file)
	}

	async uploadCustomImage(file){
		const renderUrl = await ImageService.convertToBase64(file)
		const dimensions = await ImageService.getImageDimensions(renderUrl)
		if ((this.props.requiredWidth && this.props.requiredHeight) && (dimensions.width !== this.props.requiredWidth && dimensions.height !== this.props.requiredHeight)) {
			return alert("Image size not right");
		}

		this.props.onUploadCustomImage(file)

	}

	componentDidMount() {
		this.setState({image: this.props.image, tag: this.props.tag})
	}

	componentDidUpdate(prevProps) {
		//set the state with data from props
		if(this.props.image !== prevProps.image) {
			this.setState({
				image: this.props.image,
				tag: this.props.tag
})
		}
	}

	render() {
		return (
			<Fragment>
				<div className="panel__head">
					{this.props.title}
					{(this.props.use_default_images) &&
						<div className="panel__end ">
								<a href="#donothing" onClick={(e) => {e.preventDefault()}} style={{color: '#C9243F', marginRight: "30px"}} data-tip data-for='default_image'><i className="material-icons v-mid m-right red">info</i> USGA Default Image is being used.</a>
								<ReactTooltip id='default_image' className="default-image__tooltip type-white " type="light" place='top' effect='float'>
									When no custom images have been uploaded, USGA Default Images will be used.
								</ReactTooltip>
								<Dropzone
								accept={[
									"image/png",
									"image/jpg",
									"image/jpeg",
								]}
								onDrop={(acceptedFiles) =>
									acceptedFiles.length > 0 &&
									this.uploadCustomImage(acceptedFiles[0])
								}
								multiple={false}
							>
								{({ getRootProps, getInputProps }) => (
									<div className="btn lnk" {...getRootProps()}>
									<input {...getInputProps()} />
									<button
										className="btn lnk">
										Upload Custom Image
									</button>
									</div>
								)}
							</Dropzone>
						</div>
					}
					{(this.props.removable && !this.props.readOnly) &&
					(this.props.image!=null && !this.props.use_default_images)&& <div className="panel__end">
					<button
						onClick={() => this.openEditKioskImageInformationModal()}
						className="btn lnk" style={{marginRight: "30px"}}>
						Edit
					</button>
						{!this.props.use_default_images && <button className="btn lnk red"
							onClick={() => this.setState({ confirmElementDelete: true })}>Delete
						</button>}
					</div>}
				</div>
				<div className="panel__body">
					<div className="upload__item">
						{this.state.image || this.props.use_default_images?
							<Fragment>
								<div className="upload__details">
									<div className="row">
										<div className="col is-1-of-2">
											<figure className="large" style={{width: "100%"}}>
												<img src={this.state.image} style={{width: "100%"}} alt=''/>
											</figure>
										</div>
										<div className="col is-1-of-2 jc-fs">
											<div className="upload_item">
												<h6 className="upload__label">Image Url:</h6>
												<a className="upload__url" href={this.state.image}>{this.state.image}</a>
												<h6 className="upload__label">Image Name:</h6>
												<span className="upload__info">{this.state.tag}</span>
											</div>
										</div>
									</div>
								</div>
								{!this.props.readOnly && !this.props.use_default_images && <div className="upload__controls list">
									<button
										className="btn fill gray"
										onClick={() => {this.removeImage()}}>
										<i className="material-icons-outlined no-margin">clear</i>
									</button>
								</div>}
							</Fragment> :
							(
							 <div className="upload__zone">
								 <Dropzone
									 accept={[
										 "image/png",
										 "image/jpg",
										 "image/jpeg",
									 ]}
									 onDrop={(acceptedFiles) =>
										 acceptedFiles.length > 0 &&
										 this.setImage(acceptedFiles[0])
									 }
									 multiple={false}
								 >
									 {({ getRootProps, getInputProps }) => (
										 <div
											 className="dropzone"
											 {...getRootProps()}
										 >
											 <input {...getInputProps()} />
											 <i className="material-icons-outlined">
												 cloud_upload
											 </i>
											 <p>
												 Drag &amp; Drop file here to
												 upload
											 </p>
											 <p>or</p>
											 <button className="btn fill green">
												 Choose from computer
											 </button>
										 </div>
									 )}
								 </Dropzone>
							 </div>
						 )}
					</div>
				</div>

				<Modal
					isOpen={this.state.editKioskImageInformationModalIsOpen}
					onRequestClose={() => {
						this.closeModal()
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					{this.state.editKioskImageInformationModalIsOpen &&
						<EditKioskImageInformationModal errors={ this.state.displayError ? this.props.errors : null} image={this.state.image} tag={this.props.tag} closeModal={(data) => this.closeImageModal(data)}/>
					}
				</Modal>

				<ConfirmationModal openModal={this.state.confirmModalIsOpen}
					onConfirmAction={()=>{this.setState({confirmModalIsOpen: false});this.props.onRemove()}}
					onCancelAction={()=>{this.setState({confirmModalIsOpen: false})}}
					closeModal={()=>{this.setState({confirmModalIsOpen: false})}}
					question={"Are you sure?"}
					cancelLabel={"No"}
					confirmLabel={"Yes"}/>

				<ConfirmationModal
					question={`Are you sure you want to delete this entry?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({confirmElementDelete: false})}
					onConfirmAction={() => {this.setState({confirmElementDelete: false});this.props.onDelete()}}
					openModal={this.state.confirmElementDelete}
					closeModal={() => this.setState({confirmElementDelete: false})}
				/>

			</Fragment>
		);
	}
}
