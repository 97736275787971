import React, {Component} from "react";
import AssociationService from "../../../../../services/api/association";
import {addAlert} from "../../../../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import UploadLogo from "../../../../../inputs/UploadLogo";

class HeaderContentSetup extends Component {
  constructor(props) {
      super(props);
      this.state = {}
  }

  saveLogo(file){
  	AssociationService.uploadEmailLogo(this.props.associationId, this.props.emailSettings.id, {logo: file})
  		.then(res => {
        this.props.addAlert({
					type:'success',
					message: 'Default Header Logo has been successfully uploaded'
				});
  			this.props.updateEmailSettings(res.email_settings);
  		})
  		.catch(err => {
  			console.log(err);
  		})
  	}
	removeLogo(){
		AssociationService.deleteEmailLogo(this.props.associationId, this.props.emailSettings.id)
			.then(res => {
        this.props.addAlert({
					type:'success',
					message: 'Default Header Logo has been successfully removed'
				});
				this.props.updateEmailSettings(res.email_settings);
			})
			.catch(err => {
				console.log(err);
			})
	}

	setAltText(alt_text, email_settings) {
    	AssociationService.editEmailSettings(this.props.associationId, this.props.emailSettings.id, {newsletter_logo_alt_text: alt_text, email_settings: email_settings})
			.then(res => {
        this.props.addAlert({
					type:'success',
					message: 'Default Header Setup has been successfully edited'
				});
				this.props.updateEmailSettings(res.email_settings);
			})
			.catch(err => {
				console.log(err)
			})
	}

  setCustomHTML(data, message = "Header Custom HTML has been successfully edited") {
    AssociationService.editEmailSettings(this.props.associationId, this.props.emailSettings.id, {email_settings: data})
    .then(res => {
      this.props.addAlert({
        type:'success',
        message: message,
      });
      this.props.updateEmailSettings(res.email_settings);
    })
    .catch(err => {
      console.log(err)
    })
  }

  setContentSetup(data){
    this.setCustomHTML(data, "Header Content type has been successfully modified")
  }

	render() {
    return (
			<div className="row">
				<div className="col">
					<div className="panel">
						<UploadLogo title={`Header Content Setup`}
							 readOnly={!this.props.canEditAssociation}
							 image={this.props.emailSettings.newsletter_logo}
							 alt_text={this.props.emailSettings.newsletter_logo_alt_text}
               email_settings={this.props.emailSettings}
							 square={true}
							 logo={true}
							 onSave={(data) => {this.saveLogo(data)}}
							 onRemove={() => {this.removeLogo()}}
							 onSetAltText={(alt_text, email_settings) => this.setAltText(alt_text, email_settings)}
							 onSaveSettings={(data) => this.setCustomHTML(data)}
							 onChangeSetup={(data) => this.setContentSetup(data)}
						/>
					</div>
				</div>
			</div>
    )
  }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({addAlert}, dispatch);
    return {
        dispatch,
        ...actions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContentSetup);
