import React, {Component, Fragment} from 'react';

import EditAssociationForm from '../Forms/EditAssociationForm';

export default class EditAssociationModal extends Component {

	render() {
		return (
            <Fragment>
                <div className="modal__container narrow">
                    <div className="modal__head">
                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">{this.props.assoc.name}</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
                    </div>
                    <div className="modal__body">
                        <EditAssociationForm assoc={this.props.assoc} close={()=>this.props.closeModal()}/>
                    </div>
                </div>
            </Fragment>
		);
	}
}
