import React, {Component, Fragment} from 'react';

import Merge from './Merge';
import UnMerge from './UnMerge';

export default class GolferMerges extends Component {


	constructor(props) {
		super(props);
    let defaultPage = 'merge';
    if (props.fromAccountCommand) {
      defaultPage = props.fromAccountCommand.pageType;
    }
		this.state = {
			selectedAction: defaultPage
		}
	}

	changeAction(e, selectedAction) {
		this.setState({
			selectedAction
		})
	}

	render() {
		return (
            <Fragment>

                <div className="columns">
                    <div className="row">
						<div className="col is-1-of-6">
							<div className="custom-radio__container">
								<input id={'merge'} type={'radio'} name={'merge'} onChange={(e) => this.changeAction(e, 'merge')} checked={this.state.selectedAction === 'merge'}/>
								<label htmlFor="merge">Merge</label>
								<input id={'unmerge'} type={'radio'} name={'unmerge'} onChange={(e) => this.changeAction(e, 'unmerge')} checked={this.state.selectedAction === 'unmerge'}/>
								<label htmlFor="unmerge">Unmerge</label>
							</div>
						</div>

                    </div>
					{this.state.selectedAction === 'unmerge' && <div className="row">
						<div className={"col jc-c"}>
							<span><span className={"bold red"}>Important:</span>  Unmerging records will return them to their original state.  Any scores posted to the merged record will be lost.  Please export the golfers scoring record prior to unmerging the records.</span>
						</div>
					</div>}
                    <div className="row">
						{this.state.selectedAction === 'merge' && <Merge superUser={this.props.superUser} fromAccountCommand={this.props.fromAccountCommand}/>}
						{this.state.selectedAction === 'unmerge' && <UnMerge fromAccountCommand={this.props.fromAccountCommand}/>}
                    </div>

                </div>

            </Fragment>
		);
	}
}
