import request from '../shared/request';

/**
 * todo: rename this from Club to something more relevant
 */
export default class ClubService {
    static getGolfAssociations(data) {
        return request({
            url: `/golf_associations.json${data ? '?global_search=true' : ''}`,
            method: 'GET',
        })
    }

    static createClub(association_id, data) {
        return request({
            url: `/associations/${association_id}/clubs.json`,
            method: 'POST',
            data
        })
    }

    static specialUpdate(associationId, clubIds, select_all, params) {
    	let data = {};
    	if (select_all) {
    		data.unchecked_items = clubIds;
    		data.clubs_ids = [];
		} else {
    		data.club_ids = clubIds;
		}
        return request({
            url: `/associations/${associationId}/special_update.json`,
            method: 'POST',
            data,
			params
        });
    }

    static getClubRoster(association, clubId, params) {
		return request({
			url: `/clubs/${clubId}/golfers.json`,
			params: {
				...params
			}
		})
	}

	static getClubRosterExport(association, clubId, params) {
		return request({
			url: `/clubs/${clubId}/golfers/export_to_excel.json`,
			params,
			method: "GET"
		})
	}

	static getSpecialUpdateCsv(clubId, url) {
		return request({
			url: `/clubs/${clubId}/get_csv.json`,
			params: {url: url},
			method: 'GET'
		})
	}

    static getClub(associationId, clubId) {
        return request({
            url: `/associations/${associationId}/clubs/${clubId}.json`,
            method: "GET"
        }).then((data) => {
            return data.club;
        })
    }

    static getClubs(params) {
        return request({
            url: `/clubs.json`,
            params
        })
    }

    static getClubHomeCourses(associationId, clubId) {
		return request({
			url: `/clubs/${clubId}/facility_home_courses.json`,
			method: "Get"
		})
	}

	static addClubHomeCourses(clubId, data) {
		return request({
			url: `/clubs/${clubId}/facility_home_courses.json`,
			method: "POST",
			data
		})
	}

	static updateClubHomeCourses(facilityId,clubId, data) {
		return request({
			url: `/clubs/${clubId}/facility_home_courses/${facilityId}.json`,
			method: "PATCH",
			data
		})
	}


	static getKiosks(associationId, clubId) {
		return request({
			url: `/associations/${associationId}/clubs/${clubId}/kiosks.json`,
			method: "GET"
		})
	}

	static addKiosk(associationId, clubId, data) {
    	return request({
			url: `/associations/${associationId}/clubs/${clubId}/kiosks.json`,
			method: "POST",
			data
		})
	}

	static updateKiosk(associationId, clubId, kioskId, data) {
		return request({
			url: `/associations/${associationId}/clubs/${clubId}/kiosks/${kioskId}.json`,
			method: "PATCH",
			data
		})
	}

	static deleteKiosk(associationId, clubId, kioskId) {
		return request({
			url: `/associations/${associationId}/clubs/${clubId}/kiosks/${kioskId}.json`,
			method: "DELETE"
		})
	}
	static removeClubHomeCourses(facilityId, facilityNumber, clubId, courseId) {
		return request({
			url: `/clubs/${clubId}/facility_home_courses/${facilityNumber}.json`,
			method: "PATCH",
			data: {facility: { facility_id: facilityId, homeCourses: [{delete: true, id: courseId}]}}

		})
	}
	static getClubAddresses(associationId, clubId) {
		return request({
			url: `/associations/${associationId}/clubs/${clubId}.json`,
			method: "GET"
		})
	}

	static editPrimary(associationId,clubId, data) {

		let bodyFormData = new FormData();
		Object.keys(data).forEach((key) => {
			bodyFormData.append('club['+key+']', data[key]);
		});


		return request({
			url: `/associations/${associationId}/clubs/${clubId}.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static editClubLogo(associationId,clubId, data) {
		let bodyFormData = new FormData();
		Object.keys(data).forEach((key) => {
			bodyFormData.append(key, data[key]);
		});


		return request({
			url: `/associations/${associationId}/clubs/${clubId}.json`,
			method: 'PATCH',
			data: bodyFormData
		})
	}

	static editAddress(associationId,clubId, data) {
		return request({
			url: `/associations/${associationId}/clubs/${clubId}.json`,
			method: 'PATCH',
			data: data
		})
	}

	static activateClubs(associationId, data, params) {

		return request({
			url: `/associations/${associationId}/clubs/activate.json`,
			method: "POST",
			params: {...params},
			data
		})
	}

	static inactivateClubs(associationId, data, params){
		return request({
			url: `/associations/${associationId}/clubs/inactivate.json`,
			method: "POST",
			params: {...params},
			data
		})
	}

	static getTeeAGS(params, tee_set_side) {
		return request({
			url: 'maximum_hole_scores.json',
			method: 'GET',
			params: {tee_set_side: tee_set_side || 'All18', ...params}
		})
	}

	static getMembershipTypes(clubId, params = {}) {
		return request({
			url: `/clubs/${clubId}/membership_types.json`,
			method: 'GET',
      params: params
		})
	}

	static addMembershipType(data, params) {
		return request({
			url: `/clubs/membership_types.json`,
			method: 'POST',
			data,
			params: {admin: true, ...params},
		})
	}

	static removeMembershipType(data, params) {
		return request({
			url: `/clubs/membership_types.json`,
			method: 'DELETE',
			data: data,
			params: {admin: true, ...params},
		})
	}

	static getLogs(clubId, params) {
		return request({
			url: `/clubs/${clubId}/logs.json`,
			method: 'GET',
			params
		});
	}

  static getMembershipFees(clubId) {
    return request({
      url: `/clubs/${clubId}/club_membership_fees.json`,
      method: 'GET'
    })
  }

  static addMembershipFee(clubId, data) {
    return request({
      url: `/clubs/${clubId}/club_membership_fees.json`,
      method: 'POST',
      data
    })
  }

  static removeMembershipFee(clubId, id) {
    return request({
      url: `/clubs/${clubId}/club_membership_fees/${id}.json`,
      method: 'DELETE'
    })
  }

  static editMembershipFee(clubId, id, data) {
    return request({
      url: `/clubs/${clubId}/club_membership_fees/${id}.json`,
      method: 'PATCH',
      data
    })
  }

	static getArchivedMembershipFees(clubId, id) {
		return request({
			url: `/clubs/${clubId}/club_membership_fees/${id}/edit_logs.json`,
			method: 'GET'
		})
	}

  static enableJoinAndRenew(clubId) {
    return request({
      url: `/clubs/${clubId}/enable_join_and_renew.json`,
      method: 'POST'
    })
  }

  static disableJoinAndRenew(clubId) {
    return request({
      url: `/clubs/${clubId}/disable_join_and_renew.json`,
      method: 'POST'
    })
  }

  static markAsDefault(clubId, id) {
    return request({
      url: `/clubs/${clubId}/membership_types/${id}/mark_as_default.json`,
      method: 'POST'
    })
  }
}
