import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import SharedService from '../services/api/shared';
import EditImageInformationModal from "../screens/Modals/EditImageInformationModal";
import ConfirmationModal from "../screens/Modals/ConfirmationModal";
import { ImageService } from "../services/imageService";

export default class UploadDefaultMobileImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editImageInformationModalIsOpen: false,
			image: this.props.image,
			ad_image_url: this.props.ad_image_url,
			mobile_image1_ad_tag: this.props.mobile_image1_ad_tag,
			mobile_image2_ad_tag: this.props.mobile_image2_ad_tag,
			mobile_deep_links: null,
			url_label: null,
			confirmModalIsOpen: false,
			displayError: false,
			display_image_name: this.props.display_image_name,
		};

		this.openEditImageInformationModal = this.openEditImageInformationModal.bind(
			this
		);
		this.onSucefullyResponse = this.onSucefullyResponse.bind(this);
		this.onErrorResponse = this.onErrorResponse.bind(this);
	}

	openEditImageInformationModal() {
		this.setState({
			editImageInformationModalIsOpen: true,
			displayError: false,
		});
	}

	closeModal() {
		this.setState({
			confirmModalIsOpen: false,
			editImageInformationModalIsOpen: false,
			displayError: false,
		});
	}

	closeImageModal(data) {
		if (data) {
			this.props.onsetImageURL(
				data,
				this.onSucefullyResponse,
				this.onErrorResponse
			);
		} else {
			this.setState({ editImageInformationModalIsOpen: false });
		}
	}

	onSucefullyResponse(data) {
		this.setState({
			editImageInformationModalIsOpen: false,
			ad_image_url: data ? data.ad_image_url : this.state.ad_image_url,
			mobile_image1_ad_tag: data
				? data.mobile_image1_ad_tag
				: this.state.mobile_image1_ad_tag,
			mobile_image2_ad_tag: data
				? data.mobile_image2_ad_tag
				: this.state.mobile_image2_ad_tag,
			displayError: false,
		});
		if (this.props.is_deep_link) {
			const url = this.state.ad_image_url;
			this.setState({ url_label: this.state.mobile_deep_links.find(function(obj) {
					return obj.value == url;
			}).label });
		}
	}

	onErrorResponse(data) {
		this.setState({
			editImageInformationModalIsOpen: true,
			displayError: true,
		});
	}

	async setImage(file) {
		const renderUrl = await ImageService.convertToBase64(file);
		const dimensions = await ImageService.getImageDimensions(renderUrl);

		if (
			this.props.requiredWidth &&
			this.props.requiredHeight &&
			dimensions.width !== this.props.requiredWidth &&
			dimensions.height !== this.props.requiredHeight
		) {
			return alert("Image size not right");
		}

		this.saveImage(file);
	}

	removeImage() {
		this.setState({ confirmModalIsOpen: true });
	}

	saveImage(file, type) {
		this.props.onSave(file);
	}

	loadMobileDeepLinks() {
		SharedService.getMobileDeepLinks()
		.then(res=>{
				this.setState({
						mobile_deep_links: res['mobile_deep_links'],
				})
				if (this.props.is_deep_link) {
					const url = this.state.ad_image_url;
					this.setState({ url_label: this.state.mobile_deep_links.find(function(obj) {
							return obj.value == url;
					}).label });
				}
		})
		.catch(err => {
				console.error(err);
		})
	}

	componentDidMount() {
		this.setState({ image: this.props.image });
		this.loadMobileDeepLinks();
	}

	componentDidUpdate(prevProps) {
		//set the state with data from props
		if (
			this.props.image !== prevProps.image ||
			this.props.ad_image_url !== prevProps.ad_image_url
		) {
			this.setState({
				image: this.props.image,
				mobile_image1_ad_tag: this.props.mobile_image1_ad_tag,
				mobile_image2_ad_tag: this.props.mobile_image2_ad_tag,
				ad_image_url: this.props.ad_image_url,
			});
		}
	}

	render() {
		return (
			<Fragment>
				<div className="panel__head">{this.props.title}</div>
				<div className="panel__body">
					<div className="upload__item">
						{this.state.image ? (
							<Fragment>
								<div className="upload__details">
									<div className="row">
										<div className="col is-1-of-2">
											<figure
												className="large"
												style={{ width: "100%" }}
											>
												<img
													src={this.state.image}
													style={{ width: "100%" }}
													alt=''
												/>
											</figure>
										</div>
										<div className="col is-1-of-2 jc-fs">
											<div className="upload_item">
												<h6 className="upload__label">
													Image Url:
												</h6>
												<a
													className="upload__url"
													href={this.state.image}
												>
													{this.state.image}
												</a>
												<h6 className="upload__label">
													{this.props.is_deep_link ? "Deep Link" : "Redirect URL"}
												</h6>
												<span className="upload__info">
													{this.props.is_deep_link ? this.state.url_label : this.state.ad_image_url}
												</span>
												<h6 className="upload__label">
													Image Name:
												</h6>
												<span className="upload__info">
													{this.state
														.mobile_image1_ad_tag ||
														this.state
															.mobile_image2_ad_tag}
												</span>
											</div>
										</div>
									</div>
								</div>

								{
									<div className="upload__controls list">
										<button
											onClick={() =>
												this.openEditImageInformationModal()
											}
											className="btn lnk"
										>
											Edit
										</button>
										<button
											disabled={!this.state.image}
											className="btn fill gray"
											onClick={() => {
												this.removeImage();
											}}
										>
											<i className="material-icons-outlined no-margin">
												clear
											</i>
										</button>
									</div>
								}
							</Fragment>
						) : (
							<div className="upload__zone">
								<Dropzone
									accept={[
										"image/png",
										"image/jpg",
										"image/jpeg",
									]}
									onDrop={(acceptedFiles) =>
										acceptedFiles.length > 0 &&
										this.setImage(acceptedFiles[0])
									}
									multiple={false}
								>
									{({ getRootProps, getInputProps }) => (
										<div
											className="dropzone"
											{...getRootProps()}
										>
											<input {...getInputProps()} />
											<i className="material-icons-outlined">
												cloud_upload
											</i>
											<p>
												Drag &amp; Drop file here to
												upload
											</p>
											<p>or</p>
											<button
												disabled={this.state.image}
												className="btn fill green"
											>
												Choose from computer
											</button>
										</div>
									)}
								</Dropzone>
							</div>
						)}
					</div>
				</div>

				<Modal
					isOpen={this.state.editImageInformationModalIsOpen}
					onRequestClose={() => {
						this.closeModal();
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					{this.state.editImageInformationModalIsOpen && (
						<EditImageInformationModal
							display_image_name={this.state.display_image_name}
							errors={
								this.state.displayError
									? this.props.errors
									: null
							}
							image={this.state.image}
							has_ad_image_url={true}
							ad_image_url={this.state.ad_image_url}
							remove_alt_text={true}
							mobile_image1_ad_tag={
								this.props.mobile_image1_ad_tag
							}
							mobile_image2_ad_tag={
								this.props.mobile_image2_ad_tag
							}
							mobile_deep_links = {
								this.state.mobile_deep_links
							}
							is_deep_link={this.props.is_deep_link}
							closeModal={(data) => this.closeImageModal(data)}
						/>
					)}
				</Modal>

				<ConfirmationModal
					openModal={this.state.confirmModalIsOpen}
					onConfirmAction={() => {
						this.setState({ confirmModalIsOpen: false });
						this.props.onRemove();
					}}
					onCancelAction={() => {
						this.setState({ confirmModalIsOpen: false });
					}}
					closeModal={() => {
						this.setState({ confirmModalIsOpen: false });
					}}
					question={"Are you sure?"}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
				/>
			</Fragment>
		);
	}
}
