import React, {Component, Fragment} from 'react';
import EditKioskForm from "../Forms/EditKioskForm";

export default class EditKioskModal extends Component {

	render() {
		return (
			<Fragment>

				<div className="modal__container narrow">

					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
						<h2 className="modal__title">Edit Kiosk</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">
						<EditKioskForm data={this.props.data} closeModal={this.props.closeModal} clubId={this.props.clubId} associationId={this.props.associationId}/>
					</div>

				</div>

			</Fragment>
		);
	}
}
