import React, { Component, Fragment } from 'react';
import Modal from "react-modal";

export default class ExportToHubspotModal extends Component {

  render() {
    return (
      <Modal
        isOpen={true}
        onRequestClose={this.props.closeModal}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
      >
        <Fragment>

          <div className="modal__container narrow">

            <div className="modal__head">

              <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
              <h2 className="modal__title">Hubspot Setup</h2>
              <button className="modal__close" onClick={this.props.closeModal}><i className="material-icons-outlined">clear</i></button>

            </div>

            <div className="modal__body">
              <div className="row">
                <div className="col">
                  <span>We will start processing the initial sync in the background. This process may take a while.</span>
                </div>
              </div>

              <div className="row">
                <div className="col is-1-of-2">
                  <button type={'button'} className="btn fill gray" onClick={this.props.closeModal}>Cancel</button>
                </div>
                <div className="col is-1-of-2">
                  <button type={'submit'} className="btn fill blue" onClick={this.props.onSubmit}>Connect</button>
                </div>
              </div>
            </div>

          </div>

        </Fragment>
      </Modal>
    );
  }
}
