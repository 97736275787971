import axios from 'axios'
import { onError, onSuccess } from './responseHandler'

const client = (() => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL
    });
})();

const request = function (options, store) {
    options.params = {
        ...options.params
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};


export default request;
