import React, {Component} from 'react';
class SelectedGuardianInfo extends Component {
	render() {
		const {first_name, middle_name, last_name, phone_number, email} = this.props.guardian;

		let entries = [];

		entries.push(['Name', `${[first_name, middle_name, last_name].join(" ")}`]);

		if (phone_number) {
			entries.push(['Phone Number', phone_number]);
		}

		if (email) {
			entries.push(['Email', email]);
		}


		return (
			<>
				{
					entries.map((entry) => {
						return (
							<div className="row with-padding ">
								{entry[0]}: &#xA0; <strong>{entry[1]}</strong>
							</div>
						)
					})
				}
			</>
		)
	}
}
export  default SelectedGuardianInfo;
