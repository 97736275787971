import React, {Component, Fragment} from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import {addAlert} from '../../../shared/actions'
import {bindActionCreators} from "redux";
import AssociationTable from "./AssociationTable";
import {GolfAssociationGroup} from "../../../../services/api/federations";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import {connect} from "react-redux";
import AssociationService from "../../../../services/api/association";
import AddClubGroup from "../../../Modals/AddClubGroup";
import EditClubGroup from "../../../Modals/EditClubGroup";
import TableFilter from "./TableFilter";
import difference from 'lodash.difference';
import differenceBy from 'lodash.differenceby';
import uniqBy from 'lodash.uniqby';
import TableService from "../../../../services/tableService";

class AssociationGroup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			addAssocGroupModalIsOpen: false,
			editAssocGroupModalIsOpen: false,
			openDeleteConfirmation: false,
			checkAllAvailable: false,
			checkAllSelected: false,
			loaded: false,
			availableClubs: [],
			initialClubsState: [],
			selectedClubs: [],
			assocGroupOptions: [],
			selectedGroup: null,
			tempMovedAssociations: [],
			filters: [],
			initialClubsLength: 0,
			membershipTypes: []
		};
		this.openAddAssocGroupModal = this.openAddAssocGroupModal.bind(this);
		this.toggleAvailableClubs = this.toggleAvailableClubs.bind(this);
		this.togleSelectedClub = this.togleSelectedClub.bind(this);
		this.toggleAllAvailable = this.toggleAllAvailable.bind(this);
		this.toggleAllSelected = this.toggleAllSelected.bind(this);
		this.onGroupChange = this.onGroupChange.bind(this);
		this.deleteGroup = this.deleteGroup.bind(this);
	}

	openAddAssocGroupModal() {
		this.setState({addAssocGroupModalIsOpen: true});
	}

	closeModal() {
		this.setState({
			addAssocGroupModalIsOpen: false,
		});
	}

	getGroups(callback) {
		AssociationService.getClubGroups(this.props.match.params.id)
			.then(response => {
				let assocGroupOptions = response.club_groups.map(club => {
					club.value = club.id;
					club.label = club.name;
					return club;
				});
				this.setState({
					assocGroupOptions
				}, callback)
			})
			.catch(err => {

			});
	}

	deleteGroup() {
		GolfAssociationGroup.delete(this.state.selectedGroup.id)
			.then(() => {
				this.props.addAlert({type:'success',message:'Group has been successfully deleted'});
				this.setState({
					selectedGroup: null,
					openDeleteConfirmation: false
				});
				this.getGroups();
				this.onGroupChange(null)
			})
			.catch(err => {

			})
	}

	updateGroup() {
		let selectedClubs = this.state.selectedClubs.map(association => association.id);
		let data = {name: this.state.selectedGroup.name, club_ids: selectedClubs}
		AssociationService.updateClubGroup(this.props.match.params.id, this.state.selectedGroup.id, data)
			.then(response => {
				this.props.addAlert({type:'success',message:'Group has been successfully saved'});
				let selectedGroup = response.club_group;
				selectedGroup.label = selectedGroup.name;
				selectedGroup.value = selectedGroup.id;
				this.setState({
					selectedGroup
				});
				this.getGroups();
			})
			.catch(err => {
				console.error(err);
			})
	}

	getClubs() {
		let params = {};

		params = {
			...params,
			...TableService.filtersArrayToParams(this.state.filters)
		};

		if (this.state.selectedGroup) {
			params['not_in_group'] = this.state.selectedGroup.id;
		}
		AssociationService.getAllClubs(this.props.match.params.id, params)
			.then(response => {
				let data = response.clubs;
				data.map(club => {club.checked = false; club.name = club.club_name; return club});
				data = differenceBy(data, this.state.selectedClubs, (club) => club.id);
				data = uniqBy([...data, ...this.state.tempMovedAssociations], v => v.id);
				this.setState({
					loaded: true,
					availableClubs: data,
					initialClubsState: data
				});
			});
	}

	getMembershipTypes(){
		this.setState({membershipLoading: true});
		AssociationService.getMembershipTypes(this.props.match.params.id, {include_used_membership: 'false'})
			.then(res => {
				this.setState({
					membershipTypes: res.membership_types.map(membershipType => {
						return {label: membershipType.code + ' - ' + membershipType.description, value: membershipType.id};
					}).sort((a, b) => a['label'].toString().localeCompare(b['label'].toString()))
				});
			})
			.catch(err => {
				this.setState({membershipLoading: false, membershipTypes: []});
			})
	}

	componentDidMount() {
		Modal.setAppElement('body');
		this.getGroups();
		this.getClubs();
		this.getMembershipTypes();

		AssociationService.getAllClubs(this.props.match.params.id, {})
			.then(response => {
				let data = response.clubs;
				data.map(club => {club.checked = false; club.name = club.club_name; return club});
				this.setState({
					initialClubsLength: data.length
				});
			});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.selectedClubs !== this.state.selectedClubs) {
			let availableClubs = difference(this.state.availableClubs, this.state.selectedClubs);
			this.setState({
				availableClubs
			})
		}
	}

	toggleAvailableClubs(index, checked) {
		let associations = this.state.availableClubs;
		associations.filter(assoc => {
			if (assoc.id === index) {
				assoc.checked = checked;
			}
			return assoc;
		});
		this.setState({
			availableClubs: associations
		})
	}

	togleSelectedClub(index, checked) {
		let associations = this.state.selectedClubs;
		associations.filter(assoc => {
			if (assoc.id === index) {
				assoc.checked = checked;
			}
			return assoc;
		});
		this.setState({
			selectedClubs: associations
		})
	}

	toggleAllAvailable(checked) {
		let availableClubs = this.state.availableClubs;
		availableClubs.map(assoc => assoc.checked = checked);
		this.setState({
			availableClubs,
			checkAllAvailable: checked
		});
	}

	toggleAllSelected(checked, callback) {
		let selectedClubs = this.state.selectedClubs;
		selectedClubs.map(assoc => assoc.checked = checked);
		this.setState({
			selectedClubs,
			checkAllSelected: checked
		}, callback);
	}

	moveSelectedClubs(direction) {
		if (direction === 'right') {
			let filteredClubs = this.state.availableClubs.filter(assoc => assoc.checked === true).map(assoc => {assoc.checked = false; return assoc;});
			let selectedClubs = [...this.state.selectedClubs, ...filteredClubs];
			let availableClubs = this.state.availableClubs.filter(assoc => selectedClubs.indexOf(assoc) < 0);
			this.setState({
				selectedClubs,
				availableClubs,
				checkAllAvailable: false
			})
		} else {
			let selectedClubs = this.state.selectedClubs.filter(assoc => assoc.checked !== true);
			let filteredClubs = this.state.selectedClubs.filter(assoc => assoc.checked === true).map(assoc => {assoc.checked = false; return assoc;});
			let selectedavailableClubs = [...this.state.availableClubs, ...filteredClubs];

			let tempMovedAssociations = uniqBy([...this.state.tempMovedAssociations, ...filteredClubs], (assoc) => assoc.id)
			this.setState({
				availableClubs: selectedavailableClubs,
				tempMovedAssociations,
				selectedClubs,
				checkAllSelected: false
			})
		}
	}

	onGroupChange(selectedGroup) {
		this.setState({
			availableClubs: this.state.initialClubsState.map(c => {c.checked = false; return c}),
			selectedClubs: []
		}, () => {
			if (selectedGroup) {
				selectedGroup.clubs.map(gassoc => {
					this.toggleAvailableClubs(gassoc.club_id, true);
					return null;
				});
				this.moveSelectedClubs('right');
			}
			this.setState({
				selectedGroup
			})
		});

	}

	updateFilter(filters) {
		this.setState({
			filters
		}, () => {
			this.getClubs();
		})

	}


	render() {
		let checkedAssociationsLength = this.state.availableClubs.filter(a => a.checked).length;
		let selectedClubsLength = this.state.selectedClubs.filter(a => a.checked).length;
		return (
			<Fragment>



				<div className="assoc-g__head row">
					<div className="col is-1-of-4">
						<Select
							className="react-select-container"
							classNamePrefix="react-select"
							isDisabled={!this.state.loaded}
							onChange={this.onGroupChange}
							options={this.state.assocGroupOptions.sort((a, b) => a['name'].toString().localeCompare(b['name'].toString()))}
							isSearchable={false}
							value={this.state.selectedGroup}
						/>
					</div>
					{this.props.canEditAssociation && this.state.selectedGroup &&
					<div className="col is-1-of-4 push-left">
						<ul className="assoc-g__controls">
							<li><button className="btn" onClick={(e) => {e.preventDefault(); this.setState({editAssocGroupModalIsOpen: true})}}>Edit Name</button></li>
							<li><button className="btn" onClick={(e) => {e.preventDefault(); this.setState({openDeleteConfirmation: true})}}>Delete</button></li>
						</ul>
					</div>
					}
					{this.props.canEditAssociation && <div className="col is-1-of-8 push-right">
						<button className="btn fill green" onClick={this.openAddAssocGroupModal} >Add Group</button>
					</div>}
				</div>
				<TableFilter updateFiltered={this.updateFilter.bind(this)} membershipTypes={this.state.membershipTypes}/>
				<div className="assoc-g__body row">
					<div className="col is-5-of-11">
						<div className="assoc-g__column">
							<div className="assoc-g__column__head">
								Available Clubs ({this.state.initialClubsLength}) {this.state.initialClubsLength > this.state.availableClubs.length && <Fragment> Filtered Clubs ({this.state.availableClubs.length})</Fragment>}
							</div>
							<div className="assoc-g__column__body">
								<AssociationTable mainLabelName={"Club"} checkAll={this.state.checkAllAvailable} isMain={true} toggleAllAvailable={this.toggleAllAvailable} toggleAvailableAssociation={this.toggleAvailableClubs} associations={this.state.availableClubs} associationId={this.props.match.params.id}/>
							</div>
						</div>
					</div>
					<div className="col is-1-of-11">
						{this.props.canEditAssociation &&<div className="assoc-g__transition-controls">
							<button disabled={!this.state.selectedGroup || !checkedAssociationsLength} className={`btn fill ${(!this.state.selectedGroup || !checkedAssociationsLength) ? 'gray' : 'blue'}`} onClick={() => this.moveSelectedClubs("right")}><i className="material-icons-outlined">chevron_right</i></button>
							<button disabled={!this.state.selectedGroup || !selectedClubsLength} className={`btn fill ${(!this.state.selectedGroup || !selectedClubsLength) ? 'gray' : 'blue'}`} onClick={() => this.moveSelectedClubs("left")}><i className="material-icons-outlined">chevron_left</i></button>
						</div>}
					</div>
					<div className="col is-5-of-11">
						<div className="assoc-g__column">
							<div className="assoc-g__column__head">
								{this.state.selectedGroup && (`${this.state.selectedGroup.name} (${this.state.selectedClubs.length})`)}
							</div>
							<div className="assoc-g__column__body">
								<AssociationTable mainLabelName={"Club"} checkAll={this.state.checkAllSelected} isMain={false} toggleAllSelected={this.toggleAllSelected} toggleSelectedAssociation={this.togleSelectedClub} associations={this.state.selectedClubs} associationId={this.props.match.params.id}/>
							</div>
						</div>
					</div>
				</div>

				{this.props.canEditAssociation && <div className="assoc-g__foot row">
					<div className="col is-1-of-8 push-right">
						<button disabled={!this.state.selectedGroup} className="btn outline gray" onClick={(e) => {e.preventDefault(); this.onGroupChange(null)}}>Cancel</button>
					</div>
					<div className="col is-1-of-8">
						<button disabled={!this.state.selectedGroup} className="btn fill blue" onClick={(e) => {e.preventDefault(); this.updateGroup()}}>Save Group</button>
					</div>
				</div>}

				<Modal
					isOpen={this.state.addAssocGroupModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Add Association Group"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddClubGroup associationId={this.props.match.params.id} selectGroup={(group) => {this.getGroups(this.onGroupChange(group))}} closeModal={()=>{this.closeModal()}} />
				</Modal>

				<Modal
					isOpen={this.state.editAssocGroupModalIsOpen}
					onRequestClose={()=>{this.setState({editAssocGroupModalIsOpen: false})}}
					contentLabel="Add Association Group"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditClubGroup associationId={this.props.match.params.id} selectGroup={(group) => {this.getGroups(this.onGroupChange(group))}} closeModal={() => this.setState({editAssocGroupModalIsOpen: false})} selectedGroup={this.state.selectedGroup} />
				</Modal>

				<ConfirmationModal
					question={`Are you sure you want to delete ${this.state.selectedGroup ? this.state.selectedGroup.name : ''}?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({openDeleteConfirmation: false})}
					onConfirmAction={this.deleteGroup}
					openModal={this.state.openDeleteConfirmation}
					closeModal={() => this.setState({openDeleteConfirmation: false})}
				/>

			</Fragment>
		);
	}
}


function mapDispatchToProps(dispatch) {
	return bindActionCreators({addAlert}, dispatch);
}

export default connect(null, mapDispatchToProps)(AssociationGroup);
