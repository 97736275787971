import axios from 'axios';


import request from "../shared/request";
import requestPreview from "../shared/requestPreview";


export default class SharedService {

    static globalSearch(params) {
        return request({
            url: `/global_search.json`,
            params,
            method: "GET"
        })
    }


	static getTimezones() {
		return request({
			url: `/time_zones.json`,
			method: 'GET'
		})
	}

    static getCountriesAndStates() {
        return request({
            url: `/get_countries_and_states.json`,
            method: 'GET'
        })
    }

    static getLocationByPostalCode(code){
		return (axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${code}&key=AIzaSyA6lLufwnuXAob_PJubDYGjVPAkD-3HSKs&libraries=places`))
	}

	static downloadFile(data, fileName, type="text/plain") {
        // Create an invisible A element
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);

        // Set the HREF to a Blob representation of the data to be downloaded
        a.href = window.URL.createObjectURL(
            new Blob([data], { type })
        );

        // Use download attribute to set set desired file name
        a.setAttribute("download", fileName);

        // Trigger the download by simulating click
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }

    static arrayUnique(array) {
    	return [...new Set(array)];
        // let a = array.concat();
        // for(let i=0; i<a.length; ++i) {
        //     for(let j=i+1; j<a.length; ++j) {
        //         if(a[i] === a[j])
        //             a.splice(j--, 1);
        //     }
        // }
        // return a;
    }


    static getReports(userId, type){
		return requestPreview({
			url: `${process.env.REACT_APP_API_URL.replace('/api/v1', '')}/reports/${userId}/${type}`,
			method: 'GET'
		})
	}

	static getFederationTerms() {
		return request({
			url: `/terms.json`,
			method: 'GET'
		})
	}

	static acceptTerms() {
		return request({
			url: `/accept_terms.json`,
			method: 'POST'
		})
	}

	static resendDigitalProfileInvitationEmail(data, associationId) {
		return request({
			url: `/resend_email_after_ghin_account_verification.json${associationId ? '?association_id=' + associationId : ''}`,
			method: 'POST',
			data
		})
	}

  static getCodeChanges(object_type) {
    return request({
      url: `/logs/change_codes.json${object_type ? '?object_type=' + object_type : ''}`,
      method: 'GET'
    })
  }

  static getMobileDeepLinks() {
    return request({
      url: `/mobile_deep_links.json`,
      method: 'GET'
    })
  }
}
