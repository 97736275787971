import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
	required,
	maxLength,
	handicapIndex,
	golfer_age,
	number_of_scores,
	checkSignIsSelected,
	isNumeric,
	isUrl,
} from "../../inputs/validations";
import { InputField } from "../../inputs/InputField";
import { addAlert } from "../shared/actions";
import { SelectInput } from "../../inputs/Select";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { DatePickerInput } from "../../inputs/DatePicker";
import { Textarea } from "../../inputs/Textarea";
import GhinFilterSelect from "../shared/GhinFilterSelect";
import notificationCategories from "../shared/pushNotificationCategories.json";
import AssociationService from "../../services/api/association";
import UploadPushNotificationImage from "../../inputs/UploadPushNotificationImage";
import moment from "moment";

const genderOptions = [
	{ value: "M", label: "Male" },
	{ value: "F", label: "Female" },
];

const statusOptions = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

class EditPushNotificationForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			submit_approval: false,
			now: true,
			membershipTypes: [],
			gender: [],
			golfer_status: [],
			selectedMembershipTypes: [],
			clubs: [],
			clubGroups: [],
			selectedClubGroups: [],
			selectedClubs: [],
			clubCategory: [],
			date: null,
			expiration_date: null,
			image: null,
			image_url: null,
			notification_text: "",
			notification_category: [],
			external_link: "",
			handicap_index: "",
			golfer_age: "",
			number_of_scores: "",
			time: "",
			day_time: "",
			exp_time: "",
			exp_day_time: "",
			show_expired: false,
			delete_image: false,
			btn1_blue: false,
			btn2_blue: false,
			btn3_blue: false,
			btn4_blue: false,
			btn5_blue: false,
			btn6_blue: false,
			btn7_blue: false,
			btn8_blue: false,
			btn9_blue: false,
			pristine: true,
		};
		this.onSubmit = this.onSubmit.bind(this);
		
	}

	componentDidMount() {
		let push_notification_setting = this.props.push_notification_setting;
		let selectedMembershipTypes = push_notification_setting.audience
			.membership_codes
			? push_notification_setting.audience.membership_codes.map(
					(membershipType) => {
						return {
							label:
								membershipType.code +
								" - " +
								membershipType.description,
							value: membershipType.id,
						};
					}
			  )
			: [];
		let selectedClubs = push_notification_setting.audience.clubs
			? push_notification_setting.audience.clubs.map((club) => {
					return { label: club.name, value: club.id };
			  })
			: [];

		let selectedClubGroups = push_notification_setting.audience.club_groups
			? push_notification_setting.audience.club_groups.map(
					(club_group) => {
						return { label: club_group.name, value: club_group.id };
					}
			  )
			: [];

		let gender = push_notification_setting.audience.gender
			? push_notification_setting.audience.gender.map((g) => {
					if (g === "M") {
						return { value: "M", label: "Male" };
					} else {
						return { value: "F", label: "Female" };
					}
			  })
			: [];

			let golfer_status = push_notification_setting.audience.golfer_status
			? push_notification_setting.audience.golfer_status.map((g) => {
					if (g === "Active") {
						return { value: "Active", label: "Active" };
					} else {
						return { value: "Inactive", label: "Inactive" };
					}
			  })
			: [];

		let club_categories = push_notification_setting.audience.club_categories
			? push_notification_setting.audience.club_categories.map(
					(club_category) => {
						return { label: club_category, value: club_category };
					}
			  )
			: [];

		// handicap_index button logic
		let handicap_index = push_notification_setting.audience.handicap_index;
		let button1 =
			handicap_index.sign === "<" || handicap_index.sign === "<="
				? true
				: false;
		let button2 =
			handicap_index.sign === "=" ||
			handicap_index.sign === ">=" ||
			handicap_index.sign === "<="
				? true
				: false;
		let button3 =
			handicap_index.sign === ">" || handicap_index.sign === ">="
				? true
				: false;

		// golfer_age button logic
		let golfer_age = push_notification_setting.audience.golfer_age;
		let button4 =
			golfer_age.sign === "<" || golfer_age.sign === "<=" ? true : false;
		let button5 =
			golfer_age.sign === "=" ||
			golfer_age.sign === ">=" ||
			golfer_age.sign === "<="
				? true
				: false;
		let button6 =
			golfer_age.sign === ">" || golfer_age.sign === ">=" ? true : false;

		// number_of_scores button logic
		let number_of_scores =
			push_notification_setting.audience.number_of_scores;
		let button7 =
			number_of_scores.sign === "<" || number_of_scores.sign === "<="
				? true
				: false;
		let button8 =
			number_of_scores.sign === "=" ||
			number_of_scores.sign === ">=" ||
			number_of_scores.sign === "<="
				? true
				: false;
		let button9 =
			number_of_scores.sign === ">" || number_of_scores.sign === ">="
				? true
				: false;

		let submit_approval =
			push_notification_setting.status === "awaiting approval"
				? true
				: false;
		let later = push_notification_setting.schedule.later;
		let date = later
			? new Date(push_notification_setting.schedule.scheduled_at)
			: null;
		let hour = date ? date.getHours() : null;
		let day_time = parseInt(hour) >= 12 ? "PM" : "AM";
		//case 00:00 AM
		if (hour === 0){
			hour = 12;
			day_time = "AM"
		}
		day_time = later ? day_time : null;
		let new_time =
			day_time && day_time === "PM" && parseInt(hour) !== 12 ? parseInt(hour) - 12 : hour;

		let time = new_time ? new_time + ":00" : "0:00";

		let expiration_date = push_notification_setting.schedule.expiration_date
			? new Date(push_notification_setting.schedule.expiration_date)
			: null;
		let exp_hour = expiration_date ? expiration_date.getHours() : null;
		let exp_day_time = parseInt(exp_hour) >= 12 ? "PM" : "AM";
		if (exp_hour === 0){
			exp_hour = 12;
			exp_day_time = "AM"
		}
		exp_day_time = expiration_date ? exp_day_time : null;
		let new_exp_time =
			(exp_day_time && exp_day_time === "PM" && parseInt(exp_hour) !== 12)
				? parseInt(exp_hour) - 12
				: exp_hour;
		let exp_time = new_exp_time ? new_exp_time + ":00" : "0:00";
		let image_url = push_notification_setting.message.image.url;
		let external_link = push_notification_setting.message.external_link
			? push_notification_setting.message.external_link
			: "";
		let show_expired = push_notification_setting.schedule.expiration_date
			? true
			: false;

		this.props.initialize({
			...{
				notification_category: {
					label:
						notificationCategories[
							push_notification_setting.message
								.notification_category
						],
					value:
						push_notification_setting.message.notification_category,
				},
				notification_text: push_notification_setting.message.body,
				handicap_index: handicap_index.value,
				golfer_age: golfer_age.value,
				number_of_scores: number_of_scores.value,
				date: date,
				time: time !== '0:00' ? { label: time, value: time } : { label: '1:00', value: '1:00' },
				day_time: day_time ? { label: day_time, value: day_time } : { label: 'AM', value: 'AM' },
				expiration_date: expiration_date,
				exp_time: exp_time !== '0:00' ? { label: exp_time, value: exp_time } : { label: '1:00', value: '1:00' },
				exp_day_time: exp_day_time ? { label: exp_day_time, value: exp_day_time } : { label: 'AM', value: 'AM'},
				external_link: external_link,
				image: push_notification_setting.message.image.url,
				show_expired: show_expired,
			},
		});


		const initial_values = {
			membership_codes: this.sortArrayByValue(selectedMembershipTypes),
			gender: this.sortArrayByValue(gender),
			golfer_status: this.sortArrayByValue(golfer_status),
			clubs: this.sortArrayByValue(selectedClubs),
			club_groups: this.sortArrayByValue(selectedClubGroups),
			club_categories: this.sortArrayByValue(club_categories),
			handicap_index: handicap_index,
			golfer_age: golfer_age,
			number_of_scores: number_of_scores,
			notification_category: {
				value:
					push_notification_setting.message.notification_category,
				label:
					notificationCategories[
						push_notification_setting.message
							.notification_category
					]
			},
			notification_text: push_notification_setting.message.body,
			external_link: push_notification_setting.message.external_link,
			image: push_notification_setting.message.image.url,
			now: !push_notification_setting.schedule.later,
			date: date,
			time: time !== '0:00' ? { value: time, label: time } : {  value: '1:00', label: '1:00' },
			day_time: day_time ? { label: day_time, value: day_time } : { label: 'AM', value: 'AM' },
			show_expired: show_expired,
			expiration_date: expiration_date,
			exp_time: exp_time !== '0:00' ? { value: exp_time, label: exp_time } : { value: '1:00', label: '1:00' },
			exp_day_time: exp_day_time ? { label: exp_day_time, value: exp_day_time } : { label: 'AM', value: 'AM'},
			submit_approval: submit_approval,
			}

		this.setState({
			gender: gender,
			golfer_status: golfer_status,
			selectedMembershipTypes: selectedMembershipTypes,
			selectedClubs: selectedClubs,
			selectedClubGroups: selectedClubGroups,
			clubCategory: club_categories,
			count: push_notification_setting.message.body.length,
			btn1_blue: button1,
			btn2_blue: button2,
			btn3_blue: button3,
			btn4_blue: button4,
			btn5_blue: button5,
			btn6_blue: button6,
			btn7_blue: button7,
			btn8_blue: button8,
			btn9_blue: button9,
			now: !push_notification_setting.schedule.later,
			time: time !== '0:00' ? { label: time, value: time } : { label: '1:00', value: '1:00' },
			day_time: day_time ? { label: day_time, value: day_time } : { label: 'AM', value: 'AM' },
			exp_time: exp_time !== '0:00' ? { label: exp_time, value: exp_time } : { label: '1:00', value: '1:00' },
			exp_day_time: exp_day_time ? { label: exp_day_time, value: exp_day_time } : { label: 'AM', value: 'AM'},
			submit_approval: submit_approval,
			image: push_notification_setting.message.image.url,
			image_url: image_url,
			show_expired: show_expired,
			initial_values: initial_values,
			curret_values: initial_values
		});

		this.props.onRef(this);

		this.loadMembershipTypes();
		this.loadClubs();
		this.loadClubGroups();
	}

	get_sign(type) {
		let sign = "=";
		if (type === "handicap_index") {
			if (this.state.btn1_blue) {
				sign = "<";
				if (this.state.btn2_blue) sign = "<=";
			}
			if (this.state.btn3_blue) {
				sign = ">";
				if (this.state.btn2_blue) sign = ">=";
			}
			return sign;
		}

		if (type === "golfer_age") {
			if (this.state.btn4_blue) {
				sign = "<";
				if (this.state.btn5_blue) sign = "<=";
			}
			if (this.state.btn6_blue) {
				sign = ">";
				if (this.state.btn5_blue) sign = ">=";
			}
			return sign;
		}

		if (type === "number_of_scores") {
			if (this.state.btn7_blue) {
				sign = "<";
				if (this.state.btn8_blue) sign = "<=";
			}
			if (this.state.btn9_blue) {
				sign = ">";
				if (this.state.btn8_blue) sign = ">=";
			}
			return sign;
		}
	}
	
	get_sign_by_value(lower, equal, greater) {
		let sign = "=";
		if (lower) {
			sign = "<";
			if (equal) sign = "<=";
		}
		if (greater) {
			sign = ">";
			if (equal) sign = ">=";
		}
		return sign;
	}

	onSubmit(values, dispatch, props) {
		let data = JSON.parse(JSON.stringify(values));

		if (this.state.selectedMembershipTypes !== []) {
			data.membership_codes = this.state.selectedMembershipTypes.map(
				(membershipType) => {
					return membershipType.value;
				}
			);
		}

		if (this.state.gender !== []) {
			data.gender = this.state.gender.map((gender) => {
				return gender.value;
			});
		}

		if (this.state.golfer_status !== []) {
			data.golfer_status = this.state.golfer_status.map((golfer_status) => {
				return golfer_status.value;
			});
		}

		if (this.state.selectedClubs !== []) {
			data.clubs = this.state.selectedClubs.map((club) => {
				return club.value;
			});
		}

		if (this.state.selectedClubGroups !== []) {
			data.club_groups = this.state.selectedClubGroups.map(
				(club_group) => {
					return club_group.value;
				}
			);
		}

		if (this.state.clubCategory !== []) {
			data.club_category = this.state.clubCategory.map(
				(club_category) => {
					return club_category.value;
				}
			);
		}

		if (data.date) {
			let time =
				(this.state.day_time.value === "PM" && parseInt(this.state.time.value) !== 12)
					? parseInt(this.state.time.value) + 12 + ":00"
					: this.state.time.value;
			time = (this.state.day_time.value === "AM" && parseInt(this.state.time.value) === 12) ? "00:00" : time;
			data.scheduled_at =
				moment(data.date).format("YYYY-MM-DD") + " " + time;
		}

		if (this.state.show_expired === false) data.expiration_date = '';
		else if (data.expiration_date) {
			let exp_time =
				(this.state.exp_day_time.value === "PM" && parseInt(this.state.exp_time.value) !== 12)
					? parseInt(this.state.exp_time.value) + 12 + ":00"
					: this.state.exp_time.value;
			exp_time = (this.state.exp_day_time.value === "AM" && parseInt(this.state.exp_time.value) === 12) ? "00:00" : exp_time;
			data.expiration_date =
				moment(data.expiration_date).format("YYYY-MM-DD") +
				" " +
				exp_time;
		}

		if (data.golfer_age) {
			data.age = {
				sign: this.get_sign("golfer_age"),
				value: data.golfer_age,
			};
		}

		if (data.handicap_index) {
			data.handicap_index = {
				sign: this.get_sign("handicap_index"),
				value: data.handicap_index,
			};
		}

		if (data.number_of_scores) {
			data.number_of_scores = {
				sign: this.get_sign("number_of_scores"),
				value: data.number_of_scores,
			};
		}

		if (data.notification_category)
			data.notification_category = data.notification_category.value;
		data.later = !this.state.now;
		if (this.state.submit_approval === true)
			data.status = "awaiting approval";
		else data.status = "draft";

		if (this.state.delete_image === true && !this.state.image)
			data.delete_image = true;
		if (this.state.image && data.image !== this.state.image)
			data.image = this.state.image;
		else delete data.image;

		data.notes = "";

		if (data.handicap_index === "") delete data.handicap_index;
		if (data.golfer_age === "") delete data.golfer_age;
		if (data.number_of_scores === "") delete data.number_of_scores;

		return AssociationService.editPushNotification(
			this.props.associationId,
			this.props.push_notification_setting.id,
			data
		)
			.then((res) => {
				if (res.push_notification_settings) {
					this.props.close();
					this.props.setTableRefresh(true);
					this.props.addAlert({
						type: "success",
						message:
							"Push Notification has been successfully updated !",
					});
					console.log("PushNotificationSetting edited.");
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}

	changeColor() {
		this.setState({ blue: !this.state.blue });
	}

	loadMembershipTypes() {
		AssociationService.getMembershipTypes(this.props.associationId, {
			include_used_membership: "false",
		})
			.then((res) => {
				this.setState({
					membershipTypes: res.membership_types
						? res.membership_types
								.map((membershipType) => {
									return {
										label:
											membershipType.code +
											" - " +
											membershipType.description,
										value: membershipType.id,
									};
								})
								.sort((a, b) =>
									a["label"]
										.toString()
										.localeCompare(b["label"].toString())
								)
						: [],
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	loadClubs() {
		AssociationService.getAllClubs(this.props.associationId, {
			status: "Active",
		})
			.then((res) => {
				let clubs = res.clubs.map((club) => {
					return {
						label: club.club_name,
						value: club.id
					};
				});
				
				this.setState({
					clubs: clubs.sort((a, b) =>
						a["label"]
							.toString()
							.localeCompare(b["label"].toString())
					),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	loadClubGroups() {
		AssociationService.getClubGroups(this.props.associationId, {
			include_clubs: "false",
		}).then((res) => {
			this.setState({
				clubGroups: res.club_groups.map((item) => {
					return {
						label: item.name,
						value: item.id,
					};
				}),
			});
		});
	}
	
	componentDidUpdate(){
		if (JSON.stringify(this.state.curret_values) !== JSON.stringify(this.state.initial_values)){
				if (this.state.pristine === true) 
					this.setState({pristine: false})
		}
		else if (this.state.pristine === false) this.setState({pristine: true})
	}
	
	sortArrayByValue(array) {
		if (Array.isArray(array))
			return JSON.stringify(array.sort((i1,i2) => { return i1.value > i2.value ? 1 : -1}));
		else 
			return array;
	}

	render() {
		const { handleSubmit, submitting, invalid } = this.props;
		const noteMaxLength = 100;

		const btn1_class = this.state.btn1_blue
			? "btn fill round blue pd-l-13px"
			: "btn fill round gray pd-l-13px";
		const btn2_class = this.state.btn2_blue
			? "btn fill round blue"
			: "btn fill round gray";
		const btn3_class = this.state.btn3_blue
			? "btn fill round blue pd-r-13px"
			: "btn fill round gray pd-r-13px";
		const btn4_class = this.state.btn4_blue
			? "btn fill round blue pd-l-13px"
			: "btn fill round gray pd-l-13px";
		const btn5_class = this.state.btn5_blue
			? "btn fill round blue "
			: "btn fill round gray";
		const btn6_class = this.state.btn6_blue
			? "btn fill round blue pd-r-13px"
			: "btn fill round gray pd-r-13px";
		const btn7_class = this.state.btn7_blue
			? "btn fill round blue pd-l-13px"
			: "btn fill round gray pd-l-13px";
		const btn8_class = this.state.btn8_blue
			? "btn fill round blue"
			: "btn fill round gray";
		const btn9_class = this.state.btn9_blue
			? "btn fill round blue pd-r-13px"
			: "btn fill round gray pd-r-13px";

		const time_array = [
			{
				value: "1:00",
				label: "1:00",
			},
			{
				value: "2:00",
				label: "2:00",
			},
			{
				value: "3:00",
				label: "3:00",
			},
			{
				value: "4:00",
				label: "4:00",
			},
			{
				value: "5:00",
				label: "5:00",
			},
			{
				value: "6:00",
				label: "6:00",
			},
			{
				value: "7:00",
				label: "7:00",
			},
			{
				value: "8:00",
				label: "8:00",
			},
			{
				value: "9:00",
				label: "9:00",
			},
			{
				value: "10:00",
				label: "10:00",
			},
			{
				value: "11:00",
				label: "11:00",
			},
			{
				value: "12:00",
				label: "12:00",
			},
		];

		return (
			<Fragment>
				<form
					autoComplete="off"
					onSubmit={handleSubmit(this.onSubmit)}
					ref={"form"}
				>
					<div className="row">
						<div className="col">
							<p className="pn_blue pn_small pn_bold">
								Who are you sending this to?
							</p>
						</div>
					</div>
					{/* First row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="membership_code"
								id="membership_code"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={this.state.membershipTypes}
								value={this.state.selectedMembershipTypes}
								onChange={(values) => {
									this.setState({
										selectedMembershipTypes: values,
										pristine: false,
										curret_values: {...this.state.curret_values, membership_codes: this.sortArrayByValue(values) }
									});
								}}
								label={"Membership Code"}
							/>
						</div>
						{/* Second Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="gender"
								id="gender"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={genderOptions}
								value={this.state.gender}
								onChange={(values) => {
									this.setState({
										gender: values,
										pristine: false,
										curret_values: {...this.state.curret_values, gender: this.sortArrayByValue(values) }
									});
								}}
								label={"Gender"}
							/>
						</div>
						{/* Third Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="sgolfer_status"
								id="golfer_status"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={statusOptions}
								value={this.state.golfer_status}
								onChange={(values) => {
									this.setState({
										golfer_status: values,
										pristine: false,
										curret_values: {...this.state.curret_values, golfer_status: this.sortArrayByValue(values) }
									});
								}}
								label={"Golfer Status"}
							/>
						</div>
					</div>
					{/* Second Row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="clubs"
								id="clubs"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={this.state.clubs}
								value={this.state.selectedClubs}
								onChange={(values) => {
									this.setState({
										selectedClubs: values,
										pristine: false,
										curret_values: {...this.state.curret_values, clubs: this.sortArrayByValue(values) }
									});
								}}
								label={"Clubs"}
							/>
						</div>
						{/* Second Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="club_groups"
								id="club_groups"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={this.state.clubGroups}
								value={this.state.selectedClubGroups}
								onChange={(values) => {
									this.setState({
										selectedClubGroups: values,
										pristine: false,
										curret_values: {...this.state.curret_values, club_groups: this.sortArrayByValue(values) }
									});
								}}
								label={"Select Club Group(s)"}
							/>
						</div>
						{/* Third Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="club_category"
								id="club_category"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={[
									"Affiliate",
									"Military",
									"Private",
									"Public",
									"Resort",
									"Semi-Private",
									"Not Specified",
								]}
								value={this.state.clubCategory}
								onChange={(values) => {
									this.setState({
										clubCategory: values,
										pristine: false,
										curret_values: {...this.state.curret_values, club_categories: this.sortArrayByValue(values) }
									});
								}}
								label={"Club Category"}
							/>
						</div>
					</div>

					{/* First Category */}
					<div className="row centered-items">
						{/* Name */}
						<div className="row align-right height-28-width-160">
							<div className="col is-4-of-4 text-right">
								Handicap Index
							</div>
						</div>
						{/* Icons column */}
						<div className="col is-1-of-4">
							<div className="row jc-sa">
								<button
									className={btn1_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn3_blue)
											this.setState({
												btn1_blue: !this.state.btn1_blue,
												curret_values: {
													...this.state.curret_values, 
													handicap_index: { 
														sign: this.get_sign_by_value(
															!this.state.btn1_blue,
															this.state.btn2_blue,
															this.state.btn3_blue,
														),
														value: this.state.initial_values.handicap_index.value
													}
												}
											});
									}}
								>
									{"<"}
								</button>
								<button
									className={btn2_class}
									type={"button"}
									onClick={() => {
										this.setState({
											btn2_blue: !this.state.btn2_blue,
											curret_values: {
												...this.state.curret_values, 
												handicap_index: { 
													sign: this.get_sign_by_value(
														this.state.btn1_blue,
														!this.state.btn2_blue,
														this.state.btn3_blue,
													),
													value: this.state.initial_values.handicap_index.value
												}
											}
										});
									}}
								>
									{"="}
								</button>
								<button
									className={btn3_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn1_blue)
											this.setState({
												btn3_blue: !this.state.btn3_blue,
												curret_values: {
													...this.state.curret_values, 
													handicap_index: { 
														sign: this.get_sign_by_value(
															this.state.btn1_blue,
															this.state.btn2_blue,
															!this.state.btn3_blue,
														),
														value: this.state.initial_values.handicap_index.value
													}
												}
											});
									}}
								>
									{">"}
								</button>
							</div>
						</div>
						{/* Input */}
						<div className="col is-1-of-4">
							<Field
								name="handicap_index"
								id="handicap_index"
								component={InputField}
								type="text"
								onChange={(values) => {
									const value = values.currentTarget.value;
									this.setState({
										curret_values: {
											...this.state.curret_values, 
											handicap_index: { sign: this.get_sign('handicap_index'), value: value }
										}
									});
								}}
								validate={[
									handicapIndex,
									isNumeric,
									checkSignIsSelected([
										this.state.btn1_blue ||
											this.state.btn2_blue ||
											this.state.btn3_blue,
									]),
								]}
							/>
						</div>
					</div>

					{/* Second Category */}
					<div className="row centered-items">
						{/* Name column */}
						<div className="row align-right height-28-width-160">
							<div className="col is-4-of-4 text-right">
								Golfer Age
							</div>
						</div>
						{/* Icons column */}
						<div className="col is-1-of-4">
							<div className="row jc-sa">
								<button
									className={btn4_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn6_blue)
											this.setState({
												btn4_blue: !this.state.btn4_blue,
												curret_values: {
													...this.state.curret_values, 
													golfer_age: { 
														sign: this.get_sign_by_value(
															!this.state.btn4_blue,
															this.state.btn5_blue,
															this.state.btn6_blue,
														),
														value: this.state.initial_values.golfer_age.value
													}
												}
											});
									}}
								>
									{"<"}
								</button>
								<button
									className={btn5_class}
									type={"button"}
									onClick={() => {
										this.setState({
											btn5_blue: !this.state.btn5_blue,
											curret_values: {
												...this.state.curret_values, 
												golfer_age: { 
													sign: this.get_sign_by_value(
														this.state.btn4_blue,
														!this.state.btn5_blue,
														this.state.btn6_blue,
													),
													value: this.state.initial_values.golfer_age.value
												}
											}
										});
									}}
								>
									{"="}
								</button>
								<button
									className={btn6_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn4_blue)
											this.setState({
												btn6_blue: !this.state.btn6_blue,
												curret_values: {
													...this.state.curret_values, 
													golfer_age: { 
														sign: this.get_sign_by_value(
															this.state.btn4_blue,
															this.state.btn5_blue,
															!this.state.btn6_blue,
														),
														value: this.state.initial_values.golfer_age.value
													}
												}
											});
									}}
								>
									{">"}
								</button>
							</div>
						</div>
						{/* Input */}
						<div className="col is-1-of-4">
							<Field
								name="golfer_age"
								id="golfer_age"
								component={InputField}
								type="text"
								onChange={(values) => {
									const value = values.currentTarget.value;
									this.setState({
										curret_values: {
											...this.state.curret_values, 
											golfer_age: { sign: this.get_sign('golfer_age'), value: value }
										}
									});
								}}
								validate={[
									isNumeric,
									golfer_age,
									checkSignIsSelected([
										this.state.btn4_blue ||
											this.state.btn5_blue ||
											this.state.btn6_blue,
									]),
								]}
							/>
						</div>
					</div>

					{/* Third category */}
					<div className="row centered-items">
						<div
							className="row height-28-width-160"
							style={{ marginTop: "6px" }}
						>
							<div className="col is-4-of-4 text-right">
								Number Of Scores (Current Year)
							</div>
						</div>
						{/* Icons column */}
						<div className="col is-1-of-4">
							<div className="row jc-sa">
								<button
									className={btn7_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn9_blue)
											this.setState({
												btn7_blue: !this.state.btn7_blue,
												curret_values: {
													...this.state.curret_values, 
													number_of_scores: { 
														sign: this.get_sign_by_value(
															!this.state.btn7_blue,
															this.state.btn8_blue,
															this.state.btn9_blue,
														),
														value: this.state.initial_values.number_of_scores.value
													}
												}
											});
									}}
								>
									{"<"}
								</button>
								<button
									className={btn8_class}
									type={"button"}
									onClick={() => {
										this.setState({
											btn8_blue: !this.state.btn8_blue,
											curret_values: {
												...this.state.curret_values, 
												number_of_scores: { 
													sign: this.get_sign_by_value(
														this.state.btn7_blue,
														!this.state.btn8_blue,
														this.state.btn9_blue,
													),
													value: this.state.initial_values.number_of_scores.value
												}
											}
										});
									}}
								>
									{"="}
								</button>
								<button
									className={btn9_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn7_blue)
											this.setState({
												btn9_blue: !this.state.btn9_blue,
												curret_values: {
													...this.state.curret_values, 
													number_of_scores: { 
														sign: this.get_sign_by_value(
															this.state.btn7_blue,
															this.state.btn8_blue,
															!this.state.btn9_blue,
														),
														value: this.state.initial_values.number_of_scores.value
													}
												}
											});
									}}
								>
									{">"}
								</button>
							</div>
						</div>
						{/* Input */}
						<div className="col is-1-of-4">
							<Field
								name="number_of_scores"
								id="number_of_scores"
								component={InputField}
								type="text"
								onChange={(values) => {
									const value = values.currentTarget.value;

									this.setState({
										curret_values: {
											...this.state.curret_values, 
											number_of_scores: { sign: this.get_sign('number_of_scores'), value: value }
										}
									});
							 }}
								validate={[
									isNumeric,
									number_of_scores,
									checkSignIsSelected([
										this.state.btn7_blue ||
											this.state.btn8_blue ||
											this.state.btn9_blue,
									]),
								]}
							/>
						</div>
					</div>

					{/* Third Row */}
					<div className="row">
						<div className="col">
							<p className="pn_blue pn_small pn_bold">
								What is the message?
							</p>
						</div>
					</div>
					{/* Fourth Row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-2">
							<label htmlFor="notification_category">
								Notification Category <span>*</span>
							</label>
							<Field
								name="notification_category"
								id="notification_category"
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								validate={[required]}
								onChange={(values) => {
									this.setState({
										curret_values: {...this.state.curret_values, notification_category: values }
									});
								}}
								options={[
									{
										value: "tournaments_and_events",
										label: "Tournaments & Events",
									},
									{
										value: "member_benefits_and_promotions",
										label: "Member Benefits & Promotions",
									},
									{
										value: "general_updates",
										label: "General Updates",
									},
								]}
							/>
						</div>
					</div>

					{/* Sixth Row */}
					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="notification_text">
								Notification Text <span>*</span>{" "}
							</label>
						</div>
						<div
							className="col is-2-of-2"
							style={{ textAlign: "right" }}
						>
							<label
								className={
									this.state.count > noteMaxLength
										? "length-error"
										: "blue"
								}
								htmlFor="notification_text"
							>
								{this.state.count} / {noteMaxLength}
							</label>
						</div>
					</div>
					<div className="f-row">
						<Field
							component={Textarea}
							className={"large"}
							validate={[
								required,
								maxLength([
									100,
									"Value too long (max 100 characters)",
								]),
							]}
							name="notification_text"
							id="notification_text"
							type="text"
							onChange={(e) => {
								const value = e.currentTarget.value;
								this.setState({
									count: e.target.value.length,
									curret_values: {...this.state.curret_values, notification_text: value }
								});
							}}
						/>
					</div>

					{/* Seventh Row */}
					<div className="row">
						<div className="col">
							<label htmlFor="external_link">External Link</label>
							<Field
								component={InputField}
								type="text"
								validate={[isUrl]}
								name="external_link"
								id="external_link"
								onChange={(values) => {
									const value = values.currentTarget.value;
									this.setState({
										curret_values: {...this.state.curret_values, external_link: value }
									});
								}}
							/>
						</div>
					</div>

					{/* Eigth Row */}
					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<label htmlFor="upload_image"> Upload Image</label>
							<div
								className="panel__body"
								style={{
									border: "1px solid lightgrey",
									padding: "15px",
								}}
							>
								{!this.state.image ? (
									<UploadPushNotificationImage
										requiredWidth={720}
										requiredHeight={360}
										errors={this.state.error}
										onSave={(data) => {
											this.setState({
												image: data,
												curret_values: {...this.state.curret_values, image: data }
											});
										}}
									/>
								) : (
									<div
										className="upload__item"
										style={{ marginRight: "10px" }}
									>
										<Fragment>
											<div className="upload__details pn__details">
												<div className="row">
													<div className="col is-1-of-2">
														<figure
															className="large"
															style={{
																width: "100%",
															}}
														>
															{this.state
																.image_url ? (
																<img
																	src={
																		this
																			.state
																			.image
																	}
																	style={{
																		width:
																			"100%",
																	}}
																	alt=""
																/>
															) : (
																<img
																	src={URL.createObjectURL(
																		this
																			.state
																			.image
																	)}
																	style={{
																		width:
																			"100%",
																	}}
																	alt=""
																/>
															)}
														</figure>
													</div>
													{this.state.image_url && (
														<div className="col is-1-of-2 jc-fs">
															<div className="upload_item">
																<h6 className="upload__label">
																	Image Url:
																</h6>
																<a
																	className="upload__url"
																	href={
																		this
																			.state
																			.image
																	}
																>
																	{
																		this
																			.state
																			.image
																	}
																</a>
															</div>
														</div>
													)}
												</div>
											</div>
											<div className="upload__controls list">
												<button
													className="btn fill gray"
													onClick={() => {
														this.setState({
															delete_image: true,
															image: null,
															image_url: null,
															curret_values: {...this.state.curret_values, image: null }
														});
													}}
												>
													<i className="material-icons-outlined no-margin">
														clear
													</i>
												</button>
											</div>
										</Fragment>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Nineth Row */}
					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<p className="pn_blue pn_small pn_bold">
								When should it be sent?
							</p>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-1">
							<p className="pn_small">
								All Push Notifications will need to be approved
								by USGA staff.
							</p>
						</div>
					</div>

					{/* Tenth Row */}
					<div className="row">
						<div className="col is-1-of-2">
							<div className="radio__container">
								<Field
									onClick={() => {
										this.setState((prevState) => {
											return { 
												now: !prevState.now,
											  curret_values: {...this.state.curret_values, now: !prevState.now } 
											};
										});
									}}
									checked={this.state.now}
									id="dac-yes"
									value={true}
									name={"now"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="dac-yes">Now</label>

								<Field
									onClick={() => {
										this.setState((prevState) => {
											return { 
												now: !prevState.now,
											  curret_values: {...this.state.curret_values, now: !prevState.now } 
											};
										});
									}}
									checked={!this.state.now}
									id="dac-no"
									value={false}
									name={"later"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="dac-no">Later</label>
							</div>
						</div>
					</div>
					{this.state.now ? (
						<div className="row">
							<div className="col is-1-of-1">
								<p className="pn_small pn_red">
									Once approved, the notification will be sent
									immediately.
								</p>
							</div>
						</div>
					) : (
						<div className="row">
							<div className="col is-1-of-1">
								<p className="pn_small pn_red">
									Once approved, the notification will be sent
									as scheduled. If the approval occurs after
									the scheduled date & time it will be sent
									out immediately.
								</p>
							</div>
						</div>
					)}
					{/* Eleventh Row */}
					{!this.state.now && (
						<div className="row">
							{/* First col */}
							<div className="col is-1-of-2">
								<label htmlFor="date">Date</label>
								<Field
									minDate={new Date()}
									className={"date"}
									showMonthDropdown
									showYearDropdown
									name={"date"}
									id={"date"}
									validate={[required]}
									component={DatePickerInput}
									placeholder="Select ... "
									onChange={(value) => {
										this.setState({
											curret_values: {...this.state.curret_values, date: value } 
										});
									}}
								/>
							</div>
							{/* Second col */}
							<div className="col is-1-of-4">
								<GhinFilterSelect
									singleChoice={true}
									hideSelectAllOption={true}
									validate={[required]}
									component={SelectInput}
									className="react-select-container"
									classNamePrefix="react-select"
									options={time_array}
									label={"Time"}
									name={"time"}
									id={"time"}
									onChange={(values) => {
										this.setState({
											time: values,
											curret_values: {...this.state.curret_values, time: values } 
										});
									}}
									value={this.state.time}
								/>
							</div>
							{/* Third col */}
							<div className="col is-1-of-4">
								<div className="row"></div>
								<GhinFilterSelect
									singleChoice={true}
									hideSelectAllOption={true}
									name="day_time"
									id="day_time"
									validate={[required]}
									component={SelectInput}
									className="react-select-container"
									classNamePrefix="react-select"
									options={["AM", "PM"]}
									onChange={(values) => {
										this.setState({
											day_time: values,
											curret_values: {...this.state.curret_values, day_time: values } 
										});
									}}
									value={this.state.day_time}
								/>
							</div>
						</div>
					)}

					{/* Twelfth Row */}
					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<p className="pn_blue pn_small pn_bold">
								Does this push notification have a cut-off date?
							</p>
						</div>
					</div>
					{/* Thirteenth Row */}
					<div className="row">
						<div className="col is-1-of-2">
							<div className="radio__container">
								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												show_expired: !prevState.show_expired,
												curret_values: {...this.state.curret_values, show_expired: !prevState.show_expired}
											};
										});
									}}
									checked={this.state.show_expired}
									id="show_expired_yes"
									value={true}
									name={"show_expired"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="show_expired_yes">Yes</label>

								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												show_expired: !prevState.show_expired,
												curret_values: {...this.state.curret_values, show_expired: !prevState.show_expired}
											};
										});
									}}
									checked={!this.state.show_expired}
									id="show_expired_no"
									value={false}
									name={"show_expired"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="show_expired_no">No</label>
							</div>
						</div>
					</div>
					{this.state.show_expired && (
						<div className="row">
							<div className="col is-1-of-1">
								<p className="pn_small">
									Do not send this push notification if it is
									not approved by the following date/time:
								</p>
							</div>
						</div>
					)}
					{/* Fourteenth Row */}
					{this.state.show_expired && (
						<div className="row">
							{/* First col */}
							<div className="col is-1-of-2">
								<label htmlFor="date">Date</label>
								<Field
									minDate={new Date()}
									className={"expiration_date"}
									showMonthDropdown
									showYearDropdown
									name={"expiration_date"}
									id={"expiration_date"}
									validate={[required]}
									component={DatePickerInput}
									placeholder="Select ... "
									onChange={(value) => {
										this.setState({
											curret_values: {...this.state.curret_values, expiration_date: value } 
										});
									}}
								/>
							</div>
							{/* Second col */}
							<div className="col is-1-of-4">
								<GhinFilterSelect
									singleChoice={true}
									hideSelectAllOption={true}
									validate={[required]}
									component={SelectInput}
									className="exp_time"
									options={time_array}
									label={"Time"}
									onChange={(values) => {
										this.setState({
											exp_time: values,
											curret_values: {...this.state.curret_values, exp_time: values } 
										});
									}}
									value={this.state.exp_time}
									name={"exp_time"}
									id={"exp_time"}
								/>
							</div>
							{/* Third col */}
							<div className="col is-1-of-4">
								<div className="row"></div>
								<GhinFilterSelect
									className={"exp_day_time"}
									singleChoice={true}
									hideSelectAllOption={true}
									name={"exp_day_time"}
									id={"exp_day_time"}
									validate={[required]}
									component={SelectInput}
									options={["AM", "PM"]}
									onChange={(values) => {
										this.setState({
											exp_day_time: values,
											curret_values: {...this.state.curret_values, exp_day_time: values }
										});
									}}
									value={this.state.exp_day_time}
								/>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col is-1-of-1 jc-fe">
							<Field
								checked={this.state.submit_approval}
								onClick={() => {
									this.setState({
										submit_approval: !this.state
											.submit_approval,
										curret_values: {
											...this.state.curret_values, 
											submit_approval: !this.state.submit_approval
										}
									});
								}}
								id="submit_approval"
								value={true}
								name={"submit_approval"}
								component={"input"}
								type="checkbox"
							/>
							<label htmlFor="submit_approval">
								Submit for Approval
							</label>
						</div>
					</div>
					<div className="row">
						<div className="col is-1-of-2">
							<button
								type={"button"}
								className="btn fill gray"
								onClick={() => this.props.close()}
							>
								Cancel
							</button>
						</div>
						<div className="col is-1-of-2">
							<button
								type={"submit"}
								ref={"submit"}
								disabled={
									submitting || this.state.pristine || invalid
								}
								className="btn fill blue"
							>
								{this.state.submit_approval
									? "Submit for Approval"
									: "Save as Draft"}
							</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

EditPushNotificationForm = reduxForm({
	form: "addPushNotificationForm",
	destroyOnUnmount: true,
})(EditPushNotificationForm);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withKeyPress(EditPushNotificationForm));
