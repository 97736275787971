import React, {Component, Fragment} from 'react';

import AddNewClubForm from '../Forms/AddNewClubForm';
import SuccessModal from "./SuccessModal";

export default class AddNewClubModal extends Component {

	constructor(){
		super();
		this.state = {
			club: null,
		}
	}
	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
                        <h2 className="modal__title">Add New Club</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

						{!this.state.club ?
							<AddNewClubForm association={this.props.association} onSuccess={(data)=> {this.setState({club: data})}} closeModal={this.props.closeModal}/>
						:
							<SuccessModal
								openModal={this.state.club}
								onMainAction={() => {
									this.props.closeModal({type:'club', club: this.state.club});
								}}
								onSecundaryAction={() => {
									this.setState({club: null});
								}}
								closeModal={() => {
									this.props.closeModal()
								}}
								club={this.state.club}
								mainLabel={"Continue to Setup"}
								secundaryLabel={"Add Another Club"}
							/>
						}

                    </div>

                </div>

            </Fragment>
		);
	}
}
