import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SharedService from '../../services/api/shared';
import {Picky} from 'react-picky';
import { Field, reduxForm } from "redux-form";
import {
	required,
	maxLength,
	handicapIndex,
	golfer_age,
	number_of_scores,
	checkSignIsSelected,
	isNumeric,
	isUrl,
} from "../../inputs/validations";
import { InputField } from "../../inputs/InputField";
import { addAlert } from "../shared/actions";
import { SelectInput } from "../../inputs/Select";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import { DatePickerInput } from "../../inputs/DatePicker";
import { Textarea } from "../../inputs/Textarea";
import GhinFilterSelect from "../shared/GhinFilterSelect";
import { FederationAccount } from "../../services/api/federationAccount";
import { GolfAssociationGroup } from "../../services/api/federations";
import UploadPushNotificationImage from "../../inputs/UploadPushNotificationImage";
import moment from "moment";
import {
	genderOptions,
	statusOptions,
	membershipTypes,
	time_array,
	notification_category,
} from "../../services/shared/pushNotificationForm";

class AddPushNotificationForFederationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			scheduled: false,
			now: true,
			membershipTypes: [],
			gender: [],
			is_deep_link: false,
			mobile_deep_links: null,
			selected_link: null,
			golfer_status: [],
			associations: [],
			assocGroups: [],
			selectedAssocGroups: [],
			selectedAssoc: [],
			time: null,
			day_time: null,
			image_url: null,
			image: null,
			delete_image: false,
			btn1_blue: false,
			btn2_blue: false,
			btn3_blue: false,
			btn4_blue: false,
			btn5_blue: false,
			btn6_blue: false,
			btn7_blue: false,
			btn8_blue: false,
			btn9_blue: false,
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.loadAssociations();
		this.loadAssociationGroups();
		this.loadMobileDeepLinks();
	}

	loadMobileDeepLinks() {
		SharedService.getMobileDeepLinks()
		.then(res=>{
				this.setState({
						mobile_deep_links: res['mobile_deep_links']
				})
		})
		.catch(err => {
				console.error(err);
		})
	}

	get_sign(type) {
		let sign = "=";
		if (type === "handicap_index") {
			if (this.state.btn1_blue) {
				sign = "<";
				if (this.state.btn2_blue) sign = "<=";
			}
			if (this.state.btn3_blue) {
				sign = ">";
				if (this.state.btn2_blue) sign = ">=";
			}
			return sign;
		}

		if (type === "golfer_age") {
			if (this.state.btn4_blue) {
				sign = "<";
				if (this.state.btn5_blue) sign = "<=";
			}
			if (this.state.btn6_blue) {
				sign = ">";
				if (this.state.btn5_blue) sign = ">=";
			}
			return sign;
		}

		if (type === "number_of_scores") {
			if (this.state.btn7_blue) {
				sign = "<";
				if (this.state.btn8_blue) sign = "<=";
			}
			if (this.state.btn9_blue) {
				sign = ">";
				if (this.state.btn8_blue) sign = ">=";
			}
			return sign;
		}
	}

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));

		if (this.state.membershipTypes) {
			data.membership_types = this.state.membershipTypes.map(
				(membershipType) => membershipType.value
			);
		}

		if (this.state.gender) {
			data.gender = this.state.gender.map((gender) => gender.value);
		}

		if (this.state.golfer_status) {
			data.golfer_status = this.state.golfer_status.map((golfer_status) => golfer_status.value);
		}

		if (this.state.selectedAssoc) {
			data.associations = this.state.selectedAssoc.map(
				(assoc) => assoc.value
			);
		}

		if (this.state.selectedAssocGroups) {
			data.association_groups = this.state.selectedAssocGroups.map(
				(asoc_group) => asoc_group.value
			);
		}

		if (data.golfer_age_input) {
			data.age = {
				sign: this.get_sign("golfer_age"),
				value: data.golfer_age_input,
			};
		}

		if (data.handicap_index_input) {
			data.handicap_index = {
				sign: this.get_sign("handicap_index"),
				value: data.handicap_index_input,
			};
		}

		if (data.number_of_scores) {
			data.number_of_scores = {
				sign: this.get_sign("number_of_scores"),
				value: data.number_of_scores,
			};
		}

		if (data.date) {
			let time =
				this.state.day_time === "PM" && parseInt(this.state.time) !== 12
					? parseInt(this.state.time) + 12 + ":00"
					: this.state.time;
			time =
				this.state.day_time === "AM" && parseInt(this.state.time) === 12
					? "00:00"
					: time;
			data.scheduled_at =
				moment(data.date).format("YYYY-MM-DD") + " " + time;
		}

		data.is_deep_link = this.state.is_deep_link

		if (this.state.delete_image === true && !this.state.image)
			data.delete_image = true;
		if (this.state.image) data.image = this.state.image;

		data.user_id = this.props.user.user_id;
		data.notification_category = data.notification_category.value;
		data.later = !this.state.now;
		if (this.state.scheduled === true) data.status = "scheduled";
		else data.status = "draft";

		return FederationAccount.createPushNotificationSetting(1, data).then(
			(res) => {
				if (res.push_notification_settings) {
					this.props.showSuccess(res.push_notification_settings);
					this.props.setTableRefresh(true);
					this.props.addAlert({
						type: "success",
						message:
							"Push Notification has been successfully created !",
					});
					console.log("PushNotificationSetting created.");
				}
			}
		);
	}

	changeColor() {
		this.setState({ blue: !this.state.blue });
	}

	loadAssociations() {
		FederationAccount.getAssociations()
			.then((res) => {
				let associations = res.associations;
				associations = associations.filter(
					(association) => association.status === "Active"
				);
				associations.map((association) => {
					association.value = association.id;
					association.label = association.name;
					return association;
				});

				this.setState({
					associations: associations.sort((a, b) =>
						a["label"]
							.toString()
							.localeCompare(b["label"].toString())
					),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	loadAssociationGroups() {
		GolfAssociationGroup.list()
			.then((response) => {
				let assocGroups = response.golf_association_groups
					.map((assoc) => {
						assoc.value = assoc.id;
						assoc.label = assoc.name;
						return assoc;
					})
					.sort((a, b) =>
						a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
					);
				this.setState({
					assocGroups,
				});
			})
			.catch((err) => {});
	}

	render() {
		const { handleSubmit, pristine, submitting, invalid } = this.props;
		const noteMaxLength = 100;

		const btn1_class = this.state.btn1_blue
			? "btn fill round blue pd-l-13px"
			: "btn fill round gray pd-l-13px";
		const btn2_class = this.state.btn2_blue
			? "btn fill round blue"
			: "btn fill round gray";
		const btn3_class = this.state.btn3_blue
			? "btn fill round blue pd-r-13px"
			: "btn fill round gray pd-r-13px";
		const btn4_class = this.state.btn4_blue
			? "btn fill round blue pd-l-13px"
			: "btn fill round gray pd-l-13px";
		const btn5_class = this.state.btn5_blue
			? "btn fill round blue "
			: "btn fill round gray";
		const btn6_class = this.state.btn6_blue
			? "btn fill round blue pd-r-13px"
			: "btn fill round gray pd-r-13px";
		const btn7_class = this.state.btn7_blue
			? "btn fill round blue pd-l-13px"
			: "btn fill round gray pd-l-13px";
		const btn8_class = this.state.btn8_blue
			? "btn fill round blue"
			: "btn fill round gray";
		const btn9_class = this.state.btn9_blue
			? "btn fill round blue pd-r-13px"
			: "btn fill round gray pd-r-13px";

		return (
			<Fragment>
				<form
					autoComplete="off"
					onSubmit={handleSubmit(this.onSubmit)}
					ref={"form"}
				>
					<div className="row">
						<div className="col">
							<p className="pn_blue pn_small pn_bold">
								Who are you sending this to?
							</p>
						</div>
					</div>
					{/* First row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="membership_type"
								id="membership_type"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={membershipTypes}
								value={this.state.membershipTypes}
								onChange={(values) => {
									this.setState({
										membershipTypes: values,
									});
								}}
								label={"Membership Type"}
							/>
						</div>
						{/* Second Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="gender"
								id="gender"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={genderOptions}
								value={this.state.gender}
								onChange={(values) => {
									this.setState({ gender: values });
								}}
								label={"Gender"}
							/>
						</div>
						{/* Third Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="golfer_status"
								id="golfer_status"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={statusOptions}
								value={this.state.golfer_status}
								onChange={(values) => {
									this.setState({ golfer_status: values });
								}}
								label={"Golfer Status"}
							/>
						</div>
					</div>
					{/* Second Row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="associations"
								id="associations"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={this.state.associations}
								value={this.state.selectedAssoc}
								onChange={(values) => {
									this.setState({ selectedAssoc: values });
								}}
								label={"Associations"}
							/>
						</div>

						{/* Second Column */}
						<div className="col is-1-of-3">
							<GhinFilterSelect
								name="assoc_groups"
								id="assoc_groups"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={this.state.assocGroups}
								value={this.state.selectedAssocGroups}
								onChange={(values) => {
									this.setState({
										selectedAssocGroups: values,
									});
								}}
								label={"Select Association Group(s)"}
							/>
						</div>
					</div>

					{/* First Category */}
					<div className="row centered-items">
						{/* Name */}
						<div className="row align-right height-28-width-160">
							<div className="col is-4-of-4 text-right">
								Handicap Index
							</div>
						</div>
						{/* Icons column */}
						<div className="col is-1-of-4">
							<div className="row jc-sa">
								<button
									className={btn1_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn3_blue)
											this.setState({
												btn1_blue: !this.state
													.btn1_blue,
											});
									}}
								>
									{"<"}
								</button>
								<button
									className={btn2_class}
									type={"button"}
									onClick={() => {
										this.setState({
											btn2_blue: !this.state.btn2_blue,
										});
									}}
								>
									{"="}
								</button>
								<button
									className={btn3_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn1_blue)
											this.setState({
												btn3_blue: !this.state
													.btn3_blue,
											});
									}}
								>
									{">"}
								</button>
							</div>
						</div>
						{/* Input */}
						<div className="col is-1-of-4">
							<Field
								name="handicap_index_input"
								id="handicap_index_input"
								component={InputField}
								type="text"
								validate={[
									isNumeric,
									handicapIndex,
									checkSignIsSelected([
										this.state.btn1_blue ||
											this.state.btn2_blue ||
											this.state.btn3_blue,
									]),
								]}
							/>
						</div>
					</div>

					{/* Second Category */}
					<div className="row centered-items">
						{/* Name column */}
						<div className="row align-right height-28-width-160">
							<div className="col is-4-of-4 text-right">
								Golfer Age
							</div>
						</div>
						{/* Icons column */}
						<div className="col is-1-of-4">
							<div className="row jc-sa">
								<button
									className={btn4_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn6_blue)
											this.setState({
												btn4_blue: !this.state
													.btn4_blue,
											});
									}}
								>
									{"<"}
								</button>
								<button
									className={btn5_class}
									type={"button"}
									onClick={() => {
										this.setState({
											btn5_blue: !this.state.btn5_blue,
										});
									}}
								>
									{"="}
								</button>
								<button
									className={btn6_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn4_blue)
											this.setState({
												btn6_blue: !this.state
													.btn6_blue,
											});
									}}
								>
									{">"}
								</button>
							</div>
						</div>
						{/* Input */}
						<div className="col is-1-of-4">
							<Field
								name="golfer_age_input"
								id="golfer_age_input"
								component={InputField}
								type="text"
								validate={[
									isNumeric,
									golfer_age,
									checkSignIsSelected([
										this.state.btn4_blue ||
											this.state.btn5_blue ||
											this.state.btn6_blue,
									]),
								]}
							/>
						</div>
					</div>

					{/* Third category */}
					<div className="row centered-items">
						<div
							className="row height-28-width-160"
							style={{ marginTop: "6px" }}
						>
							<div className="col is-4-of-4 text-right">
								Number Of Scores (Current Year)
							</div>
						</div>
						{/* Icons column */}
						<div className="col is-1-of-4">
							<div className="row jc-sa">
								<button
									className={btn7_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn9_blue)
											this.setState({
												btn7_blue: !this.state
													.btn7_blue,
											});
									}}
								>
									{"<"}
								</button>
								<button
									className={btn8_class}
									type={"button"}
									onClick={() => {
										this.setState({
											btn8_blue: !this.state.btn8_blue,
										});
									}}
								>
									{"="}
								</button>
								<button
									className={btn9_class}
									type={"button"}
									onClick={() => {
										if (!this.state.btn7_blue)
											this.setState({
												btn9_blue: !this.state
													.btn9_blue,
											});
									}}
								>
									{">"}
								</button>
							</div>
						</div>
						{/* Input */}
						<div className="col is-1-of-4">
							<Field
								name="number_of_scores"
								id="number_of_scores"
								component={InputField}
								type="text"
								validate={[
									isNumeric,
									number_of_scores,
									checkSignIsSelected([
										this.state.btn7_blue ||
											this.state.btn8_blue ||
											this.state.btn9_blue,
									]),
								]}
							/>
						</div>
					</div>

					{/* Third Row */}
					<div className="row">
						<div className="col">
							<p className="pn_blue pn_small pn_bold">
								What is the message?
							</p>
						</div>
					</div>
					{/* Fourth Row */}
					<div className="row">
						{/* First Column */}
						<div className="col is-1-of-2">
							<label htmlFor="notification_category">
								Notification Category <span>*</span>
							</label>
							<Field
								name="notification_category"
								id="notification_category"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={notification_category}
							/>
						</div>
					</div>

					{/* Sixth Row */}
					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="notification_text">
								Notification Text <span>*</span>{" "}
							</label>
						</div>
						<div
							className="col is-2-of-2"
							style={{ textAlign: "right" }}
						>
							<label
								className={
									this.state.count > noteMaxLength
										? "length-error"
										: "blue"
								}
								htmlFor="notification_text"
							>
								{this.state.count} / {noteMaxLength}
							</label>
						</div>
					</div>
					<div className="f-row">
						<Field
							component={Textarea}
							className={"large"}
							validate={[
								maxLength([
									100,
									"Value too long (max 100 characters)",
								]),
								required,
							]}
							name="notification_text"
							id="notification_text"
							type="text"
							onChange={(e) =>
								this.setState({ count: e.target.value.length })
							}
						/>
					</div>

					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<p className="pn_blue pn_small pn_bold">
								Deep link?
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col is-1-of-2">
							<div className="radio__container">
								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												is_deep_link: !prevState.is_deep_link,
											};
										});
									}}
									checked={this.state.is_deep_link}
									id="deep_link_yes"
									value={true}
									name={"is_deep_link"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="deep_link_yes">Yes</label>

								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												is_deep_link: !prevState.is_deep_link,
											};
										});
									}}
									checked={!this.state.is_deep_link}
									id="deep_link_no"
									value={false}
									name={"is_deep_link"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="deep_link_no">No</label>
							</div>
						</div>
					</div>

					{/* Seventh Row */}
					{!this.state.is_deep_link && <div className="row">
						<div className="col">
							<label htmlFor="external_link">External Link</label>
							<Field
								component={InputField}
								type="text"
								validate={[isUrl]}
								name="external_link"
								id="external_link"
							/>
						</div>
					</div>}

					{this.state.is_deep_link && <div className="row">
						{/* First Column */}
						<div className="col is-1-of-2">
							<label htmlFor="notification_category">
								Deep link destination: <span>*</span>
							</label>
							<Field
								name="deep_link"
								id="deep_link"
								validate={[required]}
								component={SelectInput}
								className="react-select-container"
								classNamePrefix="react-select"
								options={this.state.mobile_deep_links}
							/>
						</div>
					</div>}

					{/* Eigth Row */}
					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<label htmlFor="upload_image"> Upload Image</label>
							<div
								className="panel__body"
								style={{
									border: "1px solid lightgrey",
									padding: "15px",
								}}
							>
								{!this.state.image ? (
									<UploadPushNotificationImage
										requiredWidth={720}
										requiredHeight={360}
										errors={this.state.error}
										onSave={(data) => {
											this.setState({ image: data });
										}}
									/>
								) : (
									<div
										className="upload__item"
										style={{ marginRight: "10px" }}
									>
										<Fragment>
											<div className="upload__details pn__details">
												<div className="row">
													<div className="col is-1-of-2">
														<figure
															className="large"
															style={{
																width: "100%",
															}}
														>
															<img
																src={URL.createObjectURL(
																	this.state
																		.image
																)}
																style={{
																	width:
																		"100%",
																}}
																alt=""
															/>
														</figure>
													</div>
												</div>
											</div>
											<div className="upload__controls list">
												<button
													className="btn fill gray"
													onClick={() => {
														this.setState({
															delete_image: true,
															image: null,
														});
													}}
												>
													<i className="material-icons-outlined no-margin">
														clear
													</i>
												</button>
											</div>
										</Fragment>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Nineth Row */}
					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<p className="pn_blue pn_small pn_bold">
								When should it be sent?
							</p>
						</div>
					</div>

					{/* Tenth Row */}
					<div className="row">
						<div className="col is-1-of-2">
							<div className="radio__container">
								<Field
									onClick={() => {
										this.setState((prevState) => {
											return { now: !prevState.now };
										});
									}}
									checked={this.state.now}
									id="dac-yes"
									value={true}
									name={"now"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="dac-yes">Now</label>

								<Field
									onClick={() => {
										this.setState((prevState) => {
											return { now: !prevState.now };
										});
									}}
									checked={!this.state.now}
									id="dac-no"
									value={false}
									name={"later"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="dac-no">Later</label>
							</div>
						</div>
					</div>
					{this.state.now ? (
						<div className="row">
							<div className="col is-1-of-1">
								<p className="pn_small pn_red">
									The notification will be sent immediately.
								</p>
							</div>
						</div>
					) : (
						<div className="row">
							<div className="col is-1-of-1">
								<p className="pn_small pn_red">
									The notification will be sent as scheduled.
								</p>
							</div>
						</div>
					)}
					{/* Eleventh Row */}
					{!this.state.now && (
						<div className="row">
							{/* First col */}
							<div className="col is-1-of-2">
								<label htmlFor="date">Date</label>
								<Field
									minDate={new Date()}
									className={"date"}
									showMonthDropdown
									showYearDropdown
									name={"date"}
									id={"date"}
									validate={[required]}
									component={DatePickerInput}
									placeholder="Select ... "
								/>
							</div>
							{/* Second col */}
							<div className="col is-1-of-4">
								<GhinFilterSelect
									singleChoice={true}
									hideSelectAllOption={true}
									validate={[required]}
									component={SelectInput}
									className="time"
									options={time_array}
									label={"Time"}
									onChange={(time) => {
										this.setState({ time: time.value });
									}}
									name={"time"}
									id={"time"}
								/>
							</div>
							{/* Third col */}
							<div className="col is-1-of-4">
								<div className="row"></div>
								<GhinFilterSelect
									className={"day_time"}
									singleChoice={true}
									hideSelectAllOption={true}
									name={"day_time"}
									id={"day_time"}
									validate={[required]}
									component={SelectInput}
									options={["AM", "PM"]}
									onChange={(day_time) => {
										this.setState({
											day_time: day_time.value,
										});
									}}
								/>
							</div>
						</div>
					)}

					{/* Twelfth Row */}
					<div className="row">
						{/* First col */}
						<div className="col is-1-of-1">
							<p className="pn_blue pn_small pn_bold">
								Would you like to save it as a Draft?
							</p>
						</div>
					</div>
					{/* Thirteenth Row */}
					<div className="row">
						<div className="col is-1-of-2">
							<div className="radio__container">
								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												scheduled: !prevState.scheduled,
											};
										});
									}}
									checked={!this.state.scheduled}
									id="scheduled_yes"
									value={true}
									name={"scheduled"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="scheduled_yes">Yes</label>

								<Field
									onClick={() => {
										this.setState((prevState) => {
											return {
												scheduled: !prevState.scheduled,
											};
										});
									}}
									checked={this.state.scheduled}
									id="scheduled_no"
									value={false}
									name={"scheduled"}
									component={"input"}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="scheduled_no">No</label>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<button
								type={"button"}
								className="btn fill gray"
								disabled={submitting}
								onClick={() => this.props.close()}
							>
								Cancel
							</button>
						</div>
						<div className="col is-1-of-2">
							<button
								type={"submit"}
								ref={"submit"}
								disabled={pristine || submitting || invalid}
								className="btn fill blue"
							>
								{this.state.scheduled
									? "Save"
									: "Save as Draft"}
							</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

AddPushNotificationForFederationForm = reduxForm({
	form: "addPushNotificationForm",
	destroyOnUnmount: true,
})(AddPushNotificationForFederationForm);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withKeyPress(AddPushNotificationForFederationForm));
