import {Fragment} from "react";
import GhinTable from "../shared/GhinTable";

export default function TransactionFeeDetailsModal(props) {
  const transformedData = props.selectedTransaction.join_pages.flatMap(page => {
    return page.club_membership_fees.map(fee => ({
      id: page.id + "-" + fee.id,
      name: page.name,
      url: page.url,
      membership_name: fee.name
    }));
  });

  const columns = [{
    Header: "Page Name", accessor: "name", Cell: (data) => {
      return <span>{data.value}</span>;
    },
  }, {
    Header: "Association Page URL", accessor: "url", Cell: (data) => {
      return <span>{data.value}</span>;
    },
  }, {
    Header: "Membership Fee Name", accessor: "membership_name", Cell: (data) => {
      return <span>{data.value}</span>;
    },
  }];

  return (<Fragment>
    <div className="modal__container extra-wide">
      <div className="modal__head">
        <div className="modal__icon">
          <i className="material-icons-outlined">edit</i>
        </div>
        <h2 className="modal__title">
          {props.selectedTransaction.description}
        </h2>
        <button
          className="modal__close"
          onClick={() => props.closeModal()}
        >
          <i className="material-icons-outlined">clear</i>
        </button>
      </div>
      <div className="modal__body">
        <div className="row">
          <div className="col">
            <h1>
              In the table below are listed the names of the join pages the transaction fee is assigned to.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col is-full">
            <GhinTable
              idKey={"id"}
              columns={columns}
              hideSelect={true}
              hidePagination={true}
              sortable={false}
              data={transformedData}
              customNoDataMessage={'This transaction fee has not been assigned to any join page.'}
            />
          </div>
        </div>
        <div className="row" style={{marginTop: "0"}}>
          <div className="col is-1-of-2" style={{marginRight: "0", marginLeft: "auto", width: "200px"}}>
            <button
              type={"button"}
              className="btn fill gray"
              onClick={() => props.closeModal()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Fragment>);
}
