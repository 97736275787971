import React, {Component, Fragment} from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import {addAlert} from '../../../shared/actions'
import {bindActionCreators} from "redux";
import AssociationTable from "./ClubTable";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import {connect} from "react-redux";
import difference from 'lodash.difference';
import differenceBy from 'lodash.differenceby';
import uniqBy from 'lodash.uniqby';
import GolferService from "../../../../services/api/golfer";
import AddGolferGroup from "../../../Modals/AddGolferGroup";
import EditGolferGroup from "../../../Modals/EditGolferGroup";
import TableFilter from "./TableFilter";
import ClubService from "../../../../services/api/club";
import TableService from "../../../../services/tableService";
import Loader from "../../../NotFound/Loader";

class GolferGroups extends Component {

	constructor(props) {
		super(props);
		this.state = {
			addGolferGroupModalIsOpen: false,
			editGolferGroupModalIsOpen: false,
			openDeleteConfirmation: false,
			checkAllAvailable: false,
			checkAllSelected: false,
			loaded: false,
			availableGolfers: [],
			initialGolfersState: [],
			selectedGolfers: [],
			golferGroupOptions: [],
			selectedGroup: null,
			tempMovedGolfers: [],
			filters: [],
			initialClubsLength: 0,
			membershipTypes: [],
			allGolfers: [],
			loading: true
		};
		this.openAddGolferGroupModal = this.openAddGolferGroupModal.bind(this);
		this.toggleAvailableClubs = this.toggleAvailableClubs.bind(this);
		this.togleSelectedClub = this.togleSelectedClub.bind(this);
		this.toggleAllAvailable = this.toggleAllAvailable.bind(this);
		this.toggleAllSelected = this.toggleAllSelected.bind(this);
		this.onGroupChange = this.onGroupChange.bind(this);
		this.deleteGroup = this.deleteGroup.bind(this);
	}

	getMembershipTypes() {
		ClubService.getMembershipTypes(this.props.match.params.id, {include_used_membership: 'false'})
			.then(res => {
				this.setState({
					membershipTypes: res.membership_types.map(membershipType => {
						return {label: membershipType.code + ' - ' + membershipType.description, value: membershipType.id};
					}).sort((a, b) => a['label'].toString().localeCompare(b['label'].toString()))
				});
			})
			.catch(err => {
				console.error(err)
			})
	}

	openAddGolferGroupModal() {
		this.setState({addGolferGroupModalIsOpen: true});
	}

	closeModal() {
		this.setState({
			addGolferGroupModalIsOpen: false,
		});
	}

	getGroups(callback) {
		GolferService.getGroups(this.props.match.params.id)
			.then(response => {
				let golferGroupOptions = response.golfer_groups.map(club => {
					club.value = club.id;
					club.label = club.name;
					return club;
				});
				this.setState({
					golferGroupOptions
				}, callback)
			})
			.catch(err => {

			});
	}

	deleteGroup() {
		GolferService.deleteGolferGroup(this.props.match.params.id, this.state.selectedGroup.id)
			.then(() => {
				this.props.addAlert({type:'success',message:'Group has been successfully deleted'});
				this.setState({
					selectedGroup: null,
					openDeleteConfirmation: false
				});
				this.getGroups();
				this.onGroupChange(null)
			})
			.catch(err => {

			})
	}

	updateGroup() {
		let selectedGolfers = this.state.selectedGolfers.map(association => association.id);
		let data = {name: this.state.selectedGroup.name, golfer_ids: selectedGolfers}
		GolferService.updateGolferGroup(this.props.match.params.id, this.state.selectedGroup.id, data)
			.then(response => {
				this.props.addAlert({type:'success',message:'Group has been successfully saved'});
				let selectedGroup = response.golfer_group;
				selectedGroup.label = selectedGroup.name;
				selectedGroup.value = selectedGroup.id;
				this.setState({
					selectedGroup
				});
				this.getGroups();
			})
			.catch(err => {
				console.error(err);
			})
	}

	generateGolferName = (row) => {
		return row.last_name + (row.suffix ? ' ' + row.suffix + ', ' : ', ') + (row.prefix ?  row.prefix + ' ' : '') + row.first_name + ' ' + (row.middle_name ? row.middle_name : '');
	};

	getClubs() {
		let params = {}
        params = {
            ...params,
            ...TableService.filtersArrayToParams(this.state.filters)
        };

        if (this.state.selectedGroup) {
			params['not_in_group'] = this.state.selectedGroup.id;
		}

		Promise.all([
			GolferService.getAllGolfers(this.props.match.params.id, params),
			GolferService.getAllGolfers(this.props.match.params.id)
		])
			.then(([availableResponse, selectedResponse]) => {
				let availableData = availableResponse.golfers;
				let selectedData = selectedResponse.golfers;

				availableData.map(golfer => {golfer.checked = false; golfer.name = this.generateGolferName(golfer); return golfer});
				availableData = differenceBy(availableData, this.state.selectedGolfers, (club) => club.id);
				availableData = uniqBy([...availableData, ...this.state.tempMovedGolfers], v => v.id);

				selectedData = selectedData.map(golfer => {
					golfer.checked = false;
					golfer.name = this.generateGolferName(golfer);
					return golfer;
				});
				selectedData = uniqBy([...selectedData, ...this.state.tempMovedGolfers], v => v.id);


				this.setState({
					loaded: true,
					availableGolfers: availableData,
					initialGolfersState: availableData,
					allGolfers: selectedData,
					loading: false
				});
			})
			.catch(error => {
				console.error(error);
			});
	}

	componentDidMount() {
		Modal.setAppElement('body');
		this.getGroups();
		this.getClubs();
		this.getMembershipTypes();

		GolferService.getAllGolfers(this.props.match.params.id)
			.then(response => {
				let data = response.golfers;
				data.map(golfer => {golfer.checked = false; golfer.name = this.generateGolferName(golfer); return golfer});
				this.setState({
					initialClubsLength: data.length
				});
			});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.selectedGolfers !== this.state.selectedGolfers) {
			let availableGolfers = differenceBy(this.state.availableGolfers, this.state.selectedGolfers, 'id');
			this.setState({
				availableGolfers
			})
		}
	}

	toggleAvailableClubs(index, checked) {
		let associations = this.state.availableGolfers;
		let golfers = this.state.allGolfers;
		associations.forEach(assoc => {
			if (assoc.id === index) {
				assoc.checked = checked;
			}
		});
		golfers.forEach(g => {
			if (g.id === index) {
				g.checked = checked;
			}
		});
		this.setState({
			availableGolfers: associations,
			allGolfers: golfers
		})
	}

	togleSelectedClub(index, checked) {
		let associations = this.state.selectedGolfers;
		associations.forEach(assoc => {
			if (assoc.id === index) {
				assoc.checked = checked;
			}
		});
		this.setState({
			selectedGolfers: associations
		})
	}

	toggleAllAvailable(checked) {
		let availableGolfers = this.state.availableGolfers;
		availableGolfers.map(assoc => assoc.checked = checked);
		this.setState({
			availableGolfers,
			checkAllAvailable: checked
		});
	}

	toggleAllSelected(checked, callback) {
		let selectedGolfers = this.state.selectedGolfers;
		selectedGolfers.map(assoc => assoc.checked = checked);
		this.setState({
			selectedGolfers,
			checkAllSelected: checked
		}, callback);
	}

	moveSelectedClubs(direction) {
		if (direction === 'right') {
			let filteredGolfers = this.state.allGolfers.filter(assoc => assoc.checked === true).map(assoc => {assoc.checked = false; return assoc;});
			let selectedGolfers = [...this.state.selectedGolfers, ...filteredGolfers];
			let availableGolfers = differenceBy(this.state.availableGolfers, selectedGolfers, 'id')
			this.setState({
				selectedGolfers,
				availableGolfers,
				checkAllAvailable: false
			})
		} else {
			let selectedGolfers = this.state.selectedGolfers.filter(assoc => assoc.checked !== true);
			let filteredGolfers = this.state.selectedGolfers.filter(assoc => assoc.checked === true).map(assoc => {assoc.checked = false; return assoc;});
			let selectedavailableGolfers = [...this.state.availableGolfers, ...filteredGolfers];

			let tempMovedGolfers = uniqBy([...this.state.tempMovedGolfers, ...filteredGolfers], (assoc) => assoc.id)
			this.setState({
				availableGolfers: selectedavailableGolfers,
				tempMovedGolfers,
				selectedGolfers,
				checkAllSelected: false
			})
		}
	}

	onGroupChange(selectedGroup) {
		this.setState({ loading: true });
		let params = {}
		params = {
			...params,
			...TableService.filtersArrayToParams(this.state.filters)
		};

		GolferService.getAllGolfers(this.props.match.params.id, params)
			.then(response => {
				let data = response.golfers;
				data.map(golfer => {golfer.checked = false; golfer.name = this.generateGolferName(golfer); return golfer});
				data = differenceBy(data, this.state.selectedGolfers, (club) => club.id);
				data = uniqBy([...data, ...this.state.tempMovedGolfers], v => v.id);
				this.setState({
					loaded: true,
					availableGolfers: data,
					initialGolfersState: data,
					loading: false
				});
			});
		this.setState({
			availableGolfers: this.state.initialGolfersState.map(g => {g.checked = false; return g}),
			selectedGolfers: [],
			allGolfers: this.state.allGolfers.map(g => {g.checked = false; return g})
		}, () => {
			if (selectedGroup) {
				selectedGroup.golfers.forEach(gassoc => {
					this.toggleAvailableClubs(gassoc.golfer_id, true);
				});
				this.moveSelectedClubs('right');
			}
			this.setState({
				selectedGroup,
			})
		});

	}

	updateFilter(filters) {
		this.setState({
			filters
		}, () => {
			this.getClubs();
		})

	}

	render() {
		let checkedAssociationsLength = this.state.availableGolfers.filter(a => a.checked).length;
		let selectedGolfersLength = this.state.selectedGolfers.filter(a => a.checked).length;
		let availableGolfers = this.state.selectedGolfers.length + this.state.availableGolfers.length - this.state.selectedGolfers.length;
		return (
			<Fragment>



				<div className="assoc-g__head row">
					<div className="col is-1-of-4">
						<Select
							className="react-select-container"
							classNamePrefix="react-select"
							onChange={this.onGroupChange}
							isDisabled={!this.state.loaded}
							options={this.state.golferGroupOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)}
							isSearchable={false}
							value={this.state.selectedGroup}
						/>
					</div>
					{this.props.canEditClub && this.state.selectedGroup &&
					<div className="col is-1-of-4 push-left">
						<ul className="assoc-g__controls">
							<li><button className="btn" onClick={(e) => {e.preventDefault(); this.setState({editGolferGroupModalIsOpen: true})}}>Edit Name</button></li>
							<li><button className="btn" onClick={(e) => {e.preventDefault(); this.setState({openDeleteConfirmation: true})}}>Delete</button></li>
						</ul>
					</div>
					}
					{this.props.canEditClub && <div className="col is-1-of-8 push-right">
						<button className="btn fill green" onClick={this.openAddGolferGroupModal} >Add Group</button>
					</div>}
				</div>
				<TableFilter membershipTypes={this.state.membershipTypes} updateFiltered={this.updateFilter.bind(this)}/>
				{this.state.loading && <Loader />}
				<div className="assoc-g__body row">
					<div className="col is-5-of-11">
						<div className="assoc-g__column">
							<div className="assoc-g__column__head">
								Available Golfers ({availableGolfers < this.state.initialClubsLength ? (this.state.initialClubsLength - this.state.selectedGolfers.length) : availableGolfers}) {(availableGolfers < this.state.initialClubsLength ? (this.state.initialClubsLength - this.state.selectedGolfers.length) : availableGolfers) > this.state.availableGolfers.length && <Fragment> Filtered Golfers ({this.state.availableGolfers.length})</Fragment>}
							</div>
							<div className="assoc-g__column__body">
								<AssociationTable mainLabelName={"Club"} checkAll={this.state.checkAllAvailable} isMain={true} toggleAllAvailable={this.toggleAllAvailable} toggleAvailableAssociation={this.toggleAvailableClubs} golfers={this.state.availableGolfers}/>
							</div>
						</div>
					</div>
					<div className="col is-1-of-11">
						{this.props.canEditClub && <div className="assoc-g__transition-controls">
							<button disabled={!this.state.selectedGroup || !checkedAssociationsLength} className={`btn fill ${(!this.state.selectedGroup || !checkedAssociationsLength) ? 'gray' : 'blue'}`} onClick={() => this.moveSelectedClubs("right")}><i className="material-icons-outlined">chevron_right</i></button>
							<button disabled={!this.state.selectedGroup || !selectedGolfersLength} className={`btn fill ${(!this.state.selectedGroup || !selectedGolfersLength) ? 'gray' : 'blue'}`} onClick={() => this.moveSelectedClubs("left")}><i className="material-icons-outlined">chevron_left</i></button>
						</div>}
					</div>
					<div className="col is-5-of-11">
						<div className="assoc-g__column">
							<div className="assoc-g__column__head">
								{this.state.selectedGroup && (`${this.state.selectedGroup.name} (${this.state.selectedGolfers.length})`)}
							</div>
							<div className="assoc-g__column__body">
								<AssociationTable mainLabelName={"Club"} checkAll={this.state.checkAllSelected} isMain={false} toggleAllSelected={this.toggleAllSelected} toggleSelectedAssociation={this.togleSelectedClub} golfers={this.state.selectedGolfers} />
							</div>
						</div>
					</div>
				</div>

				{this.props.canEditClub && <div className="assoc-g__foot row">
					<div className="col is-1-of-8 push-right">
						<button disabled={!this.state.selectedGroup} className="btn outline gray" onClick={(e) => {e.preventDefault(); this.onGroupChange(null)}}>Cancel</button>
					</div>
					<div className="col is-1-of-8">
						<button disabled={!this.state.selectedGroup} className="btn fill blue" onClick={(e) => {e.preventDefault(); this.updateGroup()}}>Save Group</button>
					</div>
				</div>}

				<Modal
					isOpen={this.state.addGolferGroupModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Add Association Group"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddGolferGroup clubId={this.props.match.params.id} selectGroup={(group) => {this.getGroups(this.onGroupChange(group))}} closeModal={()=>{this.closeModal()}} />
				</Modal>

				<Modal
					isOpen={this.state.editGolferGroupModalIsOpen}
					onRequestClose={()=>{this.setState({editGolferGroupModalIsOpen: false})}}
					contentLabel="Add Association Group"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditGolferGroup clubId={this.props.match.params.id} selectGroup={(group) => {this.getGroups(this.onGroupChange(group))}} closeModal={() => this.setState({editGolferGroupModalIsOpen: false})} selectedGroup={this.state.selectedGroup} />
				</Modal>

				<ConfirmationModal
					question={`Are you sure you want to delete ${this.state.selectedGroup ? this.state.selectedGroup.name : ''}?`}
					confirmLabel={'Continue'}
					cancelLabel={'Cancel'}
					onCancelAction={() => this.setState({openDeleteConfirmation: false})}
					onConfirmAction={this.deleteGroup}
					openModal={this.state.openDeleteConfirmation}
					closeModal={() => this.setState({openDeleteConfirmation: false})}
				/>

			</Fragment>
		);
	}
}


function mapDispatchToProps(dispatch) {
	return bindActionCreators({addAlert}, dispatch);
}

export default connect(null, mapDispatchToProps)(GolferGroups);
