import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {SelectInput} from "../../../inputs/Select";
import {required} from "../../../inputs/validations";
import {withKeyPress} from "../../shared/highOrderComponent/withKeyPress";
import GuardianService from "../../../services/api/guardian";
import {InputField} from "../../../inputs/InputField";
import {processErrorMessage} from "../../../services/shared/errorHelper";
const relationshipTypes = [
	{label: 'Father', value: 'Father'},
	{label: 'Mother', value: 'Mother'},
	{label: 'Legal Guardian', value: 'Legal Guardian'},
	{label: 'Coach', value: 'Coach'},
	{label: 'Other', value: 'Other'}
];

class AddExistingMinorForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			associations: [],
			clubs: [],
			membershipTypes: [],
			error: null,
			membershipTypesLoaded: false
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		this.setState({
			startDate: date
		});
	}




	componentWillUnmount() {
		this.props.onRef(undefined);
	}



	onSubmit(values) {
		return GuardianService.addMinor(this.props.guardian.guardian_id, {
			golfer_id: this.props.golfer.id,
			...values,
			relationship:  values.relationship.value
		}).then(res => {
			this.props.showSuccess(this.props.golfer);
		})
			.catch(err => {
				if (err.data) {
					let errorMessage = processErrorMessage(err.data.errors);
					this.setState({
						error: errorMessage
					})
				}
			});
	}

	render() {
		const {handleSubmit, pristine, valid, submitting} = this.props;
		const showRelationshipTextInput =  this.props.relationship && this.props.relationship.value === 'Other';

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
					<div className="row with-padding ">
							Name: &#xA0; <strong>{this.props.golfer.first_name} {this.props.golfer.middle_name} {this.props.golfer.last_name}</strong>
					</div>
					<div className="row with-padding ">
							Club Name{this.props.golfer.clubs.length > 1 ? 's' : ''}:&#xA0;
							<strong>{this.props.golfer.clubs.sort((a, b) => (a.club_name > b.club_name) ? 1 : -1).map(club => {
								return <Fragment> {club.club_name}<br/></Fragment>;
							})}</strong>
					</div>
					<div className="row with-padding ">
							Handicap Index: &#xA0; <strong>{this.props.golfer.handicap_index}</strong>
					</div>
					<div className="row with-padding ">
							GHIN #: &#xA0; <strong>{this.props.golfer.id}</strong>
					</div>
					<div className="row with-padding ">
						Gender: &#xA0; <strong>{this.props.golfer.gender}</strong>
					</div>


					<div className={'row'}>
						<div className="col is-1-of-2">
							<label htmlFor="golfer.relationship">Relationship to Minor <span>*</span></label>

							<Field name={'relationship'}
								   id={'relationship'}
								   validate={[required]}
								   component={SelectInput}
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={relationshipTypes}
							/>
						</div>
						{
							showRelationshipTextInput ? (<div className="col is-2-of-2">
								<label htmlFor="other_relationship">What is the relationship ? <span>*</span></label>

								<Field component={InputField}
									   validate={[required]}
									   type="text"
									   name="other_relationship"
									   id="other_relationship"/>
							</div>) : null
						}



					</div>

					{this.state.error && <span className={'validation__message is-error'}>{this.state.error}</span>}



					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} onClick={(e) => {
								e.preventDefault();
								this.props.resetToSelect()
							}} className="btn fill gray">Back
							</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'} disabled={pristine || !valid || submitting} className="btn fill blue">Add Minor
							</button>
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

AddExistingMinorForm = reduxForm({
	form: 'addExistingMinorForm',
	destroyOnUnmount: true
})(AddExistingMinorForm);

function mapStateToProps(state) {
	const selector = formValueSelector('addExistingMinorForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		access: state.user.access,
		relationship: selector(state, 'relationship')

	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddExistingMinorForm));
