import React, {Component, Fragment} from 'react';

import EditHomeCourseForm from '../Forms/EditHomeCourseForm';

export default class EditHomeCourseModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Edit Home Course and Default Tees</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">
                        <EditHomeCourseForm assocId={this.props.assocId} cantRemove={this.props.cantRemove} facility={this.props.facility} clubId={this.props.clubId} closeModal={(data)=>this.props.closeModal(data)} />
                    </div>

                </div>

            </Fragment>
		);
	}
}
