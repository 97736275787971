export const checkKeyForDateInput = (e) => { 
  const currentValue = e.target.value + e.key;
  if (e.ctrlKey || e.metaKey || e.key === "Control" || e.key === "Meta") { return true;}
  
  const regex = /^(\d*(\d*|\/)\d*(\d*|\/))\d*$/;
  const acceptedKeys = {8:true, 37:true, 38:true, 39:true, 40:true, 46:true};
  if(!regex.test(currentValue) && acceptedKeys[e.keyCode] !== true) {e.preventDefault();}
  
  return true;
}
