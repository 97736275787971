import React, { PureComponent } from 'react'
import Modal from 'react-modal';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid,
    LabelList,
    ResponsiveContainer
  } from 'recharts';
import moment from "moment";
import HandicapRevisionDetailsModal from '../Modals/HandicapRevisionDetailsModal';
import HandicapRevisionService from "../../services/api/handicapRevision";



class HandicapIndexHistoryChart extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      handicapRevisionDetailsModalIsOpen: false,
      revisionDetails: null
    }
    this.openHandicapRevisionDetailsModal = this.openHandicapRevisionDetailsModal.bind(this);
  }
	componentDidMount() {
    Modal.setAppElement('body');
		document.getElementsByClassName('history__chart')[0].scrollLeft = 9999;
	}

  handleRevisionClick = (data, index) => {
    HandicapRevisionService.getHandicapRevisionDetails(data.Id).then((data) => {
      this.setState({
				revisionDetails: data.handicap_revision
			});
      this.openHandicapRevisionDetailsModal(index);
		});
  };

  openHandicapRevisionDetailsModal(index) {
    this.setState({
      handicapRevisionDetailsModalIsOpen: true
    });
  }

  closeModal(data) {
		this.setState({
      handicapRevisionDetailsModalIsOpen: false
    });
	}

	render() {
        const data = this.props.handicapRevisions.map((item) => {
            return {
                RevDate: moment(item.RevDate).format("MMM DD YYYY"),
                Value:  parseFloat(item.Value) === 999.0 ? 0 : parseFloat(item.Value),
                Display: item.Display,
                Id: item.ID
            }
        });

        let width = data.length*35*3;
        if (width < 1180) {
            width = 1180;
        }

        const formatter = (value) => `${value < 0 ? `+${value.toFixed(1)*-1}` : value.toFixed(1)}`;
        return (
          <>
            <div className={'history__chart'}>
                <div style={{width: width, height: 300}}>
                    <ResponsiveContainer width={'100%'} height={'100%'}>
                        <BarChart data={data} margin={{top: 30, bottom: 5}} stackOffset="sign">
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="RevDate"/>
                            <YAxis domain={[`dataMin - 3`, 'dataMax + 1']} tickFormatter={formatter}/>
                            <Bar dataKey="Value" fill="rgba(95, 173, 219, 0.8)" barSize={54}  onClick={this.handleRevisionClick}>
                                <LabelList dataKey="Display" position="top" />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <HandicapRevisionDetailsModal
              revisionDetails={this.state.revisionDetails} closeModal={() => {this.closeModal()}}
              isOpen={this.state.handicapRevisionDetailsModalIsOpen}
            />
          </>
        )
    }
}

export default HandicapIndexHistoryChart;
