export default function InfoBox(props) {
  return (
    <div className={props.className}>
      <span className={props.classTitle}>{props.title}</span>
      <span className={props.classMessage}> 
        {props.message}
      </span>
      {props.childrens}        
    </div>
  )
}
