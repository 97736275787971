import React, {Component} from 'react';

import {NavLink} from 'react-router-dom';
import {Field, reduxForm, SubmissionError} from "redux-form";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {InputField} from "../../inputs/InputField";
import {isEmailAddress, required} from "../../inputs/validations";
import AuthService from "../../services/api/auth";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class Recovery extends Component {

	constructor(props) {
		super(props);
		this.state = {
			emailSent: false
		}
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values) {

		return AuthService.sendResetEmail({...values, callback_url: window.location.origin + '/auth/reset/'})
			.then(response => {
				this.setState({
					emailSent: true
				});
			})
			.catch(err => {
				throw new SubmissionError({email: err.data.error})
			})

	}

	render() {
		const {handleSubmit} = this.props;
		return (
            <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
                <section className="auth">
                    <div className="auth__container">
                        <div className="auth__head">Forgot Password</div>
                        <div className="auth__body">
                            <div className="f-row">
                                <div className="f-col">
                                    <p className="f-paragraph">
										{this.state.emailSent ? 'An email regarding your password change has been sent to your email address.' : 'Please enter your email address to reset your password:'}
                                    </p>
                                </div>
                            </div>
							{!this.state.emailSent &&
								<div className="f-row">
									<div className="f-col">
										<label htmlFor="username">Email<span>*</span></label>
										<Field validate={[required, isEmailAddress]} component={InputField} type="text"
											   name="email" id="email"/>
									</div>
								</div>
							}

							<div className="f-row">
								<div className="f-col">
									{!this.state.emailSent && <button type={'submit'} ref={'submit'} className="btn full fill blue">Submit</button>}
									{this.state.emailSent && <NavLink className="btn full fill blue" to={"/auth/login"} >Back to Login</NavLink>}
								</div>
							</div>
							{!this.state.emailSent &&
								<div className="f-row">
									<div className="f-col auto justify-center">
										<NavLink className="form-link" to={"/auth/login"}>Back to Login</NavLink>
									</div>
								</div>
							}
                        </div>
                    </div>
                </section>
            </form>
		);
	}
}

Recovery = reduxForm({
	form: 'recoveryForm',
	destroyOnUnmount: true
})(Recovery);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(Recovery));
