import {Field} from "redux-form";
import {InputField} from "../../../inputs/InputField";
import {required} from "../../../inputs/validations";
import {SelectInput} from "../../../inputs/Select";
import {relationshipTypes} from "../../../services/shared/guardianRelationshipTypes";
import React, {Component} from "react";

class GuardianRelationshipForm extends Component{

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	render() {

		const showRelationshipTextInput = this.props.formGuardian && this.props.formGuardian.relationship && this.props.formGuardian.relationship.value === 'Other';
		return (
			<div className={'row'}>
				<div className="col is-1-of-2">
					<label htmlFor="guardian.relationship">Relationship to Minor <span>*</span></label>

					<Field name={'guardian.relationship'}
						   id={'guardian.relationship'}
						   validate={[required]}
						   component={SelectInput}
						   className="react-select-container"
						   classNamePrefix="react-select"
						   options={relationshipTypes}
					/>
				</div>
				{
					showRelationshipTextInput ? (<div className="col is-2-of-2">
						<label htmlFor="guardian.other_relationship">What is the relationship ? <span>*</span></label>

						<Field component={InputField}
							   validate={[required]}
							   type="text"
							   name="guardian.other_relationship"
							   id="guardian.other_relationship"/>
					</div>) : null
				}
			</div>
		)
	}
}
export default GuardianRelationshipForm
