import React, { Component, Fragment } from "react";
import Table from "./Table";

export default class PushNotificationList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tableNeedsRefresh: false,
		};

		this.setTableRefresh = this.setTableRefresh.bind(this);
	}

	setTableRefresh(value) {
		this.setState({ tableNeedsRefresh: value });
	}

	render() {
		return (
			<Fragment>
				<Table
					user={this.props.user}
					setTableRefresh={(value) => this.setTableRefresh(value)}
					tableNeedsRefresh={this.state.tableNeedsRefresh}
				/>
			</Fragment>
		);
	}
}
