export function updateSelectedMembership(data) {
	return {
		type: "@@ghin/UPDATE_SELECTED_MEMBERSHIP",
		payload: data
	}
}

export function clearSelectedMembership() {
	return {
		type: "@@ghin/CLEAR_SELECTED_MEMBERSHIP",
	}
}
