import request from '../shared/request';

export class GolfAssociationGroup {
	static create(data) {
		return request({
			url: '/federations/1/golf_association_groups.json',
			method: 'POST',
			data
		})
	}

	static delete(id) {
		return request({
			url: `/federations/1/golf_association_groups/${id}.json`,
			method: 'DELETE'
		})
	}

	static update(id, data) {
		return request({
			url: `/federations/1/golf_association_groups/${id}.json`,
			method: 'PATCH',
			data
		})
	}

	static list() {
		return request({
			url: '/federations/1/golf_association_groups.json',
			method: 'GET',
		})
	}

	static listAssociation() {

	}
}
