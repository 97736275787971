import React from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const pxTomm = (px) => {
	return Math.ceil(px * 25.4 / 96)
}

export default function ExportToPdfButton(props) {
	const exportToPdf = (exportButton) => {
		// Disable the button while exporting the PDF file to prevent
		// opening multiple pages
		exportButton = document.getElementById('exportButton')
		exportButton.disabled = true
		// Retrieve the component from the input ref
		const component = props.componentRef.current
		// Convert the component to an image using the html2canvas library
		// Note: This is done by copying the DOM content into an iframe
		// and directly screenshotting the component referenced by the input ref
		html2canvas(component, {
			// scale: the scale to use for rendering,
			// defaults to the browsers device pixel ratio.
			// When set, it will ensures the good quality of the screenshot and
			// also that the browser zoom level will not affect the screenshot size
			scale: props.scale,
			// onclone: callback function which is called when the component
			// has been cloned for rendering. Used here in order to change the
			// cloned component's font family.
			onclone: (clonedComponent) => {
				const clonedDiv = clonedComponent.getElementsByClassName(component.className)[0]
				clonedDiv.style.fontFamily = 'Arial, sans-serif'
			}
		}).then((canvas) => {
			const dataImage = canvas.toDataURL('image/jpeg');

			// Retrieve the image's width and height
			// and convert them from pixels to millimeters
			const imgWidth = pxTomm(canvas.width);
			const imgHeight = pxTomm(canvas.height);

			// Set the default PDF orientation to landscape
			let orientation = 'l'

			// Change the page orientation to portrait if the image's height
			// exceeds 700mm. This will prevent image cutting.
			if(imgHeight > 700) {
				orientation = 'p'
			}

			// Create a new PDF file using the jsPDF library
			// Set the PDF page width and height to the image's width and height
			// in order to preserve the component's original scale and format
			const pdf = new jsPDF(
				orientation,
				'mm',
				[imgWidth, imgHeight]
			)

			// Set the PDF title to the input title
			pdf.setProperties({title: props.title})

			// Add the obtained image to the PDF page
			pdf.addImage(dataImage, 'JPEG', 0, 0, imgWidth, imgHeight);

			// Open a new BLOB tab to display the printable PDF file
			window.open(pdf.output('bloburl'), '_blank')

			// Re-enable the export button
			exportButton.disabled = false
		})
	}

	return (
		<button
			id='exportButton'
			style={{width: 'auto'}}
			className='btn fill green'
			onClick={exportToPdf}
		>
			Export to PDF
		</button>
	)
}
