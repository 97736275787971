import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SendForm from './SendForm';
import { FederationAccount } from "../../../../services/api/federationAccount";
import {
  configInitialValues,
  configAssociationsOptions,
  prepareSendData,
  compareAssociationsByName
} from "./utils";

import { addAlert } from "../../../shared/actions";

export function Send() {
  const dispatch = useDispatch();
  let infoMessage = "Use this feature to send an email to golfers in Allied Golf Associations that meet the following criteria:";
  let [associations, setAssociations] = useState([])

  // Download the templates. This effect will run only once.
  useEffect(() => {
    FederationAccount.getAssociations()
      .then(res => {
        let sortedAssociations = res.associations.sort(compareAssociationsByName);
        setAssociations(sortedAssociations);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  let defaultAssociationsOptions = configAssociationsOptions(associations, 'GHIN');

  let initialValues = configInitialValues(defaultAssociationsOptions);
  let onSubmit = (values) => {
    let bodyData = prepareSendData(values);

    return FederationAccount.sendFederationNewsletter(bodyData)
      .then(
        (res) => {
          let alertAction = addAlert({
            type: "success",
            message: "Emails were successfully sent!"
          });
          dispatch(alertAction);
        }
      )
      .catch(
        (err) => {
          let alertAction = addAlert({
            type: "error",
            message: "Emails were not sent!"
          });
          dispatch(alertAction);
        }
      );
  };

	return (
		<div className="row">
			<div className="col">
				<div className="panel">
					<div className="panel__head">Setup</div>
					<div className="panel__body">
						<div className="row">
							<div className="col jc-fs">
								<div className="data-set__container left">
                  <p style={{marginBottom: "1rem"}}>
                    {infoMessage}
                  </p>
                  <SendForm
                    initialValues={ initialValues }
                    defaultAssociationsOptions={defaultAssociationsOptions}
                    associations = { associations }
                    enableReinitialize= { true }
                    onSubmit = { onSubmit }
                  />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
