import React, {Component} from 'react'
import {Field, reduxForm, SubmissionError} from "redux-form";
import {required, isEmailAddress, isNumber} from "../../../../../inputs/validations";
import {InputField} from "../../../../../inputs/InputField";
import AssociationService from "../../../../../services/api/association";

class PreviewAndTest extends Component {
    constructor(props) {
        super(props);
		this.onSubmit = this.onSubmit.bind(this);
    }

	onSubmit(values, dispatch,props) {
		return AssociationService.sendTestEmail(this.props.associationId, values.ghin, values.email)
			.then(res=>{
				if(res.sent === "true") {
					props.addAlert({type: 'success', message: 'Test email has been successfully sent!'})
					props.initialize();
				}
			})
			.catch(err=>{
				console.error(err);
				throw new SubmissionError({ghin: err.data.Error})
			})
	}

    render(){
		const {handleSubmit, pristine, submitting, valid} = this.props;
        return (
            <div className="row">
                <div className="col">
                    <div className="panel">
                        <div className="panel__head">Preview &amp; Test</div>
                        <div className="panel__body">
                            <div className="row">
                                <div className="col is-1-of-4">
                                    <label htmlFor="ghin_number_preview">GHIN #</label>
                                    <input type="text" id="ghin_number_preview" onChange={this.props.ghinPreviewGolfer} name="ghin_number_preview" />
                                </div>
                                <div className="col auto jc-fe">
                                    <button onClick={this.props.openEmailPreviewModal} className="btn fill green">Preview</button>
                                </div>
                            </div>
							<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
                                <div className="row">
                                    <div className="col is-1-of-4">
                                        <label htmlFor="ghin_number_test">GHIN # <span>*</span></label>
                                        <Field component={InputField} type="text" validate={[required, isNumber]} name="ghin" id="ghin" />
                                    </div>
                                    <div className="col is-1-of-4">
                                        <label htmlFor="email_address_test">Email Address <span>*</span></label>
                                        <Field component={InputField} type="text" validate={[required, isEmailAddress]} name="email" id="email" />
                                    </div>
                                    <div className={`col auto jc-fs `}>
                                        <label htmlFor="email_address_test">&#160;</label>
                                        <button disabled={pristine || submitting || !valid} type={'submit'} ref={'submit'} className="btn fill green">Send Test Email</button>
                                    </div>
                                </div>
							</form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
PreviewAndTest = reduxForm({
    form: 'erevisionTestEmailForm',
    destroyOnUnmount: true
})(PreviewAndTest);


export default PreviewAndTest;
