export function membershipReducer(state = [], action) {
	switch(action.type) {
		case '@@ghin/UPDATE_SELECTED_MEMBERSHIP':
			return {
				...state,
				membership: action.payload.membership,
				displayMembershipDetails: action.payload.displayMembershipDetails,
			};
		case '@@ghin/CLEAR_SELECTED_MEMBERSHIP':
			return {
				...state,
				membership: null,
				displayMembershipDetails: false,
			};
		default:
			return state;
	}
}
