import request from '../shared/request';

export default class CourseRatingSystemService {
	static searchCourses(name) {
		return request({
			url: `/crsCourseMethods.asmx/SearchCourses.json?name=${name}`,
			method: 'GET',
		})
	}

	static getCourseDetails(id) {
		return request({
			url: `/crsCourseMethods.asmx/GetCourseDetails.json?CourseId=${id}`,
			method: 'GET',
		})
	}
}
