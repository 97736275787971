import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import EditFederationAccountModal from '../../../Modals/EditFederationAccountModal';
import {FederationAccount} from "../../../../services/api/federationAccount";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import UploadImage from "../../../../inputs/UploadImage";
import {connect} from "react-redux";
import {updateFederation} from "./Actions/";
import {bindActionCreators} from "redux";

class PrimaryFederation extends Component {

	constructor() {
		super();
		this.state = {
            editAccountModalIsOpen: false,
			confirmModalIsOpen: false,
        };
        this.openEditAccountModal = this.openEditAccountModal.bind(this);
	}

	componentDidMount(){
		this.getAccountInfo();
	}

	getAccountInfo() {
		FederationAccount.get().then(data => {
			this.props.updateFederation(data.federation);
		});
	}

    openEditAccountModal() {
		this.setState({editAccountModalIsOpen: true});
    }

	openConfirmImageRemoveModal() {
		this.setState({confirmModalIsOpen:true});
	}

	closeEditAccountModal(updatedProfileState) {
		this.setState({
			editAccountModalIsOpen: false,
		});
		this.props.updateFederation(updatedProfileState ? updatedProfileState : this.props.federation);
	}

	saveLogo(file){
		FederationAccount.uploadLogo({logo: file}).then(res => {
			this.props.updateFederation(res.federation);
		}).catch(err=>{console.error(err)});
	}

	setAltText(text){
		FederationAccount.update({alt_text: text}).then(res => {
			this.props.updateFederation(res.federation);
		}).catch(err=>{console.error(err)});
	}

	removeLogo() {
		FederationAccount.deleteLogo().then(res => {
			this.setState({
				confirmModalIsOpen: false,
			});
			this.props.updateFederation(res.federation);
		});
	}

	render() {
		return (
            <Fragment>

                <div className="columns">

                    <div className="row">
                        <div className="col">
                            <div className="panel">
                                <div className="panel__head">Basic Information</div>
                                <div className="panel__body">
                                    <div className="row">
                                        <div className="col auto push-right">
                                            <button onClick={this.openEditAccountModal} className="btn lnk">Edit</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col jc-fs">
                                            <div className="data-set__container">
                                                <div className="data-set">
                                                    <span className="data-label">Name:</span>
                                                    <span>{this.props.federation.name}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Short Name:</span>
                                                    <span>{this.props.federation.short_name}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Phone:</span>
                                                    <span>{this.props.federation.phone}</span>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Email:</span>
													<a href={`mailto: ${this.props.federation.email}`}>{this.props.federation.email}</a>
                                                </div>
                                                <div className="data-set">
                                                    <span className="data-label">Website:</span>
													<a href={`//${this.props.federation.website}`} target='_blank' rel='noopener noreferrer'>{this.props.federation.website}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

					<div className="row">
						<div className="col">
							<div className="panel">
								<UploadImage title="USGA Logo"
											 image={this.props.federation.logo_url}
											 alt_text={this.props.federation.alt_text}
											 minimumHeight={155}
											 minimumWidth={155}
											 square={true}
											 logo={true}
											 onSave={(data) => {this.saveLogo(data)}}
											 onRemove={() => {this.removeLogo()}}
											 onSetAltText={(data) => this.setAltText(data)}
								/>
							</div>
						</div>
					</div>

                </div>

                    <Modal
						isOpen={this.state.editAccountModalIsOpen}
						onRequestClose={()=>{this.closeEditAccountModal()}}
						contentLabel="Modal"
						portalClassName="modal"
						overlayClassName="modal__overlay"
						className="modal__content"
						bodyOpenClassName="modal--is-open"
						htmlOpenClassName="prevent-scroll"
						shouldCloseOnOverlayClick={false}
						shouldFocusAfterRender={false}
					>
						<EditFederationAccountModal profile={this.props.federation} closeModal={(updatedProfileState)=>{this.closeEditAccountModal(updatedProfileState)}} />
					</Modal>

					<ConfirmationModal openModal={this.state.confirmModalIsOpen}
									   onConfirmAction={() => this.removeLogo()}
									   onCancelAction={()=>{this.setState({confirmModalIsOpen: false})}}
									   closeModal={()=>{this.setState({confirmModalIsOpen: false})}}
									   question={"Are you sure?"}
									   cancelLabel={"No"}
									   confirmLabel={"Yes"}/>

            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		federation: state.federation
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({updateFederation}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryFederation);
