import React, { Component, Fragment } from "react";
import Dropzone from "react-dropzone";
import { ImageService } from "../services/imageService";

export default class UploadPushNotificationImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			image: this.props.image,
		};
	}

	async setImage(file) {
		const renderUrl = await ImageService.convertToBase64(file);
		const dimensions = await ImageService.getImageDimensions(renderUrl);

		if (
			this.props.requiredWidth &&
			this.props.requiredHeight &&
			dimensions.width !== this.props.requiredWidth &&
			dimensions.height !== this.props.requiredHeight
		) {
			return alert("Image size not right");
		}

		this.saveImage(file);
	}

	saveImage(file) {
		this.props.onSave(file);
	}

	componentDidMount() {
		this.setState({ image: this.props.image });
	}

	componentDidUpdate(prevProps) {
		//set the state with data from props
		if (this.props.image !== prevProps.image) {
			this.setState({
				image: this.props.image,
			});
		}
	}

	render() {
		return (
			<Fragment>
				<div className="panel__head"></div>
				<div className="panel__body">
					<div
						className="upload__item"
						style={{
							display: "flex",
							justifyContent: "space-around",
						}}
					>
						<div className="upload__zone">
							<Dropzone
								accept={[
									"image/png",
									"image/jpg",
									"image/jpeg",
								]}
								onDrop={(acceptedFiles) =>
									acceptedFiles.length > 0 &&
									this.setImage(acceptedFiles[0])
								}
								multiple={false}
							>
								{({ getRootProps, getInputProps }) => (
									<div
										className="dropzone"
										{...getRootProps()}
									>
										<input {...getInputProps()} />
										<i className="material-icons-outlined">
											cloud_upload
										</i>
										<p>
											Drag &amp; Drop file here to upload
										</p>
										<p>or</p>
										<button
											className="btn fill green"
											type="button"
										>
											Choose from computer
										</button>
									</div>
								)}
							</Dropzone>
						</div>
						<div>
							<ul className="upload__requirements">
								<li>Required Width = 720 px</li>
								<li>Required Height = 360 px</li>
								<li>Required Format = PNG, JPG</li>
							</ul>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
