import React, { useEffect } from 'react';

const ContentPreview = (props) => {
	useEffect(() => {
		const iframe = document.getElementById('content-preview');

		const resizeIframe = () => {
			console.log(iframe.contentWindow.document.body.scrollHeight);
			iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 50 + 'px';
		};

		iframe.addEventListener('load', resizeIframe);

		return () => {
			iframe.removeEventListener('load', resizeIframe);
		};
	}, []);

	return (
		<div>
			<iframe
				id="content-preview"
				srcDoc={props.content}
				title="Content Preview"
				style={{ width: '100%', border: 'none' }}
			></iframe>
		</div>
	);
};

export default ContentPreview;
