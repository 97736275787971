import React, {Fragment, useEffect, useState} from "react";
import GhinTable from "../shared/GhinTable";
import ClubService from "../../services/api/club";
import moment from "moment/moment";
import JoinAndRenewServices from "../../services/api/joinAndRenew";

export default function OfferEditLogModal(props) {
  const [archivedOffers, setArchivedOffers] = useState([]);
  const [loading, setLoading] = useState(false);

  const calculateAmount = (offer) => {
    return offer.offer_type === 'promotion' ? '-' : offer.discount_type === 'dollar' ? `$${offer.discount_value.toFixed(2)}` : `${offer.discount_value}%`
  };

  const clubDiscount = (offer) => {
    return offer.offer_type === 'discount' ? (offer.club_discount ? 'Yes' : 'No') : "-"
  };

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      setLoading(true);

      JoinAndRenewServices.getArchivedPromotionalCodes(props.association_id, props.selected_offer.id)
        .then((res) => {
          if (cancel) return;

          setArchivedOffers(res.promotional_codes);
          setLoading(false);
        })
        .catch(error => {
          if (cancel) return;
          console.error("Error:", error);
          setLoading(false);
        });
    }

    fetchData();

    return () => {
      cancel = true;
    };
  }, [props.selected_offer.id, props.association_id]);

  const columns = [{
    Header: "Date Edited",
    accessor: "created_at",
    colClassName: 'col_archived_offer_date',
    Cell: props => {
      return <span>{moment(new Date(props.original.created_at), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY')}</span>;
    }
  }, {
    Header: "Offer Code", accessor: "code", colClassName: 'col_archived_offer_code', Cell: props => {
      return <span style={{display: "contents"}}>{props.original.code}</span>;
    }
  }, {
    Header: <Fragment>Offer<br/>Description</Fragment>,
    accessor: "description",
    thClassName: 'table-align-center',
    Cell: props => {
      return <span style={{display: "contents"}}>{props.original.description}</span>;
    }
  }, {
    Header: <Fragment>Message<br/>when Applied</Fragment>,
    accessor: "subtext",
    thClassName: 'table-align-center',
    Cell: props => {
      return <span style={{display: "contents"}}>{props.original.subtext}</span>;
    }
  }, {
    Header: <Fragment>Offer<br/>Type</Fragment>,
    accessor: "offer_type",
    colClassName: 'col_archived_offer_type',
    className: 'table-align-center',
    thClassName: 'table-align-center',
    Cell: data => {
      return <div>{data.original.offer_type === 'discount' ? "D" : "P"}</div>
    }
  }, {
    Header: <Fragment>Discount<br/>Amount</Fragment>,
    accessor: "discount_value",
    colClassName: 'col_archived_offer_discount_amount',
    className: 'table-align-center',
    thClassName: 'table-align-center',
    Cell: data => {
      return <span style={{display: "contents"}}>{calculateAmount(data.original)}</span>
    }
  }, {
    Header: <Fragment>Club<br/>Discount?</Fragment>,
    accessor: "club_discount",
    colClassName: 'col_archived_offer_club_discount',
    className: 'table-align-center',
    thClassName: 'table-align-center',
    Cell: data => {
      return <div>{clubDiscount(data.original)}</div>
    }
  }, {
    Header: "Status",
    accessor: "active",
    colClassName: 'col_archived_offer_status',
    className: 'table-align-center',
    Cell: data => {
      return <div>{data.original.status === 'Active' ? 'A' : 'I'}</div>
    }
  }, {
    Header: "Applied To",
    accessor: "available_to_all_ojr_clubs",
    colClassName: 'col_archived_offer_applied_to',
    Cell: data => {
      return <div>{data.original.available_to_all_ojr_clubs ? "All Clubs" : "Selected Clubs"}</div>
    }
  }, {
    Header: "Valid From",
    colClassName: 'col_archived_offer_date',
    accessor: "valid_from",
    className: 'table-align-center',
    Cell: props => {
      return <span>{moment(new Date(props.original.valid_from), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY')}</span>;
    }
  }, {
    Header: "Valid Until",
    colClassName: 'col_archived_offer_date',
    accessor: "valid_to",
    className: 'table-align-center',
    Cell: props => {
      return <span>{props.original.valid_to ? moment(new Date(props.original.valid_to), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY') : '-'}</span>;
    }
  }, {
    Header: <Fragment>Total #<br/>Available</Fragment>,
    colClassName: 'col_archived_offer_usage',
    accessor: "usage_limit",
    className: 'table-align-center',
    thClassName: 'table-align-center',
    Cell: props => {
      return <span style={{display: "contents"}}>{props.original.usage_limit ? props.original.usage_limit : '-'}</span>;
    }
  }, {
    Header: <Fragment># Uses<br/>Per Golfer</Fragment>,
    colClassName: 'col_archived_offer_usage',
    accessor: "golfer_usage_limit",
    className: 'table-align-center',
    thClassName: 'table-align-center',
    Cell: props => {
      return <span style={{display: "contents"}}>{props.original.golfer_usage_limit ? props.original.golfer_usage_limit : '-'}</span>;
    }
  }]

  return (<Fragment>
    <div className="modal__container ultra-wide">
      <div className="modal__head">
        <div className="modal__icon">
          <i className="material-icons-outlined">edit</i>
        </div>
        <h2 className="modal__title">
          {props.selected_offer.code} Edit Log
        </h2>
        <button
          className="modal__close"
          onClick={() => props.closeModal()}
        >
          <i className="material-icons-outlined">clear</i>
        </button>
      </div>
      <div className="modal__body">
        <div className="row">
          <div className="col">
            <h1>
              The table below indicates any historical changes that have been made to this offer. The
              bottom-most offer is the initial version. The top row is the most recent change. The table does not
              include the current version of the offer.
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col is-full">
            <GhinTable
              idKey={"id"}
              columns={columns}
              hideSelect={true}
              hidePagination={true}
              sortable={false}
              data={archivedOffers}
              loading={loading}
            />
          </div>
        </div>
        <div className="row" style={{marginTop: "0"}}>
          <div className="col is-1-of-2" style={{marginRight: "0", marginLeft: "auto", width: "200px"}}>
            <button
              type={"button"}
              className="btn fill gray"
              onClick={() => props.closeModal()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Fragment>);
}
