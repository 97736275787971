import {Fragment, useState, useEffect} from 'react';
import GhinFilterSelect from "../../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css';
import DatePicker from 'react-datepicker';
import {checkKeyForDateInput} from '../../../../../inputs/helper';

const initial_filters = {
  code: '',
  offer_type: '',
  status: {"label": "Active", "value": "Active"},
  valid_from: '',
  valid_to: ''
};

export default function TableFilter(props) {
  const [filters, setFilters] = useState(initial_filters);

  useEffect(() => {
    let timer = setTimeout(() => {
      props.updateFilters(
        {
          ...filters,
          status: filters.status.value,
          offer_type: filters.offer_type.value
        }
      );
    }, 500);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Fragment>
      <div className="filters">
        <div className="row">
          <div className="filters__inputs col">
            <div className="row">
              <div className={'col'}>
                <div className="row">
                  <div className="col is-1-of-5">
                    <label htmlFor="golfer_id ">Code</label>
                    <input type="text" autoComplete="off" value={filters.code} name="code" id="code" onChange={(e) => {
                      setFilters({...filters, code: e.target.value});
                    }}/>
                  </div>
                  <div className="col is-1-of-5">
                    <GhinFilterSelect
                      options={['Discount', 'Promotion']}
                      value={filters.offer_type}
                      singleChoice={true}
                      onChange={value => setFilters({...filters, offer_type: value})}
                      label={'Offer Type'}
                    />
                  </div>
                  <div className="col is-1-of-5">
                    <GhinFilterSelect
                      options={[{"label": "Active", "value": "Active"}, {"label": "Inactive", "value": "Inactive"}]}
                      value={filters.status}
                      singleChoice={true}
                      onChange={value => setFilters({...filters, status: value})}
                      label={'Status'}
                    />
                  </div>
                  <div className="col is-1-of-5">
                    <label>Valid From</label>
                    <DatePicker
                      selected={filters.valid_from ? new Date(filters.valid_from) : null}
                      minDate={null}
                      maxDate={filters.valid_to ? new Date(filters.valid_to) : null}
                      className={`date`}
                      dropdownMode="select"
                      dateFormat={'MM/dd/yyyy'}
                      showMonthDropdown
                      showYearDropdown
                      onKeyDown={e => {
                        checkKeyForDateInput(e)
                      }}
                      onChange={(value) => {
                        if (value) {
                          const date = new Date(value)
                          const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
                          setFilters({...filters, valid_from: dateString});
                        }
                      }}
                    />
                  </div>
                  <div className="col is-1-of-5">
                    <label>Valid To</label>
                    <DatePicker
                      selected={filters.valid_to ? new Date(filters.valid_to) : null}
                      maxDate={null}
                      minDate={filters.valid_from ? new Date(filters.valid_from) : null}
                      className={`date`}
                      dropdownMode="select"
                      dateFormat={'MM/dd/yyyy'}
                      showMonthDropdown
                      showYearDropdown
                      onKeyDown={e => {
                        checkKeyForDateInput(e)
                      }}
                      onChange={(value) => {
                        if (value) {
                          const date = new Date(value)
                          const dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
                          setFilters({...filters, valid_to: dateString});
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters__controls col is-1-of-10 jc-fe">
            <button className="btn fill gray" onClick={() => setFilters(initial_filters)}>Reset</button>
          </div>
        </div>
      </div>

    </Fragment>
  );
}
