import React from "react";

const Loader = () => {
	return(
		<div className='container__loader'>
			<span className="image__loader"></span>
		</div>
	)
}

export default Loader;
