import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import {addAlert} from "../../shared/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import GhinTable from "../../shared/GhinTable";
import AddMinorModal from "../../Modals/AddMinorModal";
import GuardianService from "../../../services/api/guardian";
import moment from "moment";
import {NavLink} from "react-router-dom";
const generateGolferName = (row) => {
	return row.last_name + (row.suffix ? ' ' + row.suffix + ', ' : ', ') + (row.prefix ?  row.prefix + ' ' : '') + row.first_name + ' ' + (row.middle_name ? row.middle_name : '');
};
class MinorManagement extends Component {

	constructor(props) {

		super(props);

		this.state = {
			golfers: [],
			loading: true,
			AddMinorModalIsOpen: false
		};

	}


	componentDidMount() {
		this.getGuardians()
		Modal.setAppElement('body');
	}

	getGuardians() {
		const guardianId = this.props.guardian.guardian_id;
		GuardianService
			.getGolfers(guardianId)
			.then(({golfers}) => {
				this.setState({
					loading: false,
					golfers: golfers.map((golfer) => ({
						...golfer,
						status_date: golfer.status_date ? moment(golfer.status_date).format("MM/DD/YYYY") : "",
					}))
				})
			}).catch((e) => console.log(e))
	}

	closeAddMinorModal(data) {
		this.setState({
			AddMinorModalIsOpen: false
		});

		this.getGuardians();
	}


	render() {

		const columns = [
			{
				Header: 'Minor Name',
				accessor: 'name',
				Cell: props => {
					return (
						<NavLink to={`/manage/association/${props.original.golf_association_id}/club/${props.original.club_id}/golfer/${props.original.id}`}>{generateGolferName(props.original)}</NavLink>
					)

				}
			},
			{
				Header: 'GHIN Number',
				Cell: props => {
					return (<>{props.original.id || "N/A"}</>)
				}
			},
			{
				Header: 'Gender',
				accessor: 'gender',
				colClassName: 'col_gender'
			},
			{
				Header: 'Date of Birth',
				accessor: 'date_of_birth',
				Cell: props => {
					return moment(props.original.date_of_birth).format('MM/DD/YYYY')
				}
			},
			{
				Header: 'Club Name',
				accessor: 'club_name',
			},
			{
				Header: 'Relationship to Minor',
				Cell: (props) => <>{props.original.relationship === 'Other'  ?  `Other - ${props.original.other_relationship}` : props.original.relationship}  </>
			},
			{
				Header: 'Guardianship Status',
				accessor: 'status',

			},
			{
				Header: 'Status Date',
				accessor: 'status_date',
			}
		];
		return (
			<Fragment>

				{this.props.guardian && <div className="columns">

					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">Minor Management</div>
								<div className="panel__body">

									<div className="row">
										<div className="col is-1-of-2">
											<span><strong>Note:</strong> Guardianship status is managed from the minor account.</span>
										</div>
										<div className="col auto push-right">
											{
												this.props.accessLevel > 1 ? <button className="btn fill green"
																				 onClick={() => this.setState({AddMinorModalIsOpen: true})}>Add Minor
												</button> : null
											}
										</div>
									</div>
									<div className='row'>
										<div className="col jc-fs">

											<GhinTable
												loading={this.state.loading}
												columns={columns}
												ref={(r) => this.table = r}
												hideSelect
												hidePagination={true}
												data={this.state.golfers}
												sortable={true}
											/>

										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>}

				<Modal
					isOpen={this.state.AddMinorModalIsOpen}
					onRequestClose={() => {
						this.setState({AddMinorModalIsOpen: false})
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddMinorModal guardian={this.props.guardian}
								   closeModal={(data) => {
									   this.closeAddMinorModal(data)
								   }}/>
				</Modal>

			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user_type: state.user.access.user_type,
		rawAccess: state.user.rawAccess,
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MinorManagement);
