import React, {Component, Fragment} from 'react';
import {Field, formValueSelector, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {localNumber} from "../../inputs/validations";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import moment from "moment";
import {DatePickerInput} from "../../inputs/DatePicker";
import diff from 'object-diff';
import GolferServices from "../../services/api/golfer";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import ClubService from "../../services/api/club";
import InfoBox from '../shared/InfoBox';

class EditClubMembershipForm extends Component {


	constructor(props){
		super(props);
		this.state = {
			status: false,
			status_changed: false,
			is_home_club: false,
			membershipTypes: [],
			error: null,
			isDateOfBirth: false,
			auto_renew_enabled: props.clubMembership.auto_renew_enabled,
      is_auto_renew_enabled_in_db: props.clubMembership.auto_renew_enabled,
      club_membership_fee_id: props.clubMembership.club_membership_fee_id,
			membershipIsLocked: null,
			areLockedMemberships: false,
		};
		this.onSubmit = this.onSubmit.bind(this);
		this.onValidate = this.onValidate.bind(this);
	}

	componentDidMount() {
		this.initialize();
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (prevState.status !== this.state.status && this.state.status_changed){
			this.props.change('membership_start_date', "");
			this.props.change('membership_end_date', "");
		}
	}

	onSubmit(values, dispatch, props) {

		let data = diff(props.clubMembership, values);
			data.membership_active = data.status;
			if (data.membership_start_date) {
				data.inactive_date = moment(data.membership_start_date).format('YYYY-MM-DD');
				data.inactive_flag = "Activate"
			}
			if (data.membership_end_date) {
				data.inactive_date = moment(data.membership_end_date).format('YYYY-MM-DD');
				data.inactive_flag = "Inactivate"
			}
			if (!data.membership_start_date && !data.membership_end_date) {
				data.inactive_date = "";
				data.inactive_flag = ""
			}
			delete data.membership_end_date;
			delete data.membership_start_date;
			if (props.clubMembership.status === data.status) delete data.status;
			if (data.membership_id) data.membership_id = data.membership_id.value;

      if (data.status === true && !this.props.golfer.email && !this.props.golfer.has_active_guardians) {
        const err_msg = this.props.golfer.is_minor ? 'This golfer does not have an email address or an active guardian. Please provide a valid email address or an active guardian for this golfer.'
        : 'This golfer does not have an email address. Please provide a valid email address for this golfer.'
        this.setState({ error: err_msg })
        return;
      }

			return GolferServices.updateAffiliation(props.golfer.id, props.clubMembership.id, data)
				.then(res => {
					props.closeModal(res.club_affiliation);
				})
				.catch(err => {
					if (err.data) {
						this.setState({
							error: err.data.errors
						})

					}
					console.error(err)
				});
	}



	initialize() {
		ClubService.getMembershipTypes(this.props.clubMembership.club_id, {include_used_membership: 'false'})
			.then(res => {
				let lockedMemberships = false
				this.setState({
					membershipTypes: res.membership_types.filter(membershipType => membershipType.active !== "false").map(membershipType => {
						if (membershipType.locked)
							lockedMemberships = true;
						return {
							label:
								<Fragment>
									<div style={{display: "inline-flex", alignItems: "end"}}>
										<span style={{display: "flex", alignItems: "center"}}>
											<span className="membership-description-dropdown">{membershipType.code + ' - ' + membershipType.description}</span>
											{membershipType.locked && <i className="material-icons-outlined lock-icon-container"/>}
										</span>
									</div>
								</Fragment>,
							code: membershipType.code,
							value: membershipType.id,
							locked: membershipType.locked,
							isDisabled: !this.props.canEditAssociation && membershipType.locked
						};
					}).sort((a, b) => a['label'].toString().localeCompare(b['label'].toString()))
				}, () => {
					let selectedMembership = this.props.clubMembership.membership_code ?
							this.state.membershipTypes.length > 0 ?
								this.state.membershipTypes.filter(membershipType =>
									membershipType.code === this.props.clubMembership.membership_code
								)
								:
								[]
							:
							[];

					if (selectedMembership.length > 0) {
						selectedMembership = selectedMembership[0]
					}

					this.setState({
						status: this.props.clubMembership.status === "Active" ? true : false,
						is_home_club: this.props.clubMembership.is_home_club,
						membershipIsLocked: selectedMembership.locked,
						areLockedMemberships: lockedMemberships
					});

					this.props.initialize({
						...this.props.clubMembership,
						...{
							status: this.props.clubMembership.status === "Active" ? true : false,
							is_home_club: this.props.clubMembership.is_home_club,
							local_number: this.props.clubMembership.local_number ? this.props.clubMembership.local_number.toString() : null,
							membership_start_date: (this.props.clubMembership.status !== "Active" && this.props.clubMembership.inactive_flag === "Activate") ? (this.props.clubMembership.inactive_date ? new Date(moment(this.props.clubMembership.inactive_date)) : null) : (this.props.clubMembership.membership_start_date ? new Date(moment(this.props.clubMembership.membership_start_date)) : null),
							membership_end_date: (this.props.clubMembership.status === "Active" && this.props.clubMembership.inactive_flag === "Inactivate") ? (this.props.clubMembership.inactive_date ? new Date(moment(this.props.clubMembership.inactive_date)) : null) : (this.props.clubMembership.membership_end_date ? new Date(moment(this.props.clubMembership.membership_end_date)) : null),
							membership_id:  selectedMembership ? {
								label:  selectedMembership.label,
								value:  selectedMembership.value
							} : null,
						}
					});
				});
			})
			.catch(err => {
				console.error(err);
			})
	}

	onValidate(membershipType) {
		if(membershipType && membershipType.code === "J") {
			if(!this.state.isDateOfBirth || !this.state.error) {
				this.setState({
					isDateOfBirth: true,
					error: "Please complete the Date of Birth in the Primary tab before selecting this Membership Code"
				})
			}
		} else if(membershipType && membershipType.code === "R") {
			if(this.state.error) {
				this.setState({
					error: null
				})
			}
		}
	}

	handleDateChange = (date) => {
		if (this.state.error && this.state.error.includes(date)) {
			this.setState({error: null})
		}
	}

	render() {
		let golfer = this.props.golfer;
		const ojr = this.props.clubMembership.club_membership_fee_id;
		const {handleSubmit, pristine, submitting, endDate, startDate} = this.props;
		
		const enableAutoRenew = this.props.userType === "association-admin" && this.props.rawAccess.associations.some(entry => Number(entry.golf_association.id) === Number(this.props.clubMembership.association_number));
		const disableEndDate = () => {
			if ((this.props.userType === "super-admin" || this.props.userType === "association-admin") && golfer.status === "Active")
				return false

			return  startDate || ojr;
		}
		const disableEditForLockedMembership = this.state.membershipIsLocked && !this.props.canEditAssociation;
		const ojrLocked = ojr && this.state.membershipIsLocked;
		const nonOjrWithLockedInList = !ojr && this.state.areLockedMemberships;

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
					<div className="row"><h2>{this.props.clubMembership.club_name}</h2></div>
					{ojr &&
						<div className="row" style={{marginTop: "-15px"}}>
							<span className={"red"}>NOTE: This golfer joined the club via GHIN Join & Renew. The golfers' status, Membership Code, and Start and End Dates cannot be changed until the membership has reached its natural end.</span>
						</div>
					}
					{!this.props.canEditAssociation && <div className="row" style={{width: (ojrLocked || nonOjrWithLockedInList) ? "398px" : "", marginTop: ojr ? 0 : "-15px"}}>
	            <InfoBox className="info-box-yellow"
								title = "PLEASE READ: "
								classTitle="color-red font-weight-600"
								message="Membership codes are created by your association for billing and tracking purposes. Please use caution when updating. If you have any questions, please contact your association."
							/>
	        </div>}
					{this.props.canEditAssociation && nonOjrWithLockedInList && <div className="row" style={{marginTop: ojr ? 0 : "-15px"}}>
						<InfoBox className="info-box-yellow"
										 title = "PLEASE READ: "
										 classTitle="color-red font-weight-600"
										 message="Membership codes are created by your association for billing and tracking purposes. Please use caution when updating."
						/>
					</div>}
					{!this.props.canEditAssociation && <div className="row">
						{(ojrLocked || nonOjrWithLockedInList) && <div className="locked-membership-info-box" style={{top: ojr ? "215px" : ""}}>
							<div><i className="material-icons-outlined lock-icon-container"/><span style={{fontWeight: '500'}}> Membership Code is Locked </span></div>
							<div style={{marginTop: '10px'}}> Locked Membership codes cannot be manually removed or assigned.</div>
							<div style={{marginTop: '10px'}}> Please contact your association for assistance.</div>
						</div>}
					</div>}
					{this.props.canEditAssociation && (ojrLocked || nonOjrWithLockedInList) && <div className="row">
						<div className="locked-membership-info-box" style={{padding: "5px 5px 10px 9px", top: ojr ? "255px" : "272px"}}>
							<div><i className="material-icons-outlined lock-icon-container lock-icon-container-relative"/><span style={{fontWeight: '500'}}> Membership Code is Locked </span></div>
						</div>
					</div>}
          <div className="row" style={{marginTop: this.props.canEditAssociation && ojr ? 0 : "-15px"}}>
						<div className="col is-1-of-4">
							<label htmlFor="membership_id">Membership Code <span>*</span></label>
							<Field name={'membership_id'}
								   id={'membership_id'}
								   component={SelectInput}
								   className="react-select-container memberships-select-container"
								   classNamePrefix="react-select"
								   options={this.state.membershipTypes}
                validate={!golfer.date_of_birth ? value => this.onValidate(value) : []}
                disabled={ojr || disableEditForLockedMembership}
							/>
						</div>
						<div className="col is-1-of-6 jc-fe ">
							<Field
								onClick={
									() => {
										this.setState({
											status: !this.state.status,
											status_changed: true,
										});
									}
								}
								checked={this.state.status}
								id="status"
								value={true}
								name={'status'}
								component={'input'}
                type="checkbox"
                disabled={ojr || disableEditForLockedMembership}
							/>
							<label htmlFor="status">Active</label>
						</div>
						<div className="col is-1-of-5 jc-fe ">
							<Field
								onClick={
									() => {
										this.setState({
											is_home_club: !this.state.is_home_club,
										});
									}
								}
								checked={this.state.is_home_club}
								id="is_home_club"
								value={true}
								name={'is_home_club'}
								component={'input'}
								type="checkbox"
							/>
							<label htmlFor="is_home_club">Home Club</label>
						</div>
					</div>

					<div className='row'>
						<div className="col is-1-of-3">
							<label htmlFor="local_number">Local Number </label>
							<Field component={InputField} validate={[localNumber]} type="text" name="local_number" id="local_number" />
						</div>
						<div className="col is-1-of-3">
							<label htmlFor="date">Start Date</label>
							<Field className={'date'}
								   showMonthDropdown
								   showYearDropdown
									 disabled={this.state.status === true || endDate || ojr || disableEditForLockedMembership}
								   minDate={new Date()}
								   name={'membership_start_date'}
								   value={null}
								   id={'membership_start_date'}
								   component={DatePickerInput}
									 onFocus={e => this.props.change('membership_start_date', new Date())}
								   placeholder="Select ..."
									 onChange={e => this.handleDateChange("Start Date")}/>

						</div>
						<div className="col is-1-of-3">
							<label htmlFor="date">End Date</label>
							<Field className={'date'}
								   showMonthDropdown
								   showYearDropdown
									 disabled={this.state.status === false || disableEndDate() || disableEditForLockedMembership}
								   minDate={ojr ? endDate : new Date()}
								   name={'membership_end_date'}
								   value={null}
								   id={'membership_end_date'}
								   component={DatePickerInput}
									 onFocus={e => this.props.change('membership_end_date', new Date(moment().add(1,'days')))}
								   placeholder="Select ..."
									 onChange={e => this.handleDateChange("End Date")}/>
						</div>
					</div>
					{ojr && (this.props.userType === "super-admin" || enableAutoRenew) &&
						<div className="row">
							<div className="col is-full">
								<span className="label">Auto-Renew Enabled? <span>*</span></span>
								<div className="radio__container">
									<Field
										id="auto_renew_enabled-yes"
										value={true}
										onClick={
											() => {
												this.setState(() => {
													return { auto_renew_enabled: true };
												});
											}
										}
										name={'auto_renew_enabled'}
										component={'input'}
										checked={this.state.auto_renew_enabled}
										className="simple__radio"
										type="radio"
										disabled={!this.state.is_auto_renew_enabled_in_db}
									/>
									<label htmlFor="auto_renew_enabled-yes">Yes</label>

									<Field
										id="auto_renew_enabled-no"
										value={false}
										onClick={
											() => {
												this.setState(() => {
													return { auto_renew_enabled: false };
												});
											}
										}
										name={'auto_renew_enabled'}
										component={'input'}
										checked={!this.state.auto_renew_enabled}
										className="simple__radio"
										type="radio"
									/>
									<label htmlFor="auto_renew_enabled-no">No</label>

								</div>
							</div>
						</div>
					}
					{this.state.error && <span className={'validation__message is-error'}>{this.state.error}</span>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={() => this.props.closeModal(null)}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={pristine || submitting} className="btn fill blue">Save</button>
						</div>
					</div>

				</form>
			</Fragment>
		);
	}
}


EditClubMembershipForm = reduxForm({
	form: 'editClubMembershipForm',
	destroyOnUnmount: true
})(EditClubMembershipForm);

function mapStateToProps(state) {
	const selector = formValueSelector('editClubMembershipForm');
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		startDate: selector(state, 'membership_start_date'),
		endDate: selector(state, 'membership_end_date'),
		userType: state.user.access.user_type,
		rawAccess: state.user.rawAccess,
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditClubMembershipForm));
