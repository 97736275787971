import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {passwordMatch, required} from "../../inputs/validations";
import {InputField} from "../../inputs/InputField";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AuthService from "../../services/api/auth";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class ResetPassword extends Component {

	constructor(props) {
		super(props);
		this.state = {
			success:false,
			error: null,
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(values) {
		return AuthService.resetPassword({password: values.password, reset_password_token: this.props.match.params.reset_password_token})
			.then(resp => {
				if (resp && resp.user && resp.user.email) {
						this.setState({success:true})
				}
			})
			.catch(err => {
				console.log(err);
				this.setState({error: this.props.setPassword ? 'Set password token is invalid' : 'Reset password token is invalid'})
			})
	}

	componentDidMount() {

		if (!this.props.match && !this.props.match.params && !this.props.match.params.reset_password_token) {
			this.props.history.push("/")
		}
	}

	render() {
		const {setPassword, handleSubmit, pristine, submitting} = this.props;
		return (
			<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
				<section className="auth">
					<div className="auth__container">
						<div className="auth__head">USGA Password {setPassword ? 'Set' : 'Reset'}</div>
						<div className="auth__body">
							<div className="usga__logo push-right"></div>
							{this.state.success ?
								<Fragment>
									<div className="f-row">
										<div className="f-col">
											<label htmlFor="password">Password set successfully</label>
										</div>
									</div>

									<div className="f-row">
										<div className="f-col">
											<button type={'button'} onClick={() => {this.props.history.push('/auth/login')}}  className="btn full fill blue">Admin Portal Login</button>
										</div>
									</div>
								</Fragment>
								:
								<Fragment>
									<div className="f-row">
										<div className="f-col">
											<label htmlFor="password">New Password <span>*</span></label>
											<Field validate={[required, passwordMatch()]} className={'input'} component={InputField} type="password" name="password" id="password" />
										</div>
									</div>
									<div className="f-row">
										<div className="f-col">
											<label htmlFor="confirm_password">Confirm Password <span>*</span></label>
											<Field validate={[required, passwordMatch()]} className={'input'} component={InputField} type="password" name="confirm_password" id="confirm_password" />
										</div>
									</div>

									{this.state.error && <div className="f-row">
										<div className="f-col">
											<span className={'validation__message is-error'}>{this.state.error}</span>
										</div>
									</div>}
									<div className="f-row">
										<div className="f-col">
											<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn full fill blue">{setPassword ? 'Set password' : 'Reset password'}</button>
										</div>
									</div>
								</Fragment>
							}
						</div>
					</div>
				</section>
			</form>
		);
	}
}

ResetPassword = reduxForm({
	form: 'recoveryForm',
	destroyOnUnmount: true,
	touchOnBlur: false
})(ResetPassword);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withKeyPress(ResetPassword)));

