import React, {Component, Fragment} from 'react';
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";
import GhinFilterSelect from "../../shared/GhinFilterSelect";
import ClubService from "../../../services/api/club";
import TableService from "../../../services/tableService";

const statusOptions = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' },
];

export default class TableFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            associations: [],
            filters: {search: this.props.searchText}
        };
    }

    componentDidMount() {
        this.loadAssociations();
    }

    updateFilter = (name, value) => {

        let newState = {
            filters: {
                ...this.state.filters,
                [name]: value
            },
        };
        this.setState(newState, () => {
            this.updateTable();
        })
    };

    updateTable = debounce(() => {
        let filteredArray = TableService.filtersToArray(this.state.filters);
        this.props.updateFiltered(filteredArray);
    }, 200);


    reset() {
        this.setState({filters: {search: "", email: "",  association_ids: null, status: null}}, () => {
            this.updateTable();
        });
    }

    loadAssociations() {
        return ClubService.getGolfAssociations("global").then((data) => {
            this.setState({
                associations: data.associations.map((item) => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                }).sort((a, b) => a.label.localeCompare(b.label) )
            })
        })
    }

    render() {
        return (
            <Fragment>
                <div className="filters columns">
                    <div className="row">
                        <div className={"filters__inputs col"}>
                            <div className={"row"}>
                            <div className="col is-1-of-7">
                            <label htmlFor="association-name">User Name</label>
                            <input type="text" autoComplete="off" value={this.state.filters.search} name="user-name" id="user-name" onChange={(e) => {
                              this.updateFilter('search', e.target.value)
                            }}/>
                            </div>
                    
                              <div className="col is-1-of-6">
                                <label htmlFor="association-name">Email</label>
                                <input type="text" autoComplete="off" value={this.state.filters.email} name="email" id="email" onChange={(e) => {
                                  this.updateFilter('email', e.target.value)
                                }}/>
                              </div>

                              <div className={'col is-1-of-6'}>
                              <GhinFilterSelect
                               numberDisplayed={1}
                               isSearchable={true}
                               options={this.state.associations}
                               value={this.state.filters.association_ids}
                               onChange={values => this.updateFilter('association_ids', values)}
                               label={'Association'}
                               />
                                </div>

                              <div className="col is-1-of-6">
                              <GhinFilterSelect
                                options={statusOptions}
                                  value={this.state.filters.active}
                                  onChange={values => this.updateFilter('active', values)}
                                  label={'Status'}
                              />
                              </div>
                              </div>
                              </div>
                              <div className="filters__controls col is-1-of-7 jc-fe">
                                <button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
                              </div>
                            </div>

                </div>

            </Fragment>
        );
    }
}
