import {connectRouter} from 'connected-react-router';
import {reducer as formReducer} from 'redux-form';
import {authReducer} from "./screens/Auth/reducers";
import {userReducer} from "./screens/shared/reducers";
import {alertsReducer} from "./screens/shared/reducers/alerts";
import {termsReducer} from "./screens/shared/reducers/terms";
import {federationReducer} from "./screens/Manage/Federation/Account/Reducers/";
import {associationReducer} from "./screens/Manage/Association/Account/Reducers";
import {clubReducer} from "./screens/Manage/Club/Account/Reducers";
import {membershipReducer} from "./screens/Golfer/Account/ClubMembership/reducers";
import {productAlertsReducer} from "./screens/shared/reducers/productAlerts";
import { associationAlertsReducer } from './screens/shared/reducers/associationAlerts';

const historyReducer = (history) => {
	return {
		router: connectRouter(history),
		form: formReducer,
		auth: authReducer,
		user: userReducer,
		terms: termsReducer,
		alerts: alertsReducer,
		federation: federationReducer,
		association: associationReducer,
		club: clubReducer,
		membership: membershipReducer,
		productAlerts: productAlertsReducer,
    associationAlerts: associationAlertsReducer
	}
}

export default historyReducer;
