import React, {Component, Fragment} from 'react'
import AssociationService from "../../../../../../services/api/association";
import moment from "moment";
import ReactTooltip from 'react-tooltip';
import GhinTable from "../../../../../shared/GhinTable";
import TableFilter from "./TableFilter";
import TableService from "../../../../../../services/tableService";
import PreviewEmailModal from "../../../../../Modals/PreviewEmailModal";
import ItemComponent from '../../../../../shared/ItemComponent';

class Campaigns extends Component {
    constructor(props) {
        super(props);
		this.state = {
			filtered: [],
			exportUrl: null,
      exportUrlForCampaign: null,
			campaigns: [],
			campaignsLoading: true,
      campaign_sorted: [{id: "date", desc: true}],
			selectedCampaign: null,
			campaignDetails: [],
			detailsLoading: true,
			sorted: [{id: "ghin_number", desc: false}],
			pages:0,
      campaign_pages: 0,
      campaign_per_page: 5,
      campaign_page: 0,
			page:0,
			per_page: 20,
			total: 0,
			previewEmailModalIsOpen: false,
			campaignId: null
		}
    }

	componentDidMount() {
		this.loadCampaigns();
		this.getExportUrl();
	}

	updateFilter(filtered) {
		this.setState({
			filtered,
			page: 0
		}, () => {
			this.loadDetails();
		});
	};

	getClubRequestParams() {
		let params = {
			page: (this.state.page) + 1,
			per_page: this.state.per_page
		};

		if (this.state.sorted.length > 0) {
			params['sorting_criteria'] = this.state.sorted[0].id;
			params['order'] = this.state.sorted[0].desc ? 'desc' : 'asc';
		}

		params = {
			...params,
			...TableService.filtersArrayToParams(this.state.filtered)
		};
		return params;
	}

    loadDetails() {
		this.setState({
			detailsLoading: true,
			campaignDetails : []
		});

		const params = this.getClubRequestParams();
		params.status = params.sent === "open" ? "open" : null;
		params.sent  = params.sent === "open" ? true : params.sent;

		AssociationService.getCampaignDetails(this.props.associationId,this.state.selectedCampaign.id, params)
			.then( res => {
				this.setState({
					detailsLoading: false,
					total: parseInt(res['total-count'], 10),
					per_page: parseInt(res['per-page'], 10),
					pages: parseInt(res['total-pages'], 10),
					campaignDetails: res.campaign_emails.map(campaign => {
						return {
							...campaign,
							date_opened: campaign.date_opened  && moment(campaign.date_opened).format("MM-DD-YYYY HH:mm:ss"),
							date_sent: campaign.date_sent && moment(campaign.date_sent).format("MM-DD-YYYY HH:mm:ss")
						}
					})
				});
			})
			.catch( err => {
				console.log(err)
			})
	}

  getCampaignParams(){
    let params = {
      page: this.state.campaign_page+1,
      per_page: this.state.campaign_per_page,
    }

    if (this.state.campaign_sorted.length > 0) {
      if(this.state.campaign_sorted[0].id === 'date'){
        params['sorting_criteria'] = 'date_sent'
      }
			params['order'] = this.state.campaign_sorted[0].desc ? 'DESC' : 'ASC';
		}
    return params
  }

	loadCampaigns() {
    this.setState({
      campaignsLoading: true,
      campaigns: []
    })
    let params = this.getCampaignParams()
		AssociationService.getCampaigns(this.props.associationId, params)
			.then( res => {
				this.setState({campaigns: res.campaigns.map(campaign => {
						return {
							...campaign,
							date: moment(campaign.created_at).format("MM/DD/YYYY HH:mm:ss"),
							open_percent: campaign.open_percent + '%',
              unique_opens_percent: campaign.unique_opens_percent + '%',
						}
					}),
          campaignsLoading: false,
          campaign_pages: res['total-pages'],
          campaign_per_page: res['per-page'],
          campaign_total: res['total-count']
        });
			})
			.catch( err => {
				console.log(err);
			})
	}

	getExportUrl() {
		AssociationService.exportCampaigns(this.props.associationId)
			.then(res => {
				this.setState({exportUrl: res.url});
			})
			.catch(err => {
					console.log(err)
			});
	}

  getExportUrlForCampaign() {
		AssociationService.exportCampaign(this.props.associationId, this.state.selectedCampaign.id )
			.then(res => {
				this.setState({exportUrlForCampaign: res.url});
			})
			.catch(err => {
					console.log(err)
			});
	}

	showTemplatePreview(id) {
		this.setState({
			previewEmailModalIsOpen: true,
			campaignId: id
		})
	}

	closeModal(){
		this.setState({
			previewEmailModalIsOpen: false,
			campaignId: null
		})
	}

    render(){
		let columns = [
			{
				Header: 'Date Sent',
				accessor: 'date',
				colClassName: 'col_username',
        className: 'table-align-center',
        thClassName: 'table-align-center'
			},
			{
				Header: 'Template Name',
				accessor: 'name',
				colClassName: 'col_golfer-name-short',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
				Cell: props => <Fragment>
					<a href="#donothing" className="nav__link" onClick={(e) => {e.preventDefault(); this.showTemplatePreview(props.original.id)}}>{props.value}</a>
				</Fragment>,
			},
			{
				Header: '# Sent',
				accessor: 'processed',
        colClassName: 'col_status',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
			{
				Header: '# Delivered',
				accessor: 'delivered_count',
        colClassName: 'col_centered_checkbox',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
      {
				Header: '# Not Delivered',
				accessor: 'error_count',
        colClassName: 'col_date',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
      {
				Header: <Fragment>
					Total Opens
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='total_opens'><i className="material-icons v-mid m-left white">info</i></a>
					<ReactTooltip id='total_opens' className="table-head__tooltip" type="light" place='top' effect={'float'}>
						<strong>Total Opens </strong> data was added on 08/18/2021. Historical data prior to this date is unavailable.
              <strong> N/A</strong> will display in the <strong>Total Opens</strong> field for newsletters sent prior to 08/18/2021.
					</ReactTooltip>
				</Fragment>,
				accessor: 'total_opens',
				colClassName: 'col_golfer-name-short',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
			{
				Header: '# Unique Opens',
				accessor: 'open_count',
        colClassName: 'col_phone-number',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
      {
				Header: 'Open Rate',
				accessor: 'unique_opens_percent',
        colClassName: 'col_phone-number',
        className: 'table-align-center',
        thClassName: 'table-align-center',
        sortable: false,
			},
			{
				Header: '',
				sortable: false,
				show: this.props.canEditAssociation,
				Cell: props => ( <td className='col_delete'>
        <button className={'btn lnk'} onClick={() =>
          this.setState({selectedCampaign: null, filtered: [],campaignDetails: [], detailsLoading: true},
            () => this.setState({selectedCampaign: props.original}, () => {
              this.getExportUrlForCampaign()
            }))}>Details</button>
				</td>)
			}
		];

		let selectedColumn = [
			{
				Header: 'Date Sent',
				accessor: 'date',
				colClassName: 'col_username',
        className: 'table-align-center',
        thClassName: 'table-align-center'
			},
			{
				Header: 'Template Name',
				accessor: 'name',
				colClassName: 'col_golfer-name-short',
        className: 'table-align-center',
        thClassName: 'table-align-center',
				Cell: props => <Fragment>
					<a href="#donothing" className="nav__link" onClick={(e) => {e.preventDefault(); this.showTemplatePreview(props.original.id)}}>{props.value}</a>
				</Fragment>,
			},
      {
				Header: '# Sent',
				accessor: 'processed',
        colClassName: 'col_status',
        className: 'table-align-center',
        thClassName: 'table-align-center'
			},
			{
				Header: '# Delivered',
				accessor: 'delivered_count',
        colClassName: 'col_centered_checkbox',
        className: 'table-align-center',
        thClassName: 'table-align-center'
			},
      {
				Header: '# Not Delivered',
				accessor: 'error_count',
        colClassName: 'col_date',
        className: 'table-align-center',
        thClassName: 'table-align-center'
			},
      {
        Header: <Fragment>
					Total Opens
					<a href="#donothing" onClick={(e) => {e.preventDefault()}} data-tip data-for='total_opens'><i className="material-icons v-mid m-left white">info</i></a>
					<ReactTooltip id='total_opens' className="table-head__tooltip" type="light" place='top' effect={'float'}>
						<strong>Total Opens </strong> data was added on 08/18/2021. Historical data prior to this date is unavailable.
              <strong> N/A</strong> will display in the <strong>Total Opens</strong> field for newsletters sent prior to 08/18/2021.
					</ReactTooltip>
				</Fragment>,
				accessor: 'total_opens',
				colClassName: 'col_golfer-name-short',
        className: 'table-align-center',
        thClassName: 'table-align-center'
			},
			{
				Header: '# Unique Opens',
				accessor: 'open_count',
        colClassName: 'col_phone-number',
        className: 'table-align-center',
        thClassName: 'table-align-center'
			},
      {
				Header: 'Open Rate',
				accessor: 'unique_opens_percent',
        colClassName: 'col_phone-number',
        className: 'table-align-center',
        thClassName: 'table-align-center'
			}
		];

		let detailsColumns = [
			{
				Header: 'GHIN Number',
				accessor: 'ghin_number',
			},
			{
				Header: 'Email Address',
				accessor: 'email',
				colClassName: 'col_golfer-name'
			},
			{
				Header: 'Date/Time Sent',
				accessor: 'date_sent',
				colClassName: 'col_golfer-name',
        Cell: props => moment(new Date(props.original.date_sent), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss')
			},
			{
				Header: 'Status',
        accessor: 'status',
        Cell: props => {
          const key = Math.random();
          return <div>{props.value}{props.original.not_sent && props.original.error_message && <Fragment><a href="#donothing" onClick={(e) => { e.preventDefault() }} data-tip data-for={`status${key}`}>
            <i className="material-icons v-mid m-left dark-blue">info</i></a>
            <ReactTooltip id={`status${key}`} className="auto-size-opaque_tooltip" type="light" place='top' effect={'float'} style={{ width: "auto" }}>
              {props.original.error_message}
            </ReactTooltip>
          </Fragment>
          }</div>
        }
      }
		];

    let impressionsColumns = [
      {
        Header: <Fragment>
          Total Image Views
          <a data-tip data-for='total_image_views' href="#donothing"><i className="material-icons v-mid m-left white">info</i></a>
          <ReactTooltip id='total_image_views' className="table-head__tooltip" type="light" place='top' effect={'float'}>
            <strong>Total Image Views </strong>data was added on 03/23/2022. Historical data prior to this date is unavailable.
            <strong> N/A</strong> will display in the <strong>Total Image Views</strong> field for newsletters sent prior to 03/23/2022 and for newsletters without images.
          </ReactTooltip>
        </Fragment>,
        accessor: 'total_image_views',
        colClassName: 'col_centered_checkbox',
        className: 'table-align-center',
        thClassName: 'table-align-center'
      },
      {
        Header: <Fragment>
          Total Link Clicks
          <a data-tip data-for='total_link_clicks' href="#donothing"><i className="material-icons v-mid m-left white">info</i></a>
          <ReactTooltip id='total_link_clicks' className="table-head__tooltip" type="light" place='top' effect={'float'}>
            <strong>Total Link Clicks</strong> data was added on 03/23/2022. Historical data prior to this date is unavailable.
            <strong> N/A</strong> will display in the <strong>Total Link Clicks</strong> field for newsletters sent prior to 03/23/2022.
          </ReactTooltip>
        </Fragment>,
        accessor: 'total_link_clicks',
        colClassName: 'col_centered_checkbox',
        className: 'table-align-center',
        thClassName: 'table-align-center'
      },
      {
        Header: 'Links',
        accessor: 'link_clicks',
        colClassName: 'col_golfer-name-short',
        className: 'table-align-center-no-padding without_tooltip',
        thClassName: 'table-align-center-no-padding',
        Cell: props => {
          if (props.value !== null && props.value.length > 0) return (
            <table className='data__table'>
              <tbody>
                {props.value.map((elem) => {
                  return (<tr key={Math.random().toString()}><td>
                    <ItemComponent key={Math.random().toString()} id={elem.link} data={elem.link} />
                  </td></tr>)
                }
                )}
              </tbody>
            </table>
          )
          else return ''
        }
      },
      {
        Header: 'Link Clicks',
        accessor: 'link_clicks',
        colClassName: 'col_centered_checkbox',
        className: 'table-align-center-no-padding',
        thClassName: 'table-align-center-no-padding',
        Cell: props => {
          if (props.value !== null && props.value.length > 0) return (
            <table className='data__table' style={{ 'border': 'none' }}>
              <tbody>
                {props.value.map((elem) => {
                  return <tr key={Math.random().toString()}><td>{elem.clicks}</td></tr>
                })}
              </tbody>
            </table>)
          else return ''
        }
      },
      {
        Header: 'Clickthrough Rate',
        accessor: 'link_clicks',
        colClassName: 'col_centered_checkbox',
        className: 'table-align-center-no-padding',
        thClassName: 'table-align-center-no-padding',
        Cell: props => {
          if (props.value !== null && props.value.length > 0) return (
            <table className='data__table' style={{ 'border': 'none' }}>
              <tbody>
                {props.value.map((elem) => {
                  return <tr key={Math.random().toString()}><td>{elem.clickthrough_rate}%</td></tr>
                })}
              </tbody>
            </table>
        )
        else return ''
      }
      }

    ]

		return (
			<div className="row">
				<div className="col">
					<div className="panel">
						<div className="panel__head">Sent Newsletters & Statistics</div>
						<div className="panel__body">
							<div className="row jc-c">
								<div className="col">
									<p>Click the "Template Name" to view the email sent on that specific date.</p>
									<p>Press the "Details" button to view more information about the selected email.</p>
								</div>
								<div className="col auto push-right">
									<a href={this.state.exportUrl} target="_blank" rel="noreferrer" className="btn fill green small">Export to Excel (Last 12 Months)</a>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<GhinTable
										idKey={'id'}
										columns={columns}
                    hideSelect={true}
                    pages={this.state.campaign_pages}
                    page={this.state.campaign_page}
                    pageSize={this.state.campaign_per_page}
                    onPageSizeChange={(pageSize, pageIndex) => this.setState({
                      campaign_per_page: pageSize,
                      campaign_pages: pageIndex,
                      campaign_page: 0
                    }, () => this.loadCampaigns())}
										loading={this.state.campaignsLoading}
                    total={this.state.campaign_total}
                    manual
                    sortable={true}
										data={this.state.campaigns}
                    onPageChange={(pageIndex) =>this.setState({campaign_page: pageIndex}, () =>  this.loadCampaigns())}
                    sorted={this.state.campaign_sorted}
                    onSortedChange={(newSorted, column, shiftKey) => {
                      this.setState({
                        campaign_sorted: newSorted,
                        page: 0
                      }, () => {
                        this.loadCampaigns()
                      })
                    }}
									/>
								</div>
							</div>
							{this.state.selectedCampaign &&
							<Fragment>
								<div className="row">
									<div className="col">
										<div className="panel">
											<div className="panel__head">
												<span className="gray italic">{this.state.selectedCampaign.name}</span>
											</div>
											<div className="panel__body">
                        <div className="row" style={{'margin': 0}}>
                          <div className="col auto push-right">
                            <a href={this.state.exportUrlForCampaign} target="_blank" rel="noreferrer" className="btn fill green small">Export to Excel</a>
                            </div>
                        </div>
                          <div className="row">
                            <div className="col">
                              <p><strong>Overall Statistics</strong></p>
                            </div>
                          </div>
												<div className="row">
													<div className="col">
														<GhinTable
															idKey={'id'}
															columns={selectedColumn}
															hideSelect={true}
															data={[this.state.selectedCampaign]}
															ref={(r) => this.table = r}
															sortable={false}
															hidePagination={true}
														/>
													</div>
												</div>
                          <div className="row jc-c">
                            <div className="col">
                              <p><strong>Image & Link Impressions</strong></p>
                            </div>
                          </div>
                        <div className="row">
													<div className="col">
														<GhinTable
															idKey={'id'}
															columns={impressionsColumns}
															hideSelect={true}
															data={[this.state.selectedCampaign]}
															ref={(r) => this.table = r}
															sortable={false}
															hidePagination={true}
														/>
													</div>
												</div>
                        <div className="form__separator padding-16-y"></div>
                        <div className="row jc-c">
                            <div className="col">
                              <p><strong>Golfer Search</strong></p>
                            </div>
                          </div>
												<div className="row">
													<div className="col">
														<TableFilter updateFiltered={this.updateFilter.bind(this)} hideFilters={false} />
													</div>
												</div>
												<div className="row">
													<div className="col">
														<GhinTable
															idKey={'id'}
															columns={detailsColumns}
															loading={this.state.detailsLoading}
															hideSelect={true}
															manual
															data={this.state.campaignDetails}
															total={this.state.total}
															pages={this.state.pages}
															page={this.state.page}
															pageSize={this.state.per_page}
															ref={(r) => this.table = r}
															onPageChange={(pageIndex) => this.setState({page: pageIndex}, () =>  this.loadDetails())}
															onPageSizeChange={(pageSize, pageIndex) => this.setState({
																per_page: pageSize,
																page: pageIndex
															}, () => this.loadDetails())}
															defaultSorted={[{
																id: 'SeasonName',
																desc: false
															}]}
															sortable={true}
															sorted={this.state.sorted}
															onSortedChange={(newSorted, column, shiftKey) => {
																this.setState({
																  sorted: newSorted,
																  page: 0
																}, () => this.loadDetails())}
															}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Fragment>}
						</div>
					</div>
				</div>
				{this.state.previewEmailModalIsOpen && <PreviewEmailModal
					campaignId={this.state.campaignId}
					associationId={this.props.associationId}
					closeModal={() => {this.closeModal()}}
				/>}
			</div>
		)
    }
}


export default Campaigns;
