import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import {isNumeric, maxLength, required} from "../../inputs/validations";
import AssociationService from "../../services/api/association";
import {addAlert} from '../shared/actions';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import ReactTooltip from "react-tooltip";

const usgaMembershipType = [
    { value: 'Junior', label: 'Junior' },
    { value: 'Standard', label: 'Standard' },
]

class EditMembershipTypeForm extends Component {

	constructor(props) {
		super(props);
		this.state = {active:false, errors: []};
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
    	this.props.initialize(
    		{...this.props.membership,
				...{type: {
						label: this.props.membership.type,
						value: this.props.membership.type
					},
					bill_rate: this.props.membership.bill_rate.toString(),
					active: this.props.membership.active === "true" ? true : false
    			}
    		});
		this.setState({active: this.props.membership.active === "true" ? true : false});
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));
		data.type = data.type.value;
		data.active = data.active ? 'true' : 'false';
		delete data.id;
		delete data.is_used;
		return AssociationService.editMembershipType(this.props.associationId, this.props.membership.id, data)
			.then(res => {
				this.props.addAlert({
					type:'success',
					errors: [],
					message:'Membership type successfully edited'
				});
				this.props.closeModal('refresh')
			})
			.catch(err => {
				if (err.data && err.data.error) {
					this.setState({errors: [err.data.error]});
				} else if (err.data && err.data.errors) {
					let errors = [];
					Object.keys(err.data.errors).map(error => {
						errors.push(err.data.errors[error][0]);
						return null;
					});
					this.setState({errors});
				}
				console.error(err)
			});
	}

	render() {
		const { handleSubmit, submitting, pristine, valid} = this.props;

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
					<div className="row">
						<div className="col is-1-of-4">
							<label htmlFor="code">Membership Code <span>*</span></label>
							<Field component={InputField} validate={[required, maxLength([2,'Code must contain max 2 characters'])]} type="text" name="code" id="code" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="description">Membership Description <span>*</span></label>
							<Field component={InputField} validate={[required]} type="text" name="description" id="description" />
						</div>
					</div>

					<div className="row">
						<div className="col is-5-of-7">
							<label htmlFor="type">USGA Membership Type <span>*</span></label>
							<Field validate={[required]}
								   component={SelectInput}
								   disabled={this.props.membership.is_used}
								   id='type'
								   name='type'
								   className="react-select-container"
								   classNamePrefix="react-select"
								   options={usgaMembershipType}
								   />
						</div>
						<div className="col is-2-of-7">
							<label htmlFor="bill_rate">Bill Rate <span>*</span></label>
							<Field component={InputField}
								   validate={[required, isNumeric]}
								   type="text"
								   name="bill_rate"
								   id="bill_rate" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-4 jc-fe">
							<Field component={'input'}
								   type="checkbox"
								   disabled={this.props.membership.is_used}
								   name="active"
								   id="active"
								   checked={this.state.active}
								   onClick={()=>{this.setState({active: !this.state.active})}} />
							<label htmlFor="active">Active</label>
						</div>
					</div>

					<div className="row" style={{marginTop: "-14px"}}>
						<div className="col" style={{flexDirection: "row"}}>
							<Field
								component={'input'}
								type="checkbox"
								name="locked"
								id="locked"
							/>
							<label style={{color: 'black'}} htmlFor="locked">Lock Membership Code</label>
							<a style={{alignSelf: "center", marginLeft: "3px"}} href="#donothing" onClick={(e) => {
								e.preventDefault()
							}}
								 data-tip data-for={`offer_type`}>
								<i className="material-icons v-mid" style={{color: "#0089CE", fontSize: "18px"}}>info</i>
							</a>
							<ReactTooltip id={`offer_type`} className="table-head__tooltip" place='bottom' effect={'float'}>
								<div style={{padding: '10px 0px'}}>
									<p><strong>Locked Membership Code:</strong> When a membership code is locked it means that any
										golfers assigned to it cannot be reassigned to another Membership Type. A locked code is
										denoted with the light blue lock icon. If you have questions, please contact your association.</p>
								</div>
							</ReactTooltip>
						</div>
					</div>

					{this.props.joinRenewEnabled && <div className="row" style={{marginBottom: 0, marginTop: "-6px"}}>
						<div className="col">
							<span className={"red"} style={{fontStyle: "italic"}}>NOTE: This fee is effective immediately in the association's local timezone.</span>
						</div>
					</div>}

					{this.state.errors.length > 0 && <div className="row">
						<div className="col">
							{this.state.errors.map(error => {return (<span className={'validation__message is-error'}>{error}</span>)})}
						</div>
					</div>}

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={this.props.closeModal}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={submitting || pristine || !valid} className="btn fill blue" >Save</button>
						</div>
					</div>
				</form>
            </Fragment>
		);
	}
}

EditMembershipTypeForm = reduxForm({
	form: 'editMembershipTypeForm',
	destroyOnUnmount: true
})(EditMembershipTypeForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditMembershipTypeForm));
