import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { InputField } from "../../inputs/InputField";
import { maxLength, required } from "../../inputs/validations";
import { addAlert } from "../shared/actions";
import { withKeyPress } from "../shared/highOrderComponent/withKeyPress";
import JoinAndRenewServices from "../../services/api/joinAndRenew"

function EditJoinPageNameFormFunction(props) {
  const { handleSubmit, pristine, submitting, invalid } = props;
  const [serverError, setServerError] = useState('');
  const hasServerError = serverError && serverError !== '';

  const onSubmit = (values, dispatch, props) => {
    return JoinAndRenewServices.updateJoinPage(props.join_page.golf_association_id, props.join_page.id, {
      name: values.name
    }).then(() => {
      props.set_name(values.name)
      let page_name_elements = document.getElementsByClassName(`link-name-${props.join_page.id}`);
      for (let item of page_name_elements)
          item.innerHTML = values.name;
      props.updateJoinPageTableData(props.join_page.id, {name: values.name});
          
      props.addAlert({
        type: 'success',
        message: 'Join Page has been successfully updated'
      })
      props.close_modal()
    }).catch((e) => {
      var error = ""
      try {
        let key = Object.keys(e.data.errors)[0];
        error = e.data.errors[key][0];
      } catch {
        error = "An error has occurred"
      }
      setServerError(error);
    })
  }

  const clearServerError = () => {
    setServerError('');
  }
  
  useEffect(() => {
    const initialize = props.initialize
    const name = props.name
    initialize({
      name: name
    })
  }, [props.initialize, props.name])

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col is-full">
          <label htmlFor="name">Name</label>
          <Field
            className={ hasServerError ? 'is-error' : ''}
            component={InputField}
            validate={[required, maxLength([100, "The Name cannot exceed 100 characters"])]}
            type="text"
            name="name"
            id="name"
            onChange={(e) => {
              clearServerError();
            }}
          />
         {hasServerError && <span class="validation__message is-error">{serverError}</span>}
        </div>
      </div>
      <div className="row">
        <div className="col is-1-of-2">
          <button type={'button'} className="btn fill gray" onClick={() => props.close_modal()}>Cancel</button>
        </div>
        <div className="col is-1-of-2">
          <button type={'submit'} disabled={pristine || submitting || invalid} className="btn fill blue">Save</button>
        </div>
      </div>
    </form>
  )
}

const EditJoinPageNameForm = reduxForm({
  form: 'EditJoinPageNameForm',
  destroyOnUnmount: true
})(EditJoinPageNameFormFunction)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAlert }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditJoinPageNameForm))
