import axios from 'axios'
import Cookies from 'universal-cookie';
import {store} from './../../store';
const cookies = new Cookies();

const client = (() => {
	return axios.create({
		baseURL: process.env.REACT_APP_API_URL.replace('/api/v1', '')
	});
})();

const request = function (options, store) {
	options.params = {
		...options.params
	};
	const onSuccess = function (response) {
		console.debug('Request Successful!', response);
		return response.data;
	};

	const onError = function (error) {
		return Promise.reject(error.response || error.message);
	};


	if (cookies.get('token')) {
		options.headers = {
			"Authorization": "Bearer " + cookies.get('token')
		};
	}

	return client(options)
		.then(onSuccess)
		.catch(onError);
};

client.interceptors.request.use(config => {
	if ((/login/.test(config.url))) {
		config.headers = {};
		return config;
	} else {
		return config;
	}
});

client.interceptors.response.use(config => {
	return config;
}, err => {
	if(err && err.response && err.response.data && err.response.data.error && err.response.data.error === "Invalid token") {
		cookies.remove('token', {path: '/'});
		return store.dispatch({
			type: "@@ghin/USER_AUTHENTICATED",
			payload: false
		});
	} else {
		return Promise.reject(err);
	}
});


export default request;
