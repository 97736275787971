import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect, withRouter} from 'react-router-dom';

import PrimaryGuardian from './PrimaryGuardian';
import MinorManagement from "./MinorManagement";

class Account extends Component {
	render() {
		return (
			<Fragment>

				<div className="vertical-tabs__container row">
					<ul className="vertical-tabs col">
						<li><NavLink className="vertical-tab" activeClassName="is-active"
									 to={`${this.props.match.url}/primary`}>Primary</NavLink></li>
						<li><NavLink className="vertical-tab" activeClassName="is-active"
									 to={`${this.props.match.url}/minor-management`}>Guardianship</NavLink></li>

					</ul>
					<div className="vertical-tabs__panel col">
						<Switch>
							<Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/primary`}/>
							<Route path={`${this.props.match.url}/primary`} component={(props) =>
								<PrimaryGuardian
									user_type={this.props.user_type}
									accessLevel={this.props.accessLevel}
									updateGuardian={() => this.props.updateGuardian()}
									guardian={this.props.guardian}
									archived={this.props.archived}
									publicView={this.props.publicView}
								/>}/>
							{<Route path={`${this.props.match.url}/minor-management`} component={(props) =>
								<MinorManagement accessLevel={this.props.accessLevel} guardian={{...this.props.guardian, guardian_id: this.props.guardian.id}} canEditClub={!this.props.publicView} publicView={this.props.publicView}/>}
							/>}

						</Switch>
					</div>
				</div>

			</Fragment>
		);
	}
}


export default withRouter(Account);
