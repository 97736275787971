import React from 'react';
import Async from 'react-select/async';

export const AsyncSelectInput = (field) => (
	<div style={field.type === "hidden" ? {display:'none'} : {}}>

		<Async
			filterOption={() => (true)}
			id="state-select"
			cacheOptions={!field.dontCacheOptions}
			defaultOptions={field.defaultOptions}
			className={`react-select-container async ${field.className} ${field.meta.touched && field.meta.error ? 'is-error' : ''}`}
			isClearable={true}
			classNamePrefix={field.grayPlaceholder === true ? 'react-gray-select' : 'react-select'}
			value={field.input.value}
			onChange={field.input.onChange}
			onFocus={()=>field.input.onFocus()}
			onBlur={()=>field.input.onBlur()}
			placeholder={field.placeholder}
			name={field.input.name}
			style={field.style}
			multi={field.multi}
			maxMenuHeight={field.maxMenuHeight}
			isDisabled={field.disabled}
			loadOptions={field.loadOptions}
			noOptionsMessage={()=>{if (field.noOptionsMessage) return field.noOptionsMessage;}}
			backspaceRemoves={field.backspaceRemoves}
			searchable={field.searchable || false}
		/>
		{!field.hideErrorMessages  && field.meta.touched && (field.meta.error && Array.isArray(field.meta.error) ?
				field.meta.error.map(err=>{
					return (<p className="validation__message is-error">{err}</p>);
				})
				:
				<p className="validation__message is-error">{field.meta.error}</p>
		)}
	</div>
);
