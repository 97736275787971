import React, {Component, Fragment} from 'react';
import Table from './Table';

export default class AssociationList extends Component {

	constructor() {

		super();
	
		this.state = {
		};

	}


	render() {
		return (
            <Fragment>

                <Table refreshList={this.props.refreshList}/>

            </Fragment>
		);
	}
}
