import React, {Fragment, useEffect, useState} from 'react';
import ScoreService from '../../services/api/score';
import GhinTable from '../shared/GhinTable';
import {LineChart, Line, ResponsiveContainer, PieChart, Pie, Cell, YAxis, Legend} from 'recharts';
import { Collapse } from 'react-collapse';
import { getCleanName } from '../utils'
import moment from 'moment'

function isEmptyValue(val)
{
  return !val || val === 0
}

function parse_score_flagged(score)
{
  if(score.flag_type === 'low')
  {
    return(
      <div className={'bubble low_bubble'}>▼ Low</div>    
    );
  }
  else if(score.flag_type === 'high')
  {
    return(
      <div className={'bubble high_bubble'}>▲ High</div>    
    );
  }
}

function calculate_days_ago(d) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const date_today = new Date();
  const date = new Date(d);

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date_today.getFullYear(), date_today.getMonth(), date_today.getDate());
  const utc2 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

  return Math.floor((utc1 - utc2) / MS_PER_DAY);
}

function setReviewStatus(apply, applyAction, denyAction, setLoading) {
  if(apply) {
    setLoading(true)
    applyAction();
  } else {
    denyAction();
  }
}

function reviewStatus(review)
{
  return(
    <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
      <div style={{marginLeft: '1rem'}}>{review.recommendation_applied ? 'Applied' : 'Denied'} on {moment(review.created_at).format('MM/DD/YYYY HH:mm:ss').replace(' ', ' at ')}</div>
    </div>
  )
}

// The input handicap index value must be a string
function displayHandicapIndex(value) {
  return parseFloat(value) < 0 ? '+' + value.split('-')[1] : value
}

function recommendedAdjustmentTitle(review)
{
  if(review.recommendation_applied === null)
  {
    return(
      <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', 'alignItems': 'center'}}>
        <div>
          <span className='recommended_adjustment_symbol-pending material-icons yellow v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>
            warning
          </span>
        </div>
        <h2 className='text_title padding_small'>USGA recommended adjustment: {review.recommended_adjustment}</h2>
      </div>
    );
  }
  else if((review.recommendation_applied === true || review.recommendation_applied === false) && review.recommended_adjustment === 0)
  {
    return(
      <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', 'alignItems': 'center'}}>
        <div>
          <span className='recommended_adjustment_symbol-zero material-icons green v-mid club-icons-legend club-icons-legend-icon'>
              check_circle
          </span>
        </div>
        <h2 className='text_title padding_small'>No adjustment recommended</h2>
      </div>
    );
  }
  else if(review.recommendation_applied === true && review.recommended_adjustment < 0)
  {
    return(
      <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', 'alignItems': 'center'}}>
        <div>
          <span className='recommended_adjustment_symbol-applied material-icons blue v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>
              arrow_downward
          </span>
        </div>
        <h2 className='text_title padding_small'>Handicap Index was adjusted by {review.recommended_adjustment}</h2>
      </div>
    );
  }
  else if(review.recommendation_applied === true && review.recommended_adjustment > 0)
  {
    return(
      <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', 'alignItems': 'center'}}>
        <div>
          <span className='recommended_adjustment_symbol-applied material-icons blue v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>
              arrow_upward
          </span>
        </div>
        <h2 className='text_title padding_small'>Handicap Index was adjusted by {review.recommended_adjustment}</h2>
      </div>
    );
  }
  else if(review.recommendation_applied === false)
  {
    return(
      <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', 'alignItems': 'center'}}>
        <div>
          <span className='recommended_adjustment_symbol-denied material-icons red v-mid club-icons-legend club-icons-legend-icon' style={{marginRight: '1rem'}}>
            do_not_disturb_on
          </span>
        </div>
        <h2 className='text_title padding_small'>Recommended adjustment was denied</h2>
      </div>
    );
  }

  return(<></>);
}

function handicapChange(review, props)
{
  const currentHandicapIndex = displayHandicapIndex(parseFloat(review.current_handicap_index).toFixed(1))
  const adjustedHandicapIndex = displayHandicapIndex(
    parseFloat(review.current_handicap_index + review.recommended_adjustment).toFixed(1)
  )

  if(review.recommendation_applied === null)
  {
    return(
      <div>
        <p className='text_info'>
          Adjust Handicap Index from {currentHandicapIndex} to {adjustedHandicapIndex}
        </p>
        <p className='text_note'>
          To apply a different adjustment, use the <a href={`${props.url}/handicap-management`}>Handicap Management tab</a> 
        </p>
      </div>
    );
  }
  else if(review.recommendation_applied === null && review.recommended_adjustment === 0)
  {
    return(
      <div>
        <p className='text_info'>
          Keep Handicap Index at {currentHandicapIndex}
        </p>
      </div>
    );
  }
  else if(review.recommendation_applied === true && review.recommended_adjustment < 0)
  {
    return(
      <div>
        <p className='text_info'>
          Decreased from {currentHandicapIndex} to {adjustedHandicapIndex}
        </p>
      </div>
    );
  }
  else if(review.recommendation_applied === true && review.recommended_adjustment > 0)
  {
    return(
      <div>
        <p className='text_info'>
          Increased from {currentHandicapIndex} to {adjustedHandicapIndex}
        </p>
      </div>
    );
  }
  else if(review.recommendation_applied === false)
  {
    return(<></>);
  }

  return(<></>);
}

function details_1(review, handicapHistory)
{
  const data = handicapHistory.map(value => {
      return({
        name: '',
        hi: value === 999 ? null : value
      })
    }
  ).reverse();

  const CustomizedDot = (props) => {
    const { cx, cy, value, index} = props;

    if(value === null || (index !== 0 && index !== data.length - 1)) return <></>;

    return (
      <svg
      x={cx - 50}
      y={cy - 50}
      width={100}
      height={100}
    >
      <circle r='4' cx='50' cy='50' fill="#0086C9" />
      <foreignObject x={index === 0 ? '30' : '40'} y='10' width='200' height='200'>
        <div style={{'fontSize': '14px', 'color': '#535353'}}>{index === 0 ? 'Start' : 'End'}</div>
        <div style={{'fontSize': '18px', 'fontWeight': 'bold'}}>{displayHandicapIndex(value.toFixed(1))}</div>
      </foreignObject>
    </svg>
    );
  };

  return (
    <div className="handicap_review_details" style={{flexGrow: '1', justifyContent: 'space-between', alignItems: 'center', 'paddingBottom': '2rem'}}>
      <div style={{'fontSize': '18px', 'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
        H.I. During Review
      </div>
      <div style={{'width': '266px', 'height': '266px'}}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={200}
          height={200}
          data={data}
          margin={{
            top: 50,
            right: 50,
            left: 50,
            bottom: 50,
          }}
        >
          <YAxis domain={['dataMin - 7', 'dataMax + 7']} hide={true} />
          <Line
            type="monotone"
            dataKey="hi"
            stroke="#0086C9"
            strokeWidth={2}
            dot={<CustomizedDot />}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
      </div>
      <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-around'}}>
          <div style={{'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
            <div style={{'fontSize': '14px', 'color': '#535353'}}>Soft caps</div>
            <div style={{'fontSize': '18px', 'fontWeight': 'bold'}}>{review.soft_cap_count}</div>
          </div>
          <div style={{'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
            <div style={{'fontSize': '14px', 'color': '#535353'}}>Hard caps</div>
            <div style={{'fontSize': '18px', 'fontWeight': 'bold'}}>{review.hard_cap_count}</div>
          </div>
          <div style={{'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
            <div style={{'fontSize': '14px', 'color': '#535353'}}>ESRs</div>
            <div style={{'fontSize': '18px', 'fontWeight': 'bold'}}>{review.esr_count}</div>
          </div>
      </div>
    </div>
  );
}

function details_2(review)
{
  const COLORS = ['#EAECF0', '#00365F']

  const data = [
    { name: 'Not Played To H.I.', value: 100 - review.played_to_handicap_percent },
    { name: 'Played To H.I.', value: review.played_to_handicap_percent }
  ];

  return(
    <div className="handicap_review_details" style={{flexGrow: '1', justifyContent: 'space-between', alignItems: 'center', 'paddingBottom': '2rem'}}>
      <div style={{'fontSize': '18px', 'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
        Played to Handicap Index
      </div>
        <div style={{'width': '266px', 'height': '266px'}}>
          <ResponsiveContainer width="100%" height="100%">
          {!isEmptyValue(review.played_to_handicap_percent) &&
            <PieChart width={200} height={200}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                // label={renderCustomizedLabel}
                outerRadius={80}
                fill="#EAECF0"
                dataKey="value"
                isAnimationActive={false}
                startAngle={90}
                endAngle={450}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend verticalAlign='top' align='center' layout='vertical'/>
            </PieChart>
          }
          </ResponsiveContainer>
        </div>
      <div style={{'fontSize': '18px', 'color': '#535353', 'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
        <span className='bullet_low'/> {isEmptyValue(review.played_to_handicap_percent) ? '-' : (review.number_of_scores * review.played_to_handicap_percent / 100.0).toFixed(0)} rounds ({isEmptyValue(review.played_to_handicap_percent) ? '-' : review.played_to_handicap_percent.toFixed(1)}%)
      </div>
      <div style={{'fontSize': '14px', 'color': '#535353', 'fontStyle': 'italic', 'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
        Most Recent: {isEmptyValue(review.played_to_handicap_percent) ? '-' : (review.last_play_to_handicap_count + " rounds / " + calculate_days_ago(review.last_play_to_handicap_date) + " days ago")}
      </div>
    </div>
  );
}

function details_3(review)
{
  const COLORS = ['#EAECF0', '#0089D1', '#00365F']

  const data = [
    { name: 'Not flagged', value: review.number_of_scores - (review.number_of_flags_low + review.number_of_flags_high) },
    { name: 'High', value: review.number_of_flags_high },
    { name: 'Low', value: review.number_of_flags_low },
  ];

  const legend = ['Not flagged', 'Low', 'High'].map(name => {
    const index = data.findIndex(item => item.name === name)
    return {
      id: data[index].name,
      type: 'rect',
      value: data[index].name,
      color: COLORS[index],
    }
  })

  return(
    <div className="handicap_review_details" style={{flexGrow: '1', justifyContent: 'space-between', alignItems: 'center', 'paddingBottom': '2rem'}}>
      <div style={{'fontSize': '18px', 'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
        Flagged Scores
      </div>
      <div style={{'width': '266px', 'height': '266px'}}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#EAECF0"
              dataKey="value"
              isAnimationActive={false}
              startAngle={90}
              endAngle={450}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend wrapperStyle={{left: '81.5px'}} verticalAlign='top' align='center' layout='vertical' payload={legend}/>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div style={{'fontSize': '18px', 'color': '#535353', 'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
        <span className='bullet_low'/> {review.number_of_flags_low} low flags ({((review.number_of_flags_low / review.number_of_scores) * 100).toFixed(1)}%)
      </div>
      <div style={{'fontSize': '18px', 'color': '#535353', 'textAlign': 'center', 'paddingTop': '10px', 'paddingDown': '10px'}}>
        <span className='bullet_high'/> {review.number_of_flags_high} high flags ({((review.number_of_flags_high / review.number_of_scores) * 100).toFixed(1)}%)
      </div>
    </div>
  );
}

function additional_review_details(review)
{
  return(
    <div className='' style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
      <div className='text_title'>Additional review details</div>
      <div className='header'>
        <div className='' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flex: '1'}}>
          <div className='header_cell'>General Rounds</div>
          <div className='sub_header_layout'>
            <div className='sub_header_cell'>Total</div>
            <div className='sub_header_cell'>Avg. Diff.</div>
          </div>
          <div className='simple_cells_layout'>
            <div className='simple_cell'>{isEmptyValue(review.general_play_count) ? '-' : review.general_play_count.toFixed(0)}</div>
            <div className='simple_cell'>{isEmptyValue(review.general_play_mean) ? '-' : review.general_play_mean.toFixed(1)}</div>
          </div>
        </div>
        <div className='' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flex: '1'}}>
          <div className='header_cell'>Competition Rounds</div>
          <div className='sub_header_layout'>
            <div className='sub_header_cell'>Total</div>
            <div className='sub_header_cell'>Avg. Diff.</div>
            {/* <div className='sub_header_cell'>Improvement Factor</div>  */}{/* PHASE II */}
          </div>
          <div className='simple_cells_layout'>
            <div className='simple_cell'>{isEmptyValue(review.competition_play_count) ? '-' : review.competition_play_count.toFixed(0)}</div>
            <div className='simple_cell'>{isEmptyValue(review.competition_play_mean) ? '-' : review.competition_play_mean.toFixed(1)}</div>
            {/* <div className='simple_cell'>TODO</div> */}{/* PHASE II */}
          </div>
        </div>
        <div className='' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flex: '1'}}>
          <div className='header_cell'>Handicap Index</div>
          <div className='sub_header_layout'>
            <div className='sub_header_cell'>Min</div>
            <div className='sub_header_cell'>Max</div>
          </div>
          <div className='simple_cells_layout'>
            <div className='simple_cell'>{isEmptyValue(review.min_handicap_index) ? '-' : displayHandicapIndex(review.min_handicap_index.toFixed(1))}</div>
            <div className='simple_cell'>{isEmptyValue(review.max_handicap_index) ? '-' : displayHandicapIndex(review.max_handicap_index.toFixed(1))}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function golfer_name(golfer)
{
  return golfer.first_name + ' ' + 
         (golfer.middle_name !== null ? golfer.middle_name : '') + ' ' +
         golfer.last_name
}

function golfer_details(golfer, review)
{
  return(
    <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', 'paddingBottom': '1.5rem', 'paddingLeft': '12px', 'paddingRight': '12px'}}>
      <div className='' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flex: '5', 'gap': '1rem'}}>
        <div className='' style={{'fontSize': '32px'}}>{golfer_name(golfer)}</div>
        <div className='' style={{'fontSize': '16px'}}>GHIN Number: {golfer.id}</div>
      </div>
      <div className='' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flex: '3', 'gap': '0.5rem'}}>
        <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{'fontWeight': 'bold'}}>Review Run</div>
          <div>{moment(review.created_at).format('MM/DD/YYYY HH:mm:ss')}</div>
        </div>
        <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{'fontWeight': 'bold'}}>Handicap Index®</div>
          <div>{golfer.handicap_index}</div>
        </div>
        <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{'fontWeight': 'bold'}}>Low H.I.™</div>
          <div>{golfer.low_hi}</div>
        </div>
      </div>
    </div>
  );
}

export default function HandicapReviewDetailsModal(props) {
  const columnsReviewScores = [
    {
      Header: 'Type',
      accessor: 'score_type_display_full',
      className: 'table-align-center',
      thClassName: 'table-align-center is-1-of-5',
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'Date Played',
      accessor: 'played_at',
      className: 'table-align-center',
      thClassName: 'table-align-center is-2-of-5',
      highlightColumn: true,
      highlightConditionKey: 'flag_type',
      Cell: props => moment(props.original.played_at).format('MM/DD/YYYY')
    },
    {
      Header: 'Score',
      accessor: 'adjusted_gross_score',
      Cell: props => 
      <Fragment>
      {props.original.adjusted_gross_score} {props.original.scaled_up_differential !== null && props.original.number_of_holes !== 9 && <a className='score-number_of_played_holes'>{" ("}{props.original.number_of_played_holes}{")"}</a>}
      </Fragment>,
      className: 'table-align-center',
      thClassName: 'table-align-center is-1-of-4',
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'C.R./Slope',
      accessor: 'course_and_slope_rating',
      Cell: props => <Fragment>{parseFloat(props.original.course_rating).toFixed(1)} / {props.original.slope_rating}</Fragment>,
      className: 'table-align-center',
      thClassName: 'table-align-center is-1-of-2',
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'Diff.',
      Cell: props => props.original.scaled_up_differential === null ? parseFloat(props.original.differential).toFixed(1) : parseFloat(props.original.scaled_up_differential).toFixed(1),
      accessor: 'differential',
      className: 'table-align-center',
      thClassName: 'table-align-center is-2-of-6',
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'Course Name / Tee',
      accessor: 'name',
      thClassName: 'table-align-center',
      Cell: props => <Fragment>{getCleanName(props.original)}</Fragment>,
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
    {
      Header: 'Date Posted',
      accessor: 'created_at',
      className: 'table-align-center',
      thClassName: 'table-align-center is-3-of-5',
      highlightColumn: true,
      highlightConditionKey: 'flag_type',
      Cell: props => moment(props.original.created_at).format('MM/DD/YYYY HH:mm:ss')
    },
    {
      Header: 'Flagged',
      accessor: 'flag_type',
      className: 'table-align-center',
      thClassName: 'table-align-center is-2-of-5',
      Cell: props => <div style={{'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'width': '100%'}} className='bubble'> 
        {parse_score_flagged(props.original)}
      </div>,
      highlightColumn: true,
      highlightConditionKey: 'flag_type'
    },
  ];

  const [reviewScores, setReviewScores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingScores, setLoadingScores] = useState(true)
  const [review, setReview] = useState(props.review);
  const [golfer, setGolfer] = useState(props.golfer);
  const [handicapHistory, setHandicapHistory] = useState(props.review.handicap_history);
  const [scoresOpen, setScoresOpen] = useState(false)
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [pages, setPages] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    setLoadingScores(true)
    let isMounted = true;

    let params = [];
    params['page'] = page + 1;
    params['per_page'] = perPage;

    ScoreService.getHandicapRevisionScores(review.id, params).then(res => {
      if (isMounted) {
        setReviewScores(res.scores)
        setTotal(parseInt(res["total-count"], 10));
        setPerPage(parseInt(res["per-page"], 10));
        setPages(parseInt(res["total-pages"], 10));
        setLoadingScores(false)
      }
    }).catch(err => {
      console.error(err)
    });
  }, [page, perPage]);

  const approvalMenu = (applyAction, denyAction, setLoading) =>
  {
    return (
      props.canEditClub && (
        <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <button className='btn fill green' style={{margin: '0.1rem 0.5rem'}} onClick={() => setReviewStatus(true, applyAction, denyAction, setLoading)}>Apply</button>
          <button className='btn deny_button' style={{margin: '0.1rem'}} onClick={() => setReviewStatus(false, applyAction, denyAction, setLoading)}>Deny</button>
        </div>
      )
    )
  }
  
  return (
    <Fragment>
      <div className="modal__container extra-wide">
        <div className="modal__head">
          <div className="" style={{width: '100%'}}>
            <div className='handicap_review_modal_title'>
              <div className='handicap_review_modal_title_item'>Handicap Review Details</div>
              <div className='handicap_review_modal_title'>
                <button className="modal__close" onClick={() => props.closeModal()}><i className="material-icons-outlined">clear</i></button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__body">
          {loading ?
            <table style={{width: '100%'}}>
              <tbody>
              <tr>
                <td style={{textAlign: 'center'}}>
                  <span className="data-table__loader"></span>
                </td>
              </tr>
              </tbody>
            </table> : (
            <div className='' style={{display: 'flex', flexDirection: 'column', alignContent: 'space-around'}}>
              {golfer_details(golfer, review)}
              <div className='' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <div className="handicap_review_details" style={{flexGrow: '2'}}>
                  <div className='' style={{padding: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start'}}>
                      <div>{recommendedAdjustmentTitle(review)}</div>
                      <div style={{paddingLeft: '3.3rem'}}>{handicapChange(review, props)}</div>
                    </div>
                    <div>
                      { 
                        review.recommendation_applied === null ?
                        approvalMenu(
                          () => props.applyHandicapReview(),
                          () => props.denyHandicapReview(),
                          setLoading,
                        )
                        :
                        <div style={{paddingTop: `${review.recommendation_applied ? '1rem' : '0.5rem'}`}}>{reviewStatus(review)}</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>
                <div className='text_title' style={{'fontSize': '21px', 'fontWeight': 'bold'}}>{review.number_of_scores} Scores in Review Period</div>
                <div className='text_subtitle' style={{'fontSize': '16px', 'color': '#535353'}}>{moment(review.review_start_date).format('MM/DD/YYYY')} - {moment(review.review_end_date).format('MM/DD/YYYY')}</div>
              </div>
              <div className='chart_cards' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                {details_1(review, handicapHistory)}
                {details_2(review)}
                {details_3(review)}
              </div>
              <div className="handicap_review_details">
                {additional_review_details(review)}
              </div>
              <div className="handicap_review_details">
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
                  <div className='text_title'>Scores in Review</div>
                  <label style={{cursor: "pointer"}} onClick={() => setScoresOpen(!scoresOpen)}>
                    <span className='material-icons-outlined collapse-arrow' style={{ top: '6px'}}>
                      {scoresOpen ? 'expand_less' : 'expand_more'}
                    </span>
                  </label>
                </div>
                <Collapse isOpened={scoresOpen}>
                  <GhinTable
                    loading={loadingScores}
                    columns={columnsReviewScores}
                    hideSelect
                    data={reviewScores}
                    pages={pages}
                    page={page}
                    total={total}
                    pageSize={perPage}
                    manual
                    sortable={false}
                    onPageChange={(index) => setPage(index)}
                    onPageSizeChange={(size, index) => {
                      setPerPage(size);
                      setPage(index);
                    }}
                  />
                </Collapse>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

