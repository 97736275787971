import {Fragment, useEffect, useState} from "react";
import GhinTable from "../shared/GhinTable";
import TransactionFeesServices from "../../services/api/transactionFees";
import moment from "moment/moment";

export default function TransactionFeeEditLogModal(props) {
  const [archivedFees, setArchivedFees] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      setLoading(true);
      const association_id = props.selectedTransaction.golf_association_id;
      const fee_id = props.selectedTransaction.id;

      TransactionFeesServices.getArchivedTransactions(association_id, fee_id)
        .then((res) => {
          if (cancel) return;

          const current_transaction = {
            created_at: props.selectedTransaction.updated_at,
            description: props.selectedTransaction.description,
            amount: props.selectedTransaction.amount,
            is_default: props.selectedTransaction.is_default,
          }

          const allFees = [{
            ...current_transaction,
            changedColumns: {}
          }, ...res.transaction_fees.map(fee => ({...fee, changedColumns: {}}))];

          for (let i = allFees.length - 2; i >= 0; i--) {
            const current = allFees[i];
            const next = allFees[i + 1];

            if (current.description !== next.description)
              current.changedColumns.description = true;
            if (current.amount !== next.amount)
              current.changedColumns.amount = true;
            if (current.is_default !== next.is_default)
              current.changedColumns.is_default = true;
          }

          setArchivedFees(allFees);
          setLoading(false);
        })
        .catch(error => {
          if (cancel) return;
          console.error("Error:", error);
          setLoading(false);
        });
    }

    fetchData();

    return () => {
      cancel = true;
    };
  }, [props.selectedTransaction.id, props.selectedTransaction.golf_association_id]);

  const columns = [{
    Header: "Date",
    accessor: "created_at",
    colClassName: 'col_date_edited',
    Cell: (data) => {
      return <span>{moment(data.value).format('MM/DD/YYYY HH:mm:ss')}</span>;
    },
  }, {
    Header: "Transaction Fee Name",
    accessor: "description",
    Cell: (data) => {
      const className = data?.original?.changedColumns['description'] ? "changed-column" : "";
      return <span className={className}>{data.value}</span>;
    },
  }, {
    Header: "Transaction Fee",
    accessor: "amount",
    colClassName: 'col_method-small',
    Cell: (data) => {
      const className = data?.original?.changedColumns['amount'] ? "changed-column" : "";
      return <span className={className}>${data.value}</span>;
    },
  }, {
    Header: "Default Fee?",
    accessor: "is_default",
    colClassName: 'col_method-small',
    Cell: (data) => {
      const className = data?.original?.changedColumns['is_default'] ? "changed-column" : "";
      return <span className={className}>{data.value ? "Yes" : "No"}</span>;
    },
  }];

  return (
    <Fragment>
      <div className="modal__container extra-wide">
        <div className="modal__head">
          <div className="modal__icon">
            <i className="material-icons-outlined">edit</i>
          </div>
          <h2 className="modal__title">
            {props.selectedTransaction.description} Edit Log
          </h2>
          <button
            className="modal__close"
            onClick={() => props.closeModal()}
          >
            <i className="material-icons-outlined">clear</i>
          </button>
        </div>
        <div className="modal__body">
          <div className="row">
            <div className="col">
              <h1>
                The table below indicates any historical changes that have been made to this transaction fee. The
                bottom-most fee is the initial version. The top row is the most recent change. The data indicated with
                red text indicates what values were changed at a given time.
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col is-full">
              <GhinTable
                idKey={"id"}
                columns={columns}
                hideSelect={true}
                hidePagination={true}
                sortable={false}
                data={archivedFees}
                loading={loading}
              />
            </div>
          </div>
          <div className="row" style={{marginTop: "0"}}>
            <div className="col is-1-of-2" style={{marginRight: "0", marginLeft: "auto", width: "200px"}}>
              <button
                type={"button"}
                className="btn fill gray"
                onClick={() => props.closeModal()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
