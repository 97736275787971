import React, {Component, Fragment} from 'react';

import AddAssociationForm from '../Forms/AddAssociationForm';

export default class AddAssociationModal extends Component {

	render() {
		return (
            <Fragment>
                <div className="modal__container narrow">
                    <div className="modal__head">
                        <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
                        <h2 className="modal__title">Add Association</h2>
												<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>
                    </div>
                    <div className="modal__body">
                        <AddAssociationForm survey={this.props.survey} closeModal={() => {this.props.closeModal()}} />
                    </div>
                </div>
            </Fragment>
		);
	}
}
