import React, {Component, Fragment} from 'react';

import ManualCourseForm from '../Forms/ManualCourseForm';

export default class ManualCourseModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container very-narrow">

                    <div className="modal__head">

                        <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
                        <h2 className="modal__title">Manual {this.props.course ? 'Rating' : 'Course'} Entry</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <ManualCourseForm  clear={this.props.clear} nrHoles={this.props.nrHoles} setManualValues={this.props.setManualValues} course={this.props.course} close={(data)=>this.props.closeModal(data)}/>

                    </div>

                </div>

            </Fragment>
		);
	}
}
