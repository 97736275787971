import { Fragment } from "react"
import EditJoinAndRenewEnabledForm from "../Forms/EditJoinAndRenewEnabledForm"

export default function EditJoinAndRenewEnabledModal(props) {
  return (
    <Fragment>
      <div className="modal__container narrow">
        <div className="modal__head">
          {
            props.enable_join_and_renew ?
              <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
              :
              <div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
          }
          <h2 className="modal__title">Enable Join & Renew</h2>
          <button className="modal__close" onClick={() => { props.closeModal() }}><i className="material-icons-outlined">clear</i></button>
        </div>
        <div className="modal__body">
          <EditJoinAndRenewEnabledForm
            {...props}
          />
        </div>
      </div>
    </Fragment>
  )
}