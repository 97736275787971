import React from 'react';

export const Textarea = ({ input, placeholder, disabled, hideErrorMessages, className, label, type, meta: { touched, error, warning } }) => (
	<div>
		<textarea disabled={disabled || false}
			   autoComplete="off"
			   id={input.id}
			   placeholder={placeholder}
			   className={className + (!disabled && touched && error ? ' is-error' : '')}
			   {...input}
			   type={type}/>
		{!input.hideErrorMessages && !hideErrorMessages && !disabled && touched && error && <span className={'validation__message is-error'}>{error}</span>}
	</div>
);
