import React, {Component, Fragment} from 'react';

import AddAssocGroupForm from '../Forms/AddAssocGroupForm';

export default class AddAssocGroupModal extends Component {

	render() {
		return (
            <Fragment>

                <div className="modal__container narrow">

                    <div className="modal__head">

                        <div className="modal__icon" onClick={() => this.props.closeModal()}><i className="material-icons-outlined">add_circle_outline</i></div>
                        <h2 className="modal__title">Add Association Group</h2>
                        <button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

                    </div>

                    <div className="modal__body">

                        <AddAssocGroupForm selectGroup={this.props.selectGroup} closeModal={this.props.closeModal}/>

                    </div>

                </div>

            </Fragment>
		);
	}
}
