import React, {Component, Fragment} from 'react';
import AssociationService from "../../services/api/association";
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ClubService from "../../services/api/club";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class AddClubMembershipTypeForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			membershipTypes: [],
			membershipLoading: true,
			isAllSelected: false,
			error: null
		}
		this.getMembershipTypes = this.getMembershipTypes.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.getMembershipTypes()
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	getMembershipTypes(){
		this.setState({membershipLoading: true});
		AssociationService.getMembershipTypes(this.props.association.id)
			.then(res => {
				let mt = res.membership_types.sort((a, b) => a['code'].toString().localeCompare(b['code'].toString())).filter(membershipType => membershipType.active !== "false");
				if (mt.length > 0) {
					mt.map((membershipType => {
						this.props.membershipTypes.forEach(propsMembershipType => {
							if(propsMembershipType.id === membershipType.id) {
								membershipType.isChecked = true;
								membershipType.is_used = propsMembershipType.is_used;
								membershipType.isUsedInClub = true;
							}
						});
						return membershipType;
					}))
				}
				this.setState({membershipLoading: false, membershipTypes: mt});
			})
			.catch(err => {
				this.setState({membershipLoading: false, membershipTypes: []});
			})
	}

	onSubmit(values,dispatch, props) {
		let toSave=[], toDelete=[];

		if (this.state.isAllSelected) {
			toSave = this.state.membershipTypes.map(t => {return t.id;})
		} else {
			this.state.membershipTypes.forEach(t => {
				if(t.isChecked) {
					toSave.push(t.id);
				} else {
					toDelete.push(t.id)
				}
			});
		}

			if(toDelete.length > 0 ) {
				return ClubService.removeMembershipType({club_ids: [this.props.clubId], membership_ids: toDelete})
					.then(res=>{
						ClubService.addMembershipType({membership_ids:toSave, club_ids: [this.props.clubId]})
							.then(res => {
								this.props.addAlert({
									type:'success',
									message:'Membership type successfully added'
								});
								this.props.closeModal('refresh')
							})
							.catch(err => {
								console.error(err)
							})
					}).catch(err => {
						console.error(err);
					})
			} else {
				return ClubService.addMembershipType({membership_ids:toSave, club_ids: [this.props.clubId]})
					.then(res => {
						this.props.addAlert({
							type:'success',
							message:'Membership type successfully added'
						});
						this.props.closeModal('refresh')
					})
					.catch(err => {
						console.error(err)
					})
			}



	}

	render() {
		const {handleSubmit, submitting} = this.props;
		return (
			<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
            <Fragment>

                <div className="row">
                    <div className="col is-full">
                        <h2 className="form-section__title">{this.props.club.club_name} ({this.props.club.city}, {this.props.club.state})</h2>
                        <p className="form-section__description">Please select the Membership Type(s) to add to the club.</p>
                    </div>
                </div>

				<div className="row">
					<div className="col">
						<table className="data__table normal-overflow">
							<thead>
							<tr>
								<th className={'primary'}>
									<Field
										onClick={
											() => {
												let membershipTypes = this.state.membershipTypes.map(t => {
													t.isChecked = !this.state.isAllSelected;
													return t;
												});
												this.setState({membershipTypes,isAllSelected: !this.state.isAllSelected});
											}
										}
										checked={this.state.isAllSelected}
										id={'isAllSelected'}
										value={true}
										name={'isAllSelected'}
										component={'input'}
										type="checkbox"
									/>
									<label htmlFor={'isAllSelected'}></label>
								</th>
								<th>Membership Code - Membership Description</th>
								<th>Bill Rate</th>
							</tr>
							</thead>
							<tbody>
							{this.state.membershipTypes.length ? this.state.membershipTypes.map((type, key) => {
								return (
									<tr key={key}>
										<td className={'primary'}>
											<Field
												onClick={
													() => {
														let membershipTypes = this.state.membershipTypes.map(t => {
															if(t.id === type.id){
																type.isChecked = !type.isChecked;
																return type
															} else {
																return t;
															}
														});
														this.setState({membershipTypes, isAllSelected: false});
													}
												}
												checked={type.isChecked || this.state.isAllSelected}
												id={type.id + '.is_checked'}
												value={true}
												disabled={type.is_used && type.isUsedInClub}
												name={type.id + '.is_checked'}
												component={'input'}
												type="checkbox"
											/>
											<label htmlFor={type.id + '.is_checked'}></label>
										</td>
										<td>
											<div>
												{type.code + ' - ' + type.description}
												{type.locked && <i style={{marginBottom: "-4px"}} className="material-icons-outlined lock-icon-container"/>}
											</div>
										</td>
										<td>{'$'+ parseFloat(type.bill_rate).toFixed(2)}</td>
									</tr>
								)})
								:
								<tr>
									<td colSpan={3}>
										{this.state.membershipLoading ? <span className="data-table__loader"></span> : <h1>No data available.</h1>}
									</td>
								</tr>
							}
							</tbody>
						</table>
					</div>
				</div>

				<div className="row">
					<div className="col is-1-of-2">
						<button type={'button'} className="btn fill gray" onClick={()=>{this.props.closeModal()}}>Cancel</button>
					</div>
					<div className="col is-1-of-2">
						<button className="btn fill blue"
								disabled={submitting || this.state.membershipTypes.length === 0}
								type={'submit'} ref={'submit'}>Save</button>
					</div>
				</div>

            </Fragment>
			</form>
		);
	}
}

AddClubMembershipTypeForm = reduxForm({
	form: 'addClubMembershipTypeForm',
	destroyOnUnmount: true
})(AddClubMembershipTypeForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddClubMembershipTypeForm));
