import { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import HtmlEditor from "../../../../shared/HtmlEditor";
import CreateProfileImage from "../../../../../img/create_profile.png"
import SentryLogo from "../../../../../img/sentry_01.png"
import GenericModal from "../../../../Modals/GenericModal"
import previewContent from "./previewContent"

function AssociationPageCustomization(props) {
  const history = useHistory();
  const [image, set_image] = useState()
  const [previewOpen, setPreviewOpen] = useState(false);
  const imageRef = useRef()

  useEffect(() => {
    const props_image = props.data.header_image
    if (props_image) {
      if (typeof props_image === typeof {}) {
        set_image(URL.createObjectURL(props_image))
      }
      else {
        set_image(props_image)
      }
    }
  }, [props.data.header_image])

  const handleClick = () => {
    imageRef.current.click();
  };

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    props.set_data({
      ...props.data,
      header_image: fileObj
    })
  };

  const submit = () => {
    props.push_join_page_changes().then(() => {
      history.push("./add-ons");
    }).catch(() => {

    })
  }

  return (
    <Fragment>
      <div className="hr-margin-24-top hr-margin-24-bottom">
        <div className="hr-margin-24-bottom">
          <strong className="pd-l-13px">
            Association Page Customization
          </strong>
        </div>
        <p className="pd-l-13px">Add a header image, custom join page text, set club search preferences, and include the Create your Profile widget below. This section can be skipped and updated at a later date.</p>
      </div>
      <div className="border_box">
        <div className="hr-margin-24-top hr-margin-24-bottom row centered-items">
          <div className="col is-1-of-4">
            <button
              className="btn fill"
              onClick={() => props.push_join_page_changes().then(() => {
                setPreviewOpen(true);
              }).catch(() => {

              })
              }
            >
              CLICK HERE TO PREVIEW
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <strong>
              <span className="underline">Header Image</span>
            </strong>
            <p>
              The header image will appear centered at the top of this join page. You are able to add a custom image for each join page you create. Otherwise, the logo found in the association account section will be used as the default.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              Required Format = PNG, JPG
              <br />
              Maximum Height is 150 px.
            </p>
          </div>
        </div>
        <div className="row hr-margin-24-bottom">
          <div className="col">
            <p>
              Any images uploaded with an image height greater than 100 px will be resized proportionately.
            </p>
          </div>
        </div>

        <>
          {
            image ?
              <>
                <div className="row centered-items padding-10-y">
                  <div className="col auto form__image-preview">
                    <img
                      alt=""
                      src={image}
                    />
                  </div>
                  <div className="col auto" style={{paddingTop: "12px"}}>
                    <input
                      type="file"
                      accept={"image/png, image/jpg, image/jpeg"}
                      className={"hidden"}
                      ref={imageRef}
                      onChange={handleFileChange}
                    />
                    <button
                      onClick={handleClick}
                      className={"btn lnk"}
                      style={{width: "fit-content", marginBottom: "8px"}}
                    >
                      Edit
                    </button>
                    <input
                      id="hide_logo"
                      type="checkbox"
                      checked={props.data.hide_logo}
                      onChange={() => {
                        props.set_data({
                          ...props.data,
                          hide_logo: !props.data.hide_logo
                        })
                      }}
                    />
                    <label htmlFor="hide_logo">Hide Logo from Association Join Page</label>
                    <span className="bold header-image-note">Note: The Logo will still display on Club Join Pages.</span>
                  </div>
                </div>
              </>
              :
              <>
                <div className="row centered-items">
                  <div className="col auto">
                    <input
                      type="file"
                      accept={"image/png, image/jpg, image/jpeg"}
                      className={"hidden"}
                      ref={imageRef}
                      onChange={handleFileChange}
                    />
                    <button
                      onClick={handleClick}
                      className={"btn lnk"}
                    >Add Header Image
                    </button>
                  </div>
                </div>
              </>
          }
        </>

        <hr />

        <div className="row hr-margin-24-top">
          <div className="col">
            <strong>
              <span className="underline">Header Text</span>
            </strong>
            <p>
              The Join Page Header will be displayed under the header image.
            </p>
          </div>
        </div>
        <div className="row hr-margin-24-bottom">
          <div className="col is-full">
            <HtmlEditor
              html={props.data.header_text}
              onChange={(e) => props.set_data({
                ...props.data,
                header_text: e.target.value
              })}
            />
          </div>
        </div>

        <hr />

        <div className="row hr-margin-24-top">
          <div className="col">
            <strong>
              <span className="underline">Page Description</span>
            </strong>
            <p>
              The Page Description will be displayed under the page header.
            </p>
          </div>
        </div>
        <div className="row hr-margin-24-bottom">
          <div className="col is-full">
            <HtmlEditor
              html={props.data.description}
              onChange={(e) => props.set_data({
                ...props.data,
                description: e.target.value
              })}
            />
          </div>
        </div>

        <hr />

        <div className="row hr-margin-24-top">
          <div className="col">
            <strong>
              <span className="underline">Club Search</span>
            </strong>
            <p>
              How would you like potential members to be able to search for a club using this join page?
            </p>
          </div>
        </div>
        <div className="row hr-margin-24-bottom">
          <div className="col">
            <div className="radio__container middle hr-margin-24-bottom">
              <input
                onChange={() => props.set_data({
                  ...props.data,
                  club_name_search: true,
                  zip_code_search: true
                })}
                checked={props.data.club_name_search && props.data.zip_code_search}
                id="name_or_zip"
                component={'input'}
                className="simple__radio"
                type="radio"
              />
              <label htmlFor="name_or_zip">Club Name or Zip Code</label>
              <input
                onChange={() => props.set_data({
                  ...props.data,
                  club_name_search: true,
                  zip_code_search: false
                })}
                checked={props.data.club_name_search && !props.data.zip_code_search}
                id="name"
                component={'input'}
                className="simple__radio"
                type="radio"
              />
              <label htmlFor="name">Club Name Only</label>
              <input
                onChange={() => props.set_data({
                  ...props.data,
                  club_name_search: false,
                  zip_code_search: true
                })}
                checked={!props.data.club_name_search && props.data.zip_code_search}
                id="zip"
                component={'input'}
                className="simple__radio"
                type="radio"
              />
              <label htmlFor="zip">Zip Code Only</label>
            </div>
          </div>
        </div>
      </div>
      <div className="border_box hr-margin-26-bottom hr-margin-24-top">
        <div className="row hr-margin-24-top">
          <div className="col">
            <strong>
              Include "Create your Profile"
            </strong>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              Add a quick way for a golfer to create their profile once they have completed registration.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              This information will be exposed to the golfer once they have successfully submitted their registration form.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              This information will also be added to the email they recieve once registration is complete.
            </p>
          </div>
        </div>
        <div className="row centered-items">
          <div className="col">
            <div className="center-join-pages-logos">
              <input
                id="digital_profile"
                label="test"
                type="checkbox"
                checked={props.data.digital_profile}
                onChange={(e) => props.set_data({
                  ...props.data,
                  digital_profile: !props.data.digital_profile
                })}
              />
              <label htmlFor={"digital_profile"}>Include "Create your Profile"</label>
            </div>
          </div>
        </div>
        <div className="row centered-items">
          <div className="col is-1-of-2">
            <div className="border_box hr-margin-24-bottom">
              <div className="hr-margin-24-top">
                <img
                  src={CreateProfileImage}
                  alt=""
                  className={"fit_image_webkit"}
                />
              </div>
              <div className="center-join-pages-logos hr-margin-24-bottom">
                <div>
                  <span
                    role="link"
                    tabIndex={0}
                    className="usga_logo border-focus"
                    aria-label={"USGA, Golf Handicap Information Network"}
                  >
                    golf handicap <br /> information network
                  </span>
                </div>
                <div>
                  <img
                    src={SentryLogo}
                    alt=""
                    className="join-pages-sentry-logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row hr-margin-24-top">
        <div className="col is-1-of-4 push-right">
          <div className="row">
            <div className="col">
              <button type={'button'} className="btn fill gray" onClick={() => history.push('select-membership')}>Go Back</button>
            </div>
            <div className="col">
              <button type={'submit'} className="btn fill blue" onClick={submit}>Next/Save Changes</button>
            </div>
          </div>
        </div>
      </div>
      
      <GenericModal
        isOpen={previewOpen}
        className="semi-extra-narrow"
        icon={"edit"}
        child={previewContent}
        title={"Preview Join Page "}
        close_modal={() => { setPreviewOpen(false) }}
        url={`${props.data.url}/preview`}
      />
    </Fragment>
  )
}

export default AssociationPageCustomization;
