import React, {Component, Fragment} from 'react';
import {FederationAccount} from "../../services/api/federationAccount";
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {InputField} from "../../inputs/InputField";
import {isEmailAddress, required, alphaNumeric, maxLength, isUrl} from "../../inputs/validations";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";


class EditFederationAccountForm extends Component {

	componentDidMount() {
		this.props.initialize(this.props.profile);
	}

	onSubmit(values, dispatch, props) {
		let data = JSON.parse(JSON.stringify(values));
		delete data.id;
		delete data.logo_url;
		FederationAccount.update({federation: data})
			.then(res => {
				props.addAlert({
					type:'success',
					message:'Federation account has been updated successfully'
				});
				props.close(res.federation);
			})
			.catch(err => {
				console.error(err)
			});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="name">Name <span>*</span></label>
							<Field component={InputField} validate={[required]} type="text" name="name" id="name" />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="short-name">Short Name</label>
							<Field component={InputField} validate={[alphaNumeric, maxLength([8])]} type="text" name="short_name" id="short_name" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="email">Email <span>*</span></label>
							<Field component={InputField} validate={[required, isEmailAddress]} type="text" name="email" id="email" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<label htmlFor="phone">Phone <span>*</span></label>
							<Field component={InputField} validate={[required]} type="text" name="phone" id="phone" />
						</div>
						<div className="col is-1-of-2">
							<label htmlFor="website">Website <span>*</span></label>
							<Field component={InputField} validate={[required, isUrl]} type="text" name="website" id="website" />
						</div>
					</div>

					<div className="row">
						<div className="col is-1-of-2">
							<button type={'button'} className="btn fill gray" onClick={()=>this.props.close(this.props.profile)}>Cancel</button>
						</div>
						<div className="col is-1-of-2">
							<button type={'submit'} ref={'submit'}  disabled={pristine || submitting} className="btn fill blue">Save</button>
						</div>
					</div>

				</form>
			</Fragment>
		);
	}
}

EditFederationAccountForm = reduxForm({
	form: 'editFederationAccountForm',
	destroyOnUnmount: true
})(EditFederationAccountForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditFederationAccountForm));
