import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {maxLength} from "../../inputs/validations";
import {Textarea} from "../../inputs/Textarea";

class EditEmailSubscriptionForm extends Component {


	constructor(props){
		super(props);
		this.state = {
			countries: [],
			states: [],
			addressAsPrimary: false,
			contactAsPrimary: false
		}
	}

	componentDidMount() {
		var note = this.props.subscription.note !== '-' ? this.props.subscription.note : ''
		this.props.initialize({subscribed: this.props.subscription.subscribed === "Yes", note: note});
		this.setState({subscribed: this.props.subscription.subscribed === "Yes", note: note, count: note.length});
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {
		return props.onSubmit(values);
	}

	render() {
		const {handleSubmit, pristine, submitting, invalid} = this.props;
		const noteMaxLength = 150;

		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
                <div className="row">
					<div className="col is-1-of-2">
						<span className="label">Subscribed?</span>
						<div className="radio__container">

							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {subscribed: !prevState.subscribed};
										});
									}
								}
								checked={this.state.subscribed}

								id="dac-yes"
								value={true}
								name={'subscribed'}
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
							<label htmlFor="dac-yes">Yes</label>

							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {subscribed: !prevState.subscribed};
										});
									}
								}
								checked={!this.state.subscribed}
								id="dac-no"
								value={false}
								name={'subscribed'}
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
							<label htmlFor="dac-no">No</label>

						</div>
					</div>
					</div>
					<div className="row">
							<div className="col is-1-of-2">
								<label htmlFor="note">Note: </label>
							</div>
							<div className="col is-2-of-2" style={{textAlign:"right"}}>
								<label  className={(this.state.count > noteMaxLength ? 'length-error' : 'blue')} htmlFor="note">{this.state.count}  / {noteMaxLength}</label>
							</div>
							</div>
							<div className="f-row">
								<Field component={Textarea} className={'large'} validate={[maxLength([150, 'Value too long (max 150 characters)'])]} name="note" id="note" type="text" onChange={(e) => this.setState({ count: e.target.value.length })}/>
						</div>
                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={() => this.props.close(null)}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
						<button disabled={pristine || submitting || invalid} type={'submit'} ref={'submit'} className="btn fill blue">Save</button>
                    </div>
                </div>

				</form>
			</Fragment>
		);
	}
}


EditEmailSubscriptionForm = reduxForm({
	form: 'editEmailSubscriptionForm',
	destroyOnUnmount: true
})(EditEmailSubscriptionForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditEmailSubscriptionForm));
