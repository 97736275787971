import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk'
import {createBrowserHistory} from 'history';
import {persistCombineReducers, persistStore} from "redux-persist";
import {routerMiddleware} from 'connected-react-router'
import {createStore, applyMiddleware} from 'redux';
import rootReducer from "./rootReducer";
import ReactGA from 'react-ga4';
import Cookies from "universal-cookie";
import AuthService from "./services/api/auth";
import {clearSelectedMembership} from "./screens/Golfer/Account/ClubMembership/actions";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

// const loggerMiddleWare = store => next => action => {
// 	console.log("[LOG] Action triggered", action);
// 	next(action);
// };

export const history = createBrowserHistory({basename: process.env.REACT_APP_BASENAME})
const gaTrackingMiddleware = store => next => action => {
	if (action.type === '@@router/LOCATION_CHANGE') {
		const nextPage = `${action.payload.location.pathname}`;
		if(store.getState().router.location.pathname.indexOf('/club-memberships') !== -1 && action.payload.location.pathname.indexOf('/club-memberships') === -1) {
			store.dispatch(clearSelectedMembership());
		}
		trackPage(nextPage);
	}
	return next(action);
};

const trackPage = (page) => {
	ReactGA.ga('send', 'pageview', page);
};

const config = {
	key: 'ghinadmin',
	version: 1,
	storage,
	migrate: (state) => {
		if(state && state._persist && state._persist.version !== 1) {
			const cookies = new Cookies();
			AuthService.logout({user_token: cookies.get('token')});
			cookies.remove('token', {path: '/'});
			cookies.remove('gotAlert', { path: '/' });
			cookies.remove('closedAlert', { path: '/' });
      cookies.remove('gotAssociationAlert', { path: '/' });
			return Promise.resolve(initialState)
		} else {
			return Promise.resolve(state)
		}
	},
	blacklist: ['form', 'router']
};

const initialState = {
	auth: {
		isAuthenticated: false
	},
	alerts: [],
	user: {},
	terms: {},
	productAlerts: {}
};

const reducer = persistCombineReducers(config, rootReducer(history));

function configureStore() {

	let store = createStore(
		reducer,
		initialState,
		applyMiddleware(thunk, routerMiddleware(history), gaTrackingMiddleware, /*loggerMiddleWare*/)
	);
	let persistor = persistStore(store);
	return {persistor, store};
}

export const {persistor, store} = configureStore();
