import React, {Component} from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import {checkKeyForDateInput} from "../../inputs/helper.js"

export default class OptionDatePicker extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      start_date: "",
      end_date: "",
      value: ""
    }
  }

  onSubmit = () => {
    this.props.updateLabel(this.state.value);
    this.props.updateDates(this.state.start_date, this.state.end_date);
    this.props.close()
  }

  changeDates = (value) => {
    let {start_date, end_date} = this.getValues(value);
    this.setState({
      start_date: start_date,
      end_date: end_date,
      value: value
    })
  }
  
  getValues = (value) => {
    let start_date = ""
    let end_date = ""
    switch(value) {
      case "Today":
        start_date = moment().format("MM/DD/YYYY");
        end_date = moment().format("MM/DD/YYYY");
        break;
      case "All time":
        start_date = "";
        end_date = "";
        break;
      case "This week":
        start_date = moment().startOf('week').format("MM/DD/YYYY");
        end_date = moment().endOf('week').format("MM/DD/YYYY");
        break;
      case "Last week":
        start_date = moment().subtract(1, 'weeks').startOf('week').format("MM/DD/YYYY");
        end_date = moment().subtract(1, 'weeks').endOf('week').format("MM/DD/YYYY");
        break;
      case "This month":
        start_date = moment().startOf('month').format("MM/DD/YYYY");
        end_date = moment().endOf('month').format("MM/DD/YYYY");
        break;
      case "Last month":
        start_date = moment().subtract(1, 'months').startOf('month').format("MM/DD/YYYY");
        end_date = moment().subtract(1, 'months').endOf('month').format("MM/DD/YYYY");
        break;
      case "This quarter":
        start_date = moment().startOf('quarter').format("MM/DD/YYYY");
        end_date = moment().endOf('quarter').format("MM/DD/YYYY");
        break;
      case "Last quarter":
        start_date = moment().subtract(1, 'quarters').startOf('quarter').format("MM/DD/YYYY");
        end_date = moment().subtract(1, 'quarters').endOf('quarter').format("MM/DD/YYYY");
        break;
      case "This half":
        if (moment().quarter() <= 2) {
          start_date = moment().startOf('year').format("MM/DD/YYYY");
          end_date = moment(start_date).add(1, 'quarters').endOf('quarter').format("MM/DD/YYYY");
        }
        else {
          end_date = moment().endOf('year').format("MM/DD/YYYY");
          start_date = moment(end_date).subtract(1, 'quarters').startOf('quarter').format("MM/DD/YYYY");
        }
        break;
      case "Last half":
        if (moment().quarter() <= 2) {
          end_date = moment().subtract(1, 'years').endOf('year').format("MM/DD/YYYY");
          start_date = moment(end_date).subtract(1, 'quarters').startOf('quarter').format("MM/DD/YYYY");
        }
        else {
          start_date = moment().startOf('year').format("MM/DD/YYYY");
          end_date = moment(start_date).add(1, 'quarters').endOf('quarter').format("MM/DD/YYYY");
        }
        break; 
      default:
        break;
    }
    return {start_date: start_date, end_date: end_date}
  }
  
  selectedRange = (value) => {
    let {start_date, end_date} = this.getValues(value);
    
    return this.state.start_date === start_date &&  this.state.end_date === end_date ? "active" : ""
  }

  render() {

  return (
    <div className="option-picker">
      <label className="first-title">Relative dates</label>
      <div className="group">
        <div className="btn-group">
          <button onClick={() => this.changeDates("Today")} className={this.selectedRange("Today")}>Today</button>
          <button onClick={() => this.changeDates("All time")} className={this.selectedRange("All time")}>All time</button>
        </div>

        <div className="btn-group">
          <button onClick={() => this.changeDates("This week")} className={this.selectedRange("This week")}>This week</button>
          <button onClick={() => this.changeDates("Last week")} className={this.selectedRange("Last week")}>Last week</button>
        </div>

        <div className="btn-group">
          <button onClick={() => this.changeDates("This month")} className={this.selectedRange("This month")}>This month</button>
          <button onClick={() => this.changeDates("Last month")} className={this.selectedRange("Last month")}>Last month</button>
        </div>

        <div className="btn-group">
          <button onClick={() => this.changeDates("This quarter")} className={this.selectedRange("This quarter")}>This quarter</button>
          <button onClick={() => this.changeDates("Last quarter")} className={this.selectedRange("Last quarter")}>Last quarter</button>
        </div>

        <div className="btn-group">
          <button onClick={() => this.changeDates("This half")} className={this.selectedRange("This half")}>This half</button>
          <button onClick={() => this.changeDates("Last half")} className={this.selectedRange("Last half")}>Last half</button>
        </div>
      </div>

      <div>
        <label className="title">Fixed dates</label>
        <div className="select-ranges-container row">
          <div className="rm-pd col is-4-of-9">
            <DatePicker
              selected={this.state.start_date ? new Date(this.state.start_date) : null}
              maxDate={new Date()}
              className={`date`}
              dropdownMode="select"
              dateFormat={'MM/dd/yyyy'}
              showMonthDropdown
              showYearDropdown
              onKeyDown={e => {checkKeyForDateInput(e)}}
              onChange={(value) => {
                if (value) {
                  let start_date = moment(value).format("MM/DD/YYYY");
                  if (this.state.end_date && start_date > this.state.end_date){
                    start_date = this.state.end_date;
                  }
                  if (!this.state.end_date || start_date <= this.state.end_date)
                    this.setState({
                      start_date: start_date,
                      value: `${start_date} - ${this.state.end_date}`
                    })
                } else {
                  this.setState({
                    start_date: null
                  })
                }
              }}
            />
          </div>
          <div className="line rm-pd col is-1-of-9">
            <span>-</span></div>
          
          <div className="rm-pd col is-4-of-9">
            <DatePicker
              selected={this.state.end_date ? new Date(this.state.end_date) : null}
              maxDate={new Date()}
              className={`date`}
              dropdownMode="select"
              dateFormat={'MM/dd/yyyy'}
              showMonthDropdown
              showYearDropdown
              onKeyDown={e => {checkKeyForDateInput(e)}}
              onChange={(value) => {
                if (value) {
                  let end_date = moment(value).format("MM/DD/YYYY");
                  if (this.state.start_date && this.state.start_date > end_date){
                    end_date = this.state.start_date;
                  }
                  
                  if (!this.state.start_date || this.state.start_date <= end_date)
                    this.setState({
                      end_date: end_date,
                      value: `${this.state.start_date} - ${end_date}`
                    })
                } else {
                  this.setState({
                    end_date: null
                  })
                }
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <button className='btn fill blue apply-btn col is-2-of-8'
                type="submit"
                onClick={() => this.onSubmit()}>Apply</button>
      </div>
    </div>
  );

  }

}
