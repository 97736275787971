import React, {Component, Fragment} from 'react';
import GhinFilterSelect from "../../../shared/GhinFilterSelect";
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";
import TableService from "../../../../services/tableService";
import technologyProviders from "../../../shared/technologyProvider.json"



export default class TableFilter extends Component {

	constructor() {

		super();

		this.state = {
			filters: {}
		};

	}

	updateFilter = (name, value) => {
		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		}, () => {
			this.updateTable();
		})
	};

	updateTable = debounce(() => {
		let filteredArray = TableService.filtersToArray(this.state.filters);
		this.props.updateFiltered(filteredArray);
	}, 200);

	reset() {
		this.setState({filters: {name: '', club_name: null, postal_code: null, is_test: null, is_dac: null, technology_provider: null}}, () => {
			this.updateTable();
		});
	}

	render() {
		return (
			<Fragment>

				<div className="filters">

					<div className="row">

						<div className="filters__inputs col">

							<div className="row">

								<div className={'col'}>

									<div className="row">
										<div className="col is-1-of-6">
											<label htmlFor="association-name">Club Name</label>
											<input type="text" autoComplete="off" value={this.state.filters.club_name || ''} name="association-name" id="association-name" onChange={(e) => {
												this.updateFilter('club_name', e.target.value)
											}}/>
										</div>

										<div className={"col is-1-of-6"}>
											<GhinFilterSelect
												options={['Type 1', 'Type 2', 'Type 3']}
												value={this.state.filters.club_type}
												onChange={values => this.updateFilter('club_type', values)}
												label={'Club Type'}
											/>
										</div>


										<div className="col is-1-of-6">

											<GhinFilterSelect
												options={['Active', 'Inactive', 'Archived']}
												value={this.state.filters.status}
												onChange={values => this.updateFilter('status', values)}
												label={'Club Status'}
											/>

										</div>

										<div className="col is-1-of-6">

											<GhinFilterSelect
												options={['Affiliate', 'Military', 'Private', 'Public', 'Resort', 'Semi-Private', 'Municipal', 'WORE', 'Not Specified']}
												value={this.state.filters.club_category}
												onChange={values => this.updateFilter('club_category', values)}
												label={'Club Category'}
											/>

										</div>

										<div className="col is-1-of-6">
											<label htmlFor="postal_code">Postal Code</label>
											<input type="text" autoComplete="off" value={this.state.filters.postal_code || ''} name="postal_code" id="postal_code" onChange={(e) => {
												this.updateFilter('postal_code', e.target.value)
											}}/>
										</div>

										<div className="col is-1-of-6">
											<GhinFilterSelect
												options={this.props.membershipTypes}
												value={this.state.filters.membership_code}
												onChange={values => this.updateFilter('membership_code', values)}
												label={'Membership Code (MC)'}
											/>
										</div>
									</div>


									<div className="row">

										<div className="col is-1-of-5">
											<GhinFilterSelect
												singleChoice={true}
												options={[{value: true, label: 'DAC only'}, {value: false, label: 'Non-DAC'}]}
												value={this.state.filters.is_dac}
												onChange={values => this.updateFilter('is_dac', values)}
												label={'Handicap Oversight'}
											/>
										</div>

										<div className="col is-1-of-5">
											<GhinFilterSelect
												singleChoice={true}
												options={technologyProviders}
												value={this.state.filters.technology_provider}
												onChange={values => this.updateFilter('technology_provider', values)}
												label={'Front End Provider'}
											/>
										</div>
									</div>

								</div>

							</div>

						</div>

						<div className="filters__controls col is-1-of-7 jc-fe">
							<button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
						</div>

					</div>

				</div>

			</Fragment>
		);
	}
}
