import React, {Component} from 'react';
import {Field} from "redux-form";
import {InputField} from "../../inputs/InputField";
import {required} from "../../inputs/validations";
import GolferService from "../../services/api/golfer";
import debounce from "debounce-promise";
import {SelectInput} from "../../inputs/Select";
import {relationshipTypes} from "../../services/shared/guardianRelationshipTypes";

class GhinGuardianFormComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasGhinNumber: !props.hide_search,
			isGHINValid: undefined,
			isMinor: false,
			isJunior: false,
			assignGuardian: false
		};
		this.searchForGhinNumber = debounce(this.searchForGhinNumber.bind(this), 1000);
	}

	clearInput () {
		['email', 'first_name', 'last_name', 'middle_name', 'prefix', 'suffix', 'phone_number']
			.forEach((key) => {
				this.props.change(`guardian.${key}`, undefined)
				if (this.props.untouch ) {
					this.props.untouch(`guardian.${key}`)

				}
			});
	}

	searchForGhinNumber(value) {
		if (!value) {
			return;
		}
		GolferService.findGolferByGhinOrEmail(value, null)
			.then(res => {
				const golfer = res.golfer;
				['email', 'first_name', 'last_name', 'middle_name', 'prefix', 'suffix', 'phone_number']
					.forEach((key) => {
						this.props.change(`guardian.${key}`, golfer[key])
					});
				this.setState({
					isGHINValid: true
				})
			})
			.catch(err => {
				this.setState({
					isGHINValid: false
				})
			});
	}

	componentDidMount() {
		const isMinor = !!this.props.minor;
		const isJunior = !!this.props.junior && !isMinor;
		this.setState(({
			isJunior,
			isMinor,
			assignGuardian: !isJunior,
		}))
		this.props.change("assignGuardian", !isJunior);
	}

	componentDidUpdate(prevProps) {
		const isMinor = !!this.props.minor;
		const isJunior = !!this.props.junior && !isMinor;

		if (this.state.isMinor !== isMinor || this.state.isJunior !== isJunior) {
			this.setState(({
				isJunior,
				isMinor,
				assignGuardian: !isJunior,
			}))
			this.props.change("assignGuardian", !isJunior);
		}
		this.props.change("assignGuardian", this.state.assignGuardian);

	}



	render() {
		const {isMinor, isJunior} = this.state;
		const showRelationshipTextInput = this.props.guardian && this.props.guardian.relationship && this.props.guardian.relationship.value === 'Other';
		return (
			<>
				{
					!this.props.hide_search ? <div className="form__separator padding-16-y"></div> : null
				}
				<div className={'row'}>
					<div className="col is-full">
						{isMinor ?
							<span><strong>Note:</strong> The birthdate provided indicates this golfer is a minor. A guardian is required for golfers less than 13 years of age. The guardian must be at least 18 years of age.</span> : null}
						{isJunior ?
							<span><strong>Note:</strong> The birthdate provided indicates this golfer is a junior (> 13 but {"<"} 19 years of age). A guardian is not required, but can be added for Junior golfers. The guardian must be at least 18 years of age.</span> : null}
					</div>
				</div>

				{
					isJunior ? <div className="row">
						<div className="col is-full">
							<span className="label">Would you like to assign a guardian? <span>*</span></span>
							<div className="radio__container">
								<Field
									onClick={
										() => {
											this.setState((prevState) => {
												return {assignGuardian: !prevState.assignGuardian};
											});

										}
									}
									checked={this.state.assignGuardian}
									id="assignGuardian_yes"
									value={true}
									name={'golfer.assignGuardian'}
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="assignGuardian_yes">Yes</label>
								<Field
									onClick={
										() => {
											this.setState((prevState) => {
												return {assignGuardian: !prevState.assignGuardian};
											});

										}
									}
									checked={!this.state.assignGuardian}
									id="assignGuardian_no"
									value={false}
									name={'golfer.assignGuardian'}
									component={'input'}
									className="simple__radio"
									type="radio"
								/>
								<label htmlFor="assignGuardian_no">No</label>
							</div>
						</div>
					</div> : null
				}

				{
					this.state.assignGuardian ? (<>
						{
							!this.props.hide_search ? <div className="row">
								<div className="col is-full">
								<span
									className="label">Does the guardian have a GHIN Number? <span>*</span></span>
									<div className="radio__container">
										<Field
											onClick={
												() => {
													this.setState((prevState) => {
														return {hasGhinNumber: !prevState.hasGhinNumber};
													});
													this.clearInput();
												}
											}
											checked={this.state.hasGhinNumber}
											id="hasGhinNumber_yes"
											value={true}
											name={'golfer.hasGhinNumber'}
											component={'input'}
											className="simple__radio"
											type="radio"
										/>
										<label htmlFor="hasGhinNumber_yes">Yes</label>
										<Field
											onClick={
												() => {
													this.setState((prevState) => {
														return {hasGhinNumber: !prevState.hasGhinNumber};
													});
													this.clearInput();
												}
											}
											checked={!this.state.hasGhinNumber}
											id="hasGhinNumber_no"
											value={false}
											name={'golfer.hasGhinNumber'}
											component={'input'}
											className="simple__radio"
											type="radio"
										/>
										<label htmlFor="hasGhinNumber_no">No</label>
									</div>
								</div>
							</div> : null
						}
						{
							this.state.hasGhinNumber ? (
								<div className="row">
									<div className="col is-1-of-2">
										<label htmlFor="guardian.golfer_id">Guardian GHIN
											Number <span>*</span></label>
										<Field component={InputField}
											   type="text"
											   onChange={(event) => this.searchForGhinNumber(event.target.value)}
											   name="guardian.golfer_id"
											   id="guardian.golfer_id"/>
										{this.state.isGHINValid === false ? (
											<span className={'validation__message is-error'}>Golfer Not Found</span>) : null}
									</div>
								</div>
							) : null
						}
						<div className="row">
							<div className="col is-1-of-5">
								<label htmlFor="guardian.prefix">Prefix</label>
								<Field component={InputField}
									   type="text"
									   name="guardian.prefix"
									   id="guardian.prefix"/>
							</div>
							<div className="col is-2-of-5">
								<label htmlFor="guardian.first_name">Guardian First Name <span>*</span></label>
								<Field component={InputField}
									   validate={[required]}
									   type="text"
									   name="guardian.first_name"
									   id="guardian.first_name"/>
							</div>
							<div className="col is-2-of-5">
								<label htmlFor="guardian.middle_name">Guardian Middle Name</label>
								<Field component={InputField}
									   type="text"
									   name="guardian.middle_name"
									   id="guardian.middle_name"/>
							</div>
						</div>
						<div className={'row'}>
							<div className="col is-2-of-3">
								<label htmlFor="guardian.first_name">Guardian Last Name <span>*</span></label>
								<Field component={InputField}
									   validate={[required]}
									   type="text"
									   name="guardian.last_name"
									   id="guardian.last_name"/>
							</div>
							<div className="col is-1-of-3">
								<label htmlFor="guardian.suffix">Suffix</label>
								<Field component={InputField}
									   type="text"
									   name="guardian.suffix"
									   id="guardian.suffix"/>
							</div>
						</div>
						<div className={'row'}>
							<div className="col is-1-of-2">
								<label htmlFor="guardian.email">Guardian Email <span>*</span></label>
								<Field component={InputField}
									   validate={[required]}
									   type="text"
									   name="guardian.email"
									   id="guardian.email"/>
							</div>
							<div className="col is-2-of-2">
								<label htmlFor="guardian.phone_number">Guardian Phone Number </label>
								<Field component={InputField}
									   type="text"
									   name="guardian.phone_number"
									   id="guardian.phone_number"/>
							</div>
						</div>
						{
							!this.props.hide_relationship ? <div className={'row'}>
								<div className="col is-1-of-2">
									<label htmlFor="guardian.relationship">Relationship to Minor <span>*</span></label>

									<Field name={'guardian.relationship'}
										   id={'guardian.relationship'}
										   validate={[required]}
										   component={SelectInput}
										   className="react-select-container"
										   classNamePrefix="react-select"
										   options={relationshipTypes}
									/>
								</div>
								{
									showRelationshipTextInput ? (<div className="col is-2-of-2">
										<label htmlFor="guardian.other_relationship">What is the relationship ? <span>*</span></label>

										<Field component={InputField}
											   validate={[required]}
											   type="text"
											   name="guardian.other_relationship"
											   id="guardian.other_relationship"/>
									</div>) : null
								}



							</div> : null
						}
						{
							this.props.edit_status && this.props.guardian  ? <div className={'row'}>
								<div className={'col is-1-2'}>
									<Field
										id="status"
										value={true}
										onClick={
											() => {
												this.props.change('guardian.status', this.props.guardian.status === 'Active' ? 'Inactive' : 'Active')
											}
										}
										checked={this.props.guardian.status === 'Active'}
										component={'input'}
										type="checkbox"
									/>
									<label htmlFor="status">Active</label>
								</div>
							</div> : null
						}
					</>) : null
				}

			</>
		)
	}
}

export default GhinGuardianFormComponent;
