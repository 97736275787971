import React, {Component, Fragment} from 'react';

import AddGuardianForm from '../Forms/AddGuardianForm';

export default class AddGuardianModal extends Component {

	render() {
		return (
			<Fragment>

				<div className="modal__container narrow">

					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">add_circle_outline</i></div>
						<h2 className="modal__title">Add Guardian</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">

						<AddGuardianForm golfer={this.props.golfer} closeModal={(data) => {this.props.closeModal(data)}} />

					</div>

				</div>

			</Fragment>
		);
	}
}
