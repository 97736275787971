import { Fragment, useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import JoinAndRenewServices from "../../../../../services/api/joinAndRenew";
import EditAddonChargeModal from "../../../../Modals/EditAddonChargeModal";
import GhinTable from "../../../../shared/GhinTable";

function AddOns(props) {
  const [addons, set_addons] = useState([]);
  const [loading, set_loading] = useState(false);
  const [selected, set_selected] = useState([]);
  const [addAddonModalIsOpen, set_addAddonModalIsOpen] = useState(false);
  const [selectedAddon, set_selectedAddon] = useState({})
  const [operationType, set_operationType] = useState("")

  const history = useHistory()

  const toggle_addon_selected = (data) => {
    if (selected.includes(data.id)) {
      props.set_data({
        ...props.data,
        addon_charges: [...selected].filter(e => e !== data.id)
      });
    }
    else {
      props.set_data({
        ...props.data,
        addon_charges: [...selected, data.id]
      });
    }
  }

  const reloadAddons = useCallback(() => {
    set_loading(true)
    JoinAndRenewServices.getAddonCharges(
      props.associationId, {
      is_enabled: true
    }).then((res) => {
      set_addons(res.addon_charges)
      set_loading(false)
    })
  }, [props.associationId])

  const submit = () => {
    JoinAndRenewServices.JoinPageAssignAddons(props.association.id, props.data.id, selected).then(() => {
      history.push(`./summary`)
    }).catch(() => {
      console.log("An error has occurred")
    })
  }
  useEffect(() => {
    const props_selected = props.data.addon_charges
    if (props_selected) {
      set_selected([...props_selected])
    }
  }, [props.data.addon_charges])

  useEffect(() => {
    reloadAddons()
  }, [props.associationId, reloadAddons])

  const columns = [
    {
      colClassName: "col_holes",
      Header: "Include",
      className: 'table-align-center',
      thClassName: 'table-align-center',
      Cell: data => {
        return <Fragment>
          <input type="checkbox"
            checked={selected.includes(data.original.id)}
            onChange={() => { }}
          />
          <label
            style={{ margin: 'auto', height: '22px' }}
            onClick={(e) => {
              toggle_addon_selected(data.original)
            }
            }
          ></label>
        </Fragment>
      }
    },
    {
      Header: "Add-On Name",
      accessor: "name"
    },
    {
      Header: "Description",
      accessor: "description"
    },
    {
      colClassName: 'col_change_code',
      Header: "Fee",
      accessor: "amount",
      Cell: data => {
        return data.original.is_multiple_options ? 'Multiple Fee Options'
          :
          `$${data.value}`
      }
    },
    {
      Header: "Optional?",
      accessor: "is_optional",
      colClassName: "col_gender",
      Cell: data => {
        return <div>{data.value ? "Yes" : "No"}</div>
      }
    },
    {
      Header: "Donation?",
      accessor: "is_donation",
      colClassName: "col_gender",
      Cell: data => {
        return <div>{data.value ? "Yes" : "No"}</div>
      }
    },
    {
      colClassName: "col_ghin",
      className: 'table-align-center',
      thClassName: 'table-align-center',
      Cell: data => <button className="btn lnk" onClick={() => {
        set_selectedAddon(data.original)
        set_addAddonModalIsOpen(true)
        set_operationType("Edit")
      }}>Edit</button>
    }
  ]

  return (
    <Fragment>
      <div className="hr-margin-24-top hr-margin-24-bottom">
        <div className="hr-margin-24-bottom">
          <strong className="pd-l-13px">
            Add-Ons <strong className="medium">(OPTIONAL)</strong>
          </strong>
        </div>
        <p className="pd-l-13px">Create a new add-on or add an existing add-on to your Join Page. This step can be skipped during page setup, and can be added later. Any selected add-ons will be displayed during checkout.</p>
      </div>
      <div className="row">
        {props.canEditAssociation && <div className="col auto push-right">
          <button className="btn fill green"
            onClick={() => {
              set_addAddonModalIsOpen(true)
              set_operationType("Create")
              set_selectedAddon(null)
            }}
          >Create Add-On</button>
        </div>}
      </div>
      <div className="row">
        <div className="col jc-fs">
          <GhinTable
            loading={loading}
            idKey={'addons'}
            hideSelect
            hidePagination={true}
            data={addons}
            sortable={false}
            columns={columns}
          />
        </div>
      </div>
      <div className="row hr-margin-24-top">
        <div className="col is-1-of-4 push-right">
          <div className="row">
            <div className="col">
              <button type={'button'} className="btn fill gray" onClick={() => history.push('association-page-customization')}>Go Back</button>
            </div>
            <div className="col">
              <button type={'submit'} className="btn fill blue" onClick={submit}>Next/Save Changes</button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={addAddonModalIsOpen}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
      >
        <EditAddonChargeModal
          reload_addons={reloadAddons}
          close_modal={() => { set_addAddonModalIsOpen(false) }}
          select_addon={(data) => {
            toggle_addon_selected(data)
          }}
          operationType={operationType}
          association_id={props.associationId}
          selected_addon={selectedAddon}
        />
      </Modal>
    </Fragment>
  )
}

export default AddOns
