import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {SelectInput} from "../../inputs/Select";
import {requiredMultiSelect, required} from "../../inputs/validations";
import {addAlert} from '../shared/actions';
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {FederationAccount} from "../../services/api/federationAccount";

class AddAssociationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            associations: [],
            facilities: [],
            excluded_facilities: [],
			      errors: [],
            all_facilities: true,
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
    	let data = JSON.parse(JSON.stringify(values));
      data.golf_association_id = data.golf_association_id.value;
      data.all_facilities = data.all_facilities ?  data.all_facilities : "true"
      data.facilities = data.facilities ? data.facilities.map(ga => {return ga.value}) : null;
      data.excluded_facilities = data.excluded_facilities ? data.excluded_facilities.map(ga => {return ga.value}) : null;

      return FederationAccount.createSurveyAssociation(data).then(() => {
				if(this.props.setRefreshList) this.props.setRefreshList(true);
				this.props.addAlert({type: 'success', message: 'Association has been successfully added !'});
				this.props.closeModal();
			}).catch(err => {
				let errors = [];

				Object.keys(err.data.errors).forEach(error => {
					if (err.data.errors[error].length > 1) {
						err.data.errors[error].forEach(errorChild => {
							errors.push(error.replace(/_/g, ' ') + ' ' + errorChild);
						})
					} else {
						errors.push(err.data.errors[error][0]);
					}
				});
				this.setState({errors})
			});
    }

    componentDidMount() {
      FederationAccount.getAssociations()
          .then(response => {
              let associations = response.associations.map(assoc => {
                assoc.value = assoc.id; assoc.label = assoc.name;
                return assoc
              });
      associations.sort((a, b) => a['label'].toString().localeCompare(b['label'].toString()));
      this.setState({
                  associations
              })
          })
		  this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

  getAllFacilities(assoc_id) {
		FederationAccount.getFacilities(assoc_id)
			.then(response => {
				let facilities = response.Facilities;
				facilities.map(ga => {ga.value = ga.FacilityId; ga.label = ga.FacilityName; return ga});
        this.setState({
          facilities: facilities.sort((a, b) => a['label'].toString().localeCompare(b['label'].toString())),
          excluded_facilities: facilities.sort((a, b) => a['label'].toString().localeCompare(b['label'].toString()))
        })
			})
			.catch(err => {
        this.setState({
          facilities: [],
          excluded_facilities: []
        })
			})
	}

	render() {
        const {handleSubmit, submitting} = this.props;

        return (
            <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
            <div className="row">
                <div className="col">
                    <label htmlFor="association">Association <span>*</span></label>
                    <Field name={'golf_association_id'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" onChange={(newValue)=> {this.getAllFacilities(newValue.crs_id)}} options={this.state.associations}/>
                </div>
            </div>
                <div className="row">
                    <div className="col is-full">
                        <span className="label" htmlFor="all_facilities">All Facilities ? <span>*</span></span>
                        <div className="radio__container">
                            <Field
                                id="all_facilities-yes"
                                value={true}
                                onClick={
                                    () => {
                                        this.setState( {all_facilities: true});
                                    }
                                }
                                name={'all_facilities'}
                                component={'input'}
                                checked={this.state.all_facilities}
                                className="simple__radio"
                                type="radio"
                            />
                            <label htmlFor="all_facilities-yes">Yes</label>

                            <Field
                                id="all_facilities-no"
                                value={false}
                                name={'all_facilities'}
                                onClick={
                                    () => {
                                        this.setState( {all_facilities: false});
                                    }
                                }
                                checked={!this.state.all_facilities}
                                className="simple__radio"
                                component={'input'}
                                type="radio"
                            />
                            <label htmlFor="all_facilities-no">No</label>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                  <div className="col">
                  {!this.state.all_facilities ?
                    <div className="row">
                      <div className="col">
                        <label htmlFor="facilities">Facilities <span>*</span></label>
                        <Field
                          validate={[requiredMultiSelect]}
                          name={'facilities'}
                          component={SelectInput}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={this.state.facilities}
                          isSearchable={false}
                          isMulti={true}
                          />
                        </div>
                        </div>
                      :
                      <div className="row">
                        <div className="col">
                          <label htmlFor="excluded_facilities">Exclude </label>
                          <Field
                            name={'excluded_facilities'}
                            component={SelectInput}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={this.state.excluded_facilities}
                            isSearchable={false}
                            isMulti={true}
                            />
                          </div>
                          </div>}
                  </div>
                </div>

        				{this.state.errors.length > 0 &&<div className={"row"}>
        					<div className={"col is-full"}>
        						<p>
        							{this.state.errors.map(error => {
        								return (<span
        									className={'validation__message is-error'}>{error.charAt(0).toUpperCase() + error.substring(1)}</span>);
        							})}
        						</p>
        					</div>
        				</div>}

                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={()=>{this.props.closeModal()}}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
                        <button className="btn fill blue" disabled={submitting} type={'submit'} ref={'submit'}>Save</button>
                    </div>
                </div>
            </form>
		);
	}
}

AddAssociationForm = reduxForm({
    form: 'addAssociationForm',
    destroyOnUnmount: true
})(AddAssociationForm);

function mapStateToProps() {
    return {
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({addAlert}, dispatch);
    return {
        dispatch,
        ...actions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddAssociationForm));
