import { Fragment } from "react";
import EditContactSettingsReasonForm from "../Forms/EditContactSettingsReasonForm";

export default function EditContactSettingsReasonModal(props) {
  return (
    <Fragment>

      <div className="modal__container narrow">

        <div className="modal__head">

          <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
          <h2 className="modal__title">Do Not Contact Reason</h2>
          <button className="modal__close" onClick={() => { props.close_modal() }}><i className="material-icons-outlined">clear</i></button>

        </div>

        <div className="modal__body">
          <EditContactSettingsReasonForm
            {...props}
          />
        </div>

      </div>

    </Fragment>
  )
}