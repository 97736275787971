import React, {Component, Fragment} from 'react';
import GolferRecord from "./GolferRecord";
import GolferService from "../../../services/api/golfer";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";

const generateGolferName = (row) => {
	return row.last_name + (row.suffix ? ' ' + row.suffix + ', ' : ', ') + (row.prefix ?  row.prefix + ' ' : '') + row.first_name + ' ' + (row.middle_name ? row.middle_name : '');
};
export default class UnMerge extends Component {


	constructor(props) {
		super(props);
    let defaultMergedGolfer = "";
    if (this.props.fromAccountCommand) {
      let ghinNumber = this.props.fromAccountCommand.ghinNumber;
      defaultMergedGolfer = ghinNumber.toString();
    }
		this.state = {
			ghinMergedGolfer: defaultMergedGolfer,
			golferOne: null,
			golferTwo: null,
			currentGolfer: null,
			confirmationModalOpen: false,
			unMergeLoading: false,
			responseModalOpen: false,
			id: null,
			idError: false,
			nameError: null
		}
	}
  componentDidMount() {
    if (this.props.fromAccountCommand) {
      let e = new Event("default");
      this.populateGolfers(e);
    }
  }
	setInitialState() {
		this.setState({
			ghinMergedGolfer: '',
			golferOne: null,
			golferTwo: null,
			currentGolfer: null,
			confirmationModalOpen: false,
			unMergeLoading: false,
			responseModalOpen: false,
			id: null,
			idError: false,
			nameError: null
		})
	}

	populateGolfers(e) {
    let ghin = "";

    if (e.type === "default") {
      // Gets here only if redirected from golfer Account "Unmerge" link.
      ghin = this.state.ghinMergedGolfer;
    } else {
      ghin = (e.target.validity.valid) ? e.target.value : this.state.ghinMergedGolfer;
    }
		this.setState({
			ghinMergedGolfer: ghin
		});
		GolferService.getGolferMerges(ghin)
			.then(response => {
				if (!response.errors) {
					let currentGolfer = response.golfer
					let golferOne = response.merged_golfer;
					golferOne.PlayerName = generateGolferName(response.merged_golfer);
					let golferTwo = response.merger_golfer;
					golferTwo.PlayerName = generateGolferName(response.merger_golfer);
					document.getElementById('golferOne').value = response.merged_golfer.id;
					document.getElementById('golferTwo').value = response.merger_golfer.id;
					this.setState({
						currentGolfer,
						golferOne,
						golferTwo,
						idError: false,
						nameError: null,
						id: ghin
					})
				} else {
					let nameError = response.errors[0];
					if (nameError !== "This golfer is Archived and cannot be unmerged.")
						nameError = "GHIN # is not a merged golfer.";
					this.setState({
						idError: true,
						nameError: nameError
					})
				}
			})
			.catch(err => {
				console.error(err);
			})
	}

	unMergeGolfers() {
		this.setState({
			unMergeLoading: true
		}, () => {
			GolferService.unmergeGolfers({
				ghin_number: this.state.id
			}).then(response => {
				document.getElementById('golferOne').value = '';
				document.getElementById('golferTwo').value = '';
				document.getElementById('mergedGolfer').value = '';
        let msg_note = response.show_message;
				this.setState({
					confirmationModalOpen: false,
					responseModalErrorOpen: false,
					responseModalOpen: true,
          note: msg_note
				});
			}).catch(err => {

			})
		})
	}

	render() {
		return (
            <Fragment>

            <div className="col is-full">

                <div className="row">

                    <div className="col is-1-of-3 jc-fs">
                        <div className="panel">
                            <div className="panel__head">Merged Golfer Record</div>
                            <div className="panel__body">
                                <div className="columns">
                                    <div className="row">
                                        <div className="col is-full">
                                            <input id={'mergedGolfer'} value={this.state.ghinMergedGolfer} onChange={e => this.populateGolfers(e)} type="text" pattern="[0-9]*" autoComplete="off" placeholder="GHIN Number" />
											{this.state.idError && <span className={'validation__message is-error'}>{this.state.nameError}</span>}
                                        </div>
                                    </div>
									<GolferRecord golfer={this.state.currentGolfer}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col is-1-of-3 jc-fs">
                        <div className="panel">
                            <div className="panel__head">Golfer 1</div>
                            <div className="panel__body">
                                <div className="columns">
                                    <div className="row">
                                        <div className="col is-full">
                                            <input id={'golferOne'} type="text" autoComplete="off" placeholder="GHIN Number" disabled />
                                        </div>
                                    </div>
									<GolferRecord golfer={this.state.golferOne}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col is-1-of-3 jc-fs">
                        <div className="panel">
                            <div className="panel__head">Golfer 2</div>
                            <div className="panel__body">
                                <div className="columns">
                                    <div className="row">
                                        <div className="col is-full">
                                            <input id={'golferTwo'} type="text" autoComplete="off" placeholder="GHIN Number" disabled />
                                        </div>
                                    </div>
									<GolferRecord golfer={this.state.golferTwo}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

								{ this.state.currentGolfer && this.state.currentGolfer.has_digital_profile &&
											<div className="col auto push-right">
													<span className={'validation__message is-error'}>
															When unmerged, each golfer will need to create a new Digital Profile.
													</span>
													<br/>
											</div>
								}

                <div className="row">
                    <div className="col auto push-right">
                        <button onClick={e => {
													e.preventDefault();
													if (this.state.golferOne && this.state.golferTwo) {
														this.setState({
															confirmationModalOpen: true
														})
													}
												}}
												className="btn fill green">
													Unmerge
												</button>
                    </div>
                </div>

            </div>
				{this.state.confirmationModalOpen &&
				<ConfirmationModalTypeB
					openModal={this.state.confirmationModalOpen}
					onConfirmAction={() => this.unMergeGolfers()}
					onCancelAction={() => {
						this.setState({
							confirmationModalOpen: false
						})
					}}
					closeModal={() => {
						this.setState({
							confirmationModalOpen: false
						})
					}}
					loading={this.state.unMergeLoading}
					bodyQuestion={<Fragment>{this.state.golferOne.PlayerName} - {this.state.golferOne.id}
						<br/> {this.state.golferTwo.PlayerName} - {this.state.golferTwo.id}</Fragment>}
					question={"Are you sure you want to unmerge the following golfers?"}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
          mergeContext={true}
				/>
				}
				{this.state.responseModalOpen &&
				<ConfirmationModalTypeB
					openModal={this.state.responseModalOpen}
					onConfirmAction={() => this.setInitialState()}
					onCancelAction={() => {
						this.setState({
							responseModalOpen: false
						})
					}}
					closeModal={() => {
						this.setState({
							responseModalOpen: false
						})
					}}
					bodyQuestion={`Golfers have been unmerged successfully.`}
					question={"Success!"}
					confirmLabel={"Ok"}
					modalIcon={"check_circle"}
          note={this.state.note}
				/>
				}
        </Fragment>
		);
	}
}
