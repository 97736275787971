import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import SharedService from '../../services/api/shared';
import {bindActionCreators} from "redux";
import {maxLength,required, requiredSelect} from "../../inputs/validations";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import GolferService from "../../services/api/golfer";
import moment from 'moment';
import {DatePickerInput} from "../../inputs/DatePicker";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";

class EditAddressForm extends Component {


	constructor(props){
		super(props);
		this.state = {
			countries: [],
			states: [],
			addressAsPrimary: false,
			contactAsPrimary: false,
			error: null
		}
		
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {

		SharedService.getCountriesAndStates()
			.then((response) => {
				this.setState({
					countries: response.countries.map((item) => {
						return {
							...item,
							value: item.code,
							label: item.name,
							states: item.states.map((state) => {
								return {
									...state,
									value: state.code,
									label: state.name.includes('Armed ') ? state.code : state.name
								}
							})
						}
					})
				}, ()=>{this.addressInitialize();})
			})

		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	onSubmit(values, dispatch, props) {

		let addressData = JSON.parse(JSON.stringify(values.address));
		addressData.active_from = moment(addressData.active_from).format('YYYY-MM-DD');
		addressData.active_to = moment(addressData.active_to).format('YYYY-MM-DD');
		addressData.country = addressData.country.value;
		addressData.state = addressData.state.value;

		delete addressData.state_name;
		delete addressData.country_name;
		if (props.type === "secondary") {delete addressData.all_year_round}
		if (props.type === "primary" && props.golfer.primary_address.all_year_round) { delete addressData.active_from}
		if (props.type === "primary" && props.golfer.primary_address.all_year_round) { delete addressData.active_to}

		return GolferService.updateGolfer(props.golfer.club_id, props.golfer.id, {[props.type + "_address"]: addressData}).then(res=>{
			props.addAlert({
				type:'success',
				message: `Golfer ${props.type} address  has been successfully ${props.actionType}`
			});
			props.close(res);
		})
		.catch(error => {
			console.error(error);
			this.setErrors(error.data.errors)
		})
	}
	
	setErrors(error) {
		let errors = null;
		let keys = Object.keys(error);
		
		keys.forEach((key) => {
			errors = error[key];
		})
		
		this.setState({
			error: errors
		});
	}
	
	renderErrors(){
		let errors = this.state.error;
		var displayError = null;
		
		if (errors){
			if (Array.isArray(errors))
				displayError = errors.map((el, index) => {
					return <span key={index} className={'validation__message is-error'}>{el}</span>;
				})
			else {
				let keys = Object.keys(errors);
				let displayValues = {"street_1": "Address 1", "street_2": "Address 2"};
				displayError = keys.map((key, index) => {
					let errorKey = displayValues[key] ? displayValues[key] : key;
					return <span key={index} className={'validation__message is-error'}>{`${errorKey}: ${errors[key]}`}</span>;
				})
			}
		}
		return displayError;
	}
	
	onCountryChange(country) {
		this.props.change('address.state',{label: 'Select State', value: null});
		this.setState({
			states: country.states
		})
	}

	addressInitialize() {
		let currentState = null;
		let currentCountry = this.state.countries.filter(country => {
			if (this.props.golfer[this.props.type + '_address'].country) {
				if (country.name === this.props.golfer[this.props.type + '_address'].country_name ) {
					currentState = country.states.filter(state =>
					this.props.golfer[this.props.type + '_address'] && this.props.golfer[this.props.type + '_address'].state === state.code )[0];
				}
				return (country.name === this.props.golfer[this.props.type + '_address'].country_name)
			} else {
				return (country.name === "United States")
			}
		})[0];
		this.props.initialize({
			address: {
				...this.props.golfer[this.props.type + '_address'],
				...{
					country: currentCountry,
					state: currentState,
					active_from: this.props.golfer[this.props.type + '_address'].active_from ? new Date(moment(this.props.golfer[this.props.type + '_address'].active_from, 'MM/DD')) : null,
					active_to: this.props.golfer[this.props.type + '_address'].active_from ? new Date(moment(this.props.golfer[this.props.type + '_address'].active_to, 'MM/DD')) : null
				}
			}});
		this.setState({
			states:  currentCountry.states,
		});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return (
			<Fragment>
				<form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} ref={'form'}>
                <div className="row">
					<div className="col is-1-of-3 jc-c">
						<h3>Address Information</h3>
					</div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label  htmlFor={'address.country'}>Country <span>*</span></label>
						<Field name={'address.country'}
							   id={'address.country'}
							   validate={[requiredSelect]}
							   component={SelectInput}
							   onChange={this.onCountryChange.bind(this)}
							   className="react-select-container"
							   classNamePrefix="react-select"
							   options={this.state.countries}/>
					</div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor={'address.street_1'}>Address 1 <span>*</span></label>
						<Field component={InputField} type="text" name={'address.street_1'} id={'address.street_1'} validate={[required, maxLength([46, "Value too long (max 46 characters)"])]}/>
					</div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor={'address.street_2'}>Address 2</label>
						<Field component={InputField}  type="text" name={'address.street_2'} id={'address.street_2'} validate={[maxLength([46, "Value too long (max 46 characters)"])]} />
					</div>
                </div>

                <div className="row">
                    <div className="col is-3-of-7">
                        <label htmlFor={'address.city'}>City <span>*</span></label>
						<Field component={InputField}
							   validate={[required]}
							   type="text"
							   name={'address.city'}
							   id={'address.city'} />

					</div>
                    <div className="col is-2-of-7">
                        <label htmlFor="address.state">State <span>*</span></label>
						<Field name={'address.state'}
							   id={'address.state'}
							   validate={[requiredSelect]}
							   component={SelectInput}
							   className="react-select-container"
							   classNamePrefix="react-select"
							   options={this.state.states}/>
                    </div>
                    <div className="col is-2-of-7">
                        <label htmlFor={'zip'}>Postal Code <span>*</span></label>
						<Field component={InputField} validate={[required]} type="text" name={'address.zip'} id={'address.zip'} />
					</div>
                </div>
					{(this.props.type === "secondary" || (this.props.type === "primary" && !this.props.golfer.primary_address.all_year_round)) &&
					<div className="row">
						<div className="col is-1-of-3">
							<label htmlFor="date_of_birth">Start Date <span>*</span></label>
							<Field className={'date'}
								   showMonthDropdown
								   dateFormatCalendar={"MM/dd"}
								   dateFormat={'MM/dd'}
								   propperPlacement={"top"}
								   name={'address.active_from'}
								   id={'address.active_from'}
								   component={DatePickerInput}
								   validate={[required]}
								   placeholderText="MM/DD"
								   placeholder="Select ..." />
						</div>
						<div className="col is-1-of-3">
							<label htmlFor="date_of_birth">End Date <span>*</span></label>
							<Field className={'date'}
								   showMonthDropdown
								   propperPlacement={"top"}
								   dateFormatCalendar={"MM/dd"}
								   dateFormat={'MM/dd'}
								   name={'address.active_to'}
								   id={'address.active_to'}
								   component={DatePickerInput}
								   validate={[required]}
								   placeholderText="MM/DD"
								   placeholder="Select ..." />
						</div>
					</div>
					}
					{this.renderErrors()}
                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={() => this.props.close(null)}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
						<button disabled={pristine || submitting} type={'submit'} ref={'submit'} className="btn fill blue">Save</button>
                    </div>
                </div>

				</form>
			</Fragment>
		);
	}
}


EditAddressForm = reduxForm({
	form: 'editAddressForm',
	destroyOnUnmount: true
})(EditAddressForm);

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(EditAddressForm));
