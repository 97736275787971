import React, { Component, Fragment } from "react";
import Table from "./Table";
import Modal from "react-modal";
import "react-picky/dist/picky.css";
import AddPushNotificationModal from "../../../Modals/AddPushNotificationModal";

export default class PushNotification extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addNewPushNotificationModalIsOpen: false,
			tableNeedsRefresh: false,
		};

		this.openAddNewPushNotificationModal = this.openAddNewPushNotificationModal.bind(
			this
		);
		this.setTableRefresh = this.setTableRefresh.bind(this);
	}

	closeModal() {
		this.setState({
			addNewPushNotificationModalIsOpen: false,
		});
	}

	openAddNewPushNotificationModal() {
		this.setState({ addNewPushNotificationModalIsOpen: true });
	}

	setTableRefresh(value) {
		this.setState({ tableNeedsRefresh: value });
	}

	render() {
		let panelTitle = "Create & Manage Push Notifications";
		let panelMessage =
			"Push Notifications will appear on a golfer's device if they have enabled Push Notifications on their device and within the GHIN Mobile App. ";

		return (
			<Fragment>
				<div className="row">
					<div className="col">
						<div className="panel">
							<div className="panel__head">{panelTitle}</div>
							<div className="panel__body">
								<div className="row">
									<div className="col is-5-of-5">
										<p>
											<span className="bold">Note:</span>
											{" " + panelMessage}
										</p>
									</div>
									{
										<div className="col is-1-of-5">
											<button
												className="btn fill green"
												onClick={() =>
													this.openAddNewPushNotificationModal()
												}
											>
												Add New Push Notification
											</button>
										</div>
									}
								</div>
								<div className="row">
									<div className="col">
										<Table
											canEditAssociation={
												this.props.canEditAssociation
											}
											associationId={
												this.props.associationId
											}
											user={this.props.user}
											setTableRefresh={(value) =>
												this.setTableRefresh(value)
											}
											tableNeedsRefresh={
												this.state.tableNeedsRefresh
											}
										></Table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal
					isOpen={this.state.addNewPushNotificationModalIsOpen}
					onRequestClose={() => {
						this.closeModal();
					}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<AddPushNotificationModal
						associationId={this.props.associationId}
						user={this.props.user}
						closeModal={() => this.closeModal()}
						setTableRefresh={(value) => this.setTableRefresh(value)}
						forFederation={true}
					/>
				</Modal>
			</Fragment>
		);
	}
}
