import React, {Component, Fragment} from 'react';
import {Field, reduxForm, getFormValues, getFormSyncErrors} from "redux-form";
import moment from "moment";
import {DatePickerInput} from "../../inputs/DatePicker";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {SelectInput} from "../../inputs/Select";
import ClubService from "../../services/api/club";
import {withRouter} from "react-router-dom";
import CourseRatingSystemService from "../../services/api/courseRatingSystem";
import {InputField} from "../../inputs/InputField";
import debounce from "debounce-promise";
import {AsyncSelectInput} from "../../inputs/AsyncSelectInput";
import {requiredSelect, biggerThanPuts} from "../../inputs/validations";
import ScoreService from "../../services/api/score";
import ConfirmationModalTypeB from "../Modals/ConfirmationModalTypeB";
import GolferService from "../../services/api/golfer";
import flatten from 'lodash.flatten';
import {orderTees} from "../../services/shared/teesFilter";
import { ConsoleView } from 'react-device-detect';
import {checkHoles} from "../utils"

class EditHBHScore extends Component{

	constructor(props){
		super(props);
		this.state = {
			scoreType: 'home',
			nrHoles: '18',
			method: 'home-course',
			scoreId: null,
			courses:this.props.score.number_of_holes === 9 && this.props.homeCourses.length > 0 ?
			this.props.homeCourses.filter(hc=> hc.tee_set_side === "F9" || hc.tee_set_side === "B9") : this.props.homeCourses.filter(hc=> hc.tee_set_side === "All18"),
      	home_courses: flatten(this.props.homeCourses).map(el => el.courseId),
      	auto_score_type: false,
			errors: [],
			overrideModal: false,
			agsMax: null,
      	allAgsMax: null,
			teeSetsOptions: this.props.score.number_of_holes === 9 ? this.props.teeSetsOptions.filter(ts=> ts.tee_set_side === "F9" || ts.tee_set_side === "B9") : this.props.teeSetsOptions.filter(ts => ts.tee_set_side === "All18"),
			golfer: this.props.golfer,
			selectedCourse: null,
			loading: false,
			confirmIncompleteScoreModal: false,
			modalReviewQuestion: '',
			modalReviewBodyQuestion: '',
			editData: null,
		};
		this.getCoursesOptions = this.getCoursesOptions.bind(this);
	}

	onSubmit(values) {
		let holes = [];

		for (let i = 1; i < 19; i++) {
			let hole = values.holes['hole' + i];

			if (hole === undefined) {
				if(this.props.golfer.use_scaling && this.state.nrHoles === "18") {
					holes.push({hole_number: i, x_hole: true, raw_score: 0});
				}
				else {
					holes.push({});
				}
			}
			else if (this.customParseInt(hole) === 'X') {
				if (this.props.golfer.use_scaling) {
					this.setState({errors: ['X is not valid score']})
					return
				}
				holes.push({hole_number: i, x_hole: true, raw_score: 10});
			}
			else if (hole[0] === 'X') {
				holes.push({hole_number: i, most_likely_score: this.customParseInt(hole), raw_score: this.customParseInt(hole)});
			}
			else if (hole === '' && this.props.golfer.use_scaling) {
				holes.push({hole_number: i, x_hole: true, raw_score: 0});
			}
			else {
				holes.push({hole_number: i, raw_score: parseInt(hole)});
			}
		}

		let data = {};
		let scoreType = this.state.scoreType === 'home' ? 'H' : (this.state.scoreType === 'away' ? 'A' : 'T');

		if (this.state.method === 'home-course') {
			if (this.state.nrHoles === "9") {
				if (values.course_name.tee_set_side === "F9") {
					holes = holes.slice(0,9);
				} else {
					holes = holes.slice(9,18);
				}
			}

			data = {
				golfer_id: this.state.golfer.id,
				course_id: values.course_name.courseId,
				tee_set_id: values.course_name.teeSetID,
				tee_set_side: parseInt(this.state.nrHoles) === 9 ? values.course_name.tee_set_side : 'All18',
				played_at: moment(values.date).format("YYYY-MM-DD"),
				score_type: scoreType,
				hole_details: holes,
				override_confirmation: false,
				is_manual: false,
				number_of_holes: this.state.nrHoles,
				gender: this.state.golfer.gender
			};
		} else {
			if (this.state.nrHoles === "9") {
				if (values.tee_set_id.tee_set_side === "F9") {
					holes = holes.slice(0,9);
				} else {
					holes = holes.slice(9,18);
				}
			}
			data = {
				golfer_id: this.state.golfer.id,
				course_id: values.course_name.value,
				tee_set_id: values.tee_set_id.value,
				tee_set_side: parseInt(this.state.nrHoles) === 9 ? values.tee_set_id.tee_set_side : 'All18',
				played_at: moment(values.date).format("YYYY-MM-DD"),
				score_type: scoreType,
				hole_details: holes,
				override_confirmation: false,
				is_manual: false,
				number_of_holes: this.state.nrHoles,
				gender: this.state.golfer.gender
			};
		}

		if (this.props.combined_score_id) {
			data['combined_score_id'] = this.props.combined_score_id;
			data['score_index'] = this.props.selectedScoreIndex;
			data['combined_9_hole_score'] = this.props.combined_9_hole_score;
		}

		let x_hole = data.hole_details.map(function(hole){return hole.x_hole === undefined ? false : hole.x_hole})
		let front_incomplete = false
		let back_incomplete = false

		front_incomplete = x_hole.slice(10, 19).some((element) => element == true)
		back_incomplete = x_hole.slice(0, 10).some((element) => element == true)

		// If the golfer is using scaling,
		// an incomplete score will be posted only after it is confirmed using the modal
		if(this.props.golfer.use_scaling && this.state.nrHoles == "18" && (front_incomplete || back_incomplete)){
			this.setState({
				confirmIncompleteScoreModal: true,
				scoreId: this.props.score.id,
				modalReviewQuestion: "Action Required",
				modalReviewBodyQuestion: `You have not entered a score for every hole.
        			If you post your score, it will receive an 18-hole
        			Score Differential used for your Handicap Index.`,
				editData: data
			})
		}
		else{
			this.handleEditedScoreSubmit(data)
		}
	}

	handleEditedScoreSubmit(data) {
		return ScoreService.editHoleByHole(this.props.score.id, data)
		.then(response => {
			if (response.score.status === "UnderReview") {
				// It should not be the case:
				// - if an edited score is duplicated it will be 'Validated'
			} else {
				this.props.addAlert({
					type: 'success',
					message: 'Hole by hole score has been successfully edited'
				});
				this.props.closeModal(true);
			}
			  this.props.setOutdatedScoreModalOpen(!response.score.is_recent)
		})
		.catch(err => {
			this.setState({
				confirmIncompleteScoreModal: false,
				scoreId: null,
				modalReviewQuestion: '',
				modalReviewBodyQuestion: '',
				editData: null
			})

			let errors = [];

			if (err && err.data && err.data.errors) {
				Object.keys(err.data.errors).forEach(error => {
					if (err.data.errors[error].length > 1) {
						if(Array.isArray(err.data.errors[error])) {
							err.data.errors[error].forEach(errorChild => {
								errors.push(error.replace(/_/g, ' ') + ' ' + errorChild);
							})
						}
						else {
							errors.push(err.data.errors[error]);
						}
					} else {
						errors.push(err.data.errors[error][0]);
					}
				});
			}

			this.setState({errors})
		});
	}

	getHomeCourses() {
		if(this.state.nrHoles === '9'){
			this.setState({courses: this.props.homeCourses.filter(hc=> hc.tee_set_side === "F9" || hc.tee_set_side === "B9")})
		} else {
			this.setState({courses: this.props.homeCourses.filter(hc=> hc.tee_set_side === "All18")})
		}
	}

	getCoursesOptions(searchTerm, callback) {
		if (searchTerm && searchTerm.length > 2) {
			return CourseRatingSystemService.searchCourses(searchTerm)
				.then(response => {
					let options = response.courses.map(c => {
						return {value: c.CourseID, label: `${c.FullName} ${c.City && c.State ? `(${c.City}, ${c.State})` : ''}`, unparsedLabel: c.FullName}
					});
					this.setState({
						courses: options
					});
					callback(options)
				})
				.catch(err => {

				})
		} return new Promise(resolve => {
			resolve([])
		})

	}

	componentDidMount() {
		this.initializeScoreFromProps();
	}

	initializeScoreFromProps() {
		this.props.initialize({
			...this.props.score,
			date: new Date(moment(this.props.score.played_at, "YYYY-MM-DD")),
			score_type: !this.props.selectedTee ? 'home' : (this.props.score.score_type === "A" ? 'away': 'competition'),
			nrHoles: this.props.score.number_of_holes.toString(),
		});
		this.setState({
			scoreType: this.props.score.score_type === "H"? 'home' : (this.props.score.score_type === "A" ? 'away': "competition"),
			nrHoles: this.props.score.number_of_holes.toString(),
			method: !this.props.selectedTee ? 'home-course' : 'course'
		}, () => {
			if(this.props.selectedTee) {
				this.props.change('course_name',this.props.selectedCourse);
				this.props.change('tee_set_id', this.props.selectedTee);
				this.changeCourse(this.props.selectedCourse, this.props.selectedTee.value,this.props.selectedTee.tee_set_side)
				let index = this.props.selectedTee.tee_set_side === "B9" ? 10 : 1;
				this.setState({
					index: index,
					first_tee_set_side: this.props.selectedTee.tee_set_side
				});
			} else {
				this.props.change('course_name',this.props.selectedCourse);
				this.changeHomeCourse(this.props.selectedCourse)
				let index = this.props.selectedCourse.tee_set_side === "B9" ? 10 : 1;
				this.setState({
					index: index,
					first_tee_set_side: this.props.selectedCourse.tee_set_side
				});
			}
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.score !== prevProps.score) {
			this.initializeScoreFromProps();
		} else {
      if (this.state.scoreType !== prevState.scoreType) {
        if (!this.state.auto_score_type) {
          if ((this.state.scoreType === 'away' && prevState.scoreType === 'home') ||
          (this.state.scoreType === 'home' && prevState.scoreType === 'away')) {
          this.props.change('course_name', null);
          this.props.untouch('course_name');
          this.props.change('tee_set_id', null);
          this.props.untouch('tee_set_id');
        }
          this.setState({
            errors: []
          })
        }
        else {
          this.setState({
            auto_score_type: false
          });
        }
      }
			if (this.state.method !== prevState.method) {
				this.setState({
					courses: [],
					errors: []
				});
				if (this.state.method === 'home-course') this.getHomeCourses();
			}
			if (this.state.nrHoles !== prevState.nrHoles) {
				this.setState({
					errors: []
				});
				if (this.state.method === 'home-course') this.getHomeCourses();
			}
		}
	}

	returnTotalValueForScaling(array, what, i)
	{
		if(what === 'hole' || what === 'holes.hole') {
			if(array[what + i]) {
				if(isNaN(this.customParseInt(array[what + i]))) {
					return 0
				}
				else {
					return this.customParseInt(array[what + i])
				}
			}
			else {
				return 0
			}
		}
		else {
			return array[i] ? array[i][what] : 0;
		}
	}

	returnTotal(array, what, from, to) {
		let total = 0;

		if(!array) {
			return ''
		}

    if (from === 0 || from === 1) {
      for (let i = from; i <= from + 8; i++) {
			let hole_value = 0;

			if(this.state.golfer.use_scaling === true) {
				hole_value = this.returnTotalValueForScaling(array, what, i)
			}
			else {
				hole_value = what === 'hole' || what === 'holes.hole' ? (array[what + i] ?
          		(isNaN(this.customParseInt(array[what + i])) ? (this.state.selectedCourse.tee_set_side === 'B9' ? this.state.allAgsMax[i-1].net_par : this.state.agsMax[i-1].net_par) : this.customParseInt(array[what + i])) : 0) : array[i] ? array[i][what] : 0;
			}

			total += hole_value;
      }
    }
    if (to === 17 || to === 18) {
      for (let i = to - 8; i <= to; i++) {
			let hole_value = 0;

			if(this.state.golfer.use_scaling === true) {
				hole_value = this.returnTotalValueForScaling(array, what, i)
			}
			else {
        		hole_value= what === 'hole' || what === 'holes.hole' ? (array[what + i] ?
         		(isNaN(this.customParseInt(array[what + i])) ? (this.state.selectedCourse.tee_set_side === 'B9' ? this.state.agsMax[i-10].net_par : this.state.allAgsMax[i-10].net_par) : this.customParseInt(array[what + i])) : 0) : array[i] ? array[i][what] : 0;
		  }

		  total += hole_value;
      }
    }

		if (total === 0) {
			return ''
		} else {
			return total;
		}
  }

	generateCells(side, type) {
		let a = [];
		if (side === 'front') {
			a = [1, 2, 3, 4, 5, 6, 7, 8, 9];
		} else {
			a = [10, 11, 12, 13, 14, 15, 16, 17, 18];
		}
		let holes = this.state.selectedCourse.holes;

		return a.map(i => {
			if (type) {
				if (type === 'yards') return <div key={i} className="hbh_cell">{holes[i - 1] ? holes[i - 1].Length : 0}</div>;
				if (type === 'par') return <div key={i} className="hbh_cell">{holes[i - 1] ? holes[i - 1].Par : 0}</div>;
				if (type === 'stroke') return <div key={i}
												   className="hbh_cell">{holes[i - 1] ? holes[i - 1].Allocation : 0}</div>;
				if (type === 'hole'){

				let puttsValue = null;
				let holes = this.props.score.hole_details
				if (holes){
					let currentHole = holes.filter(hole => hole.hole_number === (i))[0];
					if (currentHole)
						puttsValue = currentHole.putts;
				}
				return (
					<div key={i} className="hbh_cell">
						<Field component={InputField}
							   type="text"
							   hideErrorMessages={true}
							   name={`holes.hole${i}`}
							   validate={(holes && puttsValue) && [ biggerThanPuts(puttsValue)]}
							   disabled={side === 'front' ? (this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "B9") : (this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "F9")}
							   onChange={(e) => {
								   if( !/^[xX]?[0-9]*$/.test(e.target.value) || this.customParseInt(e.target.value) === 0) {
									   e.preventDefault();
								   } else {
										 let index = this.state.selectedCourse.tee_set_side === "B9" ? 11 : 2
									   this.checkValue(e, i + 1, index)
								   }
							   }}
                 			normalize={value => value.toUpperCase()}
							   className={`hbh-score-input`}
							   id={`holes.hole${i}`}/>
					</div>);
				}
				if (type === 'ags') return <div key={i} className="hbh_cell">{this.props.formValues.ags && this.props.formValues.ags['hole' + i]}</div>;
				return null;
			} else {
				return <div key={i} className="hbh_cell">{i}</div>;
			}
		});

	}

	getTeeAGS(tee_set_id, date, tee_set_side) {
		let params = {golfer_id: this.state.golfer.id, tee_set_id: tee_set_id, include_net_pars: true, played_at: moment(date).format('YYYY-MM-DD')}
		ClubService.getTeeAGS(params, tee_set_side)
			.then(response => {
				if (response.maximum_scores && response.maximum_scores.length > 0) {
					this.setState({agsMax: response.maximum_scores, errors: []}, () => {
						let ags = {};
						let holes = {};
						let index = this.state.index;
						let hole_number_index = this.calculateHoleNumberIndex(this.state.selectedCourse.tee_set_side);
						this.props.score.hole_details.forEach(hole => {
              if (hole.x_hole) {
					if(this.state.golfer.use_scaling) {
						holes['hole' + (hole.hole_number - hole_number_index)] = '';
						ags['hole' + (hole.hole_number - hole_number_index)] = null;
					}
					else {
						holes['hole' + (hole.hole_number - hole_number_index)] = 'X';
						ags['hole' + (hole.hole_number - hole_number_index)] = this.state.agsMax[hole.hole_number-index]['net_par'];
					}
              }
              else if (hole.most_likely_score) {
                  holes['hole' + (hole.hole_number - hole_number_index)] = `X${hole.most_likely_score}`;
                  ags['hole' + (hole.hole_number - hole_number_index)] = Math.min(hole.most_likely_score, this.state.agsMax[hole.hole_number-index]['maximum_score']);
              }
              else {
                holes['hole' + (hole.hole_number - hole_number_index)] = hole.raw_score;
                ags['hole' + (hole.hole_number - hole_number_index)] = Math.min(hole.raw_score, this.state.agsMax[hole.hole_number-index]['maximum_score']);
              }
						});
						this.props.change('holes', holes );
						this.props.change('ags', ags);
						this.setState({loading: false});
					});
				}
			})
			.catch(err => {
				let errors = [];

				Object.keys(err.data.errors).forEach(error => {
					if (err.data.errors[error].length > 1) {
						err.data.errors[error].forEach(errorChild => {
							errors.push(error.replace(/_/g, ' ') + ' ' + errorChild);
						})
					} else {
						errors.push(err.data.errors[error][0]);
					}
				});

				this.setState({errors, loading: false})
			})

      ClubService.getTeeAGS(params)
      .then(res => {
        this.setState({
          allAgsMax: res.maximum_scores
        })
      })
      .catch(err => {
        console.log(err);
      })
	}

	calculateHoleNumberIndex(current_tee_set_side) {
		if (this.state.first_tee_set_side === "B9") {
			return current_tee_set_side === "B9" ? 0 : 9;
		} else if (this.state.first_tee_set_side === "F9") {
			return current_tee_set_side === "F9" ? 0 : -9;
		} else {
			return 0;
		}
	}

  //function to parseInt considering that the first character could be an X
  customParseInt(value) {
    if (value === 'x' || value === 'X') {
      return 'X';
    }
    if (value[0] === 'x' || value[0] === 'X') {
      value = value.substring(1);
    }
    return parseInt(value);
  }

	checkValue(e, target, index) {
		let value = e.target && e.target.value;
		let name = e.target && e.target.name;
		let reg = /^[xX]?\d*$/;
		let holes = this.props.formValues.holes || {};
		let ags = this.props.formValues.ags;
		let nextTarget = null;

		/// Remove holes. form input name
		if (name.indexOf('holes.') !== -1) {
			name = name.slice(6);
		}

		// add values
		if (!isNaN(this.customParseInt(value))) {
			if (this.customParseInt(value) < 30) {
				holes[name] = this.customParseInt(value);
				if (this.customParseInt(value) !== 0)
          ags[name] = Math.min(this.customParseInt(value), this.state.agsMax[target - index]['maximum_score']);
				if (!ags[name]) {
					ags[name] = ''
				}
			}
		}
		else if (this.customParseInt(value) === 'X') {
			holes[name] = 'X';

			if(this.state.golfer.use_scaling) {
				ags[name] = 0;
			}
			else {
				ags[name] = this.state.agsMax[target - index]['net_par'];
			}
		}
		else {
			holes[name] = '';
			ags[name] = '';
		}

		// check if the target has value.. else focus on the next empty input
		if (holes['hole' + target]) {
			nextTarget = Object.keys(holes).find(hole => {
				return (!holes[hole] && hole.slice(4) > target) ? hole : null
			});
			if (nextTarget) {
				nextTarget = nextTarget.slice(4);
			} else {
				nextTarget = 20;
			}
		} else {
			nextTarget = target;
		}

		/// if input is between 1-19 focus next or if is last field from table blur...
		if ((reg.test(value) || !value) && this.customParseInt(value) !== 0 && (isNaN(this.customParseInt(value)) || this.customParseInt(value) < 30)) {
      if (this.customParseInt(value) !== 1 && !isNaN(this.customParseInt(value))  && target) {
				if (!this.props.formValues.holes || !this.props.formValues.holes['hole' + nextTarget]) {
					switch (nextTarget) {
						case 10:
							if (this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "F9") {
								this.blurInput(9);
							} else {
								this.focusInput(nextTarget);
							}
							break;

						case 19:
							this.blurInput(18);
							break;
						case 20:
							//if nextTarget is 20, nothing happens and the focus remains on the current hole
							break;
						default:
							this.focusInput(nextTarget);
							break;
					}
				} else {
					if (!isNaN(this.customParseInt(value))) {
						this.blurInput(target - 1);
					}
				}
			}
		} else {
			// if !parseInt(value) prevent typing
			e.preventDefault();
		}
		this.props.change({holes: holes});
		this.props.change({ags: ags});
		this.forceUpdate();
	}

	changeHomeCourse(e){
		if(e.holesNumber === 9) {this.props.change('nrHoles', '9');}
		this.setState({
			selectedCourse: {...e, ...{tee_set_side: e.holesNumber === 9 ? 'F9': e.tee_set_side}},
			nrHoles: e.holesNumber === 9 ? e.holesNumber.toString() : this.state.nrHoles,
			agsMax: null
		});
		this.getTeeAGS(e.teeSetID, this.props.formValues.date, e.tee_set_side);
	}

	changeCourse(newValue, tee_set_id, tee_set_side) {
		if (newValue) {
      if (this.state.home_courses.includes(newValue.value)) {
        if (this.state.scoreType === 'away') {
         this.setState({
           scoreType: 'home',
           auto_score_type: true
         });
         this.props.change('scoreType', 'home');
       }
      }
      else {
        if (this.state.scoreType === 'home') {
         this.setState({
           scoreType: 'away',
           auto_score_type: true
         });
         this.props.change('scoreType', 'away');
       }
      }
			this.props.change('course_name.courseId', newValue);
			CourseRatingSystemService.getCourseDetails(newValue.value)
				.then(response => {
					let filteredTS = this.parseCourse(response);
					filteredTS = this.state.nrHoles === "18" ? filteredTS.filter(ts => ts.tee_set_side === "All18") : filteredTS.filter(ts => ts.tee_set_side === "F9" || ts.tee_set_side === "B9");
					let currentTee = filteredTS.filter(ts => parseInt(ts.value) === parseInt(tee_set_id) && ts.tee_set_side === tee_set_side)[0];
					if(currentTee) {
						this.props.change('tee_set_id', currentTee);
						this.setState({
							teeSetsOptions: filteredTS,
							selectedCourse: currentTee,
							agsMax: null
						}, () => {this.getTeeAGS(currentTee.value, this.props.formValues.date, tee_set_side)})

					} else {
						this.props.change('tee_set_id', null);
						this.props.change('tee_set_id', null);
						this.setState({
							teeSetsOptions: filteredTS,
							agsMax: null
						})
					}


				})
		} else {
			this.props.change('tee_set_id', null);
			this.setState({
				teeSetsOptions: [],
				agsMax: null
			});
      if (this.state.scoreType === 'home') {
        this.setState({
          scoreType: 'away'
        });
        this.props.change('scoreType', 'away');
      }
		}
		this.props.change('ags', {
			hole1: '',
			hole2: '',
			hole3: '',
			hole4: '',
			hole5: '',
			hole6: '',
			hole7: '',
			hole8: '',
			hole9: '',
			hole10: '',
			hole11: '',
			hole12: '',
			hole13: '',
			hole14: '',
			hole15: '',
			hole16: '',
			hole17: '',
			hole18: ''
		})
	}

	parseCourse(response, type) {
		let filteredTS = response.TeeSets.filter(ts => ts.Gender.charAt(0) === this.props.golfer.gender).map(ts => {
			let total = ts.Ratings.filter(rating => rating.RatingType === "Total")[0];
			let front = ts.Ratings.filter(rating => rating.RatingType === "Front")[0];
			let back = ts.Ratings.filter(rating => rating.RatingType === "Back")[0];
			return {label: ts.TeeSetRatingName,
				holes: ts.Holes,
				holesNumber: ts.HolesNumber,
				value: ts.TeeSetRatingId,
				courseId: response.CourseId,
				ratingTotal: total && `${parseFloat(total.CourseRating).toFixed(1)} / ${total.SlopeRating}`,
				ratingFront: front && `${parseFloat(front.CourseRating).toFixed(1)} / ${front.SlopeRating}`,
				ratingBack: back &&`${parseFloat(back.CourseRating).toFixed(1)} / ${back.SlopeRating}`,
				totalCourseRating: total && total.CourseRating,
				frontCourseRating: front && front.CourseRating,
				backCourseRating:  back && back.CourseRating,
				totalSlopeRating: total && total.SlopeRating,
				frontSlopeRating: front && front.SlopeRating,
				backSlopeRating:  back && back.SlopeRating,
				ratingTotalValue: total && parseFloat(total.CourseRating).toFixed(1),
			}
		});
		orderTees(filteredTS);
		filteredTS = filteredTS.reduce((res, current) => {
			let frontPar = current.holes.reduce((acc, h, index) => {
				if (index < 9) return acc + h.Par;
				return acc + 0;
			}, 0);
			let backPar = current.holes.reduce((acc, h, index) => {
				if (index >= 9) return acc + h.Par;
				return acc + 0;
			}, 0);
			let totalPar = current.holes.reduce((acc, h, index) => acc + h.Par, 0);
			let label = ''
			let front = {}
			if (current.ratingFront !== undefined) {
				label = current.label + " F9 \n" + `(${current.ratingFront} / ${frontPar})`;
				front = {
					label: label,
					unparsedLabel: current.label + ' F9',
					value: current.value,
					holes: current.holes,
					courseId: current.courseId,
					teeSetID: current.value,
					tee_set_side: 'F9',
					course_rating: current.frontCourseRating,
					slope_rating: current.frontSlopeRating,
					teeName: current.teeName,
					par: frontPar,
				};
			}

			let back = {}
			if (current.ratingBack !== undefined) {
				label = current.label + " B9 \n" + `(${current.ratingBack} / ${backPar})`;
				back = {
					label: label,
					unparsedLabel: current.label + ' B9',
					value: current.value,
					holes: current.holes,
					courseId: current.courseId,
					teeSetID: current.value,
					tee_set_side: 'B9',
					course_rating: current.backCourseRating,
					slope_rating: current.backSlopeRating,
					teeName: current.teeName,
					par: backPar,
				};
			}
			let total = {}
			if (current.ratingTotal !== undefined) {
				label = current.label + "\n" + `(${current.ratingTotal} / ${totalPar})`;
				total = {
					label: label,
					value: current.value,
					holes: current.holes,
					courseId: current.courseId,
					teeSetID: current.value,
					tee_set_side: 'All18',
					course_rating: current.frontCourseRating,
					slope_rating: current.frontSlopeRating,
					teeName: current.teeName,
					par: totalPar,
				};
			}
			return res.concat([total, front, back])
		}, []);
		return filteredTS;
	}

	focusInput(target) {
		document.getElementsByName('holes.hole'+target)[0].focus();
	}

	blurInput(target) {
		document.getElementsByName('holes.hole'+target)[0].blur();
	}

	removeScoreDifferentials() {
		GolferService.removeScoreDifferentials(this.props.score.golfer_id, this.props.score.id)
			.then(() => {
				this.props.closeModal(true, true);
			})
			.catch(err => {
				console.log(err);
			})
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		let checked = true;

		if (this.props.formValues && !this.props.formValues.holes) {
			checked = false
		}

		if(this.state.selectedCourse) {
			if (this.props.formValues && this.props.formValues.holes) {
				if (this.state.selectedCourse.tee_set_side === 'All18') {
					const hasHandicapIndex = this.props.golfer.handicap_index !== "NH" && this.props.golfer.handicap_index !== "WD"
					if(this.props.golfer.use_scaling && hasHandicapIndex) {
            checked = checkHoles(this.props.formValues.holes);
					}
					else {
						if (Object.keys(this.props.formValues.holes).length !== 18)
							checked = false;
					}
				}
				else {
					if (Object.keys(this.props.formValues.holes).length !== 9)
						checked = false;
				}
			}
    	}

		let self = this;
		let displayPuttsError = false

		if (this.props.errors && this.props.errors.holes) {
			Object.keys(this.props.errors.holes).forEach(err => {
				let noPuttsError = ["This field is required", "is not a number"];
				if(!noPuttsError.includes(this.props.errors.holes[err]))
					displayPuttsError = true;
			})
		}

		return (
			<Fragment>
				<form autoComplete="off"  onSubmit={handleSubmit(this.onSubmit.bind(this))} ref={'form'}>
					<div className="row">
						<div className="col">
							<div className="row with-padding-vertical ">
								<div className="col  is-1-of-5">
									<label htmlFor="date">Date Played <span>*</span></label>
									<Field maxDate={new Date()}
										   minDate={new Date('1/1/1990')}
										   className={'date'}
										   showMonthDropdown
										   showYearDropdown
										   value={new Date()}
										   name={'date'}
										   id={'date'}
										   component={DatePickerInput}
										   placeholder="Select ..."
											 onChange={(newValue)=>{
												 this.setState({loading: true})
												 this.getTeeAGS(this.state.selectedCourse.value,newValue, this.state.selectedCourse.tee_set_side)
											 }} />
								</div>
								<div className="col  is-3-of-5">
									<label htmlFor="date">Score Type</label>
									<div className="custom-radio__container">
										<Field
											onClick={
												() => {
													let prevScoreType = this.state.scoreType;
													let prevMethod = this.state.method;

													this.setState({
														scoreType: 'home',
														method: (prevScoreType !== "competition" || prevMethod !== "home-course") ? 'home-course' : this.state.method,
														selectedCourse: (prevScoreType !== "competition" || prevMethod !== "home-course") ? null : this.state.selectedCourse
													});

													this.props.change('scoreType', 'home');

													if(prevScoreType !== "competition" || prevMethod !== "home-course") {
														this.props.change('course_name', null);
														this.props.change('method', 'home-course')
													}
												}
											}
											checked={this.state.scoreType === "home"}
											id="home"
											name={'home'}
											component={'input'}
											type="radio"
										/>
										<label htmlFor="home">Home</label>

										<Field
											onClick={
												() => {
													let prevScoreType = this.state.scoreType;
													let prevMethod = this.state.method;

													this.setState({
														scoreType: 'away',
														method:  (prevScoreType !== "competition" || prevMethod !== "course") ? 'course' : this.state.method,
														course: null,
														selectedCourse:  (prevScoreType !== "competition" || prevMethod !== "course") ? null : this.state.selectedCourse
													});

													if(prevScoreType !== "competition" || prevMethod !== "course") {
														this.props.change('course_name', null);
														this.props.change('tee_set_id', null);
														this.props.change('method', 'course')
													}

													this.props.change('scoreType', 'away');

												}
											}
											checked={this.state.scoreType === 'away'}
											id='away'
											name={'away'}
											component={'input'}
											type="radio"
										/>
										<label htmlFor="away">Away</label>

										<Field
											onClick={
												() => {
													this.setState({
														scoreType: "competition",
													});
													this.props.change('scoreType', "competition");
												}
											}
											checked={this.state.scoreType === "competition"}
											id="competition"
											name={"competition"}
											component={'input'}
											type="radio"
										/>
										<label htmlFor="competition">Competition</label>

									</div>
								</div>

								<div className="col  is-1-of-5">
									<label htmlFor="date">Holes</label>
									<div className="custom-radio__container">
										<Field
											onClick={
												() => {
													this.setState({
														nrHoles: '18',
														selectedCourse: null,
														agsMax: null
													});
													this.props.change('nrHoles', '18');
													this.props.change('course_name', null);
													if(this.state.scoreType!=='home') this.props.change('tee_set_id', null);
												}
											}
											checked={this.state.nrHoles === "18"}
											disabled={true}
											id="18"
											name={'18'}
											component={'input'}
											type="radio"
										/>
										<label className={`disabled`} htmlFor="18">18</label>

										<Field
											onClick={
												() => {
													if (this.state.scoreType === 'competition') {
														return false;
													}
													this.setState({
														nrHoles: '9',
														selectedCourse: null,
														agsMax: null
													});
													this.props.change('nrHoles', '9');
													this.props.change('course_name', null);
													if(this.state.scoreType!=='home') this.props.change('tee_set_id', null);
												}
											}
											disabled={true}
											checked={this.state.nrHoles === '9'}
											id="9"
											name={'9'}
											component={'input'}
											type="radio"
										/>
										<label className={`disabled`} htmlFor="9">9</label>
									</div>
								</div>

							</div>

							<div className="row with-padding-vertical ">
								<div className="col  is-1-of-2">
									<label htmlFor="date">Choose Course/Tee Lookup Method:</label>
									<div className="radio__container">
										<Field
											onClick={
												() => {
													this.setState({
														method: 'home-course',
														selectedCourse: null
													})
													this.props.change('method', 'home-course');
													this.props.change('course_name', null);
													this.props.untouch('course_name');
												}
											}
											checked={this.state.method === 'home-course'}
											id='home-course'
											name={'home-course'}
											component={'input'}
											type="radio"
											className="simple__radio"
										/>
										<label htmlFor="home-course">Home Courses/Tees</label>

										<Field
											onClick={
												() => {
													this.setState({
														method: 'course',
														selectedCourse: null
													})
													this.props.change('method', 'course');
													this.props.change('course_name', null);
													this.props.untouch('course_name');
												}
											}
											checked={this.state.method === 'course'}
											id='course'
											name={'course'}
											component={'input'}
											type="radio"
											className="simple__radio"
										/>
										<label htmlFor="course">Course/Tee Search</label>
									</div>
								</div>


								{this.state.method === "home-course" ?
									<div className="col is-1-of-2">

										<Fragment>
											<label htmlFor={'course_name'}>Course/Tee Played</label>
											<Field name={'course_name'}
												   id={'course_name'}
												   onChange={(newValue)=>{
														 this.setState({loading: true})
													   this.changeHomeCourse(newValue);
												   }}
												   component={SelectInput}
												   className="react-select-container"
												   classNamePrefix="react-select"
												   options={this.state.courses}/>
										</Fragment>
									</div>
									:
									<Fragment>
										<div className="col is-2-of-8">
											<label  htmlFor={'tee'}>Course Played <span>*</span></label>
											<Field name={'course_name'}
												   id={'course_name'}
												   validate={[requiredSelect]}
												   defaultOptions={this.state.courses}
												   loadOptions={debounce(self.getCoursesOptions, 1000)}
												   component={AsyncSelectInput}
												   onChange={(event, newValue, prevValue) => {
													   this.changeCourse(newValue);
												   }}
												   className="react-select-container"
												   classNamePrefix="react-select"
											/>
										</div>
										<div className="col is-2-of-8">
											<label  htmlFor={'tee'}>Tee (C.R. / Slope / Par) <span>*</span></label>
											<Field name={'tee_set_id'}
												   id={'tee_set_id'}
												   validate={[requiredSelect]}
												   onChange={(e)=>{
													   this.setState({selectedCourse: {
															   tee_set_side: e.tee_set_side,
															   value: e.value,
															   holes: e.holes,
														   },
															 loading: true,
														   agsMax: null
													   }, ()=>{
														   this.getTeeAGS(e.value, this.props.formValues.date, e.tee_set_side);
													   })
												   }}
												   options={this.state.teeSetsOptions}
												   component={SelectInput}
												   className="react-select-container"
												   classNamePrefix="react-select"
											/>
										</div>
									</Fragment>}

							</div>

							{!this.state.loading ?
							((this.state.selectedCourse && this.state.agsMax && this.state.allAgsMax && this.props.formValues) &&
              <>
                <div className="row">
                  <div className="col">
                    <ul className="table__legend">
                      <li>
                        { !this.state?.golfer?.use_scaling && 'Hole Is Not Played: Enter X |'} Hole Not Completed: Enter X + Most Likely Score (ex: X5)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row align-right ">
                  <Fragment>
                    <div className={`hbh-container ${(this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "B9") ? 'disabled': ''}`}>

                      <div className="hbh">

                        <Fragment>
                          <div className="hbh_row hole">
                            <div className="hbh_cell label"></div>
                            {this.generateCells("front")}
                            <div className="hbh_cell">OUT</div>
                          </div>

                          <div className="hbh_row yards">
                            <div className="hbh_cell label">Yards</div>
                            {this.generateCells("front", 'yards')}
                            <div className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Length', "front" === 'front' ? 0 : 9, "front" === 'front' ? 8 : 17)}</div>
                          </div>

                          <div className="hbh_row par">
                            <div className="hbh_cell label">Par</div>
                            {this.generateCells("front", 'par')}
                            <div className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Par', "front" === 'front' ? 0 : 9, "front" === 'front' ? 8 : 17)}</div>
                          </div>

                          <div className="hbh_row">
                            <div className="hbh_cell label">Stroke Index</div>
                            {this.generateCells('front', 'stroke')}
                            <div className="hbh_cell"></div>
                          </div>

                          <div className="hbh_row score">
                            <div className="hbh_cell label">Score</div>
                            {this.generateCells("front", 'hole')}
                            <div className="hbh_cell gray">{this.returnTotal(this.props.formValues.holes, 'hole', "front" === 'front' ? 1 : 10, "front" === 'front' ? 9 : 18)}</div>
                          </div>

                          <div className="hbh_row esc_score ">
                            <div className="hbh_cell label ">Adj. Score</div>
                            {this.generateCells("front", 'ags')}
                            <div className="hbh_cell">{this.returnTotal(this.props.formValues.ags, 'hole',  1,  9)}</div>
                          </div>

                        </Fragment>

                      </div>
                    </div>

                    <div className={`hbh-container ${(this.state.nrHoles === '9' && this.state.selectedCourse.tee_set_side === "F9") ? 'disabled': ''}`}>

                      <div className="hbh">

                        <Fragment>
                          <div className="hbh_row hole">
                            <div className="hbh_cell label"></div>
                            {this.generateCells("back")}
                            <div className="hbh_cell">IN</div>
                            <div className="hbh_cell">TOT</div>
                          </div>

                          <div className="hbh_row yards">
                            <div className="hbh_cell label">Yards</div>
                            {this.generateCells("back", 'yards')}
                            <div className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Length', "back" === 'front' ? 0 : 9, "back" === 'front' ? 8 : 17)}</div>
                            <div className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Length', 0, 17)}</div>
                          </div>

                          <div className="hbh_row par">
                            <div className="hbh_cell label">Par</div>
                            {this.generateCells("back", 'par')}
                            <div className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Par', "back" === 'front' ? 0 : 9, "back" === 'front' ? 8 : 17)}</div>
                            <div className="hbh_cell">{this.returnTotal(this.state.selectedCourse.holes, 'Par', 0, 17)}</div>
                          </div>
                          <div className="hbh_row">
                            <div className="hbh_cell label">Stroke Index</div>
                            {this.generateCells('back', 'stroke')}
                            <div className="hbh_cell"></div>
                            <div className="hbh_cell"></div>
                          </div>
                          <div className="hbh_row score">
                            <div className="hbh_cell label">Score</div>
                            {this.generateCells("back", 'hole')}
                            <div className="hbh_cell gray">{this.returnTotal(this.props.formValues.holes, 'hole', "back" === 'front' ? 1 : 10, "back" === 'front' ? 9 : 18)}</div>
                            <div className="hbh_cell gray">{this.returnTotal(this.props.formValues.holes, 'hole', 1, 18)}</div>
                          </div>

                          <div className="hbh_row esc_score ">
                            <div className="hbh_cell label ">Adj. Score</div>
                            {this.generateCells("back", 'ags')}
                            <div className="hbh_cell">{this.returnTotal(this.props.formValues.ags, 'hole', 10, 18)}</div>
                            <div className="hbh_cell">{this.returnTotal(this.props.formValues.ags, 'hole', 1, 18)}</div>
                          </div>

                        </Fragment>

                      </div>
                    </div>
                  </Fragment>
                </div>
              </>
							)
							:
							<table style={{width: '100%'}}>
								<tbody >
								<tr>
									<td style={{textAlign: 'center'}}>
										 <span className="data-table__loader"></span>
									</td>
								</tr>
								</tbody>
							</table>}
							{this.state.errors.length > 0 &&
							<p>
								{this.state.errors.map((error,i)=> {
									return (<span key={i} className={'validation__message is-error'}>{error.charAt(0).toUpperCase() + error.substring(1)}</span>);
								})}
							</p>
							}

							{displayPuttsError && <div className="row">
								<div className="col ">
									<p className="validation__message is-error">A hole score must be greater than the number of putts.</p>
								</div>
							</div>}

							<div className="row with-padding-vertical align-right">
								{this.props.score.exceptional &&
								<div className="col is-1-of-4 ai-fe jc-c"
									 style={{height: '40px'}}>
									{this.props.overriden ?
										'ESR Adjustment Overriden'
										:
										<button
											onClick={() => this.setState({overrideModal: true})}
											type={'button'}
											className="btn fill gray">Override
											ESR Adjustments</button>
									}
								</div>
								}

								{this.props.score.committee_master_score && <div className="col is-2-of-6">
									<button onClick={() => this.setState({confirmScoreDifferentialsRemoval: true})}
											type={'button'} className="btn fill gray">Remove Score Differentials
									</button>
								</div>}

								<div className="col is-1-of-6">
									<button onClick={() => this.props.closeModal()}
											type={'button'} className="btn fill gray">Close
									</button>
								</div>

								<div className="col is-1-of-6 ">
									<button disabled={pristine || submitting || !checked} type={'submit'} ref={'submit'} className="btn fill blue">Edit Score</button>
								</div>
							</div>
						</div>
					</div>
				</form>
				{
					this.state.overrideModal &&
					<ConfirmationModalTypeB
						openModal={this.state.overrideModal}
						wideClass={"narrow"}
						onConfirmAction={() => {this.props.overrideScore(); this.setState({overrideModal: false}); }}
						modalIcon={"error_outline"}
						onCancelAction={() => {
							this.setState({overrideModal: false})
						}}
						closeModal={() => {
							this.setState({overrideModal: false})
						}}
						infoText={["Please confirm that you would like to override the ESR adjustments of this score"]}
						cancelLabel={"Close"}
						confirmLabel={"Confirm"}
					/>
				}
				{
					this.state.confirmScoreDifferentialsRemoval &&
					<ConfirmationModalTypeB
						openModal={true}
						wideClass={"narrow"}
						onConfirmAction={() => {this.removeScoreDifferentials(); this.setState({confirmScoreDifferentialsRemoval: false}); }}
						modalIcon={"error_outline"}
						onCancelAction={() => {
							this.setState({confirmScoreDifferentialsRemoval: false})
						}}
						closeModal={() => {
							this.setState({confirmScoreDifferentialsRemoval: false})
						}}
						infoText={["Please confirm that you would like to remove the score differentials adjustments."]}
						cancelLabel={"Close"}
						confirmLabel={"Confirm"}
					/>
				}
				{
					this.state.confirmIncompleteScoreModal &&
					<ConfirmationModalTypeB
						openModal={true}
						wideClass={"narrow"}
						onConfirmAction={() => this.handleEditedScoreSubmit(this.state.editData)}
						modalIcon={"error_outline"}

						onCancelAction={() => {
							this.setState({
								confirmIncompleteScoreModal: false,
								scoreId: null,
								modalReviewQuestion: '',
								modalReviewBodyQuestion: '',
								editData: null
							})
							this.props.closeModal(true);
						}}
						closeModal={() => {
							this.setState({
								confirmIncompleteScoreModal: false,
								scoreId: null,
								modalReviewQuestion: '',
								modalReviewBodyQuestion: '',
								editData: null
							})
							this.props.closeModal(true);
						}}

						question = {this.state.modalReviewQuestion}
						bodyQuestion = {this.state.modalReviewBodyQuestion}
						infoText={['Press "Continue" to post the score anyway', 'Press "Cancel" to review the score prior to posting']}
						cancelLabel={"Cancel"}
						confirmLabel={"Continue"}
					/>
				}
			</Fragment>

		);
	}
}

EditHBHScore = reduxForm({
	form: 'editHBHScore',
	destroyOnUnmount: true
})(EditHBHScore);

function mapStateToProps(state) {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true,
		formValues: getFormValues('editHBHScore')(state),
		errors: getFormSyncErrors('editHBHScore')(state),
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditHBHScore));
