import Modal from "react-modal";

function GenericModal(props) {
  const filtered_props = { ...props }
  delete filtered_props.icon
  delete filtered_props.child
  delete filtered_props.title
  delete filtered_props.isOpen

  let className = "narrow";
  if (props.className)
    className = props.className;
  
  return (
    <Modal
      isOpen={props.isOpen}
      contentLabel="Modal"
      portalClassName="modal"
      overlayClassName="modal__overlay"
      className="modal__content"
      bodyOpenClassName="modal--is-open"
      htmlOpenClassName="prevent-scroll"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
    >
      <div className={`modal__container ${className}`}>
        <div className="modal__head">
          <div className="modal__icon"><i className="material-icons-outlined">{props.icon}</i></div>
          <h2 className="modal__title">{props.title}</h2>
          <button className="modal__close" onClick={() => { props.close_modal() }}><i className="material-icons-outlined">clear</i></button>
        </div>
        <div className="modal__body">
          <props.child
            {...filtered_props}
          />
        </div>
      </div>
    </Modal>
  );
}

export default GenericModal
