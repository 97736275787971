import React, {Component, Fragment} from 'react';
import Modal from "react-modal";

class ConfirmationModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
		this.onConfirm = this.onConfirm.bind(this);
	}

	componentDidUpdate(prevProps) {
		if(prevProps.openModal !== this.props.openModal)
		this.setState({loading:false})
	}

	onConfirm() {
		this.setState({loading: true});
		this.props.onConfirmAction();
	}

	render() {
		return (
			<Modal
				isOpen={this.props.openModal}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={true}
				shouldFocusAfterRender={false}
			>

				<div className="modal__container confirmation narrow">
					<div className={`modal__head ${this.props.longQuestion ? 'auto__height' : ''}`}>
						<h2 className={`modal__title ${this.props.longQuestion ? 'long' : ''}`}>{this.props.question}</h2>
						<button className="modal__close" onClick={() => this.props.closeModal()}><i
							className="material-icons-outlined">clear</i></button>
					</div>
					<div className="modal__body">
						{this.state.loading ?
							<table style={{width: '100%'}}>
								<tbody>
								<tr>
									<td style={{textAlign: 'center'}}>
										<span className="data-table__loader"></span>
									</td>
								</tr>
								</tbody>
							</table>
							:

							<Fragment>
								{this.props.bodyQuestion && <div><h2>{this.props.bodyQuestion}</h2></div>}
								{this.props.body && <div>{this.props.body}</div>}
								{this.props.note &&
									<div><span>NOTE: </span><span className="gray">{this.props.note}</span></div>}
									<div className="row">
										{this.props.cancelLabel && <div className="col">
											<button type="button" disabled={this.props.loading} onClick={() => {
												this.props.onCancelAction()
											}} className="btn fill gray">{this.props.cancelLabel}</button>
										</div>
										}
										<div className="col">
											<button type="submit" disabled={this.props.loading} onClick={this.onConfirm}
													className="btn fill blue">{this.props.confirmLabel}</button>
										</div>
									</div>
							</Fragment>

						}
					</div>
				</div>
			</Modal>
		)
	}
}

export default ConfirmationModal;
