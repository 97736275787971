import React, {Component} from 'react';

import SelectedGuardianInfo from "./SelectedGuardianInfo";
import {Field} from "redux-form";
import {InputField} from "../../../inputs/InputField";
import {required} from "../../../inputs/validations";
import GuardianRelationshipForm from "./GuardianRelationshipForm";

class GolferGuardianForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGuardianFields: false
		}
	}

	render() {
		return (
			<>
				<SelectedGuardianInfo guardian={this.props.guardian}/>
				{
					!this.props.guardian.email ? (
						<div className={'row'}>
							<div className="col is-1-of-2">
								<label htmlFor="guardian.email">Guardian Email <span>*</span></label>
								<Field component={InputField}
									   validate={[required]}
									   type="text"
									   name="guardian.email"
									   id="guardian.email"/>
							</div>
						</div>
					) : null
				}
				<GuardianRelationshipForm guardian={this.props.guardian} formGuardian={this.props.formGuardian}/>

			</>
		)
	}
}

export default GolferGuardianForm
