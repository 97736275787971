import { Fragment } from "react";
import MembershipFeeForm from "../Forms/MembershipFeeForm";

export default function MembershipFeeModal(props) {
  return (
    <Fragment>
      <div className="modal__container narrow">
        <div className="modal__head">
          <div className="modal__icon"><i className="material-icons-outlined">{props.action === 'add' ? 'add_circle_outline' : 'edit'}</i></div>
          <h2 className="modal__title">{props.action === 'add' ? 'Add' : 'Edit'} Membership Fee</h2>
          <button className="modal__close" onClick={() => props.closeModal()}><i className="material-icons-outlined">clear</i></button>
        </div>
        <div className="modal__body">
          <MembershipFeeForm
            {...props}
          />
        </div>
      </div>
    </Fragment>
  )
}