export function productAlertsReducer(state = {}, action) {
	switch(action.type) {
		case '@@ghin/SHOW_PRODUCT_ALERTS':
			return {
				...state,
				showAlert: action.payload
			};
		case '@@ghin/PRODUCT_ALERTS_DATA':
			return {
				...state,
				alert: action.payload
			};
		default:
			return state;
	}
}
