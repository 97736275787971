export const membershipTypes = [
	{
		value: "Junior",
		label: "Junior",
	},
	{
		value: "Standard",
		label: "Standard",
	},
];

export const genderOptions = [
	{ value: "M", label: "Male" },
	{ value: "F", label: "Female" },
];

export const statusOptions = [
	{ value: "Active", label: "Active" },
	{ value: "Inactive", label: "Inactive" },
];

export const time_array = [
	{
		value: "1:00",
		label: "1:00",
	},
	{
		value: "2:00",
		label: "2:00",
	},
	{
		value: "3:00",
		label: "3:00",
	},
	{
		value: "4:00",
		label: "4:00",
	},
	{
		value: "5:00",
		label: "5:00",
	},
	{
		value: "6:00",
		label: "6:00",
	},
	{
		value: "7:00",
		label: "7:00",
	},
	{
		value: "8:00",
		label: "8:00",
	},
	{
		value: "9:00",
		label: "9:00",
	},
	{
		value: "10:00",
		label: "10:00",
	},
	{
		value: "11:00",
		label: "11:00",
	},
	{
		value: "12:00",
		label: "12:00",
	},
];

export const notification_category = [
	{
		value: "tournaments_and_events",
		label: "Tournaments & Events",
	},
	{
		value: "member_benefits_and_promotions",
		label: "Member Benefits & Promotions",
	},
	{
		value: "general_updates",
		label: "General Updates",
	},
];
