import React, { Component, Fragment } from 'react';
import Modal from "react-modal";

export default class InvalidHubspotSetupModal extends Component {

  render() {
    return (
      <Modal
        isOpen={true}
        onRequestClose={this.props.closeModal}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
      >
        <Fragment>

          <div className="modal__container narrow">

            <div className="modal__head">

              <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
              <h2 className="modal__title">Hubspot Setup</h2>
              <button className="modal__close" onClick={this.props.closeModal}><i className="material-icons-outlined">clear</i></button>

            </div>

            <div className="modal__body">
              <div className="row">
                <div className="col">
                  <span>The Hubspot Integration cannot be enabled for this HubSpot Account. Please, try again.</span>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <button type="button" onClick={this.props.closeModal} className="btn fill gray">Cancel</button>
                </div>
              </div>
            </div>

          </div>

        </Fragment>
      </Modal>
    );
  }
}
