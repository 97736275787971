import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import EditAssocGeneralSettingsModal from "../../../Modals/EditAssocGeneralSettingsModal";
import EditPrefferedColorPaletteModal from "../../../Modals/EditPrefferedColorPaletteModal";
import AssociationService from "../../../../services/api/association";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAlert } from "../../../shared/actions";
import { updateAssociation } from "../Account/Actions";

class BasicSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editAssocGeneralSettingsModalIsOpen: false,
			editPrefferedColorPaletteModalIsOpen: false,
			settings: null,
			error: null,
		};

		this.openEditAssocGeneralSettingsModal = this.openEditAssocGeneralSettingsModal.bind(
			this
		);
		this.openPrefferedColorPaletteModal = this.openPrefferedColorPaletteModal.bind(
			this
		);
	}

	openEditAssocGeneralSettingsModal() {
		this.setState({ editAssocGeneralSettingsModalIsOpen: true });
	}

	openPrefferedColorPaletteModal() {
		this.setState({ editPrefferedColorPaletteModalIsOpen: true });
	}

	closeModal(data) {
		this.setState({
			editAssocGeneralSettingsModalIsOpen: false,
			editPrefferedColorPaletteModalIsOpen: false,
			settings: data ? data : this.state.settings,
		});
	}

	componentDidMount() {
		AssociationService.getGolferProducts(this.props.associationId)
			.then((res) => {
				this.setState({
					settings: res.association_settings,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}

	render() {
		return (
			<Fragment>
				{this.state.settings && (
					<Fragment>
						<div className="row">
							<div className="col">
								<div className="panel">
									<div className="panel__head">
										About Us & Social Media
									</div>
									<div className="panel__body">
										{this.props.canEditAssociation && (
											<div className="row">
												<div className="col auto push-right">
													<button
														onClick={
															this
																.openEditAssocGeneralSettingsModal
														}
														className="btn lnk"
													>
														Edit
													</button>
												</div>
											</div>
										)}
										<div className="row">
											<div className="col jc-c">
												<div className="content__container">
													<h4>About Us Copy</h4>
													<div className="content">
														<p>
															{
																this.state
																	.settings
																	.about_us
															}
														</p>
													</div>
												</div>
											</div>
											<div className="col jc-c">
												<div className="data-set__container">
												<div className="data-set">
														<span className="data-label">
															Social Media Platform:
														</span>
														<span>
															<a>
																{
																	this.state
																		.settings
																		.preferred_social_media_platform || ""
																}
															</a>
														</span>
													</div>
													{(this.state.settings.preferred_social_media_platform && ((this.state.settings.preferred_social_media_platform != 'None')))									
													 && <div className="data-set">
														<span className="data-label">
															{this.state.settings.preferred_social_media_platform} URL:
														</span>
														<span>
															<a
																href={
																	this.state
																		.settings
																		.preferred_social_url
																}
																target="_blank"
																rel="noreferrer"
															>
																{
																	this.state
																		.settings
																		.preferred_social_url
																}
															</a>
														</span>
													</div>
													}
													<div className="data-set">
														<span className="data-label">
															News Feed:
														</span>
														<span>
															<a
																href={
																	this.state
																		.settings
																		.newsfeed_url
																}
																target="_blank"
																rel="noreferrer"
															>
																{
																	this.state
																		.settings
																		.newsfeed_url
																}
															</a>
														</span>
													</div>
													<div className="data-set">
														<span className="data-label">
															Association News URL:
														</span>
														<span>
															<a
																href={
																	this.state
																		.settings
																		.association_news_url
																}
																target="_blank"
																rel="noreferrer"
															>
																{
																	this.state
																		.settings
																		.association_news_url
																}
															</a>
														</span>
													</div>
													<div className="data-set">
														<span className="data-label">
															Contact Us Email
															Address:
														</span>
														<span>
															<a
																href={`mailto:${this.state.settings.contact_association_email}`}
															>
																{
																	this.state
																		.settings
																		.contact_association_email
																}
															</a>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="panel">
									<div className="panel__head">
										Mobile App Primary Color
									</div>
									<div className="panel__body">
										{this.props.canEditAssociation && (
											<div className="row">
												<div className="col auto push-right">
													<button
														onClick={
															this
																.openPrefferedColorPaletteModal
														}
														className="btn lnk"
													>
														Edit
													</button>
												</div>
											</div>
										)}
										<div className="row">
											<div className="col">
												<p>
													Choose a primary color from
													the preferred color palette
													options below which will be
													utilized throughout the GHIN
													Mobile App (e.g. header,
													buttons, graphs, charts,
													etc.)
												</p>
												<div className="badges__container row">
													<div className="default-badge__container col is-1-of-7 jc-c ai-c">
														<div
															className={`badge default color-00365F ${
																!this.state
																	.settings
																	.preferred_color_for_app ||
																this.state
																	.settings
																	.preferred_color_for_app ===
																	"00365F"
																	? "is-active"
																	: ""
															}`}
														>
															00365F
														</div>
														<span className="badge__label">
															Default
														</span>
													</div>
													<div className="badge-list col is-6-of-7">
														<div className="row fr">
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-10644B ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"10644B"
																			? "is-active"
																			: ""
																	}`}
																>
																	10644B
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-5E7331 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"5E7331"
																			? "is-active"
																			: ""
																	}`}
																>
																	5E7331
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-2592A8 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"2592A8"
																			? "is-active"
																			: ""
																	}`}
																>
																	2592A8
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-363893 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"363893"
																			? "is-active"
																			: ""
																	}`}
																>
																	363893
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-023971 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"023971"
																			? "is-active"
																			: ""
																	}`}
																>
																	023971
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-0C63B9 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"0C63B9"
																			? "is-active"
																			: ""
																	}`}
																>
																	0C63B9
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-625143 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"625143"
																			? "is-active"
																			: ""
																	}`}
																>
																	625143
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-948354 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"948354"
																			? "is-active"
																			: ""
																	}`}
																>
																	948354
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-000000 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"000000"
																			? "is-active"
																			: ""
																	}`}
																>
																	000000
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-6F6F6F ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"6F6F6F"
																			? "is-active"
																			: ""
																	}`}
																>
																	6F6F6F
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-C45200 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"C45200"
																			? "is-active"
																			: ""
																	}`}
																>
																	C45200
																</div>
															</div>
															<div className="col is-1-of-6 jc-c ai-c">
																<div
																	className={`badge color-970E16 ${
																		this
																			.state
																			.settings
																			.preferred_color_for_app ===
																		"970E16"
																			? "is-active"
																			: ""
																	}`}
																>
																	970E16
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<Modal
							isOpen={
								this.state.editPrefferedColorPaletteModalIsOpen
							}
							onRequestClose={() => {
								this.closeModal();
							}}
							contentLabel="Modal"
							portalClassName="modal"
							overlayClassName="modal__overlay"
							className="modal__content"
							bodyOpenClassName="modal--is-open"
							htmlOpenClassName="prevent-scroll"
							shouldCloseOnOverlayClick={true}
							shouldFocusAfterRender={false}
						>
							<EditPrefferedColorPaletteModal
								assocSettings={this.state.settings}
								assocId={this.props.associationId}
								closeModal={(data) => {
									this.closeModal(data);
								}}
							/>
						</Modal>
						<Modal
							isOpen={
								this.state.editAssocGeneralSettingsModalIsOpen
							}
							onRequestClose={() => {
								this.closeModal();
							}}
							contentLabel="Modal"
							portalClassName="modal"
							overlayClassName="modal__overlay"
							className="modal__content"
							bodyOpenClassName="modal--is-open"
							htmlOpenClassName="prevent-scroll"
							shouldCloseOnOverlayClick={true}
							shouldFocusAfterRender={false}
						>
							<EditAssocGeneralSettingsModal
								assocSettings={this.state.settings}
								assocId={this.props.associationId}
								closeModal={(data) => {
									this.closeModal(data);
								}}
							/>
						</Modal>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ updateAssociation, addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(mapDispatchToProps)(BasicSettings);
