import React, {Component, Fragment} from 'react';

import {bindActionCreators} from "redux";
import {addAlert} from "../shared/actions";
import {connect} from "react-redux";
import EditGuardianForm from "../Forms/EditGuardianForm";

class EditGuardianModal extends Component {

	constructor(){
		super();
		this.state = {
			dataToChange: null,
			confirmationModal: false
		}
	}


	render() {
		return (
			<Fragment>
				<div className={`modal__container narrow ${this.state.confirmationModal ? 'hidden' : ''}`}>

					<div className="modal__head">

						<div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
						<h2 className="modal__title">{this.props.title}</h2>
						<button className="modal__close" onClick={() => {this.props.closeModal()}}><i className="material-icons-outlined">clear</i></button>

					</div>

					<div className="modal__body">
						<EditGuardianForm close={(data)=>this.props.closeModal(data)} guardian={this.props.guardian} golfer={this.props.golfer} edit_status={this.props.edit_status} />
					</div>

				</div>
			</Fragment>


		);
	}
}


function mapDispatchToProps(dispatch) {

	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(null, mapDispatchToProps)(EditGuardianModal);
