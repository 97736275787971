import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { addAlert } from "../shared/actions";
import { connect } from "react-redux";
import CopyPushNotificationForm from "../Forms/CopyPushNotificationForm";
import CopyPushNotificationForFederationForm from "../Forms/CopyPushNotificationForFederationForm";

class CopyNotificationModal extends Component {
	render() {
		return (
			<Fragment>
				<div className={`modal__container narrow`}>
					<div className="modal__head">
						<div className="modal__icon">
							<i className="material-icons-outlined">
								add_circle_outline
							</i>
						</div>
						<h2 className="modal__title">Copy Push Notification</h2>
						<button
							className="modal__close"
							onClick={() => {
								this.props.closeModal();
							}}
						>
							<i className="material-icons-outlined">clear</i>
						</button>
					</div>

					<div className="modal__body">
						{this.props.forFederation ? (
							<CopyPushNotificationForFederationForm
								push_notification_setting={
									this.props.push_notification_setting
								}
								user={this.props.user}
								close={() => this.props.closeModal()}
								setTableRefresh={(value) =>
									this.props.setTableRefresh(value)
								}
							/>
						) : (
							<CopyPushNotificationForm
								push_notification_setting={
									this.props.push_notification_setting
								}
								associationId={this.props.associationId}
								user={this.props.user}
								close={() => this.props.closeModal()}
								setTableRefresh={(value) =>
									this.props.setTableRefresh(value)
								}
							/>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(null, mapDispatchToProps)(CopyNotificationModal);
