import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect, withRouter} from 'react-router-dom';
import TotalForm from './Total';
import HoleByHole from './HoleByHole';


class PostAScore extends Component {

	render() {
		return (
            <Fragment>


				<div className="vertical-tabs__container row">
					<ul className="vertical-tabs col">
						<li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/hole-by-hole`}>Hole-by-Hole</NavLink></li>
						<li><NavLink className="vertical-tab" activeClassName="is-active" to={`${this.props.match.url}/total`}>Total Score</NavLink></li>
					</ul>
					<div className="vertical-tabs__panel col">
						<Switch>
							<Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/hole-by-hole`}/>
							<Route path={`${this.props.match.path}/total`} component={(props) => <TotalForm golfer={{...this.props.golfer, ...{id: this.props.publicView ? this.props.golfer.private_id : this.props.golfer.id}}}/>}/>
							<Route path={`${this.props.match.path}/hole-by-hole`} component={(props) => <HoleByHole golfer={{...this.props.golfer, ...{id: this.props.publicView ? this.props.golfer.private_id : this.props.golfer.id}}}/>}/>
						</Switch>
					</div>
				</div>
            </Fragment>
		);
	}
}


export default withRouter(PostAScore);
