
import React, {Component, Fragment} from 'react';

const Spinner = (props) => {
  
  
	return (
    <div className={props.className}><div></div><div></div><div></div><div></div></div>
	);
}

export default Spinner
