import React, {Fragment, useEffect, useState} from "react";
import GhinTable from "../../../shared/GhinTable";
import JoinAndRenewServices from "../../../../services/api/joinAndRenew";
import GenericModal from "../../../Modals/GenericModal";
import EditClubJoinPageCustomizationForm from "../../../Forms/EditClubJoinPageCustomizationForm";

export default function ClubJoinPages(props) {
  const [joinPages, setJoinPages] = useState([])
  const [selectedJoinPage, setSelectedJoinPage] = useState({})
  const [editCustomizationOpen, setEditCustomizationOpen] = useState(false)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancel = false;

    async function fetchData() {
      setLoading(true);
      JoinAndRenewServices.getClubJoinPages(props.club.id).then((res) => {
        if (cancel) return;
        setJoinPages(res.join_pages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    }

    fetchData();

    return () => {
      cancel = true;
    }
  }, [props.club.id])

  const shouldShowEditButton = (userType) => {
    const disallowedTypes = [
      "association-read-only",
      "club-read-only",
      "read-only",
      "association-score-maintenance",
      "club-score-maintenance"
    ];

    return !disallowedTypes.includes(userType);
  }

  const columns = [
    {
      Header: "Association Join Page Name",
      accessor: "association_join_page_name",
    },
    {
      Header: "Membership Fees Included",
      Cell: (data) => {
        return (
          <ul>
            {data.original.club_membership_fees.map((cmf) => {
              return <li key={cmf.id}>{cmf.name}</li>;
            })}
          </ul>
        );
      }
    },
    {
      Header: "Club Join Page URL",
      accessor: "club_join_page_url",
      Cell: data => {
        return <a href={`${data.value}`}> {data.value}</a>
      }
    },
    {
      colClassName: shouldShowEditButton(props.userType) ? "col_join_pages_actions" : "col_club_join_pages_action",
      Cell: data => {
        return <Fragment>
          <div className="text-holder" style={{textOverflow: "ellipsis"}}>
            <span>
              <button className="btn fill blue" style={{width: "95px"}} onClick={() => {
                navigator.clipboard.writeText(data.original.club_join_page_url);
                props.addAlert({type: 'success', message: `Join Link copied to clipboard`});
              }}>
                Copy Link
              </button>
              {
                shouldShowEditButton(props.userType) &&
                <button className="btn fill green" style={{width: "105px", marginLeft: "10px"}} onClick={() => {
                  setEditCustomizationOpen(true);
                  setSelectedJoinPage(data.original)
                }}>
                  Edit Join Page
                </button>
              }
            </span>
          </div>
        </Fragment>
      }
    }
  ];

  return (
    <Fragment>
      <div className="panel">
        <div className="panel__head">
          Club Join Pages
        </div>
        <div className='panel__body'>
          <div className="row">
            <div className="col">
              <p>The following URL's can be added to your club website to allow online membership signup and renewals.
                Click on <strong>Copy URL</strong> to copy the link.</p>
              <p>To customize your join pages, click on <strong>Edit Join Page</strong>.</p>
            </div>
          </div>
          <div className="row">
            <div className="col regular_font">
              <GhinTable
                idKey={"id"}
                columns={columns}
                hideSelect={true}
                hidePagination={true}
                sortable={false}
                data={joinPages}
                loading={loading}
              />
            </div>
          </div>
          <GenericModal
            isOpen={editCustomizationOpen}
            icon={"edit"}
            child={EditClubJoinPageCustomizationForm}
            title={"Club Join Page Customization"}
            close_modal={() => {
              setEditCustomizationOpen(false)
              setSelectedJoinPage({})
            }}
            join_page={selectedJoinPage}
          />
        </div>
      </div>
    </Fragment>
  )
}
