import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect} from 'react-router-dom';
import PrimaryFederation from "./PrimaryFederation";
import SurveySetup from "./SurveySetup";
import AlertManagement from "./AlertManagement";
import {connect} from "react-redux";

class Account extends Component {

	render() {
		return (
			<Fragment>
				<div className="vertical-tabs__container row">
					<ul className="vertical-tabs col">
						<li><NavLink className="vertical-tab" activeClassName="is-active" to={'/manage/associations/account/primary'}>Primary</NavLink></li>
						<li><NavLink className="vertical-tab" activeClassName="is-active" to={'/manage/associations/account/survey-setup'}>Survey Setup</NavLink></li>
						{this.props.notification_user && <li><NavLink className="vertical-tab" activeClassName="is-active" to={'/manage/associations/account/alert-management'}>Alert Management</NavLink></li>}
					</ul>
					<div className="vertical-tabs__panel col">
						<Switch>
							<Redirect exact from="/manage/associations/account" to="/manage/associations/account/primary"/>
							<Route path={`/manage/associations/account/primary`} component={PrimaryFederation}/>
							<Route path={`/manage/associations/account/survey-setup`} component={SurveySetup}/>
							<Route path={`/manage/associations/account/alert-management`} component={AlertManagement}/>
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		notification_user : state.user.access.notification_user
	};
}

export default connect(mapStateToProps, null)(Account);
