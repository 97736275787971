import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { addAlert } from "../shared/actions";
import { connect } from "react-redux";
import ReviewNotificationForm from "../Forms/ReviewNotificationForm";

class ReviewNotificationModal extends Component {
	render() {
		return (
			<Fragment>
				<div className={`modal__container narrow`}>
					<div className="modal__head">
						<div className="modal__icon">
							<i className="material-icons-outlined">edit</i>
						</div>
						<h2 className="modal__title">
							Review Push Notification
						</h2>
						<button
							className="modal__close"
							onClick={() => {
								this.props.closeModal();
							}}
						>
							<i className="material-icons-outlined">clear</i>
						</button>
					</div>

					<div className="modal__body">
						<ReviewNotificationForm
							close={() => this.props.closeModal()}
							selectedNotification={
								this.props.selectedNotification
							}
							associationId={this.props.associationId}
							user={this.props.user}
							setTableRefresh={(value) => this.props.setTableRefresh(value)}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({ addAlert }, dispatch);
	return {
		dispatch,
		...actions,
	};
}

export default connect(null, mapDispatchToProps)(ReviewNotificationModal);
