import React from "react";
import { useDispatch } from "react-redux";
import { SubmissionError } from "redux-form";
import { FederationAccount } from "../../../../services/api/federationAccount";
import { prepareTestSendData, configInitialValuesTest } from "./utils";
import { addAlert } from "../../../shared/actions";
import { reset } from "redux-form";

import TestForm from './TestForm';

export function Test() {
  const dispatch = useDispatch();

  let initialValues = configInitialValuesTest();

  let onSubmit = (values) => {
    let bodyData = prepareTestSendData(values);
    return FederationAccount.sendTestFederationNewsletter(bodyData)
      .then(res => {
        let alertAction = addAlert({
          type: "success",
          message: "Test Email successfully sent!"
        });
        dispatch(alertAction);
        dispatch(reset("federationNewsletterTest"));
      })
      .catch(err => {
        let alertAction = addAlert({
          type: "error",
          message: "Test Email was not sent!"
        });
        dispatch(alertAction);
        console.error(this);
        throw new SubmissionError({ghinNumber: 'Golfer not found'})
      });
  }
	return (
		<div className="row">
			<div className="col">
				<div className="panel">
					<div className="panel__head">Test</div>
					<div className="panel__body">
						<div className="row">
							<div className="col jc-fs">
								<div className="data-set__container left">
                  <TestForm
                    initialValues={ initialValues }
                    enableReinitialize= { true }
                    onSubmit = { onSubmit }
                  />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}
