/* eslint-disable */
import {addValidator, numericality} from 'redux-form-validators';
import moment from "moment";

export const required = value => (value ? undefined : 'This field is required');
export const maxThreeChars = value => (value.length <= 3 ? undefined : 'Code must contain max 3 characters');
export const maxOneHundreadAndFiftyChars = value => (value.length <= 150 ? undefined : 'This field must contain max 150 characters');
export const requiredSelect = value => ((value && value.value) ? undefined : 'This field is required');
export const requiredMultiSelect = value => (value && value.length > 0) ? undefined : 'This field is required';
export const localNumber = value => (value ? ((value.length <= 10 && value.length >= 2) ? undefined : 'The Local Number should include minimum 2 and maximum 10 characters.') : undefined);
export const alphaNumeric = value => (value ? (value.match(/(^[a-zA-Z0-9]+)$/) ? undefined : 'Should contain only alpha-numeric characters') : undefined);
export const capsAlphaNumericOrHyphenOrUnderscore = value => (value ? (value.match(/(^[A-Z0-9_-]+)$/) ? undefined : 'Should contain only uppercase letters, digits, underscores, or hyphens') : undefined);
export const alphaNumericOrPoint = value => (value ? (value.match(/(^[a-zA-Z0-9.]+)$/) ? undefined : 'Should contain only alpha-numeric characters') : undefined);
// eslint-disable-next-line no-useless-escape
export const isEmailAddress = value => {
	if (!value) {
		return undefined;
	}

	const trimmedValue = value.trim();
	const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return emailRegex.test(trimmedValue) ? undefined : 'Invalid email address';
};
export const isPhoneNumber = value => (value ? (value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) ? undefined : 'Invalid phone number') : undefined);
export const isUrl = value => (value ? (
		(value.match(/^(?:http(s)?:\/\/)/))
			?
			"Do not include http:// or https://"
			:
			(value.match(/^(www\.){0,1}[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/) ? undefined : 'Invalid URL Format')
)
	:
	undefined);
/// alt url format... cred ca cel de sus e folosit cu un alt scop...
export const isUrlFormat = value => (value ? (value.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$/) ?  undefined : 'Invalid URL Format') : undefined);
export const isTermUrlFormat = value => (value ? (value.match(/^(http:\/\/|https:\/\/|www\.){0,1}[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,100}(:[0-9]{1,100})?(\/.*)?$/i) ?  undefined : 'Invalid URL Format') : undefined);
export const scoreMinimumValue = value => {if (value && value < 27) { return "score is below the minimum allowed. Please recheck your score and try again.";}}
export const scoreMaximumValue = value => {if (value && value > 90) { return "score is above the maximum allowed. Please recheck your score and try again.";}}
export const isNumber = value => {if (value && !/^\d+$/.test(value)) { return "This field should be numeric";}}
export const isFloatNumber = value => {if(value && !/^\+?[0-9]{1,2}([,.][0-9]{1,4})?$/.test(value)) { return "Invalid H.I. value"}}
export const isNumeric = value => {if(value && !/^\+?[0-9]{1,4}([,.][0-9]{1,4})?$/.test(value)){ return "This field should be numeric"}}
export const maxLength8 = value => value && value.length > 8 ? `Must be 8 characters or less` : undefined;
export const validDay = value => (value && (parseInt(value) > 0 && parseInt(value) <= 31) ? undefined : 'This field is required');
export const handicapIndex = value => {if (value && (parseFloat(value) < -9.9 || parseFloat(value) > 54.0)) { return 'Handicap Index value should be betwwen +9.9 and 54.0';}}
export const golfer_age = value => {if (value && (value < 13 || value > 150)) { return 'Golfers age value should be between 13 and 150';}}
export const number_of_scores = value => {if (value && (value < 1 || value > 1000)) { return 'Number of scores value should be between 0 and 1000';}}

export const customMsgRequired = addValidator({
  validator: (message, value) => {
    if (!value) return { defaultMessage: message}
  }
})

export const socialMediaURL = addValidator({
  validator: (social_media, value) => {
		const website = "(www\\.|https://(?:www\\.)?)?" + social_media + "\\.com/..*";
		const regexString = `^${website}\\b`;
		const regex = new RegExp(regexString);
		if (social_media != "None" && value == "")
			return { defaultMessage: "This field is required" }
    if (value && !regex.test(value)) {
      return { defaultMessage: "Invalid URL" }
    }
  }
})

export const isCustomNumeric = addValidator({
  validator: (maxLength, value) => {
    if (value && !/^\+?[0-9]+([,.][0-9]{1,2})?$/.test(value)) {
      return { defaultMessage: "The value entered in this field must be a numerical amount in dollars with optional cents." }
    }
    const num = parseFloat(value)
    if (num >= 10 ** (maxLength + 1)) {
      return { defaultMessage: `This number should be less than ${10 ** (maxLength + 1)}` }
    }
  }
})

export const biggerThanPuts = (max) => numericality({
	int: true,
	'>': max,
	msg: { '>=': 'The score can\'t be smaller or equal to the hole putts.' },
})

export const checkSignIsSelected = addValidator({
	validator:(signValue, value) => {
		if (value && (signValue[0] === false)) {
			return {defaultMessage: 'Please select a sign'};
		}
		else if (!value && (signValue[0] === true)) {
			return {defaultMessage: 'This field is required'};
		}
	}
})

export const graterThan = addValidator({
	validator:(minValue, value) => {
		if (value && parseInt(value) <= minValue) {
			return {defaultMessage: "Grater than " + minValue};
		}
	}
})

export const smallerThan = addValidator({
	validator:(maxValue, value) => {
		if (value && parseInt(value) <= maxValue) {
			return {defaultMessage: "Smaller than " + maxValue};
		}
	}
})

export const maxLength = addValidator({
	validator:(maxLength, value) => {
		if (value && value.length > maxLength[0]) {
			return {defaultMessage: maxLength[1] ? maxLength[1] : `Must be ${maxLength[0]} characters or less`};
		}
	}
})

export const biggerThanValue = addValidator({
	validator:(min,value) => {
		if (parseFloat(value) < min) {
			return {defaultMessage: "The value should be more than 1 stroke"};
		}
	}
})

export const greaterThanCustomMessage = addValidator({
  validator: (value, max) => {
    if (parseFloat(value[0]) > parseFloat(max)) {
      return { defaultMessage: value[1] };
    }
  }
})

export const lowerThanCustomMessage = addValidator({
  validator: (value, min) => {
    if (parseFloat(value[0]) < parseFloat(min)) {
      return { defaultMessage: value[1] };
    }
  }
})

export const customRequired = addValidator({
	validator: (options, value, allValues) => {
		if (!value) {
			return {
				defaultMessage: `${options.fieldName} is required`
			}
		}
	}
});

export const scoreBetweenValues = addValidator({
	validator: (allowedValues, values) => {
		if(parseFloat(values) < allowedValues[0] || parseFloat(values) > allowedValues[1]) {
			if(allowedValues[3]) {
				return {
					defaultMessage: `${allowedValues[2]} must be between ${allowedValues[0].toFixed(1)} and ${allowedValues[1].toFixed(1)}`
				}
			} else {
				return {
					defaultMessage: `${allowedValues[2]} must be between ${allowedValues[0]} and ${allowedValues[1]}`
				}
			}

		}
	}
})


export const totalScoreValue = addValidator({
	validator: (options, value, allValues) => {
		if(allValues.number_of_holes) {
			if (allValues.number_of_holes === 9) {
				if (value && value < 27) {
					return {
						defaultMessage: 'The total score is below the minimum allowed. Please recheck your score and try again.'
					}
				}
				if (value && value > 90) {
					return {
						defaultMessage: 'The total score is above the minimum allowed. Please recheck your score and try again.'
					}
				}
			} else {
				if (allValues.number_of_holes.value === "9") {
					if (value && value < 27) {
						return {
							defaultMessage: 'The total score is below the minimum allowed. Please recheck your score and try again.'
						}
					}
					if (value && value > 90) {
						return {
							defaultMessage: 'The total score is above the minimum allowed. Please recheck your score and try again.'
						}
					}
				} else {
					if (value && value < 54) {
						return {
							defaultMessage: 'The total score is below the minimum allowed. Please recheck your score and try again.'
						}
					}
					if (value && value > 180) {
						return {
							defaultMessage: 'The total score is above the minimum allowed. Please recheck your score and try again.'
						}
					}
				}
			}
			if(!value){
				return {
					defaultMessage: 'Please enter your score.'
				}
			}
		}
	}
});

export const validateSeasonDates = addValidator({
	validator: (options, value, allValues) => {
		if (allValues.seasonStart && allValues.seasonEnd && !allValues.seasonAllYear) {
			let seasonStart = moment(`${allValues.seasonStart}/${moment().format("YY")}`, 'MM/DD/YY');
			let seasonEnd = moment(`${allValues.seasonEnd}/${moment().format("YY")}`, 'MM/DD/YY');
			if (!moment(allValues.played_at).isBetween(seasonStart, seasonEnd)) {
				return {
					defaultMessage: 'Date played is outside of the active score posting season for this golf course.'
				}
			}
		}
	}
})

export const passwordMatch = addValidator({
	validator: (options, value, allValues) => {
		if (allValues.password && allValues.password.length > 0
			&& allValues.confirm_password && allValues.confirm_password.length > 0) {
			if (allValues.password !== allValues.confirm_password) {
				return {
					defaultMessage: 'Passwords do not match'
				}
			}
		}
	}
});

export const validateRapidScoreEntry = addValidator({
	validator: (options, value, allValues) => {
		switch (options.selectedEntryMethod) {
			case 'ghin':
				if (value && !/^\d+$/.test(value)) {
					return {
						defaultMessage: 'Only numbers allowed.'
					}
				}
				break;
			case 'local':
				if (value && !/^\d+$/.test(value)) {
					return {
						defaultMessage: 'Only numbers allowed.'
					}
				}
				break;
			default:
				return null;
		}
	}
})
