import { Fragment } from 'react';

export default function AssociationAlertModal(props) {
  return (
    <Fragment>

      <div className="modal__container very-narrow">
        <div className="modal__head orange">
          <div className="modal__icon"/>
          <h2 className="modal__title">Alert</h2>
        </div>

        <div className="modal__body">
          <div className={"row"}>
            <div className={"col"}>
              <p><strong>{props.data.message_title}</strong></p>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col"}>
              <p>{props.data.message_body}</p>
            </div>
          </div>
          <div className="row no-margin">
            <div className="col is-1-of-2 push-right">
              <button type={'submit'} onClick={() => props.closeModal()} className="btn fill orange">Close</button>
            </div>
          </div>
        </div>
      </div>

  </Fragment>
  );
}