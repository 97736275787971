import axios from 'axios'
import { onError, onSuccess } from './responseHandler'

const client = (() => {
    return axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`
    });
})();


const request = function (options, store) {
    options.params = {
        action: 'show',
        controller: 'api/v1/orchestration',
        format: 'json',
        ...options.params,
    };
    options.headers = {
        'Accept': 'text/html',
        'Content-Type': 'application/x-www-form-urlencoded',
        ...options.headers,
    };

    if (!options.method) {
    	options.method = "POST";
	}


    return client(options)
        .then(onSuccess)
        .catch(onError);
};


export default request;
