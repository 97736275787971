import { Fragment, useCallback, useEffect } from "react";
import { useState } from "react";
import { addAlert } from "../../../shared/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import { maxLength, required } from "../../../../inputs/validations";
import { SelectInput } from "../../../../inputs/Select";
import { InputField } from "../../../../inputs/InputField";
import PaymentGatewayServices from "../../../../services/api/paymentGateway";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";

const providers = [
  { value: 'Automated Financial Solutions (AFS)', label: 'Automated Financial Solutions (AFS)' }
]

function SetupFunction(props) {
  const [error, set_error] = useState("")
  const { handleSubmit, pristine, submitting, dispatch } = props;
  const [edit, setEdit] = useState(false)
  const [publicKey, setPublicKey] = useState('');
  const [showCancel, setShowCancel] = useState(true);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const hasFormValues = pristine || submitting;

  useEffect(() => {
    let cancel = false;
    async function fetchData() {
      PaymentGatewayServices.getSettings(props.associationId).then((res) => {
        if (cancel) return;
        if (res.payment_gateway_settings[0]) {
          setPublicKey(res.payment_gateway_settings[0].public_key);
          setShowCancel(true);
        } else {
          setEdit(prevState => !prevState);
          setShowCancel(false);
        }
      });
    }
    fetchData();

    return () => {
      cancel = true;
    }
  }, [props.associationId])

  const reload_payment_settings = useCallback(() => {
    const initialize = props.initialize
    PaymentGatewayServices.getSettings(props.associationId).then((res) => {
      const selected_payment_setting = res.payment_gateway_settings[0]
      const public_key = selected_payment_setting !== undefined ? selected_payment_setting.public_key : ''
      setPublicKey(public_key)
      initialize({
        payment_gateway: providers[0],
        public_key: public_key
      })
    })
  }, [props.initialize, props.associationId])

  useEffect(() => {
    const initialize = props.initialize
    initialize({
      payment_gateway: providers[0],
      public_key: publicKey
    })
  }, [props.initialize, publicKey])

  const onSubmit = (values, dispatch, props) => {
    setEdit(!edit);
    const params = {
      private_key: values.private_key,
      name: values.name,
      payment_provider: values.payment_gateway.value,
      public_key: values.public_key,
    }
    const golf_association_id = props.associationId
    return PaymentGatewayServices.updateSettings(golf_association_id, params).then(res => {
      props.addAlert({ type: 'success', message: 'Payment data successfully updated' })
      reload_payment_settings()
    }).catch(err => {
      if (err.data.errors) {
        const error = err.data.errors
        props.addAlert({ type: "error", message: error[Object.keys(error)[0]][0] })
      }
      if (err.data.error) {
        console.log(err.data.error)
        set_error(err.data.error)
      }
    })
  }

  const onCancel = () => {
    reload_payment_settings();
    if (publicKey) {
      setEdit(!edit);
    }
  };

  const deletePaymentGatewaySetting = () => {
    PaymentGatewayServices.deleteSetting(props.associationId).then(() => {
      props.addAlert({ type: 'success', message: 'Payment data successfully deleted' });
      setEdit(prevState => !prevState);
      setShowCancel(false);
      reload_payment_settings();
    }).catch(err => {
      console.log(err);
      if (err.data.errors) {
        const error = err.data.errors
        props.addAlert({ type: "error", message: error[Object.keys(error)[0]][0] })
      }
    }).finally(() => {
      setDeleteModalIsOpen(false);
    });
  };

  const onEdit = () => {
    setEdit(!edit);
    setShowCancel(true);
  }

  return (
    <Fragment>
      <div className="panel">
        <div className="panel__head">
          Commerce Setup
        </div>
        <div className="panel__body">
          <div className="row">
           {!edit && <div className="afs-info-wrapper">
            <div className="col is-1-of-2">
              <p className="afs-title">Automated Financial Solutions (AFS)</p>
              <p className="afs-data">Heidi Olson</p>
              <p className="afs-data">715-858-0083</p>
              <p className="afs-data">heidi@harbortouch-afs.com</p>

              <br/>
              <br/>

              <p className="afs-data">{`Private Security Key: ${'*'.repeat(5)}`}</p>
              <p className="afs-data">{`Public Security Key: ${'*'.repeat(5)}`}</p>

              <br/>
              <br/>

              {/* <div className="no-padding col is-3-of-5">
                <button className="btn fill gray" onClick={() => {}}>Perform a Test Transaction</button>
              </div> */}
            </div>
              {props.canEditAssociation && <Fragment>
                <div className="col is-1-of-9 push-right">
                  <button
                    className="btn fill gray"
                    onClick={() => {
                      setDeleteModalIsOpen(true);
                    }}
                  >Delete</button>
                </div>
                <div className="col is-1-of-9">
                  <button className="btn fill green" onClick={() => onEdit()}>Edit</button>
                </div>
              </Fragment>}
           </div>}

            {edit && <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="full-width">
              <div className="row">
                <div className="col is-4-of-6">
                  <div className="container middle-narrow">
                    <div className="row">
                      <div className="col">
                        <label htmlFor="payment_gateway">Payment Gateway <span>*</span></label>
                        
                        <div className="row">
                          <div className="col is-3-of-5">
                            <Field
                              name="payment_gateway"
                              id="payment_gateway"
                              validate={[required]}
                              component={SelectInput}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={providers}
                              disabled={true}
                            />
                          </div>

                          <div className="col middle">
                            <p className="light-text">Note: Automated Financial Solutions (AFS) is currently the only Payment Gateway</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="private_key">Private Security Key <span>*</span></label>
                        <Field
                          component={InputField}
                          validate={[required, maxLength([100])]}
                          type="text"
                          name="private_key"
                          id="private_key"
                          disabled={!props.canEditAssociation}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="public_key">Public Security Key <span>*</span></label>
                        <Field
                          component={InputField}
                          validate={[required, maxLength([100])]}
                          type="text"
                          name="public_key"
                          id="public_key"
                          disabled={!props.canEditAssociation}
                        />
                      </div>
                    </div>
                    {error.length > 0 && <div className="row">
                      <div className="col" style={{ color: "red" }}>
                        {error}
                      </div>
                    </div>}
                    
                    <div className="row">
                      <div className="col">
                        <span className="red bold ">Important</span>
                        <p className="regular-text">
                          By providing the Private Security Key and Public Security Key, you consent to payment processing through the Automated Financial Solutions payment gateway. Please review and approve your gateway provider account settings to ensure proper configurations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Future - We will display the contact information based on the value selected in the provider input */}
                <div className="col is-2-of-6">
                  <div className="afs-info-wrapper" style={{marginTop: "20px", marginLeft: "-45px"}}>
                   <div className="col">
                     <p className="afs-title">Automated Financial Solutions (AFS)</p>
                     <p className="afs-data">Heidi Olson</p>
                     <p className="afs-data">715-858-0083</p>
                     <p className="afs-data">heidi@harbortouch-afs.com</p>
                   </div>
                </div>
                </div>
              </div>
              <div className="row">
                <div className="col is-1-of-4 push-right">
                  <div className="row">
                    {showCancel && <div className="col is-1-of-2">
                      <button type={'button'} className="btn fill gray" onClick={() => onCancel()}>Cancel</button>
                    </div>}
                    {!showCancel && !hasFormValues && <div className="col is-1-of-2">
                      <button type={'button'} className="btn fill gray" onClick={() => dispatch(reset('editCredentialSetupForm'))}>Cancel</button>
                    </div>}
                    {!showCancel && hasFormValues && <span className="col is-1-of-2" />}
                    <div className="col is-1-of-2">
                      <button type={'submit'} className="btn fill blue" disabled={pristine || submitting}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>}
          </div>
        </div>
      </div>

      <ConfirmationModalTypeB
        openModal={deleteModalIsOpen}
        wideClass={"narrow"}
        onConfirmAction={() => {
          deletePaymentGatewaySetting()
        }}
        modalIcon={"help_outline"}
        onCancelAction={() => {
          setDeleteModalIsOpen(false)
        }}
        closeModal={() => {
          setDeleteModalIsOpen(false)
        }}
        infoText={[`Are you sure you want to delete the setting?`]}
        cancelLabel={"No"}
        confirmLabel={"Yes"} />
    </Fragment>
  );
}

const Setup = reduxForm({
  form: 'editCredentialSetupForm',
  destroyOnUnmount: true
})(SetupFunction)

function mapStateToProps() {
  return {
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    noOverwriteOnInitialize: true
  }
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ addAlert }, dispatch);
  return {
    dispatch,
    ...actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
