import React, {Fragment, useCallback, useEffect} from "react";
import {useState} from "react";
import {addAlert} from "../../../shared/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {InputField} from "../../../../inputs/InputField";
import JoinAndRenewServices from "../../../../services/api/joinAndRenew";
import {
	isTermUrlFormat,
	maxOneHundreadAndFiftyChars,
	required
} from "../../../../inputs/validations";
import ConfirmationModalTypeB from "../../../Modals/ConfirmationModalTypeB";

function TermsAndPolicy(props) {
	const [error, set_error] = useState("")
	const {handleSubmit, pristine, submitting} = props;
	const [edit, setEdit] = useState(false)
	const [terms, setTerms] = useState('')
	const [termsId, setTermsId] = useState('')
	const [policy, setPolicy] = useState('')
	const [termsAndPolicy, setTermsAndPolicy] = useState(true)
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
	const [termsInputError, setTermsInputError] = useState(false);
	const [policyInputError, setPolicyInputError] = useState(false);
	const [termsInputValue, setTermsInputValue] = useState(undefined);
	const [policyInputValue, setPolicyInputValue] = useState(undefined);
	
	const hasTermValue = termsInputValue !== '' && termsInputValue !== undefined && termsInputValue !== null
	const hasPolicyValue = policyInputValue !== '' && policyInputValue !== undefined && policyInputValue !== null
	
	useEffect(() => {
		let cancel = false;

		async function fetchData() {
			JoinAndRenewServices.getAssociationTerms(props.associationId).then((res) => {
				if (cancel) return;
				if (res.golf_association_terms.length === 0) {
					setTermsAndPolicy(false);
					setEdit(true);
					return;
				}
				setTerms(res.golf_association_terms[0].terms_url);
				setTermsId(res.golf_association_terms[0].id);
				setPolicy(res.golf_association_terms[0].policy_url);
				setTermsInputValue(res.golf_association_terms[0].terms_url);
				setPolicyInputValue(res.golf_association_terms[0].policy_url);
			});
		}

		fetchData();

		return () => {
			cancel = true;
		}
	}, [props.associationId])

	const reload_terms_and_policy = useCallback(() => {
		const initialize = props.initialize

		JoinAndRenewServices.getAssociationTerms(props.associationId).then((res) => {
			if (res.golf_association_terms.length === 0) {
				setTermsInputError(false);
				setPolicyInputError(false);
				setTermsAndPolicy(false);
				setEdit(true);
				initialize({
					terms: '',
					policy: ''
				})
				return;
			}
			setTerms(res.golf_association_terms[0].terms_url);
			setTermsId(res.golf_association_terms[0].id);
			setPolicy(res.golf_association_terms[0].policy_url);
			setTermsInputValue(res.golf_association_terms[0].terms_url);
			setPolicyInputValue(res.golf_association_terms[0].policy_url);
			initialize({
				terms: res.golf_association_terms[0].terms_url,
				policy: res.golf_association_terms[0].policy_url
			})
		})

	}, [props.initialize, props.associationId])

	const remove_terms_and_policy = () => {
		const golf_association_id = props.associationId
		return JoinAndRenewServices.removeAssociationTerms(golf_association_id, termsId).then(res => {
			props.addAlert({
				type: 'success',
				message: 'Terms & Conditions and Privacy Policy successfully removed'
			})
			reload_terms_and_policy()
		}).catch(err => {
			if (err.data.errors) {
				const error = err.data.errors
				set_error(error[Object.keys(error)[0]])
				props.addAlert({
					type: 'error',
					message: error[Object.keys(error)[0]]
				})
			}
			if (err.data.error) {
				set_error(err.data.error)
				props.addAlert({type: 'error', message: err.data.error})
			}
		})
			.finally(() => {
				setDeleteModalIsOpen(false)
			})
	}

	useEffect(() => {
		const initialize = props.initialize
		initialize({
			terms: terms,
			policy: policy
		})
	}, [props.initialize, terms, policy])

	const onSubmit = (values, dispatch, props) => {
		setEdit(!edit);
		const params = {
			terms_url: values.terms,
			policy_url: values.policy,
		}
		const golf_association_id = props.associationId
		if (termsAndPolicy === true) {
			return JoinAndRenewServices.updateAssociationTerms(golf_association_id, termsId, params).then(res => {
				props.addAlert({
					type: 'success',
					message: 'Terms & Conditions and Privacy Policy successfully updated'
				})
				reload_terms_and_policy()
			}).catch(err => {
				if (err.data.errors) {
					const error = err.data.errors
					set_error(error[Object.keys(error)[0]])
					props.addAlert({
						type: 'error',
						message: error[Object.keys(error)[0]]
					})
				}
				if (err.data.error) {
					set_error(err.data.error)
					props.addAlert({type: 'error', message: err.data.error})
				}
			})
		} else {
			return JoinAndRenewServices.createAssociationTerms(golf_association_id, params).then(res => {
				props.addAlert({
					type: 'success',
					message: 'Terms & Conditions and Privacy Policy successfully created'
				})
				reload_terms_and_policy()
				setTermsAndPolicy(true);
			}).catch(err => {
				if (err.data.errors) {
					const error = err.data.errors
					set_error(error[Object.keys(error)[0]])
					props.addAlert({
						type: 'error',
						message: error[Object.keys(error)[0]]
					})
				}
				if (err.data.error) {
					set_error(err.data.error)
					props.addAlert({type: 'error', message: err.data.error})
				}
			})
		}
	}

	const onCancel = () => {
		reload_terms_and_policy();
		setEdit(!edit);
	}

	const validateInput = (inputFieldName, value) => {
		if (inputFieldName === 'terms') {
			setTermsInputValue(value);
			setTermsInputError(false);
			if ((value === '' && policyInputValue === '') || (value !== '' && isTermUrlFormat(value) === 'Invalid URL Format') || value.length > 150)
				setTermsInputError(true);
		} else if (inputFieldName === 'policy') {
			setPolicyInputValue(value);
			setPolicyInputError(false);
			if ((value === '' && termsInputValue === '') || (value !== '' && isTermUrlFormat(value) === 'Invalid URL Format') || value.length > 150)
				setPolicyInputError(true);
		}
	};

	useEffect(() => {
		if(policyInputValue !== undefined && edit && termsAndPolicy)
			validateInput('policy', policyInputValue)
	}, [termsInputValue])

	useEffect(() => {
		if(termsInputValue !== undefined && edit && termsAndPolicy)
			validateInput('terms', termsInputValue)
	}, [policyInputValue])

	return (
		<Fragment>
			<div className="panel">
				<div className="panel__head">
					Terms & Conditions and Privacy Policy
				</div>
				<div className="panel__body">
					<div className="row">
						{!edit && <div className="afs-info-wrapper">
							<div className="col is-3-of-4">
								<p className="terms-and-privacy-title">Terms &
									Conditions</p>
								<p
									className="terms-and-privacy-mt terms-and-privacy-url">{terms ? terms : '-'}</p>

								<br/>
								<br/>

								<p className="terms-and-privacy-title">Privacy
									Policy</p>
								<p
									className="terms-and-privacy-mt terms-and-privacy-url">{policy ? policy : '-'}</p>

								<br/>
								<br/>


							</div>

							{props.canEditAssociation && <Fragment>
								<div className="col is-1-of-9 push-right">
									<button className="btn fill gray"
											onClick={() => {
												setDeleteModalIsOpen(true);
											}}>Delete
									</button>
								</div>
								<div className="col is-1-of-9">
									<button className="btn fill green"
											onClick={() => setEdit(!edit)}>Edit
									</button>
								</div>
							</Fragment>}
						</div>}

						{edit && <div className="col">
							<form autoComplete="off"
								  onSubmit={handleSubmit(onSubmit)}
								  className="full-width-no-lef-margin">
								<div
									className="container full-width-no-lef-margin">
									<div className="row">
										<div className="col">
											{termsAndPolicy === true &&
												<p className="terms-and-privacy-title">
													Edit Terms & Conditions
												</p>
											}
											{termsAndPolicy === false &&
												<p className="terms-and-privacy-title">
													Add Terms & Conditions
												</p>
											}
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p>
												The Terms & Conditions will be
												displayed on the golfer
												registration form. The golfer
												will be required to
												acknowledge that they have read
												and agree to the terms
												before submitting the form. This
												may also be called
												"Terms of Service".
											</p>
										</div>
									</div>
									<div className="row">
										<div
											className="col is-1-of-2 terms-and-privacy-mt">
											<label htmlFor="terms">Terms &
												Conditions
												URL {!hasTermValue && !hasPolicyValue ? <span>*</span> : (!hasTermValue || hasTermValue && hasPolicyValue ? null : <span>*</span>)}</label>
											<Field
												component={InputField}
												validate={policyInputValue === '' ? [isTermUrlFormat, maxOneHundreadAndFiftyChars, required] : [isTermUrlFormat, maxOneHundreadAndFiftyChars]}
												type="text"
												name="terms"
												id="terms"
												disabled={!props.canEditAssociation}
												onChange={(event) => validateInput('terms', event.target.value)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className="regular-text">
												Example:
												www.golfassociation.org/terms/
											</p>
										</div>
									</div>
									<hr style={{
										border: '0.5px solid #cccccc',
										marginTop: '25px',
										marginBottom: '25px'
									}}></hr>
									<div className="row">
										<div className="col">
											{termsAndPolicy === true &&
												<p className="terms-and-privacy-title">
													Edit Privacy Policy
												</p>
											}
											{termsAndPolicy === false &&
												<p className="terms-and-privacy-title">
													Add Privacy Policy
												</p>
											}
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p>
												The Privacy Policy will be
												displayed on the golfer
												registration form. The golfer
												will be required to
												acknowledge that they have read
												and agree to the terms
												of the policy before submitting
												the form.
											</p>
										</div>
									</div>
									<div className="row">
										<div
											className="col col is-1-of-2 terms-and-privacy-mt">
											<label htmlFor="policy">Privacy
												Policy
												URL {!hasTermValue && !hasPolicyValue ? <span>*</span> : (!hasPolicyValue || hasTermValue && hasPolicyValue ? null : <span>*</span>)} </label>
											<Field
												component={InputField}
												validate={termsInputValue === '' ? [isTermUrlFormat, maxOneHundreadAndFiftyChars, required] : [isTermUrlFormat, maxOneHundreadAndFiftyChars]}
												type="text"
												name="policy"
												id="policy"
												disabled={!props.canEditAssociation}
												onChange={(event) => validateInput('policy', event.target.value)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<p className="regular-text">
												Example:
												www.golfassociation.org/privacy-policy/
											</p>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col is-1-of-4 push-right">
										<div className="row">
											{termsAndPolicy &&
												<div className="col is-1-of-2">
													<button type={'button'}
															className="btn fill gray"
															onClick={() => onCancel()}>Cancel
													</button>
												</div>
											}
											<div
												className="col is-1-of-2 push-right">
												<button type={'submit'}
														className="btn fill blue"
														disabled={termsInputError || policyInputError || pristine || submitting || !props.canEditAssociation}>Save
												</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>}
					</div>
				</div>
			</div>
			<ConfirmationModalTypeB
				openModal={deleteModalIsOpen}
				wideClass={"narrow"}
				onConfirmAction={() =>
					remove_terms_and_policy()
				}
				modalIcon={"help_outline"}
				onCancelAction={() => {
					setDeleteModalIsOpen(false)
				}}
				closeModal={() => {
					setDeleteModalIsOpen(false)
				}}
				infoText={[`Are you sure you want to delete the Terms & Conditions and Privacy Policy?`]}
				cancelLabel={"No"}
				confirmLabel={"Yes"}
			/>
		</Fragment>
	);
}

const termsAndPolicy = reduxForm({
	form: 'editTermsAndPolicyForm',
	destroyOnUnmount: true
})(TermsAndPolicy)

function mapStateToProps() {
	return {
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		noOverwriteOnInitialize: true
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(termsAndPolicy);
