import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import EditGolferAccountModal from "../../Modals/EditGolferAccountModal";
import GolferAddressModal from "../../Modals/GolferAddressModal";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import GolferCreateDigitalProfileLinkModal from "../../Modals/GolferCreateDigitalProfileLinkModal";
import GolferResetDigitalProfilePasswordLinkModal from "../../Modals/GolferResetDigitalProfilePasswordLinkModal";
import EditGolferStatusModal from "../../Modals/EditGolferStatusModal";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";
import GolferService from "../../../services/api/golfer";
import {addAlert} from "../../shared/actions";
import GhinTable from "../../shared/GhinTable";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import moment from "moment";
import {getPermission} from "../../../permissionManager";
import {NavLink} from 'react-router-dom';
import SharedService from "../../../services/api/shared";

class DateInterval {
	constructor(start, end) { // two Date instances
		if (start > end) [start, end] = [end, start]; // swap
		let age = moment.duration(moment(end).diff(start))
		this.days = age._data.days;
		this.months = age._data.months;
		this.years = age._data.years;
	}
	toString() {
		const arr = ["years", "months", "days"].map(p =>
			this[p] && (this[p] + " " + p.slice(0, this[p] > 1 ? undefined : -1))
		).filter(Boolean);
		if (!arr.length) return "0 days";
		const last = arr.pop();
		return arr.length ? [arr.join(" "), last].join(" ") : last;
	}
}


function getAge(dateString) {
	let today = moment()
	let dob = moment(dateString)
	today.set({hour:0,minute:0,second:0,millisecond:0});
	dob.set({hour:0,minute:0,second:0,millisecond:0});
	return `${new DateInterval(dob, today)}`;
}


class Account extends Component {

	constructor(props) {

		super(props);

		this.state = {
			editAccountModalIsOpen: false,
			addressModalIsOpen: false,
			deleteAddressModal: false,
			deleteAddressError: null,
			type: null,
			actionType: null,
			loadingDigitalProfile: true,
			digitalProfile: null,
			digitalProfileEmails: [],
			loadingDigitalProfileEmails: true,
			page: 1,
			per_page: 5,
			total: 0,
			pages: 0,
			sendInvitationModal: false,
			resetPasswordModal: false,
			invitationsError: null,
			resendInvitationModal: false,
			digitalProfileLinkError: null,
			createDigitalProfileLinkModal: false,
			resetDigitalProfilePasswordLinkModal: false,
			removeDigitalProfileModal: false,
			removeDigitalProfileError: null,
			secondaryAddressModalIsOpen: false,
		};

		this.openEditAccountModal = this.openEditAccountModal.bind(this);
		this.openEditStatusModal = this.openEditStatusModal.bind(this);
		this.deleteAddress = this.deleteAddress.bind(this);
		this.sendInvitation = this.sendInvitation.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.resendInvitation = this.resendInvitation.bind(this);
		this.removeDigitalProfile = this.removeDigitalProfile.bind(this);
		this.generateCreateDigitalProfileLink = this.generateCreateDigitalProfileLink.bind(this);
		this.generateResetDigitalProfilePasswordLink = this.generateResetDigitalProfilePasswordLink.bind(this);
		this.openSecondaryAddressModal = this.openSecondaryAddressModal.bind(this);
	}

	openEditAccountModal() {
		this.setState({editAccountModalIsOpen: true});
	}

	openEditStatusModal() {
		this.setState({editStatusModalIsOpen: true});
	}

	openSecondaryAddressModal() {
		this.setState({secondaryAddressModalIsOpen: true});
	}

	getAge(dateString) {
		let a = moment();
		let b = moment(dateString);

		let years = a.diff(b, 'year');
		b.add(years, 'years');

		let months = a.diff(b, 'months');
		b.add(months, 'months');

		let days = a.diff(b, 'days');
		let age = {
			years: `${years} years`,
			months: `${months} months`,
			days: `${days} days`
		}
		let string = "";
		Object.keys(age).forEach(entry => {
			if(parseInt(age[entry]) === 0) {
				delete age[entry];
			}
			if(age[entry]) {
				string += age[entry]+ " ";
			} else {
				return string
			}
		})

		return string;
	}


	closeModal(data) {
		if(data){
			this.props.updateGolfer(data.golfers);
		}
		this.setState({
			editAccountModalIsOpen: false,
			editStatusModalIsOpen: false,
			addressModalIsOpen: false,
			createDigitalProfileLinkModal: false,
			resetDigitalProfilePasswordLinkModal: false,
			secondaryAddressModalIsOpen: false,
		});
	}

	componentDidMount() {
		Modal.setAppElement('body');
		this.getDigitalProfile();
    if (this.props.golfer.email)
		  this.getDigitalProfileEmails();
	}

	getDigitalProfile(){
		GolferService.getDigitalProfile(this.props.golfer.id)
			.then(res => {
				this.setState({digitalProfile: res.digital_profile});
			})
			.catch(err => {
				console.error(err)
			})
	}

	getDigitalProfileEmails(){
		this.setState({
			loadingDigitalProfileEmails: true,
		});
		
		const email = String(this.props.golfer.email)
		GolferService.getDigitalProfileEmails(this.state.page, this.state.per_page, (email.trim() === "" ? null : email))
			.then(res => {
				this.setState({
					digitalProfileEmails: res.digital_profile_emails,
					loadingDigitalProfileEmails: false,
					total: parseInt(res['total-count'], 10),
					per_page: parseInt(res['per-page'], 10),
					pages: parseInt(res['total-pages'], 10),
				});
			})
			.catch(err => {
				this.setState({
					loadingDigitalProfileEmails: false,
				});
				console.error(err)
			})
	}

	getPage(){
		this.setState({
			loadingDigitalProfileEmails: true
		});
		this.getDigitalProfileEmails();
	}

	deleteAddress(){
		GolferService.deleteGolferAddress(this.props.golfer.club_id, this.props.golfer.id, this.state.deleteGolferAddressModal)
			.then(res => {
				this.props.addAlert({
					type:'success',
					message: `Golfer ${this.state.deleteGolferAddressModal} address has been successfully deleted`
				});
				this.setState({deleteGolferAddressModal: false},() => {this.props.updateGolfer(res.golfers)});
			})
			.catch(err => {
				if(err.data.errors && err.data.errors.guardian && err.data.errors.guardian.length > 0) {
					this.setState({
						deleteAddressError: err.data.errors.guardian[0]
					})
				}
			})
	}

	sendInvitation() {
		GolferService.sendInvitation(this.props.golfer.id, this.props.associationId)
			.then(res => {
				this.getDigitalProfile();
				this.props.addAlert({
					type:'success',
					message:'Invitation email has been successfully sent.'
				})
				this.setState({sendInvitationModal: false})
			})
			.catch(err => {
				this.setState({sendInvitationModal: false})
				if(err.data.errors && err.data.errors.digital_profile && err.data.errors.digital_profile.length > 0) {
					this.setState({
						invitationsError: err.data.errors.digital_profile[0]
					})
				}
				console.log(err)
			})
	}

	resendInvitation() {
		SharedService.resendDigitalProfileInvitationEmail(this.props.golfer.email ? {email: this.props.golfer.email, ghin_number: this.props.golfer.id} : {last_name: this.props.golfer.last_name, ghin_number: this.props.golfer.id}, this.props.associationId)
			.then(res => {
				this.getDigitalProfile();
				this.props.addAlert({
					type: 'success',
					message:'Invitation email has been successfully sent.'
				})
				this.setState({resendInvitationModal: false})
			})
			.catch(err => {
				this.setState({resendInvitationModal: false})
				if(err.data.errors && err.data.errors.digital_profile && err.data.errors.digital_profile.length > 0) {
					this.setState({
						invitationsError: err.data.errors.digital_profile[0]
					})
				}
				console.log(err)
			})
	}

	resetPassword() {
		GolferService.sendResetPassword(this.props.golfer.id, this.props.associationId)
			.then(res => {
				this.getDigitalProfile();
				this.props.addAlert({
					type:'success',
					message:'Email has been successfully sent.'
				})
				this.setState({resetPasswordModal: false})
			})
			.catch(err => {
				console.error(err);
			})
	}

	removeDigitalProfile() {
			GolferService.removeDigitalProfile(this.props.golfer.id)
				.then(res => {
					this.getDigitalProfile();
					this.props.addAlert({
						type:'success',
						message:'Digital Profile has been successfully removed.'
					})
					this.setState({removeDigitalProfileModal: false})
				})
				.catch(err => {
					this.setState({removeDigitalProfileModal: false})
					if(err.data.errors && err.data.errors.digital_profile && err.data.errors.digital_profile.length > 0) {
						this.setState({
							removeDigitalProfileError: err.data.errors.digital_profile[0]
						})
					}
					console.log(err)
				})
	}

	generateCreateDigitalProfileLink() {
		GolferService.generateInvitationLink(this.props.golfer.id)
    .then((response) => {
      const generated_url = response.digital_profile.generated_url;
      this.setState({
        generatedUrl: generated_url,
				createDigitalProfileLinkModal: true
      });
    })
    .catch((error) => {
      console.error(error);
      this.setState({digitalProfileLinkError: error.data.errors.digital_profile[0]})
    });
	}

	generateResetDigitalProfilePasswordLink() {
		GolferService.generateResetPasswordLink(this.props.golfer.id)
			.then((response) => {
				const generated_url = response.digital_profile.generated_url;
				this.setState({
					generatedUrl: generated_url,
					resetDigitalProfilePasswordLinkModal: true
				});
			})
			.catch((error) => {
				console.error(error);
				this.setState({digitalProfileLinkError: error.data.errors.digital_profile[0]})
			});
	}

	render() {
		let golfer = this.props.golfer;
		const golferAgeInYears = golfer.date_of_birth ? moment().diff(moment(golfer.date_of_birth).format(), 'years') : 30;
		let digitalProfile = this.state.digitalProfile;
		const isMinorGolfer = golferAgeInYears < 13;
		const isJuniorGolfer = golferAgeInYears >= 13 && golferAgeInYears < 19;
		const digitalProfileEmailsColumns = [
			{
				Header: 'Date/Time Sent',
				accessor: 'date_sent',
				Cell: props => moment(new Date(props.original.date_sent), 'YYYY/MM/DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss'),
			},
			{
				Header: 'Email Type',
				accessor: 'template_type',
			},
			{
				Header: 'Status',
				accessor: 'status',
			},
			{
				Header: 'Error Message',
				accessor: 'error_message',
			},
		];
    const isArchived = golfer.status === "Archived";
    const isAssociationAdmin = this.props.user_type === "association-admin";
    const isSuperAdmin = this.props.user_type === "super-admin"
    const isAdminMergeUnmergeLink = isAssociationAdmin || isSuperAdmin;

    const unMergeLinkVisible = golfer.is_merged && !isArchived && isAdminMergeUnmergeLink;
    const mergeLinkVisible = !golfer.is_merged && !isArchived && isAdminMergeUnmergeLink;
		return (
			<Fragment>

				{this.props.golfer && <div className="columns">

					<div className="row">
						<div className="col">
							<div className="panel">
								<div className="panel__head">
									<span>Basic Information</span>
									{isMinorGolfer && <span style={{color: '#c9243f'}} className="col auto push-right">This golfer is a MINOR.</span>}
									{isMinorGolfer && this.props.canEditClub && !golfer.has_active_guardians && <span style={{color: '#c9243f'}}>A guardian is required for minor golfers. {golfer.status !=="Archived" && <NavLink style={{color: '#c9243f'}} className={'lnk'} to={'./guardianship'}>Click to add</NavLink>}</span>}
								</div>
								<div className="panel__body">
									{(!this.props.archived && ((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv" && !this.props.publicView) || (this.props.user_type === "super-admin"))) && <div className="row">
										<div className="col auto push-right">
											<button onClick={this.openEditAccountModal} className="btn lnk">Edit</button>
										</div>
									</div>}
									<div className="row">
										<div className="col jc-fs">
											<div className="data-set__container">
												<div className="data-set">
													<span className="data-label">Name:</span>
													<span>{golfer.player_name}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Gender:</span>
													<span>{golfer.gender === "M" ? 'Male' : 'Female'}</span>
												</div>
												{!this.props.publicView && <div className="data-set">
													<span className="data-label">Date of Birth:</span>
													<span>{golfer.date_of_birth && (isMinorGolfer ||  isJuniorGolfer ? <>{moment(golfer.date_of_birth, 'YYYY-MM-DD').format('MM/DD/YYYY')} ({getAge(golfer.date_of_birth, 'YYYY-MM-DD')})</> : moment(golfer.date_of_birth, 'YYYY-MM-DD').format('MM/DD/YYYY'))}</span>
												</div>}
												{!this.props.publicView && <div className="data-set">
													<span className="data-label">Phone Number:</span>
													<span>{golfer.phone_number}</span>
												</div>}
												{!this.props.publicView && <div className="data-set">
													<span className="data-label">Email Address:</span>
													<span>{golfer.email}</span>
												</div>}
												<div className="data-set">
													<span className="data-label">Is Merged?:</span>
													<span>{golfer.is_merged ? 'Yes': 'No'}</span>
                          {
                            mergeLinkVisible &&
                            <span className="data-label">
                              <button className="btn lnk">
                                <NavLink
                                  className="btn lnk"
                                  to={{
                                    pathname: "/merges",
                                    fromAccountProps: {
                                      pageType: "merge",
                                      ghinNumber: golfer.id
                                    }
                                  }}
                                  >
                                    Merge
                                  </NavLink>
                              </button>
                            </span>
                          }
                          {
                            unMergeLinkVisible &&
                            <span className="data-label">
                              <button className="btn lnk">
                                <NavLink
                                  className="btn lnk"
                                  to={{
                                    pathname: "/merges",
                                    fromAccountProps: {
                                      pageType: "unmerge",
                                      ghinNumber: golfer.id
                                    }
                                  }}
                                  >
                                    Unmerge
                                </NavLink>
                              </button>
                            </span>
                          }
												</div>
											</div>
										</div>
										<div className="col jc-fs">
											<div className="data-set__container">
												<div className="data-set">
													<span className="data-label">GHIN Number Creation Date:</span>
													<span>{moment(golfer.creation_date, 'YYYY-MM-DD', true).isValid() ?
														moment(golfer.creation_date, 'YYYY-MM-DD').format('MM/DD/YYYY') :
														moment(moment.utc(golfer.creation_date).toDate()).format('MM/DD/YYYY')}
													</span>
												</div>
												<div className="data-set">
													<span className="data-label">Home Club:</span>
													<span>{golfer.primary_club_name}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Golf Canada Network ID:</span>
													<span>{golfer.golf_canada_id}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Is Archived?:</span>
													<span className={golfer.status ==="Archived" ? `red-value`: ''}>{golfer.status ==="Archived" ? "Yes" : "No"}</span>
													{golfer.status ==="Archived" && this.props.user_type === "super-admin" && !golfer.is_merged &&
														<span className="data-label"><button onClick={this.openEditStatusModal} className="btn lnk">Edit</button></span>}
												</div>
												<div className="data-set">
													<span className="data-label">Test Golfer?:</span>
													<span>{golfer.is_test ? "Yes" : "No"}</span>
												</div>
                        <div className="data-set">
                          <span className="data-label">Deceased?:</span>
                          <span>{golfer.deceased ? "Yes" : "No"}</span>
                        </div>
                        {golfer.deceased && <div className="data-set">
                          <span className="data-label">Details:</span>
                          <span>{golfer.deceased_details}</span>
                        </div>}
												{/* not needed now */}
												{/*<div className="data-set">*/}
												{/*	<span className="data-label">Local Number:</span>*/}
												{/*	<span>{golfer.local_number}</span>*/}
												{/*</div>*/}
												{/*{!this.props.publicView && <div className="data-set">*/}
												{/*	<span className="data-label">Membership Type:</span>*/}
												{/*	<span>{golfer.membership_code}</span>*/}
												{/*</div>}*/}
											</div>
										</div>
									</div>
								</div>
							</div>

							{!isMinorGolfer && digitalProfile && this.props.golfer.email && <div className="panel">
								<div className="panel__head">Digital Profile Information</div>
								<div className="panel__body">
									<div className="row">
										<div className="col is-2-of-3 jc-fs">
											<div className="data-set__container">
												<div className="data-set">
													<span className="data-label">Golfer Profile Creation Date:</span>
													<span>{digitalProfile.creation_date && digitalProfile.creation_date !== '-' ? moment.utc(digitalProfile.creation_date, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Initial Invitation Sent Date:</span>
													<span>{digitalProfile.sent_invite_for_digital_profile_on && digitalProfile.sent_invite_for_digital_profile_on !== '-' ? moment.utc(digitalProfile.sent_invite_for_digital_profile_on, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">Last Login (Includes Admin Portal & Golfer Products):</span>
													<span>{digitalProfile.last_login && digitalProfile.last_login !== '-' ? moment.utc(digitalProfile.last_login, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">GHIN Terms of Service Accepted (for Golfer Products):</span>
												<span>{digitalProfile.tos_accepted_date && digitalProfile.tos_accepted_date !== '-' ? moment.utc(digitalProfile.tos_accepted_date, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
												<div className="data-set">
													<span className="data-label">GHIN Privacy Policy Accepted (for Golfer Products):</span>
													<span>{digitalProfile.privacy_policy_accepted_date && digitalProfile.privacy_policy_accepted_date !== '-' ? moment.utc(digitalProfile.privacy_policy_accepted_date, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss') : '-'}</span>
												</div>
											</div>
										</div>
										<div className="col is-1-of-3 ai-fe push-right">
                      {golfer.status !== "Archived" && digitalProfile.display_send_invitation_button === "true" && <Fragment><div className={"row"}>
                        <button className={'btn fill green'} onClick={() => { this.setState({ sendInvitationModal: true }) }}>Send Invitation to Create a Digital Profile</button>
                      </div>
                      </Fragment>
                      }
											{digitalProfile.display_send_reset_password_email_button === "true" &&
												<div>
													<div className={"row col is-full"}>
														<button className={'btn fill green'} onClick={()=>{this.setState({resetPasswordModal: true})}}>Send Reset Password Email</button>
													</div>
													<div className={"row col is-full"}>
														<button className={'btn fill green'} onClick={()=>{this.generateResetDigitalProfilePasswordLink()}}>Generate “Reset Digital Password” Link</button>
													</div>
													{
														(this.props.user_type === 'super-admin' || this.props.user_type === 'association-admin') &&
														<div className={"row col is-full"}>
															<button className={'btn fill green'} onClick={()=>{this.setState({removeDigitalProfileModal: true})}}>Remove Digital Profile</button>
														</div>
													}
												</div>
											}
											{digitalProfile.display_resend_invitation_button === "true" &&
												<div>
													<div className={"row col is-full"}>
														<button className={'btn fill green'} onClick={()=>{this.setState({resendInvitationModal: true})}}>Resend Invitation To Create A Digital Profile</button>
													</div>
													<div className={"row col is-full"}>
														<button className={'btn fill green'} onClick={()=>{this.generateCreateDigitalProfileLink()}}>Generate “Digital Profile Creation” Link</button>
													</div>
													{
														(this.props.user_type === 'super-admin' || this.props.user_type === 'association-admin') &&
														<div className={"row col is-full"}>
															<button className={'btn fill green'} onClick={()=>{this.setState({removeDigitalProfileModal: true})}}>Remove Digital Profile</button>
														</div>
													}
												</div>
											}
										</div>
									</div>
									<div className={'row'}>
										<div className={'col'}>
											<div style={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
												<span className={"red"}>Please note, there may be a slight delay in updating the table.</span>
												<i  className="material-icons-outlined" style={{cursor: 'pointer'}} onClick={() =>{this.getDigitalProfile(); this.getDigitalProfileEmails()}}>refresh</i>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col jc-fs">
											<GhinTable
												loading={this.state.loadingDigitalProfileEmails}
												columns={digitalProfileEmailsColumns}
												data={this.state.digitalProfileEmails}
												ref={(r) => this.table = r}
												hideSlelectAll={true}
												hideSelect
												sortable={false}
												defaultPageSize={5}
												pages={this.state.pages}
		                    total={this.state.total}
		                    manual={true}
												pageSize={this.state.per_page}
												onPageChange={(pageIndex) => this.setState({page: pageIndex+1}, () =>  this.getPage())}
												onPageSizeChange={(pageSize, pageIndex) => this.setState({
													per_page: pageSize,
													page: pageIndex + 1
												}, () => this.getPage())}
											/>
										</div>
									</div>
								</div>
							</div>}

							{!this.props.publicView &&
							<div className='row'>
								<div className="col jc-fs">
									<div className="panel">
										<div className="panel__head">
											Addresses
										</div>
										<div className="panel__body">

											<table className="data__table">
												<colgroup>
													<col className="col_state"/>
													<col className=""/>
													<col className="col_state"/>
													<col className="col_state"/>
													<col className="col_status"/>
													<col className="col_state"/>
													<col className="col_state"/>
													{!this.props.archived && ((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || (this.props.user_type === "super-admin")) && <col className="col_state"/>}
												</colgroup>
												<thead>
												<tr>
													<th>Type</th>
													<th>Address</th>
													<th>City</th>
													<th>State/Prov.</th>
													<th>Postal <br/> Code</th>
													<th>Country</th>
													<th>Date Range <br/> at Address</th>
													{!this.props.archived && ((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || (this.props.user_type === "super-admin")) && <th className='col_edit'></th>}
												</tr>
												</thead>
												<tbody>
												<tr>
													<td>Primary</td>
													{(golfer.primary_address && golfer.primary_address.city && golfer.primary_address.state) ?
														<Fragment>
															<td>{golfer.primary_address.street_1} {(golfer.primary_address.street_2 && ( <Fragment><br/>  {golfer.primary_address.street_2}</Fragment>))}</td>
															<td>{golfer.primary_address.city}</td>
															<td>{golfer.primary_address.state_name}</td>
															<td>{golfer.primary_address.zip}</td>
															<td>{golfer.primary_address.country_name}</td>
															<td>{golfer.primary_address.all_year_round ? 'Year-Round' : golfer.primary_address.active_from + ' - ' + golfer.primary_address.active_to}</td>
															{!this.props.archived && ((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || (this.props.user_type === "super-admin")) && <td className='col_edit-delete'>
																<button className={'btn'} onClick={()=>{this.setState({type: 'primary', actionType:'edit', addressModalIsOpen: true})}}>Edit</button>
																<button className={'btn'} onClick={()=>{this.setState({deleteGolferAddressModal: 'primary'})}}>Delete</button>
															</td>}
														</Fragment>
														:
														<Fragment>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															{!this.props.archived && ((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || (this.props.user_type === "super-admin")) && <td className='col_edit'>
																<button className={'btn fill green small'} onClick={()=>{this.setState({type: 'primary', actionType:'add', addressModalIsOpen: true})}}>Add</button>
															</td>}
														</Fragment>
													}
												</tr>
												<tr>
													<td>Secondary</td>
													{(golfer.secondary_address && golfer.secondary_address.city && golfer.secondary_address.state) ?
														<Fragment>
															<td>{golfer.secondary_address.street_1 + (golfer.secondary_address.street_2 && (', '  + golfer.secondary_address.street_2))}</td>
															<td>{golfer.secondary_address.city}</td>
															<td>{golfer.secondary_address.state_name}</td>
															<td>{golfer.secondary_address.zip}</td>
															<td>{golfer.secondary_address.country_name}</td>
															<td>{golfer.secondary_address.all_year_round ? 'Year-Round' : golfer.secondary_address.active_from + ' - ' + golfer.secondary_address.active_to}</td>
															{!this.props.archived && ((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || (this.props.user_type === "super-admin")) && <td className='col_edit-delete'>
																<button className={'btn'} onClick={()=>{this.setState({type: 'secondary', actionType:'edit', addressModalIsOpen: true})}}>Edit</button>
																<button className={'btn'} onClick={()=>{this.setState({deleteGolferAddressModal: 'secondary'})}}>Delete</button>
															</td>}
														</Fragment>
														:
														<Fragment>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															{!this.props.archived && ((this.props.canEditClub && process.env.REACT_APP_TYPE !== "hv") || (this.props.user_type === "super-admin")) && <td className='col_edit'>
																{(golfer.primary_address && golfer.primary_address.city && golfer.primary_address.state) &&
																<button className={'btn fill green'}
																		onClick={this.openSecondaryAddressModal}>Add</button>}
															</td>}
														</Fragment>
													}
													{/* ()=>{this.setState({type: 'secondary', actionType:'add', addressModalIsOpen: true})} */}
												</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>}
						</div>
					</div>
				</div>}
				<Modal
					isOpen={this.state.editAccountModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<EditGolferAccountModal golfer={this.props.golfer} canEditAssociation={getPermission(this.props.user_type, 'edit-association')} club={this.props.club} closeModal={(data)=>{this.closeModal(data)}} />
				</Modal>

				<Modal
					isOpen={this.state.editStatusModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
          <EditGolferStatusModal golfer={this.props.golfer} club={this.props.club} closeModal={(data) => { this.closeModal(data) }} />
				</Modal>

				{
					this.state.invitationsError ? (<ConfirmationModal openModal={this.state.invitationsError}
																	  onConfirmAction={() =>  this.setState({invitationsError: null})}
																	  body={`${this.state.invitationsError.body_line1 || ''}\n\n${this.state.invitationsError.body_line2 || ''}`}
																	  onCancelAction={()=>{this.setState({invitationsError: null})}}
																	  closeModal={()=>{this.setState({invitationsError: null})}}
																	  question={this.state.invitationsError.top_line}
																	  cancelLabel={"Cancel"}
																	  confirmLabel={"OK"}/>) : null
				}

				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.deleteGolferAddressModal}
					onConfirmAction={this.deleteAddress}
					onCancelAction={()=>{this.setState({deleteGolferAddressModal: false, deleteAddressError: null})}}
					closeModal={()=>{this.setState({deleteGolferAddressModal: false, deleteAddressError: null})}}
					question={"Are you sure you want to remove this address?"}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
					error={this.state.deleteAddressError}
				/>

				<ConfirmationModalTypeB
					               wideClass={'narrow'}
								   openModal={this.state.sendInvitationModal}
								   onConfirmAction={this.sendInvitation}
								   onCancelAction={()=>{this.setState({sendInvitationModal: false})}}
								   closeModal={()=>{this.setState({sendInvitationModal: false})}}
								   question={`Are you sure you want to send an email to invite ${this.props.golfer.first_name} ${this.props.golfer.last_name} to create a digital profile?`}
								   cancelLabel={"No"}
								   confirmLabel={"Yes"}
								   modalIcon={"help_outline"}
				/>

				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.resendInvitationModal}
					onConfirmAction={this.resendInvitation}
					onCancelAction={()=>{this.setState({resendInvitationModal: false})}}
					closeModal={()=>{this.setState({resendInvitationModal: false})}}
					question={`Are you sure you want to resend an email to invite ${this.props.golfer.first_name} ${this.props.golfer.last_name} to create a digital profile?`}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>

				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.resetPasswordModal}
					onConfirmAction={this.resetPassword}
					onCancelAction={()=>{this.setState({resetPasswordModal: false})}}
					closeModal={()=>{this.setState({resetPasswordModal: false})}}
					question={`Are you sure you want to send reset password email to ${this.props.golfer.first_name} ${this.props.golfer.last_name}?`}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>

				<ConfirmationModalTypeB
					wideClass={'narrow'}
					openModal={this.state.removeDigitalProfileModal}
					onConfirmAction={this.removeDigitalProfile}
					onCancelAction={()=>{this.setState({removeDigitalProfileModal: false})}}
					closeModal={()=>{this.setState({removeDigitalProfileModal: false})}}
					question={`Are you sure you want to remove the digital profile for ${this.props.golfer.first_name} ${this.props.golfer.last_name}?`}
					cancelLabel={"No"}
					confirmLabel={"Yes"}
					modalIcon={"help_outline"}
				/>

				{
					this.state.removeDigitalProfileError ?

					(<ConfirmationModal openModal={this.state.removeDigitalProfileError}
							onConfirmAction={() => {this.setState({removeDigitalProfileError: null}); this.getDigitalProfile();}}
							body={`${this.state.removeDigitalProfileError.body_line1 || ''}\n\n${this.state.removeDigitalProfileError.body_line2 || ''}`}
							onCancelAction={()=>{this.setState({removeDigitalProfileError: null}); this.getDigitalProfile();}}
							closeModal={()=>{this.setState({removeDigitalProfileError: null}); this.getDigitalProfile();}}
							question={this.state.removeDigitalProfileError.top_line}
							cancelLabel={"Cancel"}
							confirmLabel={"OK"}
						/>) : null
				}

				<ConfirmationModal
					openModal={this.state.secondaryAddressModalIsOpen}
					onConfirmAction={()=>{this.setState({type: 'secondary', actionType:'add', addressModalIsOpen: true})}}
					onCancelAction={() => {this.closeModal()}}
					closeModal={() => {this.closeModal()}}
					question={'Secondary Address Notice'}
          body={(<div>
									<span>Important:</span>
									<text>
										<br/>
										<br/>
										If a Primary Address has changed, please Edit or Delete it.
										<br/>
										<br/>
										The Secondary Address should only be used for golfers that live in more than one location throughout the year.
									</text>
								 </div>)}
					confirmLabel={"Continue"}
				/>

				<Modal
					isOpen={this.state.addressModalIsOpen}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<GolferAddressModal actionType={this.state.actionType} type={this.state.type} golfer={this.props.golfer} closeModal={(data)=>{this.closeModal(data)}} />
				</Modal>

				<Modal
					isOpen={this.state.createDigitalProfileLinkModal}
					onRequestClose={()=>{this.closeModal()}}
					contentLabel="Modal"
					portalClassName="modal"
					overlayClassName="modal__overlay"
					className="modal__content"
					bodyOpenClassName="modal--is-open"
					htmlOpenClassName="prevent-scroll"
					shouldCloseOnOverlayClick={true}
					shouldFocusAfterRender={false}
				>
					<GolferCreateDigitalProfileLinkModal generatedUrl={this.state.generatedUrl} closeModal={()=>{this.closeModal()}} />
				</Modal>

				<Modal
				isOpen={this.state.resetDigitalProfilePasswordLinkModal}
				onRequestClose={()=>{this.closeModal()}}
				contentLabel="Modal"
				portalClassName="modal"
				overlayClassName="modal__overlay"
				className="modal__content"
				bodyOpenClassName="modal--is-open"
				htmlOpenClassName="prevent-scroll"
				shouldCloseOnOverlayClick={true}
				shouldFocusAfterRender={false}
			>
				<GolferResetDigitalProfilePasswordLinkModal generatedUrl={this.state.generatedUrl} closeModal={()=>{this.closeModal()}} />
			</Modal>

			{
				this.state.digitalProfileLinkError ?

				(<ConfirmationModal openModal={this.state.digitalProfileLinkError}
						onConfirmAction={() =>  this.setState({digitalProfileLinkError: null})}
						body={`${this.state.digitalProfileLinkError.body_line1 || ''}\n\n${this.state.digitalProfileLinkError.body_line2 || ''}`}
						onCancelAction={()=>{this.setState({digitalProfileLinkError: null})}}
						closeModal={()=>{this.setState({digitalProfileLinkError: null})}}
						question={this.state.digitalProfileLinkError.top_line}
						cancelLabel={"Cancel"}
						confirmLabel={"OK"}
					/>) : null
			}

			</Fragment>
		);
	}
}
function mapStateToProps(state){
	return {
		user_type: state.user.access.user_type
	}
}
function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
