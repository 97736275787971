import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect, withRouter} from 'react-router-dom';

import Header from '../shared/Header';
import Navigation from '../shared/Navigation';
import BreadCrumbsNav from '../shared/BreadCrumbsNav';
import Footer from '../shared/Footer';

import MySavedReports from './MySavedReports';
import AdHoc from './AdHoc';
import Dashboards from './Dashboards';
import Standard from './Standard';
import SharedReports from './Shared'
import {connect} from "react-redux";

class AdvancedReports extends Component {

	render() {
		if(!this.props.reporting_token) return null;
		let url = process.env.REACT_APP_API_URL.slice(0,-6) + `reports/${this.props.reporting_token}/`;
		return (
			<Fragment>

				<Header/>
				<Navigation/>
				<BreadCrumbsNav/>

				<main>
					<div className="container">

						<div className="page__head">
							<h2 className="page__title">Reports</h2>
						</div>

						<ul className="page__tabs">
							<li><NavLink className="page__tab"
										 activeClassName="is-active"
										 to={`${this.props.match.url}/ad-hoc`}>Ad-hoc</NavLink>
							</li>
							<li><NavLink className="page__tab"
										 activeClassName="is-active"
										 to={`${this.props.match.url}/my-saved-reports`}>My
								Saved Reports</NavLink></li>
							<li><NavLink className="page__tab"
										 activeClassName="is-active"
										 to={`${this.props.match.url}/shared-reports`}>Shared
								Reports</NavLink></li>
							<li><NavLink className="page__tab"
										 activeClassName="is-active"
										 to={`${this.props.match.url}/standard`}>Standard</NavLink>
							</li>
							<li><NavLink className="page__tab"
										 activeClassName="is-active"
										 to={`${this.props.match.url}/dashboards`}>Dashboards</NavLink>
							</li>
						</ul>

						<section className="page__tab-panel">
							<Switch>
								<Redirect exact from={`${this.props.match.url}`}
										  to={`${this.props.match.url}/ad-hoc`}/>
								<Route path={`${this.props.match.url}/my-saved-reports`}
									   component={() => <MySavedReports iframeUrl={url + 'my_saved_reports'}/>}/>
								<Route path={`${this.props.match.url}/ad-hoc`}
									   component={() => <AdHoc iframeUrl={url + 'adhoc_templates'}/>}/>
								<Route path={`${this.props.match.url}/dashboards`}
									   component={() => <Dashboards  iframeUrl={url + 'dashboards'}/>}/>
								<Route path={`${this.props.match.url}/standard`}
									   component={() => <Standard iframeUrl={url + 'standard'}/>}/>
								<Route path={`${this.props.match.url}/shared-reports`}
									   component={() => <SharedReports  iframeUrl={url + 'shared_reports'}/>}/>
							</Switch>
						</section>

					</div>
				</main>

				<Footer/>

			</Fragment>
		);
	}

}

function mapStateToProps(state) {
	return {
		reporting_token: state.user.access.reporting_token
	}
}


export default withRouter(connect(mapStateToProps, null)(AdvancedReports));
